import React from 'react'
import {
  useTermsModal,
  CONTACTLESS,
  LearnMoreButton
} from '@local/do-secundo-terms-modal'

import styles from './ContactlessDeliveryInstructions.module.css'

export const CONTACTLESS_DELIVERY_INSTRUCTIONS =
  'Add instructions asking your delivery person to meet you outside or leave your order at your door.'

export const ContactlessDeliveryInstructions = React.memo(() => {
  const { openModal, TermsModal } = useTermsModal({ terms: CONTACTLESS })

  return (
    <>
      <div>{CONTACTLESS_DELIVERY_INSTRUCTIONS}</div>
      <LearnMoreButton
        className={styles.infoButton}
        onClick={openModal}
        data-testid='contactless-learn-more'
      />
      <TermsModal />
    </>
  )
})
