import React, { useState, useMemo } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { FractionalQuantity } from '../FractionalQuantity/FractionalQuantity'
import { Button } from '@local/do-secundo-button'
import { EditItem } from '../../Modifiers/EditItem/EditItem'
import { CartTableModifiersList } from './CartTableModifiersList/CartTableModifiersList'
import { RemoveItemButton } from '../RemoveItemButton/RemoveItemButton'
import { ItemPrice } from '../../ItemPrice/ItemPrice'
import { useDeleteItemFromCart } from '@local/do-secundo-cart-mutation'

import { stopPropagationIfExists } from '../../../utils/event-helpers'

import styles from './CartTable.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useCornucopiaState } from '@local/do-secundo-cornucopia'
import { dispatchEditItemUserRequest } from 'cornucopia-apis'

export const CartTable = ({ cart, editable = false }) => {
  const { isCornucopiaShellRunning } = useCornucopiaState()
  const [currentEditGuid, setEditGuid] = useState(null)
  const [onDelete, { loading }] = useDeleteItemFromCart()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()

  const onCloseHandler = useMemo(
    () => stopPropagationIfExists(() => setEditGuid(null)),
    [setEditGuid]
  )
  return (
    <table className={cx(styles.table, { [styles.readonly]: !editable })}>
      <tbody>
        {cart.order.selections.map((selection, index) => {
          const {
            name,
            price,
            preDiscountPrice,
            guid,
            modifiers,
            itemGroupGuid,
            itemGuid,
            usesFractionalQuantity,
            fractionalQuantity
          } = selection
          const key = guid || index

          const handleSetEditGuid = (event) => {
            if (!editable) return
            if (isCornucopiaShellRunning) {
              dispatchEditItemUserRequest({
                cartGuid: cart.guid,
                selectionGuid: selection.guid,
                itemGuid,
                itemGroupGuid
              })
              history.push(getRestaurantPath('/edit'))
            } else {
              event.stopPropagation()
              setEditGuid(selection.guid)
            }
          }
          return (
            <tr
              data-testid='cart-item-body'
              key={key}
              role='group'
              aria-label={`item ${index + 1} of ${
                cart.order.selections.length
              }`}
            >
              <td
                data-testid='cart-item-quantity'
                valign='top'
                className={cx(styles.title, styles.number, 'type-default')}
                aria-label='quantity'
              >
                {selection.quantity}
              </td>
              <td
                onClick={handleSetEditGuid}
                data-testid='cart-item-info'
                className={styles.info}
              >
                <span
                  data-testid='cart-item-name'
                  className={cx(styles.title, 'type-default')}
                >
                  {name}
                </span>
                {usesFractionalQuantity && (
                  <FractionalQuantity {...fractionalQuantity} />
                )}
                {modifiers.length > 0 && (
                  <CartTableModifiersList
                    editable={editable}
                    modifiers={modifiers}
                  />
                )}
                {editable && (
                  <>
                    <Button
                      disabled={loading}
                      data-testid='cart-item-edit'
                      onClick={handleSetEditGuid}
                    >
                      Edit
                    </Button>
                    <RemoveItemButton
                      loading={loading}
                      onDelete={onDelete}
                      cartGuid={cart.guid}
                      selectionGuid={selection.guid}
                    />
                  </>
                )}
                {selection.guid === currentEditGuid && (
                  <EditItem
                    cartGuid={cart.guid}
                    selectionGuid={selection.guid}
                    itemGuid={itemGuid}
                    itemGroupGuid={itemGroupGuid}
                    onClose={onCloseHandler}
                  />
                )}
              </td>
              <ItemPrice price={price} preDiscountPrice={preDiscountPrice} />
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

CartTable.propTypes = {
  cart: PropTypes.object.isRequired,
  editable: PropTypes.bool
}
