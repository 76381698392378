import React from 'react'
import PropTypes from 'prop-types'

import { ReorderBanner } from '../ReorderBanner/ReorderBanner'
import { ReorderBannerPasswordless } from '../ReorderBanner/ReorderBannerPasswordless'
import { PopularItems } from '../PopularItems/PopularItems'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

const ReordersOrPopularItemsBanner = ({ hasReorders = false }) => {
  const { authenticated } = useAuth()
  const canReorder = hasReorders && authenticated
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  let reorderDisplay = <></>
  if (canReorder) {
    reorderDisplay = PASSWORDLESS_ENABLED ? (
      <ReorderBannerPasswordless />
    ) : (
      <ReorderBanner />
    )
  }

  return (
    <>
      {reorderDisplay}
      <PopularItems />
    </>
  )
}

ReordersOrPopularItemsBanner.propTypes = {
  hasReorders: PropTypes.bool.isRequired
}

export { ReordersOrPopularItemsBanner }
