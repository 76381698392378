import PropTypes from 'prop-types'

export const RULE_TYPES = {
  CREDIT_LT: 'CREDIT_LT',
  DELIVERY_LT: 'DELIVERY_LT',
  CASH_GTEQ: 'CASH_GTEQ',
  TOTAL_GTEQ: 'TOTAL_GTEQ'
}

export const ApprovalRuleShape = PropTypes.shape({
  ruleType: PropTypes.oneOf(Object.keys(RULE_TYPES)).isRequired,
  requiredAdjustment: PropTypes.number.isRequired,
  thresholdAmount: PropTypes.number.isRequired
})
