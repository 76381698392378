import * as React from 'react'

/**
 * Custom hook which gives checkbox-style input state to an array of objects.
 * It creates a new array where each item has an additional checked prop with a
 * boolean value and assigns it to checkboxes. Function setCheckbox toggles the
 * checked value to the opposite boolean. (usually used in an onClick function)
 * This was built for a hack day will eventually be replaced with Formik.
 * @param {array} array
 * @returns {
 *  setCheckbox: function,
 *  checkboxes: array
 * }
 */
export const useCheckboxes = (array) => {
  const defaultCheckboxes = array.map((props) => {
    const { id } = props
    return {
      id,
      checked: false,
      ...props
    }
  })
  const [checkboxes, setCheckboxes] = React.useState(defaultCheckboxes)
  const setCheckbox = (checkboxId, checked) => {
    const newCheckboxes = [...checkboxes]
    const checkedBox = newCheckboxes.find(({ id }) => id === checkboxId)
    checkedBox.checked = !checked
    setCheckboxes(newCheckboxes)
  }
  return {
    setCheckbox,
    checkboxes
  }
}
