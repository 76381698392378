import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  useRemovePromoCode,
  useSetPromoCode,
  useSetPromoCodeV3
} from './PromoCodeMutation'

import { Field } from 'formik'
import { toast } from 'react-toastify'

import {
  ToastNotification,
  toastEachWarning
} from '../Modifiers/ToastNotification/ToastNotification'
import { ErrorComponent } from '@local/do-secundo-error'
import { Input } from '@local/do-secundo-form'
import { PromoCode } from './PromoCode/PromoCode'
import { PromoCodeLabel } from './PromoCodeLabel/PromoCodeLabel'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useFormik } from '@local/do-secundo-formik-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  dispatchRemoveAppliedPromoCode,
  usePromoBannersSessionData
} from 'cornucopia-apis'
import { XP_MENU_BADGES } from '../../components/ExperimentsProvider/experiment-definitions'
import { useVariant } from '../../hooks/use-variant'

const PromoCodeInputId = 'PromoCodeInput'

export const PromoCodeContainer = ({ cart }) => {
  const { values, touched, setFieldValue, setFieldError, setFieldTouched } =
    useFormik()
  const { restaurantGuid } = useRestaurant()
  const promoBannersSessionData = usePromoBannersSessionData(restaurantGuid)
  const canApplyPromoCodeFromSession =
    useVariant(XP_MENU_BADGES.experimentName) !== 1

  const [setPromoCode, { error, loading: setPromoCodeLoading, refetch }] =
    useSetPromoCode({
      onComplete: () => {
        setFieldValue('promoCode', '')

        // show toast message for adding promo code
        toast(
          <ToastNotification checkmark>
            Promo code has been applied!
          </ToastNotification>
        )
      },
      onError: () => {
        // show field as invalid if cart modification error or promo code application error is returned
        document.getElementById('PromoCodeInput').focus()
        setFieldError('promoCode', 'This promo code is invalid.')
        // clear promo code in session
        dispatchRemoveAppliedPromoCode(restaurantGuid)
      },
      onCartOutOfStock: (message) => {
        // in the rare case that an item in cart went out of stock while user is applying promo code,
        // show out of stock warning
        toastEachWarning({ warnings: [message] })
      }
    })

  const [
    setPromoCodeV3,
    { errorV3, loading: setPromoCodeLoadingV3, refetchV3 }
  ] = useSetPromoCodeV3({
    onComplete: () => {
      setFieldValue('promoCode', '')
      // show toast message for adding promo code
      toast(
        <ToastNotification checkmark>
          Promo code has been applied!
        </ToastNotification>
      )
    },
    onError: () => {
      // show field as invalid if cart modification error or promo code application error is returned
      document.getElementById('PromoCodeInput').focus()
      setFieldError('promoCode', 'This promo code is invalid.')
      // clear promo code in session
      dispatchRemoveAppliedPromoCode(restaurantGuid)
    },
    onCartOutOfStock: (message) => {
      // in the rare case that an item in cart went out of stock while user is applying promo code,
      // show out of stock warning
      toastEachWarning({ warnings: [message] })
    }
  })

  const handleApplyPromoCodeV3 = useCallback(
    (codeToApply = values.promoCode) => {
      setPromoCodeV3({
        variables: {
          input: {
            cartGuid: cart.guid,
            promoCode: codeToApply
          }
        }
      })
    },
    [setPromoCodeV3, cart.guid, values.promoCode]
  )

  const handleApplyPromoCode = useCallback(
    (codeToApply = values.promoCode) => {
      setPromoCode({
        variables: {
          input: {
            cartGuid: cart.guid,
            promoCode: codeToApply,
            discountType: 'RESTAURANT'
          }
        }
      })
    },
    [setPromoCode, cart.guid, values.promoCode]
  )

  const GLOBAL_GC_ENABLED = useFlag(FF.GLOBAL_GIFTCARDS)
  let actualHandleApplyPromo = handleApplyPromoCode
  let actualError = error
  let actualRefetch = refetch
  let actualSetPromoCodeLoading = setPromoCodeLoading
  if (GLOBAL_GC_ENABLED) {
    actualHandleApplyPromo = handleApplyPromoCodeV3
    actualError = errorV3
    actualRefetch = refetchV3
    actualSetPromoCodeLoading = setPromoCodeLoadingV3
  }

  // submit promo code on enter press
  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      evt.preventDefault()

      // set field touched manually, default happens on blur
      if (!touched['promoCode']) {
        setFieldTouched('promoCode', true)
      }

      actualHandleApplyPromo()
    }
  }

  /**
   * form.touched.fieldName is not set until input blurs, need to check for field
   * value to enable/disable apply button
   * */
  const isDirty = values.promoCode.trim() !== ''

  const {
    order: { discounts = {} }
  } = cart
  const { restaurantDiscount, globalReward } = discounts

  let rewardName = null
  if (restaurantDiscount) {
    rewardName = restaurantDiscount.promoCode
  } else if (globalReward && GLOBAL_GC_ENABLED) {
    rewardName = globalReward.name
  }

  const codeInSession = promoBannersSessionData.appliedPromoCode?.promoCode

  const applyCodeFromSession = () => {
    setFieldValue('promoCode', codeInSession)
    setFieldTouched('promoCode', true)
    actualHandleApplyPromo(codeInSession)
  }

  const [removePromoCode] = useRemovePromoCode({
    onComplete: () => {
      applyCodeFromSession()
    }
  })

  const removeOldCodeAndApplyNewCode = useCallback(() => {
    removePromoCode({
      variables: {
        input: {
          cartGuid: cart.guid,
          discountType: 'RESTAURANT'
        }
      }
    })
  }, [removePromoCode, cart.guid])

  useEffect(() => {
    if (!canApplyPromoCodeFromSession || !codeInSession) {
      return
    }
    const hasPromoCodeApplied = Boolean(
      rewardName && rewardName !== codeInSession
    )
    if (hasPromoCodeApplied) {
      removeOldCodeAndApplyNewCode()
    } else {
      applyCodeFromSession()
    }
  }, [])

  return (
    <div>
      {rewardName ? (
        <PromoCode name={rewardName} cartGuid={cart.guid} />
      ) : (
        <>
          {Boolean(actualError) && (
            <ErrorComponent error={actualError} retry={actualRefetch} />
          )}
          <Field
            id={PromoCodeInputId}
            component={Input}
            type='text'
            name='promoCode'
            label='Promo Code'
            endAdornment={
              <PromoCodeLabel
                canSubmit={isDirty}
                applyPromoCode={actualHandleApplyPromo}
                loading={actualSetPromoCodeLoading}
                noAdornmentMargin
              />
            }
            onKeyDown={handleKeyPress}
          />
        </>
      )}
    </div>
  )
}

PromoCodeContainer.propTypes = {
  cart: PropTypes.object.isRequired
}
