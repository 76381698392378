import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { DateTime } from 'luxon'
import * as Yup from 'yup'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { byGuid } from '../../utils/find-utils'
import { toast } from 'react-toastify'

import {
  useFulfillment,
  FULFILLMENT_TYPES
} from '../FulfillmentProvider/FulfillmentProvider'
import { Progress } from '@local/do-secundo-progress'
import { ErrorComponent } from '@local/do-secundo-error'
import {
  useDiningOptions,
  DINING_OPTION_BEHAVIORS
} from '@local/do-secundo-use-dining-options'
import { useSavedAddresses } from '../use-saved-addresses/use-saved-addresses'
import { isSameISOTime } from '../../utils/time-utils'
import { Modal } from '@local/do-secundo-modal'
import styles from './FulfillmentSelectorModal.module.css'
import { FulfillmentHeader } from './FulfillmentHeader/FulfillmentHeader'
import { useGetCart } from '../CartQuery/CartQuery'
import { DiningOptionSelector } from './DiningOptionSelector/DiningOptionSelector'
import { getDatesAndTimesFromResponse } from './fulfillment-helpers'
import { FulfillmentFooter } from './FulfillmentFooter/FulfillmentFooter'
import { FulfillmentNotification } from './FulfillmentNotification/FulfillmentNotification'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FulfillmentTimeSelector } from './FulfillmentTimeSelector/FulfillmentTimeSelector'
const { ASAP, FUTURE } = FULFILLMENT_TYPES

const WrappedModal = ({ children, ...props }) => (
  <Modal
    responsive={false}
    wrapper={(children) => <Form>{children}</Form>}
    {...props}
  >
    <div className={styles.body}>{children}</div>
  </Modal>
)

export const FulfillmentSelectorModal = ({
  onClose,
  onInvalidAddressClose
}) => {
  const {
    diningOptionBehavior,
    deliveryInfo,
    savedAddressGuid,
    fulfillmentType,
    fulfillmentTime,
    setFulfillment,
    selected,
    loading: fulfillmentLoading
  } = useFulfillment()
  const { asapAvailable, loading: availabilityLoading } = useAvailability()
  const { cart, loading: cartLoading } = useGetCart()
  const [updateFulfillmentError, setUpdateFulfillmentError] =
    useState(undefined)
  const numberOfSelections = cart ? cart.order.numberOfSelections : 0
  const cartHasItems = numberOfSelections > 0

  let selectedDate
  if (fulfillmentTime) {
    selectedDate = DateTime.fromISO(fulfillmentTime).toISODate()
  }
  const {
    loading,
    data,
    refetch,
    error: diningOptionsError
  } = useDiningOptions({ networkOnly: true })

  const { loading: savedAddressesLoading, savedAddresses } = useSavedAddresses()

  if (
    loading ||
    cartLoading ||
    savedAddressesLoading ||
    fulfillmentLoading ||
    availabilityLoading
  ) {
    return (
      <WrappedModal onClose={onClose}>
        <Progress />
      </WrappedModal>
    )
  }

  const error = diningOptionsError || updateFulfillmentError
  const retry = diningOptionsError ? refetch : undefined

  if (error) {
    return (
      <WrappedModal onClose={onClose}>
        <ErrorComponent error={error} retry={retry} />
      </WrappedModal>
    )
  }

  const {
    timeList: initialTimes,
    currentDate,
    diningOptionBehavior: selectedDiningOptionBehavior
  } = getDatesAndTimesFromResponse({
    data,
    selectedDate,
    diningOptionBehavior
  })

  const validationSchema = Yup.object().shape({
    diningOptionBehavior: Yup.string(),
    fulfillmentType: Yup.string(),
    fulfillmentDate: Yup.string().nullable(),
    fulfillmentTime: Yup.string().nullable(),
    deliveryInfo: Yup.object().when('diningOptionBehavior', {
      is: DINING_OPTION_BEHAVIORS.DELIVERY,
      then: Yup.object().shape({
        latitude: Yup.string().required('Required'),
        longitude: Yup.string().required('Required')
      }),
      otherwise: Yup.object()
    })
  })

  let currentTime
  if (initialTimes) {
    currentTime = initialTimes[0]
    if (fulfillmentTime) {
      const matchingTime = initialTimes.find((timeSlot) =>
        isSameISOTime(timeSlot.time, fulfillmentTime)
      )
      if (matchingTime) {
        currentTime = matchingTime
      }
    }
  }

  let defaultFulfillmentType = asapAvailable[selectedDiningOptionBehavior]
    ? ASAP
    : FUTURE
  let initialFulfillmentType = fulfillmentType || defaultFulfillmentType

  // this variable is used to determine initial saved address state, i.e. delivery info and savedAddressGuid
  let initialAddress = null

  if (savedAddressGuid) {
    // initial address is the matching saved address
    initialAddress = savedAddresses.find(byGuid(savedAddressGuid)) || {}
  } else {
    // initial address
    initialAddress = !isEmpty(deliveryInfo)
      ? {}
      : !isEmpty(savedAddresses)
      ? savedAddresses[0]
      : {}
  }

  const initialValues = {
    diningOptionBehavior: selectedDiningOptionBehavior,
    fulfillmentType: initialFulfillmentType,
    fulfillmentDate: currentDate ? currentDate.date : undefined,
    fulfillmentTime: currentTime ? currentTime.time : undefined,
    deliveryInfo: deliveryInfo || initialAddress.deliveryInfo,
    savedAddressGuid: initialAddress.guid,
    savedAddresses
  }

  const handleSubmit = async (values) => {
    try {
      await setFulfillment({
        fulfillmentType: values.fulfillmentType,
        fulfillmentTime: values.fulfillmentTime,
        diningOptionBehavior: values.diningOptionBehavior,
        deliveryInfo: values.deliveryInfo,
        savedAddressGuid: values.savedAddressGuid
      })
      onClose()
      if (
        values.diningOptionBehavior === DINING_OPTION_BEHAVIORS.DELIVERY &&
        !isEqual(initialValues.deliveryInfo, values.deliveryInfo)
      ) {
        toast('Delivery Address Updated!')
      }
    } catch (error) {
      setUpdateFulfillmentError(error)
    }
  }

  const initialValid = validationSchema.isValidSync(initialValues)

  return (
    <Formik
      isInitialValid={initialValid}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { values, handleSubmit } = formik
        return (
          <WrappedModal
            handleSubmit={handleSubmit}
            header={
              <FulfillmentHeader
                editing={selected}
                diningOptionBehavior={values.diningOptionBehavior}
              />
            }
            footer={
              <FulfillmentFooter
                onInvalidAddressClose={onInvalidAddressClose}
                formik={formik}
                editing={selected}
              />
            }
            onClose={selected ? onClose : undefined}
          >
            <div>
              <FulfillmentNotification
                cartHasItems={cartHasItems}
                diningOptionBehavior={values.diningOptionBehavior}
              />
              <DiningOptionSelector values={values} />
              <FulfillmentTimeSelector data={data} formik={formik} />
            </div>
          </WrappedModal>
        )
      }}
    </Formik>
  )
}

FulfillmentSelectorModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
