import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/client/react/components'
import { adopt } from 'react-adopt'

import { CREDIT_CARDS, DELETE_CARD, MAKE_PRIMARY } from './CreditCard.graphql'
import { useAuth } from '../AuthProvider/AuthProvider'
import { isExpirationDateValid } from '../../utils/credit-card-date-validation'
import { ACCOUNT_DATA_STATE } from '@local/do-secundo-passwordless-authentication'

const CreditCardContext = React.createContext({})

// eslint-disable-next-line react/prop-types
const getCardMutation =
  (props) =>
  ({ render }) =>
    (
      <Mutation
        {...props}
        refetchQueries={[{ query: CREDIT_CARDS }]}
        awaitRefetchQueries
      >
        {(mutate, result) => render({ mutate, result })}
      </Mutation>
    )

const CreditCardContainer = adopt({
  deleteCard: getCardMutation({ mutation: DELETE_CARD }),
  makePrimary: getCardMutation({ mutation: MAKE_PRIMARY })
})

const getContext = ({ auth }) => {
  const { user, loading, error, refetch, accountDataStatus } = auth
  const creditCardsStatus = accountDataStatus.creditCards
  if (loading) return { loading, creditCardsStatus }
  if (error) return { error, creditCardsStatus }

  if (user) {
    const cardList = user.creditCards
    if (
      !cardList.length &&
      creditCardsStatus === ACCOUNT_DATA_STATE.importing
    ) {
      return { creditCardsStatus }
    }
    const currentDate = new Date(Date.now())
    const decoratedCards = cardList.map((card) => ({
      ...card,
      expired: !isExpirationDateValid(
        card.expirationMonth,
        card.expirationYear,
        currentDate
      )
    }))
    return {
      creditCards: decoratedCards,
      creditCardsStatus,
      refetch
    }
  }
  return { creditCardsStatus }
}

export const CreditCardProvider = ({ children }) => {
  const auth = useAuth()
  return (
    <CreditCardContainer>
      {({ deleteCard, makePrimary }) => {
        const cardContext = getContext({ auth })
        const context = {
          ...cardContext,
          deleteCard,
          makePrimary
        }
        return (
          <CreditCardContext.Provider value={context}>
            {children}
          </CreditCardContext.Provider>
        )
      }}
    </CreditCardContainer>
  )
}

CreditCardProvider.propTypes = {
  children: PropTypes.node
}

export const CreditCardConsumer = ({ children }) => {
  return (
    <CreditCardContext.Consumer>
      {(context) => children(context)}
    </CreditCardContext.Consumer>
  )
}

CreditCardConsumer.propTypes = {
  children: PropTypes.func
}

export const useCreditCard = () => React.useContext(CreditCardContext)
