import React from 'react'
import PropTypes from 'prop-types'
import truncate from 'lodash/truncate'

import { MenuGroupsShape } from '../Menus/MenuGroups/MenuGroups'
import { HorizontalScrollMenu } from '../HorizontalScrollMenu/HorizontalScrollMenu'
import NavArrow from '../../assets/menu_nav_arrow.svg'
import { MenuGroupCarouselItem } from '../HorizontalScrollMenu/MenuGroupCarouselItem'
import { useVariant } from '../../hooks/use-variant'
import { XP_COMBINED_MENU_GROUPS } from '../ExperimentsProvider/experiment-definitions'

/**
 * Returns an array of menu groups anchors from a menu for use in the HorizontalScrollMenu or MoreNav
 * 1. filters out groups that dont have any items
 * 2. truncates the name of the group to <= 30 characters
 * @param {{ id: string, name:string, items: Array}[]} groups - menu groups of currently selected menu
 * @returns {*}
 */
export function consolidateActiveMenuGroups(groups) {
  return groups.reduce((acc, { id, name, items }) => {
    if (!items || !items.length) {
      return acc
    }
    const newMenuGroupAnchor = {
      id,
      href: `#${id}`,
      title: name,
      name: truncate(name, {
        length: 30,
        omission: '...',
        // break on white space, +, - characters aiming to break on whole words rather than partial words
        separator: /[\W+-/]/
      })
    }

    acc.push(newMenuGroupAnchor)
    return acc
  }, [])
}
/**
 * Returns an array of all menu groups from a full restaurant menu for use in the HorizontalScrollMenu or MoreNav
 * 1. filters out groups that dont have any items
 * 2. truncates the name of the group to <= 30 characters
 * @param {[]} menus - Array of restaurant menus
 * @returns {*} - an array of anchors for each menu group
 */
export function consolidateMenuGroups(menus) {
  return menus.reduce((acc, menu) => {
    const { groups } = menu
    const menuGroupAnchors = consolidateActiveMenuGroups(groups)
    acc.push(...menuGroupAnchors)
    return acc
  }, [])
}

export const MenusNav = ({ menus, activeMenuGroups, activeItem }) => {
  const variant = useVariant(XP_COMBINED_MENU_GROUPS.experimentName)
  const COMBINED_MENU_GROUPS_ENABLED = variant === 1
  if (!activeMenuGroups && !COMBINED_MENU_GROUPS_ENABLED) {
    return null
  }

  const items = COMBINED_MENU_GROUPS_ENABLED
    ? consolidateMenuGroups(menus)
    : consolidateActiveMenuGroups(activeMenuGroups)
  const sharedProps = { items, activeItemId: activeItem }

  if (items.length <= 1) return null

  return (
    <HorizontalScrollMenu
      {...sharedProps}
      rightArrowButton={NavArrow}
      leftArrowButton={NavArrow}
      activeItemId={sharedProps.activeItemId}
    >
      {items.map((item) => (
        <MenuGroupCarouselItem
          key={item.id}
          item={item}
          activeItemId={sharedProps.activeItemId}
        />
      ))}
    </HorizontalScrollMenu>
  )
}

MenusNav.propTypes = {
  activeMenuGroups: MenuGroupsShape.isRequired,
  activeItem: PropTypes.string
}
