import React, { useEffect, useState } from 'react'
import styles from './MoreInfo.module.css'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useNumberOfLines } from './useNumberOfLines'
import { useOverflowActive } from './useOverflowActive'
import { useWindowSize } from '@toasttab/buffet-use-window-size'

export const MoreInfo = ({ text }) => {
  const [expanded, setExpanded] = useState(false)
  const spanRef = React.useRef(null)
  const numberOfLines = useNumberOfLines(text, spanRef)
  const overflowActive = useOverflowActive(text, spanRef)
  const windowSize = useWindowSize({})

  useEffect(() => {
    if (text) {
      if (numberOfLines < 3) setExpanded(false)
    }
  }, [windowSize.width, windowSize.height, numberOfLines, text])

  const moreInfoStyle = expanded
    ? cx('w-11/12 text-secondary type-subhead', styles.fontSize)
    : cx(
        'type-subhead text-secondary w-11/12 overflow-hidden overflow-ellipsis  line-clamp-2 break-words',
        styles.moreInfoText,
        styles.fontSize
      )
  const moreInfoContainerStyle = expanded
    ? 'h-auto flex items-center'
    : 'h-auto flex items-center'
  const viewMoreButton =
    overflowActive && !expanded ? (
      <button
        className={cx(
          'mt-5 h-4 font-bold ml-2',
          styles.transparent,
          styles.primaryImportant
        )}
        onClick={() => setExpanded(true)}
      >
        More
      </button>
    ) : null
  const viewLessButton =
    numberOfLines > 2 || expanded ? (
      <button
        className={cx(
          'h-4 font-bold ml-2',
          styles.transparent,
          styles.primaryImportant
        )}
        onClick={() => setExpanded(false)}
      >
        {' '}
        Less
      </button>
    ) : null
  return text ? (
    <div className={moreInfoContainerStyle}>
      <p className={moreInfoStyle} ref={spanRef}>
        {text}
        {viewLessButton}
      </p>
      {viewMoreButton}
    </div>
  ) : null
}

MoreInfo.propTypes = { text: PropTypes.string }
