import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Card, CardContentLoader } from '../../Card/Card'
import { Dollars } from '@local/do-secundo-dollars'
import cx from 'classnames'

import AddToCart from '../../../assets/add-to-cart.svg'
import { ReorderButton } from '../ReorderButton/ReorderButton'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { sumByField, joinFieldByCommas } from '../../../utils/reducer-utils'

import styles from './ReorderCard.module.css'

export const ReorderCard = ({
  loading = false,
  items = [],
  date,
  price = 0,
  orderGuid,
  hideOnMobile = false
}) => {
  const { orderingAvailable, loading: availabilityLoading } = useAvailability()
  const itemsText = joinFieldByCommas('name')(items)
  const itemQuantity = sumByField('quantity')(items)

  const disableReorder = availabilityLoading || !orderingAvailable

  return (
    <li
      data-testid='reorder-card'
      key={orderGuid}
      className={cx(styles.orderCardContainer, {
        [styles.hideOnMobile]: hideOnMobile
      })}
    >
      <Card
        loading={loading}
        endAdornment={
          <ReorderButton orderGuid={orderGuid} disabled={disableReorder}>
            <AddToCart />
          </ReorderButton>
        }
        loadingSkeleton={<CardContentLoader height={58} />}
      >
        <div className={styles.orderContent}>
          <h3 className={styles.orderHeader}>{itemsText}</h3>
          <div className={styles.orderSubHeader}>
            <span>
              <Dollars amount={price} typography='type-subhead' />
            </span>
            <span className={cx(styles.orderDetailsQuantity, 'type-subhead')}>
              {itemQuantity} Item{itemQuantity > 1 ? `s` : ''}
            </span>
            <span className={cx(styles.orderDate, 'type-subhead')}>
              {DateTime.fromMillis(date).toLocaleString({
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              })}
            </span>
          </div>
        </div>
      </Card>
    </li>
  )
}

const itemShape = PropTypes.shape({
  name: PropTypes.string
})

ReorderCard.propTypes = {
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(itemShape).isRequired,
  date: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  hideOnMobile: PropTypes.bool,
  orderGuid: PropTypes.string.isRequired
}
