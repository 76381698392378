import React, { useState } from 'react'
import { Form, Field, yupToFormErrors } from 'formik'
import PropTypes from 'prop-types'
import cx from 'classnames'
import _get from 'lodash/get'

import { useAuth } from '../AuthProvider/AuthProvider'
import { useNotInDeliveryAreaWarning } from '../NotInDeliveryAreaWarning/use-not-in-delivery-area-warning'

import { ApprovalRules } from '../Cart/ApprovalRules/ApprovalRules'
import { CartTable } from '../Cart/CartTable/CartTable'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Alert } from '@toasttab/buffet-pui-alerts'

import { Shelf } from 'cornucopia-apis'

import {
  PaymentOptionsShape,
  getCreditPaymentOption,
  getSavedCreditPaymentOption,
  getPaymentUponReceiptOption
} from './payment-options'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { ErrorComponent } from '@local/do-secundo-error'
import { Loading } from '@toasttab/do-secundo-loading'
import { Radio } from '@local/do-secundo-form'
import { Password } from '../Form/Password/Password'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import * as CurbsidePickup from './CurbsidePickup/CurbsidePickup'
import * as CheckoutDeliveryInfo from './CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import * as GiftCard from './GiftCard/GiftCard'
import * as DeliveryCommunicationConsent from './DeliveryCommunicationConsent/DeliveryCommunicationConsent'
import * as Tip from './Tip/Tip'
import * as Fundraising from './Fundraising/Fundraising'
import { CreditCard } from '../CreditCard/CreditCard'
import { CheckoutSavedCreditCard } from '../CheckoutSavedCreditCard/CheckoutSavedCreditCard'
import { SaveNewCardCheckbox } from '../SaveNewCardCheckbox/SaveNewCardCheckbox'
import { FormikProvider } from '@local/do-secundo-formik-provider'
import { PromoCodeContainer } from '../PromoCodeContainer/PromoCodeContainer'
import { useCreditCard } from '../CreditCardProvider/CreditCardProvider'
import {
  UnlessAuthenticated,
  IfAuthenticated
} from '../IfAuthenticated/IfAuthenticated'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { LoyaltySection } from '@local/do-secundo-loyaltysection'

import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useGiftCard } from '../GiftCardProvider/GiftCardProvider'
import { useCurbsidePickup } from '../use-curbside-pickup/use-curbside-pickup'
import { useSavedAddresses } from '../use-saved-addresses/use-saved-addresses'
import { hasSufficientFunds } from '../../utils/gift-card-helpers'
import { getFlagsLoading } from '../../utils/checkout-helpers'

import styles from './CheckoutForm.module.css'
import {
  CheckoutError,
  INVALID_CREDIT_CARD,
  INVALID_CREDIT_CARD_MESSAGE
} from '../CheckoutError/CheckoutError'
import {
  useServiceAvailability,
  SERVICE_AVAILABILITY,
  SERVICES
} from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { PlaceOrderDegradedMessage } from './PlaceOrderDegradedMessage/PlaceOrderDegradedMessage'
import { SmsCommunicationConsent } from './SmsCommunicationConsent/SmsCommunicationConsent'
import { AccountWarning } from './AccountWarning/AccountWarning'
import { useFundraisingContext } from '../TfgContextProvider/FundraisingContextProvider'

import { LegalCopy } from '../LegalCopy/LegalCopy'
import {
  getInitialValues,
  getValidationSchema,
  getArgsForSubmit
} from './utils'
import { useVariant } from '../../hooks/use-variant'
import { XP_PRICE_TRANSPARENCY } from '../ExperimentsProvider/experiment-definitions'
import { dispatchRemoveAppliedPromoCode } from 'cornucopia-apis'

export const CheckoutForm = ({ onSubmit, error, paymentOptions, cart }) => {
  const showPtBanner = useVariant(XP_PRICE_TRANSPARENCY.experimentName) === 1
  const giftCardContext = useGiftCard()
  const { setFundraisingConfig } = useFundraisingContext()
  const { giftCard, clearGiftCard } = giftCardContext
  const {
    curbsidePickupConfig,
    loading: csLoading,
    error: csError,
    refetch: refetchCS
  } = useCurbsidePickup()
  const fulfillmentContext = useFulfillment()
  const { creditCards, loading: ccLoading } = useCreditCard()
  const savedAddressesContext = useSavedAddresses()
  const {
    savedAddresses,
    loading: savedAddressesLoading,
    error: savedAddressesError,
    refetch: refetchSavedAddresses
  } = savedAddressesContext
  let { authenticated, user, loading: authLoading } = useAuth()
  user = user || {}
  const {
    creditCardConfig = {},
    loyaltyConfig = {},
    tfgConfig = {},
    fundraisingConfig = {}
  } = cart.restaurant
  const {
    order: { selections }
  } = cart

  const campaignInfo = useFlag(FF.USE_FUNDRAISING_CONFIG, false)
    ? fundraisingConfig
    : tfgConfig

  const isFundraisingEnabled =
    campaignInfo.active && !giftCard?.availableBalance

  const { restaurantInfo, restaurantGuid } = useRestaurant()

  const restaurantPhone = React.useMemo(
    () =>
      restaurantInfo && _get(restaurantInfo, 'data.restaurant.location.phone'),
    [restaurantInfo]
  )

  const whiteLabelName = React.useMemo(
    () =>
      restaurantInfo && _get(restaurantInfo, 'data.restaurant.whiteLabelName'),
    [restaurantInfo]
  )

  const [submitCount, setSubmitCount] = useState(0)

  const featureFlags = {
    'oo-daas-integration': useFlag(FF.DAAS_INTEGRATION, false),
    'oo-v3-gift-card-redemption': useFlag(FF.GIFT_CARD_REDEMPTION, false),
    'oo-v3-use-service-availability': useFlag(
      [FF.USE_SERVICE_AVAILABILITY],
      false
    ),
    'oo-use-fundraising-config': useFlag(FF.USE_FUNDRAISING_CONFIG, false)
  }

  const { hasWarning: hasNotInDeliveryAreaWarning } =
    useNotInDeliveryAreaWarning()

  const { [SERVICES.PLACE_ORDER]: placeOrderService } = useServiceAvailability()

  const cartIsEmpty = selections.length <= 0

  const isPlaceOrderDegraded = React.useMemo(
    () =>
      placeOrderService &&
      placeOrderService.status === SERVICE_AVAILABILITY.DEGRADED,
    [placeOrderService]
  )

  const flagsLoading = getFlagsLoading(featureFlags)

  React.useEffect(() => {
    setFundraisingConfig(campaignInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfo, setFundraisingConfig])

  if (
    fulfillmentContext.loading ||
    authLoading ||
    ccLoading ||
    savedAddressesLoading ||
    flagsLoading ||
    csLoading
  ) {
    return <Loading variant='secondary' />
  }

  if (fulfillmentContext.error) {
    return <ErrorComponent error={fulfillmentContext.error} />
  }
  if (csError) {
    return <ErrorComponent error={csError} retry={refetchCS} />
  }
  if (savedAddressesError) {
    return (
      <ErrorComponent
        error={savedAddressesError}
        retry={refetchSavedAddresses}
      />
    )
  }

  const initialValues = getInitialValues({
    cart,
    creditCards,
    featureFlags,
    fulfillmentContext,
    giftCardContext,
    paymentOptions,
    savedAddresses,
    user,
    curbsidePickupConfig
  })
  const validationSchema = getValidationSchema({
    cart,
    curbsidePickupConfig,
    featureFlags,
    fulfillmentContext,
    giftCardContext
  })
  let isInitialValid = validationSchema.isValidSync(initialValues, {
    context: {
      authenticated,
      featureFlags,
      fulfillmentContext,
      giftCardContext,
      giftCardHasSufficientFunds: hasSufficientFunds({ cart, giftCard })
    }
  })

  // See this regarding custom validate prop instead of validationSchema so
  // that we can pass custom context to yup.
  // https://github.com/jaredpalmer/formik/issues/1359
  return (
    <FormikProvider
      enableReinitialize
      data-testid='checkout-form'
      initialValues={initialValues}
      isInitialValid={isInitialValid}
      validate={(values) =>
        validationSchema
          .validate(values, {
            abortEarly: false,
            context: {
              authenticated,
              featureFlags,
              fulfillmentContext,
              giftCardContext,
              giftCardHasSufficientFunds: hasSufficientFunds({ cart, giftCard })
            }
          })
          .catch((err) => {
            throw yupToFormErrors(err)
          })
      }
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setSubmitCount(submitCount + 1)
          const submitArgs = getArgsForSubmit({
            cart,
            featureFlags,
            fulfillmentContext,
            giftCardContext,
            values,
            savedAddresses,
            curbsidePickupConfig
          })
          await onSubmit(submitArgs)

          // clear promo code in session
          dispatchRemoveAppliedPromoCode(restaurantGuid)
        } catch (e) {
          console.warn('onSubmit', e)
        } finally {
          clearGiftCard()
          setSubmitting(false)
        }
      }}
    >
      {({
        values,
        handleChange,
        isSubmitting,
        isValid,
        handleSubmit,
        setFieldValue,
        validateForm
      }) => {
        const creditOption = getCreditPaymentOption({
          authenticated,
          paymentOptions
        })
        const savedCreditOption =
          creditCards &&
          getSavedCreditPaymentOption({ authenticated, paymentOptions })
        const paymentUponReceiptOption = getPaymentUponReceiptOption({
          paymentOptions
        })
        const optionMap = {
          SAVED_CREDIT_CARD: savedCreditOption,
          CREDIT_CARD: creditOption,
          CASH: paymentUponReceiptOption
        }
        const selectedOption = optionMap[values.paymentType]
        const canShowTip =
          (values.paymentType === 'SAVED_CREDIT_CARD' ||
            values.paymentType === 'CREDIT_CARD') &&
          creditCardConfig.tipEnabled
        const { loyaltyRedemptionEnabled } = loyaltyConfig
        return (
          <>
            <Form className={styles.form} onSubmit={handleSubmit}>
              <AccountWarning />
              <Fieldset label='Your Information'>
                <CustomerInfo.Component />
                <DeliveryCommunicationConsent.Component cart={cart} />
                <SmsCommunicationConsent cart={cart} />
              </Fieldset>
              <CurbsidePickup.Component
                curbsidePickupConfig={curbsidePickupConfig}
              />
              <CheckoutDeliveryInfo.Component savedAddresses={savedAddresses} />
              <Fieldset
                label='Payment Method'
                collapsable
                id='payment_type_fields'
              >
                <GiftCard.Component cart={cart} validateForm={validateForm} />
                <div className={styles['radio-tabs']}>
                  {Boolean(savedCreditOption) && (
                    <Field
                      data-testid='payment-type-saved-CC'
                      style='tab'
                      component={Radio}
                      name='paymentType'
                      id='payment_type_saved_cc'
                      label={savedCreditOption.displayName}
                      value='SAVED_CREDIT_CARD'
                    />
                  )}
                  {Boolean(creditOption) && (
                    <Field
                      data-testid='payment-type-CC'
                      style='tab'
                      component={Radio}
                      name='paymentType'
                      id='payment_type_cc'
                      label={creditOption.displayName}
                      value='CREDIT_CARD'
                    />
                  )}
                  {Boolean(paymentUponReceiptOption) && (
                    <Field
                      data-testid='payment-type-cash'
                      style='tab'
                      component={Radio}
                      name='paymentType'
                      id='payment_type_cash'
                      label={paymentUponReceiptOption.displayName}
                      value='CASH'
                      onChange={handleChange}
                    />
                  )}
                </div>
                {!(
                  savedCreditOption ||
                  creditOption ||
                  paymentUponReceiptOption
                ) && (
                  <p className={styles.description}>
                    The restaurant is currently not accepting orders.
                  </p>
                )}
                {selectedOption && selectedOption.description && (
                  <p className={styles.description}>
                    {selectedOption.description}
                  </p>
                )}
                {values.paymentType === 'SAVED_CREDIT_CARD' && (
                  <Field
                    amexAccepted={creditCardConfig.amexAccepted}
                    component={CheckoutSavedCreditCard}
                    name='savedCardGuid'
                  />
                )}
                <div
                  className={cx({
                    [styles.hidden]: values.paymentType !== 'CREDIT_CARD'
                  })}
                >
                  <Field
                    component={CreditCard}
                    name='encryptedCard'
                    amexAccepted={creditCardConfig.amexAccepted}
                  />
                  <Field
                    component={SaveNewCardCheckbox}
                    name='saveCard'
                    disabled={!values.encryptedCard}
                    onChange={(event) => {
                      handleChange(event)
                      setFieldValue('password', '')
                    }}
                  />
                  <UnlessAuthenticated>
                    <div
                      className={cx({
                        [styles.hidden]: values.saveCard === false
                      })}
                    >
                      <Field
                        component={Password}
                        label='Password'
                        name='password'
                        id='checkout_password'
                        value={values.password}
                        autoComplete='off'
                      />
                    </div>
                  </UnlessAuthenticated>
                  {error?.code === INVALID_CREDIT_CARD && (
                    <Alert
                      data-testid='invalid-credit-card-alert'
                      variant='error'
                      className='w-full'
                    >
                      {INVALID_CREDIT_CARD_MESSAGE}
                    </Alert>
                  )}
                </div>
              </Fieldset>
              {cart ? (
                <Fieldset
                  label={`Your Order (${cart.order.numberOfSelections})`}
                  collapsable
                  collapsed
                >
                  <CartTable cart={cart} />
                </Fieldset>
              ) : null}
              <Shelf
                name='corn-loyalty-selector-shelf'
                params={{ authenticated, user, cart }}
              />
              {loyaltyRedemptionEnabled && (
                <IfAuthenticated>
                  <LoyaltySection cart={cart} />
                </IfAuthenticated>
              )}
              <Fieldset label='Promo Code' collapsable>
                <FormikProvider initialValues={{ promoCode: '' }}>
                  <PromoCodeContainer cart={cart} />
                </FormikProvider>
              </Fieldset>
              {canShowTip && <Tip.Component />}
              {isFundraisingEnabled && (
                <Fundraising.Component
                  cart={cart}
                  campaignName={campaignInfo.campaignName}
                  campaignDescription={campaignInfo.campaignDescription}
                  campaignLogoURL={campaignInfo.campaignLogoURL}
                  donationType={campaignInfo.donationType}
                  donationAmounts={campaignInfo.donationAmounts}
                  tip={(canShowTip && parseFloat(values.paymentTip)) || 0}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
              {cart ? (
                <CartFooter
                  cart={cart}
                  showTotal
                  showPtBanner={showPtBanner}
                  showTip={canShowTip}
                  tip={(canShowTip && parseFloat(values.paymentTip)) || null}
                  showFundraising={
                    isFundraisingEnabled && values.isRoundUpForCharity
                  }
                  roundUp={values.roundUpAmount}
                  fundraising={
                    isFundraisingEnabled && values.fundraisingAmount
                      ? parseFloat(values.fundraisingAmount)
                      : null
                  }
                />
              ) : null}
              {cart ? <ApprovalRules cart={cart} /> : null}
              <LegalCopy />
              <div className={styles.wrapper}>
                <Button
                  data-testid='submit-button'
                  id='submit-button'
                  variant={ButtonVariant.PRIMARY}
                  type={ButtonType.SUBMIT}
                  loading={isSubmitting}
                  disabled={
                    !isValid ||
                    fulfillmentContext.updating ||
                    hasNotInDeliveryAreaWarning ||
                    cartIsEmpty
                  }
                  responsive
                >
                  Submit
                </Button>
              </div>
              {isPlaceOrderDegraded && (
                <PlaceOrderDegradedMessage restaurantPhone={restaurantPhone} />
              )}
            </Form>
            {error && (
              <div className={styles.fieldset} style={{ paddingBottom: 0 }}>
                <CheckoutError
                  loading={isSubmitting}
                  error={error}
                  onSubmit={handleSubmit}
                  SubmitCount={submitCount}
                  whiteLabelName={whiteLabelName}
                  restaurantPhone={restaurantPhone}
                />
              </div>
            )}
          </>
        )
      }}
    </FormikProvider>
  )
}

CheckoutForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  paymentOptions: PaymentOptionsShape,
  cart: PropTypes.object.isRequired
}
