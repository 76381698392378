import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { toRelativeDateString } from '../../utils/time-utils'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { formatHours } from '../../utils/restaurant-schedule-helpers'

export const FutureEstimate = ({ editable }) => {
  const { fulfillmentTime } = useFulfillment()
  const {
    restaurantInfo: { data, loading, error }
  } = useRestaurant()

  if (loading || error) return null

  const {
    restaurant: { timeZoneId }
  } = data
  const fulTime = fulfillmentTime || new Date().toISOString()

  const dt = DateTime.fromISO(fulTime)
  const date = toRelativeDateString(fulTime)
  const time = formatHours(dt, timeZoneId)
  const color = editable ? 'text-primary-75' : 'text-default'
  return (
    <div className={'type-default'}>
      <span
        data-testid='fulfillment-scheduled-ready-date'
        className={`font-bold type-default ${color}`}
      >
        {date}&nbsp;
      </span>
      at
      <span
        data-testid='fulfillment-scheduled-ready-time'
        className={`font-bold type-default ${color}`}
      >
        &nbsp;{time}&nbsp;
      </span>
    </div>
  )
}

FutureEstimate.propTypes = {
  editable: PropTypes.bool
}
