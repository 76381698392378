import * as React from 'react'
import { IdentityPrompt } from '../IdentityPrompt/IdentityPrompt'
export interface LoginPromptProps {
  form: React.ReactNode
  className?: string
  icon?: React.ReactNode
  testId?: string
  footer?: React.ReactNode
}

export const LoginPrompt = ({
  form,
  className,
  icon,
  testId,
  footer
}: LoginPromptProps) => {
  return (
    <IdentityPrompt className={className} icon={icon} testId={testId}>
      {form}
      {footer}
    </IdentityPrompt>
  )
}
