import React from 'react'
import PropTypes from 'prop-types'

import { Notification } from '@local/do-secundo-notification'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FULFILLMENT_TYPES } from '../../FulfillmentProvider/initial-fulfillment-state'

const { ASAP } = FULFILLMENT_TYPES

export const FulfillmentNotification = ({
  cartHasItems,
  diningOptionBehavior
}) => {
  const { valid, selected } = useFulfillment()
  const { futureDatesAvailable, availability } = useAvailability()

  const showFulfillmentUpdateMessage =
    selected && cartHasItems && valid && futureDatesAvailable

  return (
    <>
      {!valid && (
        <Notification severity='warning'>
          Your order time is no longer available so your cart will be cleared.
          Select a new time to begin a new order.
        </Notification>
      )}
      {!availability[ASAP][diningOptionBehavior] && (
        <Notification>
          You can only place scheduled orders right now
        </Notification>
      )}
      {showFulfillmentUpdateMessage && (
        <Notification>
          Changing order time may remove items in your cart, if no longer
          available.
        </Notification>
      )}
    </>
  )
}

FulfillmentNotification.propTypes = {
  cartHasItems: PropTypes.bool,
  diningOptionBehavior: PropTypes.string
}
