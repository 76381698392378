import { useQuery, useMutation } from '@apollo/client'

import { useAuth } from '../AuthProvider/AuthProvider'
import {
  SAVED_ADDRESSES,
  DELETE_ADDRESS,
  UPDATE_ADDRESS
} from '../../apollo/saved-addresses.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'
import { apolloSkipLogoutWorkaround } from '../../utils/workarounds'

export const useSavedAddresses = () => {
  const { authenticated } = useAuth()
  const skip = !authenticated
  const query = useQuery(SAVED_ADDRESSES, {
    ...apolloSkipLogoutWorkaround,
    skip
  })
  const { data = {} } = query
  const savedAddresses = (data?.customer && data.customer?.addresses) || []
  return {
    ...query,
    savedAddresses
  }
}

const defaultArgs = {
  onCompleted: () => {},
  onError: () => {}
}

export const useDeleteSavedAddress = ({
  onCompleted,
  onError,
  addressGuid
} = defaultArgs) => {
  return useMutation(DELETE_ADDRESS, {
    variables: { input: { addressGuid } },
    onError,
    onCompleted: (data) => {
      const { DeleteAddressError: error } = dataByTypename(data.deleteAddress)

      if (error) {
        onError(error)
      } else {
        onCompleted()
      }
    }
  })
}

export const useUpdateSavedAddress = ({
  onCompleted,
  onError
} = defaultArgs) => {
  return useMutation(UPDATE_ADDRESS, {
    onError,
    onCompleted: (data) => {
      const { UpdateAddressError: error } = dataByTypename(data.updateAddress)

      if (error) {
        onError(error)
      } else {
        onCompleted(data)
      }
    }
  })
}
