import React from 'react'
import PropTypes from 'prop-types'
import { Progress } from '@local/do-secundo-progress'
import { BeginSearchBanner } from '../BeginSearchBanner/BeginSearchBanner'
import { NoSearchResultsBanner } from '../NoSearchResultsBanner/NoSearchResultsBanner'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { MenuOverview } from '@local/do-secundo-menu-overview'

export const SearchBannerContainer = ({
  menus,
  loading,
  noItemsFound,
  inputIsEmpty,
  term
}) => {
  const BIRDS_EYE_VIEW_ENABLED = useFlag(FF.BIRDS_EYE_VIEW)

  if (loading) return <Progress />

  if (inputIsEmpty)
    return BIRDS_EYE_VIEW_ENABLED ? (
      <MenuOverview menus={menus} />
    ) : (
      <BeginSearchBanner />
    )

  if (noItemsFound) return <NoSearchResultsBanner term={term} />

  return null
}

SearchBannerContainer.propTypes = {
  term: PropTypes.string,
  loading: PropTypes.bool,
  noItemsFound: PropTypes.bool,
  inputIsEmpty: PropTypes.bool
}
