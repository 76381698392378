import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { useDeleteSavedAddress } from '../../../use-saved-addresses/use-saved-addresses'

export const DeleteSavedAddress = ({ savedAddress, onError, onCompleted }) => {
  const { guid: addressGuid } = savedAddress
  const [deleteSavedAddress, { loading }] = useDeleteSavedAddress({
    addressGuid,
    onError,
    onCompleted
  })

  return (
    <Button
      variant={ButtonVariant.LINK_WIDE}
      type={ButtonType.BUTTON}
      loading={loading}
      onClick={deleteSavedAddress}
    >
      Delete
    </Button>
  )
}

DeleteSavedAddress.propTypes = {
  savedAddress: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
}
