import * as React from 'react'
import PropTypes from 'prop-types'
import { useUpsells } from './UpsellsProvider'
import { MenuItemCard } from '../MenuItemCard/MenuItemCard'
import { CloseButton } from '@local/do-secundo-closebutton'
import { useSessionStorage } from '../../hooks/use-session-storage'
import Slider from 'react-slick'

import AddToCart from '../../assets/add-to-cart.svg'
import { imgInPreprod } from '../../utils/img-url'

import styles from './CartUpsells.module.css'
import { addEventProperties } from '@toasttab/do-secundo-analytics'

const AddToCartAdornment = ({ imageUrl }) => {
  if (!imageUrl) {
    return (
      <div className={styles.addToCartAdornment}>
        <AddToCart />
      </div>
    )
  }

  return (
    <div
      style={{ backgroundImage: `url(${imageUrl})` }}
      className={styles.image}
    />
  )
}

AddToCartAdornment.propTypes = {
  imageUrl: PropTypes.string
}

export const CartUpsells = () => {
  const [showUpsells, setShowUpsells] = useSessionStorage('show_upsells', true)
  const [upsellMasterId, setUpsellMasterId] = React.useState('')
  const { upsells } = useUpsells()

  React.useEffect(() => {
    if (upsells.length > 0 && !upsellMasterId)
      setUpsellMasterId(upsells[0].masterId)
  }, [upsells, upsellMasterId])

  React.useEffect(() => {
    addEventProperties({ upsellMasterId })
  }, [upsellMasterId])

  return (
    upsells.length > 0 &&
    showUpsells && (
      <div
        className={styles.container}
        role='group'
        aria-label='suggested item'
      >
        <div className={styles.recommended}>
          <span>Our suggestions for you</span>
          <div className={styles.closeButton}>
            <CloseButton onClick={() => setShowUpsells(false)} />
          </div>
        </div>
        <ul className={styles.passiveItemsList}>
          <Slider
            infinite
            speed={500}
            slidesToScroll={1}
            slidesToShow={1}
            afterChange={(index) => setUpsellMasterId(upsells[index].masterId)}
          >
            {upsells.slice(0, 5).map((item) => {
              return (
                <MenuItemCard
                  key={item.guid}
                  item={item}
                  dataTestid='promote-item-cart-card'
                  endAdornment={
                    <AddToCartAdornment
                      imageUrl={imgInPreprod(item.imageUrl)}
                    />
                  }
                />
              )
            })}
          </Slider>
        </ul>
      </div>
    )
  )
}
