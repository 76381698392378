import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { CloseIcon } from '@toasttab/buffet-pui-icons'

import styles from './CloseButton.module.css'

const Tag = ({ to, onClick, ...props }) =>
  to ? (
    <Link to={to} {...props} />
  ) : (
    <button type='button' onClick={onClick} aria-label='Close' {...props} />
  )

export const CloseButton = ({
  onClick,
  to,
  'data-testid': dataTestId,
  children = (
    <div className='text-oo-black-87'>
      <CloseIcon className='fill-current' />
    </div>
  ),
  ...props
}) => (
  <Tag
    className={styles.closeButton}
    onClick={onClick}
    to={to}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </Tag>
)

CloseButton.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.any
    })
  ]),
  'data-testid': PropTypes.string,
  children: PropTypes.node
}
