import { useLazyQuery } from '@apollo/client'

/**
 * Hook that determines if DO-menu flag is on, alters input to match old/new API and queries data with useLazyQuery hook.
 * @param {DocumentNode} query
 * @param {QueryHookOptions} options
 * @param {function} getter a callback that returns input object to modify in case of exra nesting (defaults to options.variables.input)
 * @returns {QueryResult}
 */
export const useMenuApiAwareLazyQuery = (
  query,
  options,
  getter = (options) => options.variables.input
) => {
  getter(options).menuApi = 'DO'
  const menusResponse = useLazyQuery(query, {
    ...options
  })

  return menusResponse
}

// Used to mock useFlag value during testing
export const DO_MENU_OPTIONS = [{ doMenuIsOn: true }, { doMenuIsOn: false }]
