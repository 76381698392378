import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'
import 'isomorphic-fetch'
import { dispatchAuthClient } from 'cornucopia-apis'

import '../../utils/lifecycle'
import { getSpaVersion } from '../../utils/spa-version'
import { getApolloClient } from '../../apollo/apollo-client'
import { sharedCache } from 'cornucopia-apis'
import { OnlineOrderingApp } from './OnlineOrderingApp'
import { OOGlobalsProvider } from '@local/do-secundo-oo-globals-provider'
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { lazyInitAuthClient } from '@toasttab/guest-authentication-js'
import { isProfileCreated } from '@local/do-secundo-passwordless-authentication'

const AppComponent = ({
  location = window.location,
  ooGlobals = window.OO_GLOBALS || {
    devError: {
      message: 'Could not find OO_GLOBALS.'
    },
    enabledFeatureFlags: []
  },
  isCornucopiaShellRunning = false
}) => {
  const spaVersion = getSpaVersion()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [authClient] = useState(() => {
    const authClientResult = lazyInitAuthClient({
      // called on user login refresh
      whenUserSessionHasResumed: () =>
        setIsAuthenticated(isProfileCreated(authClient))
    })

    dispatchAuthClient(authClientResult)
    return authClientResult
  })

  return (
    <ApolloProvider client={getApolloClient(authClient, sharedCache)}>
      <OOGlobalsProvider
        devError={ooGlobals.devError}
        enabledFeatureFlags={ooGlobals.enabledFeatureFlags}
        gatewayBaseUri={ooGlobals.gatewayBaseUri}
        toastwebBaseUri={ooGlobals.toastwebBaseUri}
        restaurantGuid={ooGlobals.restaurantGuid}
        routerBasename={ooGlobals.routerBasename}
        shortUrl={ooGlobals.shortUrl}
        spaVersion={spaVersion.current}
        spaPreviousVersion={spaVersion.previous}
        mode={ooGlobals.mode}
        managementGroupGuid={ooGlobals.managementGroupGuid}
        mapboxAccessToken={ooGlobals.mapboxAccessToken}
        toastTakeoutCTAEnabled={Boolean(ooGlobals.toastTakeoutCTAEnabled)}
        isCornucopiaShellRunning={isCornucopiaShellRunning}
      >
        <AuthProvider
          authClient={authClient}
          setIsAuthenticated={setIsAuthenticated}
          isAuthenticated={isAuthenticated}
        >
          <OnlineOrderingApp />
        </AuthProvider>
      </OOGlobalsProvider>
    </ApolloProvider>
  )
}

AppComponent.propTypes = {
  // Intended to be window.location
  location: PropTypes.shape({
    pathname: PropTypes.any.isRequired
  }),
  ooGlobals: PropTypes.object
}

export const BanquetApp = AppComponent
