import * as React from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router'
import { SmsConfirmation } from '@local/do-secundo-sms-confirmation-component'
import { CodeConfirmationForm } from '@local/do-secundo-code-confirmation'
import { AccountBenefits } from '@local/do-secundo-account-benefits'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import PoweredByToastLogo from '../../../../client/assets/powered-by/grey-horizontal.svg'
import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'
import { getRawPhoneNumberWithCountryCode } from '@local/do-secundo-form-utils'
import {
  FROM_LOCATION,
  handleVerificationCodeSubmit,
  useTrackCodeSubmissionSuccess
} from '../utils'
import { useSendSmsCode } from '@local/do-secundo-passwordless-authentication'
export interface SmsConfirmationBenefitsProps {
  testId?: string | number
  phone: string
  numFields?: number
}

export const SmsConfirmationBenefits = ({
  testId = 'SmsConfirmationBenefits',
  phone,
  numFields = 6
}: SmsConfirmationBenefitsProps) => {
  const { passwordlessLogin, authClient, setIsAuthenticated } = useAuth()
  const history = useHistory()
  const state = history.location?.state || {}

  const codeSubmissionSource = state?.from
  const trackCodeSubmissionSuccess = useTrackCodeSubmissionSuccess()

  const shouldShowFormFirst =
    codeSubmissionSource !== FROM_LOCATION.showAccountBenefits
  const [showCodeConfirmForm, setShowCodeConfirmForm] =
    React.useState(shouldShowFormFirst)

  useSendSmsCode(phone, authClient)

  const handleSmsCodeSubmit = async (code: string) => {
    await handleVerificationCodeSubmit({
      code,
      onCodeSubmissionSuccess: () => {
        history.replace({
          ...history.location,
          state: {
            ...state,
            guestPhone: '',
            from: FROM_LOCATION.showAccountBenefits
          }
        })
        setShowCodeConfirmForm(false)
      },
      trackCodeSubmissionSuccess: () =>
        trackCodeSubmissionSuccess(codeSubmissionSource),
      completeProfileCreation: passwordlessLogin,
      from: FROM_LOCATION.create
    })
    setIsAuthenticated(authClient?.isAuthenticated)
  }

  const handleResend = async () => {
    await authClient?.requestPasswordless(
      getRawPhoneNumberWithCountryCode(phone)
    )
  }

  if (!showCodeConfirmForm) {
    return (
      <div style={{ margin: '24px' }}>
        <AccountBenefits />
      </div>
    )
  }

  return (
    <div
      className='p-4 m-6 border-2 border-solid border-accent text-primary-75'
      data-testid={testId}
    >
      <div className={cx({ invisible: !showCodeConfirmForm })}>
        <SmsConfirmation
          icon={<ToastLogo size='md' className='rounded-full' />}
          title={
            <h2 className='my-3 type-headline-5'>
              Complete your Toast Account
            </h2>
          }
          testId={`sms-confirmation-benefits`}
          mainText='Enter the 6 digit-code we texted'
          phone={phone}
          secondaryText={
            <p className='pb-2'>to complete your Toast Account.</p>
          }
          form={
            <CodeConfirmationForm
              testId={`code-confirmation-form-benefits`}
              handleSubmit={handleSmsCodeSubmit}
              numFields={numFields}
              handleResend={handleResend}
            />
          }
          footer={
            <PoweredByToastLogo className='block w-32 mx-auto mt-1 mb-4' />
          }
        />
      </div>
    </div>
  )
}
