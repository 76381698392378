import React from 'react'
import PropTypes from 'prop-types'
import { Field, connect } from 'formik'

import { boolean, string } from 'yup'

import { Checkbox, Input } from '@local/do-secundo-form'

import { Fieldset } from '@local/do-secundo-fieldset'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'

const { TAKE_OUT } = DINING_OPTION_BEHAVIORS

const curbsideValid = ({ fulfillmentContext, curbsidePickupConfig }) => {
  const { diningOptionBehavior } = fulfillmentContext
  const { enabled, enabledV2 } = curbsidePickupConfig

  return diningOptionBehavior === TAKE_OUT && (enabledV2 || enabled)
}

export const Component = connect(({ curbsidePickupConfig, formik }) => {
  const fulfillmentContext = useFulfillment()
  const { enabledV2 } = curbsidePickupConfig || {}
  const showCurbside = curbsideValid({
    curbsidePickupConfig,
    fulfillmentContext
  })

  return (
    showCurbside && (
      <Fieldset label='Curbside Pickup' collapsable id='curbside_fields'>
        <>
          <Field
            component={Checkbox}
            name='isCurbsidePickup'
            id='isCurbsidePickup'
            label={<span>Bring my order to my car</span>}
          />
          {enabledV2 && formik.values.isCurbsidePickup && (
            <>
              <Field
                component={Input}
                name='transportDescription'
                id='transportDescription'
                label='Vehicle Make/Model*'
                type='text'
              />
              <Field
                component={Input}
                name='transportColor'
                id='transportColor'
                label='Vehicle Color'
                type='text'
              />
            </>
          )}
        </>
      </Fieldset>
    )
  )
})

Component.displayName = 'CurbsidePickup'
Component.propTypes = {
  curbsidePickupConfig: PropTypes.object.isRequired
}

export const getInitialValues = ({
  fulfillmentContext,
  curbsidePickupConfig,
  featureFlags
}) => {
  if (
    !curbsideValid({ fulfillmentContext, curbsidePickupConfig, featureFlags })
  ) {
    return null
  }

  const { enabledV2 } = curbsidePickupConfig

  return {
    isCurbsidePickup: false,
    ...(enabledV2
      ? {
          transportDescription: '',
          transportColor: ''
        }
      : {})
  }
}

export const getArgsForSubmit = ({
  values,
  fulfillmentContext,
  curbsidePickupConfig,
  featureFlags
}) => {
  if (
    !curbsideValid({ fulfillmentContext, curbsidePickupConfig, featureFlags })
  ) {
    return null
  }

  const { enabledV2 } = curbsidePickupConfig

  return enabledV2
    ? {
        curbsidePickupV2: values.isCurbsidePickup
          ? {
              transportDescription: values.transportDescription,
              transportColor:
                values.transportColor.trim() === ''
                  ? null
                  : values.transportColor
            }
          : null
      }
    : {
        curbsidePickup: {
          selected: values.isCurbsidePickup
        }
      }
}

export const getValidationSchema = ({
  curbsidePickupConfig,
  featureFlags,
  fulfillmentContext
}) => {
  if (
    !curbsideValid({ curbsidePickupConfig, featureFlags, fulfillmentContext })
  ) {
    return null
  }

  const { enabledV2 } = curbsidePickupConfig

  return {
    isCurbsidePickup: boolean().default(false),
    ...(enabledV2
      ? {
          transportDescription: string()
            .trim()
            .when('isCurbsidePickup', {
              is: true,
              then: string()
                .trim()
                .min(2, 'Please provide a longer description.')
                .max(100, 'Please shorten your description.')
                .required('Required'),
              otherwise: string().default('')
            }),
          transportColor: string()
            .trim()
            .when('isCurbsidePickup', {
              is: true,
              then: string()
                .trim()
                .matches(
                  /^(.{2,20}){0,1}$/,
                  'Color must be between 2 and 20 letters.'
                ),
              otherwise: string().default('')
            })
        }
      : {})
  }
}
