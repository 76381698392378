import * as React from 'react'

import { onEnterOrSpace } from '@local/do-secundo-key-handler'
import { getStyle } from '@local/do-secundo-style-helpers'
import { FormShapeProps, FieldShapeProps } from '../form-shared-types'
import styles from './Checkbox.module.css'

// TODO refactor into enum and rename style prop into variant
const checkboxStyles = Object.freeze({
  DEFAULT: 'default',
  TAG: 'tag',
  ICON: 'icon', // to use icon style you must also pass in an icon prop
  TERMS_MODAL: 'termsModal'
})

interface CheckboxProps {
  onSelect?(event?: React.ChangeEvent<HTMLInputElement>): void
  field: FieldShapeProps
  form: FormShapeProps
  disabled?: boolean
  id: string
  label: React.ReactNode
  icon?: React.ComponentType<{ className: string }>
  style: string
}

const Checkbox = ({
  onSelect,
  field: { name, value, onChange, onBlur },
  form: { setFieldValue, setFieldTouched },
  disabled,
  id,
  label,
  icon: Icon,
  style = checkboxStyles.DEFAULT,
  ...props
}: CheckboxProps) => {
  const selectedStyle: string = getStyle(style, checkboxStyles)

  return (
    <div
      data-testid='checkbox-full'
      className={styles[`${selectedStyle}InputGroup` as keyof {}]}
    >
      <input
        data-testid='checkbox-input'
        className={styles[`${selectedStyle}Input` as keyof {}]}
        name={name}
        id={id}
        type='checkbox'
        value={value}
        checked={value}
        onChange={(...a) => {
          if (onSelect) {
            onSelect(...a)
            return
          }
          setFieldTouched(name, true)
          onChange(...a)
        }}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
      />
      {Icon && <Icon className={styles.icon} />}
      <label
        data-testid='checkbox-data'
        className={styles[`${selectedStyle}Label` as keyof {}]}
        tabIndex={0}
        onKeyDown={onEnterOrSpace(() => {
          if (!disabled) {
            if (onSelect) {
              onSelect()
              return
            }
            setFieldValue(name, !value)
          }
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export { Checkbox, CheckboxProps, checkboxStyles }
