import React from 'react'
import PropTypes from 'prop-types'
import { Dollars } from '@local/do-secundo-dollars'
import { PriceTransparencyDisplay } from '@local/do-secundo-price-transparency-display'
import cx from 'classnames'

import styles from './MenuItemPrice.module.css'
import { useVariant } from '../../../../client/hooks/use-variant'
import { XP_PRICE_TRANSPARENCY } from '../../../../client/components/ExperimentsProvider/experiment-definitions'

export const MenuItemPrice = ({
  price,
  usesFractionalQuantity = false,
  unitOfMeasure = '',
  shouldRenderSchema
}) => {
  const priceTransparencyVariant = useVariant(
    XP_PRICE_TRANSPARENCY.experimentName
  )
  const priceTransparencyEnabled = priceTransparencyVariant === 1

  return (
    <span
      data-testid='menu-item-price'
      className={cx(styles.price, 'type-default')}
      itemProp={shouldRenderSchema && 'offers'}
      itemScope={shouldRenderSchema}
      itemType={shouldRenderSchema && 'https://schema.org/Offer'}
    >
      {priceTransparencyEnabled ? (
        <PriceTransparencyDisplay price={price} />
      ) : (
        <Dollars amount={price} />
      )}
      {usesFractionalQuantity && `/${unitOfMeasure.toLowerCase()}`}
    </span>
  )
}

MenuItemPrice.propTypes = {
  price: PropTypes.number.isRequired,
  usesFractionalQuantity: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  shouldRenderSchema: PropTypes.bool
}
