import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { MessageError } from '@toasttab/do-secundo-message-error'
import { ErrorComponent } from '@local/do-secundo-error'
import { Modal } from '@local/do-secundo-modal'

import {
  CriticalErrorModal,
  ExperiencingIssuesModal,
  OnlineOrderingDeliveryDisabledModal,
  OnlineOrderingDisabledModal,
  TimeChangedModal,
  UnavailableModal
} from './CheckoutErrorModals'
import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'

export const CRITICAL_ERROR = 'CRITICAL_ERROR'
export const INVALID_CREDIT_CARD = 'INVALID_CREDIT_CARD'
export const ONLINE_ORDERING_DELIVERY_DISABLED = 'OO_DELIVERY_DISABLED'
export const ONLINE_ORDERING_DISABLED = 'OO_DISABLED'
export const ORDER_TIME_CHANGED = 'ORDER_TIME_CHANGED'
export const ORDER_TIME_UNAVAILABLE = 'ORDER_TIME_UNAVAILABLE'
export const UNSUPPORTED_PROMO_CODE = 'UNSUPPORTED_PROMO_CODE'
export const AUTOFIRE_OFFLINE = 'OO_AUTOFIRE_OFFLINE'

const errorsMaps = (errors) =>
  errors.map((msg) => {
    // put all matching errors codes here
    if (msg.match(/reasonCode=provider\.phone_number\.invalid/)) {
      return 'The phone number provided is not recognized.'
    } else if (
      msg.match(
        /(provider\.unknown|toast\.3pd_disabled|toast\.provider_disabled|toast\.invalid_config|toast\.internal_error|toast\.provider_error|restaurant\.provider_disabled|provider\.pickup_address\.invalid|provider\.pickup_address\.unserved)/
      )
    ) {
      return 'Delivery is currently unavailable.'
    } else if (msg.match(/provider\.dropoff_address\.invalid/)) {
      return 'The address provided is not recognized.'
    } else if (msg.match(/provider\.dropoff_address\.unserved/)) {
      return 'The address provided is not in range.'
    } else if (msg.match(/provider\.order_value\.too_small/)) {
      return 'Order total has not met the minimum for delivery.'
    } else if (msg.match(/provider\.order_value\.too_big/)) {
      return 'Order total is above the maximum allowed for delivery.'
    } else if (msg.match(/provider\.unavailable_at_time/)) {
      return 'Delivery is unavailable for your selected time.'
    } else if (msg.match(/provider\.invalid_tip_amount/)) {
      return msg.match(/reasonMsg=([^&]+)\)/)[1]
    }

    // If we can't parse return empty string. If the error is empty we will
    // just display the raw error message.
    return ''
  })

export const INVALID_CREDIT_CARD_MESSAGE =
  'Please check your card information and re-submit.'

/*
 * Temporary until we get structured data back. Parse out any
 * raw messages and format to be more human readable for the guest.
 */
const formatErrorMsg = (error) => {
  if (!error) return
  const newMessage = {}
  // Delivery provider errors
  if (error.message.match(/^\[UnavailabilityReason/)) {
    // check for multiple errors
    const errorsRaw = error.message.match(/^\[(.*)\],?$/)[1] || ''
    const errors = errorsRaw.split(/\),\s*/)

    if (errors) {
      const errorMsgs = errorsMaps(errors)
      const msg = errorMsgs ? errorMsgs.join(' ') : ''
      const trimmedMsg = msg.trim()
      if (msg.toLowerCase().match(/tip/)) {
        newMessage.message = trimmedMsg
        newMessage.retryMessage = 'Please adjust tip amount and try again.'
        return newMessage
      } else if (trimmedMsg) {
        newMessage.message = trimmedMsg
        return newMessage
      }
    }
  } else if (error.code === CRITICAL_ERROR) {
    newMessage.message = "We're having trouble placing the order."
    newMessage.retryMessage = 'Submit order to try again.'
  } else if (error.code === UNSUPPORTED_PROMO_CODE) {
    newMessage.message = 'You have already redeemed this one-time promotion.'
    newMessage.retryMessage = 'Please remove the promo code and try again.'
  }
  return newMessage
}

const hasMappedErrors = (error) => {
  if (error && error.message.match(/^\[UnavailabilityReason/)) {
    // check for multiple errors
    const errorsRaw = error.message.match(/^\[(.*)\],?$/)[1] || ''
    return errorsMaps(errorsRaw.split(/\),\s*/)).filter((n) => n).length > 0
  }
  return false
}

export const CheckoutError = ({
  error,
  loading,
  onSubmit,
  SubmitCount,
  whiteLabelName,
  restaurantPhone
}) => {
  const [displayError, setDisplayError] = useState(true)
  useEffect(() => {
    setDisplayError(true)
  }, [error])

  const formattedError = formatErrorMsg(error)
  if (loading || !error) return null

  const code = error.cartUpdatedCode || error.code

  if (code === ORDER_TIME_UNAVAILABLE) {
    return <UnavailableModal />
  }

  if (code === ORDER_TIME_CHANGED) {
    return <TimeChangedModal onSubmit={onSubmit} loading={loading} />
  }

  if (code === ONLINE_ORDERING_DELIVERY_DISABLED) {
    return (
      displayError && (
        <OnlineOrderingDeliveryDisabledModal
          onClick={() => {
            setDisplayError(false)
          }}
        />
      )
    )
  }

  if (code === ONLINE_ORDERING_DISABLED) {
    return (
      displayError && (
        <OnlineOrderingDisabledModal onClick={() => setDisplayError(false)} />
      )
    )
  }

  if (code === INVALID_CREDIT_CARD) {
    return (
      displayError && (
        <Modal responsive={false}>
          <div className='p-8'>
            <MessageError
              header={`We couldn't process your order`}
              message={INVALID_CREDIT_CARD_MESSAGE}
              buttonPrimary={{
                label: 'Got it',
                onClick: () => setDisplayError(false)
              }}
            />
          </div>
        </Modal>
      )
    )
  }

  // The following errors display more info to be read out and should not
  // be displayed in the catch all since it does not display error info
  if (code === UNSUPPORTED_PROMO_CODE || hasMappedErrors(error))
    return <ErrorComponent error={formattedError} scrollTo />

  if (SubmitCount === 1) {
    return <CriticalErrorModal show />
  }

  return (
    <ExperiencingIssuesModal
      show
      autofireOffline={code === AUTOFIRE_OFFLINE}
      whiteLabelName={whiteLabelName}
      restaurantPhone={restaurantPhone}
    />
  )
}

CheckoutError.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}
