import * as React from 'react'
import cx from 'classnames'

import { MenusNav } from '../MenusNav/MenusNav'
import { MenuSelector } from '../MenuSelector/MenuSelector'
import { ModeLink } from '../ModeRouter/utils'
import { useVariant } from '../../hooks/use-variant'
import { XP_COMBINED_MENU_GROUPS } from '../ExperimentsProvider/experiment-definitions'
import { BirdsEyeViewHeader } from './BirdsEyeViewHeader'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'

import SearchListIcon from '../../assets/magnifying-glass-list.svg'
import styles from './RestaurantHeader.module.css'

export const MenuNavMobile = ({
  stickyRef,
  showStickyShadow,
  activeMenu,
  withHeader,
  menus,
  activeItem
}) => {
  return (
    <div
      data-testid='birds-eye-view-navigation-container'
      ref={stickyRef}
      className={cx(styles.sticky, {
        [styles.stickyShadow]: showStickyShadow
      })}
    >
      <div className={`${styles.pageWidth} flex pr-5 pl-0`}>
        {withHeader && <BirdsEyeViewHeader activeMenu={activeMenu} />}
        <div
          className={cx(styles.navContainer, {
            'pl-5 pr-0': withHeader
          })}
        >
          {!withHeader && (
            <ModeLink
              data-testid='birds-eye-view-menu-page'
              mode='search'
              className='flex items-center'
            >
              <SearchListIcon
                data-testid='search-list-icon'
                className='w-12 pl-5 mr-3 fill-current text-primary-75'
              />
            </ModeLink>
          )}
          <MenusNav
            menus={menus}
            activeItem={activeItem}
            activeMenuGroups={activeMenu.groups}
          />
        </div>
      </div>
    </div>
  )
}

export const MenuNavDesktop = ({
  stickyRef,
  showStickyShadow,
  withHeader,
  activeMenu,
  activeItem,
  menus
}) => {
  return (
    <div
      ref={stickyRef}
      data-testid='menu-navigation-container'
      role='group'
      aria-label='menu groups'
      className={cx(styles.sticky, styles.stickyMinHeight, {
        [styles.stickyShadow]: showStickyShadow
      })}
    >
      <div className={cx(styles.pageWidth, 'flex')}>
        <div
          className={cx(styles.selector, 'w-full', {
            hidden: !withHeader
          })}
        >
          <MenuSelector activeMenu={activeMenu} />
        </div>
        <div
          className={cx(styles.navContainer, {
            'pt-0': !withHeader
          })}
        >
          <MenusNav
            menus={menus}
            activeItem={activeItem}
            activeMenuGroups={activeMenu.groups}
          />
        </div>
      </div>
    </div>
  )
}
export const MenuNavigationModule = ({
  stickyRef,
  showStickyShadow,
  moreThanOneMenu,
  activeMenu,
  menus,
  activeItem
}) => {
  const combinedMenuGroupsVariant = useVariant(
    XP_COMBINED_MENU_GROUPS.experimentName
  )
  const COMBINED_MENU_GROUPS_ENABLED = combinedMenuGroupsVariant === 1

  const { isMobile } = useGetWindowSize()

  const sharedProps = {
    withHeader: moreThanOneMenu && !COMBINED_MENU_GROUPS_ENABLED,
    stickyRef,
    activeItem,
    activeMenu,
    menus,
    showStickyShadow
  }
  return isMobile ? (
    <MenuNavMobile {...sharedProps} />
  ) : (
    <MenuNavDesktop {...sharedProps} />
  )
}
