import React from 'react'
import PropTypes from 'prop-types'

import styles from './ServicePeriods.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { formatHours } from '../../../utils/restaurant-schedule-helpers'

export const ServicePeriods = ({ servicePeriods = [] }) => {
  const {
    restaurantInfo: { data, loading, error }
  } = useRestaurant()

  if (loading || error) return null

  const {
    restaurant: { timeZoneId }
  } = data

  if (!servicePeriods.length > 0) return 'closed'

  return (
    <ul className={styles.hoursList}>
      {servicePeriods.map(({ startTime, endTime }, index) => (
        <li key={index}>
          {`${formatHours(startTime, timeZoneId, false)} - ${formatHours(
            endTime,
            timeZoneId
          )}`}
        </li>
      ))}
    </ul>
  )
}

ServicePeriods.propTypes = {
  servicePeriods: PropTypes.array
}
