import * as React from 'react'
import {
  checkIfSequenceStrategy,
  getModifierGroupPricingData,
  PRICING_STRATEGIES
} from '@local/do-secundo-pricingutility'
import {
  getModifierSizePrice,
  checkIfDefaultOptionsChargePrice
} from '@local/do-secundo-pricingutility'

export const ModifierGroupPricingContainer = ({
  component: Component,
  modifierGroup,
  itemModifierGroups,
  itemSize,
  transformedMenuData,
  ...otherProps
}) => {
  const { guid } = modifierGroup

  const defaultOptionsChargePrice = checkIfDefaultOptionsChargePrice(
    transformedMenuData.modifierGroupOptionsMap[guid]?.[0]
      ?.defaultOptionsChargePrice
  )

  const [selectedModifiersGroup] = itemModifierGroups.filter(
    ({ guid: selectedModifierGroupGuid }) => selectedModifierGroupGuid === guid
  )
  const { pricingStrategy, modifierGroupPrice } = getModifierGroupPricingData(
    { ...transformedMenuData, itemSize },
    selectedModifiersGroup,
    guid
  )

  const isSequencePricing = checkIfSequenceStrategy(pricingStrategy)
  const isSizePricing = pricingStrategy === PRICING_STRATEGIES.SIZE_PRICE

  const { selectedSizeBasedPrice, minPrice } = isSizePricing
    ? getModifierSizePrice(
        transformedMenuData.modifierGroupOptionsMap[guid]?.[0],
        itemSize
      )
    : {}

  const childProps = {
    defaultOptionsChargePrice,
    modifierGroup,
    modifierGroupPrice,
    selectedSizeBasedPrice,
    minPrice,
    isSequencePricing,
    ...otherProps
  }
  return <Component {...childProps} />
}
