import React from 'react'
import PropTypes from 'prop-types'

import { Dollars } from '@local/do-secundo-dollars'
import { Notification } from '@local/do-secundo-notification'

import { ApprovalRuleShape } from '../approval-rule-helpers'

export const DeliveryRule = ({ rule, rectifyLink }) => {
  return (
    <Notification
      severity='warning'
      isActionable={Boolean(rectifyLink)}
      actionLink={rectifyLink}
      actionContent='Add to order'
    >
      You’re{' '}
      <span data-testid='required-adjustment'>
        <Dollars amount={rule.requiredAdjustment} />
      </span>{' '}
      away from the{' '}
      <span data-testid='threshold-amount'>
        <Dollars amount={rule.thresholdAmount} />
      </span>{' '}
      required delivery minimum.
    </Notification>
  )
}

DeliveryRule.propTypes = {
  rectifyLink: PropTypes.string,
  rule: ApprovalRuleShape.isRequired
}
