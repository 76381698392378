import { useEffect } from 'react'

import { useExperiments } from '../components/ExperimentsProvider/ExperimentsProvider'

export const useVariant = (experimentKey) => {
  const { variant, getVariantAndTrack = () => {} } =
    useExperiments(experimentKey)
  useEffect(() => {
    getVariantAndTrack()
  }, [getVariantAndTrack])
  return variant
}
