import React, { useState, useCallback, useMemo, MouseEventHandler } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'
import { InfoIcon } from '@toasttab/buffet-pui-icons'

import styles from './TermsModal.module.css'

export const SAVE_CARD = 'saveCard'
export const LOYALTY = 'loyalty'
export const CONTACTLESS = 'contactless'
export const TFG = 'TFG'
export const CHARGE_INFO = 'chargeInfo'
export const PASSWORDLESS_ACCOUNT = 'passwordlessAccount'

export const termsModalEnums = [
  SAVE_CARD,
  LOYALTY,
  CONTACTLESS,
  TFG,
  CHARGE_INFO,
  PASSWORDLESS_ACCOUNT
]

interface getTextFromTermsEnumResult {
  responsive: boolean
  header: String
  body: JSX.Element
}

export const getTextFromTermsEnum = (
  terms: String
): getTextFromTermsEnumResult | undefined => {
  switch (terms) {
    case SAVE_CARD:
      return {
        responsive: false,
        header: 'Remember This Card',
        body: (
          <div className={styles.body}>
            By selecting “Remember this card” and clicking on “Save” you are
            authorizing Toast, Inc. to store your credit card information
            provided above (the “Card”), and to charge your Card for future
            purchases initiated by you. You may update your Card information or
            cancel this authorization any time via the “My Account” page of the
            Toast Online Ordering website. This authorization will remain in
            effect until updated or canceled.
          </div>
        )
      }
    case LOYALTY:
      return {
        responsive: false,
        header: 'Joining Loyalty Rewards',
        body: (
          <div className={styles.body}>
            You agree to join this restaurant’s rewards program and to be
            contacted by them via your email and/or phone number as part of the
            program. Msg & data rates may apply.
            <br />
            Rewards info is subject to{' '}
            <a
              href='https://pos.toasttab.com/terms-of-service#diner-tos'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='link-terms-of-service'
            >
              Toast Guest Terms of Service
            </a>{' '}
            and{' '}
            <a
              href='https://pos.toasttab.com/privacy'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='link-privacy'
            >
              Privacy Statement.
            </a>{' '}
            Rewards info received by this restaurant from Toast is managed by
            the restaurant and subject to its privacy policies and rewards
            terms.
          </div>
        )
      }
    case CONTACTLESS:
      return {
        responsive: false,
        header: 'Request contactless delivery',
        body: (
          <div className={styles.body}>
            <p>
              Get your order in a way that promotes safety for you and your
              delivery person with a no-contact option.
            </p>
            <h3 className={styles.termsListHeader}>
              1. Add a note to your delivery instructions
            </h3>
            <p>
              Let your delivery person know where you would like your order to
              be placed for pickup. Try to be as specific as possible.
            </p>
            <h3 className={styles.termsListHeader}>
              2. Be on the lookout for a call
            </h3>
            <p>
              Your delivery person may use the phone number you provided to get
              in touch when they arrive with your food.
            </p>
          </div>
        )
      }
    case CHARGE_INFO:
      return {
        responsive: false,
        header: 'Toast Online Ordering Fee',
        body: (
          <div className={styles.body}>
            This charge includes a $0.99 Toast Online Ordering Fee that helps us
            operate our platform. This fee and any other service charges set by
            the restaurant are included in the subtotal above.
          </div>
        )
      }
    case TFG:
      return {
        responsive: false,
        header: 'Support & Give',
        body: (
          <div className={styles.body}>
            Funds go to the restaurant to give to their chosen cause.
            Contributions are subject to the restaurant’s credit card processing
            fee. Toast does not endorse any individual cause or verify
            organizations chosen by restaurants.{' '}
            <a
              href='https://pos.toasttab.com/toast-for-good-terms'
              rel='noopener noreferrer'
              target='_blank'
              className='tfgTerms'
            >
              Full program terms here.
            </a>
          </div>
        )
      }
    case PASSWORDLESS_ACCOUNT:
      return {
        responsive: false,
        header: 'Create a Toast Account',
        body: (
          <div className={styles.body}>
            By selecting “Create a Toast Account for a faster checkout next
            time” and clicking on “Submit” you are authorizing Toast, Inc. to
            store your personal contact information provided above (the “Your
            Information” and "Delivery Info") and your credit card information
            provided above (the “Card”), and to charge your Card for future
            purchases initiated by you. You may update your Card information or
            cancel this authorization any time via the “My Account” page of the
            Toast Online Ordering website. This authorization will remain in
            effect until updated or canceled. By creating a Toast Account you
            agree to the Toast{' '}
            <a
              href='https://pos.toasttab.com/terms-of-service#diner-tos'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='link-terms-of-service'
            >
              Diner Terms of Service
            </a>
            .
          </div>
        )
      }
    default:
      break
  }
}

export interface TermsModalProps {
  onClose: Function
  terms: string
}

export const TermsModal = ({ onClose, terms }: TermsModalProps) => {
  const { header, body, responsive } = getTextFromTermsEnum(terms) || {}

  if (!header || !body) {
    return null
  }

  return (
    <Modal header={header} onClose={onClose} responsive={responsive}>
      {body}
    </Modal>
  )
}

interface useTermsModalProps {
  terms: string
  show: boolean
}

export const useTermsModal = ({ terms, show = false }: useTermsModalProps) => {
  const [showModal, setShowModal] = useState(show)

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return useMemo(
    () => ({
      openModal: handleOpen,
      TermsModal: (props: { terms?: string; onClose?: Function }) =>
        showModal && (
          <TermsModal terms={terms} onClose={handleClose} {...props} />
        )
    }),
    [handleClose, handleOpen, showModal, terms]
  )
}

interface LearnMoreButtonProps {
  onClick: MouseEventHandler
  linkText: string
}

export const LearnMoreButton = ({
  onClick,
  linkText = 'Learn More',
  ...props
}: LearnMoreButtonProps) => {
  return (
    <button
      type='button'
      className={styles.infoButton}
      onClick={onClick}
      {...props}
    >
      <span className='flex items-center cursor-pointer'>
        <InfoIcon className={styles.infoImage} />
        {linkText}
      </span>
    </button>
  )
}

LearnMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  linkText: PropTypes.string
}
