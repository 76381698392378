import { useEffect } from 'react'
import { lifecycle } from '../../utils/lifecycle'

export const TriggerRoutechange = ({ location }) => {
  const { pathname } = location
  useEffect(() => {
    lifecycle.trigger('routechange', { pathname })
  }, [pathname])
  return null
}
