import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { Notification } from '@local/do-secundo-notification'
import { useNotInDeliveryAreaWarning } from './use-not-in-delivery-area-warning'

export const NotInDeliveryAreaWarning = ({
  severity,
  isActionable,
  padTop = false
}) => {
  const [isModalShowing, setIsModalShowing] = useState(false)

  const handleAction = useCallback(() => {
    setIsModalShowing(true)
  }, [setIsModalShowing])
  const handleClose = useCallback(() => {
    setIsModalShowing(false)
  }, [setIsModalShowing])

  const { hasWarning } = useNotInDeliveryAreaWarning()

  if (!hasWarning) {
    return null
  }

  return (
    <>
      <Notification
        padTop={padTop}
        severity={severity}
        isActionable={isActionable}
        onAction={handleAction}
        actionContent='Edit delivery address'
      >
        You’re out of range for delivery. To place an order, update your
        delivery address.
      </Notification>
      {isModalShowing && <FulfillmentSelectorModal onClose={handleClose} />}
    </>
  )
}

NotInDeliveryAreaWarning.propTypes = {
  severity: PropTypes.oneOf(['error', 'warning']),
  isActionable: PropTypes.bool,
  padTop: PropTypes.bool
}
