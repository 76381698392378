import { useMemo } from 'react'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const useRestaurantSchedule = () => {
  const { restaurantInfo } = useRestaurant()
  const { data } = restaurantInfo

  return useMemo(() => {
    const scheduleMap = data?.restaurant?.schedule?.upcomingSchedules.reduce(
      (acc, schedule) => ({
        ...acc,
        [schedule.behavior]: schedule
      }),
      {}
    )
    return { ...restaurantInfo, scheduleMap }
  }, [restaurantInfo, data])
}
