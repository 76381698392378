import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import { IfAuthenticated } from '../IfAuthenticated/IfAuthenticated'
import { useGetCart } from '../CartQuery/CartQuery'
import { Drawer } from '../Drawer/Drawer'
import { NavigationDrawerHeader } from './NavigationDrawerHeader/NavigationDrawerHeader'
import { FlagOn, FF } from '@local/do-secundo-feature-flag'
import { Link } from 'react-router-dom'
import { Anchor } from '../Anchor/Anchor'
import { GiftCardLinks } from './GiftCardLinks/GiftCardLinks'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'

import styles from './NavigationDrawer.module.css'
import { ModeLink } from '../ModeRouter/utils'
import {
  useIsPasswordlessAuthEnabled,
  useLogout
} from '@local/do-secundo-passwordless-authentication'
import { NavigationDrawerHeaderUnified } from './NavigationDrawerHeader/NavigationDrawerHeaderUnified'

export const NavigationDrawer = ({ onClose }) => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const { getRestaurantPath } = useRestaurant()
  const { cart } = useGetCart()
  const logout = useLogout()

  const logoutAndTrack = async () => {
    const logoutInput = {
      variables: {
        input: {
          cart
        }
      }
    }
    await logout(logoutInput)
    onClose()
  }

  const header = PASSWORDLESS_ENABLED ? (
    <NavigationDrawerHeaderUnified onClose={onClose} />
  ) : (
    <NavigationDrawerHeader onClose={onClose} />
  )

  return (
    <Drawer
      header={header}
      onClose={onClose}
      footer={
        <a
          href='https://www.toasttab.com/local'
          aria-label='Toast discover page'
          className='w-full text-center'
        >
          <PoweredByToast className={styles.poweredBy} />
        </a>
      }
    >
      <ul className={styles.list}>
        <FlagOn name={FF.BIRDS_EYE_VIEW}>
          <li className={styles.mobileOnly}>
            <ModeLink
              data-testid='nav-drawer-explore-the-menu'
              className={styles.listItem}
              mode='search'
              onClick={onClose}
            >
              Explore the Menu
            </ModeLink>
          </li>
        </FlagOn>
        <FlagOn name={FF.EDIT_MODE}>
          <ModeLink
            data-testid='performance-audit'
            className={styles.listItem}
            onClick={onClose}
            mode='performance'
          >
            Measure menu performance
          </ModeLink>
        </FlagOn>
        <IfAuthenticated>
          <li>
            <Link
              data-testid='nav-drawer-my-orders'
              className={cx(styles.listItem, 'type-default')}
              to={getRestaurantPath('orders')}
              onClick={onClose}
            >
              My Orders
            </Link>
          </li>
          <li>
            <Link
              data-testid='nav-drawer-my-account'
              className={cx(styles.listItem, 'type-default')}
              to={getRestaurantPath('account')}
              onClick={onClose}
            >
              My Account
            </Link>
          </li>
        </IfAuthenticated>
        <GiftCardLinks />
        <li>
          <Anchor
            data-testid='nav-drawer-link-privacy'
            className={cx(styles.listItem, 'type-default')}
            href='https://pos.toasttab.com/privacy'
            target='_blank'
          >
            Privacy Statement
          </Anchor>
        </li>
        <li>
          <Anchor
            data-testid='nav-drawer-link-terms'
            className={cx(styles.listItem, 'type-default')}
            href='https://pos.toasttab.com/terms-of-service/#diner-tos'
            target='_blank'
          >
            Diner Terms of Service
          </Anchor>
        </li>

        <IfAuthenticated>
          <li>
            <button
              data-testid='nav-drawer-logout'
              className={cx(styles.listItem, 'type-default')}
              onClick={logoutAndTrack}
            >
              Log Out
            </button>
          </li>
        </IfAuthenticated>
      </ul>
    </Drawer>
  )
}

NavigationDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
}
