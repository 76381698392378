import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'

import { Field, connect } from 'formik'

import { stripDeliveryInfo } from '../../../utils/address-helpers'
import { byGuid } from '../../../utils/find-utils'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import {
  deliveryInfoAddress2Schema,
  deliveryInfoNotesSchema
} from '../../../utils/form-schemas'

import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { Fieldset } from '@local/do-secundo-fieldset'
import { NotInDeliveryAreaWarning } from '../../NotInDeliveryAreaWarning/NotInDeliveryAreaWarning'
import { Progress } from '@local/do-secundo-progress'
import { Radio } from '@local/do-secundo-form'
import { NewAddress } from './NewAddress'
import { SavedAddressPicker } from './SavedAddressPicker'

import styles from '../CheckoutForm.module.css'
import { CannedDeliveryInstructions } from './CannedDeliveryInstructions'
import { AlcoholDeliveryInstructions } from './AlcoholDeliveryInstructions'
import { useCartContainsAlcohol } from './use-cart-contains-alcohol'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

export const DELIVERY_INFO_TYPES = {
  NEW: 'NEW',
  SAVED: 'SAVED'
}

const ComponentImpl = ({ formik: { values }, savedAddresses = [] }) => {
  const fulfillmentContext = useFulfillment()

  const containsAlcohol = useCartContainsAlcohol()
  const ALCOHOL_ONBOARDING_ENABLED = useFlag(FF.ALCOHOL_ONBOARDING)

  if (
    fulfillmentContext.diningOptionBehavior !== DINING_OPTION_BEHAVIORS.DELIVERY
  ) {
    return null
  }

  return (
    <Fieldset label='Delivery Info' collapsable id='delivery_info_fields'>
      {fulfillmentContext.updating ? (
        <Progress />
      ) : (
        <>
          {savedAddresses.length > 0 && (
            <div className={styles['radio-tabs']}>
              <Field
                data-testid='delivery-info-type-saved'
                style='tab'
                component={Radio}
                name='deliveryInfoType'
                id='delivery_info_type_saved'
                label='Saved Addresses'
                value={DELIVERY_INFO_TYPES.SAVED}
              />
              <Field
                data-testid='delivery-info-type-new'
                style='tab'
                component={Radio}
                name='deliveryInfoType'
                id='delivery_info_type_new'
                label='New Address'
                value={DELIVERY_INFO_TYPES.NEW}
              />
            </div>
          )}
          {values.deliveryInfoType === DELIVERY_INFO_TYPES.SAVED && (
            <Field
              name='customerDeliveryInfoSavedAddressGuid'
              component={SavedAddressPicker}
              savedAddresses={savedAddresses}
            />
          )}
          {values.deliveryInfoType === DELIVERY_INFO_TYPES.NEW && (
            <NewAddress />
          )}
          {containsAlcohol && ALCOHOL_ONBOARDING_ENABLED && (
            <CannedDeliveryInstructions
              header={'Delivery details'}
              content={<AlcoholDeliveryInstructions />}
            />
          )}
          <NotInDeliveryAreaWarning severity='error' />
        </>
      )}
    </Fieldset>
  )
}

const ValuesShape = PropTypes.shape({
  deliveryInfoType: PropTypes.string
})

const FormikShape = PropTypes.shape({
  values: ValuesShape.isRequired
})

ComponentImpl.propTypes = {
  formik: FormikShape.isRequired,
  savedAddresses: PropTypes.arrayOf(PropTypes.object)
}

export const Component = connect(ComponentImpl)
Component.displayName = 'CheckoutDeliveryInfo'

export const getArgsForSubmit = ({
  featureFlags,
  fulfillmentContext,
  savedAddresses,
  values
}) => {
  if (
    fulfillmentContext.diningOptionBehavior !== DINING_OPTION_BEHAVIORS.DELIVERY
  ) {
    return null
  }

  if (
    values.deliveryInfoType === DELIVERY_INFO_TYPES.NEW &&
    values.customerDeliveryInfoAddress &&
    values.customerDeliveryInfoAddress.address1
  ) {
    const saveAddress = Boolean(values.customerDeliveryInfoSaveAddress)

    return {
      newAddress: {
        deliveryInfo: {
          ...stripDeliveryInfo(values.customerDeliveryInfoAddress),
          address2: values.customerDeliveryInfoAddress2,
          notes: values.customerDeliveryInfoNotes
        },
        saveAddress,
        name: saveAddress ? values.customerDeliveryInfoName : ''
      }
    }
  }

  if (
    values.deliveryInfoType === DELIVERY_INFO_TYPES.SAVED &&
    values.customerDeliveryInfoSavedAddressGuid
  ) {
    const addressGuid = values.customerDeliveryInfoSavedAddressGuid
    const savedAddress = savedAddresses.find(
      (address) => address.guid === addressGuid
    )

    if (
      values.contactlessCustomerDeliveryInfoNotes !==
      savedAddress.deliveryInfo.notes
    ) {
      return {
        newAddress: {
          deliveryInfo: {
            ...stripDeliveryInfo(savedAddress.deliveryInfo),
            notes: values.contactlessCustomerDeliveryInfoNotes
          },
          saveAddress: false,
          name: ''
        }
      }
    }

    return { savedAddress: { addressGuid } }
  }

  return null
}

export const getInitialValues = ({
  featureFlags,
  fulfillmentContext,
  savedAddresses
}) => {
  if (
    fulfillmentContext.diningOptionBehavior !== DINING_OPTION_BEHAVIORS.DELIVERY
  ) {
    return null
  }

  let deliveryInfo = fulfillmentContext.deliveryInfo

  let deliveryInfoType = DELIVERY_INFO_TYPES.NEW
  let customerDeliveryInfoSavedAddressGuid = ''
  let customerDeliveryInfoAddress = {}
  let customerDeliveryInfoAddress2 = ''
  let customerDeliveryInfoNotes = ''

  const savedAddress = fulfillmentContext.savedAddressGuid
    ? savedAddresses.find(byGuid(fulfillmentContext.savedAddressGuid))
    : null

  if (savedAddress) {
    deliveryInfoType = DELIVERY_INFO_TYPES.SAVED
    customerDeliveryInfoSavedAddressGuid = savedAddress.guid
    deliveryInfo = savedAddress.deliveryInfo
  }

  if (deliveryInfo && deliveryInfo.address1) {
    const { address2, notes } = deliveryInfo
    customerDeliveryInfoAddress = {
      ...stripDeliveryInfo(deliveryInfo),
      address2: null,
      notes: null
    }

    customerDeliveryInfoAddress2 = address2 || ''
    customerDeliveryInfoNotes = notes || ''
  }

  return {
    deliveryInfoType,
    customerDeliveryInfoSavedAddressGuid,
    customerDeliveryInfoAddress,
    customerDeliveryInfoAddress2,
    customerDeliveryInfoNotes,
    customerDeliveryInfoSaveAddress: false,
    customerDeliveryInfoName: '',
    contactlessCustomerDeliveryInfoNotes: ''
  }
}

export const getValidationSchema = ({ featureFlags, fulfillmentContext }) => {
  if (
    fulfillmentContext.diningOptionBehavior !== DINING_OPTION_BEHAVIORS.DELIVERY
  ) {
    return null
  }

  return {
    deliveryInfoType: yup
      .string()
      .trim()
      .required('required')
      .oneOf(
        Object.keys(DELIVERY_INFO_TYPES),
        'must be a valid delivery address type'
      ),
    customerDeliveryInfoSavedAddressGuid: yup
      .string()
      .trim()
      .when('deliveryInfoType', {
        is: DELIVERY_INFO_TYPES.SAVED,
        then: yup.string().required('required'),
        otherwise: yup.string().default('')
      }),
    customerDeliveryInfoSaveAddress: yup.bool(),
    customerDeliveryInfoAddress2: deliveryInfoAddress2Schema,
    customerDeliveryInfoNotes: deliveryInfoNotesSchema,
    customerDeliveryInfoName: yup
      .string()
      .trim()
      .when(['deliveryInfoType', 'customerDeliveryInfoSaveAddress'], {
        is: (deliveryInfoType, customerDeliveryInfoSaveAddress) =>
          deliveryInfoType === DELIVERY_INFO_TYPES.NEW &&
          Boolean(customerDeliveryInfoSaveAddress),
        then: yup.string().required('required').max(255),
        otherwise: yup.string().default('')
      }),
    contactlessCustomerDeliveryInfoNotes: deliveryInfoNotesSchema
  }
}
