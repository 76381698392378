import isNil from 'lodash/isNil'

import { getAppliedValue } from './gift-card-helpers'
import { sumByField } from './reducer-utils'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useGiftCardEnabled } from '../components/IfGiftCard/IfGiftCard'
import { useGiftCard } from '../components/GiftCardProvider/GiftCardProvider'
import { useCallback } from 'react'
import { CASH_REDEMPTION_TYPE } from '@local/do-secundo-loyaltysection'
import { Points } from '@local/do-secundo-points'

import * as React from 'react'

const toDollars = (number) => (number ? +(+number).toFixed(2) : 0)

const getGiftCardBalance = ({ giftCard, cart }) => {
  if (cart.order.giftCard) {
    return cart.order.giftCard.appliedBalance || 0
  }
  return getAppliedValue({ giftCard, cart })
}

/**
 * Returns a div describing the loyalty discounts applied to the check
   Includes the points equivalent of the cash amount if there is a cash reward on the check
 */
const getLoyaltyRewardsLabel = (loyaltyDiscounts, conversionRate) => {
  if (!loyaltyDiscounts || loyaltyDiscounts.length === 0) {
    return ''
  }
  const cashDiscount = loyaltyDiscounts.find(
    (discount) => discount.type === CASH_REDEMPTION_TYPE
  )
  const numDiscounts = loyaltyDiscounts.length

  const pointsEquivalent =
    cashDiscount &&
    conversionRate &&
    Math.ceil(cashDiscount.amount / conversionRate)

  return (
    <div data-testid='loyalty-summary'>
      {numDiscounts > 1 ? numDiscounts + ' rewards applied' : 'Reward Applied'}{' '}
      {pointsEquivalent && (
        <>
          ({pointsEquivalent} <Points points={pointsEquivalent} />)
        </>
      )}
    </div>
  )
}

export const useLineItems = ({
  cart,
  showTip,
  showTotal,
  showFundraising,
  deliveryChargeLabel = 'Delivery Cost'
}) => {
  const giftCardEnabled = useGiftCardEnabled()
  const { giftCard } = useGiftCard()
  const GLOBAL_GC_ENABLED = useFlag(FF.GLOBAL_GIFTCARDS)

  return useCallback(
    (tip, roundUp, fundraising) => {
      const hasTip = !isNil(tip)
      const serverTip = cart.order.tip || 0
      const displayTip = hasTip ? tip : serverTip
      let displayTotal = cart.order.total
      let displaySubTotal = cart.order.preDiscountItemsSubtotal || 0
      const displayRoundUp = roundUp
      // Account for case where a tip is specified, but the cart also has a tip
      // eg. When place order fails due to an out-of-stock item
      if (hasTip) {
        displayTotal = displayTotal - serverTip + tip
      }

      if (showFundraising && roundUp > 0) {
        displayTotal = displayTotal + displayRoundUp
      }

      if (fundraising) {
        displayTotal = displayTotal + fundraising
      }

      const {
        order: { discounts = {}, discountsTotal }
      } = cart

      const {
        restaurantDiscount,
        loyaltyDiscounts = [],
        globalReward
      } = discounts
      const hasGlobalReward = Boolean(globalReward)
      const showPromoAmount =
        Boolean(restaurantDiscount) || Boolean(globalReward)

      const showLoyaltyAmount = loyaltyDiscounts.length

      // if a discount has been applied, calculate the displayed subtotal
      // based on the selections' pre discount price
      if (showPromoAmount || showLoyaltyAmount) {
        displaySubTotal = sumByField('preDiscountPrice')(cart.order.selections)
      }

      // since global rewards are not applied to the order, we must do this calculation here
      if (hasGlobalReward && GLOBAL_GC_ENABLED) {
        displayTotal = displayTotal - globalReward.amount
      }

      const giftCardBalance = getGiftCardBalance({ cart, giftCard })
      const showGiftCard = Boolean(giftCardBalance > 0)

      const totals = [
        {
          label: 'Subtotal',
          amount: toDollars(displaySubTotal),
          id: 'subtotal-amount'
        }
      ]
      const loyaltyDiscountTotal = loyaltyDiscounts.reduce(
        (sum, loyaltyDiscount) => sum + loyaltyDiscount.amount,
        0
      )

      if (showLoyaltyAmount) {
        totals.push({
          label: getLoyaltyRewardsLabel(
            loyaltyDiscounts,
            cart.restaurant?.loyaltyConfig?.conversionRate
          ),

          amount: toDollars(-loyaltyDiscountTotal),
          id: 'loyalty-reward-amount'
        })
      }
      if (showPromoAmount) {
        let realDiscountsTotal = discountsTotal
        if (hasGlobalReward && GLOBAL_GC_ENABLED) {
          realDiscountsTotal = globalReward.amount
        }
        totals.push({
          label: 'Promo Applied',
          amount: toDollars(-realDiscountsTotal),
          id: 'promo-code-amount'
        })
      }
      if (
        cart.order.deliveryServiceCharges > 0 ||
        cart.diningOptionBehavior === DINING_OPTION_BEHAVIORS.DELIVERY
      ) {
        totals.push({
          label: deliveryChargeLabel,
          amount: toDollars(cart.order.deliveryServiceCharges),
          id: 'deliveryChargeTotal-amount'
        })
      }
      if (cart?.order?.tfgRoundUpTotal > 0) {
        totals.push({
          label: 'Round Up',
          amount: toDollars(cart.order.tfgRoundUpTotal),
          id: 'Round-up-amount'
        })
      } else if (cart?.order?.fundraisingTotal) {
        totals.push({
          label: 'Support & Give',
          amount: toDollars(cart.order.fundraisingTotal),
          id: 'Fundraising-amount'
        })
      }
      const feesExist =
        cart.order.gratuityServiceCharges > 0 ||
        cart.order.processingServiceCharges > 0 ||
        cart.order.nonDeliveryNonGratuityNonUbpServiceCharges > 0
      if (cart.order.tax > 0 || feesExist) {
        let taxLabel = 'Tax'
        let taxId = 'tax-amount'
        if (feesExist) {
          taxLabel = 'Taxes & Fees'
          taxId = 'tax-and-fees-amount'
        }
        let taxTotal = cart.order.tax
        if (cart.order.nonDeliveryNonGratuityNonUbpServiceCharges) {
          taxTotal += cart.order.nonDeliveryNonGratuityNonUbpServiceCharges
        }
        if (cart.order.gratuityServiceCharges) {
          taxTotal += cart.order.gratuityServiceCharges
        }
        if (cart.order.processingServiceCharges) {
          taxTotal += cart.order.processingServiceCharges
        }
        totals.push({
          label: taxLabel,
          amount: toDollars(taxTotal),
          id: taxId
        })
      }
      if (showTip) {
        totals.push({
          label: 'Tip',
          amount: toDollars(displayTip),
          id: 'tip-amount'
        })
      }
      if (showFundraising && roundUp > 0) {
        totals.push({
          label: 'Round Up',
          amount: toDollars(displayRoundUp),
          id: 'roundUp-amount'
        })
      }
      if (fundraising) {
        totals.push({
          label: 'Support & Give',
          amount: toDollars(fundraising),
          id: 'fundraising-amount'
        })
      }
      if (showTotal) {
        totals.push({
          label: 'Total',
          amount: toDollars(displayTotal),
          id: 'total-order-amount'
        })
      }
      if (giftCardEnabled) {
        if (showGiftCard) {
          totals.push({
            label: 'Gift Card Applied',
            amount: toDollars(-giftCardBalance),
            id: 'gift-card-amount'
          })
        }
        if (displayTotal - giftCardBalance > 0 && showGiftCard) {
          totals.push({
            label: 'Total Remaining',
            amount: toDollars(displayTotal - giftCardBalance),
            id: 'total-order-remaining-amount'
          })
        }
      }

      return totals
    },
    [
      cart,
      deliveryChargeLabel,
      giftCard,
      giftCardEnabled,
      showTip,
      showFundraising,
      showTotal,
      GLOBAL_GC_ENABLED
    ]
  )
}

export const getFeesList = (order) => {
  const fees = [
    {
      name: 'Taxes',
      amount: order.tax,
      id: 'taxes'
    }
  ]
  if (order.gratuityServiceCharges > 0) {
    fees.push({
      name: 'Gratuities',
      amount: order.gratuityServiceCharges,
      id: 'gratuities'
    })
  }
  if (order.nonDeliveryNonGratuityNonUbpServiceCharges > 0) {
    fees.push({
      name: 'Service Fee',
      amount: order.nonDeliveryNonGratuityNonUbpServiceCharges,
      id: 'service-charges'
    })
  }
  if (order.processingServiceCharges > 0) {
    fees.push({
      name: 'Order Processing Fee',
      amount: order.processingServiceCharges,
      id: 'processing-fees'
    })
  }
  return fees
}

export const isDaasCart = (cart) =>
  !!(
    cart &&
    cart.deliveryProviderInfo &&
    cart.deliveryProviderInfo.provider !== 'TOAST'
  )

export const getDaasAwareDiningOption = (cart) => {
  switch (cart.diningOptionBehavior) {
    case DINING_OPTION_BEHAVIORS.TAKE_OUT:
      return DINING_OPTION_BEHAVIORS.TAKE_OUT
    case DINING_OPTION_BEHAVIORS.DELIVERY:
      if (isDaasCart(cart)) {
        return 'TDS'
      }
      return '1PD'
    default:
      return null
  }
}

export const ITEMS_UNAVAILABLE = 'ITEMS_UNAVAILABLE'
export const MODIFICATION_ERROR_SOURCE_REORDER = 'REORDER'

const getUnavailableMessage = (items = []) => {
  const filteredItems =
    items.length && items.filter(({ name }) => Boolean(name))
  if (filteredItems.length > 0) {
    return filteredItems.length === 1
      ? 'The following item is no longer available to order at this time:' // only some items OOS, comes as a warning on ReorderResponse
      : 'The following items are no longer available to order at this time:'
  }

  return items.length === 1
    ? 'An item is no longer available to order at this time and has been removed from your cart'
    : 'Items are no longer available to order at this time and have been removed from your cart'
}

// In some cases we want to overwrite error messages from bff.
export const getErrorMessageByCode = ({ items = [], code }) => {
  const map = {
    NO_AVAILABLE_ITEMS:
      'Your items are no longer available to order at this time.', // all items OOS, comes as ReorderError
    [ITEMS_UNAVAILABLE]: getUnavailableMessage(items)
  }
  return map[code]
}

export const createOutOfStockError = ({ items = [] }) => ({
  items,
  message: getErrorMessageByCode({ items, code: ITEMS_UNAVAILABLE })
})
