import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const useGiftCardEnabled = () => {
  const { restaurantInfo } = useRestaurant()
  const flagOn = useFlag(FF.GIFT_CARD_REDEMPTION)
  const { loading, data, error } = restaurantInfo
  if (loading || error) return false
  const { restaurant } = data
  const {
    giftCardConfig: { redemptionAllowed }
  } = restaurant
  return Boolean(flagOn && redemptionAllowed)
}
