import { useGetCart } from '../components/CartQuery/CartQuery'
import { track } from '@toasttab/do-secundo-analytics'
import { getDaasAwareDiningOption } from './cart-helpers'
import { useCallback } from 'react'
import { usePromoBannersSessionData } from 'cornucopia-apis'

export const getFlagsLoading = (featureFlags) =>
  Object.values(featureFlags).some((value) => value === null)

const getPromoBannerMetrics = (completedOrder, promoCodesSeen) => {
  const { discounts, hasLoyaltyAttached } = completedOrder
  const appliedPromoCode = discounts?.restaurantDiscount?.promoCode
  let hasBannerPromoCode = false

  if (appliedPromoCode) {
    hasBannerPromoCode = Boolean(promoCodesSeen?.[appliedPromoCode])
  }

  return {
    hasBannerPromoCode,
    hasLoyaltyAttached: Boolean(hasLoyaltyAttached),
    promoCode: appliedPromoCode
  }
}

export const useTrackPurchase = () => {
  const { cart, restaurantGuid } = useGetCart()
  const promoBannersSessionData = usePromoBannersSessionData(restaurantGuid)
  const promoCodesSeen = promoBannersSessionData?.promoCodesSeen

  return useCallback(
    (completedOrder) => {
      const daasAwareDiningOptionBehavior = getDaasAwareDiningOption(cart)
      const { hasBannerPromoCode, hasLoyaltyAttached, promoCode } =
        getPromoBannerMetrics(completedOrder, promoCodesSeen)
      const {
        createPasswordlessAccount,
        guid,
        selections,
        tax,
        tip,
        total,
        usedApplePay
      } = completedOrder
      track('Completed Order', {
        id: guid,
        itemCount: selections.length,
        diningOptionBehavior: daasAwareDiningOptionBehavior,
        revenue: total,
        orderTax: tax,
        tip: tip || 0,
        createPasswordlessAccount,
        hasBannerPromoCode,
        hasLoyaltyAttached,
        promoCode,
        usedApplePay
      })
    },
    [cart, promoCodesSeen]
  )
}
