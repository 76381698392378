import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { LoyaltyRewardsEarned } from './LoyaltyContent/LoyaltyRewardsEarned/LoyaltyRewardsEarned'
import { LoyaltyWelcome } from './LoyaltyContent/LoyaltyWelcome/LoyaltyWelcome'
import { LoyaltyContent } from './LoyaltyContent/LoyaltyContent'

import './LoyaltyConfirmation.module.css'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useQuery } from '@apollo/client'
import { GET_COMPLETED_ORDER_LOYALTY_CONFIRMATION } from './LoyaltyConfirmation.graphql'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const LoyaltyConfirmation = ({ orderGuid }) => {
  const { restaurantGuid } = useRestaurant()
  const { user, authenticated } = useAuth()
  const [loyaltyActionTaken, setLoyaltyActionTaken] = useState(false)

  const actionTakenCallback = useCallback(() => {
    setLoyaltyActionTaken(true)
  }, [])

  const { loading, error, data } = useQuery(
    GET_COMPLETED_ORDER_LOYALTY_CONFIRMATION,
    {
      variables: {
        input: {
          orderGuid,
          restaurantGuid
        }
      }
    }
  )

  if (loading || error) return null

  const {
    completedOrder: { hasLoyaltyAttached, customerV2 }
  } = data

  const orderAndCMEmailsMatch = Boolean(
    customerV2 && user && customerV2.email === user.email
  )

  const showElement = orderAndCMEmailsMatch || !authenticated

  return (
    showElement && (
      <>
        {loyaltyActionTaken ? (
          hasLoyaltyAttached ? (
            <LoyaltyRewardsEarned />
          ) : (
            <LoyaltyWelcome orderGuid={orderGuid} />
          )
        ) : (
          <LoyaltyContent
            actionTakenCallback={actionTakenCallback}
            orderGuid={orderGuid}
          />
        )}
      </>
    )
  )
}

LoyaltyConfirmation.propTypes = {
  orderGuid: PropTypes.string.isRequired
}
