import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Button, ButtonVariant } from '@local/do-secundo-button'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import Announcement from '../../assets/announcement.svg'

import styles from './Spotlight.module.css'
import { Modal } from '@local/do-secundo-modal'
import { SmartLinks } from '@local/do-secundo-smart-links'

export const Spotlight = () => {
  const { restaurantInfo } = useRestaurant()
  const [modalShown, setModalShown] = useState(false)
  const { data, loading, error } = restaurantInfo
  const openModal = () => setModalShown(true)
  const closeModal = () => setModalShown(false)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const containerRef = useRef(null)

  const setOverflowingOnResize = useCallback(() => {
    if (!loading && containerRef.current) {
      const { clientWidth, scrollWidth } = containerRef.current
      setIsOverflowing(scrollWidth > clientWidth)
    }
  }, [loading, containerRef])

  useEffect(() => {
    setOverflowingOnResize()
    window.addEventListener('resize', setOverflowingOnResize)
    return () => window.removeEventListener('resize', setOverflowingOnResize)
  }, [setOverflowingOnResize, containerRef])

  if (loading || error) return null
  const {
    restaurant: {
      spotlightConfig: { headerText, bodyText }
    }
  } = data

  if (!headerText) return null

  return (
    <>
      <header className={styles.spotlight} role='banner'>
        <Announcement height='18px' width='15px' />
        <p
          data-testid='spotlight-header-content'
          className={styles.text}
          ref={containerRef}
        >
          {headerText}
        </p>
        {(bodyText || isOverflowing) && (
          <button className={styles.moreLink} onClick={openModal}>
            More
          </button>
        )}
      </header>
      {modalShown && (
        <Modal onClose={closeModal}>
          <div className={styles.modalBody}>
            <div className={styles.announcement}>
              <Announcement width='42px' height='35px' />
            </div>
            <p className={styles.header}>{headerText}</p>
            <p className={styles.body}>
              <SmartLinks text={bodyText} />
            </p>
            <Button onClick={closeModal} variant={ButtonVariant.LINK_WIDE}>
              Back To Menu
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}
