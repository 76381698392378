import React from 'react'
import PropTypes from 'prop-types'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { Anchor } from '../../../../client/components/Anchor/Anchor'

const linkRegExp = /(https?:\/\/[a-z0-9\-\.\/\#\?\=\&]+\b)/i

const embedLinks = (text) => {
  const parts = text.split(linkRegExp)
  return parts.map((part, i) => {
    return (i + 1) % 2 === 0 ? (
      <Anchor href={part} target='_blank' key={part}>
        {part}
      </Anchor>
    ) : (
      part
    )
  })
}

export const SmartLinks = ({ text }) => {
  const smartLinksEnabled = useFlag(FF.SMART_LINKS)
  return smartLinksEnabled ? embedLinks(text) : text
}

SmartLinks.propTypes = {
  text: PropTypes.string.isRequired
}
