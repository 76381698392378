import { setContext } from '@apollo/client/link/context'

import { dataByTypename } from '../../utils/apollo-helpers'
import { namespacedStorage } from '../../utils/namespaced-storage'
import { REFRESH } from './authentication.graphql'
import { ooV2Auth } from './oov2-auth'
import { ResumeSessionError } from './authentication-errors'
import { REMOVE_LOYALTY_REDEMPTION_FROM_CART } from '../cart/cart.graphql'

export const storage = namespacedStorage('auth')

// for passwordless auth and account linking functionality
export const accountLinkingStorage = namespacedStorage('accountLinkingAuth')

export const deleteTokens = (storage = namespacedStorage('auth')) => {
  storage.remove('accessToken')
  storage.remove('refreshToken')
  ooV2Auth.deleteTokens()
}

export const setTokens = (
  { accessToken, refreshToken },
  storage = namespacedStorage('auth')
) => {
  storage.set('accessToken', accessToken)
  storage.set('refreshToken', refreshToken)
}

export const getAuthLink = () =>
  setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =
      storage.get('accessToken') ||
      accountLinkingStorage.get('accessToken') ||
      ''
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'toast-customer-access': token
      }
    }
  })

export const shouldRefreshToken = () => {
  const refreshToken = storage.get('refreshToken')
  if (!refreshToken) {
    deleteTokens()
  }
  return !!refreshToken
}

/**
 * Refreshes customermgmt/password based authentication tokens.
 * Do not use for passwordless.
 *
 * `shouldRefreshToken` should be checked before calling this function,
 * otherwwise an error may be thrown.
 */
export const refreshToken = async (config) => {
  const refreshToken = storage.get('refreshToken')
  if (!shouldRefreshToken()) {
    throw new ResumeSessionError('No tokens to refresh')
  }
  try {
    const resp = await config.client.mutate({
      mutation: REFRESH,
      variables: {
        input: { refreshToken }
      }
    })
    const { AuthenticationResponse: data, RefreshError: error } =
      dataByTypename(resp.data.refresh)

    if (error) {
      deleteTokens()
      throw new ResumeSessionError('Tokens could not be refreshed', error)
    }

    setTokens(data)
    return data
  } catch (error) {
    throw new ResumeSessionError('Error handling refresh', error)
  }
}

/**
 * Removes any discount on the cart with the supplied redemptionGuid, without checking the type of the discount.
 */
const removeAllLoyaltyDiscountsFromCart = async (
  client,
  { redemptionGuid, cartGuid }
) => {
  const response = await client.mutate({
    mutation: REMOVE_LOYALTY_REDEMPTION_FROM_CART,
    variables: {
      input: {
        cartGuid,
        redemptionGuid
      }
    }
  })

  const { CartOutOfStockError } = dataByTypename(
    response.data.removeLoyaltyRedemption
  )

  if (CartOutOfStockError) {
    throw new Error(CartOutOfStockError.message)
  }

  return response
}

export const cleanUpCartData = async (client, { cart }) => {
  if (!cart) return

  const discounts = cart.order?.discounts

  const redemptionGuid = discounts?.loyaltyDiscounts?.at(0)?.guid
  if (redemptionGuid) {
    await removeAllLoyaltyDiscountsFromCart(client, {
      redemptionGuid,
      cartGuid: cart.guid
    })
  }
}
