import React, { useEffect, useState } from 'react'
import { Field, connect } from 'formik'
import PropTypes from 'prop-types'

import { Checkbox, Radio } from '@local/do-secundo-form'
import { Dollars } from '@local/do-secundo-dollars'
import { Fieldset } from '@local/do-secundo-fieldset'
import { TermsModal, TFG } from '@local/do-secundo-terms-modal'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import styles from './Fundraising.module.css'
import { DonationType } from '../../../apollo/generated/TakeoutWebGraphQLOperations'
import { FF } from '@local/do-secundo-feature-flag'

export const Component = connect(
  ({
    cart,
    campaignName = '',
    campaignDescription = '',
    campaignLogoURL = '',
    donationType = DonationType.ROUND_UP,
    donationAmounts = [],
    tip = 0,
    setFieldValue,
    values
  }) => {
    const [shouldShowTerms, editShowTerms] = useState(false)

    const orderTotal = cart.order.total || 0
    const serverTip = cart.order.tip || 0
    const roundUpValue = parseFloat(
      (
        1.0 -
        ((parseFloat(orderTotal - serverTip + tip) * 100) % 100) / 100
      ).toFixed(2)
    )

    useEffect(() => {
      if (donationType === DonationType.ROUND_UP) {
        setFieldValue('roundUpAmount', roundUpValue)
      }
    }, [roundUpValue, setFieldValue, donationType])

    useEffect(() => {
      return () => {
        setFieldValue('isRoundUpForCharity', false)
        setFieldValue('roundUpAmount', 0)
        setFieldValue('fundraisingAmount', 0)
      }
    }, [setFieldValue])

    const Label = () => (
      <span>
        <span className={'flex items-center'}>
          <span className='mr-1'>Round up and give </span>
          <Dollars amount={roundUpValue} />
          <button
            data-testid='tfg-terms-button'
            className={styles.infoButton}
            onClick={() => editShowTerms(!shouldShowTerms)}
            type='button'
          >
            <span className='flex'>
              <InfoIcon className={styles.infoImage} />
            </span>
          </button>
        </span>
      </span>
    )

    return (
      <>
        {shouldShowTerms && (
          <TermsModal onClose={() => editShowTerms(false)} terms={TFG} />
        )}
        <Fieldset
          label='Support & Give'
          collapsable
          id='roundup_fields'
          data-testid='collapsible-roundup-header'
        >
          <div className={styles.restaurantContent}>
            {campaignName && (
              <div className={styles.leftColumn}>
                {campaignName && (
                  <div
                    className={styles.heading}
                    data-testid='name-of-campaign'
                  >
                    {campaignName}
                  </div>
                )}
                {campaignDescription && (
                  <div
                    className={styles.description}
                    data-testid='desc-of-campaign'
                  >
                    {campaignDescription}
                  </div>
                )}
              </div>
            )}
            {campaignLogoURL && (
              <img
                src={campaignLogoURL}
                data-testid='logo-of-campaign'
                alt={`${campaignName} logo`}
                className='w-20 h-20 border border-solid object-cover'
              />
            )}
          </div>
          {donationType === DonationType.ROUND_UP && (
            <div>
              <Field
                data-testid='roundup-checkbox'
                component={Checkbox}
                name='isRoundUpForCharity'
                id='isRoundUpForCharity'
                label={<Label />}
              />
              <Field
                data-testid='roundup-amount'
                type='hidden'
                name='roundUpAmount'
                id='roundUpAmount'
              />
            </div>
          )}
          {donationType === DonationType.FLAT_RATE && (
            <div className='border-0 border-b border-solid border-gray-25 pb-4'>
              <div className='flex mt-4'>
                {donationAmounts.map((amount, index) => (
                  <Field
                    component={Radio}
                    style='chunky'
                    label={<Dollars amount={amount} />}
                    data-testid={'fundraising-' + amount}
                    name='fundraisingAmount'
                    id={`fundraising-${index}`}
                    key={`fundraising-${index}`}
                    value={amount.toFixed(1)}
                    onClick={() => {
                      if (Number(values.fundraisingAmount) === Number(amount)) {
                        setFieldValue('fundraisingAmount', null)
                      } else {
                        setFieldValue('fundraisingAmount', amount)
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </Fieldset>
      </>
    )
  }
)

export const getInitialValues = () => ({ fundraisingAmount: '' })

export const getArgsForSubmit = ({ values, cart, featureFlags }) => {
  const { tfgConfig, fundraisingConfig } = cart.restaurant
  const active = tfgConfig?.active || fundraisingConfig?.active ? true : false
  if (fundraisingConfig?.donationType === DonationType.FLAT_RATE) {
    return {
      fundraisingInput: {
        fundraisingAmount: parseFloat(values.fundraisingAmount) || 0
      }
    }
  }
  const finalRoundUpAmount = (active && parseFloat(values.roundUpAmount)) || 0
  const isRoundUpEnabled = values.isRoundUpForCharity

  // only return via fundraisingInput if feature flag enabled
  if (featureFlags?.[FF.USE_FUNDRAISING_CONFIG]) {
    return {
      fundraisingInput: {
        fundraisingAmount: (isRoundUpEnabled && finalRoundUpAmount) || 0
      }
    }
  }

  return {
    tfgInput: {
      roundUpEnabled: Boolean(active && isRoundUpEnabled),
      estimatedRoundUpValue: (isRoundUpEnabled && finalRoundUpAmount) || 0
    }
  }
}

export const getValidationSchema = () => ({})

Component.propTypes = {
  cart: PropTypes.object.isRequired,
  campaignName: PropTypes.string,
  campaignDescription: PropTypes.string,
  campaignLogoURL: PropTypes.string,
  donationType: PropTypes.string,
  donationAmounts: PropTypes.arrayOf(PropTypes.number),
  tip: PropTypes.number
}
