import React, { useState, useEffect } from 'react'

import { useWindowSize } from '@toasttab/buffet-use-window-size'

export const compareToLineHeight = (height, lineHeight = 20) => {
  return Math.round(height / lineHeight)
}

export const useOverflowActive = (text, ref) => {
  const [overflowActive, setOverflowActive] = useState(false)
  const windowSize = useWindowSize({})

  const isEllipsisActive = React.useCallback((e) => {
    // iOS devices randomly add 1px, rounding here as we don't want to compare
    return (
      compareToLineHeight(e.offsetHeight) < compareToLineHeight(e.scrollHeight)
    )
  }, [])

  useEffect(() => {
    if (text) {
      isEllipsisActive(ref.current)
      setOverflowActive(isEllipsisActive(ref.current))
    }
  }, [windowSize.width, windowSize.height, ref, isEllipsisActive, text])

  return overflowActive
}
