const noDelivery = {
  address1: null,
  address2: null,
  city: null,
  state: null,
  zipCode: null,
  latitude: null,
  longitude: null,
  notes: null,
  __typename: 'DeliveryInfo'
}

const paymentOptionsAllAllowed = {
  __typename: 'CartPaymentOptions',
  atCheckout: [
    {
      __typename: 'CartPaymentOption',
      paymentType: 'CREDIT'
    }
  ],
  uponReceipt: [
    {
      __typename: 'CartPaymentOption',
      paymentType: 'CREDIT'
    },
    {
      __typename: 'CartPaymentOption',
      paymentType: 'CASH'
    }
  ]
}
const cart0Selections = {
  guid: 'CART_GUID',
  order: {
    numberOfSelections: 0,
    selections: [],
    deliveryChargeTotal: 0,
    serviceChargeTotal: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
    discounts: {
      restaurantDiscount: null,
      loyaltyDiscounts: [],
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 0,
    deliveryInfo: noDelivery,
    __typename: 'Order'
  },
  quoteTime: 0,
  paymentOptions: paymentOptionsAllAllowed,
  preComputedTips: [
    {
      percent: 25,
      value: 0,
      __typename: 'PreComputedTip'
    },
    {
      percent: 20,
      value: 0,
      __typename: 'PreComputedTip'
    },
    {
      percent: 18,
      value: 0,
      __typename: 'PreComputedTip'
    },
    {
      percent: 15,
      value: 0,
      __typename: 'PreComputedTip'
    }
  ],
  approvalRules: [],
  fulfillmentType: 'ASAP',
  fulfillmentDateTime: null,
  diningOptionBehavior: 'TAKE_OUT',
  takeoutQuoteTime: 10,
  deliveryQuoteTime: 10,
  deliveryProviderInfo: null,
  cartUpsellInfo: {
    upsellItems: [],
    __typename: 'CartUpsellInfo'
  },
  __typename: 'Cart'
}

export const cart1Selection = {
  guid: 'CART_GUID',
  order: {
    numberOfSelections: 1,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 5,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      restaurantDiscount: null,
      loyaltyDiscounts: [],
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 0,
    deliveryChargeTotal: 0,
    serviceChargeTotal: 0,
    subtotal: 5,
    tax: 0,
    total: 5,
    deliveryInfo: noDelivery,
    __typename: 'Order'
  },
  quoteTime: 0,
  paymentOptions: paymentOptionsAllAllowed,
  preComputedTips: [
    {
      percent: 25,
      value: 1.25,
      __typename: 'PreComputedTip'
    },
    {
      percent: 20,
      value: 1,
      __typename: 'PreComputedTip'
    },
    {
      percent: 18,
      value: 0.9,
      __typename: 'PreComputedTip'
    },
    {
      percent: 15,
      value: 0.75,
      __typename: 'PreComputedTip'
    }
  ],
  approvalRules: [],
  fulfillmentType: 'ASAP',
  fulfillmentDateTime: null,
  diningOptionBehavior: 'TAKE_OUT',
  takeoutQuoteTime: 10,
  deliveryQuoteTime: 10,
  deliveryProviderInfo: null,
  cartUpsellInfo: {
    upsellItems: [],
    __typename: 'CartUpsellInfo'
  },
  __typename: 'Cart'
}
export const cart1SelectionLoyaltyConfig = {
  ...cart1Selection,
  restaurant: {
    loyaltyConfig: {
      conversionRate: 1
    }
  },
  __typename: 'Cart'
}

export const cart1SelectionWithLoyaltyCashDiscount = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      loyaltyDiscounts: [
        {
          guid: 'LOYALTY_GUID',
          amount: 5,
          type: 'LOYALTY_DOLLARS_AMOUNTS',
          __typename: 'LoyaltyDiscount'
        }
      ],
      restaurantDiscount: null,
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 5
  }
}

export const cart1SelectionWithEmptyLoyaltyDiscounts = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      loyaltyDiscounts: [],
      restaurantDiscount: null,
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 5
  }
}

export const cart1SelectionWithNoLoyaltyDiscounts = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      loyaltyDiscounts: null,
      restaurantDiscount: null,
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 5
  }
}
export const cart1SelectionWithNoDiscounts = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {},
    discountsTotal: 5
  }
}
export const cart1SelectionWithLoyaltyBirthdayDiscount = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      loyaltyDiscounts: [
        {
          guid: 'LOYALTY_GUID',
          amount: 5,
          type: 'BIRTHDAY_REWARDS',
          __typename: 'LoyaltyDiscount'
        }
      ],
      restaurantDiscount: null,
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 5
  }
}

export const cart1SelectionWithMultipleLoyaltyDiscounts = {
  ...cart1SelectionLoyaltyConfig,
  order: {
    ...cart1Selection.order,
    selections: [
      {
        guid: 'SELECTION_GUID',
        groupingKey: 'GROUPING_KEY',
        itemGuid: 'ITEM_GUID',
        itemGroupGuid: 'ITEM_GROUP_GUID',
        name: 'name 1',
        preDiscountPrice: 10,
        price: 5,
        quantity: 1,
        modifiers: [],
        usesFractionalQuantity: false,
        fractionalQuantity: {
          unitOfMeasure: 'NONE',
          quantity: 0,
          __typename: 'FractionalQuantity'
        },
        __typename: 'Selection'
      }
    ],
    discounts: {
      loyaltyDiscounts: [
        {
          guid: 'LOYALTY_GUID',
          amount: 5,
          type: 'BIRTHDAY_REWARDS',
          __typename: 'LoyaltyDiscount'
        },
        {
          guid: 'LOYALTY_GUID',
          amount: 5,
          type: 'LOYALTY_DOLLARS_AMOUNTS',
          __typename: 'LoyaltyDiscount'
        }
      ],
      restaurantDiscount: null,
      globalReward: null,
      __typename: 'Discounts'
    },
    discountsTotal: 5
  }
}
export const successAddCashLoyalty = Object.freeze({
  addLoyaltyRedemptionV2: {
    cart: cart1SelectionWithLoyaltyCashDiscount,
    info: null,
    warnings: null,
    __typename: 'CartResponse'
  }
})

export const successAddBirthdayLoyalty = Object.freeze({
  addLoyaltyRedemptionV2: {
    cart: cart1SelectionWithLoyaltyBirthdayDiscount,
    info: null,
    warnings: null,
    __typename: 'CartResponse'
  }
})

export const successRemoveLoyaltyRequest = Object.freeze({
  addLoyaltyRedemptionV2: {
    cart: cart1Selection,
    info: null,
    warnings: null,
    __typename: 'CartResponse'
  }
})

export const successResultLoyaltyInquiry = Object.freeze({
  loyaltyInquiryV2: {
    redemptions: [
      {
        redemptionAmount: 10,
        redemptionGuid: 'LOYALTY_GUID',
        type: 'NAME',
        __typename: 'LoyaltyRedemption'
      }
    ],
    loyaltyAccountIdentifier: 'LOYALTY_ID_GUID',
    __typename: 'LoyaltyInquiryResponse'
  }
})

export const cartOutOfStockError = {
  cart: cart0Selections,
  items: [],
  message: 'Something went wrong with the cart',
  __typename: 'CartOutOfStockError'
}
