import { useEffect, useRef, useState } from 'react'

import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'

export const useNewAddressUpdater = ({ address, address2, notes }) => {
  const { setDeliveryInfo } = useFulfillment()
  const [error, setError] = useState(null)
  const refs = useRef({ setDeliveryInfo, address2, notes })

  useEffect(() => {
    refs.current = { setDeliveryInfo, address2, notes }
  }, [setDeliveryInfo, address2, notes])

  // setDeliveryInfo() is idempotent and therefore safe to call on mount.
  useEffect(() => {
    const currentRefs = refs.current
    const onAddressChange = async (newAddress) => {
      setError(null)
      // dont set the address if not present to avoid updating fulfillment with invalid input
      if (!newAddress.address1) return
      try {
        await currentRefs.setDeliveryInfo({
          savedAddressGuid: '',
          deliveryInfo: {
            ...newAddress,
            address2: currentRefs.address2,
            notes: currentRefs.notes
          }
        })
      } catch (e) {
        setError(e)
      }
    }

    onAddressChange(address)
  }, [address])

  return { error }
}
