import { track } from '@toasttab/do-secundo-analytics'

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoiam9uZGFjayIsImEiOiJjajJtOGl4b2MwMGptMzNxYmhmbGxhN3Z0In0.liPiYKje1XwFoj6RbpHgwQ'

export const geolocationResolvers = {
  Query: {
    async addressSuggestions(_, { inputValue, latitude, longitude }) {
      if (!inputValue) {
        return []
      }

      inputValue = encodeURIComponent(inputValue.replace(';', ''))
      const url = new URL(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?`
      )
      const params = {
        access_token: MAPBOX_ACCESS_TOKEN,
        country: 'us',
        types: 'address',
        language: 'en',
        proximity: `${longitude},${latitude}`
      }

      url.search = new URLSearchParams(params).toString()

      const response = await fetch(url)

      const { features } = await response.json()
      const addresses = features.map(
        ({ center: [longitude, latitude], context = [], address, text }) => {
          const contextMap = context.reduce((acc, ctx) => {
            const [id] = ctx.id.split('.')
            return { ...acc, [id]: ctx.short_code || ctx.text }
          }, {})
          const [, state = null] = contextMap.region.split('-')
          return {
            address1: address ? `${address} ${text}` : text,
            address2: null,
            city: contextMap.locality || contextMap.place || null,
            state,
            zipCode: contextMap.postcode || null,
            latitude,
            longitude,
            notes: null,
            __typename: 'AddressSuggestion'
          }
        }
      )
      track('Mapbox Lookup', {
        mapboxResultCount: addresses && addresses.length ? addresses.length : 0
      })
      return addresses
    }
  }
}
