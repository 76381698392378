import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { Input } from '@local/do-secundo-form'
import { emailSchema } from '../../../../client/utils/form-schemas'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { Notification } from '@local/do-secundo-notification'
import { getRawPhoneNumber } from '@local/do-secundo-form-utils'

export interface AuthFormPhoneAndEmailSubmitValues {
  phoneOrEmail: string
}
export interface AuthFormPhoneAndEmailProps {
  handleSubmit: (e: AuthFormPhoneAndEmailSubmitValues, isPhone: boolean) => void
  initialValues: AuthFormPhoneAndEmailSubmitValues
  validationSchema: Yup.ObjectSchema<
    Yup.Shape<object | undefined, AuthFormPhoneAndEmailSubmitValues>,
    object
  >
  buttonText: string
  includeEmail?: boolean
  handleSubmitError?: (error: { Error: string }) => void
  disclaimer?: React.ReactNode
  hasEmail?: boolean
  testId?: string
}

export const AuthFormPhoneAndEmail = ({
  handleSubmit,
  initialValues,
  validationSchema,
  buttonText,
  handleSubmitError,
  disclaimer,
  testId = 'auth-form'
}: AuthFormPhoneAndEmailProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        let input = ''
        let isPhone = true
        if (emailSchema.isValidSync(values.phoneOrEmail)) {
          input = values.phoneOrEmail
          isPhone = false
        } else {
          input = getRawPhoneNumber(values.phoneOrEmail)
        }
        const formValues = {
          ...values,
          phoneOrEmail: input
        }

        try {
          await handleSubmit(formValues, isPhone)
        } catch (err) {
          setStatus(err)
          handleSubmitError && handleSubmitError(err as { Error: string })
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid, status }) => {
        return (
          <Form data-testid={`${testId}-form`}>
            {status && (
              <Notification severity='error'>{status.message}</Notification>
            )}
            <Field
              component={Input}
              data-testid={`${testId}-phone-email-input`}
              id='phone-email-input'
              label='Phone number or email'
              type={'text'}
              name='phoneOrEmail'
            />

            {disclaimer}
            <Button
              type={ButtonType.SUBMIT}
              variant={ButtonVariant.PRIMARY}
              responsive
              loading={isSubmitting}
              disabled={!isValid}
              data-testid={`${testId}-submit-button`}
            >
              {buttonText}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
