import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { Notification } from '@local/do-secundo-notification'
import {
  useLogin,
  useAuth,
  importSuccessToast
} from '../../../../client/components/AuthProvider/AuthProvider'
import { useImportAccount } from '@local/do-secundo-passwordless-authentication'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { Input } from '@local/do-secundo-form'
import { Password } from '../../../../client/components/Form/Password/Password'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import {
  ModeLink,
  ModeRedirect
} from '../../../../client/components/ModeRouter/utils'
import {
  emailSchema,
  passwordSchema
} from '../../../../client/utils/form-schemas'

export interface ConnectAccountModalProps extends RouteComponentProps<any> {
  onClose: () => void
}

export const ConnectAccountModal = withRouter(
  ({
    onClose,
    location: { state = {}, pathname }
  }: ConnectAccountModalProps) => {
    const { setMfaState, mfaRequiresVerification } = useAuth()
    const handleImportAccount = useImportAccount()
    const {
      login,
      result: { error: loginError, loading }
    } = useLogin()
    let notificationText
    if (state.createAccountRedirect) {
      notificationText =
        'Looks like you already have an account! Enter your password to complete checkout or continue as guest.'
    }

    if (mfaRequiresVerification) {
      return <ModeRedirect pathname={pathname} mode='mfa' />
    }

    return (
      <PoweredByToastModal
        onClose={onClose}
        header={
          <>
            <h2
              data-testid='connect-account-modal-header'
              className={'mx-0 mt-0 mb-3 type-headline-4'}
            >
              Connect account info
            </h2>
            {!notificationText && (
              <p
                data-testid='connect-account-modal-description'
                className={'m-0 type-default'}
              >
                Connect your order history and payment methods to your Toast
                Account by entering your email and password one last time.
              </p>
            )}
          </>
        }
      >
        <Formik
          initialValues={{
            email: state.email || '',
            password: ''
          }}
          validationSchema={Yup.object().shape({
            email: emailSchema,
            password: passwordSchema
          })}
          onSubmit={async (values, { setError }) => {
            const input = {
              variables: { input: { ...values, forAccountLinking: true } }
            }
            const result = await login(input)

            const { MfaChallengeGeneratedResponse, LoginError } =
              dataByTypename(result.data.login)

            if (MfaChallengeGeneratedResponse) {
              setMfaState({
                mfaRequiresVerification: true,
                email: values.email,
                ...result.data.login
              })
            } else if (!LoginError) {
              await handleImportAccount({
                onSuccess: () => {
                  importSuccessToast()
                  onClose()
                },
                onError: setError
              })
            }
          }}
        >
          {({ isValid, error: connectError, values }) => (
            <Form>
              {notificationText && (
                <Notification>{notificationText}</Notification>
              )}
              {loginError && (
                <Notification severity='error'>
                  {loginError.code === 'EMAIL_LOCKED' ? (
                    <>
                      You have reached the maximum number of failed login
                      attempts. Please&nbsp;
                      <ModeLink
                        mode='forgot'
                        data-testid='connect-account-modal-reset-password-link'
                        className='font-bold hover:underline text-error'
                        state={{ email: values.email }}
                      >
                        reset your password
                      </ModeLink>
                      &nbsp;and try again.
                    </>
                  ) : (
                    'Invalid username or password combination.'
                  )}
                </Notification>
              )}
              {connectError?.code && !loginError && (
                <Notification severity='error'>
                  {connectError.message}
                </Notification>
              )}
              <Field
                data-testid='connect-account-modal-email'
                autoFocus
                id='connect-account-email'
                name='email'
                type='text'
                label='email'
                component={Input}
              />
              <Field
                data-testid='connect-account-modal-password'
                id='connect-account-password'
                name='password'
                label='password'
                component={Password}
              />
              <Button
                data-testid='connect-account-modal-button-submit'
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.PRIMARY}
                responsive
                loading={loading}
                disabled={!isValid}
              >
                Connect account
              </Button>
              <ButtonSpacer vertical />
              <ModeLink
                data-testid='connect-account-modal-button-forgot-password'
                style='secondaryWide'
                mode='forgot'
                state={{ email: values.email }}
              >
                Forgot Password
              </ModeLink>
            </Form>
          )}
        </Formik>
      </PoweredByToastModal>
    )
  }
)
