import {
  RouterButton,
  RouterButtonProps
} from '@local/do-secundo-router-button'
import cx from 'classnames'
import * as React from 'react'
import { BrandedContent, BrandedIcon } from '../BrandedButton'
import style from '../BrandedButton/BrandedButton.module.css'

/**
 * Do not allow configurable size unless we add ability to also size the icon + background appropriately.
 * Do not allow configurable variants; the OO colors do not match properly, so we add buffet specific primary colors to this button.
 */
export interface BrandedRouterButtonProps
  extends Omit<RouterButtonProps, 'size' | 'variant' | 'iconLeft'> {}

export const BrandedRouterButton = ({
  children,
  className,
  ...props
}: BrandedRouterButtonProps) => {
  return (
    <RouterButton
      size='base'
      className={cx(style.branded, className)}
      iconLeft={<BrandedIcon testId='toast-logo' />}
      {...props}
    >
      <BrandedContent>{children}</BrandedContent>
    </RouterButton>
  )
}
