import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'

import { dataByTypename } from '../../utils/apollo-helpers'
import { useGiftCardEnabled } from '../IfGiftCard/IfGiftCard'

import { INQUIRE_GIFT_CARD_BALANCE } from '../../apollo/giftCard/giftCard.graphql'

const GiftCardContext = React.createContext({})

const initialGiftCard = {
  cardNumber: null,
  availableBalance: null
}

const noop = () => {}

export const GiftCardProvider = ({ children }) => {
  const [giftCard, setGiftCard] = useState(initialGiftCard)
  const [gcError, setGcError] = useState()
  const [applyGiftCard, response] = useLazyQuery(INQUIRE_GIFT_CARD_BALANCE, {
    fetchPolicy: 'no-cache'
  })
  const { data } = response

  useEffect(() => {
    if (data) {
      const { GiftCardBalanceInquiryResponse, GiftCardBalanceInquiryError } =
        dataByTypename(data.giftCardBalanceInquiry)

      if (GiftCardBalanceInquiryResponse) {
        const { cardNumber, availableBalance } = GiftCardBalanceInquiryResponse
        setGiftCard({ cardNumber, availableBalance })
      }

      if (GiftCardBalanceInquiryError) {
        setGcError(GiftCardBalanceInquiryError)
        setGiftCard(initialGiftCard)
      }
    }
  }, [data])

  const context = {
    giftCard,
    applyGiftCard,
    clearGiftCard: () => {
      setGiftCard(initialGiftCard)
      setGcError()
    },
    ...response,
    error: gcError || response.error
  }

  return (
    <GiftCardContext.Provider value={context}>
      {children}
    </GiftCardContext.Provider>
  )
}

GiftCardProvider.propTypes = {
  children: PropTypes.node
}

export const useGiftCard = () => {
  const enabled = useGiftCardEnabled()
  const context = React.useContext(GiftCardContext)
  if (!enabled) {
    return {
      giftCard: initialGiftCard,
      applyGiftCard: noop,
      clearGiftCard: noop
    }
  }

  return context
}
