import * as React from 'react'
import ReactDOM from 'react-dom'
// keep this import on top
import './index.css'

import { BanquetApp } from '../client/components/App/BanquetApp.jsx'
import { banquetSingleSpaReact, initSentry } from 'banquet-runtime-modules'
import { getSentryConfig } from 'do-cornucopia-root'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { getErrorBoundary, getLogger } from '@toasttab/do-secundo-sentry-client'

import 'intersection-observer'

const tempWindow: any = window
const sentryConfig = getSentryConfig(tempWindow.OO_GLOBALS.mode)
const { hub, client } = initSentry({ ...sentryConfig, releaseVersion: process.env.PKG_VERSION })

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'do',
  rootComponent: BanquetApp,
  sentry: sentryConfig,
  initSentry: () => ({ hub, client }),
  singleSpaCssLifecycles,
  sentryErrorBoundary: getErrorBoundary(hub, { spa: 'takeout-web' })
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const logError = getLogger(hub, { spa: 'takeout-web' })
