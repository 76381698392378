import React, { useMemo, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useMenus } from '../../hooks/use-menus'
import { useGetCart } from '../CartQuery/CartQuery'
import { maybeDeepFreeze } from '@apollo/client/utilities'
import { usePersistEqualObject } from '../../hooks/use-persist-equal-object'
import { usePopularItems } from '../../hooks/use-popular-items'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { XP_INTELLIGENT_UPSELLS } from '../ExperimentsProvider/experiment-definitions'
import { useVariant } from '../../hooks/use-variant'
import { filterCartUpsellInfo } from './upsell-utils'

const getItemsFlat = (menus) =>
  menus.reduce((acc, menu) => {
    const menuName = menu.name
    const items = menu.groups.reduce((itemsAcc, group) => {
      const groupName = group.name
      return itemsAcc.concat(
        group.items.map((item) => ({ ...item, groupName, menuName }))
      )
    }, [])
    return acc.concat(items)
  }, [])

const emptyContext = {
  upsells: []
}
const UpsellsContext = createContext(emptyContext)

const noFilterRules = [() => true]
const noSortRules = [() => 0]
const emptyArr = []
/**
 *
 * @param {object} props
 * @param {Array<import('./upsell-rules').filterFunctionWithContext>} props.filterRules
 * @param {Array<import('./upsell-rules').sortFunctionWithContext>} props.sortRules
 */
const UpsellsProviderInner = ({
  children,
  filterRules = noFilterRules,
  secondaryFilterRules = noFilterRules,
  sortRules = noSortRules
}) => {
  if (filterRules.length === 0) filterRules = noFilterRules
  if (secondaryFilterRules.length === 0) secondaryFilterRules = noFilterRules
  if (sortRules.length === 0) sortRules = noSortRules
  const { availability } = useAvailability()
  const { cart } = useGetCart()
  const { data: popularItemsData } = usePopularItems()
  const intelligentUpsellVariant = useVariant(
    XP_INTELLIGENT_UPSELLS.experimentName
  )

  const { menus = [] } = useMenus({ skip: !availability })
  const popularItems =
    (popularItemsData &&
      popularItemsData.popularItems &&
      popularItemsData.popularItems.items) ||
    emptyArr

  const cartUpsellItems = useMemo(() => {
    if (cart) {
      const _cartUpsellItems = _get(cart, 'cartUpsellInfo.upsellItems', [])
      if (_cartUpsellItems.length) {
        return _cartUpsellItems.reduce((acc, item) => {
          acc[item] = true
          return acc
        }, {})
      }
    }
    return null
  }, [cart])

  // avoids redudundant calculations if rules have not changed
  const currentFilterRules = usePersistEqualObject(filterRules)
  const currentSecondaryFilterRules =
    usePersistEqualObject(secondaryFilterRules)
  const currentSortRules = usePersistEqualObject(sortRules)

  const upsells = useMemo(() => {
    const items = getItemsFlat(menus)
    const ctx = maybeDeepFreeze({ cart, popularItems })

    const filterRulesWithExperiment =
      intelligentUpsellVariant === 1
        ? [
            filterCartUpsellInfo(cartUpsellItems),
            ...currentSecondaryFilterRules
          ]
        : currentFilterRules
    const filtered = filterRulesWithExperiment.reduce((acc, rule) => {
      return acc.filter((...args) => rule(ctx, ...args))
    }, items)

    const sorted = currentSortRules.reduce((acc, rule) => {
      return acc.sort((...args) => rule(ctx, ...args))
    }, filtered)

    return sorted
  }, [
    cart,
    currentFilterRules,
    currentSortRules,
    menus,
    popularItems,
    cartUpsellItems,
    intelligentUpsellVariant,
    currentSecondaryFilterRules
  ])

  const context = { upsells }

  return (
    <UpsellsContext.Provider value={context}>
      {children}
    </UpsellsContext.Provider>
  )
}

UpsellsProviderInner.propTypes = {
  children: PropTypes.node.isRequired,
  filterRules: PropTypes.arrayOf(PropTypes.func.isRequired),
  secondaryFilterRules: PropTypes.arrayOf(PropTypes.func.isRequired),
  sortRules: PropTypes.arrayOf(PropTypes.func.isRequired)
}

const UpsellsProvider = (props) => {
  const { restaurantInfo } = useRestaurant()

  let enabled = false

  if (
    restaurantInfo.data &&
    restaurantInfo.data.restaurant &&
    restaurantInfo.data.restaurant.upsellsConfig
  ) {
    enabled = Boolean(restaurantInfo.data.restaurant.upsellsConfig.enabled)
  }

  if (!enabled) {
    return (
      <UpsellsContext.Provider value={emptyContext}>
        {props.children}
      </UpsellsContext.Provider>
    )
  }

  return <UpsellsProviderInner {...props} />
}

UpsellsProvider.propTypes = {
  children: PropTypes.node
}

const useUpsells = () => useContext(UpsellsContext)

export { useUpsells, UpsellsProvider }
