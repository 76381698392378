import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@local/do-secundo-button'

import styles from './RemoveItemButton.module.css'
import { useDeleteItemFromCart } from '@local/do-secundo-cart-mutation'
import { useHandleDelete } from '../../../../client/components/Modifiers/modifier-helpers'
import { useCart } from '@local/do-secundo-cart-provider'
import { useFormik } from '@local/do-secundo-formik-provider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { MenuItemDetailsShape } from '@local/do-secundo-model'
import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'

export const BaseRemoveItemButton = ({ handleClick, isDisabled }) => {
  return (
    <div className={styles.delete}>
      <Button
        data-testid='modifier-remove-item-button'
        onClick={handleClick}
        disabled={isDisabled}
      >
        Remove Item
      </Button>
    </div>
  )
}

export const RemoveItemButton = ({
  handleDeleteParams,
  updateError,
  itemDetails
}) => {
  const { cartGuid } = useCart()
  const { modalLoading, setModalLoading } = useNestedModifiers()
  const { values, isSubmitting } = useFormik()
  const [deleteItemFromCart, { error: deleteError }] = useDeleteItemFromCart()
  const { loading: availabilityLoading } = useAvailability()

  const handleDelete = useHandleDelete({
    cartGuid,
    itemGuid: itemDetails.guid,
    itemDetails,
    deleteItemFromCart,
    ...handleDeleteParams
  })

  if (deleteError) {
    updateError(deleteError)
  }

  const handleClick = useCallback(async () => {
    setModalLoading(true)
    try {
      await handleDelete(values)
    } catch (error) {
      updateError(error)
    } finally {
      setModalLoading(false)
    }
  }, [setModalLoading, handleDelete, updateError, values])

  const isDisabled = modalLoading || isSubmitting || availabilityLoading

  return (
    <BaseRemoveItemButton handleClick={handleClick} isDisabled={isDisabled} />
  )
}

const handleDeleteParamsShape = PropTypes.shape({
  selectionGuid: PropTypes.string.isRequired,
  getComputedPrice: PropTypes.func.isRequired,
  basePrice: PropTypes.number.isRequired
})

RemoveItemButton.propTypes = {
  handleDeleteParams: handleDeleteParamsShape.isRequired,
  updateError: PropTypes.func.isRequired,
  itemDetails: MenuItemDetailsShape.isRequired
}
