import * as React from 'react'
import { DateTime } from 'luxon'
import ReactRouterPropTypes from 'react-router-prop-types'
import { useLocation } from 'react-router-dom'
import { NetworkStatus, useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { Progress } from '@local/do-secundo-progress'
import { CloseButton } from '@local/do-secundo-closebutton'
import { GET_COMPLETED_ORDER } from '../../apollo/cart/cart.graphql'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { RestaurantAddress } from '../RestaurantAddress/RestaurantAddress'
import { Fieldset } from '@local/do-secundo-fieldset'
import { BackToMenuButton } from './BackToMenuButton/BackToMenuButton'
import { CartTable } from '../Cart/CartTable/CartTable'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { LoyaltyConfirmation } from '../LoyaltyConfirmation/LoyaltyConfirmation'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { toRelativeDateString } from '../../utils/time-utils'
import { useAuth } from '../AuthProvider/AuthProvider'
import { formatPhoneNumber } from '../../utils/phone-number'
import { SocialBanner } from './SocialBanner/SocialBanner'
import { GuestFeedback } from '../GuestFeedback/GuestFeedback'
import { XP_GUEST_FEEDBACK } from '../ExperimentsProvider/experiment-definitions'
import { useVariant } from '../../hooks/use-variant'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { useFundraisingContext } from '../TfgContextProvider/FundraisingContextProvider'
import { Alert } from '@toasttab/buffet-pui-alerts'

import styles from './ConfirmPage.module.css'
import { GuestCommunication } from '../GuestCommunication/GuestCommunication'
import { formatHours } from '../../utils/restaurant-schedule-helpers'
import {
  SmsConfirmationBenefits,
  useIsPasswordlessAuthEnabled,
  FROM_LOCATION
} from '@local/do-secundo-passwordless-authentication'
import { useCartContainsAlcohol } from '../CheckoutForm/CheckoutDeliveryInfo/use-cart-contains-alcohol'
import { AlcoholDeliveryInstructions } from '../CheckoutForm/CheckoutDeliveryInfo/AlcoholDeliveryInstructions'
import { ModeLink } from '../ModeRouter/utils'
import { FF, useFlag } from '@local/do-secundo-feature-flag'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'

export const formatCurbsideV2Info = (curbsidePickupV2) => {
  const elements = [
    curbsidePickupV2.transportColor,
    curbsidePickupV2.transportDescription
  ]

  return elements.filter((element) => Boolean(element)).join(' ')
}

export const ConfirmPage = ({ match }) => {
  const { orderGuid } = match.params
  const { clearUnverifiedCustomer, hasErrorFromCheckout } = useAuth()
  const { fundraisingConfig } = useFundraisingContext()
  const serviceChargeGuid = fundraisingConfig?.serviceChargeGuid
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const guestFeedbackVariant = useVariant(XP_GUEST_FEEDBACK.experimentName)
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const ALCOHOL_ONBOARDING_ENABLED = useFlag(FF.ALCOHOL_ONBOARDING)

  const { loading, error, data, networkStatus } = useQuery(
    GET_COMPLETED_ORDER,
    {
      variables: {
        input: {
          orderGuid,
          restaurantGuid,
          tfgServiceChargeGuid: serviceChargeGuid
        }
      }
    }
  )

  const containsAlcohol = useCartContainsAlcohol(
    data?.completedOrder.selections
  )

  const { state: browserState } = useLocation()

  React.useEffect(
    function clearUnverifiedCustomerOnUnmount() {
      return () => {
        clearUnverifiedCustomer()
      }
    },
    [clearUnverifiedCustomer]
  )

  if (loading || networkStatus === NetworkStatus.refetch) return <Progress />
  if (!data || !data.completedOrder || error)
    return <Redirect to={getRestaurantPath()} />

  const completedOrder = data.completedOrder
  const {
    checkNumber,
    checkGuid,
    estimatedFulfillmentDate,
    guestCommunication,
    restaurant: {
      whiteLabelName,
      location,
      timeZoneId,
      creditCardConfig: { tipEnabled },
      loyaltyConfig,
      socialMediaLinks
    },
    paymentType,
    orderPaymentGuid,
    tip,
    deliveryInfo,
    curbsidePickup,
    curbsidePickupV2,
    diningOptionBehavior
  } = completedOrder
  const { address1, address2, city, state, phone } = location
  const firstName =
    completedOrder.customerV2 && completedOrder.customerV2.firstName
  const cart = {
    order: completedOrder,
    diningOptionBehavior
  }
  const curbsideSelected =
    (curbsidePickup && curbsidePickup.selected) || Boolean(curbsidePickupV2)
  const loyaltyEnabled =
    loyaltyConfig.loyaltySignupEnabled || loyaltyConfig.loyaltyRedemptionEnabled
  const canShowTip = paymentType === 'CREDIT' && tipEnabled
  const fulfillmentDateTime = DateTime.fromMillis(estimatedFulfillmentDate)
  const showFeedbackForm =
    paymentType === 'CREDIT' && guestFeedbackVariant === 1
  const isDelivery =
    diningOptionBehavior === DINING_OPTION_BEHAVIORS.DELIVERY || deliveryInfo

  const customerPhoneNumber = browserState?.guestPhone

  const showSmsBenefits =
    (browserState?.from === FROM_LOCATION.createAccountCheckout ||
      browserState?.from === FROM_LOCATION.showAccountBenefits) &&
    !hasErrorFromCheckout

  const showAlcoholInstructions =
    (cart.order.deliveryServiceCharges > 0 || isDelivery) &&
    containsAlcohol &&
    ALCOHOL_ONBOARDING_ENABLED

  return (
    <div className={styles.confirmPage}>
      <ScrollToTop />
      <>
        <div>
          {hasErrorFromCheckout && (
            <div className='px-6 pt-14'>
              <Alert
                variant='warning'
                className='px-0'
                testId='has-error-from-checkout-alert'
              >
                <p className='text-left'>
                  We were unable to create your account, and could not save your
                  credit card or order information. Please try to create an
                  account again.{' '}
                  <ModeLink
                    mode='create'
                    data-testid='create-account-checkout-error-link'
                  >
                    Create account
                  </ModeLink>
                </p>
              </Alert>
            </div>
          )}
          <div className={styles.information}>
            <h2 data-testid='order-sent-header' className={styles.heading}>
              Order Sent
            </h2>

            <p data-testid='confirmation-text' className={styles.confirmation}>
              {firstName ? `Thanks ${firstName}!` : 'Thanks!'} <br />
              {`Your order will be ${isDelivery ? 'delivered' : 'ready'}`}
            </p>

            <p data-testid='order-ready-time' className={styles.time}>
              {toRelativeDateString(fulfillmentDateTime)} at{' '}
              {formatHours(fulfillmentDateTime, timeZoneId)}
            </p>
            {curbsideSelected && (
              <p className={styles.confirmation} data-testid='curbside-text'>
                for curbside pickup.
              </p>
            )}
            {curbsidePickupV2 && curbsidePickupV2.transportDescription && (
              <p data-testid='curbside-info-wrapper' className={styles.text}>
                <strong>Your vehicle: </strong>
                <span data-testid='curbside-info'>
                  {formatCurbsideV2Info(curbsidePickupV2)}
                </span>
              </p>
            )}
            {deliveryInfo && (
              <p data-testid='delivery-info' className={styles.confirmation}>
                to {deliveryInfo.address1}
                {deliveryInfo.address2 ? ` ${deliveryInfo.address2}` : ''}
                , <br />
                {deliveryInfo.city}, {deliveryInfo.state} {deliveryInfo.zipCode}
              </p>
            )}
            {deliveryInfo && deliveryInfo.notes && (
              <p data-testid='delivery-note-wrapper' className={styles.text}>
                <strong>Your instructions: </strong>
                <span data-testid='delivery-note'>{deliveryInfo.notes}</span>
              </p>
            )}
          </div>
          {PASSWORDLESS_ENABLED && showSmsBenefits && (
            <SmsConfirmationBenefits
              phone={formatPhoneNumber(customerPhoneNumber)}
            />
          )}
          <div className={styles.emailConfirmationWrapper}>
            <p
              data-testid='email-confirmation-send-message'
              className={styles.text}
            >
              An email receipt has been sent.
            </p>
            {showAlcoholInstructions && (
              <AlcoholDeliveryInstructions textClassName='break-words type-subhead text-black-87' />
            )}

            <GuestCommunication
              curbsideSelected={curbsideSelected}
              guestCommunication={guestCommunication}
              phone={phone}
            />
            <h2 data-testid='restaurant-name' className={styles.name}>
              {whiteLabelName}
            </h2>
            <div className={styles.text}>
              <RestaurantAddress
                address1={address1}
                address2={address2}
                city={city}
                state={state}
              />
            </div>
            <p className={styles.text}>
              Issue with your order? Call {whiteLabelName}{' '}
              <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
            </p>
          </div>

          {loyaltyEnabled && <LoyaltyConfirmation orderGuid={orderGuid} />}
          {showFeedbackForm ? (
            <GuestFeedback
              orderPaymentGuid={orderPaymentGuid}
              checkGuid={checkGuid}
              restaurantGuid={restaurantGuid}
              orderGuid={orderGuid}
            />
          ) : null}
          <Fieldset
            label='Your Order'
            instructions={checkNumber && `Check #${checkNumber}`}
            collapsable
            collapsed
          >
            <CartTable cart={cart} editable={false} />
          </Fieldset>
          <div className={styles.cartFooter}>
            <CartFooter
              cart={cart}
              deliveryChargeLabel='Delivery Cost'
              showTotal
              showTip={canShowTip}
              tip={(canShowTip && parseFloat(tip)) || null}
            />
          </div>
          <SocialBanner
            socialMediaLinks={socialMediaLinks}
            whiteLabelName={whiteLabelName}
          />
          <BackToMenuButton getRestaurantPath={getRestaurantPath} />
        </div>
        <div className={styles.closeButton}>
          <CloseButton
            data-testid='close-button-order-sent-page'
            to={getRestaurantPath()}
          />
        </div>
        <div className={styles.poweredByWrapper}>
          <PoweredByToast className={styles.poweredBy} />
        </div>
      </>
    </div>
  )
}

ConfirmPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired
}

// eslint-disable-next-line import/no-default-export
export default ConfirmPage
