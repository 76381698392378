import { onError } from '@apollo/client/link/error'

import { promiseToObservable } from '../../utils/apollo-helpers'
import {
  refreshToken,
  shouldRefreshToken
} from '../authentication/authentication-helpers'
import { logError } from '@local/do-secundo-error'

export const getErrorLink = (config) =>
  onError((error) => {
    const { graphQLErrors, networkError, operation, forward } = error
    if (graphQLErrors) {
      for (let { message, locations, path, extensions } of graphQLErrors) {
        // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error#retrying-failed-requests
        if (extensions.code === 'UNAUTHENTICATED') {
          const originalHeaders = operation.getContext().headers
          if (!shouldRefreshToken()) {
            return null
          }
          const promise = refreshToken(config)
          return promiseToObservable(promise).flatMap((newToken) => {
            if (!newToken) {
              throw Error('Invalid state; refreshToken should not return null') //if this error is thrown the underlying UNAUTHENTICATED will bubble up as the error for the given request
            }
            // retry the request, returning the new observable
            const headers = {
              ...originalHeaders,
              'toast-customer-access': newToken
            }
            operation.setContext({ headers })
            return forward(operation)
          })
        }

        logError(
          {
            message: `GraphQL error: Message: ${message}`
          },
          undefined,
          {
            tags: {
              locations: JSON.stringify(locations),
              path
            }
          }
        )
      }
    }

    if (networkError) {
      logError(error)
    }
  })
