import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'

import { MenuItemDetailsShape, ModifierShape } from '@local/do-secundo-model'

import { Modal } from '@local/do-secundo-modal'
import { CloseButton } from '@local/do-secundo-closebutton'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'

import styles from './ModifiersHeader.module.css'

export const ModifiersHeader = ({ itemDetails }) => {
  const { nestedModifier, popNestedModifier } = useNestedModifiers()

  const clickHandler = useCallback(() => {
    popNestedModifier()
  }, [popNestedModifier])

  const headerText = <Modal.Header>{itemDetails.name}</Modal.Header>

  if (nestedModifier) {
    return (
      <div className={styles.headerNavBack}>
        <CloseButton autoFocus onClick={clickHandler}>
          <ChevronLeftIcon size='md' />
        </CloseButton>
        {headerText}
      </div>
    )
  }

  return headerText
}

const itemDetailsShape = PropTypes.oneOfType([
  MenuItemDetailsShape,
  ModifierShape
])

ModifiersHeader.propTypes = {
  itemDetails: itemDetailsShape.isRequired
}
