import { DateTime } from 'luxon'
import capitalize from 'lodash/capitalize'

export const isSameISODay = (first, second) => {
  if (!first || !second) {
    return false
  }
  const firstDate = DateTime.fromISO(first)
  const secondDate = DateTime.fromISO(second)
  return firstDate.hasSame(secondDate, 'day')
}

export const isSameISOTime = (first, second) => {
  if (!first || !second) {
    return false
  }
  const firstTime = DateTime.fromISO(first)
  const secondTime = DateTime.fromISO(second)
  return (
    firstTime.hour === secondTime.hour && firstTime.minute === secondTime.minute
  )
}

export const isISODateTimeEqual = (a, b) =>
  a === b || +DateTime.fromISO(a) === +DateTime.fromISO(b)

/**
 * @param {DateTime|string} isoDate ISO formatted date/time object or string
 * @returns {string} formatted relative time string (eg: "tomorrow")
 */
export const toRelativeDateString = (isoDate) => {
  const dateTime = DateTime.fromISO(isoDate)
  const diffDays = dateTime
    .startOf('day')
    .diff(DateTime.local().startOf('day'), 'days')
    .toObject().days

  if (diffDays === 0) return 'Today'
  if (diffDays === 1) return 'Tomorrow'
  return capitalize(
    dateTime.toLocaleString({
      weekday: 'short',
      month: 'numeric',
      day: 'numeric'
    })
  )
}

export const getCurrentLocalTime = (timeZoneId) =>
  DateTime.local().setZone(timeZoneId).ts
