import {
  ModalHeaderWithBackButton,
  SmsVerificationModal
} from '@local/do-secundo-auth-modals'
import {
  formatTel,
  getRawPhoneNumberWithCountryCode
} from '@local/do-secundo-form-utils'
import { History } from 'history'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router'
import { useImportAccount } from '../..'
import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'
import {
  useSendSmsCode,
  useTrackCodeSubmissionSuccess,
  handleCodeSubmission
} from '../index'
import { isPasswordlessLoggedIn } from '@local/do-secundo-passwordless-authentication'
import { FROM_LOCATION } from '../utils/enums'

export interface UnifiedSmsVerificationModalProps {
  onClose: () => void
}

interface GetUnifiedSmsModalHeaderParams {
  from: string | undefined
  phone: string | undefined
  history: History<unknown>
  dataIdSuffix: string
}

export const getUnifiedSmsModalHeader = ({
  from,
  phone,
  history,
  dataIdSuffix
}: GetUnifiedSmsModalHeaderParams) => {
  const headerText = (
    <>
      Enter the code sent to
      <br />
      {formatTel(phone!)}
    </>
  )
  if (from) {
    return (
      <ModalHeaderWithBackButton
        onClick={() => history.goBack()}
        headerText={headerText}
        testId={`sms-verification-modal-${dataIdSuffix}-back-button`}
      />
    )
  } else {
    return (
      <h2
        className='mx-0 type-headline-4'
        data-testid={`sms-verification-modal-${dataIdSuffix}-header`}
      >
        {headerText}
      </h2>
    )
  }
}

export const UnifiedSmsVerificationModal = ({
  onClose
}: UnifiedSmsVerificationModalProps) => {
  const {
    authClient,
    setIsAuthenticated,
    confirmPasswordlessLogin,
    user,
    completePasswordlessLogin,
    cancelPasswordlessLoginAndKeepLegacy
  } = useAuth()
  const history = useHistory()
  if (isPasswordlessLoggedIn(authClient)) {
    onClose()
  }
  const { state } = useLocation<{
    from?: FROM_LOCATION
    guestPhone?: string
    email?: string
    basicInfo?: { firstName: string; lastName: string; phone: string }
    loyaltyAccountCreationInput?: {
      checkIdentifier: string
      restaurantGuid: string
      transactionGuid: string
    }
  }>()
  const trackCodeSubmissionSuccess = useTrackCodeSubmissionSuccess()
  const from = state?.from
  const phone = state?.guestPhone
  const dataIdSuffix = state?.from === FROM_LOCATION.login ? 'login' : 'create'
  const header = getUnifiedSmsModalHeader({
    from,
    phone,
    history,
    dataIdSuffix
  })
  const handleImportAccount = useImportAccount({
    keepLegacyLoginOnError: true
  })

  React.useEffect(() => {
    if (!phone) {
      history.push('?mode=login')
    }
  }, [history, phone])

  useSendSmsCode(phone, authClient)

  return (
    <SmsVerificationModal
      onClose={onClose}
      header={header}
      handleSubmit={async (code: string) =>
        handleCodeSubmission({
          code,
          confirmPasswordlessLogin,
          completePasswordlessLogin,
          trackCodeSubmissionSuccess,
          handleImportAccount,
          setIsAuthenticated,
          cancelPasswordlessLoginAndKeepLegacy,
          onClose,
          user,
          history,
          from,
          email: state?.email,
          basicInfo: state?.basicInfo,
          loyaltyAccountCreationInput: state?.loyaltyAccountCreationInput
        })
      }
      numFields={6}
      phone={formatTel(phone!)}
      handleResend={() =>
        authClient?.requestPasswordless(getRawPhoneNumberWithCountryCode(phone))
      }
    />
  )
}
