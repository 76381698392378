import React from 'react'
import { Formik, Form, Field } from 'formik'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import ReactRouterPropTypes from 'react-router-prop-types'
import * as Yup from 'yup'

import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  useIsPasswordlessAuthEnabled,
  isPasswordlessLoggedIn,
  SEARCH_PARAMS,
  FROM_LOCATION
} from '@local/do-secundo-passwordless-authentication'
import { ModalHeaderWithBackButton } from '@local/do-secundo-auth-modals'
import { ForgotPasswordSuccessModal } from '../ForgotPasswordSuccessModal/ForgotPasswordSuccessModal'
import { EMAIL_PASSWORD_RESET } from './ForgotPassword.graphql'
import { ButtonSpacer } from '../../ButtonSpacer/ButtonSpacer'
import { ErrorComponent } from '@local/do-secundo-error'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import { Input } from '@local/do-secundo-form'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { ModeLink } from '../../ModeRouter/utils'
import { Notification } from '@local/do-secundo-notification'
import { emailSchema } from '../../../utils/form-schemas'

import styles from './ForgotPasswordModal.module.css'
import { useHistory, useLocation } from 'react-router'
import { useAuth } from '../../AuthProvider/AuthProvider'

export const ForgotPasswordModal = ({ onClose }) => {
  const { state = {} } = useLocation()
  const history = useHistory()
  const { toastwebBaseUri, routerBasename } = useOOGlobals()
  const { restaurantGuid } = useRestaurant()
  const [mutate, { data, loading, called }] = useMutation(EMAIL_PASSWORD_RESET)
  const { success } = data ? data.initiateEmailPasswordReset : {}
  const from = state?.from
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const { authClient } = useAuth()

  const shouldShowPasswordlessStyle =
    (PASSWORDLESS_ENABLED && isPasswordlessLoggedIn(authClient)) ||
    from === FROM_LOCATION.enterYourPassword

  return success ? (
    <ForgotPasswordSuccessModal onClose={onClose} />
  ) : (
    <PoweredByToastModal
      onClose={onClose}
      header={
        <>
          {shouldShowPasswordlessStyle ? (
            <ModalHeaderWithBackButton
              headerText='Forgot Password'
              onClick={() => history.goBack()}
              className='items-center'
            />
          ) : (
            <h2 data-testid='forgot-password-header' className={styles.header}>
              Forgot Password
            </h2>
          )}
          <p
            data-testid='forgot-password-description'
            className={cx(styles.description, {
              'mt-2 type-default': shouldShowPasswordlessStyle
            })}
          >
            Enter the email address on file and we will send instructions to
            reset your password
          </p>
        </>
      }
    >
      <Formik
        initialValues={{ email: state?.email || '' }}
        validationSchema={Yup.object().shape({
          email: emailSchema
        })}
        isInitialValid={emailSchema.isValidSync(state?.email)}
        onSubmit={async ({ email }) => {
          let redirectUrl = `${toastwebBaseUri}${routerBasename}?mode=reset`
          if (from) {
            redirectUrl += `&${SEARCH_PARAMS.resetFrom}=${from}`
          }
          const input = {
            variables: {
              input: {
                email: email.trim(),
                redirectUrl,
                restaurantGuid
              }
            }
          }
          mutate(input)
        }}
      >
        {({ isValid }) => (
          <Form>
            {state.expired && (
              <Notification>
                Unfortunately, that link isn't working. Enter your email to get
                a new link.
              </Notification>
            )}
            <Field
              data-testid='forgot-password-login-email'
              autoFocus
              id='login-email'
              name='email'
              type='text'
              label='email'
              component={Input}
            />
            {/* This code path only visible if success is false */}
            {called && !loading && <ErrorComponent />}
            <Button
              data-testid='forgot-password-button-submit'
              type={ButtonType.SUBMIT}
              variant={ButtonVariant.PRIMARY}
              responsive
              disabled={!isValid}
              loading={loading}
            >
              Send Instructions
            </Button>
            <ButtonSpacer vertical />
            {!shouldShowPasswordlessStyle && (
              <ModeLink
                data-testid='forgot-password-button-login'
                style='secondaryWide'
                mode='login'
              >
                Log In
              </ModeLink>
            )}
          </Form>
        )}
      </Formik>
    </PoweredByToastModal>
  )
}

ForgotPasswordModal.displayName = 'ForgotPasswordModal'

ForgotPasswordModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location
}
