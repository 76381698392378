import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonVariant } from '@local/do-secundo-button'

import styles from './PromoCodeLabel.module.css'

export const PromoCodeLabel = ({ canSubmit, applyPromoCode, loading }) => {
  return (
    <div className={styles.promoCodeLabel}>
      <Button
        data-testid='apply-promo-code-button'
        onClick={() => applyPromoCode()}
        disabled={!canSubmit}
        loading={loading}
        variant={ButtonVariant.LINK}
      >
        Apply
      </Button>
    </div>
  )
}

PromoCodeLabel.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  applyPromoCode: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}
