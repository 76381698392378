import * as React from 'react'

import { useQuery } from '@apollo/client'
import { CUSTOMER } from '../../../../client/components/AuthProvider/Auth.graphql'
import isEmpty from 'lodash/isEmpty'
import {
  IMPORT_DATA_POLL_INTERVAL_MS,
  MAX_IMPORT_WAIT_MS
} from './account-data-status-poll-settings'
import { ACCOUNT_DATA_STATE } from './enums'

/**
 * Provides information on the status of various account data, which could be impacted by triggering
 * an account import.
 *
 * Can be expanded in the future to include the user profile, addresses, order history, and others.
 */
export type AccountDataStatus = {
  importTriggered: boolean
  setImportTriggered: Function
  creditCards: ACCOUNT_DATA_STATE
}

export const useAccountDataStatus = (): AccountDataStatus => {
  const [creditCardsStatus, setCreditCardsStatus] = React.useState(
    ACCOUNT_DATA_STATE.notAuthenticated
  )
  // the CUSTOMER query only executes when the user is authenticated, see authentication-resolvers
  const { data, startPolling, stopPolling } = useQuery(CUSTOMER)
  const [importTriggered, setImportTriggered] = React.useState(false)
  const [shouldPoll, setShouldPoll] = React.useState(false)
  const [maxTimeWaited, setMaxTimeWaited] = React.useState(false)

  const user = data?.customer

  const resetWaitState = React.useCallback(() => {
    setCreditCardsStatus(ACCOUNT_DATA_STATE.notAuthenticated)
    setImportTriggered(false)
    setMaxTimeWaited(false)
    setShouldPoll(false)
  }, [])

  React.useEffect(() => {
    if (!user) {
      resetWaitState()
      return
    }

    if (!importTriggered) {
      setCreditCardsStatus(ACCOUNT_DATA_STATE.ready)
      return
    }

    if (maxTimeWaited) {
      setCreditCardsStatus(ACCOUNT_DATA_STATE.importNotReady)
      return
    }

    if (isEmpty(user.creditCards)) {
      setCreditCardsStatus(ACCOUNT_DATA_STATE.importing)
      return
    }

    setCreditCardsStatus(ACCOUNT_DATA_STATE.ready)
  }, [importTriggered, maxTimeWaited, resetWaitState, user])

  React.useEffect(() => {
    setShouldPoll(creditCardsStatus === ACCOUNT_DATA_STATE.importing)
  }, [creditCardsStatus])

  React.useEffect(() => {
    if (shouldPoll) {
      startPolling(IMPORT_DATA_POLL_INTERVAL_MS)
    } else {
      stopPolling()
    }
  }, [shouldPoll, startPolling, stopPolling])

  /**
   * We start a timer in a separate effect than `shouldPoll` above so we do not have the
   * `startPolling`/`stopPolling` dependencies that would reset our timer when they change.
   */
  React.useEffect(() => {
    if (shouldPoll) {
      const timer = setTimeout(() => {
        setMaxTimeWaited(true)
        setShouldPoll(false)
      }, MAX_IMPORT_WAIT_MS)

      return () => clearTimeout(timer)
    }
  }, [shouldPoll])

  return {
    importTriggered,
    setImportTriggered,
    creditCards: creditCardsStatus
  }
}
