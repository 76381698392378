import React, { useState } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import {
  IfAuthenticated,
  UnlessAuthenticated
} from '../IfAuthenticated/IfAuthenticated'
import { Checkbox } from '@local/do-secundo-form'
import { ErrorComponent } from '@local/do-secundo-error'
import { Progress } from '@local/do-secundo-progress'

import { useCreditCard } from '../CreditCardProvider/CreditCardProvider'

import { TermsModal, SAVE_CARD } from '@local/do-secundo-terms-modal'
import { InfoIcon } from '@toasttab/buffet-pui-icons'

import styles from './SaveNewCardCheckbox.module.css'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

export const SaveNewCardCheckbox = ({
  disabled,
  field,
  onChange = field.onChange
}) => {
  const [shouldShowTerms, editShowTerms] = useState(false)
  const { creditCards = [], error } = useCreditCard()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  const Label = ({ text }) => (
    <span>
      <span className={'flex items-center'}>
        {text}
        <button
          data-testid='save-new-card-terms-button'
          className={styles.infoButton}
          onClick={() => editShowTerms(!shouldShowTerms)}
          type='button'
        >
          <span className='flex'>
            <InfoIcon className={styles.infoImage} />
          </span>
        </button>
      </span>
    </span>
  )

  return (
    <>
      {shouldShowTerms && (
        <TermsModal onClose={() => editShowTerms(false)} terms={SAVE_CARD} />
      )}
      <IfAuthenticated>
        {() => {
          if (error) {
            return <ErrorComponent error={error} />
          }

          return (
            <Field
              component={Checkbox}
              name={field.name}
              value={field.value}
              id={`${field.name}_authenticated_checkbox`}
              disabled={disabled}
              onChange={onChange}
              label={<Label text='Remember this card' />}
            />
          )
        }}
      </IfAuthenticated>
      <UnlessAuthenticated>
        <Field
          component={Checkbox}
          name={field.name}
          value={field.value}
          id={`${field.name}_unauthenticated_checkbox`}
          disabled={disabled}
          onChange={onChange}
          label={
            <Label
              text={
                PASSWORDLESS_ENABLED
                  ? `Remember this card`
                  : `Remember this card and create account`
              }
            />
          }
        />
      </UnlessAuthenticated>
    </>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
})

SaveNewCardCheckbox.propTypes = {
  disabled: PropTypes.bool,
  field: FieldShape.isRequired,
  onChange: PropTypes.func
}
