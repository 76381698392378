import { useMutation } from '@apollo/client'

import {
  APPLY_PROMO_CODE,
  APPLY_PROMO_CODE_V3,
  REMOVE_PROMO_CODE
} from '../../apollo/cart/cart.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'

export const useSetPromoCode = ({ onComplete, onError, onCartOutOfStock }) => {
  return useMutation(APPLY_PROMO_CODE, {
    onError,
    onCompleted: (data) => {
      let {
        CartResponse,
        CartModificationError,
        CartOutOfStockError,
        ApplyPromoCodeError
      } = dataByTypename(data.applyPromoCodeV2)

      if (CartResponse) {
        onComplete()
      }

      if (CartModificationError || ApplyPromoCodeError) {
        onError()
      }

      if (CartOutOfStockError) {
        onCartOutOfStock(CartOutOfStockError.message)
      }
    }
  })
}

export const useSetPromoCodeV3 = ({
  onComplete,
  onError,
  onCartOutOfStock
}) => {
  return useMutation(APPLY_PROMO_CODE_V3, {
    onError,
    onCompleted: (data) => {
      let {
        CartResponse,
        CartModificationError,
        CartOutOfStockError,
        ApplyPromoCodeError
      } = dataByTypename(data.applyPromoCodeV3)

      if (CartResponse) {
        onComplete()
      }

      if (CartModificationError || ApplyPromoCodeError) {
        onError()
      }

      if (CartOutOfStockError) {
        onCartOutOfStock(CartOutOfStockError.message)
      }
    }
  })
}

export const useRemovePromoCode = ({
  onComplete,
  onError,
  onCartOutOfStock
}) => {
  return useMutation(REMOVE_PROMO_CODE, {
    onError,
    onCompleted: (data) => {
      let {
        ApplyPromoCodeResponse,
        CartModificationError,
        CartOutOfStockError
      } = dataByTypename(data.removePromoCode)

      if (
        ApplyPromoCodeResponse ||
        (!CartModificationError && !CartOutOfStockError)
      ) {
        onComplete()
      }

      if (CartModificationError) {
        onError(CartModificationError)
      }

      if (CartOutOfStockError) {
        onCartOutOfStock(CartOutOfStockError.message)
      }
    }
  })
}
