import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import cx from 'classnames'

import styles from './Card.module.css'

export const CardContentLoader = (props) => (
  <ContentLoader {...props}>
    <rect x='8' y='20%' rx='4' ry='4' width='70%' height='12' />
    <rect x='8' y='70%' rx='4' ry='4' width='50%' height='12' />
    <rect x='85%' y='6' rx='4' ry='4' width='42' height='42' />
  </ContentLoader>
)

export const Card = ({
  children,
  loading = false,
  responsive = false,
  endAdornment = null,
  loadingSkeleton = <CardContentLoader />
}) => {
  return (
    <div
      className={cx('h-full', styles.cardContainer, {
        [styles.responsive]: responsive
      })}
    >
      <div className={styles.cardContent}>
        {loading ? loadingSkeleton : children}
      </div>
      {!loading && endAdornment}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  responsive: PropTypes.bool,
  endAdornment: PropTypes.node,
  loadingSkeleton: PropTypes.node
}
