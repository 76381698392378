/**
 * Use when the Apollo `skip` useQuery option is dependent on the authenticated state of a guest.
 * This is necessary because `apolloClient.resetStore()` will ignore the `skip=true` state once a query is active
 * and will refetch that query again, even though it will not be usable to the application because of `skip=true` returning
 * undefined for the data and query values.
 *
 * To solve this, this sets nextFetchPolicy to 'cache-only' as a workaround to not re-fetch from remote after the first query.
 * For the duration of the guest's session, the cached data will be used until they logout which will reset the skip state
 * and next fetch policy.
 *
 * There are many reported issues of skip not working properly in different scenarios,
 * see https://github.com/apollographql/apollo-client/issues/6190
 * though the workarounds here did not solve for our `resetStore()` usage.
 * Possibly fixed in Apollo Client 3.6.9: https://github.com/apollographql/apollo-client/issues/9765
 *
 * This could also be removed when `resetStore` usage is removed from the app.
 */
export const apolloSkipLogoutWorkaround = {
  nextFetchPolicy: 'cache-only'
}
