import * as React from 'react'
import cx from 'classnames'
import { CloseButton } from '@local/do-secundo-closebutton'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'

export interface ModalHeaderWithBackButtonProps {
  onClick: () => void
  headerText: string | React.ReactElement
  testId?: string
  className?: string
}

export const ModalHeaderWithBackButton = ({
  onClick,
  headerText,
  testId,
  className
}: ModalHeaderWithBackButtonProps) => (
  <div className={cx('flex', className)}>
    <CloseButton onClick={onClick} data-testid={testId}>
      <ChevronLeftIcon size={'md'} className='rounded-full text-gray-125 ' />
    </CloseButton>
    <h2 className='pl-2 type-headline-4' data-testid={`${testId}-text`}>
      {headerText}
    </h2>
  </div>
)
