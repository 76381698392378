import React, { useState } from 'react'
import { Modal } from '@local/do-secundo-modal'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { FulfillmentSelectorModal } from '../../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { useHistory } from 'react-router'

export const UnavailableModal = () => {
  const { getRestaurantPath } = useRestaurant()

  const [showErrorModal, setShowErrorModal] = useState(true)
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false)
  const { push: historyPush } = useHistory()
  return (
    <>
      {showErrorModal && (
        <Modal responsive={false}>
          <div className='flex items-center justify-center h-full'>
            <div className='pb-10 w-80'>
              <MessageError
                header={"We couldn't process your order"}
                message={
                  'Your pickup or delivery time is no longer available. Reschedule your pickup or delivery to complete your order.'
                }
                buttonPrimary={{
                  label: 'Reschedule your order',
                  onClick: () => {
                    setShowFulfillmentModal(true)
                    setShowErrorModal(false)
                  }
                }}
              />
            </div>
          </div>
        </Modal>
      )}
      {showFulfillmentModal && (
        <FulfillmentSelectorModal
          onClose={() => {
            setShowFulfillmentModal(false)
          }}
          onInvalidAddressClose={() => {
            historyPush(getRestaurantPath())
          }}
        />
      )}
    </>
  )
}
