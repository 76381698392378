// placeholder images for now
import TwitterImg from '../../assets/twitter.svg'
import FacebookImg from '../../assets/facebook.svg'
import InstagramImg from '../../assets/instagram.svg'

import React from 'react'
import { PropTypes } from 'prop-types'

import styles from './SocialMediaButtons.module.css'

/**
 * Renders a set of social media link buttons based on which ones are
 * configured.
 */
export const SocialMediaButtons = ({
  socialMediaLinks: { twitterLink, facebookLink, instagramLink }
}) => {
  return (
    <div className={styles.socialMediaButtonGroup}>
      {facebookLink && (
        <a
          href={facebookLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='facebookButton'
        >
          <FacebookImg
            title='Facebook page'
            className={styles.socialMediaImg}
          />
        </a>
      )}
      {twitterLink && (
        <a
          href={twitterLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='twitterButton'
        >
          <TwitterImg title='Twitter feed' className={styles.socialMediaImg} />
        </a>
      )}
      {instagramLink && (
        <a
          href={instagramLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='instagramButton'
        >
          <InstagramImg
            title='Instagram feed'
            className={styles.socialMediaImg}
          />
        </a>
      )}
    </div>
  )
}

const SocialMediaLinksShape = PropTypes.shape({
  twitterLink: PropTypes.string,
  facebookLink: PropTypes.string
})

SocialMediaButtons.propTypes = {
  socialMediaLinks: SocialMediaLinksShape
}
