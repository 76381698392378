import { toast } from 'react-toastify'

import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useUpdateEffect } from '../../../utils/use-update-effect'

/**
 * This hook will show a toast when the delivery cost has changed.
 */
export const useDeliveryToasts = ({ cart }) => {
  const deliveryChargeTotal =
    cart && cart.order ? cart.order.deliveryChargeTotal : null
  const diningOptionBehavior = cart ? cart.diningOptionBehavior : null

  useUpdateEffect(() => {
    if (diningOptionBehavior === DINING_OPTION_BEHAVIORS.DELIVERY) {
      toast('Delivery cost updated')
    }
  }, [deliveryChargeTotal, diningOptionBehavior])
}
