import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './CartTableModifiersList.module.css'
import {
  joinNestedModByCommas,
  modifierConsolidator
} from '../../../../utils/reducer-utils'

export const CartTableModifiersListComponent = ({ modifiers, editable }) => {
  const consolidatedModifiers = modifierConsolidator(modifiers)

  return (
    <ul
      data-testid='cart-item-modifiers'
      className={cx(styles.modifiers, { [styles.readonly]: !editable })}
      role='group'
      aria-label='modifiers'
    >
      {consolidatedModifiers.map(
        ({ name, count, modifiers: nestedModifiers = [] }, index) => {
          const isLast = index === consolidatedModifiers.length - 1
          const consolidatedNestedModifiers =
            modifierConsolidator(nestedModifiers)

          // must use index here because GUID will be null for special instructions modifier
          return (
            <li
              data-testid='cart-item-modifier'
              key={index}
              className={cx(styles.modifier, 'type-default')}
            >
              {count > 1 ? (
                <Fragment>
                  <span className={cx(styles.modifierNumber, 'type-default')}>
                    {count}
                  </span>
                  {` ${name}`}
                </Fragment>
              ) : (
                name
              )}
              {consolidatedNestedModifiers.length > 0 && (
                <span className={styles.nestedModifier}>
                  {' ('}
                  {joinNestedModByCommas('name')(consolidatedNestedModifiers)}
                  {')'}
                </span>
              )}
              {!editable && !isLast && ', '}
            </li>
          )
        }
      )}
    </ul>
  )
}

CartTableModifiersListComponent.propTypes = {
  modifiers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      modifiers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    })
  ),
  editable: PropTypes.bool
}

export const CartTableModifiersList = React.memo(
  CartTableModifiersListComponent
)
CartTableModifiersList.displayName = 'CartTableModifiersList'
