import React from 'react'
import PropTypes from 'prop-types'

import { Stepper } from '@local/do-secundo-stepper'
import styles from './ItemQuantity.module.css'

export const ItemQuantity = ({ field, form, min = 1, max = Infinity }) => {
  const { setFieldValue } = form
  const { value, name } = field
  const handleDecrement = () => {
    setFieldValue(name, value - 1)
  }
  const handleIncrement = () => {
    setFieldValue(name, value + 1)
  }
  const decrementDisabled = value <= min
  const incrementDisabled = value >= max
  return (
    <div data-testid='item-quantity' className={styles.wrapper}>
      <Stepper
        onIncrement={handleIncrement}
        onDecrement={handleDecrement}
        value={value}
        incrementDisabled={incrementDisabled}
        decrementDisabled={decrementDisabled}
        dataTestId={'item-quantity'}
      />
      <div />
    </div>
  )
}

const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

ItemQuantity.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired,
  min: PropTypes.number,
  max: PropTypes.number
}
