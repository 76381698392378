import React from 'react'
import ContentLoader from 'react-content-loader'
import cx from 'classnames'

import styles from './Menus.loader.module.css'

export const MenusLoader = () => (
  <>
    <ul className={cx(styles.fake, styles.menus)}>
      {Array.from({ length: 24 }, (_, i) => (
        <li className={styles.fakeItem} key={i}>
          <ContentLoader height={160} width={510}>
            <rect x='0' y='0' rx='4' ry='4' width='221' height='26' />
            <rect x='0' y='42' rx='4' ry='4' width='299' height='52' />
            <rect x='0' y='104' rx='4' ry='4' width='68' height='20' />
            <rect x='331' y='0' rx='4' ry='4' width='158' height='125' />
          </ContentLoader>
        </li>
      ))}
    </ul>
  </>
)
