import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { EmailInput, PhoneInput } from '@local/do-secundo-form'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { Notification } from '@local/do-secundo-notification'
import { getRawPhoneNumber } from '@local/do-secundo-form-utils'

export interface AuthFormSubmitValues {
  phone: string
  email?: string
}

export interface CompleteAccountFormValues {
  email: string
  firstName: string
  lastName: string
}
export interface AuthFormProps {
  handleSubmit: (e: AuthFormSubmitValues) => void
  initialValues: AuthFormSubmitValues
  validationSchema: Yup.ObjectSchema<
    Yup.Shape<object | undefined, AuthFormSubmitValues>,
    object
  >
  buttonText: string
  includeEmail?: boolean
  handleSubmitError?: (error: { Error: string }) => void
  disclaimer?: React.ReactNode
  hasEmail?: boolean
  testId?: string
}

export const AuthForm = ({
  handleSubmit,
  initialValues,
  validationSchema,
  buttonText,
  includeEmail,
  handleSubmitError,
  disclaimer,
  hasEmail = false,
  testId = 'auth-form'
}: AuthFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      isInitialValid={validationSchema.isValidSync(initialValues)}
      onSubmit={async (values, { setSubmitting, setError }) => {
        const formValues = {
          ...values,
          phone: getRawPhoneNumber(values.phone)
        }

        try {
          await handleSubmit(formValues)
        } catch (err) {
          setError(err)
          handleSubmitError && handleSubmitError(err as { Error: string })
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid, error }) => {
        return (
          <Form data-testid={`${testId}-form`}>
            {error && (
              <Notification severity='error'>{error.message}</Notification>
            )}
            {includeEmail && (
              <div style={{ display: hasEmail ? ' none' : 'unset' }}>
                <EmailInput testId={`${testId}-email-input`} />
              </div>
            )}
            <PhoneInput
              label={`Phone number${includeEmail ? '*' : ''}`}
              testId={`${testId}-phone-input`}
            />
            {disclaimer}
            <Button
              type={ButtonType.SUBMIT}
              variant={ButtonVariant.PRIMARY}
              responsive
              loading={isSubmitting}
              disabled={!isValid}
              data-testid={`${testId}-submit-button`}
            >
              {buttonText}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
