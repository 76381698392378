import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ErrorComponent } from '@local/do-secundo-error'
import { useLoyaltyRedemption } from './use-loyalty-redemption'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { Dollars } from '@local/do-secundo-dollars'
import { BIRTHDAY_REDEMPTION_TYPE } from '../../../constants'
import { PointsDescription } from './PointsDescription/PointsDescription'

import {
  CancelIcon,
  GiftCardIcon,
  MoneyBagIcon
} from '@toasttab/buffet-pui-icons'

import styles from './LoyaltyRedemption.module.css'

/**
 * Displays and allows redemption of a loyalty reward.
 */
export const LoyaltyRedemption = ({ cart, redemption, pointsBalance }) => {
  const {
    addError,
    removeError,
    toggleRedeemed,
    redemptionOnCart,
    addLoading,
    removeLoading
  } = useLoyaltyRedemption({ cart, redemption })

  const anyLoading = addLoading || removeLoading
  const redeemedIsActive = !removeLoading && (redemptionOnCart || addLoading)

  const description =
    redemption.type === BIRTHDAY_REDEMPTION_TYPE ? (
      'Birthday Reward'
    ) : (
      <PointsDescription
        conversionRate={cart.restaurant?.loyaltyConfig?.conversionRate}
        redemptionAmount={redemption.redemptionAmount}
        pointsBalance={pointsBalance}
      />
    )

  const icon =
    redemption.type === BIRTHDAY_REDEMPTION_TYPE ? (
      <GiftCardIcon />
    ) : (
      <MoneyBagIcon />
    )

  const error = addError || removeError

  return (
    <div className='pt-2'>
      {error && <ErrorComponent error={error} />}
      <button
        type='button'
        className={cx(styles.rewardButton, {
          [styles.active]: redeemedIsActive
        })}
        data-testid='redemption-button'
        disabled={anyLoading}
        onClick={toggleRedeemed}
      >
        <div className={cx(styles.reward)}>
          {anyLoading ? (
            <Loading variant={LoadingVariant.SECONDARY} centerDots />
          ) : (
            <div className={cx(styles.reward)}>
              <div className='flex justify-start'>
                <div className='pt-2 pr-4'>{icon}</div>
                <div>
                  <div
                    className={(cx(styles.rewardValue), 'flex justify-start')}
                  >
                    <span>
                      <Dollars amount={redemption.redemptionAmount} /> off
                    </span>
                  </div>
                  {description && (
                    <div className='text-color-default font-normal'>
                      {description}
                    </div>
                  )}
                </div>
              </div>
              {redeemedIsActive ? (
                <div>
                  Applied <CancelIcon />
                </div>
              ) : (
                'Redeem'
              )}
            </div>
          )}
        </div>
      </button>
    </div>
  )
}

LoyaltyRedemption.propTypes = {
  cart: PropTypes.object.isRequired,
  redemption: PropTypes.object.isRequired,
  pointsBalance: PropTypes.number.isRequired
}
