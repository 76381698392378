import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { Link } from '../../Link/Link'
import { ButtonSpacer } from '../../ButtonSpacer/ButtonSpacer'
import { useGetCart } from '../../CartQuery/CartQuery'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { AsapEstimate } from '../../AsapEstimate/AsapEstimate'
import { FutureEstimate } from '../../FutureEstimate/FutureEstimate'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import styles from './CheckoutErrorModals.module.css'

const Estimate = () => {
  const { fulfillmentType, diningOptionBehavior } = useFulfillment()

  return fulfillmentType === 'ASAP' ? (
    <AsapEstimate hideMessage diningOptionBehavior={diningOptionBehavior} />
  ) : (
    <FutureEstimate />
  )
}

export const TimeChangedModal = ({ onSubmit, loading }) => {
  const { deleteCartGuid } = useGetCart()
  const { getRestaurantPath } = useRestaurant()

  return (
    <Modal header='Your order time has changed'>
      <div className={styles.wrapper}>
        <p className={styles.paragraph}>
          Your order time is no longer available. The new time for your order is{' '}
          <strong>
            <Estimate />
          </strong>
          .
        </p>
        <Button
          data-testid='submit'
          variant={ButtonVariant.PRIMARY_WIDE}
          onClick={onSubmit}
          loading={loading}
        >
          Yes, submit my order
        </Button>
        <ButtonSpacer vertical />
        <Link
          data-testid='cancel'
          onClick={deleteCartGuid}
          to={getRestaurantPath('?mode=fulfillment')}
          style='secondaryWide'
        >
          Schedule a new order
        </Link>
      </div>
    </Modal>
  )
}

TimeChangedModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
}
