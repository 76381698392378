export enum FROM_LOCATION {
  sms = 'sms',
  login = 'login',
  create = 'create',
  createAccountCheckout = 'createAccountCheckout',
  showAccountBenefits = 'showAccountBenefits',
  emailDetected = 'emailDetected',
  loginEasier = 'loginEasier',
  emailLogin = 'emailLogin',
  enterYourPassword = 'enterYourPassword'
}

export enum SEARCH_PARAMS {
  resetFrom = 'resetFrom'
}

/**
 * Various states that a user's account data can be in.
 *
 * These states currently do not cover "LOADING", "RELOADING", or "ERROR" to indicate other
 * possible states the data can be in, but this could be expanded on in the future.
 */
export enum ACCOUNT_DATA_STATE {
  /**
   * There is no valid user session so account data is not retrieved.
   */
  notAuthenticated = 'notAuthenticated',
  /**
   * The data has been loaded and is ready for display.
   * If the data has loaded and there is an absense of data (for example, the user has no saved no credit cards), the data state is still READY.
   */
  ready = 'ready',
  /**
   * There is no current data for the account and we are actively polling for the
   * data to retrieve it as it gets imported.
   * The state will move to "READY" if the data becomes available,
   * or "NOT_IMPORTED" if the data takes too long to load.
   */
  importing = 'importing',
  /**
   * The data may still be importing, but the app should no longer try to wait
   * for the imported data to load in.
   */
  importNotReady = 'importNotReady'
}

/**
 * Various Errors that we're sending to our analytics and want to track.
 */
export enum ANALYTICS_ERROR {
  EmailNotRecognizedError = 'EmailNotRecognizedError',
  SmsCodeError = 'SmsCodeError'
}
