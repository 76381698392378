import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Card } from '../Card/Card'
import { Dollars } from '@local/do-secundo-dollars'
import { StateLink } from '../StateLink/StateLink'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import styles from './MenuItemCard.module.css'
import { useWindowSize } from '@toasttab/buffet-use-window-size'

export const MenuItemCard = ({ item, endAdornment, dataTestid, badge }) => {
  const { getRestaurantPath } = useRestaurant()
  const windowSize = useWindowSize({})
  const isMobile = windowSize.width <= 1025

  if (!item) return null

  const { name, guid, itemGroupGuid, price } = item
  return (
    <li className={styles.item}>
      <StateLink
        data-testid={dataTestid}
        to={getRestaurantPath(`add/${guid}/${itemGroupGuid}`)}
      >
        <Card endAdornment={endAdornment}>
          <div
            className={cx('bg-white h-full pr-2 pl-4', {
              'py-3': !isMobile,
              'py-4': isMobile
            })}
          >
            {badge}
            <h2 className={cx(styles.name, 'type-default')}>{name}</h2>
            {price > 0 && (
              <p className={cx(styles.price, 'type-default')}>
                <Dollars amount={price} />
              </p>
            )}
          </div>
        </Card>
      </StateLink>
    </li>
  )
}

const itemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  price: PropTypes.number
})

MenuItemCard.propTypes = {
  item: itemShape,
  endAdornment: PropTypes.node,
  dataTestid: PropTypes.string.isRequired
}
