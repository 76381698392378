import React from 'react'

import { WelcomeMessage } from '../WelcomeMessage/WelcomeMessage'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Notification } from '@local/do-secundo-notification'
import { NotInDeliveryAreaWarning } from '../NotInDeliveryAreaWarning/NotInDeliveryAreaWarning'
import { useGetCart } from '../CartQuery/CartQuery'

export const RestaurantNotification = () => {
  let { info } = useGetCart()
  if (!info) {
    // use default if info is null
    info = []
  }
  const { orderingAvailable } = useAvailability()

  return (
    <div>
      <WelcomeMessage />
      {orderingAvailable && (
        <NotInDeliveryAreaWarning padTop severity='warning' isActionable />
      )}
      {info.length > 0 &&
        info.map(({ code, message }) => (
          <div key={code}>
            <Notification padTop isCloseable>
              {message}
            </Notification>
          </div>
        ))}
    </div>
  )
}
