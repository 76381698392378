import React, { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { FeedbackForm } from '../FeedbackForm/FeedbackForm'
import { FeedbackCompleteBanner } from './FeedbackCompleteBanner/FeedbackCompleteBanner'
import { FeedbackError } from './FeedbackError/FeedbackError'
import { SUBMIT_GUEST_FEEDBACK } from './guest-feedback.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'

import './GuestFeedback.module.css'

export const GuestFeedback = (props) => {
  const [submitFeedbackMutation, { data, error }] = useMutation(
    SUBMIT_GUEST_FEEDBACK
  )
  const submitFeedback = useCallback(
    async (guestResponse, formikUtils) => {
      try {
        await submitFeedbackMutation({
          variables: {
            input: {
              ...props,
              ...guestResponse,
              feedbackRawRating: 'FACES',
              feedbackSource: 'OO'
            }
          }
        })
      } catch (err) {
      } finally {
        formikUtils.setSubmitting(false)
      }
    },
    [props, submitFeedbackMutation]
  )

  const { GuestFeedbackV2SuccessResponse, GuestFeedbackV2Error } = data
    ? dataByTypename(data.submitGuestFeedbackV2)
    : {}
  if (GuestFeedbackV2SuccessResponse) return <FeedbackCompleteBanner />
  const { code } = GuestFeedbackV2Error || {}

  return (
    <>
      {error && <FeedbackError code='UNKNOWN_ERROR' />}
      {GuestFeedbackV2Error && <FeedbackError code={code} />}
      {code !== 'FEEDBACK_ALREADY_SUBMITTED' && (
        <FeedbackForm onSubmit={submitFeedback} />
      )}
    </>
  )
}
