import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { useMutation } from '@apollo/client'
import { object } from 'yup'
import cx from 'classnames'

import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useAuth } from '../../AuthProvider/AuthProvider'
import { CREATE_ACCOUNT } from '../../../apollo/authentication/authentication.graphql'
import { dataByTypename } from '../../../utils/apollo-helpers'
import { ModeLink } from '../../ModeRouter/utils'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import { Notification } from '@local/do-secundo-notification'
import { ErrorComponent } from '@local/do-secundo-error'
import { Input } from '@local/do-secundo-form'
import { Password } from '../../Form/Password/Password'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { ButtonSpacer } from '../../ButtonSpacer/ButtonSpacer'
import { CreateAccountSuccessModal } from '../CreateAccountSuccessModal/CreateAccountSuccessModal'
import { passwordSchema, emailSchema } from '../../../utils/form-schemas'

import styles from './CreateAccountModal.module.css'

const validationSchema = object().shape({
  email: emailSchema,
  password: passwordSchema
})

const getModalCopy = (loyaltyAccountCreationInput) => {
  if (loyaltyAccountCreationInput) {
    return {
      header: 'Create Account to Earn Rewards',
      description:
        'Earn points towards rewards and get your food faster. Save order info and payment details across all of your favorite restaurants with a Toast account.'
    }
  }
  return {
    header: 'Create Account',
    description:
      'Get your food faster. Save order info and payment details across all of your favorite restaurants with a Toast account.'
  }
}

export const CreateAccountModal = withRouter(
  ({ onClose, location: { state = {} } }) => {
    const { toastwebBaseUri, routerBasename } = useOOGlobals()
    const { restaurantGuid } = useRestaurant()
    const { setUnverifiedCustomer } = useAuth()
    const [userEmail, setUserEmail] = useState(null)
    const [createAccount, { data, loading, error }] =
      useMutation(CREATE_ACCOUNT)
    const { customerGuid } = data ? data.createAccount : {}
    const { header, description } = getModalCopy(
      state.loyaltyAccountCreationInput
    )

    return customerGuid && userEmail ? (
      <CreateAccountSuccessModal email={userEmail} onClose={onClose} />
    ) : (
      <PoweredByToastModal
        onClose={onClose}
        header={
          <>
            <h2
              data-testid='create-account-header'
              className={cx(styles.header, 'type-headline-4')}
            >
              {header}
            </h2>
            {!state.expired && (
              <p
                data-testid='create-account-description'
                className={cx(styles.description, 'type-default')}
              >
                {description}
              </p>
            )}
          </>
        }
      >
        <Formik
          initialValues={{ email: state.email || '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setStatus }) => {
            const input = {
              variables: {
                input: {
                  ...values,
                  email: values.email.trim(),
                  redirectUrl: `${toastwebBaseUri}${routerBasename}?mode=verify`,
                  registrationClient: 'ONLINE_ORDERING',
                  restaurantGuid,
                  loyaltyAccountCreationInput:
                    state.loyaltyAccountCreationInput,
                  basicInfo: state.basicInfo
                }
              }
            }
            try {
              const result = await createAccount(input)
              const { CreateAccountResponse, CreateAccountError } =
                dataByTypename(result.data.createAccount)
              if (CreateAccountError) {
                setStatus(CreateAccountError)
              }
              if (CreateAccountResponse) {
                setUnverifiedCustomer({
                  guid: CreateAccountResponse.customerGuid,
                  signupSource: state.signupSource || 'MANUAL_CREATE_ACCOUNT'
                })
                setUserEmail(values.email)
              }
            } catch (err) {
              console.warn(err)
            }
          }}
        >
          {({ isValid, values, status: formError }) => {
            const displayError = error || formError
            let errorComponent = <ErrorComponent error={displayError} />
            if (displayError && displayError.code === 'EMAIL_IN_USE') {
              errorComponent = (
                <Notification severity='error'>
                  An account has already been created with that email. Please
                  log in to continue.
                </Notification>
              )
            }
            return (
              <Form>
                {state.expired && (
                  <Notification>
                    Unfortunately, that link has expired. Create a new account
                    account below.
                  </Notification>
                )}
                {Boolean(displayError) && errorComponent}
                <Field
                  data-testid='create-account-email'
                  autoFocus
                  id='create-account-email'
                  name='email'
                  type='text'
                  label='email'
                  component={Input}
                />
                <Field
                  data-testid='create-account-password'
                  id='create-account-password'
                  name='password'
                  type='password'
                  label='password'
                  component={Password}
                  autoComplete='off'
                />
                <p
                  data-testid='create-account-legal-copy'
                  className={cx(styles['legal-copy'], 'type-subhead')}
                >
                  By signing up, you agree to Toast's{' '}
                  <a
                    href='https://pos.toasttab.com/terms-of-service#diner-tos'
                    rel='noopener noreferrer'
                    target='_blank'
                    className={cx(styles['legal-link'], 'type-default')}
                    data-testid='link-terms-of-service'
                  >
                    Guest Terms of Service
                  </a>{' '}
                  and acknowledge that you have read our{' '}
                  <a
                    href='https://pos.toasttab.com/privacy'
                    rel='noopener noreferrer'
                    target='_blank'
                    className={cx(styles['legal-link'], 'type-default')}
                    data-testid='link-privacy'
                  >
                    Privacy Statement
                  </a>
                  .
                </p>
                <Button
                  data-testid='create-account-submit'
                  type={ButtonType.SUBMIT}
                  variant={ButtonVariant.PRIMARY}
                  disabled={!isValid}
                  loading={loading}
                >
                  Create Account
                </Button>
                <ButtonSpacer vertical />
                <ModeLink
                  data-testid='create-account-login'
                  style='secondaryWide'
                  mode='login'
                  state={{ email: values.email }}
                >
                  Log In
                </ModeLink>
              </Form>
            )
          }}
        </Formik>
      </PoweredByToastModal>
    )
  }
)

CreateAccountModal.displayName = 'CreateAccountModal'

CreateAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location
}
