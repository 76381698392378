import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@local/do-secundo-button'

export const RemoveItemButton = ({
  selectionGuid,
  cartGuid,
  onDelete,
  loading
}) => (
  <Button
    disabled={loading}
    onClick={(event) => {
      event.stopPropagation()
      onDelete({ variables: { input: { cartGuid, selectionGuid } } })
    }}
  >
    Remove
  </Button>
)

RemoveItemButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectionGuid: PropTypes.string.isRequired,
  cartGuid: PropTypes.string.isRequired
}
