import * as React from 'react'
import cx from 'classnames'

export interface IdentityPromptProps {
  children: React.ReactNode
  headerText?: string
  text?: React.ReactNode
  className?: string
  icon?: React.ReactNode
  testId?: string
}

export const IdentityPrompt = ({
  children,
  headerText,
  text,
  className,
  icon,
  testId
}: IdentityPromptProps) => (
  <div data-testid={testId} className={cx('flex flex-col primary', className)}>
    {headerText && (
      <h2 className='mx-0 mt-0 mb-3 type-headline-4'>{headerText}</h2>
    )}
    {text && <div className='type-default'>{text}</div>}
    <div className='self-center w-full'>{children}</div>
    {icon}
  </div>
)
