export const getSpaVersion = () => {
  const current = process.env.PKG_VERSION

  let previous = localStorage.getItem('toast.oo.version')
  if (!previous) {
    const hasV2Token = localStorage.getItem('ls.cartGuidMap')
    previous = hasV2Token ? 'V2' : ''
  }

  localStorage.setItem('toast.oo.version', current)

  return {
    current,
    previous
  }
}
