import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { OOGlobalsConsumer } from '@local/do-secundo-oo-globals-provider'
import { RestaurantPage } from '../RestaurantPage/RestaurantPage'
import { RestaurantProvider } from '@local/do-secundo-restaurant-provider'
import { TriggerRoutechange } from '../TriggerRoutechange/TriggerRoutechange'
import { ServiceAvailabilityProvider } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { ModalOverrideProvider } from '@local/do-secundo-modal'

export const OnlineOrderingApp = () => (
  <OOGlobalsConsumer>
    {({ restaurantGuid, managementGroupGuid, routerBasename, shortUrl }) => (
      <ModalOverrideProvider>
        <ServiceAvailabilityProvider>
          <RestaurantProvider
            restaurantGuid={restaurantGuid}
            shortUrl={shortUrl}
          >
            <>
              <BrowserRouter basename={routerBasename}>
                <Route component={TriggerRoutechange} />
                <Switch>
                  <Route component={RestaurantPage} path='/' />
                  <Redirect to='/' />
                </Switch>
              </BrowserRouter>
            </>
          </RestaurantProvider>
        </ServiceAvailabilityProvider>
      </ModalOverrideProvider>
    )}
  </OOGlobalsConsumer>
)
