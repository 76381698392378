import { useMenuApiAwareQuery } from './use-menu-api-aware-query'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { POPULAR_ITEMS } from '../components/PopularItems/PopularItems.graphql'

export const usePopularItems = () => {
  const { restaurantGuid, shortUrl } = useRestaurant()
  const { fulfillmentTime } = useFulfillment()
  return useMenuApiAwareQuery(
    POPULAR_ITEMS,
    {
      variables: {
        input: {
          restaurantGuid,
          menusInput: {
            restaurantGuid,
            shortUrl,
            dateTime: fulfillmentTime || undefined
          }
        }
      }
    },
    (options) => options.variables.input.menusInput
  )
}
