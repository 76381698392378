import { useMutation } from '@apollo/client'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { DELETE_ACCOUNT, useLogout } from '../../index'
import { useGetCart } from '../../../../client/components/CartQuery/CartQuery'

/**
 * Returns a mutation function that deletes a passwordless account
 */
export const useDeleteAccount = () => {
  const [deleteAccount] = useMutation(DELETE_ACCOUNT)
  const logout = useLogout()
  const { cart } = useGetCart()
  const logoutInput = {
    variables: {
      input: {
        cart
      }
    }
  }
  return async ({
    onSuccess,
    onError
  }: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => {
    const deleteResponse = await deleteAccount()
    const { DeleteAccountResponse, DeleteAccountError } = dataByTypename(
      deleteResponse.data.deleteAccount
    )

    if (DeleteAccountResponse) {
      await logout(logoutInput, 'Your account has been queued to be deleted.')
      onSuccess && onSuccess(DeleteAccountResponse)
    } else if (DeleteAccountError) {
      onError && onError(DeleteAccountError)
    }
  }
}
