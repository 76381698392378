import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { updateQueryParams } from '../utils/update-query-params'

/**
 * Custom hook to use in combination with usePageSearch/useFuseSearch hooks to update URL search param and history.
 * Example: props = { term: 'pizza', keyword: 'item'}.
 * URL will get ?item=pizza parameter attached and the new URL will be replacing the current address in history.
 * Empty term will cause URL parameter to be removed.
 * @param {String} term - Search term
 * @param {String} keyword - key of the URL search param to modify
 */
export const useUpdateSearchParamsAndHistory = ({
  term = '',
  keyword = 'term'
}) => {
  const history = useHistory()
  useEffect(() => {
    updateQueryParams(history, term, keyword)
  }, [term, history, keyword])
  return { history }
}
