import { useMutation } from '@apollo/client'

import {
  EDIT_ITEM_IN_CART,
  ADD_ITEM_TO_CART,
  DELETE_ITEM_FROM_CART,
  GET_CART,
  VALIDATE_CART
} from '../../../../client/apollo/cart/cart.graphql'

import {
  useCart,
  CLEAR_MODIFICATION_ERRORS,
  PUSH_MODIFICATION_ERRORS
} from '@local/do-secundo-cart-provider'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { createOutOfStockError } from '../../../../client/utils/cart-helpers'

const nop = () => {}

const updateCache =
  (getResponse) =>
  (cache, { data }) => {
    const response = getResponse(data)
    const { CartResponse, CartOutOfStockError } = dataByTypename(response)
    if (CartResponse || CartOutOfStockError) {
      const guid = CartResponse
        ? CartResponse.cart.guid
        : CartOutOfStockError.cart.guid
      cache.writeQuery({
        query: GET_CART,
        data: {
          cartV2: {
            info: [],
            warnings: [],
            ...response,
            __typename: 'CartResponse'
          }
        },
        variables: { guid }
      })
    }
  }

const completeCartMutationWithOrderValidation =
  (getResponse, onCompleted, cartProps) => (data) => {
    const response = getResponse(data)
    const { CartOutOfStockError, CartModificationError } =
      dataByTypename(response)
    if (CartModificationError) {
      throw new Error(CartModificationError.message)
    }

    cartProps.dispatch({ type: CLEAR_MODIFICATION_ERRORS })
    cartProps.updateCartGuid(response.cart.guid)

    if (CartOutOfStockError) {
      cartProps.dispatch({
        type: PUSH_MODIFICATION_ERRORS,
        modificationErrors: [createOutOfStockError(CartOutOfStockError)]
      })
    }
    onCompleted(data)
  }

const useCompleteCartMutation = (getResponse, onCompleted) => {
  const cartProps = useCart()
  return completeCartMutationWithOrderValidation(
    getResponse,
    onCompleted,
    cartProps
  )
}

export const useAddItemToCart = ({ onCompleted = nop, onError } = {}) => {
  const query = ADD_ITEM_TO_CART

  const getResponse = (data) => data.addItemToCartV2
  const onCompletedCallback = useCompleteCartMutation(getResponse, onCompleted)
  return useMutation(query, {
    onError,
    update: updateCache(getResponse),
    onCompleted: onCompletedCallback
  })
}

export const useEditItemInCart = ({ onCompleted = nop, onError } = {}) => {
  const getResponse = (data) => data.editItemInCartV2
  const onCompletedCallback = useCompleteCartMutation(getResponse, onCompleted)
  return useMutation(EDIT_ITEM_IN_CART, {
    onError,
    update: updateCache(getResponse),
    onCompleted: onCompletedCallback
  })
}

export const useDeleteItemFromCart = ({
  onCompleted = nop,
  onError,
  ...args
} = {}) => {
  const getResponse = (data) => data.deleteItemFromCartV2
  const onCompletedCallback = useCompleteCartMutation(getResponse, onCompleted)
  return useMutation(DELETE_ITEM_FROM_CART, {
    onError,
    update: updateCache(getResponse),
    onCompleted: onCompletedCallback,
    ...args
  })
}

export const useValidateCart = ({
  onCompleted = nop,
  onError,
  ...args
} = {}) => {
  const getResponse = (data) => data.validateCartPreCheckout
  const onCompletedCallback = useCompleteCartMutation(getResponse, onCompleted)
  return useMutation(VALIDATE_CART, {
    onError,
    update: updateCache(getResponse),
    onCompleted: onCompletedCallback,
    ...args
  })
}
