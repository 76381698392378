import { Button, ButtonProps } from '@toasttab/buffet-pui-buttons'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import { useUniqueId } from '@toasttab/buffet-utils'
import cx from 'classnames'
import * as React from 'react'
import style from './BrandedButton.module.css'

export const BrandedContent = ({
  testId,
  children
}: {
  testId?: string | undefined
  children?: React.ReactNode
}) => (
  <div
    data-testid={useUniqueId(testId, 'branded-content-')}
    className='pl-3 pr-6'
  >
    {children}
  </div>
)

export const BrandedIcon = ({ testId }: { testId?: string | undefined }) => (
  <ToastLogo
    size='xs'
    testId={useUniqueId(testId, 'branded-icon-')}
    className='rounded-full mx-3'
  />
)

/**
 * Do not allow configurable size unless we add ability to also size the icon + background appropriately.
 * Do not allow configurable variants; the OO colors do not match properly, so we add buffet specific primary colors to this button.
 */
export interface BrandedButtonProps
  extends Omit<ButtonProps, 'size' | 'variant' | 'iconLeft'> {}

export const BrandedButton = ({
  children,
  className,
  ...props
}: BrandedButtonProps) => {
  return (
    <Button
      size='base'
      className={cx(style.branded, className)}
      iconLeft={<BrandedIcon />}
      {...props}
    >
      <BrandedContent>{children}</BrandedContent>
    </Button>
  )
}
