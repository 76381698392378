import { IMPORT_STATUS } from '../../index'
import { useQuery } from '@apollo/client'

export const useImportStatus = ({ skip = false } = {}) => {
  const { data = {}, loading, error } = useQuery(IMPORT_STATUS, { skip })
  const { importCount } = data.importStatus || {}
  return {
    importCount,
    loading,
    error,
    data
  }
}
