import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { isFulfillmentTimeValid } from '../FulfillmentSelectorModal/fulfillment-helpers'

export const FULFILLMENT_TYPES = {
  ASAP: 'ASAP',
  FUTURE: 'FUTURE'
}

export const { TAKE_OUT, DELIVERY } = DINING_OPTION_BEHAVIORS
export const { ASAP, FUTURE } = FULFILLMENT_TYPES

const availabilityError = {
  error: new Error('Ordering not available')
}

/*
If the FF is on and delivery is available as a fastest option, default to delivery.
- if takeout is available asap, but delivery is available future -> takeout
- if takeout and delivery both asap -> delivery
- if takeout and delivery both future -> delivery

The fulfillment type should also match what is available (i.e. if it is default FUTURE, return FUTURE).

The default is always ASAP/TAKE_OUT
 */
export const getDefaultFulfillment = (
  availability,
  defaultToDeliveryFlagOn
) => {
  if (defaultToDeliveryFlagOn && availability) {
    if (availability.ASAP.DELIVERY) {
      return {
        fulfillmentType: ASAP,
        diningOptionBehavior: DELIVERY
      }
    } else if (!availability.ASAP.TAKE_OUT && availability.FUTURE.DELIVERY) {
      return {
        fulfillmentType: FUTURE,
        diningOptionBehavior: DELIVERY
      }
    }
  }

  return {
    fulfillmentType: ASAP,
    diningOptionBehavior: TAKE_OUT
  }
}

export const initialReducerState = ({
  fulfillmentStorage,
  availability,
  orderingAvailable,
  defaultToDeliveryFlagOn
}) => {
  const { get } = fulfillmentStorage
  const defaultFulfillment = getDefaultFulfillment(
    availability,
    defaultToDeliveryFlagOn
  )
  const fulfillmentType =
    get('fulfillmentType') || defaultFulfillment.fulfillmentType
  const fulfillmentTime = get('fulfillmentTime') || null
  const diningOptionBehavior =
    get('diningOptionBehavior') || defaultFulfillment.diningOptionBehavior

  if (!orderingAvailable) return availabilityError
  const def = {
    deliveryInfo: get('deliveryInfo') || undefined,
    deliveryProvider: null,
    savedAddressGuid: null,
    fulfillmentTime,
    valid: isFulfillmentTimeValid(fulfillmentTime, fulfillmentType),
    selected: false
  }

  if (availability[fulfillmentType][diningOptionBehavior]) {
    return {
      ...def,
      fulfillmentType,
      diningOptionBehavior,
      selected: Boolean(get('fulfillmentType'))
    }
  }

  if (availability[ASAP][TAKE_OUT]) {
    return {
      ...def,
      fulfillmentType: ASAP,
      fulfillmentTime: null,
      diningOptionBehavior: TAKE_OUT
    }
  }

  if (availability[FUTURE][TAKE_OUT]) {
    return {
      ...def,
      fulfillmentType: FUTURE,
      diningOptionBehavior: TAKE_OUT
    }
  }

  if (availability[ASAP][DELIVERY]) {
    return {
      ...def,
      fulfillmentType: ASAP,
      fulfillmentTime: null,
      diningOptionBehavior: DELIVERY
    }
  }

  if (availability[FUTURE][DELIVERY]) {
    return {
      ...def,
      fulfillmentType: FUTURE,
      diningOptionBehavior: DELIVERY
    }
  }

  return availabilityError
}
