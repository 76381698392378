import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { getMessage, getRetryMessage, getError } from './error-utils'

import { Notification } from '@local/do-secundo-notification'
import { Button } from '@local/do-secundo-button'
import { Modal } from '@local/do-secundo-modal'

import styles from './Error.module.css'

const DEFAULT_ACTION_CONTENT = 'Click here to retry'

export const ErrorComponent = ({
  error,
  retry,
  isActionable,
  scrollTo,
  ...props
}) => {
  const scrollRef = useCallback(
    (node) => {
      if (scrollTo) {
        if (node !== null) {
          node.focus()
        }
      }
    },
    [scrollTo]
  )
  const [isShowingMoreInfo, setShowMoreInfo] = useState(false)
  isActionable = Boolean(retry || isActionable)
  const showMoreInfo = useCallback(() => {
    setShowMoreInfo(true)
  }, [])
  const hideMoreInfo = useCallback(() => {
    setShowMoreInfo(false)
  }, [])
  const errObj = getError(error)
  return (
    <>
      <div
        ref={scrollRef}
        tabIndex={-1}
        role='alert'
        data-testid='focus-container'
      >
        <Notification
          severity='error'
          isActionable={isActionable}
          actionContent={DEFAULT_ACTION_CONTENT}
          onAction={() => retry()}
          {...props}
        >
          <span data-testid='error-message'>
            {getMessage(errObj)}
            {errObj.moreInfo && (
              <div className={styles.moreInfo}>
                <Button
                  onClick={showMoreInfo}
                  data-testid='error-more-info-button'
                >
                  Learn more
                </Button>
              </div>
            )}
            {!isActionable && (
              <>
                <br />
                {getRetryMessage(errObj) || 'Please try again'}
              </>
            )}
          </span>
        </Notification>
      </div>
      {isShowingMoreInfo && errObj.moreInfo && (
        <Modal responsive={false} onClose={hideMoreInfo}>
          <div className={styles.modal}>
            <h1
              className={styles.header}
              data-testid='error-more-info-modal-header'
            >
              {errObj.moreInfo.header}
            </h1>
            <p
              className={styles.text}
              data-testid='error-more-info-modal-message'
            >
              {errObj.moreInfo.message}
            </p>
          </div>
        </Modal>
      )}
    </>
  )
}

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.string,
    networkError: PropTypes.object,
    graphQLErrors: PropTypes.array,
    message: PropTypes.string,
    moreInfo: PropTypes.shape({
      header: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired
    })
  }),
  retry: PropTypes.func,
  isActionable: PropTypes.bool
}
