import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonVariant } from '@local/do-secundo-button'
import { CartLoader } from '../Cart/Cart.loader'
import { MenusLoader } from '../Menus/Menus.loader'
import { MenuPageLayout } from './MenuPageLayout/MenuPageLayout'
import { Modal } from '@local/do-secundo-modal'
import { RestaurantHeaderLoader } from '../RestaurantHeader/RestaurantHeader.loader'

import Warning from '../../assets/warning-outline.svg'
import styles from './MenuPage.error.module.css'
import { formatPhoneNumber } from '../../utils/phone-number'
import { logError } from '@local/do-secundo-error'

export const MenuPageError = ({ retry, restaurantInfo, error }) => {
  useEffect(() => {
    logError(error, (scope) => scope.setTag('Origin', 'Menu Page'))
  }, [error])
  const phoneNumber = restaurantInfo
    ? restaurantInfo.restaurant.location.phone
    : null
  return (
    <MenuPageLayout
      menus={
        <div className={styles.error}>
          <Modal responsive={false}>
            <div className={styles.modal}>
              <Warning />
              <h1 className={styles.header}>Unable to load restaurant page</h1>
              <p className={styles.text}>
                Our system is having an issue loading the restaurant page right
                now. Please try refreshing the page in a few minutes, or contact
                the restaurant to order
                {phoneNumber && (
                  <span>
                    {' '}
                    by calling{' '}
                    <a href={`tel:${phoneNumber}`}>
                      {formatPhoneNumber(phoneNumber)}
                    </a>
                  </span>
                )}
                .
              </p>
              <p className={styles.text}>
                If you’re outside the US or on a remote VPN, the site will not
                load.
              </p>
              <Button
                onClick={retry}
                variant={ButtonVariant.LINK_WIDE}
                data-testid='retry-button'
              >
                Try Again
              </Button>
            </div>
          </Modal>
          <RestaurantHeaderLoader />
          <MenusLoader />
        </div>
      }
      cart={<CartLoader />}
    />
  )
}

MenuPageError.propTypes = {
  retry: PropTypes.func,
  restaurantInfo: PropTypes.object,
  error: PropTypes.object
}
