import * as React from 'react'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useAuth } from '../AuthProvider/AuthProvider'
import { CustomerInformationForm } from './CustomerInformationForm/CustomerInformationForm'
import { UpdatePasswordForm } from './UpdatePasswordForm/UpdatePasswordForm'
import { SavedCreditCardForm } from './SavedCreditCardForm/SavedCreditCardForm'
import { AccountHeader } from '@local/do-secundo-account-header'
import { Progress } from '@local/do-secundo-progress'

import { formatTel, getRawPhoneNumber } from '@local/do-secundo-form-utils'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'
import { ToastContainer } from 'react-toastify'
import { SavedAddressForm } from './SavedAddressForm/SavedAddressForm'
import { ConnectAccountFieldset } from '@local/do-secundo-connect-account-fieldset'

import style from './AccountPage.module.css'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'
import { ModeLink } from '../ModeRouter/utils'

const { toastContainer, toastWrapper } = style

const getInitialValues = (user = {}) => {
  const { firstName, lastName, email, phone } = user

  return {
    customerFirstName: firstName || '',
    customerLastName: lastName || '',
    customerEmail: email || '',
    customerTel: phone ? formatTel(getRawPhoneNumber(phone)) : ''
  }
}

export const AccountPage = () => {
  const { user, loading, mfaLoading } = useAuth()
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  return (
    <div className={style.page}>
      <AccountHeader to={getRestaurantPath()} title='My Account' />
      {loading && !mfaLoading ? (
        <Progress />
      ) : (
        <>
          <CustomerInformationForm initialValues={getInitialValues(user)} />
          {!PASSWORDLESS_ENABLED && (
            <UpdatePasswordForm restaurantGuid={restaurantGuid} />
          )}
          <SavedAddressForm />
          <CreditCardProvider>
            <SavedCreditCardForm />
          </CreditCardProvider>
          {PASSWORDLESS_ENABLED && (
            <ConnectAccountFieldset email={user?.email} />
          )}

          <div className='pb-2 mx-6'>
            <ModeLink
              mode='delete'
              style='secondaryWide'
              data-testid='delete-account-link'
            >
              Delete my account
            </ModeLink>
          </div>
        </>
      )}
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position={'top-center'}
      />
    </div>
  )
}

// eslint-disable-next-line import/no-default-export
export default AccountPage
