import { useQuoteTime } from '../use-quote-time/use-quote-time'

export const AsapEstimate = ({ diningOptionBehavior, hideMessage }) => {
  const { loading, time, isDelivery } = useQuoteTime({ diningOptionBehavior })

  if (loading || time === undefined) return '...'
  if (hideMessage) return `${time}-${time + 5} Min`
  const message = isDelivery ? 'Arrives' : 'Ready'
  return `${message} in ${time}-${time + 5} Min`
}
