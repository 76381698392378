import React from 'react'
import { useGetCart } from '../CartQuery/CartQuery'
import { Notification } from '@local/do-secundo-notification'

const NOT_IN_DELIVERY_AREA = 'NOT_IN_DELIVERY_AREA'

export const CartNotifications = () => {
  let { info, warnings } = useGetCart()
  info = info || []
  warnings = (warnings || []).filter(
    ({ code }) => code !== NOT_IN_DELIVERY_AREA
  )
  return (
    <>
      {info.map((info) => (
        <Notification key={info.code} isCloseable>
          {info.message}
        </Notification>
      ))}
      {warnings.map((warning) => (
        <Notification key={warning.code} isCloseable severity='warning'>
          {warning.message}
        </Notification>
      ))}
    </>
  )
}
