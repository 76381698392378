import React from 'react'
import PropTypes from 'prop-types'
import { Query, Mutation } from '@apollo/client/react/components'
import { Formik, Form, Field } from 'formik'
import { withRouter } from 'react-router'
import ReactRouterPropTypes from 'react-router-prop-types'
import * as Yup from 'yup'

import { Notification } from '@local/do-secundo-notification'
import { ModeRedirect } from '../../ModeRouter/utils'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { Input } from '@local/do-secundo-form'
import {
  IS_RESET_TOKEN_VALID,
  RESET_PASSWORD
} from './ResetPasswordModal.graphql'
import { ErrorComponent } from '@local/do-secundo-error'
import { Progress } from '@local/do-secundo-progress'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import {
  importSuccessToast,
  importErrorToast,
  useAuth,
  showLoggedInNotification
} from '../../AuthProvider/AuthProvider'
import { dataByTypename } from '../../../utils/apollo-helpers'
import { passwordSchema } from '../../../utils/form-schemas'
import {
  isPasswordlessLoggedIn,
  useImportAccount,
  useIsPasswordlessAuthEnabled,
  FROM_LOCATION,
  SEARCH_PARAMS
} from '@local/do-secundo-passwordless-authentication'

export const ResetPasswordModal = withRouter(
  ({ onClose: closeModal, location, history }) => {
    const search = new URLSearchParams(location.search)
    const resetToken = search.get('resetToken')

    if (!resetToken) {
      closeModal()
    }

    const isFromEmailDetected =
      search.get(SEARCH_PARAMS.resetFrom) === FROM_LOCATION.emailDetected
    const isFromEnterYourPassword =
      search.get(SEARCH_PARAMS.resetFrom) === FROM_LOCATION.enterYourPassword
    const { user, authenticate, authClient } = useAuth()
    const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
    const pwlessLoggedIn =
      PASSWORDLESS_ENABLED && isPasswordlessLoggedIn(authClient)
    const handleImportAccount = useImportAccount()

    const guestName = user?.firstName
    const onClose = React.useCallback(() => {
      closeModal()
      if (pwlessLoggedIn && isFromEmailDetected) {
        showLoggedInNotification(guestName)
      }
    }, [pwlessLoggedIn, isFromEmailDetected, closeModal, guestName])

    return (
      <PoweredByToastModal header='Reset Password' onClose={onClose}>
        <Query query={IS_RESET_TOKEN_VALID} variables={{ resetToken }}>
          {({ data, error, loading, refetch }) => {
            if (loading) {
              return <Progress />
            }
            if (error) return <ErrorComponent error={error} retry={refetch} />
            const { isResetTokenValid } = data
            return (
              <Mutation mutation={RESET_PASSWORD}>
                {(reset, { loading: resetLoading, data = {} }) => {
                  const { ResetPasswordError } = dataByTypename(
                    data.resetPassword
                  )

                  if (
                    !isResetTokenValid ||
                    (ResetPasswordError &&
                      ResetPasswordError.code === 'INVALID_TOKEN')
                  ) {
                    return (
                      <ModeRedirect mode='forgot' state={{ expired: true }} />
                    )
                  }
                  return (
                    <Formik
                      initialValues={{ newPassword: '' }}
                      validationSchema={Yup.object().shape({
                        newPassword: passwordSchema
                      })}
                      onSubmit={async ({ newPassword }) => {
                        const input = {
                          variables: {
                            input: {
                              newPassword,
                              resetToken
                            }
                          }
                        }
                        const resp = await reset(input)
                        const { AuthenticationResponse } = dataByTypename(
                          resp.data.resetPassword
                        )
                        if (AuthenticationResponse) {
                          const authenticateInput = {
                            variables: {
                              input: {
                                ...AuthenticationResponse,
                                forAccountLinking:
                                  !isFromEnterYourPassword &&
                                  PASSWORDLESS_ENABLED
                              }
                            }
                          }
                          await authenticate(authenticateInput)

                          if (PASSWORDLESS_ENABLED) {
                            if (!isPasswordlessLoggedIn(authClient)) {
                              if (isFromEnterYourPassword) {
                                history.push('?mode=loginEasier', {
                                  from: FROM_LOCATION.enterYourPassword
                                })
                              }
                              return
                            } else {
                              await handleImportAccount({
                                onSuccess: () => {
                                  onClose()
                                  importSuccessToast()
                                },
                                onError: () => {
                                  onClose()
                                  importErrorToast()
                                }
                              })
                              return
                            }
                          }

                          onClose()
                        }
                      }}
                    >
                      {({ isValid }) => (
                        <Form>
                          {ResetPasswordError && (
                            <Notification severity='error'>
                              {ResetPasswordError.message}
                            </Notification>
                          )}
                          <Field
                            autoFocus
                            id='new-password'
                            name='newPassword'
                            type='password'
                            label='new password'
                            component={Input}
                            data-testid='new-password'
                          />
                          <Button
                            type={ButtonType.SUBMIT}
                            variant={ButtonVariant.PRIMARY}
                            responsive
                            disabled={!isValid}
                            loading={resetLoading}
                          >
                            Change
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </PoweredByToastModal>
    )
  }
)

ResetPasswordModal.displayName = 'ResetPasswordModal'

ResetPasswordModal.propTypes = {
  onClose: PropTypes.func,
  location: ReactRouterPropTypes.location
}
