import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Observer from '@researchgate/react-intersection-observer'

export const ViewableMonitor = ({ tag: Tag = 'div', children, ...rest }) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const handleChange = useCallback(
    ({ isIntersecting }) => setIntersecting(isIntersecting),
    [setIntersecting]
  )
  return (
    <Observer {...rest} onChange={handleChange}>
      <Tag>{children(isIntersecting)}</Tag>
    </Observer>
  )
}

ViewableMonitor.propTypes = {
  tag: PropTypes.node,
  children: PropTypes.func.isRequired
}
