import * as Yup from 'yup'

export const PHONE_REGEX = /^\(?[2-9][0-9][0-9]\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export const passwordSchema = Yup.string()
  .min(8, 'Must be at least 8 characters')
  .required('Required')

export const emailSchema = Yup.string()
  .trim()
  .email('Must be valid email')
  .required('Required')

export const deliveryInfoAddress2Schema = Yup.string()
  .label('Apt./Suite no.')
  .trim()
  .max(255)
  .default('')

export const deliveryInfoNotesSchema = Yup.string()
  .label('Delivery Instructions')
  .trim()
  .max(300)
  .default('')

export const phoneSchema = Yup.string()
  .test('validPhone', 'Must be a 10 digit number', (value) => {
    if (!value) return false
    const noWhiteSpace = value.replace(/\s/g, '')
    return PHONE_REGEX.test(noWhiteSpace)
  })
  .required('Required')

export const savedAddressSchema = Yup.object().shape({
  address2: deliveryInfoAddress2Schema,
  notes: deliveryInfoNotesSchema
})

export const createAccountValidationSchema = Yup.object().shape({
  phone: phoneSchema,
  email: emailSchema
})

export const loginValidationSchema = Yup.object().shape({
  phone: phoneSchema
})

export const unifiedCreateAccountValidationSchema = Yup.object().shape({
  phone: phoneSchema
})

export const phoneOrEmailSchema = Yup.string()
  .required('Phone number or email is required')
  .test(
    'validEmailOrPhone',
    'Phone number must be 10 digits long. Email must be a valid email',
    (value) => {
      if (!value) return false
      return emailSchema.isValidSync(value) || phoneSchema.isValidSync(value)
    }
  )

export const unifiedLoginValidationSchema = Yup.object().shape({
  phoneOrEmail: phoneOrEmailSchema
})
