import {
  ButtonCommonProps,
  ButtonContent,
  useButtonStyles
} from '@toasttab/buffet-pui-buttons'
import { useUniqueId } from '@toasttab/buffet-utils'
import * as React from 'react'
import { Link, LinkProps } from 'react-router-dom'

export interface RouterButtonProps extends LinkProps, ButtonCommonProps {}

export const RouterButton = ({
  children,
  size,
  variant,
  iconLeft,
  iconRight,
  testId,
  className,
  ...props
}: RouterButtonProps) => {
  testId = useUniqueId(testId, 'link-')
  const buttonStyleSettings = {
    size: size,
    variant: variant,
    className: className,
    hasIconRight: !!iconRight,
    hasLeftIcon: !!iconLeft,
    disabled: false
  }
  return (
    <Link
      data-testid={testId}
      className={useButtonStyles(buttonStyleSettings)}
      {...props}
    >
      <ButtonContent iconRight={iconRight} iconLeft={iconLeft}>
        {children}
      </ButtonContent>
    </Link>
  )
}
