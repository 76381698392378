import React from 'react'
import PropTypes from 'prop-types'
import { ErrorComponent } from '@local/do-secundo-error'
import styles from './FeedbackError.module.css'

const FEEDBACK_NOT_SUBMITTED =
  'Our system is having trouble submitting the feedback.'
const FEEDBACK_ALREADY_SUBMITTED =
  'It looks like you already gave feedback on this order! Feedback may only be submitted once per order.'
const UNKNOWN_ERROR = 'Our system is having trouble submitting the feedback.'

export const errorMessageMap = {
  FEEDBACK_NOT_SUBMITTED,
  FEEDBACK_ALREADY_SUBMITTED,
  UNKNOWN_ERROR
}

export const FeedbackError = ({ code = UNKNOWN_ERROR }) => {
  return (
    <div className={styles.errorContainer}>
      <ErrorComponent
        error={{
          message: errorMessageMap[code],
          retryMessage: code === 'FEEDBACK_ALREADY_SUBMITTED' ? ' ' : null
        }}
      />
    </div>
  )
}

FeedbackError.propTypes = {
  code: PropTypes.string.isRequired
}
