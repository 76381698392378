import React from 'react'
import PropTypes from 'prop-types'
import { POSITIVE, NEGATIVE, PASSIVE } from '../feedbackConstants'

import styles from './FeedbackResponseBanner.module.css'

export const feedbackMap = {
  [NEGATIVE]: (
    <div>
      Sorry to hear that.
      <br />
      What could have been better?
    </div>
  ),
  [PASSIVE]: (
    <div>
      Thanks for letting us know.
      <br />
      What could have been better?
    </div>
  ),
  [POSITIVE]: (
    <div>
      Glad to hear!
      <br />
      What made it great?
    </div>
  )
}

export const FeedbackResponseBanner = ({ feedbackRating }) => {
  const text = feedbackMap[feedbackRating]
  if (text) {
    return <div className={styles.response}>{text}</div>
  }
  return null
}

FeedbackResponseBanner.propTypes = {
  feedbackRating: PropTypes.string.isRequired
}
