import * as React from 'react'
import { toast } from 'react-toastify'
import {
  updateCartRequest$,
  dispatchCartResponse,
  dispatchCartDefaultError
} from 'cornucopia-apis'
import {
  useAddItemToCart,
  useEditItemInCart
} from '@local/do-secundo-cart-mutation'
import { logError } from '@local/do-secundo-error'
import { ToastNotification } from '../../../../client/components/Modifiers/ToastNotification/ToastNotification'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'

export interface CartListenerProps {
  restaurantGuid?: string
  cartGuid?: string
  cartFulfillmentInput?: Object
}

/**
 * Helper to manage the lifecycle of the listener. This is a component and not a hook
 * so that the provider can more easily manage when it is loaded and so we can use the
 * DOM to manage the lifecycle of the subscription. It requires we unsubscribe and resubscribe
 * when we rerender to avoid subscribing more than once.
 */
export const CartListener = ({
  restaurantGuid,
  cartGuid,
  cartFulfillmentInput
}: CartListenerProps) => {
  const [addItemToCart] = useAddItemToCart()
  const [editItemInCart] = useEditItemInCart()

  React.useEffect(() => {
    const updateCartSubscription = updateCartRequest$.subscribe(
      (request: any) => {
        const { selection, selectionGuid } = request
        let createCartInput
        if (!cartGuid) {
          createCartInput = {
            restaurantGuid,
            orderSource: 'ONLINE',
            cartFulfillmentInput
          }
        }
        const variables = {
          input: {
            cartGuid,
            createCartInput,
            selection: selection,
            ...(selectionGuid && { selectionGuid })
          }
        }

        const actionFn = selectionGuid
          ? editItemInCart({ variables })
          : addItemToCart({ variables })

        actionFn
          .then((responseData: any) => {
            const data = responseData.data
            if (!data || (!data.addItemToCartV2 && !data.editItemInCartV2)) {
              const fields =
                typeof data === 'object'
                  ? Object.keys(data).join(', ')
                  : 'undefined'
              logError(
                new Error(`Unknown response for addItemToCart (${fields}`)
              )

              dispatchCartDefaultError(request)
            } else {
              const responseData = data.addItemToCartV2 || data.editItemInCartV2
              const { CartResponse } = dataByTypename(responseData)
              if (CartResponse) {
                // TODO: handle this via corn-menu-spa when we start preloading
                const quantity = selection.quantity
                const text = `${quantity} item${quantity > 1 ? 's' : ''} ${
                  selectionGuid ? 'edited' : 'added'
                }`
                toast(<ToastNotification checkmark>{text}</ToastNotification>, { autoClose: 2500 })
              }
              dispatchCartResponse(request, responseData)
            }
          })
          .catch((e: Error) => {
            logError(e)
            dispatchCartDefaultError(request)
          })
      }
    )

    return () => {
      updateCartSubscription.unsubscribe()
    }
  }, [
    restaurantGuid,
    addItemToCart,
    cartGuid,
    cartFulfillmentInput,
    editItemInCart
  ])

  // Return an empty node instead of null so we don't immediately unmount and
  // unsubscribe from the subscription.
  return <></>
}
