import React from 'react'

import PropTypes from 'prop-types'

import { Field } from 'formik'
import { Input } from '@local/do-secundo-form'

export const InputGroup = ({ inputConfigs }) =>
  inputConfigs.map((input) => (
    <Field
      data-testid={input.id}
      key={input.id}
      {...input}
      component={input.component || Input}
    />
  ))

InputGroup.propTypes = {
  inputConfigs: PropTypes.array.isRequired
}
