import { createOutOfStockError } from '../../../client/utils/cart-helpers'

import {
  useCart,
  CLEAR_MODIFICATION_ERRORS,
  PUSH_MODIFICATION_ERRORS
} from '@local/do-secundo-cart-provider'

const onCartOutOfStock =
  ({ dispatch }) =>
  (cartOutOfStockError) => {
    dispatch({ type: CLEAR_MODIFICATION_ERRORS })
    dispatch({
      type: PUSH_MODIFICATION_ERRORS,
      modificationErrors: [createOutOfStockError(cartOutOfStockError)]
    })
  }

export const useGetOnCartOutOfStockCallback = () => {
  const cartProps = useCart()
  return onCartOutOfStock(cartProps)
}
