import React from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { LOYALTY_GET_COMPLETED_ORDER } from '../../LoyaltyConfirmation.graphql'

import Flare from '../../../../assets/flare.svg'
import styles from '../../LoyaltyConfirmation.module.css'

export const LoyaltyWelcome = ({ orderGuid }) => {
  const { restaurantGuid } = useRestaurant()
  const { data, loading, error } = useQuery(LOYALTY_GET_COMPLETED_ORDER, {
    variables: {
      input: {
        orderGuid,
        restaurantGuid
      }
    }
  })
  if (loading || error) return null

  const {
    loyaltyConfig: { loyaltySignupBonus }
  } = data.completedOrder.restaurant
  const description = loyaltySignupBonus
    ? `You're ${loyaltySignupBonus} points closer to your first reward. Check your email for more details.`
    : "You're one step closer to your first reward. Check your email for more details."
  return (
    <div className={styles.container}>
      <Flare />
      <h2 className={styles.title}>
        Welcome to this restaurant's reward program.
      </h2>
      <p className={styles.description}>{description}</p>
    </div>
  )
}

LoyaltyWelcome.propTypes = {
  orderGuid: PropTypes.string.isRequired
}
