import React from 'react'
import cx from 'classnames'
import styles from './TermsOfService.module.css'

interface TermsOfServiceProps {
  className?: string
}

export const TermsOfService = ({ className }: TermsOfServiceProps) => {
  return (
    <p
      data-testid='create-account-legal-copy'
      className={cx(styles.legalCopy, 'type-subhead', className)}
    >
      By signing up, you agree to Toast's{' '}
      <a
        href='https://pos.toasttab.com/terms-of-service#diner-tos'
        rel='noopener noreferrer'
        target='_blank'
        className={cx(styles.legalLink, 'type-default')}
        data-testid='link-terms-of-service'
      >
        Guest Terms of Service
      </a>{' '}
      and acknowledge that you have read our{' '}
      <a
        href='https://pos.toasttab.com/privacy'
        rel='noopener noreferrer'
        target='_blank'
        className={cx(styles.legalLink, 'type-default')}
        data-testid='link-privacy'
      >
        Privacy Statement
      </a>
      .
    </p>
  )
}
