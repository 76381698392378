import * as React from 'react'
const { createContext, useContext, useState } = React

const ItemContext = createContext({})

export const ItemProvider = ({ children }) => {
  const [itemSize, setItemSize] = useState({})

  return (
    <ItemContext.Provider value={{ itemSize, setItemSize }}>
      {children}
    </ItemContext.Provider>
  )
}

export const useItem = () => {
  return useContext(ItemContext)
}
