import * as React from 'react'
import { Dollars } from '@local/do-secundo-dollars'

export interface PriceTransparencyDisplayProps {
  price: number
}

export const PriceTransparencyDisplay = ({
  price
}: PriceTransparencyDisplayProps) => {
  const competitorPrice = Number((price * 1.3).toFixed(2))

  return (
    <div className='flex gap-1 items-baseline'>
      <Dollars amount={price} />
      <div className='line-through truncate type-subhead'>
        Up to <Dollars typography='type-subhead' amount={competitorPrice} />
        &nbsp;with DoorDash
      </div>
    </div>
  )
}
