import PropTypes from 'prop-types'
import React from 'react'
import { NetworkStatus } from '@apollo/client'
import cx from 'classnames'

import { CartButton } from '@local/do-secundo-cart-button'
import { useGetCart } from '../CartQuery/CartQuery'
import { Dollars } from '@local/do-secundo-dollars'
import { EmptyCart } from '../EmptyCart/EmptyCart'
import { ErrorComponent } from '@local/do-secundo-error'

import { ApprovalRules } from './ApprovalRules/ApprovalRules'
import { useApprovalRulesValidator } from './ApprovalRules/use-approval-rules-validator'
import { CartLoader } from './Cart.loader'
import { CartTable } from './CartTable/CartTable'
import { useReorder } from '../Reorder/ReorderProvider/ReorderProvider'
import { CartModificationError } from './CartModificationError/CartModificationError'
import { CartUpsells } from '../Upsells/CartUpsells'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useVariant } from '../../hooks/use-variant'
import { PriceTransparencyBanner } from '@toasttab/do-secundo-price-transparency-banner'

import styles from './Cart.module.css'
import { ValidateCart } from '../ValidateCart/ValidateCart'
import {
  XP_MENU_BADGES,
  XP_PRICE_TRANSPARENCY
} from '../ExperimentsProvider/experiment-definitions'
import { usePromoBannersSessionData } from 'cornucopia-apis'

export const Cart = React.memo(
  ({ checkoutLink, orderingAvailable, rectifyApprovalRuleLink, menuLink }) => {
    const { error, loading, refetch, networkStatus, cart, warnings } =
      useGetCart()
    const { restaurantInfo, restaurantGuid } = useRestaurant()
    let upsellsEnabled = false
    const priceTransparencyVariant = useVariant(
      XP_PRICE_TRANSPARENCY.experimentName
    )
    const canApplyPromoCode = !!useVariant(XP_MENU_BADGES.experimentName)
    const promoBannersSessionData = usePromoBannersSessionData(restaurantGuid)
    const { promoCode: promoCodeToApply } =
      promoBannersSessionData?.appliedPromoCode || {}
    const shouldValidateCart = !canApplyPromoCode || !promoCodeToApply

    if (
      restaurantInfo.data &&
      restaurantInfo.data.restaurant &&
      restaurantInfo.data.restaurant.upsellsConfig
    ) {
      upsellsEnabled = Boolean(
        restaurantInfo.data.restaurant.upsellsConfig.enabled
      )
    }

    const cartIsEmpty = !cart || cart.order.numberOfSelections === 0

    let cartSubtotal = !cartIsEmpty ? cart?.order?.itemsSubtotal : 0

    const priceTransparencyEnabled =
      priceTransparencyVariant === 1 && !cartIsEmpty
    const price = cart?.order?.subtotal

    const { valid: approvalRulesValid } = useApprovalRulesValidator({ cart })
    const {
      reorderMutation: [, { loading: reorderLoading }]
    } = useReorder()
    const cartHasWarnings = warnings && warnings.length > 0

    const checkoutButtonEnabled =
      orderingAvailable && approvalRulesValid && !cartHasWarnings

    if (loading || networkStatus === NetworkStatus.refetch) {
      return <CartLoader />
    }

    if (error) {
      return <ErrorComponent error={error} retry={refetch} />
    }

    return (
      <>
        <CartModificationError />
        {cartIsEmpty ? (
          <EmptyCart
            menuLink={menuLink}
            orderingAvailable={orderingAvailable}
          />
        ) : (
          <>
            <div className={styles.long}>
              {shouldValidateCart && <ValidateCart />}
              <CartTable cart={cart} editable />
            </div>
            <div className={styles.sticky}>
              {upsellsEnabled && <CartUpsells />}
              {priceTransparencyEnabled && (
                <div
                  data-testid='pt-cart-banner'
                  className={cx('pb-4', styles.ptBannerContainer)}
                >
                  <PriceTransparencyBanner price={price} cartTreatment={true} />
                </div>
              )}
              <ApprovalRules
                cart={cart}
                rectifyApprovalRuleLink={rectifyApprovalRuleLink}
              />
              <CartButton
                data-testid='cart-checkout-button'
                to={checkoutLink}
                left={
                  <span>
                    Continue to checkout ({cart.order.numberOfSelections})
                  </span>
                }
                right={
                  <span>
                    Subtotal: <Dollars amount={cartSubtotal} />
                  </span>
                }
                loading={reorderLoading}
                disabled={!checkoutButtonEnabled || reorderLoading}
              />
            </div>
          </>
        )}
      </>
    )
  }
)

Cart.displayName = 'Cart'

Cart.propTypes = {
  checkoutLink: PropTypes.string,
  orderingAvailable: PropTypes.bool,
  /**
   * If present, this path indicates where the user must navigate in order to rectify
   * an approval rule that is currently violated.
   */
  rectifyApprovalRuleLink: PropTypes.string
}
