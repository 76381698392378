import * as React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Header } from '../Header/Header'
import { CartPage } from '../CartPage/CartPage'
import { CheckoutPage } from '../CheckoutPage/CheckoutPage'
import { ConfirmPage } from '../ConfirmPage/ConfirmPage'
import { MenuPage } from '../MenuPage/MenuPage'
import { AccountPage } from '../AccountPage/AccountPage'
import { AddItem } from '../Modifiers/AddItem/AddItem'
import { MyOrdersPage } from '../MyOrdersPage/MyOrdersPage'
import { MyOrdersPagePasswordless } from '../MyOrdersPage/MyOrdersPagePasswordless'
import { ModeRouter } from '../ModeRouter/ModeRouter'
import { CartProvider } from '@local/do-secundo-cart-provider'
import { FulfillmentProvider } from '../FulfillmentProvider/FulfillmentProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { AuthenticatedRoute } from '../AuthenticatedRoute/AuthenticatedRoute'
import { WrapIfFlagOn, FF } from '@local/do-secundo-feature-flag'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { AvailabilityProvider } from '@local/do-secundo-availability-provider'
import { ReorderProvider } from '../Reorder/ReorderProvider/ReorderProvider'
import { GiftCardProvider } from '../GiftCardProvider/GiftCardProvider'
import { ExperimentsProvider } from '../ExperimentsProvider/ExperimentsProvider'
import { EditModeProvider } from '@local/do-secundo-edit-mode-provider'
import { Spotlight } from '../Spotlight/Spotlight'
import { IframeTracker } from '../IframeTracker/IframeTracker'
import { UpsellsProvider } from '../Upsells/UpsellsProvider'
import {
  upsellSortRules,
  upsellFilterRules,
  intelligentUpsellFilterRules
} from '../Upsells/upsell-rules'
// Removed temporarily per product for MPF
// import { TakeoutCTA } from '../TakeoutCTA/TakeoutCTA'
import { canMakeApplePayPayment } from '../ApplePay/apple-pay-utils'
import { addEventProperties } from '@toasttab/do-secundo-analytics'
import { FundraisingContextProvider } from '../TfgContextProvider/FundraisingContextProvider'
import { useVariant } from '../../hooks/use-variant'
import { XP_MEAL_SELECTOR } from '../ExperimentsProvider/experiment-definitions'
import {
  CornucopiaProvider,
  IfNotInCornucopiaRoute
} from '@local/do-secundo-cornucopia'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

export const RestaurantPage = ({ match, location, history }) => {
  const { path, url } = match
  const { state = {} } = location
  const { restaurantGuid } = useRestaurant()
  const restaurantStorage = useRestaurantStorage()
  const mealSelectorVariant = !!useVariant(XP_MEAL_SELECTOR.experimentName)
  const pathBase = path === '/' ? '' : path
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  const goToRestaurantHome = () => {
    if (state.internal) {
      history.go(-1)
    } else {
      history.replace(url)
    }
  }

  React.useEffect(() => {
    const canMakeApplePayPayments = canMakeApplePayPayment()
    addEventProperties({ canMakeApplePayPayments })
  }, [])

  const addItemRoute = `${pathBase}/add/:itemGuid/:itemGroupGuid`
  const editItemRoute = `${pathBase}/edit`

  const WrappedModifiers = ({
    match: {
      params: { itemGuid, itemGroupGuid }
    }
  }) => (
    <AddItem
      itemGuid={itemGuid}
      itemGroupGuid={itemGroupGuid}
      onClose={() => {
        if (mealSelectorVariant) {
          restaurantStorage.remove('toast-oo-meal-selector')
        }
        goToRestaurantHome()
      }}
    />
  )
  return (
    <ExperimentsProvider>
      <AvailabilityProvider>
        <CartProvider
          restaurantGuid={restaurantGuid}
          restaurantStorage={restaurantStorage}
        >
          <WrapIfFlagOn
            name={FF.GIFT_CARD_REDEMPTION}
            wrapper={(children) => (
              <GiftCardProvider>{children}</GiftCardProvider>
            )}
            defaultValue={false}
          >
            <FundraisingContextProvider>
              <WrapIfFlagOn
                name={FF.EDIT_MODE}
                wrapper={(children) => (
                  <EditModeProvider>{children}</EditModeProvider>
                )}
                defaultValue={false}
              >
                <>
                  <Route
                    exact
                    path={[addItemRoute, path]}
                    component={Spotlight}
                  />
                  <Header />
                  <IframeTracker />
                  <FulfillmentProvider>
                    <CornucopiaProvider restaurantGuid={restaurantGuid}>
                      <ReorderProvider>
                        <>
                          <ModeRouter />
                          <IfNotInCornucopiaRoute
                            path={addItemRoute}
                            component={WrappedModifiers}
                          />
                          <UpsellsProvider
                            filterRules={upsellFilterRules}
                            secondaryFilterRules={intelligentUpsellFilterRules}
                            sortRules={upsellSortRules}
                          >
                            <Switch>
                              <AuthenticatedRoute
                                path={`${pathBase}/account`}
                                component={AccountPage}
                              />

                              <Route
                                path={`${pathBase}/cart`}
                                component={CartPage}
                              />
                              <Route
                                path={`${pathBase}/checkout`}
                                component={CheckoutPage}
                              />
                              <AuthenticatedRoute
                                path={`${pathBase}/orders`}
                                component={
                                  PASSWORDLESS_ENABLED
                                    ? MyOrdersPagePasswordless
                                    : MyOrdersPage
                                }
                              />
                              <Route
                                path={`${pathBase}/confirm/:orderGuid`}
                                component={ConfirmPage}
                              />
                              <Route
                                exact
                                path={[addItemRoute, path, editItemRoute]}
                                component={MenuPage}
                              />
                              <Redirect to={path} />
                            </Switch>
                          </UpsellsProvider>
                          {/* Removed temporarily per product for MPF <TakeoutCTA /> */}
                        </>
                      </ReorderProvider>
                    </CornucopiaProvider>
                  </FulfillmentProvider>
                </>
              </WrapIfFlagOn>
            </FundraisingContextProvider>
          </WrapIfFlagOn>
        </CartProvider>
      </AvailabilityProvider>
    </ExperimentsProvider>
  )
}

RestaurantPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  history: ReactRouterPropTypes.history.isRequired
}
