import { useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import * as yup from 'yup'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { VALIDATE_MERCHANT } from './CheckoutApplePay.graphql'
import { useGetCart } from '../CartQuery/CartQuery'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useLineItems } from '../../utils/cart-helpers'

export const useApplePay = () => {
  const cartInfo = useGetCart()
  const { restaurantGuid, restaurantInfo } = useRestaurant()
  const [mutation] = useMutation(VALIDATE_MERCHANT)
  const getLineItems = useLineItems({
    cart: cartInfo.cart,
    showTip: true,
    showTotal: true,
    showFundraising: true
  })

  const loading = cartInfo.loading || restaurantInfo.loading
  const error = cartInfo.error || restaurantInfo.error

  const getConfig = useCallback(
    (tip, roundUp, fundraising) => {
      if (loading || error) return {}
      const allItems = getLineItems(tip, roundUp, fundraising)
      const { whiteLabelName, creditCardConfig } =
        restaurantInfo.data.restaurant
      const supportedNetworks = ['discover', 'masterCard', 'visa']
      if (creditCardConfig.amexAccepted) {
        supportedNetworks.push('amex')
      }
      const total = allItems.find(({ id }) => id === 'total-order-amount')
      const lineItems = allItems.filter(({ id }) => id !== 'total-order-amount')

      return {
        currencyCode: 'USD',
        countryCode: 'US',
        total: {
          label: whiteLabelName,
          amount: total.amount
        },
        requiredBillingContactFields: ['postalAddress'],
        requiredShippingContactFields: ['name', 'phone', 'email'],
        lineItems,
        supportedNetworks,
        merchantCapabilities: ['supports3DS']
      }
    },
    [error, getLineItems, loading, restaurantInfo.data]
  )

  const getMerchantSession = useCallback(
    async (validationURL) => {
      const response = await mutation({
        variables: {
          input: {
            validationURL,
            restaurantGuid,
            merchantDomain: window.location.hostname
          }
        }
      })

      const { ValidateApplePayMerchantSuccessResponse } = dataByTypename(
        response.data.validateApplePayMerchant
      )
      if (ValidateApplePayMerchantSuccessResponse) {
        const string = ValidateApplePayMerchantSuccessResponse.merchantSession
        const merchantSession = JSON.parse(string)
        return merchantSession
      }

      return {}
    },
    [mutation, restaurantGuid]
  )

  return { getMerchantSession, getConfig, loading, error }
}

export const useFormikValues = ({ formModules, context }) => {
  return useMemo(() => {
    const { schemaObject, initialValues } = formModules.reduce(
      (acc, formModule) => ({
        initialValues: {
          ...acc.initialValues,
          ...formModule.getInitialValues(context)
        },
        schemaObject: {
          ...acc.schemaObject,
          ...formModule.getValidationSchema(context)
        }
      }),
      {
        schemaObject: {
          paymentType: yup.string()
        },
        initialValues: {
          paymentType: 'CREDIT_CARD'
        }
      }
    )

    const validationSchema = yup.object().shape(schemaObject)
    return { validationSchema, initialValues }
  }, [context, formModules])
}

const update = 'Update your Apple Pay account information to continue.'
const requiredMessage = (key) => `Apple Pay ${key} is required. ${update}`
export const contactInfoSchema = yup.object().shape({
  emailAddress: yup
    .string()
    .email(`Apple Pay email address not valid. ${update}`)
    .required(requiredMessage('email address')),
  givenName: yup.string().required(requiredMessage('first name')),
  familyName: yup.string().required(requiredMessage('last name')),
  phoneNumber: yup
    .string()
    .trim()
    .required(requiredMessage('phone number'))
    .matches(
      /^\d{10}$/,
      `Apple Pay phone number must be a 10 digit number. ${update}`
    )
})
