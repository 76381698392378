import React, { useMemo } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'

import { useMenus } from '../../../hooks/use-menus'
import { usePageSearch } from '../../../hooks/use-page-search'
import { useUpdateSearchParamsAndHistory } from '../../../hooks/use-update-search-params-and-history'

const getQueryValueByTheKey = (key) => {
  const search = new URLSearchParams(window.location.search)
  return search.get(`${key}`) ? decodeURIComponent(search.get(`${key}`)) : ''
}

export const SearchContainer = ({ component: Component, ...props }) => {
  const { menus = [], loading } = useMenus()
  const menuItems = useMemo(
    () =>
      menus
        .reduce((acc, { groups }) => [...acc, ...groups], [])
        .reduce((acc, { items }) => [...acc, ...items], []),
    [menus]
  )

  const initialValue = useMemo(() => getQueryValueByTheKey('term'), [])
  const { term, results, setTerm } = usePageSearch({
    keys: ['name', 'description'],
    deduplicate: ({ item }) => item.guid,
    list: menuItems,
    initialValue
  })
  useUpdateSearchParamsAndHistory({ term })

  const childProps = {
    term,
    menus,
    loading,
    results,
    setTerm,
    initialValue,
    ...props
  }
  return <Component {...childProps} />
}
SearchContainer.propTypes = {
  match: ReactRouterPropTypes.match
}
