import React from 'react'
import { LearnMoreButton } from '@local/do-secundo-terms-modal'

import styles from './ContactlessDeliveryInstructions.module.css'

export const ALCOHOL_DELIVERY_INSTRUCTIONS =
  'Alcohol purchases must be 21+. ID verification is required for delivery.'

export interface AlcoholDeliveryInstructionsProps {
  textClassName?: string
}

export const AlcoholDeliveryInstructions = ({
  textClassName
}: AlcoholDeliveryInstructionsProps) => (
  <>
    <p className={textClassName}>{ALCOHOL_DELIVERY_INSTRUCTIONS}</p>
    <LearnMoreButton
      className={styles.infoButton}
      data-testid='alcohol-learn-more'
      onClick={() =>
        window.open(
          'https://toasttakeout.zendesk.com/hc/en-us/articles/8988692006419-How-does-alcohol-delivery-work-',
          '_blank'
        )
      }
    />
  </>
)
