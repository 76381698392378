import { useQuery } from '@apollo/client'
import { useFulfillment } from '../../../../client/components/FulfillmentProvider/FulfillmentProvider'
import { DO_MENU_ITEM_DETAILS } from './graphql/doMenuItem.graphql'

const useGetDoMenusItemDetails = ({
  restaurantGuid,
  itemGuid,
  itemGroupGuid,
  queryOptions
}) => {
  const { fulfillmentTime } = useFulfillment()
  const dateTime = fulfillmentTime || undefined

  const { loading, error, data, called } = useQuery(DO_MENU_ITEM_DETAILS, {
    notifyOnNetworkStatusChange: true,
    // no cache instead of cache-and-network
    // because of: https://github.com/apollographql/react-apollo/issues/3361
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        restaurantGuid,
        menuItemGuid: itemGuid,
        menuGroupGuid: itemGroupGuid,
        visibility: 'TOAST_ONLINE_ORDERING',
        dateTime
      }
    },
    ...queryOptions
  })
  return { loading, error, data, called }
}

export { useGetDoMenusItemDetails }
