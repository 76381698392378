import React, { useState, useCallback } from 'react'
import cx from 'classnames'

import { Modal } from '@local/do-secundo-modal'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { PhoneIcon } from '@toasttab/buffet-pui-icons'

import { formatPhoneNumber } from '../../../utils/phone-number'
import { useObserved } from './useObserved'

import styles from './ExperiencingIssuesModal.module.css'
import { ErrorModal } from '@local/do-secundo-error-modal'

type ExperiencingIssuesModalProps = {
  show: boolean
  autofireOffline: boolean
  whiteLabelName: string
  restaurantPhone: string
}

const ISSUES_TRACK_SEEN = 'Experiencing Issues Observed'
const AUTOFIRE_TRACK_SEEN = 'Autofire Issues Observed'

export const ExperiencingIssuesModal = ({
  show,
  autofireOffline,
  whiteLabelName,
  restaurantPhone
}: ExperiencingIssuesModalProps) => {
  const [showModal, setShowModal] = useState(show)

  let track = ISSUES_TRACK_SEEN
  if (autofireOffline) {
    track = AUTOFIRE_TRACK_SEEN
  }
  useObserved(track)

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  let msg =
    'We apologize for the inconvenience. Please contact the restaurant directly to order.'
  if (autofireOffline) {
    msg =
      'This issue is typically resolved in less than 5 minutes. If the error persists, contact the restaurant directly to order.'
  }

  return (
    showModal && (
      <ErrorModal
        testId='ExperiencingIssuesModalMessage'
        header="We're experiencing issues processing online orders"
        message={msg}
        buttonLabel='Ok'
        onClick={handleClose}
        hideIcon={true}
        showRxPhone={true}
        whiteLabelName={whiteLabelName}
        restaurantPhone={restaurantPhone}
      />
    )
  )
}
