import React from 'react'
import cx from 'classnames'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'

import styles from './Button.module.css'

export const enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset'
}

export const enum ButtonVariant {
  PRIMARY = 'primary',
  LINK = 'link',
  PRIMARY_WIDE = 'primaryWide',
  LINK_WIDE = 'linkWide',
  SECONDARY = 'secondary'
}
export interface ButtonProps {
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  responsive?: boolean
  type?: ButtonType
  variant?: ButtonVariant
  'data-testid'?: string
  id?: string
  eventData?: object
  className?: string
}

export const Button = ({
  children,
  disabled,
  loading,
  onClick = () => {},
  responsive = false,
  variant = ButtonVariant.LINK,
  type = ButtonType.BUTTON,
  className = '',
  ...props
}: ButtonProps) => {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event)
  }

  const loadingStyle = variant.includes(LoadingVariant.PRIMARY)
    ? LoadingVariant.PRIMARY
    : LoadingVariant.LINK

  return (
    <button
      type={type}
      className={cx(
        styles[variant],
        {
          [styles.loading]: loading,
          [styles.responsive]: responsive
        },
        className
      )}
      onClick={clickHandler}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Loading variant={loadingStyle} /> : children}
    </button>
  )
}

// Need to set DisplayName so HOC name
// doesn't interfere with tests
Button.displayName = 'Button'
