import * as React from 'react'
import cx from 'classnames'
import { useGetWindowSize } from '@local/do-secundo-use-get-window-size'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import { Tooltip } from '@toasttab/buffet-pui-tooltip'
import CelebrationSvg from './celebration.svg'
import PriceTransparency from './PriceTransparency.svg'

import styles from './PriceTransparencyCheckoutBanner.module.css'

export interface PriceTransparencyCheckoutBannerProps {
  savings: number
}

export const PriceTransparencyCheckoutBanner = ({
  savings
}: PriceTransparencyCheckoutBannerProps) => {
  const { isMobile, isDesktop } = useGetWindowSize()
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const openDrawer = () => {
    if (isMobile) {
      setDrawerOpen(true)
    }
  }

  return (
    <>
      <div
        data-testid='open-bottom-drawer-btn'
        className={cx(
          'shadow-lg flex justify-center items-center gap-x-4 text-primary-75 rounded-lg',
          {
            'p-4': isMobile,
            'px-4 h-14': isDesktop,
            'h-20': isMobile
          }
        )}
        onClick={openDrawer}
      >
        <CelebrationSvg />
        <div className='flex w-full flex-row justify-between flex-wrap'>
          <div className='type-subhead'>{`You're saving up to $${savings} by ordering with\u00A0Toast`}</div>
          {isDesktop ? (
            <Tooltip
              placement='top-end'
              content={() => (
                <div className='type-subhead'>
                  Restaurants pay less with us, so you pay less with them. Enjoy
                  better menu prices and fewer fees.
                </div>
              )}
            >
              <div className='font-medium type-subhead'>Learn More</div>
            </Tooltip>
          ) : (
            <div className='font-medium type-subhead'>Learn More</div>
          )}
        </div>
      </div>
      <BottomDrawer
        data-testid='price-transparency-checkout-drawer'
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className='flex flex-col items-center text-center px-6'>
          <div className='mb-8'>
            <PriceTransparency />
          </div>
          <div className={cx('mb-2 font-semibold text-xl', styles.title)}>
            Seriously, Toast saves you money
          </div>
          <div className='font-normal text-base'>
            Restaurants pay less with us, so you pay less with them. Enjoy
            better menu prices and fewer&nbsp;fees.
          </div>
        </div>
      </BottomDrawer>
    </>
  )
}
