import React from 'react'
import PropTypes from 'prop-types'

import { useAuth } from '../AuthProvider/AuthProvider'

export const AuthenticatedCore = ({ children, test }) => {
  const { authenticated } = useAuth()

  if (typeof children === 'function') {
    return test(authenticated) ? children() : null
  }
  return test(authenticated) ? children : null
}

const IfAuthenticated = ({ children }) => {
  const test = (authenticated) => authenticated
  return <AuthenticatedCore test={test} children={children} />
}

IfAuthenticated.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

const UnlessAuthenticated = ({ children }) => {
  const test = (authenticated) => !authenticated
  return <AuthenticatedCore test={test} children={children} />
}

UnlessAuthenticated.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired
}

export { IfAuthenticated, UnlessAuthenticated }
