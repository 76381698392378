import React from 'react'
import PropTypes from 'prop-types'

import { Field, connect } from 'formik'

import { useAuth } from '../../AuthProvider/AuthProvider'

import { AddressAutocomplete } from '../../AddressAutocomplete/AddressAutocomplete'
import { ErrorComponent } from '@local/do-secundo-error'
import { InputGroup } from '../../Form/InputGroup/InputGroup'
import { Checkbox } from '@local/do-secundo-form'

import { useNewAddressUpdater } from './use-new-address-updater'
import { CannedDeliveryInstructions } from './CannedDeliveryInstructions'
import { ContactlessDeliveryInstructions } from './ContactlessDeliveryInstructions'

const getInputs = ({ authenticated, values }) => {
  const inputs = [
    {
      id: 'customer_delivery_info_address2',
      label: 'Apt./Suite no.',
      name: 'customerDeliveryInfoAddress2',
      type: 'text'
    },
    {
      id: 'customer_delivery_info_notes',
      label: 'Delivery Instructions',
      name: 'customerDeliveryInfoNotes',
      type: 'text'
    },
    {
      id: 'canned_delivery_instructions',
      component: CannedDeliveryInstructions,
      content: <ContactlessDeliveryInstructions />,
      header: 'Request contactless delivery'
    }
  ]

  if (authenticated) {
    inputs.push({
      id: 'customer_delivery_info_save_address',
      name: 'customerDeliveryInfoSaveAddress',
      component: Checkbox,
      label: <span>Save address</span>
    })

    if (values.customerDeliveryInfoSaveAddress) {
      inputs.push({
        id: 'customer_delivery_info_name',
        name: 'customerDeliveryInfoName',
        label: 'Address label*',
        type: 'text'
      })
    }
  }

  return inputs
}

const NewAddressImpl = ({ formik: { values } }) => {
  const { authenticated } = useAuth()
  const { error } = useNewAddressUpdater({
    address: values.customerDeliveryInfoAddress,
    address2: values.customerDeliveryInfoAddress2,
    notes: values.customerDeliveryInfoNotes
  })

  return (
    <>
      <Field
        id='customer_delivery_info_address'
        name='customerDeliveryInfoAddress'
        component={AddressAutocomplete}
      />
      <InputGroup inputConfigs={getInputs({ authenticated, values })} />
      {error && <ErrorComponent error={error} />}
    </>
  )
}

NewAddressImpl.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      customerDeliveryInfoAddress: PropTypes.object,
      customerDeliveryInfoAddress2: PropTypes.string,
      customerDeliveryInfoNotes: PropTypes.string,
      customerDeliveryInfoSaveAddress: PropTypes.bool
    }).isRequired
  }).isRequired
}

export const NewAddress = connect(NewAddressImpl)
NewAddress.displayName = 'NewAddress'
