import React from 'react'
import { Button } from '@local/do-secundo-button'
import { Modal } from '@local/do-secundo-modal'
import { Dollars } from '@local/do-secundo-dollars'
import { CardContainer } from './CardContainer'
import { getFeesList } from '../../../utils/cart-helpers'
import styles from './CartFooter.module.css'
import cx from 'classnames'

export const FeesModal = ({ onClose, data }) => {
  const fees = getFeesList(data)
  return (
    <Modal
      responsive={false}
      showHeader={false}
      modalStyles={styles.feesModal}
      bodyStyles={styles.feesBody}
      footer={
        <div className='px-6 p-5 border-0 border-t border-solid'>
          <div className='rounded-lg'>
            <Button
              onClick={onClose}
              variant='primary'
              buttonStyles={cx('rounded-lg')}
              id='confirm-button'
            >
              Got It
            </Button>
          </div>
        </div>
      }
    >
      <div
        className={cx(styles.feesScroll, 'sticky self-start h-10 top-0')}
      ></div>
      <div
        className={cx(
          styles.feesInfo,
          'pt-20 -mt-14 -z-10 overflow-y-scroll h-auto overflow-x-hidden'
        )}
      >
        <h2 className='pt-5 mx-8 w-5/6 text-center text-2xl font-semibold bg-white'>
          What fees are there?
        </h2>
        {fees.map(({ name, amount, id }) => {
          return (
            <CardContainer title={name} amount={amount} testId={id} key={id} />
          )
        })}
      </div>
    </Modal>
  )
}
