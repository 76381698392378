import {
  ADD_ITEM_TO_CART,
  EDIT_ITEM_IN_CART,
  PLACE_CASH_ORDER,
  PLACE_CC_ORDER
} from './cart.graphql'
import { selectionItemDetails } from '../menu/menuitem.graphql'

export const cartResolvers = {
  Mutation: {
    // TODO: remove this client-side mutation once updating dining option is part of the BFF mutation
    addItemToCartAndUpdateDiningOption: async (_, { input }, { client }) => {
      let lastOperationDataName = 'addItemToCartV2'

      let result = await client.mutate({
        mutation: ADD_ITEM_TO_CART,
        variables: { input }
      })
      if (result.error) {
        return result
      }

      return result.data[lastOperationDataName]
    },
    editQuantityOfItemInCart: async (_, { input, quantity }, { client }) => {
      const { cartGuid, selectionGuid } = input
      const {
        data: { selectionItemDetails: selection }
      } = await client.query({
        query: selectionItemDetails,
        variables: { input },
        fetchPolicy: 'no-cache'
      })
      selection.quantity = quantity
      // Todo: remove this remapping when editItemInCart mutation is replaced
      selection.specialInstructions = selection.specialRequest
      delete selection.specialRequest
      // Generate modifierGroups structure that only contains selected modifiers
      // and non-empty modifierGroups
      selection.modifierGroups = selection.modifierGroups
        .map(({ guid, modifiers }) => ({
          guid,
          modifiers: modifiers
            .filter((m) => m.selected)
            .map(({ itemGuid, itemGroupGuid }) => ({
              itemGroupGuid,
              itemGuid,
              modifierGroups: [],
              quantity
            }))
        }))
        .filter(({ modifiers }) => modifiers.length > 0)
      delete selection.guid
      delete selection.name
      delete selection.__typename
      const editItemInCartResult = await client.mutate({
        mutation: EDIT_ITEM_IN_CART,
        variables: { input: { cartGuid, selectionGuid, selection } }
      })
      return editItemInCartResult.data.editItemInCart
    },
    placeOrder: async (_, { cashInput, ccInput, paymentType }, { client }) => {
      let placeOrderResult
      if (paymentType === 'CREDIT') {
        placeOrderResult = await client.mutate({
          mutation: PLACE_CC_ORDER,
          variables: { input: ccInput }
        })
        return placeOrderResult.data.placeCcOrder
      } else {
        placeOrderResult = await client.mutate({
          mutation: PLACE_CASH_ORDER,
          variables: { input: cashInput }
        })
        return placeOrderResult.data.placeCashOrder
      }
    }
  }
}
