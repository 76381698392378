import React, { useCallback, useState, useMemo } from 'react'
import { Formik, Form } from 'formik'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'

import {
  PLACE_APPLE_PAY_ORDER,
  GET_CART_FOR_CHECKOUT
} from '../../apollo/cart/cart.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useGetCart } from '../CartQuery/CartQuery'
import { Fieldset } from '@local/do-secundo-fieldset'
import { CartTable } from '../Cart/CartTable/CartTable'
import { CartFooter } from '../Cart/CartFooter/CartFooter'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  useApplePay,
  contactInfoSchema,
  useFormikValues
} from './checkout-helpers'
import { makeApplePayPayment } from '../ApplePay/apple-pay-utils'
import { useSavedAddresses } from '../use-saved-addresses/use-saved-addresses'
import { Progress } from '@local/do-secundo-progress'
import * as CheckoutDeliveryInfo from '../CheckoutForm/CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import * as Tip from '../CheckoutForm/Tip/Tip'
import * as CurbsidePickup from '../CheckoutForm/CurbsidePickup/CurbsidePickup'
import * as DeliveryCommunicationConsent from '../CheckoutForm/DeliveryCommunicationConsent/DeliveryCommunicationConsent'
import * as CheckoutApplePayCreateAccountHelpers from './CheckoutApplePayCreateAccountHelpers'
import { ErrorComponent } from '@local/do-secundo-error'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useTrackPurchase } from '../../utils/checkout-helpers'
import { useCurbsidePickup } from '../use-curbside-pickup/use-curbside-pickup'
import * as Fundraising from '../CheckoutForm/Fundraising/Fundraising'
import styles from './CheckoutApplePay.module.css'
import { getRawPhoneNumber } from '@local/do-secundo-form-utils'
import { useFundraisingContext } from '../TfgContextProvider/FundraisingContextProvider'

import { LegalCopy } from '../LegalCopy/LegalCopy'
import { SmsCommunicationConsent } from '../CheckoutForm/SmsCommunicationConsent/SmsCommunicationConsent'
import { UnlessAuthenticated } from '../IfAuthenticated/IfAuthenticated'
import {
  CreateAccountCheckbox,
  FROM_LOCATION,
  START_PROFILE_CREATION
} from '@local/do-secundo-passwordless-authentication'
import { useAuth } from '../AuthProvider/AuthProvider'
import { handleCreatePasswordlessAccount } from '../CheckoutPage/utils'
import { FF, useFlag } from '@local/do-secundo-feature-flag'

const formModules = [
  CheckoutDeliveryInfo,
  CurbsidePickup,
  DeliveryCommunicationConsent,
  Tip,
  Fundraising,
  CheckoutApplePayCreateAccountHelpers
]
export const CheckoutApplePay = () => {
  const featureFlags = {
    'oo-use-fundraising-config': useFlag(FF.USE_FUNDRAISING_CONFIG, false)
  }
  const { curbsidePickupConfig, ...curbside } = useCurbsidePickup()
  const trackPurchase = useTrackPurchase()
  const [errorMessage, setErrorMessage] = useState()
  const fulfillmentContext = useFulfillment()
  const { savedAddresses } = useSavedAddresses()
  const { loading, error, getConfig, getMerchantSession } = useApplePay()
  const history = useHistory()
  const { getRestaurantPath } = useRestaurant()
  const { setFundraisingConfig } = useFundraisingContext()
  const { setHasErrorFromCheckout, setUnverifiedCustomer, authClient } =
    useAuth()
  const [checkout] = useMutation(PLACE_APPLE_PAY_ORDER)
  const [startProfileCreation] = useMutation(START_PROFILE_CREATION)
  const ENABLE_APPLE_PAY_CREATE_ACCOUNT = useFlag(
    FF.ENABLE_APPLE_PAY_CREATE_ACCOUNT
  )

  const {
    cart,
    deleteCartGuid,
    loading: cartLoading,
    error: cartError
  } = useGetCart(GET_CART_FOR_CHECKOUT)
  const context = useMemo(
    () => ({
      cart,
      featureFlags,
      fulfillmentContext,
      savedAddresses,
      curbsidePickupConfig
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, fulfillmentContext, savedAddresses, curbsidePickupConfig]
  )

  const { initialValues, validationSchema } = useFormikValues({
    context,
    formModules
  })

  const {
    creditCardConfig = {},
    tfgConfig = {},
    fundraisingConfig = {}
  } = cart.restaurant

  const campaignInfo = useFlag(FF.USE_FUNDRAISING_CONFIG, false)
    ? fundraisingConfig
    : tfgConfig

  const getPerformTransaction = useCallback(
    (values, { setSubmitting }) =>
      async (payment) => {
        const { shippingContact } = payment
        shippingContact.phoneNumber = getRawPhoneNumber(
          shippingContact.phoneNumber
        )
        let customerGuid
        setHasErrorFromCheckout(false)

        if (
          ENABLE_APPLE_PAY_CREATE_ACCOUNT &&
          CheckoutApplePayCreateAccountHelpers.shouldCreateAnAccount(values)
        ) {
          try {
            customerGuid = await handleCreatePasswordlessAccount({
              startProfileCreation,
              setErrorMessage,
              history,
              setUnverifiedCustomer,
              authClient,
              ccInput: {
                customer: {
                  email: shippingContact.emailAddress,
                  firstName: shippingContact.givenName,
                  lastName: shippingContact.familyName,
                  phone: shippingContact.phoneNumber
                }
              },
              isPasswordlessAccount: true
            })
          } catch (err) {
            console.warn(err)
          }
          if (!customerGuid) {
            setHasErrorFromCheckout(true)
          }
        }

        try {
          await contactInfoSchema.validate(shippingContact)

          const initialContactInfo = {
            cartGuid: cart.guid,
            pkPaymentToken: JSON.stringify(payment),
            customer: {
              email: shippingContact.emailAddress,
              firstName: shippingContact.givenName,
              lastName: shippingContact.familyName,
              phone: shippingContact.phoneNumber
            },
            customerGuid
          }
          const response = await checkout({
            variables: {
              input: formModules.reduce((acc, formModule) => {
                return {
                  ...acc,
                  ...formModule.getArgsForSubmit({ ...context, values })
                }
              }, initialContactInfo)
            }
          })

          const {
            PlaceOrderResponse,
            PlaceOrderError,
            PlaceOrderCartUpdatedError
          } = dataByTypename(response.data.placeApplePayOrder)
          const error = PlaceOrderError || PlaceOrderCartUpdatedError
          if (error) {
            throw new Error(error.message)
          } else {
            deleteCartGuid()
            const { guid } = PlaceOrderResponse.completedOrder
            trackPurchase({
              ...PlaceOrderResponse.completedOrder,
              createPasswordlessAccount:
                ENABLE_APPLE_PAY_CREATE_ACCOUNT &&
                Boolean(values.shouldSavePasswordlessAccount),
              usedApplePay: true
            })
            if (ENABLE_APPLE_PAY_CREATE_ACCOUNT && customerGuid) {
              const guestPhone = shippingContact.phoneNumber
              history.replace(getRestaurantPath(`confirm/${guid}`), {
                from: FROM_LOCATION.createAccountCheckout,
                guestPhone
              })
            } else {
              history.replace(getRestaurantPath(`confirm/${guid}`))
            }
          }
        } catch (error) {
          setErrorMessage(error)
          throw error
        } finally {
          setSubmitting(false)
        }
      },
    [
      cart.guid,
      checkout,
      context,
      deleteCartGuid,
      getRestaurantPath,
      history,
      trackPurchase
    ]
  )

  if (loading || cartLoading || curbside.loading) return <Progress />
  const displayError = error || cartError || curbside.error
  if (displayError) return <ErrorComponent error={displayError} />

  const isFundraisingEnabled = campaignInfo.active

  setFundraisingConfig(campaignInfo)

  const canShowTip = creditCardConfig.tipEnabled
  const showDeliveryConsent =
    cart &&
    cart.deliveryProviderInfo &&
    cart.deliveryProviderInfo.needsDeliveryCommunicationConsent
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const { tipAmount } = Tip.getArgsForSubmit({ values, cart })
        const { tfgInput, fundraisingInput } = Fundraising.getArgsForSubmit({
          values,
          cart,
          featureFlags
        })
        const config = getConfig(
          tipAmount,
          tfgInput?.estimatedRoundUpValue || fundraisingInput?.fundraisingAmount
        )
        const performTransaction = getPerformTransaction(values, {
          setSubmitting
        })
        const onCancel = () => setSubmitting(false)
        makeApplePayPayment({
          config,
          getMerchantSession,
          performTransaction,
          onCancel
        })
      }}
    >
      {({ values, isSubmitting, setFieldValue, setValues }) => (
        <Form>
          <>
            {!showDeliveryConsent && (
              <div className={styles.consent}>
                <SmsCommunicationConsent consentType='ApplePay' />
              </div>
            )}
            <Fieldset
              label={`Your Order (${cart.order.numberOfSelections})`}
              collapsable
              collapsed
            >
              <CartTable cart={cart} />
            </Fieldset>
            <CurbsidePickup.Component
              curbsidePickupConfig={curbsidePickupConfig}
            />
            <CheckoutDeliveryInfo.Component savedAddresses={savedAddresses} />
            {showDeliveryConsent && (
              <div className={styles.consent}>
                <DeliveryCommunicationConsent.Component cart={cart} />
              </div>
            )}

            {canShowTip && <Tip.Component />}
            {isFundraisingEnabled && (
              <Fundraising.Component
                cart={cart}
                campaignName={campaignInfo.campaignName}
                campaignDescription={campaignInfo.campaignDescription}
                campaignLogoURL={campaignInfo.campaignLogoURL}
                donationType={campaignInfo.donationType}
                donationAmounts={campaignInfo.donationAmounts}
                tip={(canShowTip && parseFloat(values.paymentTip)) || 0}
                setFieldValue={setFieldValue}
                values={values}
              />
            )}
            <CartFooter
              showTip={canShowTip}
              tip={parseFloat(values.paymentTip) || null}
              cart={cart}
              showTotal
              showFundraising={
                isFundraisingEnabled && values.isRoundUpForCharity
              }
              roundUp={values.roundUpAmount}
              fundraising={
                isFundraisingEnabled && values.fundraisingAmount
                  ? parseFloat(values.fundraisingAmount)
                  : null
              }
            />
            {ENABLE_APPLE_PAY_CREATE_ACCOUNT && (
              <UnlessAuthenticated>
                <CreateAccountCheckbox
                  name='shouldSavePasswordlessAccount'
                  onSelect={() =>
                    setValues({
                      ...values,
                      shouldSavePasswordlessAccount:
                        !values.shouldSavePasswordlessAccount
                    })
                  }
                />
              </UnlessAuthenticated>
            )}
            {errorMessage && <ErrorComponent error={errorMessage} />}
            <LegalCopy className={'relative z-10'} />
            <button
              disabled={isSubmitting}
              type='submit'
              className={styles.applePay}
              data-testid='apple-pay-checkout-button'
            />
          </>
        </Form>
      )}
    </Formik>
  )
}
