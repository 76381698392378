import React from 'react'
import PropTypes from 'prop-types'

import { formatDeliveryProvider } from '../../utils/delivery-provider-helpers'

import styles from './DeliveryProviderLabel.module.css'

export const DeliveryProviderLabel = ({ deliveryProvider }) => {
  if (!deliveryProvider) return null
  if (deliveryProvider === 'TOAST') return null
  return (
    <span className={styles.deliveryProvider}>
      Delivered by {formatDeliveryProvider(deliveryProvider)}
    </span>
  )
}

DeliveryProviderLabel.propTypes = {
  deliveryProvider: PropTypes.string
}
