import React from 'react'

import Flare from '../../../../assets/flare.svg'
import styles from '../../LoyaltyConfirmation.module.css'

export const LoyaltyRewardsEarned = () => {
  return (
    <div className={styles.container}>
      <Flare />
      <h2 className={styles.title}>You earned points with this order!</h2>
      <p className={styles.description}>
        Check your email receipt for more details.
      </p>
    </div>
  )
}
