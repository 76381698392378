import React, { useState, useCallback, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Form, Field, Formik } from 'formik-latest'

import { useHandleSubmit } from '../modifier-helpers'
import { useModifierFormHelpers } from '@local/do-secundo-use-modifier-form-helpers'
import { Modal } from '@local/do-secundo-modal'
import {
  ModifierBody,
  FractionalItemQuantity,
  NestedModifiersProvider,
  ModifiersFooter,
  ModifiersHeader,
  RemoveItemButton,
  LoadingModal,
  ModifierBodyPricing
} from '@local/do-secundo-modifiers'
import { ItemQuantity } from '@local/do-secundo-itemquantity'

import { ErrorModal } from '@local/do-secundo-modifiers'
import { PanelStack } from '@local/do-secundo-panel-stack'
import { useCart } from '@local/do-secundo-cart-provider'
import { PanelProvider } from '@local/do-secundo-panel-stack'
import { MenuItemDetails } from '@local/do-secundo-model'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { getItemPrice } from '@local/do-secundo-pricingutility'
import { useGetDoMenuItemData } from '@local/do-secundo-use-get-do-menu-item-data'
import { ItemProvider } from '@local/do-secundo-item-provider'

export const UpdateItemFormModal = ({
  updateCart,
  error,
  itemDetailsQuery,
  getItemDetails,
  getBasePrice,
  onClose,
  itemGuid,
  itemGroupGuid,
  selectionGuid,
  mayDelete,
  isUpsell = false
}) => {
  const PMM_ENABLED = useFlag(FF.PIZZA_MENU_MANAGEMENT)

  const { cartGuid } = useCart()
  const { restaurantInfo, restaurantGuid } = useRestaurant()

  const [modalError, setModalError] = useState(null)

  const {
    loading: doMenusLoading,
    error: doMenusError,
    data: doMenusData,
    transformedMenuData
  } = useGetDoMenuItemData({
    restaurantGuid,
    itemGuid,
    itemGroupGuid,
    queryOptions: {
      skip: !PMM_ENABLED
    }
  })
  const ModifierBodyComponent = PMM_ENABLED ? ModifierBodyPricing : ModifierBody

  // Coerce from data to model
  const itemDetailsData = getItemDetails(itemDetailsQuery.data, {
    itemGuid,
    itemGroupGuid,
    selectionGuid,
    restaurantGuid
  })
  const itemDetails = useMemo(
    () => (itemDetailsData ? new MenuItemDetails(itemDetailsData) : null),
    [itemDetailsData]
  )

  const basePrice = getBasePrice(itemDetailsQuery.data)

  const restaurant = restaurantInfo.data && restaurantInfo.data.restaurant

  const { usesFractionalQuantity, fractionalQuantity, unitOfMeasure } =
    itemDetails || {}

  const { getComputedPrice, getModifierGroups, formikProps, getSelectedNames } =
    useModifierFormHelpers({
      itemDetails,
      itemGroupGuid,
      usesFractionalQuantity,
      fractionalQuantity
    })

  const handleSubmit = useHandleSubmit({
    itemDetails,
    restaurant,
    getComputedPrice,
    basePrice,
    cartGuid,
    itemGuid,
    itemGroupGuid,
    getModifierGroups,
    updateCart,
    selectionGuid
  })

  const getDisplayPrice = useCallback(
    (values) => {
      if (PMM_ENABLED && !usesFractionalQuantity) {
        if (!doMenusLoading && !doMenusError && doMenusData) {
          const { menuItem } = doMenusData
          const itemModifierGroups = getModifierGroups(
            values,
            values.quantity,
            false
          )
          const itemPrice = getItemPrice({
            menuItem,
            itemModifierGroups,
            quantity: values.quantity,
            transformedMenuData
          })
          return itemPrice
        }
      }

      if (usesFractionalQuantity) {
        const fractionalBasePrice = basePrice * values.fractionalQuantity
        const modifierPrice = getComputedPrice(values, values.quantity)

        return fractionalBasePrice + modifierPrice
      }

      return (
        (getComputedPrice(values, values.quantity) + basePrice) *
        values.quantity
      )
    },
    [
      basePrice,
      getComputedPrice,
      usesFractionalQuantity,
      PMM_ENABLED,
      doMenusData,
      doMenusError,
      doMenusLoading,
      getModifierGroups,
      transformedMenuData
    ]
  )

  const [modalBody, setModalBodyFromModal] = useReducer((_, modalComponent) => {
    if (modalComponent) {
      return modalComponent.modalBodyRef.current
    }
  }, undefined)

  if (
    itemDetailsQuery.loading ||
    !itemDetailsQuery.called ||
    restaurantInfo.loading ||
    doMenusLoading
  ) {
    return <LoadingModal onClose={onClose} />
  }
  if (itemDetailsQuery.error || restaurantInfo.error || doMenusError) {
    return (
      <ErrorModal
        onClose={onClose}
        error={itemDetailsQuery.error || doMenusError}
        retry={itemDetailsQuery.refetch}
      />
    )
  }

  const fractionalQuantityInput = usesFractionalQuantity ? (
    <Field
      name='fractionalQuantity'
      component={FractionalItemQuantity}
      unitOfMeasure={unitOfMeasure}
      price={basePrice}
    />
  ) : null

  const quantityInput = usesFractionalQuantity ? null : (
    <Field name='quantity' component={ItemQuantity} />
  )
  return (
    <Formik {...formikProps} onSubmit={handleSubmit}>
      <PanelProvider>
        <ItemProvider>
          <NestedModifiersProvider
            doMenuItemDetails={doMenusData}
            getModifierGroups={getModifierGroups}
            transformedMenuData={transformedMenuData}
          >
            <Modal
              header={<ModifiersHeader itemDetails={itemDetails} />}
              error={error || modalError}
              wrapper={(children) => <Form>{children}</Form>}
              onClose={onClose}
              footer={
                <ModifiersFooter
                  itemDetails={itemDetails}
                  getDisplayPrice={getDisplayPrice}
                  onClose={onClose}
                  additionalText={isUpsell ? ' And Checkout' : ''}
                  isEditModal={mayDelete}
                  quantityInputField={quantityInput}
                  validationSchema={formikProps.validationSchema}
                />
              }
              ref={setModalBodyFromModal}
            >
              <PanelStack panelBody={modalBody}>
                {/* Children of PanelStack will not re-render according to the expected React tree pattern */}
                <ModifierBodyComponent
                  transformedMenuData={transformedMenuData}
                  doMenuItemDetails={doMenusData}
                  getModifierGroups={getModifierGroups}
                  itemDetails={itemDetails}
                  specialRequestsConfig={restaurant.specialRequestsConfig}
                  getSelectedNames={getSelectedNames}
                  quantityInputField={fractionalQuantityInput}
                  deleteButton={
                    mayDelete && (
                      <RemoveItemButton
                        itemDetails={itemDetails}
                        updateError={setModalError}
                        handleDeleteParams={{
                          selectionGuid,
                          getComputedPrice,
                          basePrice
                        }}
                      />
                    )
                  }
                />
              </PanelStack>
            </Modal>
          </NestedModifiersProvider>
        </ItemProvider>
      </PanelProvider>
    </Formik>
  )
}

const itemDetailsQueryShape = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object
})

UpdateItemFormModal.propTypes = {
  updateCart: PropTypes.func.isRequired,
  error: PropTypes.object,
  itemDetailsQuery: itemDetailsQueryShape.isRequired,
  getItemDetails: PropTypes.func.isRequired,
  getBasePrice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  itemGuid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  // for editing
  selectionGuid: PropTypes.string,
  mayDelete: PropTypes.bool,
  isUpsell: PropTypes.bool
}
