import React, { useState } from 'react'

import { NavigationDrawer } from '../NavigationDrawer/NavigationDrawer'
import { useAuth } from '../AuthProvider/AuthProvider'
import HamburgerIcon from '../../assets/hamburger.svg'

import styles from './Hamburger.module.css'
import { onEnterOrSpace } from '@local/do-secundo-key-handler'

export const Hamburger = () => {
  const { user, authenticated } = useAuth()
  const [isOpen, setOpen] = useState()
  const keyHandler = onEnterOrSpace(() => setOpen(true))
  return (
    <>
      <div
        data-testid='hamburger'
        onClick={() => setOpen(true)}
        onKeyPress={keyHandler}
        className={styles.hamburger}
        tabIndex='0'
        role='button'
        aria-label='Options'
        id='hamburger'
      >
        <HamburgerIcon className={styles.icon} />
        {authenticated && (
          <p
            data-testid='hamburger-welcome-user-name'
            className={styles.welcome}
          >
            Welcome{user?.firstName ? `, ${user.firstName}` : ''}!
          </p>
        )}
      </div>
      {isOpen && <NavigationDrawer onClose={() => setOpen(false)} />}
    </>
  )
}
