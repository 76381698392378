import React from 'react'
import PropTypes from 'prop-types'

import { Notification } from '@local/do-secundo-notification'
import { useDeliveryDistanceValidator } from '../../fulfillment-helpers'

export const DiningOptionError = ({ deliveryInfo, diningOptionBehavior }) => {
  const { valid, message } = useDeliveryDistanceValidator(
    deliveryInfo,
    diningOptionBehavior
  )
  return (
    !valid && (
      <Notification severity='warning'>
        {message || 'Unknown Error'}
      </Notification>
    )
  )
}

DiningOptionError.propTypes = {
  diningOptionBehavior: PropTypes.string,
  deliveryInfo: PropTypes.object
}
