import { useQuery } from '@apollo/client'

import { DINING_OPTIONS } from './graphql/fulfillment.graphql'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const DINING_OPTION_BEHAVIORS = {
  DELIVERY: 'DELIVERY',
  TAKE_OUT: 'TAKE_OUT'
}

export const DINING_OPTION_STRINGS = {
  [DINING_OPTION_BEHAVIORS.DELIVERY]: 'Delivery',
  [DINING_OPTION_BEHAVIORS.TAKE_OUT]: 'Pickup'
}

export const useDiningOptions = ({ networkOnly = false } = {}) => {
  const { restaurantGuid } = useRestaurant()
  const diningOptionsInput = {
    input: {
      restaurantGuid,
      includeBehaviors: [] // lol
    }
  }

  return useQuery(DINING_OPTIONS, {
    variables: diningOptionsInput,
    fetchPolicy: networkOnly ? 'network-only' : 'cache-first'
  })
}
