import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { CartButton } from '@local/do-secundo-cart-button'
import { Dollars } from '@local/do-secundo-dollars'

import { useNestedModifiers } from '../NestedModifiersProvider/NestedModifiersProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'

import { MenuItemDetailsShape, ModifierShape } from '@local/do-secundo-model'

import styles from './ModifiersFooter.module.css'
import { useFormikContext } from 'formik-latest'

export const ModifiersFooter = ({
  getDisplayPrice,
  onClose,
  itemDetails,
  additionalText = '',
  isEditModal = false,
  quantityInputField = null,
  validationSchema
}) => {
  const {
    nestedModifier,
    nestedModifierIsValid,
    confirmNestedModifierSelections,
    modalLoading
  } = useNestedModifiers()
  // default to an object in case component is called outside of the formik context
  // allows us to hit a safety check on line 68
  const { values, isSubmitting } = useFormikContext() || {}
  const { loading: availabilityLoading, orderingAvailable } = useAvailability()

  if (nestedModifier) {
    return (
      <Button
        type={ButtonType.BUTTON}
        variant={ButtonVariant.PRIMARY}
        disabled={!nestedModifierIsValid}
        onClick={confirmNestedModifierSelections}
        data-testid='nested-modifier-add-button'
      >
        Continue
      </Button>
    )
  }

  if (!orderingAvailable) {
    return (
      <Button
        data-testid='close-modifiers-button'
        variant={ButtonVariant.PRIMARY}
        onClick={onClose}
      >
        Close
      </Button>
    )
  }

  if (!values) return null
  const isLoading =
    isSubmitting || modalLoading || availabilityLoading || !itemDetails

  let buttonText = isEditModal ? 'Update Cart' : 'Add to Cart'
  buttonText += additionalText

  return (
    <div className={styles.wrapper}>
      {quantityInputField}
      <div className={styles.buttonWrapper}>
        <CartButton
          style='rounded'
          data-testid='add-to-cart-button'
          type='submit'
          loading={isLoading}
          disabled={!validationSchema.isValidSync(values)}
          left={<span>{buttonText}</span>}
          right={<Dollars amount={getDisplayPrice(values)} />}
        />
      </div>
    </div>
  )
}

const itemDetailsShape = PropTypes.oneOfType([
  MenuItemDetailsShape,
  ModifierShape
])

ModifiersFooter.propTypes = {
  getDisplayPrice: PropTypes.func,
  onClose: PropTypes.func,
  itemDetails: itemDetailsShape,
  additionalText: PropTypes.string
}
