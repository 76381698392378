import React from 'react'

import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'
import { useGetCart } from '../../../CartQuery/CartQuery'
import { Notification } from '@local/do-secundo-notification'
import { useFormik } from '@local/do-secundo-formik-provider'
import { hasSufficientFunds } from '../../../../utils/gift-card-helpers'

export const GiftCardNotification = () => {
  const { giftCard } = useGiftCard()
  const { cart } = useGetCart()
  const { errors } = useFormik()

  const { availableBalance } = giftCard

  if (!cart || !availableBalance) return null
  if (hasSufficientFunds({ giftCard, cart })) return null

  if (!errors.encryptedCard) {
    return (
      <Notification>
        Your gift card covers part of the total. The selected payment method
        below will be used unless another one is chosen.
      </Notification>
    )
  }

  return (
    <Notification severity='warning'>
      Your gift card covers part of the total. Choose another payment method
      below.
    </Notification>
  )
}
