import { useEffect } from 'react'

/**
 * Scrolls window/provided HTMlElement to the top
 * @param {HTMLElement} el
 */
export const ScrollToTop = ({ el = window }) => {
  useEffect(() => {
    el.scrollTo(0, 0)
  }, [el])
  return null
}
