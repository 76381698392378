export const getMerchantValidationHandler =
  ({ session, getMerchantSession }) =>
  async ({ validationURL } = {}) => {
    if (!validationURL) throw new Error('No Validation Url')
    const merchantSession = await getMerchantSession(validationURL)
    session.completeMerchantValidation(merchantSession)
  }

export const getPaymentAuthorizationHandler =
  ({ session, performTransaction }) =>
  async ({ payment } = {}) => {
    if (!payment) throw new Error('No payment')
    const { STATUS_SUCCESS, STATUS_FAILURE } = window.ApplePaySession
    try {
      await performTransaction(payment)
      session.completePayment(STATUS_SUCCESS)
    } catch (error) {
      session.completePayment(STATUS_FAILURE)
    }
  }

export const canMakeApplePayPayment = () => {
  try {
    if (window.ApplePaySession) {
      const canMakePayments = window.ApplePaySession.canMakePayments()
      return canMakePayments
    }
    return false
  } catch (error) {
    return false
  }
}

export const makeApplePayPayment = ({
  getMerchantSession,
  performTransaction,
  onCancel = () => {},
  config,
  initSession = () => {}
}) => {
  if (!window.ApplePaySession) throw new Error('Apple Pay session not defined')
  const APPLE_PAY_VERSION = 6
  const session = new window.ApplePaySession(APPLE_PAY_VERSION, config)
  session.onvalidatemerchant = getMerchantValidationHandler({
    session,
    getMerchantSession
  })
  session.onpaymentauthorized = getPaymentAuthorizationHandler({
    session,
    performTransaction
  })

  session.oncancel = onCancel
  initSession(session)
  session.begin()
  return session
}
