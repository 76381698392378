import { QueryHookOptions, useQuery } from '@apollo/client'

import { track } from '@toasttab/do-secundo-analytics'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import { GET_MENU_BADGES } from '../apollo'
import { useRestaurantStorage } from '../../../../client/utils/restaurant-storage'
import { useVariant } from '../../../../client/hooks/use-variant'
import { XP_MENU_BADGES } from '../../../../client/components/ExperimentsProvider/experiment-definitions'

import { MenuBadgesData } from '../@types'

type MenuBadgesInput = {
  input: {
    restaurantGuid: string
  }
}

type MenuBadgesResponse = {
  menuOfferBadges: MenuBadgesData
}

type GetPromoBannerQueryOptions = QueryHookOptions<
  MenuBadgesResponse,
  MenuBadgesInput
>

export const useGetMenuBadges = (
  queryOptions: GetPromoBannerQueryOptions = {}
) => {
  const { restaurantGuid } = useRestaurant()
  const allowMenuBadges = !!useVariant(XP_MENU_BADGES.experimentName)
  const restaurantSessionStorage = useRestaurantStorage(sessionStorage)

  const response = useQuery<MenuBadgesResponse, MenuBadgesInput>(
    GET_MENU_BADGES,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          restaurantGuid
        }
      },
      ...queryOptions,
      onCompleted: ({ menuOfferBadges }) => {
        const badgeCount =
          menuOfferBadges.menus.length +
          menuOfferBadges.groups.length +
          menuOfferBadges.items.length

        try {
          if (
            badgeCount === 0 ||
            Boolean(restaurantSessionStorage.get('trackedMenuBadges'))
          ) {
            return
          }

          restaurantSessionStorage.set('trackedMenuBadges', true)
          track('Menu Badges - seen')
        } catch (error) {}
      },
      skip: !allowMenuBadges
    }
  )

  if (response.loading || response.error || !response.data) {
    return null
  }

  return response.data.menuOfferBadges
}

export const useGetMenuBadge = (menuGuid: string) => {
  const menuBadges = useGetMenuBadges()

  return menuBadges?.menus?.find((badge) => badge.menuGuid === menuGuid) || null
}

export const useGetMenuGroupBadge = (groupGuid: string) => {
  const menuBadges = useGetMenuBadges()

  return (
    menuBadges?.groups?.find((badge) => badge.groupGuid === groupGuid) || null
  )
}

export const useGetMenuItemBadge = (itemGuid: string) => {
  const menuBadges = useGetMenuBadges()

  return menuBadges?.items?.find((badge) => badge.itemGuid === itemGuid) || null
}
