import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import { Checkbox } from '@local/do-secundo-form'

import styles from './TagSelector.module.css'

export const TagSelector = ({ tagOptions, setFieldValue, values, name }) => {
  return (
    <div className={styles.tagsContainer}>
      {tagOptions.map((tag, index) => {
        const handleSelect = () => {
          if (values[name].includes(tag)) {
            setFieldValue(
              name,
              values[name].filter((elem) => elem !== tag)
            )
          } else {
            setFieldValue(name, [...values[name], tag])
          }
        }
        return (
          <Field
            key={`tag-${index}`}
            id={tag}
            onSelect={handleSelect}
            checked={values[name].includes(tag)}
            style='tag'
            name={tag}
            component={Checkbox}
            label={<span>{tag}</span>}
          />
        )
      })}
    </div>
  )
}

TagSelector.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  tagOptions: PropTypes.array.isRequired
}
