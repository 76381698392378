import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { CodeInput } from './CodeInput'
import { AutoSubmitListener } from './AutoSubmitListener'
import { Notification } from '@local/do-secundo-notification'
import { Progress } from '@local/do-secundo-progress'
import { track } from '@toasttab/do-secundo-analytics'
import { useLocation } from 'react-router-dom'
import {
  ACCOUNT_LOCKED_ERROR_MESSAGE,
  ANALYTICS_ERROR,
  OTP_EXPIRED_MESSAGE
} from '@local/do-secundo-passwordless-authentication'
import { TimeoutButton } from '@local/do-secundo-timeout-button'
import { Button, ButtonVariant } from '@local/do-secundo-button'

export interface CodeConfirmationFormProps {
  testId?: string
  handleSubmit: (code: string, setSubmitting: (boolean) => void) => void
  numFields: number
  codeClassName?: string
  resetFocusAfterCompletion?: boolean
  spaceBetweenHyphens?: number
  idPrefix?: string
  handleResend?: () => void
}

export const CodeConfirmationForm = ({
  testId = 'code-confirmation-form',
  handleSubmit,
  numFields = 6,
  codeClassName = '',
  resetFocusAfterCompletion = true,
  spaceBetweenHyphens = 3,
  idPrefix,
  handleResend
}: CodeConfirmationFormProps) => {
  const codeValidationSchema = Yup.object({
    codeValues: Yup.string()
      .matches(/^\d+$/)
      .min(numFields)
      .required('Not enough fields filled')
  })
  const { search, state = {} } = useLocation()

  return (
    <div className={codeClassName}>
      <Formik
        validationSchema={codeValidationSchema}
        initialValues={{ codeValues: '' }}
        enableReinitialize
        onSubmit={async (
          { codeValues },
          { setError, setSubmitting, setFieldValue }
        ) => {
          try {
            await handleSubmit(codeValues, setSubmitting)
          } catch (error) {
            track(ANALYTICS_ERROR.SmsCodeError, {
              errorMessage: error.message,
              search,
              from: state?.from
            })
            setError(error)
            if (
              error.message !== OTP_EXPIRED_MESSAGE &&
              error.message !== ACCOUNT_LOCKED_ERROR_MESSAGE
            ) {
              setFieldValue('codeValues', '')
            }
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(formik) => {
          const { error, isSubmitting, values, setError, setFieldValue } =
            formik

          const isAccountLocked =
            error?.message === ACCOUNT_LOCKED_ERROR_MESSAGE

          const shouldDisableInput =
            error?.message === OTP_EXPIRED_MESSAGE || isAccountLocked

          return (
            <Form
              data-testid={testId}
              className='flex flex-col items-center justify-center'
            >
              <AutoSubmitListener
                formik={formik}
                canSubmit={values.codeValues.trim().length === numFields}
              />
              {isSubmitting ? (
                <Progress />
              ) : (
                <div className='flex items-center py-4'>
                  <Field
                    component={CodeInput}
                    numFields={numFields}
                    resetFocusAfterCompletion={resetFocusAfterCompletion}
                    spaceBetweenHyphens={spaceBetweenHyphens}
                    autoFocus={true}
                    idPrefix={idPrefix}
                    disabled={shouldDisableInput}
                  />
                </div>
              )}
              {error && (
                <div
                  className='py-2 '
                  style={{ visibility: isSubmitting ? 'hidden' : 'initial' }}
                >
                  <Notification severity='error'>{error.message}</Notification>
                </div>
              )}
              {handleResend &&
                (shouldDisableInput ? (
                  <Button
                    data-testid={`${testId}-resend-code-button-primary`}
                    onClick={() => {
                      setError(null)
                      setFieldValue('codeValues', '')
                      handleResend()
                    }}
                    variant={ButtonVariant.PRIMARY}
                    disabled={isAccountLocked}
                  >
                    Resend code
                  </Button>
                ) : (
                  <div className='p-4 '>
                    <p className='mb-4 type-default text-oo-grey'>
                      Didn't receive a code?
                    </p>
                    <TimeoutButton
                      onClick={handleResend}
                      timeoutPeriod={5000}
                      maxNumberOfRetries={5}
                      data-testid={`${testId}-resend-code-button`}
                    >
                      Resend code
                    </TimeoutButton>
                  </div>
                ))}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
