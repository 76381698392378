import * as React from 'react'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { track } from '@toasttab/do-secundo-analytics'
import { logError } from '@local/do-secundo-error'
import { getRawPhoneNumberWithCountryCode } from '@local/do-secundo-form-utils'
import { storage as customerMgmtLocalStorage } from '../../../../client/apollo/authentication/authentication-helpers'
import { AuthClient } from '@toasttab/guest-authentication-js'
import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'

const ACTIVE_SESSION_KEY = 'hasActiveSession'

export interface inputShape {
  variables: {
    input: {
      source: string
    }
  }
}
interface attemptToCompleteProfileFirstArg {
  completeProfileCreation: (input: inputShape) => Promise<any>
  input: inputShape
}

/**
 * Used in V1 Passwordless (deprecated) and as part of creating an account
 * in checkout flow.
 */
export const attemptToCompleteProfile = async (
  { completeProfileCreation, input }: attemptToCompleteProfileFirstArg,
  maxNumberOfAttempts = 1,
  attemptsSoFar = 0
): Promise<any> => {
  try {
    attemptsSoFar++
    const result = await completeProfileCreation(input)

    // @todo fix this so that object property and typename match with passwordlessLogin client only mutation
    const { CompleteProfileCreationResponse, CompleteProfileCreationError } =
      dataByTypename(result.data.passwordlessLogin)

    if (CompleteProfileCreationError) {
      throw Error(CompleteProfileCreationError.message)
    }

    if (CompleteProfileCreationResponse) {
      return CompleteProfileCreationResponse
    }
  } catch (error) {
    if (attemptsSoFar >= maxNumberOfAttempts) {
      throw error
    } else {
      await new Promise((resolve) => setTimeout(resolve, attemptsSoFar * 250))
      return attemptToCompleteProfile(
        { completeProfileCreation, input },
        maxNumberOfAttempts,
        attemptsSoFar
      )
    }
  }
}

export interface handleVerificationCodeSubmitProps {
  code: string
  onCodeSubmissionSuccess: () => void
  completeProfileCreation: (input: inputShape) => Promise<any>
  trackCodeSubmissionSuccess: () => void
  from?: string
}

/**
 * Handles verification of sms code and callback when verification is complete
 * TODO: As part of unified login v2, reconsider consolidating between this function and UnifiedSmsVerificationModal.
 */
export const handleVerificationCodeSubmit = async ({
  code,
  onCodeSubmissionSuccess,
  completeProfileCreation,
  trackCodeSubmissionSuccess,
  from
}: handleVerificationCodeSubmitProps) => {
  const input = {
    variables: {
      input: {
        code,
        source: 'ONLINE_ORDERING'
      }
    }
  }

  try {
    await attemptToCompleteProfile({ completeProfileCreation, input })
    trackCodeSubmissionSuccess()
    onCodeSubmissionSuccess()
  } catch (error) {
    if (error instanceof TypeError) {
      throw Error(
        `Toast is experiencing issues. Please continue to place your order without ${
          from === 'create' ? 'creating an account' : 'logging in'
        }.`
      )
    }
    throw error
  }
}

/**
 * Returns whether the authentication library auth client has an active session.
 * @returns boolean
 */
export const getAuthSessionActive = () => {
  const hasActiveSessionValue = localStorage?.getItem(ACTIVE_SESSION_KEY)
  if (!hasActiveSessionValue) {
    return false
  }
  return JSON.parse(hasActiveSessionValue)
}

/**
 *   Determines presence of 'hasActiveSession' key in localStorage,
 *   which means user is/has been logged in at some point with a passwordless acct
 */
export const hasPasswordlessAccount = () => {
  return localStorage?.hasOwnProperty(ACTIVE_SESSION_KEY)
}

/**
 *  guest-authentication-js exposes accessing the value “isProfileCreated” on the JWT that we can utilize.
 * This value is populated from guest-profiles, and will either be: `true` - guest has a profile, `false` - guest does not have a profile,
 * or undefined - from before we started setting this value, before “identityProfile” endpoints existed.
 * This means the guest either has a profile, or their profile expired due to not being verified.
 * @param authClient
 * @returns boolean
 */
export const isProfileCreated = (authClient: AuthClient) => {
  return (
    authClient.userInfo?.isProfileCreated === true ||
    (authClient.userInfo && authClient.userInfo?.isProfileCreated === undefined)
  )
}

/**
 *   Determines if 'hasActiveSession' key is in localStorage,
 *   and that they are signed in with in
 */
export const isPasswordlessLoggedIn = (authClient: AuthClient) => {
  if (!authClient) return false
  return hasPasswordlessAccount() && isProfileCreated(authClient)
}

/**
 * Determines if someone is signed in with customermgmt
 * presence  of 'auth' in local storage means user is/has been logged in at some point with a customermgmt account
 */
export const isLegacyAccountLoggedIn = () =>
  Boolean(
    customerMgmtLocalStorage?.get('accessToken') &&
      customerMgmtLocalStorage?.get('refreshToken')
  )

type PasswordlessAuthEnabledReturnType = {
  PASSWORDLESS_ENABLED: Boolean
}

/**
 * Util to determine whether or not to show user passwordless auth UI
 */
export const useIsPasswordlessAuthEnabled =
  (): PasswordlessAuthEnabledReturnType => {
    const showLegacyAccount = false // return this when a legacy account should be shown
    const { authClient } = useAuth()
    // ff flag
    const restaurantHasFeatureFlag = useFlag(
      FF.PASSWORDLESS_AUTH_AFTER_CHECKOUT
    )

    if (isLegacyAccountLoggedIn() && !isPasswordlessLoggedIn(authClient)) {
      return {
        PASSWORDLESS_ENABLED: showLegacyAccount
      }
    }

    const shouldShowPasswordlessUx =
      hasPasswordlessAccount() || restaurantHasFeatureFlag

    if (shouldShowPasswordlessUx) {
      return {
        PASSWORDLESS_ENABLED: !showLegacyAccount // if a user has ever used pwless it should take precedence
      }
    }

    return {
      PASSWORDLESS_ENABLED: restaurantHasFeatureFlag // show based on FF
    }
  }

export const useTrackCodeSubmissionSuccess = () => {
  return React.useCallback((from: string) => {
    track('Passwordless Authentication Code Submission Success', {
      from
    })
  }, [])
}

/**
 * Custom hook that starts interaction with auth library. Must have phone and authClient to make the call.
 * Errors are sent to sentry. This can be used whenever we need to send the sms code. So currently this is used
 * in our pwlessCodeVerificationModal and our SmsConfirmationBenefits.
 * @param {string|undefined} phone ISO-formatted local time
 * @param {AuthClient} authClient our authentication library
 * @returns {useEffect hook}
 */
export const useSendSmsCode = (
  phone: string | undefined,
  authClient: AuthClient
) => {
  return React.useEffect(() => {
    if (phone && authClient) {
      try {
        authClient.requestPasswordless(getRawPhoneNumberWithCountryCode(phone))
      } catch (error: any) {
        logError(error)
        console.warn(error)
      }
    }
  }, [authClient, phone])
}
