import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './MenuSelector.module.css'
import { useMenuSelection } from '../../hooks/use-menu-selection'
import { useMenus } from '../../hooks/use-menus'

const MenuSelectorInner = ({
  menus,
  activeMenu = { id: '', groups: [{}] }
}) => {
  const setActiveMenu = useMenuSelection({
    onCompleted(data) {
      const newActiveMenu = menus.find(({ id }) => id === data.setActiveMenu.id)
      const hash = newActiveMenu.groups[0].id
      if (window.location.hash.replace('#', '') === hash) {
        window.location.hash = ''
      }
      window.location.hash = hash
    }
  })

  const handleChange = (event) => {
    const id = event.target.value
    setActiveMenu(id)
  }

  return (
    menus.length > 1 && (
      <fieldset className={cx(styles.fieldset, 'type-default')}>
        <legend className={styles.legend}>Choose a Menu</legend>
        <select
          data-testid='menus-selector'
          className={styles.select}
          onChange={handleChange}
          value={activeMenu.id}
        >
          {activeMenu.id === '' && <option value=''>CHOOSE A MENU</option>}
          {menus.map(({ id, name }) => (
            <option data-testid='menu-selector' key={id} id={id} value={id}>
              {name}
            </option>
          ))}
        </select>
      </fieldset>
    )
  )
}

export const MenuSelector = ({ activeMenu }) => {
  const { loading, error, menus } = useMenus({})

  if (loading || error || !menus || menus.length <= 1) {
    return null
  }

  return <MenuSelectorInner menus={menus} activeMenu={activeMenu} />
}

MenuSelector.propTypes = {
  activeMenu: PropTypes.object
}
