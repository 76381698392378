import { useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import smoothscroll from 'smoothscroll-polyfill'

import { SCROLL_DIRECTIONS } from '../../utils/scroll-helpers'

/**
 * Custom hooks that handles horizontal scroll menu's scrolling logic
 * @param {string} activeItemId
 * @param {Function} handleScroll
 * @param {Number} scrollDistanceMultiplier
 * @returns {{
 *  hideRightArrow: boolean,
 *  hideLeftArrow: boolean,
 *  itemContainerRef: React.MutableRefObject<null>,
 *  arrowInvisibility: {leftArrow: boolean, rightArrow: boolean},
 *  handleRightArrowClick: handleRightArrowClick,
 *  handleLeftArrowClick: handleLeftArrowClick
 * }}
 */

const useHorizontalScrollMenu = (
  activeItemId,
  handleScroll,
  scrollDistanceMultiplier
) => {
  const itemContainerRef = useRef(null)
  const [scrollByDistance, setScrollByDistance] = useState(0)
  const [itemContainerScrollPosition, setItemContainerScrollPosition] =
    useState(0)
  const [arrowInvisibility, setArrowInvisibility] = useState({
    hideLeftArrow: false,
    hideRightArrow: true
  })
  const arrowOffset = 20

  /**
   * On window resize we need to adjust the scrollby distance so that each click of an arrow
   * scrolls the length of the visible part of horizontal scroll bar
   * @param {Element} itemContainer
   */
  const adjustScrollByDistance = useCallback(() => {
    if (
      itemContainerRef &&
      itemContainerRef.current &&
      itemContainerRef.current.offsetWidth
    ) {
      setScrollByDistance(
        itemContainerRef.current.offsetWidth * scrollDistanceMultiplier
      )
    }
  }, [setScrollByDistance, scrollDistanceMultiplier, itemContainerRef])

  const debouncedAdjustScrollByDistance = debounce(adjustScrollByDistance, 300)

  /**
   * On TouchEnd event, setItemContainerScrollPosition to trigger adjustArrowVisibility
   * @param {Event} e
   */
  const touchEndHandler = (e) => {
    const target = e.currentTarget
    setItemContainerScrollPosition(target.scrollLeft)
  }

  /**
   * Updates itemContainerScrollPosition on left arrow click
   */
  const handleLeftArrowClick = () => {
    const leftEdgeScrollPosition = itemContainerScrollPosition
    const newScrollPosition = leftEdgeScrollPosition - scrollByDistance

    setItemContainerScrollPosition(newScrollPosition)
  }

  /**
   * Updates itemContainerScrollPosition on right arrow click
   */
  const handleRightArrowClick = () => {
    const leftEdgeScrollPosition = itemContainerScrollPosition
    const newScrollPosition = leftEdgeScrollPosition + scrollByDistance

    setItemContainerScrollPosition(newScrollPosition)
  }

  /**
   * Reusable function to handle invisibility of arrows based on current scroll position
   * and length of scroll menu
   */
  const adjustArrowInvisibility = useCallback(() => {
    if (itemContainerRef.current) {
      const leftEdgeScrollPosition = itemContainerScrollPosition
      const itemContainerTotalWidth = itemContainerRef.current.scrollWidth
      const itemContainerVisibleWidth = itemContainerRef.current.offsetWidth
      const maxScrollPosition =
        itemContainerTotalWidth - itemContainerVisibleWidth

      // if maxScrollPosition is less than scrollDistance aka width of itemContainer
      // it means all of the menu items are visible and we can hide the arrows
      if (itemContainerTotalWidth <= itemContainerVisibleWidth) {
        setArrowInvisibility({
          hideRightArrow: true,
          hideLeftArrow: true
        })
      } else {
        setArrowInvisibility({
          hideRightArrow: leftEdgeScrollPosition >= maxScrollPosition,
          hideLeftArrow: leftEdgeScrollPosition <= arrowOffset
        })
      }
    }
  }, [itemContainerRef, itemContainerScrollPosition])

  /**
   * onMount enable smooth scroll (aka el.scrollTo({behavior: 'smooth'}) polyfill so scrolling works on IE/Edge
   */
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  /**
   * setup itemContainerRef and associated event listeners
   */
  useEffect(() => {
    const ref = itemContainerRef

    if (ref.current) {
      adjustScrollByDistance()

      // set scrollByDistance and add resize event listener on mount
      window.addEventListener('resize', debouncedAdjustScrollByDistance)

      // set touchstart listener to show/hide arrows
      ref.current.addEventListener('touchend', touchEndHandler)
    }

    return () => {
      window.removeEventListener('resize', debouncedAdjustScrollByDistance)

      if (ref.current) {
        ref.current.removeEventListener('touchend', touchEndHandler)
      }
    }
  }, [
    itemContainerRef,
    adjustScrollByDistance,
    debouncedAdjustScrollByDistance
  ])

  /**
   * set new scroll position when activeItemId updates, which will trigger scrolling behavior
   */
  useEffect(() => {
    // fixme clean it up after menu selector is removed from the codebase
    // because of birds eye view duplicate logic we have two elements with the same selector but different parents
    // we need to be targeting one whose parent is visible(based on the screen resolution)
    const activeElementsNodes = document.querySelectorAll(
      `[href="#${activeItemId}"]`
    )

    const activeEl = [...activeElementsNodes].find((element) =>
      Boolean(element?.offsetParent)
    )

    if (activeEl) {
      setItemContainerScrollPosition(activeEl.offsetLeft - arrowOffset)
    }
  }, [activeItemId])

  /**
   * Hide/Show arrows, needs to update when:
   * 1. rendering
   * 2. when window resizes
   */
  useEffect(adjustArrowInvisibility, [
    scrollByDistance,
    adjustArrowInvisibility
  ])

  /**
   * trigger our scroll animation
   */
  useEffect(() => {
    if (itemContainerRef.current) {
      handleScroll(
        itemContainerRef.current,
        itemContainerScrollPosition,
        SCROLL_DIRECTIONS.HORIZONTAL
      )
    }
    adjustArrowInvisibility()
  }, [
    itemContainerScrollPosition,
    itemContainerRef,
    adjustArrowInvisibility,
    handleScroll
  ])

  return {
    itemContainerRef,
    hideRightArrow: arrowInvisibility.hideRightArrow,
    hideLeftArrow: arrowInvisibility.hideLeftArrow,
    handleLeftArrowClick,
    handleRightArrowClick,
    itemContainerScrollPosition, // for testing,
    scrollByDistance // for testing
  }
}

export { useHorizontalScrollMenu }
