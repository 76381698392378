import React, { Fragment } from 'react'

export const sumByField =
  (fieldKey, init = 0) =>
  (entities) =>
    entities.reduce((acc, { [fieldKey]: val }) => acc + val, init)

export const joinFieldByCommas =
  (fieldKey) =>
  (items = []) =>
    Array.isArray(items)
      ? items
          .filter(({ [fieldKey]: val }) => Boolean(val))
          .map(({ [fieldKey]: val }) => val)
          .join(', ')
      : ''

/*
 * This helper function adds count to nested modifier quantities,
 * separates them by a comma, and converts them to a string.
 * (e.g. 'Fries (2 cheese, 3 bacon, ranch)')
 */
export const joinNestedModByCommas = (fieldKey) => (items) =>
  items.map(({ [fieldKey]: name, count, groupingKey }, index) => (
    <Fragment key={groupingKey}>
      <Fragment>
        {count > 1 ? (
          <Fragment>
            <span style={{ fontWeight: '700' }}>{count}</span>
            {` ${name}`}
          </Fragment>
        ) : (
          name
        )}
      </Fragment>
      {index === items.length - 1 ? '' : ', '}
    </Fragment>
  ))

/*
 * This helper function adds count to modifier quantities
 * and consolidates multiples of the same item into one,
 * increasing the count to reflect the number of modifiers.
 */
export const modifierConsolidator = (array) => {
  if (Array.isArray(array)) {
    const modMap = array.reduce((result, modifier) => {
      const { groupingKey, name } = modifier
      if (!result[groupingKey || name]) {
        result[groupingKey || name] = { ...modifier, count: 0 }
      }
      result[groupingKey || name].count++
      return result
    }, {})
    return Object.values(modMap)
  }
  return array
}
