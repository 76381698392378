import React from 'react'
import PropTypes from 'prop-types'

import { getFormattedAddress } from '../../utils/address-helpers'

import styles from './SavedAddressLabel.module.css'

export const SavedAddressLabel = React.memo(
  ({ savedAddress: { name, deliveryInfo } }) => (
    <span className={styles.address}>
      <div className={styles.addressInfo}>
        <div className={styles.infoWrapper}>
          {name && <p className={styles.name}>{name}</p>}
          <p className={styles.formattedAddress}>
            {getFormattedAddress(deliveryInfo)}
            {deliveryInfo.address2 && <span>{deliveryInfo.address2}</span>}
          </p>
          {deliveryInfo.notes && (
            <p className={styles.notes}>{deliveryInfo.notes}</p>
          )}
        </div>
      </div>
    </span>
  )
)

SavedAddressLabel.displayName = 'SavedAddressLabel'

const SavedAddressShape = PropTypes.shape({
  guid: PropTypes.string.isRequired,
  name: PropTypes.string,
  deliveryInfo: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    notes: PropTypes.string
  })
})
SavedAddressLabel.propTypes = {
  savedAddress: SavedAddressShape.isRequired
}
