import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { logError } from '@local/do-secundo-error'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { RestaurantDetails } from '@local/do-secundo-restaurant-details'

export const MenuPageOutage = ({ error, restaurantInfo }) => {
  useEffect(() => {
    logError(error, (scope) => scope.setTag('Origin', 'Menu Page'))
  }, [error])

  const displayDetails = Boolean(restaurantInfo)
  const vpnMsg =
    "Pleae note our site is not accessible if you're outside the US or on a remote VPN."

  return (
    <>
      {displayDetails ? (
        <div className='border-0 border-b-2 border-solid'>
          <RestaurantDetails restaurant={restaurantInfo.restaurant} />
        </div>
      ) : (
        <p
          className='text-center py-3 px-6 w-full text-color-default bg-gray-0 shadow'
          data-testid='vpn-message'
        >
          {vpnMsg}
        </p>
      )}
      <div className='justify-center px-8 py-24 gap-8'>
        <MessageError
          header={'Temporary service interruption'}
          message={
            'Try refreshing the page, or contact the restaurant directly to order.'
          }
          buttonSecondary={{
            label: 'Refresh page',
            onClick: () => window.location.reload()
          }}
        />
      </div>
    </>
  )
}

MenuPageOutage.propTypes = {
  error: PropTypes.object,
  restaurantInfo: PropTypes.object
}
