import { Observable } from '@apollo/client'
import { logError } from '@local/do-secundo-error'
// useful for parsing response errors
// https://artsy.github.io/blog/2018/10/19/where-art-thou-my-error/

export const dataByTypename = (data) =>
  data && data.__typename ? { [data.__typename]: data } : {}

/**
 * This function is adapted from https://github.com/apollographql/apollo-link/issues/646
 * for implementing async processing in an onError Apollo Link.
 *
 * Can return null if the promise returns null; null is not a valid response in `onError`
 * so null value handling should be taken care of by the consumer.
 * @param promise
 * @returns {Observable<unknown> | Observable}
 */
export const promiseToObservable = (promise) =>
  new Observable(async (subscriber) => {
    try {
      const value = await promise
      if (!subscriber.closed) {
        subscriber.next(value)
        subscriber.complete()
      }
    } catch (err) {
      //check custom error prop to see if it's an expected error that does not need to be logged
      if (err.shouldToastLog !== false) {
        logError(err)
      }
      subscriber.error(err)
    }
  })
