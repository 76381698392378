import React, { useEffect } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Radio } from '@local/do-secundo-form'
import { FULFILLMENT_TYPES } from '../../FulfillmentProvider/FulfillmentProvider'
import { dataByTypename } from '../../../utils/apollo-helpers'

import { useAvailability } from '@local/do-secundo-availability-provider'
import { AsapEstimate } from '../../AsapEstimate/AsapEstimate'
import { getDatesAndTimesFromResponse } from '../fulfillment-helpers'
import { isSameISOTime, toRelativeDateString } from '../../../utils/time-utils'

import styles from '../FulfillmentSelectorModal.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { formatHours } from '../../../utils/restaurant-schedule-helpers'

const { ASAP, FUTURE } = FULFILLMENT_TYPES

const setTime = ({ data, date, time, diningOptionBehavior, setFieldValue }) => {
  // Select same time on different day, or first time if selected is not available
  const { timeList: newTimes } = getDatesAndTimesFromResponse({
    data,
    selectedDate: date,
    diningOptionBehavior
  })
  if (
    newTimes &&
    newTimes.length &&
    !newTimes.some((timeSlot) => timeSlot.time === time)
  ) {
    let matchingTime = newTimes.find((timeSlot) =>
      isSameISOTime(timeSlot.time, time)
    )
    if (!matchingTime) {
      matchingTime = newTimes[0]
    }
    setFieldValue('fulfillmentTime', matchingTime.time)
  }
}

export const FulfillmentTimeSelector = ({ formik, data }) => {
  const { handleChange, setFieldValue, values } = formik
  const {
    fulfillmentType,
    diningOptionBehavior,
    fulfillmentDate: selectedDate
  } = values
  const { availability } = useAvailability()
  const {
    restaurantInfo: { data: restaurantInfoData, error, loading }
  } = useRestaurant()
  const { dateList, timeList } = getDatesAndTimesFromResponse({
    data,
    selectedDate,
    diningOptionBehavior
  })

  // when user switches between dining options, automatically
  // switch to an available fulfillment type if necessary
  useEffect(() => {
    if (!availability[ASAP][diningOptionBehavior] && fulfillmentType === ASAP) {
      setFieldValue('fulfillmentType', FUTURE)
    } else if (
      !availability[FUTURE][diningOptionBehavior] &&
      fulfillmentType === FUTURE
    ) {
      setFieldValue('fulfillmentType', ASAP)
    }
  }, [availability, diningOptionBehavior, fulfillmentType, setFieldValue])

  // When user switches between dining options, automatically
  // choose the closest time that was previously selected
  useEffect(() => {
    if (fulfillmentType === FUTURE) {
      setTime({
        data,
        date: selectedDate,
        time: values.fulfillmentTime,
        diningOptionBehavior,
        setFieldValue
      })
    }
  }, [
    data,
    diningOptionBehavior,
    fulfillmentType,
    setFieldValue,
    selectedDate,
    values.fulfillmentTime
  ])

  if (loading || error) return null

  const {
    restaurant: { timeZoneId }
  } = restaurantInfoData

  return (
    <>
      <h2 className={cx(styles.when, 'type-default')}>
        When would you like your order:
      </h2>
      <div className={styles.radioButtons}>
        <Field
          id='fulfillment_type_asap'
          name='fulfillmentType'
          value={ASAP}
          component={Radio}
          disabled={!availability[ASAP][diningOptionBehavior]}
          label={
            <div>
              <span>Order now</span>
              {fulfillmentType === ASAP && (
                <p
                  id='prep_time_message'
                  className={cx(styles.promise, 'type-default')}
                >
                  <AsapEstimate diningOptionBehavior={diningOptionBehavior} />
                </p>
              )}
            </div>
          }
        />

        <Field
          id='fulfillment_type_future'
          name='fulfillmentType'
          value={FUTURE}
          component={Radio}
          disabled={!availability[FUTURE][diningOptionBehavior]}
          label={<span>Schedule for later</span>}
        />

        {fulfillmentType === FUTURE &&
          availability[FUTURE][diningOptionBehavior] && (
            <div
              data-testid='fulfillment-selectors'
              id='fulfillment_selectors'
              className={styles.fulfillment_time_selectors}
            >
              <select
                data-testid='fulfillment-selector-dates'
                className={styles.select}
                id='fulfillment_time_date'
                name='fulfillmentDate'
                value={values.fulfillmentDate}
                onChange={handleChange}
              >
                {dateList.map((date) => (
                  <option
                    data-testid='fulfillment-selector-date'
                    value={date}
                    key={date}
                  >
                    {toRelativeDateString(date)}
                  </option>
                ))}
              </select>
              <select
                data-testid='fulfillment-selector-times'
                className={styles.select}
                id='fulfillment_time'
                name='fulfillmentTime'
                value={values.fulfillmentTime}
                onChange={handleChange}
              >
                {timeList.map((time, index) => {
                  const { FutureFulfillmentTime, FutureFulfillmentServiceGap } =
                    dataByTypename(time)
                  if (FutureFulfillmentServiceGap) {
                    return (
                      <option key={index} disabled>
                        ----------
                      </option>
                    )
                  }
                  return (
                    <option
                      data-testid='fulfillment-selector-time'
                      value={FutureFulfillmentTime.time}
                      key={time.time}
                    >
                      {formatHours(FutureFulfillmentTime.time, timeZoneId)}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
      </div>
    </>
  )
}

FulfillmentTimeSelector.propTypes = {
  formik: PropTypes.object,
  data: PropTypes.object
}
