import React, { useState } from 'react'

import { ErrorComponent } from '@local/do-secundo-error'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Progress } from '@local/do-secundo-progress'
import { SavedAddress } from './SavedAddress/SavedAddress'
import { useSavedAddresses } from '../../use-saved-addresses/use-saved-addresses'

export const SavedAddressForm = () => {
  const [editGuid, setEditing] = useState(null)
  const { savedAddresses, loading, error, refetch } = useSavedAddresses()
  if (loading) return <Progress />
  if (error) return <ErrorComponent error={error} retry={refetch} />

  return (
    <Fieldset label={'Delivery Information'} collapsable>
      {savedAddresses.length === 0 ? (
        <p>No Addresses</p>
      ) : (
        savedAddresses.map((savedAddress) => (
          <SavedAddress
            key={savedAddress.guid}
            savedAddress={savedAddress}
            setEditing={setEditing}
            editing={editGuid === savedAddress.guid}
          />
        ))
      )}
    </Fieldset>
  )
}
