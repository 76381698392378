import { useMemo } from 'react'

import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'

import { RULE_TYPES } from './approval-rule-helpers'

/**
 * Determines whether a delivery rule violation actually applies given the current
 * client-side state.
 */
const checkDeliveryRuleApplicable = ({ diningOptionBehavior }) => {
  return DINING_OPTION_BEHAVIORS.DELIVERY === diningOptionBehavior
}

const IMPLEMENTED_RULE_TYPES = {
  [RULE_TYPES.DELIVERY_LT]: checkDeliveryRuleApplicable
}

/**
 * This hook uses the results of the server-side evaluation of the configured approval
 * rules to determine whether they are pertinent given the current client-side state
 * of the cart/UI.
 */
export const useApprovalRulesValidator = ({ cart }) => {
  const { diningOptionBehavior } = useFulfillment()
  const approvalRules = useMemo(() => {
    return cart?.approvalRules || []
  }, [cart])

  const { ruleMap, valid } = useMemo(() => {
    const ruleMap = approvalRules.reduce((acc, rule) => {
      const { ruleType } = rule
      const checkApplicable = IMPLEMENTED_RULE_TYPES[ruleType]

      if (!checkApplicable) {
        return acc
      }

      if (!checkApplicable({ diningOptionBehavior })) {
        return acc
      }

      return {
        ...acc,
        [ruleType]: { ...rule }
      }
    }, {})

    const valid = Object.keys(ruleMap).length === 0
    return { ruleMap, valid }
  }, [approvalRules, diningOptionBehavior])

  return {
    /**
     * a map of rule types to the rule object for any currently violated
     * approval rules, this will never be `null` but can be empty
     *
     * @type {object}
     */
    ruleMap,
    /**
     * whether the cart is currently valid in the context of approval rules,
     * if this is `true`, `ruleMap` will not have any entries
     *
     * @type {boolean}
     */
    valid
  }
}
