import { FROM_LOCATION, ANALYTICS_ERROR } from './enums'
import { History } from 'history'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { getRawPhoneNumberWithCountryCode } from '@local/do-secundo-form-utils'
import {
  SearchForCustomermgmtAccountResponse,
  StartIdentityProfileError
} from '../../../../client/apollo/generated/TakeoutWebGraphQLOperations'
import { logError } from '@local/do-secundo-error'
import {
  showAccountCreatedNotification,
  User
} from '../../../../client/components/AuthProvider/AuthProvider'
import {
  CompletePasswordlessLoginInput,
  convertLegacyUserToPasswordless,
  handleConfirmPasswordlessLoginResponse
} from './index'
import { isLegacyAccountLoggedIn } from './passwordless-auth-utils'
import { track } from '@toasttab/do-secundo-analytics'

interface startPasswordlessLoginProps {
  variables: {
    input: {
      phone: string
    }
  }
}

export interface handleUnifiedPhoneSubmitProps {
  values: { phone: string }
  startPasswordlessLogin: (
    arg: startPasswordlessLoginProps
  ) => StartIdentityProfileError | void
  history: History
  from: FROM_LOCATION
  email?: string
  basicInfo?: { firstName: string; lastName: string; phone: string }
  loyaltyAccountCreationInput?: {
    checkIdentifier: string
    restaurantGuid: string
    transactionGuid: string
  }
}

export const handleUnifiedPhoneSubmit = async ({
  values,
  startPasswordlessLogin,
  history,
  from,
  email,
  basicInfo,
  loyaltyAccountCreationInput
}: handleUnifiedPhoneSubmitProps) => {
  const resp = await startPasswordlessLogin({
    variables: {
      input: {
        phone: getRawPhoneNumberWithCountryCode(values.phone)
      }
    }
  })

  const { StartIdentityProfileError } = dataByTypename(
    resp.data.startPasswordlessLogin
  )

  if (StartIdentityProfileError) {
    throw Error(StartIdentityProfileError.message)
  }
  history.push('?mode=sms', {
    from,
    guestPhone: values.phone,
    email,
    basicInfo,
    loyaltyAccountCreationInput
  })
}
interface searchForCustomermgmtAccountProps {
  variables: {
    input: {
      email: string
    }
  }
}

export interface handleUnifiedEmailSubmitProps {
  values: { email: string }
  searchForCustomermgmtAccount: (
    arg: searchForCustomermgmtAccountProps
  ) => SearchForCustomermgmtAccountResponse
  history: History
}

export const handleUnifiedEmailSubmit = async ({
  searchForCustomermgmtAccount,
  values,
  history
}: handleUnifiedEmailSubmitProps) => {
  const customermgmtAccountExistResponse = await searchForCustomermgmtAccount({
    variables: {
      input: {
        email: values.email
      }
    }
  })

  const { SearchForCustomermgmtAccountResponse } = dataByTypename(
    customermgmtAccountExistResponse.data.searchForCustomermgmtAccount
  )
  if (SearchForCustomermgmtAccountResponse?.foundMatchingEmail) {
    history.push('?mode=enterYourPassword', {
      email: values.email,
      from: FROM_LOCATION.emailLogin
    })
  } else {
    track(ANALYTICS_ERROR.EmailNotRecognizedError)
    throw Error('Email not recognized. Try your phone number instead.')
  }
}

interface HandleCodeSubmissionProps {
  code: string
  confirmPasswordlessLogin: (arg: {
    variables: {
      input: {
        code: string
        source: string
      }
    }
  }) => Promise<void>
  completePasswordlessLogin: (input: CompletePasswordlessLoginInput) => void
  trackCodeSubmissionSuccess: (from: FROM_LOCATION) => void
  handleImportAccount: (arg: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => Promise<void>
  setIsAuthenticated: (auth: boolean) => void
  cancelPasswordlessLoginAndKeepLegacy: () => void
  onClose: () => void
  history: History
  user?: User
  from?: FROM_LOCATION
  email?: string
  basicInfo?: { firstName: string; lastName: string; phone: string }
  loyaltyAccountCreationInput?: {
    checkIdentifier: string
    restaurantGuid: string
    transactionGuid: string
  }
}

export const handleCodeSubmission = async ({
  code,
  confirmPasswordlessLogin,
  completePasswordlessLogin,
  trackCodeSubmissionSuccess,
  handleImportAccount,
  setIsAuthenticated,
  cancelPasswordlessLoginAndKeepLegacy,
  onClose,
  user,
  history,
  from,
  loyaltyAccountCreationInput,
  email,
  basicInfo
}: HandleCodeSubmissionProps) => {
  try {
    const result = await confirmPasswordlessLogin({
      variables: {
        input: {
          code,
          source: 'ONLINE_ORDERING'
        }
      }
    })

    const { ConfirmPasswordlessLoginResponse, PasswordlessLoginError } =
      dataByTypename(result.data.confirmPasswordlessLogin)

    if (PasswordlessLoginError) {
      throw Error(PasswordlessLoginError.message)
    }

    if (from) {
      trackCodeSubmissionSuccess(from)
    }

    if (ConfirmPasswordlessLoginResponse.completed) {
      await handleConfirmPasswordlessLoginResponse({
        firstName:
          ConfirmPasswordlessLoginResponse.profileRequirements.firstName,
        handleImportAccount,
        setIsAuthenticated,
        cancelPasswordlessLoginAndKeepLegacy,
        onClose
      })
    } else {
      if (loyaltyAccountCreationInput) {
        const input = {
          variables: {
            input: {
              email,
              firstName: basicInfo?.firstName,
              lastName: basicInfo?.lastName,
              source: 'ONLINE_ORDERING'
            }
          }
        }
        const result = await completePasswordlessLogin(input)

        const { CompleteIdentityProfileResponse } = dataByTypename(
          result.data.completePasswordlessLogin
        )
        if (CompleteIdentityProfileResponse) {
          setIsAuthenticated(true)
          onClose()
          showAccountCreatedNotification()
          return
        }
      }

      const profileRequirements =
        ConfirmPasswordlessLoginResponse.profileRequirements

      if (isLegacyAccountLoggedIn()) {
        await convertLegacyUserToPasswordless({
          user,
          completePasswordlessLogin,
          history,
          profileRequirements,
          handleImportAccount,
          setIsAuthenticated,
          cancelPasswordlessLoginAndKeepLegacy,
          onClose
        })
      } else {
        return history.replace('?mode=complete', {
          email: profileRequirements?.email,
          firstName: profileRequirements?.firstName,
          lastName: profileRequirements?.lastName,
          from: FROM_LOCATION.sms
        })
      }
    }
  } catch (error) {
    if (error instanceof TypeError) {
      logError(error)
      throw Error(
        `Toast is experiencing issues. Please continue to place your order without ${
          from === FROM_LOCATION.create ? 'creating an account' : 'logging in'
        }.`
      )
    }
    throw error
  }
}
