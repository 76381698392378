import React from 'react'
import PropTypes from 'prop-types'

import { CloseButton } from '@local/do-secundo-closebutton'
import Pencil from '../../../../assets/pencil.svg'
import { SavedAddressLabel } from '../../../SavedAddressLabel/SavedAddressLabel'
import { SavedAddressEditor } from '../SavedAddressEditor/SavedAddressEditor'

import styles from './SavedAddress.module.css'

export const SavedAddress = ({ savedAddress, editing, setEditing }) => {
  const close = () => setEditing(false)
  const open = () => setEditing(savedAddress.guid)

  return editing ? (
    <SavedAddressEditor onClose={close} savedAddress={savedAddress} />
  ) : (
    <div className={styles.savedAddress}>
      <SavedAddressLabel savedAddress={savedAddress} />
      <CloseButton onClick={open}>
        <Pencil className={styles.pencil} />
      </CloseButton>
    </div>
  )
}

SavedAddress.propTypes = {
  savedAddress: PropTypes.object.isRequired,
  setEditing: PropTypes.func.isRequired,
  editing: PropTypes.bool
}
