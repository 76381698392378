import { useMutation } from '@apollo/client'

import { UPDATE_FULFILLMENT_AND_VALIDATE } from '../../apollo/cart/cart.graphql'
import { isISODateTimeEqual } from '../../utils/time-utils'
import {
  useCart,
  CLEAR_MODIFICATION_ERRORS,
  PUSH_MODIFICATION_ERRORS
} from '@local/do-secundo-cart-provider'
import { dataByTypename } from '../../utils/apollo-helpers'
import { normalizeFulfillment } from '../FulfillmentSelectorModal/fulfillment-helpers'
import { useCallback, useReducer } from 'react'
import isEqual from 'lodash/isEqual'
import { createOutOfStockError } from '../../utils/cart-helpers'

// this is necessary because times locally versus times from bff
// differ slightly, so the times need to be compared in a normalized fashion
const needsUpdate = (a, b) => {
  a = normalizeFulfillment(a)
  b = normalizeFulfillment(b)
  return (
    a.fulfillmentType !== b.fulfillmentType ||
    a.diningOptionBehavior !== b.diningOptionBehavior ||
    !isEqual(a.deliveryInfo, b.deliveryInfo) ||
    !isISODateTimeEqual(a.fulfillmentTime, b.fulfillmentTime)
  )
}

export const useUpdateFulfillment = () => {
  const getData = (data) => data.updateFulfillmentAndValidate
  const [fulfillmentMutation] = useMutation(UPDATE_FULFILLMENT_AND_VALIDATE)
  const { cartGuid, dispatch } = useCart()
  const [fulfillmentState, setFulfillmentState] = useReducer(
    (_, { data, error, loading }) => ({ data, error, loading }),
    { loading: false }
  )

  const mutation = useCallback(
    async (prev, next) => {
      if (needsUpdate(prev, next)) {
        setFulfillmentState({
          ...prev,
          loading: true
        })

        try {
          const response = await fulfillmentMutation({
            variables: {
              input: {
                cartGuid,
                cartFulfillmentInput: {
                  diningOptionBehavior: next.diningOptionBehavior,
                  fulfillmentType: next.fulfillmentType,
                  fulfillmentDateTime: next.fulfillmentTime,
                  deliveryInfo: next.deliveryInfo
                }
              }
            }
          })

          const { CartModificationError, CartResponse, CartOutOfStockError } =
            dataByTypename(getData(response.data))
          if (CartModificationError) {
            const { message } = CartModificationError
            const error = new Error(message)
            setFulfillmentState({ error, loading: false })
            return { error }
          }

          if (CartOutOfStockError) {
            const { cart } = CartOutOfStockError
            const data = normalizeFulfillment(cart)

            setFulfillmentState({ data, loading: false })
            dispatch({ type: CLEAR_MODIFICATION_ERRORS })
            dispatch({
              type: PUSH_MODIFICATION_ERRORS,
              modificationErrors: [createOutOfStockError(CartOutOfStockError)]
            })

            return { data }
          }

          const { cart } = CartResponse
          const data = normalizeFulfillment(cart)
          setFulfillmentState({ data, loading: false })
          return { data }
        } catch (error) {
          setFulfillmentState({ error, loading: false })
          return { error }
        }
      }
      setFulfillmentState({ data: next, loading: false })
      return { data: next }
    },
    [cartGuid, setFulfillmentState, fulfillmentMutation, dispatch]
  )

  return [mutation, fulfillmentState]
}
