// Once OOV2 goes away completely, this file can be deleted!

import 'isomorphic-fetch'

const OOV2_TOKEN = 'toast-csrf-token'

const getTokens = async () => {
  // Only try to login if this token exists. Not used other than as a sentinel
  // to know that OOV2 was logged in.
  if (localStorage.getItem(OOV2_TOKEN)) {
    try {
      const gatewayBaseUri = window.OO_GLOBALS.gatewayBaseUri.replace(
        /-api/,
        ''
      )
      const refreshUri = `${gatewayBaseUri}/customermgmt/v1/auth/refresh`
      const resp = await fetch(refreshUri, {
        method: 'POST',
        credentials: 'include'
      })
      const data = await resp.json()
      const accessToken = data.accessJwt
      const refreshToken = data.refreshJwt
      if (accessToken && refreshToken) {
        return { accessToken, refreshToken }
      }
    } catch (err) {}
  }
  return false
}

const deleteTokens = () => {
  // Clear token so we don't automatically try to re-login. Technically doesn't
  // log the user out of OOV2, though.
  localStorage.removeItem(OOV2_TOKEN)
}

export const ooV2Auth = { getTokens, deleteTokens }
