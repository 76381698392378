import React, { useState, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import cx from 'classnames'

import { useAuth } from '../../../AuthProvider/AuthProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { dataByTypename } from '../../../../utils/apollo-helpers'
import { UPDATE_BASIC_INFO } from '../../../../apollo/customer.graphql'
import {
  LOYALTY_SIGNUP,
  LOYALTY_GET_COMPLETED_ORDER
} from '../../LoyaltyConfirmation.graphql'
import {
  LOYALTY,
  LearnMoreButton,
  useTermsModal
} from '@local/do-secundo-terms-modal'
import { ErrorComponent } from '@local/do-secundo-error'
import { Button, ButtonVariant } from '@local/do-secundo-button'

import Flare from '../../../../assets/flare.svg'
import styles from '../../LoyaltyConfirmation.module.css'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

const genericErrorMessage = 'Rewards program signup was unsuccessful.'

export const LoyaltySignup = ({
  actionTakenCallback,
  orderGuid,
  signupOnMount
}) => {
  const { user, unverifiedCustomer } = useAuth()
  const { restaurantGuid } = useRestaurant()
  const [error, setError] = useState(null)
  const transactionGuid = uuid()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  const {
    data,
    loading,
    error: queryError
  } = useQuery(LOYALTY_GET_COMPLETED_ORDER, {
    variables: {
      input: {
        orderGuid,
        restaurantGuid
      }
    }
  })
  let checkIdentifier
  let customer = {}
  if (data && data.completedOrder) {
    checkIdentifier = data.completedOrder.checkGuid
    customer = data.completedOrder.customerV2
  }
  const [loyaltySignupMutation, { loading: loyaltySignupLoading }] =
    useMutation(LOYALTY_SIGNUP, {
      variables: {
        input: {
          loyaltyAccountCreationInput: {
            restaurantGuid,
            transactionGuid,
            checkIdentifier
          },
          customerGuid: PASSWORDLESS_ENABLED
            ? user?.guid
            : unverifiedCustomer.guid || user.guid
        }
      },
      onError: () => {
        setError({ message: genericErrorMessage })
      }
    })

  const [updateBasicInfoMutation, { loading: basicInfoLoading }] = useMutation(
    UPDATE_BASIC_INFO,
    {
      variables: {
        input: {
          firstName: customer.firstName,
          lastName: customer.lastName,
          phone: customer.phone
        }
      },
      onError: () => {
        setError({ message: genericErrorMessage })
      }
    }
  )

  const signup = useCallback(async () => {
    setError(null)
    if (!unverifiedCustomer.guid && !(user && user.firstName)) {
      const basicInfoResponse = await updateBasicInfoMutation()
      if (basicInfoResponse.data) {
        const { UpdateBasicInfoResponse } = dataByTypename(
          basicInfoResponse.data.updateBasicInfo
        )
        if (!UpdateBasicInfoResponse) {
          setError({ message: genericErrorMessage })
          return
        }
      } else {
        setError({ message: genericErrorMessage })
        return
      }
    }
    const signupResponse = await loyaltySignupMutation()

    if (signupResponse && signupResponse.data) {
      const { LoyaltySignupResponse } = dataByTypename(
        signupResponse.data.loyaltySignup
      )
      if (LoyaltySignupResponse) {
        actionTakenCallback()
      } else {
        setError({ message: genericErrorMessage })
      }
    }
  }, [
    actionTakenCallback,
    loyaltySignupMutation,
    unverifiedCustomer,
    updateBasicInfoMutation,
    user
  ])

  useEffect(() => {
    if (data && signupOnMount) {
      signup()
    }
    // removed signup dependency b/c it causes a double signup in the pw- experience
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, signupOnMount])

  const { TermsModal, openModal } = useTermsModal({ terms: LOYALTY })

  if (loading || queryError) return null

  const {
    loyaltyConfig: { loyaltySignupBonus }
  } = data.completedOrder.restaurant
  const description = loyaltySignupBonus
    ? `Earn points for this order and another ${loyaltySignupBonus} just for joining this restaurant's reward program.`
    : `Earn points for this order by joining this restaurant's reward program.`

  const isPasswordlessWithNoUserGuid = PASSWORDLESS_ENABLED && !user?.guid
  return (
    <div className={styles.container}>
      <TermsModal />
      <Flare />
      <h2 className={cx(styles.title, 'type-headline-4')}>
        Don't let your points go to waste!
      </h2>
      <p className={styles.description}>{description}</p>
      {error && <ErrorComponent error={error} />}
      <Button
        data-testid='button-loyalty-signup'
        id='LoyaltySignupButton'
        onClick={signup}
        variant={ButtonVariant.PRIMARY}
        loading={loyaltySignupLoading || basicInfoLoading}
        disabled={isPasswordlessWithNoUserGuid}
      >
        {isPasswordlessWithNoUserGuid
          ? 'Enter your code to get started'
          : 'Start Earning'}
      </Button>
      <LearnMoreButton
        className={styles.infoButton}
        onClick={openModal}
        data-testid='button-loyalty-more-info'
        linkText={'Loyalty Program Terms and Conditions'}
      />
    </div>
  )
}

LoyaltySignup.propTypes = {
  actionTakenCallback: PropTypes.func.isRequired,
  orderGuid: PropTypes.string.isRequired,
  signupOnMount: PropTypes.bool
}
