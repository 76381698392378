const FUTURE_YEAR_LIMIT = 20

export function isExpirationDateValid(month, year, currentDate) {
  month = parseInt(month)
  year = parseInt(year)
  const currentYear = currentDate.getFullYear() - 2000 // gives us the year in two digit form (e.g. 2019 becomes 19)
  const currentMonth = currentDate.getMonth() + 1 // the Date object gives month in the range 0-11, but we need 1-12
  const maxMonth = 12
  const minMonth = 1

  if (!month || !year) {
    return false
  }
  if (month > maxMonth || month < minMonth) {
    return false
  }
  if (year === currentYear) {
    return month >= currentMonth
  }
  // Check for future expiration date more than 20 years and 1 month from now to match the standard set by cc processing
  if (
    year - currentYear > FUTURE_YEAR_LIMIT ||
    (year - currentYear === FUTURE_YEAR_LIMIT && month > currentMonth)
  ) {
    return false
  }
  return year >= currentYear
}
