import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { Input } from '@local/do-secundo-form'
import { Notification } from '@local/do-secundo-notification'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import {
  importSuccessToast,
  showLoggedInNotification,
  useAuth,
  useLogin
} from '../../../../client/components/AuthProvider/AuthProvider'
import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
import { Password } from '../../../../client/components/Form/Password/Password'
import {
  ModeLink,
  ModeRedirect
} from '../../../../client/components/ModeRouter/utils'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import {
  emailSchema,
  passwordSchema
} from '../../../../client/utils/form-schemas'
import { useImportAccount } from '../utils'
import { FROM_LOCATION } from '../utils/enums'

export interface EmailDetectedModalProps extends RouteComponentProps {
  onClose: () => void
}

export const EmailDetectedModal = withRouter(
  ({
    onClose: closeModal,
    location: { state = { email: '' }, pathname }
  }: EmailDetectedModalProps) => {
    const testIdPrefix = `email-detected-modal-`
    const { user, setMfaState, mfaRequiresVerification, setIsAuthenticated } =
      useAuth()
    const handleImportAccount = useImportAccount()
    const {
      login,
      result: { error: loginError, loading }
    } = useLogin()

    // state.email should be set by complete account modal's request to determine if email exists
    if (!state?.email) {
      closeModal()
    }

    const guestName = user?.firstName
    const onClose = React.useCallback(() => {
      closeModal()
      showLoggedInNotification(guestName)
    }, [closeModal, guestName])

    if (mfaRequiresVerification) {
      return (
        <ModeRedirect
          pathname={pathname}
          mode='mfa'
          state={{ from: FROM_LOCATION.emailDetected }}
        />
      )
    }

    return (
      <PoweredByToastModal
        className='pt-1'
        onClose={onClose}
        header={
          <>
            <h2
              data-testid={`${testIdPrefix}header`}
              className={cx('mx-0 mt-0 mb-3 type-headline-4')}
            >
              Good news! <br />
              It looks like you already have an account.
            </h2>
            <p className='type-default'>
              Enter your password below for access to your order history,
              personalized information, and payment methods.
            </p>
          </>
        }
      >
        <Formik
          initialValues={{ email: state.email, password: '' }}
          validationSchema={Yup.object().shape({
            email: emailSchema,
            password: passwordSchema
          })}
          onSubmit={async (values, { setError }) => {
            const input = {
              variables: {
                input: {
                  ...values,
                  forAccountLinking: true
                }
              }
            }
            const result = await login(input)

            const { MfaChallengeGeneratedResponse, LoginError } =
              dataByTypename(result.data.login)

            if (MfaChallengeGeneratedResponse) {
              setMfaState({
                mfaRequiresVerification: true,
                email: values.email,
                ...result.data.login
              })
            } else if (!LoginError) {
              await handleImportAccount({
                onSuccess: () => {
                  onClose()
                  importSuccessToast()
                },
                onError: setError
              })
            }
          }}
        >
          {({ isValid, error }) => (
            <Form>
              {loginError && (
                <Notification severity='error'>
                  {loginError.code === 'EMAIL_LOCKED' ? (
                    <>
                      You have reached the maximum number of failed login
                      attempts. Please&nbsp;
                      <ModeLink
                        mode='forgot'
                        data-testid='connect-account-modal-reset-password-link'
                        className='font-bold hover:underline text-error'
                        state={{
                          from: FROM_LOCATION.emailDetected,
                          email: state.email
                        }}
                      >
                        reset your password
                      </ModeLink>
                      &nbsp;and try again.
                    </>
                  ) : (
                    'Invalid username or password combination.'
                  )}
                </Notification>
              )}
              {error && (
                <Notification severity='error'>{error.message}</Notification>
              )}
              <Field
                data-testid={`${testIdPrefix}password`}
                id={`${testIdPrefix}-password`}
                name='password'
                label='Password'
                component={Password}
              />
              <Button
                data-testid={`${testIdPrefix}button-submit`}
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.PRIMARY}
                responsive
                loading={loading}
                disabled={!isValid}
              >
                Continue
              </Button>
              <ButtonSpacer vertical />
              <ModeLink
                data-testid='button-forgot-password'
                style='secondaryWide'
                mode='forgot'
                state={{
                  from: FROM_LOCATION.emailDetected,
                  email: state.email
                }}
              >
                Forgot Password
              </ModeLink>
              <ButtonSpacer vertical />
              <Button
                data-testid={`${testIdPrefix}button-skip`}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.LINK_WIDE}
                responsive
                onClick={onClose}
              >
                Skip
              </Button>
            </Form>
          )}
        </Formik>
      </PoweredByToastModal>
    )
  }
)
