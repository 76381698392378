import { useQuery } from '@apollo/client'
import { useReorder } from '../Reorder/ReorderProvider/ReorderProvider'
import {
  GET_PAGINATED_ORDERS,
  GET_ORDER_HISTORY
} from '../../apollo/orders/orders.graphql'
import { useAuth } from '../AuthProvider/AuthProvider'
import { apolloSkipLogoutWorkaround } from '../../utils/workarounds'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

/**
 * Returns boolean based on if previous orders exists
 * @param {Boolean} loading
 * @param {ApolloError} error
 * @param {{ paginatedOrders: { orders: [{}], totalSize: number }}} data
 * @returns {boolean}
 */
export function hasPreviousOrders({
  loading,
  error,
  data,
  passwordlessAuthEnabled = false
}) {
  if (loading || error) {
    return false
  }
  return Boolean(
    passwordlessAuthEnabled
      ? data?.orderHistory?.orders?.length
      : data?.paginatedOrders?.orders?.length
  )
}

/**
 * Hook that returns a user's previous orders from which they can reorder
 * @param {string} query
 * @param {number} limit
 * @returns {{reorderGuid: number, reorderMutation: Function}}
 */
export const useReorderQuery = (query = GET_PAGINATED_ORDERS, limit = 2) => {
  const { authenticated } = useAuth()
  const { restaurantGuid } = useRestaurant()
  const context = useReorder()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  query = PASSWORDLESS_ENABLED ? GET_ORDER_HISTORY : query

  const queryObject = PASSWORDLESS_ENABLED
    ? {
        ...apolloSkipLogoutWorkaround,
        variables: {
          input: {
            restaurantGuid,
            pageSize: limit + 1
          }
        },
        skip: !authenticated,
        fetchPolicy: 'cache-and-network'
      }
    : {
        ...apolloSkipLogoutWorkaround,
        variables: {
          input: {
            restaurantGuid,
            limit
          }
        },
        skip: !authenticated,
        fetchPolicy: 'cache-and-network'
      }

  const { loading, error, data, ...queryResponse } = useQuery(
    query,
    queryObject
  )

  return {
    ...context,
    ...queryResponse,
    loading,
    data,
    error,
    hasReorders: hasPreviousOrders({
      loading,
      error,
      data,
      passwordlessAuthEnabled: PASSWORDLESS_ENABLED
    })
  }
}
