import React from 'react'
import PropTypes from 'prop-types'

import styles from './SavedAddressOption.module.css'

export const SavedAddressOption = ({ name, deliveryInfo: { address1 } }) => (
  <span className={styles.addressOption}>
    {name && <span className={styles.addressName}>{name}</span>}
    <span> ({address1})</span>
  </span>
)

SavedAddressOption.propTypes = {
  name: PropTypes.string,
  deliveryInfo: PropTypes.shape({
    address1: PropTypes.string.isRequired
  }).isRequired
}
