import * as React from 'react'
import cx from 'classnames'

import { Badge } from '@toasttab/buffet-pui-badge'
import { TagIcon } from '@toasttab/buffet-pui-icons'

export interface MenuBadgeProps {
  children: React.ReactNode
  className?: string
  testId: string
}

export const MenuBadge = ({
  children,
  className = '',
  testId
}: MenuBadgeProps) => (
  <Badge
    className={cx(className, 'bg-gray-0')}
    color={Badge.Color.gray}
    iconLeft={<TagIcon className='text-primary-100' />}
    testId={testId}
  >
    <span className='inline-block ml-1 type-default text-primary-100 type-overline font-semibold'>
      {children}
    </span>
  </Badge>
)
