import { dataByTypename } from '../../../utils/apollo-helpers'
import {
  useCart,
  PUSH_MODIFICATION_ERRORS,
  CLEAR_MODIFICATION_ERRORS
} from '@local/do-secundo-cart-provider'
import {
  ITEMS_UNAVAILABLE,
  getErrorMessageByCode,
  createOutOfStockError
} from '../../../utils/cart-helpers'

const completeReorderMutationWithOrderValidation =
  ({ dispatch, updateCartGuid }) =>
  (data) => {
    dispatch({ type: CLEAR_MODIFICATION_ERRORS })
    const { ReorderResponse, ReorderError } = dataByTypename(data.reorderV2)
    const modificationErrors = []
    if (ReorderResponse) {
      const {
        cart: { guid },
        outOfStockItems,
        warningsV2
      } = ReorderResponse

      updateCartGuid(guid)
      // because ITEMS_UNAVAILABLE is a "special" case - we will create a custom error for it separately later
      modificationErrors.push(
        ...warningsV2.filter(({ code }) => code !== ITEMS_UNAVAILABLE)
      )
      // if we have outOfStockItems - add a custom error to render in addition to other warnings
      if (outOfStockItems.length > 0) {
        modificationErrors.push(
          createOutOfStockError({ items: outOfStockItems })
        )
      }
    }
    if (ReorderError) {
      const { code, message } = ReorderError
      modificationErrors.push({
        ...ReorderError,
        message: getErrorMessageByCode({ code }) || message // overwrite message if no items are available for reorder
      })
    }
    if (modificationErrors.length > 0) {
      dispatch({
        type: PUSH_MODIFICATION_ERRORS,
        modificationErrors
      })
    }
  }

export const useCompleteReorderMutation = () => {
  const cartProps = useCart()
  return completeReorderMutationWithOrderValidation(cartProps)
}
