import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'
import { toast } from 'react-toastify'
import {
  useAuth,
  AUTH_NOTIFICATION_CONTAINER_ID
} from '../../../../client/components/AuthProvider/AuthProvider'

export interface LogoutInput {
  variables: {
    input: {
      cart: any
    }
  }
}

export const useLogout = () => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const {
    logout: passwordLogout,
    passwordlessLogout,
    setIsAuthenticated
  } = useAuth()

  const logout = async (
    logoutInput: LogoutInput,
    notificationText = 'You are now logged out.'
  ) => {
    if (PASSWORDLESS_ENABLED) {
      await passwordlessLogout(logoutInput)
      setIsAuthenticated(false)
    } else {
      await passwordLogout(logoutInput)
    }

    toast(notificationText, { containerId: AUTH_NOTIFICATION_CONTAINER_ID })
  }

  return logout
}
