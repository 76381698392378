import React from 'react'
import { useQuery } from '@apollo/client'

import { AccountHeader } from '@local/do-secundo-account-header'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { ErrorComponent } from '@local/do-secundo-error'
import { OrdersList } from './OrderList/OrdersList'
import { NoOrders } from './NoOrders/NoOrders'
import { LoadingOrdersList } from './LoadingOrdersList/LoadingOrdersList'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { GET_ORDER_HISTORY } from '../../apollo/orders/orders.graphql'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Notification } from '@local/do-secundo-notification'
import styles from './MyOrdersPage.module.css'

export const MyOrdersPagePasswordless = ({ pageSize = 10 }) => {
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const { loading: availabilityLoading, orderingAvailable } = useAvailability()
  const moreOrders = React.useRef()
  const isFirstCall = React.useRef(true)
  const { data, loading, error, fetchMore } = useQuery(GET_ORDER_HISTORY, {
    variables: {
      input: {
        restaurantGuid,
        pageSize: pageSize + 1,
        nextPageToken: null
      }
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const loadNextBatch = () => {
    fetchMore({
      variables: {
        input: {
          restaurantGuid,
          pageSize,
          nextPageToken: data.orderHistory.nextPageToken
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          moreOrders.current = null
          return prev
        }
        if (fetchMoreResult.orderHistory.orders.length === pageSize) {
          moreOrders.current =
            fetchMoreResult.orderHistory.orders[
              fetchMoreResult.orderHistory.orders.length - 1
            ]
          return Object.assign({}, prev, {
            orderHistory: {
              nextPageToken: fetchMoreResult.orderHistory.nextPageToken,
              orders: [
                ...prev.orderHistory.orders,
                ...fetchMoreResult.orderHistory.orders
              ],
              __typename: fetchMoreResult.orderHistory.__typename
            }
          })
        }

        moreOrders.current = null
        return Object.assign({}, prev, {
          orderHistory: {
            nextPageToken: fetchMoreResult.orderHistory.nextPageToken,
            orders: [
              ...prev.orderHistory.orders,
              ...fetchMoreResult.orderHistory.orders
            ],
            __typename: fetchMoreResult.orderHistory.__typename
          }
        })
      }
    })
  }

  const orders = data ? data.orderHistory.orders : null
  const shouldShowEmpty = data && data.orderHistory.orders.length === 0

  if (isFirstCall.current && orders && orders.length > pageSize) {
    moreOrders.current = orders[orders.length - 1]
    isFirstCall.current = false
  }

  return (
    <div className={styles.page}>
      {!availabilityLoading && !orderingAvailable && (
        <div className={styles.notificationsContainer}>
          <Notification>
            Ordering online is currently not available.
          </Notification>
        </div>
      )}
      <AccountHeader to={getRestaurantPath()} title='My Orders' />
      <div className={styles.orderListContainer}>
        {orders && (
          <OrdersList
            orders={moreOrders.current ? orders.slice(0, -1) : orders}
          />
        )}
        {loading && <LoadingOrdersList count={pageSize} />}
      </div>
      {shouldShowEmpty && <NoOrders />}
      {error && <ErrorComponent />}
      {Boolean(moreOrders.current) && !loading && (
        <div className={styles.viewMoreButton}>
          <Button
            variant={ButtonVariant.LINK}
            onClick={loadNextBatch}
            id='view-more-button'
          >
            View More
          </Button>
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line import/no-default-export
export default MyOrdersPagePasswordless
