/**
 * Constant used to indicate scroll direction
 * @type {{VERTICAL: string, HORIZONTAL: string}}
 */
export const SCROLL_DIRECTIONS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

/**
 * Utility function to animate scroll an element to a given scroll position
 * @param {HTMLElement} scrollElement
 * @param {number} newScrollPosition
 * @param {string} scrollDirection
 */
export const scrollElementToPosition = (
  scrollElement,
  newScrollPosition,
  scrollDirection = SCROLL_DIRECTIONS.VERTICAL
) => {
  if (scrollDirection === SCROLL_DIRECTIONS.VERTICAL) {
    scrollElement.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth'
    })
  } else {
    scrollElement.scrollTo({
      left: newScrollPosition,
      behavior: 'smooth'
    })
  }
}
