import PropTypes from 'prop-types'
import { useGiftCardEnabled } from './use-gift-card-enabled'

export { useGiftCardEnabled }

export const IfGiftCard = ({ children }) => {
  const enabled = useGiftCardEnabled()
  return enabled ? children : null
}

IfGiftCard.propTypes = {
  children: PropTypes.node
}

export const UnlessGiftCard = ({ children }) => {
  const enabled = useGiftCardEnabled()
  return !enabled ? children : null
}

UnlessGiftCard.propTypes = {
  children: PropTypes.node
}
