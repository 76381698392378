import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export const StateLink = ({ to, isExternal, rel, ...props }) => {
  const relProp = useMemo(() => {
    const relAttributes = new Set(rel ? rel.split(/\s+/) : [])
    relAttributes.add('nofollow')
    return Array.from(relAttributes).join(' ')
  }, [rel])

  if (typeof to === 'string') {
    to = { pathname: to }
  }
  return (
    <Link
      {...props}
      rel={relProp}
      to={{
        ...to,
        state: {
          ...to.state,
          internal: isExternal ? false : true
        }
      }}
    />
  )
}

StateLink.propTypes = {
  to: PropTypes.any,
  rel: PropTypes.string
}
