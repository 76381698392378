import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { queryDataErrorGetter } from '../errors/errors'

/**
 * Hook that wraps the Apollo useQuery hook, but processes errors
 * returned from the data response body
 * @param {DocumentNode} query
 * @param {{QueryHookOptions, queryDataErrorFields}} options
 * @returns {QueryResult}
 */
export const useDataErrorQuery = (query, options = {}) => {
  const { queryDataErrorFields = [] } = options
  delete options.queryDataErrorFields
  const originalResponse = useQuery(query, options)
  const { error: originalError, data } = originalResponse
  const processedResponse = useMemo(() => {
    const errorGetter = queryDataErrorGetter(...queryDataErrorFields)
    const error = originalError || (data && errorGetter(data))
    if (error) return { error, data: null }
    return { data }
  }, [data, originalError, queryDataErrorFields])
  return { ...originalResponse, ...processedResponse }
}
