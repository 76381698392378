// utility function to add appropriate __typenames to an existing modifierGroup recursively
const modGroupWithTypenames = ({ modifiers = [], ...modifierGroupProps }) => ({
  ...modifierGroupProps,
  __typename: 'ModifierGroup',
  modifiers: modifiers.map(({ modifierGroups = [], ...props }) => ({
    ...props,
    __typename: 'Modifier',
    modifierGroups: modifierGroups.map(modGroupWithTypenames)
  }))
})

const requiredCheckbox = {
  guid: 'RC_MODIFIER_GROUP_GUID',
  minSelections: 1,
  maxSelections: 2,
  name: 'RC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'RC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'RC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'RC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'RC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const requiredCheckboxWithModQuads = {
  guid: 'RC_MODIFIER_GROUP_GUID',
  minSelections: 1,
  maxSelections: 2,
  name: 'RC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'RC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'RC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    },
    {
      itemGuid: 'RC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'RC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    }
  ]
}

const singleSelectCheckbox = {
  guid: 'SSC_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: 1,
  name: 'SSC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const singleSelectCheckboxWithModQuads = {
  guid: 'SSC_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: 1,
  name: 'SSC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    },
    {
      itemGuid: 'SSC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    }
  ]
}

const singleSelectCheckboxWithDefault = {
  guid: 'SSCD_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: 1,
  name: 'SSCD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSCD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSCD_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSCD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSCD_ITEM_NAME_2',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const singleSelectCheckboxWithDefaultModQuads = {
  guid: 'SSCD_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: 1,
  name: 'SSCD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSCD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSCD_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      allowsDuplicates: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSCD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSCD_ITEM_NAME_2',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      allowsDuplicates: false,
      modifierGroups: []
    }
  ]
}

const infiniteCheckbox = {
  guid: 'IC_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'IC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'IC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'IC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'IC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'IC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const infiniteCheckboxWithModQuads = {
  guid: 'IC_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'IC_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'IC_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'IC_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    },
    {
      itemGuid: 'IC_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'IC_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowsDuplicates: false
    }
  ]
}

const infiniteCheckboxFree = {
  guid: 'ICF_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'ICF_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'ICF_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'ICF_ITEM_NAME_1',
      price: 0,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'ICF_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'ICF_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const infiniteCheckboxFreeModQuads = {
  guid: 'ICF_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'ICF_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'ICF_ITEM_GUID_1',
      itemGroupGuid: null,
      allowsDuplicates: false,
      name: 'ICF_ITEM_NAME_1',
      price: 0,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'ICF_ITEM_GUID_2',
      itemGroupGuid: null,
      allowsDuplicates: false,
      name: 'ICF_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const infiniteCheckboxWithDefault = {
  guid: 'ICD_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'ICD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'ICD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'ICD_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'ICD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'ICD_ITEM_NAME_2',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const infiniteCheckboxWithDefaultModQuads = {
  guid: 'ICD_MODIFIER_GROUP_GUID',
  minSelections: 0,
  maxSelections: null,
  name: 'ICD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'ICD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'ICD_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: [],
      allowDuplicates: false
    },
    {
      itemGuid: 'ICD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'ICD_ITEM_NAME_2',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      modifierGroups: [],
      allowDuplicates: false
    }
  ]
}

const singleSelectRadio = {
  guid: 'SSR_MODIFIER_GROUP_GUID',
  minSelections: 1,
  maxSelections: 1,
  name: 'SSR_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSR_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSR_ITEM_NAME_1',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSR_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSR_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const singleSelectRadioWithDefault = {
  guid: 'SSRD_MODIFIER_GROUP_GUID',
  minSelections: 1,
  maxSelections: 1,
  name: 'SSRD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSRD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSRD_ITEM_NAME_1',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSRD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSRD_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      modifierGroups: []
    }
  ]
}

const singleSelectRadioWithDefaultModQuads = {
  guid: 'SSRD_MODIFIER_GROUP_GUID',
  minSelections: 1,
  maxSelections: 1,
  name: 'SSRD_MODIFIER_GROUP_NAME',
  pricingMode: 'INCLUDED',
  modifiers: [
    {
      itemGuid: 'SSRD_ITEM_GUID_1',
      itemGroupGuid: null,
      name: 'SSRD_ITEM_NAME_1',
      price: 10,
      selected: true,
      isDefault: true,
      outOfStock: false,
      allowsDuplicates: false,
      modifierGroups: []
    },
    {
      itemGuid: 'SSRD_ITEM_GUID_2',
      itemGroupGuid: null,
      name: 'SSRD_ITEM_NAME_2',
      price: 10,
      selected: false,
      isDefault: false,
      outOfStock: false,
      allowsDuplicates: false,
      modifierGroups: []
    }
  ]
}

const menuItem = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID_NO_NESTING',
  masterId: 'ITEM_GUID_NO_NESTING_M',
  calories: 123,
  price: 10.99,
  imageUrl: 'IMAGE_URL',
  itemGroupGuid: 'BAR_ITEM_GROUP',
  modifierGroups: [
    {
      guid: 'ITEM_GROUP_GUID',
      minSelections: 1,
      maxSelections: 1,
      name: 'MODIFIER_GROUP_NAME',
      pricingMode: 'INCLUDED',
      modifiers: [
        {
          itemGuid: 'ITEM_GUID',
          itemGroupGuid: 'ITEM_GROUP_GUID',
          name: 'MODIFIER_NAME',
          price: 0,
          selected: true,
          isDefault: true,
          outOfStock: false,
          modifierGroups: [],
          __typename: 'Modifier'
        }
      ],
      __typename: 'ModifierGroup'
    }
  ],
  usesFractionalQuantity: false,
  unitOfMeasure: 'NONE',
  __typename: 'MenuItem'
}

const DEEP_MODIFIERS_WITH_SAME_GUID = {
  description: 'Pick two favorites ',
  name: 'Appetizer Combo 2',
  guid: 'APP_COMBO_GUID',
  masterId: 'APP_COMBO_GUID_M',
  calories: null,
  price: 12,
  imageUrl: null,
  usesFractionalQuantity: false,
  unitOfMeasure: 'NONE',
  itemGroupGuid: 'BAR_ITEM_GROUP',
  __typename: 'MenuItem',
  modifierGroups: [
    {
      guid: 'APP_COMBO_SELECTION_GROUP_GUID',
      minSelections: 2,
      maxSelections: 2,
      name: 'App Combo Selection (2)',
      pricingMode: 'INCLUDED',
      __typename: 'ModifierGroup',
      modifiers: [
        {
          itemGuid: 'LOADED_FRIES_GUID',
          name: '1/2 Loaded Fries',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: [],
          itemGroupGuid: null
        },
        {
          itemGuid: 'SLIDER_GUID',
          name: '1/2 Slider',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          itemGroupGuid: null,
          __typename: 'Modifier',
          modifierGroups: [
            {
              guid: 'BURGER_SELECT_GROUP_GUID',
              minSelections: 1,
              maxSelections: 1,
              name: 'Select Burger',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'CHEESEBURGER_GUID',
                  name: 'Cheeseburger',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  itemGroupGuid: null,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'TURKEYBURGER_GUID',
                  name: 'Turkeyburger',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  itemGroupGuid: null,
                  __typename: 'Modifier',
                  modifierGroups: []
                }
              ]
            },
            {
              guid: 'SLIDER_SELECT_GROUP_GUID',
              minSelections: 1,
              maxSelections: 1,
              name: 'Select Slider',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'CHEESEBURGER_GUID',
                  name: 'Cheeseburger Sliders',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  itemGroupGuid: null,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'TEMPERATURE_SELECT_GROUP_GUID',
                      minSelections: 1,
                      maxSelections: 1,
                      name: 'Temperature',
                      pricingMode: 'INCLUDED',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'RARE_GUID',
                          name: 'Rare',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'MIDRARE_GUID',
                          name: 'Medium Rare',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'MEDIUM_GUID',
                          name: 'Medium',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                },
                {
                  itemGuid: 'CHICKEN_SLIDER_GUID',
                  name: 'Chicken Sliders',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  itemGroupGuid: null,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'CHICKEN_SLIDER_INGREDIENTS_SELECT_GROUP_GUID',
                      minSelections: 0,
                      maxSelections: null,
                      name: 'Ingredients CKN SLDR',
                      pricingMode: 'ADJUSTS_PRICE',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'MAYO_GUID',
                          name: 'Mayo',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'PICKELS_GUID',
                          name: 'Pickles',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'LOUSIVILLE_HOT_GUID',
                          name: 'Louisville Hot',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          itemGroupGuid: null,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

const menuItemWithDeepModifiers = {
  description: 'Pick two favorites ',
  name: 'Appetizer Combo 2',
  guid: 'APP_COMBO_GUID',
  masterId: 'APP_COMBO_GUID_M',
  calories: null,
  price: 12,
  imageUrl: null,
  usesFractionalQuantity: false,
  unitOfMeasure: 'NONE',
  itemGroupGuid: 'BAR_ITEM_GROUP',
  modifierGroups: [
    {
      guid: 'APP_COMBO_SELECTION_GROUP_GUID',
      minSelections: 2,
      maxSelections: 2,
      name: 'App Combo Selection (2)',
      pricingMode: 'INCLUDED',
      __typename: 'ModifierGroup',
      modifiers: [
        {
          itemGuid: 'LOADED_FRIES_GUID',
          name: '1/2 Loaded Fries',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: []
        },
        {
          itemGuid: 'SLIDER_GUID',
          name: '1/2 Slider',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: [
            {
              guid: 'SLIDER_SELECT_GROUP_GUID',
              minSelections: 1,
              maxSelections: 1,
              name: 'Select Slider',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'CHEESEBURGER_GUID',
                  name: 'Cheeseburger Sliders',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'TEMPERATURE_SELECT_GROUP_GUID',
                      minSelections: 1,
                      maxSelections: 1,
                      name: 'Temperature',
                      pricingMode: 'INCLUDED',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'RARE_GUID',
                          name: 'Rare',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'MIDRARE_GUID',
                          name: 'Medium Rare',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'MEDIUM_GUID',
                          name: 'Medium',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                },
                {
                  itemGuid: 'PORK_SLIDER_GUID',
                  name: 'Pork Sliders',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'CHICKEN_SLIDER_GUID',
                  name: 'Chicken Sliders',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'CHICKEN_SLIDER_INGREDIENTS_SELECT_GROUP_GUID',
                      minSelections: 0,
                      maxSelections: null,
                      name: 'Ingredients CKN SLDR',
                      pricingMode: 'ADJUSTS_PRICE',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'MAYO_GUID',
                          name: 'Mayo',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'PICKELS_GUID',
                          name: 'Pickles',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'LOUSIVILLE_HOT_GUID',
                          name: 'Louisville Hot',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          itemGuid: 'CHEESE_CURD_GUID',
          name: '1/2 Cheese Curd',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: [
            {
              guid: 'CURD_SAUCE_SELECTION_GROUP_GUID',
              minSelections: 1,
              maxSelections: null,
              name: 'Curd Sauce',
              pricingMode: 'INCLUDED',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'MARI_RANCH_GUID',
                  name: 'Mari-Ranch (Default)',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'MARINARA_GUID',
                  name: 'Marinara Sauce',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'RANCH_GUID',
                  name: 'Ranch',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'NO_CHOICE_GUID',
                  name: 'No Choice',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                }
              ]
            }
          ]
        },
        {
          itemGuid: 'QUESADILLA_GUID',
          name: '1/2 Quesadilla',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: []
        },
        {
          itemGuid: 'AVOCADO_TOAST_GUID',
          name: '1/2 Avocado Toast',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: [
            {
              guid: 'AT_INGREDIANTS_SELECTOR_GROUP_GUID',
              minSelections: 0,
              maxSelections: null,
              name: 'Ingrediants AT',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'AVOCADO_GUID',
                  name: 'Avocado',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'HONEY_GUID',
                  name: 'Pure Honey',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'SUBSTITUTE_BREAD_GUID',
                  name: 'Substitute Bread (HB)',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'SUBSTITUTE_BREAD_SELECTION_GROUP_GUID',
                      minSelections: 0,
                      maxSelections: 1,
                      name: 'Substitute Bread (HB)',
                      pricingMode: 'INCLUDED',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'SUB_MARBLE_RYE_GUID',
                          name: 'SUB Marble Rye',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'SUB_BRIOCHE_GUID',
                          name: 'SUB Brioche Bun',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'SUB_GLUTEN_FREE_GUID',
                          name: 'SUB *Gluten Free Roll',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          itemGuid: 'VALIDATION_SHAKE_GUID',
          name: 'Validation Shake',
          price: 0,
          selected: false,
          isDefault: false,
          outOfStock: false,
          __typename: 'Modifier',
          modifierGroups: [
            {
              guid: 'VALIDATION_SHAKE_INGREDIENTS_SELECT_GROUP_1_GUID',
              minSelections: 1,
              maxSelections: 1,
              name: 'Flavors',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'MINT_GUID',
                  name: 'Mint',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: [
                    {
                      guid: 'VALIDATION_SHAKE_DEEP_SELECTION',
                      minSelections: 0,
                      maxSelections: null,
                      name: 'Selections',
                      pricingMode: 'ADJUSTS_PRICE',
                      __typename: 'ModifierGroup',
                      modifiers: [
                        {
                          itemGuid: 'SELECTION_1_GUID',
                          name: 'Selection 1',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        },
                        {
                          itemGuid: 'SELECTION_2_GUID',
                          name: 'Selection 2',
                          price: 0,
                          selected: false,
                          isDefault: false,
                          outOfStock: false,
                          __typename: 'Modifier',
                          modifierGroups: []
                        }
                      ]
                    }
                  ]
                },
                {
                  itemGuid: 'PICKEL_JUICE_GUID',
                  name: 'Pickle juice',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'SIRACHA_GUID',
                  name: 'Siracha',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                }
              ]
            },
            {
              guid: 'VALIDATION_SHAKE_INGREDIENTS_SELECT_GROUP_2_GUID',
              minSelections: 1,
              maxSelections: 1,
              name: 'Leave unselected',
              pricingMode: 'ADJUSTS_PRICE',
              __typename: 'ModifierGroup',
              modifiers: [
                {
                  itemGuid: 'FOO_GUID',
                  name: 'Foo',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                },
                {
                  itemGuid: 'BAR_GUID',
                  name: 'Bar',
                  price: 0,
                  selected: false,
                  isDefault: false,
                  outOfStock: false,
                  __typename: 'Modifier',
                  modifierGroups: []
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  __typename: 'MenuItem'
}

const selection = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID',
  masterId: 'ITEM_GUID_M',
  calories: 123,
  price: 10.99,
  menuItemPrice: 10.99,
  quantity: 1,
  itemGuid: 'ITEM_GUID',
  itemGroupGuid: 'ITEM_GROUP_GUID',
  specialRequest: null,
  imageUrl: 'IMAGE_URL',
  usesFractionalQuantity: false,
  fractionalQuantity: {
    unitOfMeasure: 'NONE',
    quantity: 1
  },
  modifierGroups: [
    {
      guid: 'ITEM_GROUP_GUID',
      minSelections: 1,
      maxSelections: 1,
      name: 'MODIFIER_GROUP_NAME',
      pricingMode: 'INCLUDED',
      modifiers: [
        {
          itemGuid: 'ITEM_GUID',
          itemGroupGuid: 'ITEM_GROUP_GUID',
          name: 'MODIFIER_NAME',
          price: 0,
          selected: true,
          isDefault: true,
          outOfStock: false,
          modifierGroups: []
        }
      ]
    }
  ]
}

const selectionWithSpecialRequest = {
  ...selection,
  specialRequest: 'SPECIAL_INSTRUCTIONS'
}

// item is in the shape of an API menu/selection item
// groups is a three dimensional array determining
// which modifierGroup & modifier is assigned a new modifierGroup
const withNesting = (item, groups) => {
  const groupGuidsWithNested = (group) => {
    // performs a text transform on guid/itemGuids
    const transformModGroupGuids = (modifierGroup, transform) => ({
      ...modifierGroup,
      guid: transform(modifierGroup.guid),
      modifiers: modifierGroup.modifiers.map((mod) => ({
        ...mod,
        itemGuid: transform(mod.itemGuid)
      }))
    })

    return transformModGroupGuids(group, (str) => `${str}_NESTED`)
  }

  return {
    ...item,
    modifierGroups: item.modifierGroups.map((modGroup, modGroupIdx) =>
      modGroupWithTypenames({
        ...modGroup,
        modifiers: modGroup.modifiers.map((mod, modIdx) => ({
          ...mod,
          // assigns a corresponding moddifierGroups array to a specific modifier that corresponds to a modifierGroup of the original item
          // groupGuidsWithNested adds '_NESTED' to guids for easier testing/debugging
          modifierGroups: groups[modGroupIdx][modIdx].map(groupGuidsWithNested)
        }))
      })
    )
  }
}

const withSelected = (group, selections) => ({
  ...group,
  modifiers: group.modifiers.map((mod, idx) => ({
    ...mod,
    selected: selections.includes(idx)
  }))
})

/**
 * Used when mod quantity enabled and modifier is of type checkbox or modifierBoxInput
 * @param group
 * @param selections
 * @returns {*&{modifiers}}
 */
const withQuantity = (group, selections) => ({
  ...group,
  modifiers: group.modifiers.map((mod, idx) => ({
    ...mod,
    quantity: Number(selections.includes(idx))
  }))
})

const menuItemv2 = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID',
  masterId: 'ITEM_GUID_M',
  itemGroupGuid: 'ITEM_GROUP_GUID',
  calories: 123,
  price: 10.99,
  imageUrl: 'IMAGE_URL',
  usesFractionalQuantity: false,
  unitOfMeasure: 'NONE',
  __typename: 'MenuItem',
  modifierGroups: [
    singleSelectRadioWithDefault,
    singleSelectCheckboxWithDefault
  ].map(modGroupWithTypenames)
}

const menuItemv2ModQuads = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID',
  masterId: 'ITEM_GUID_M',
  itemGroupGuid: 'ITEM_GROUP_GUID',
  calories: 123,
  price: 10.99,
  imageUrl: 'IMAGE_URL',
  usesFractionalQuantity: false,
  unitOfMeasure: 'NONE',
  __typename: 'MenuItem',
  modifierGroups: [
    singleSelectRadioWithDefaultModQuads,
    singleSelectCheckboxWithDefaultModQuads
  ].map(modGroupWithTypenames)
}

const selectionv2 = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID',
  calories: 123,
  price: 10.99,
  menuItemPrice: 10.99,
  quantity: 1,
  itemGuid: 'ITEM_GUID',
  itemGroupGuid: 'ITEM_GROUP_GUID',
  masterId: 'ITEM_GUID_M',
  specialRequest: null,
  imageUrl: 'IMAGE_URL',
  usesFractionalQuantity: false,
  fractionalQuantity: {
    unitOfMeasure: 'NONE',
    quantity: 1
  },
  modifierGroups: [
    singleSelectRadioWithDefault,
    singleSelectCheckboxWithDefault
  ].map(modGroupWithTypenames)
}

const selectionv2ModQuads = {
  description: 'DESCRIPTION',
  name: 'NAME',
  guid: 'ITEM_GUID',
  masterId: 'ITEM_GUID_M',
  calories: 123,
  price: 10.99,
  menuItemPrice: 10.99,
  quantity: 1,
  itemGuid: 'ITEM_GUID',
  itemGroupGuid: 'ITEM_GROUP_GUID',
  specialRequest: null,
  imageUrl: 'IMAGE_URL',
  usesFractionalQuantity: false,
  fractionalQuantity: {
    unitOfMeasure: 'NONE',
    quantity: 1
  },
  modifierGroups: [
    singleSelectRadioWithDefaultModQuads,
    singleSelectCheckboxWithDefaultModQuads
  ].map(modGroupWithTypenames)
}

const selectionWithSpecialRequestv2 = {
  ...selectionv2,
  specialRequest: 'SPECIAL_INSTRUCTIONS'
}

const selectionWithSpecialRequestv2ModQuads = {
  ...selectionv2ModQuads,
  specialRequest: 'SPECIAL_INSTRUCTIONS'
}

// dynamically generates expected addItemToCartInput for mutation from a menu item
const getAddItemToCartInput = (item, itemGroupGuid) => {
  // recursively get modifierGroups in proper format with itemGroupGuid
  const getModGroups = (modifierGroups) =>
    modifierGroups.map(({ guid, modifiers = [] }) => ({
      guid,
      modifiers: modifiers
        .filter((mod) => mod.selected)
        .map(({ itemGuid, modifierGroups = [] }) => ({
          itemGuid,
          itemGroupGuid,
          modifierGroups: getModGroups(modifierGroups),
          quantity: 1
        }))
    }))

  return {
    cartGuid: 'CART_GUID',
    selection: {
      itemGuid: item.guid,
      itemGroupGuid,
      itemMasterId: item.masterId,
      quantity: 1,
      modifierGroups: getModGroups(item.modifierGroups),
      specialInstructions: ''
    }
  }
}

const addItemToCartInput = {
  cartGuid: 'CART_GUID',
  selection: {
    itemGuid: 'ITEM_GUID',
    itemMasterId: 'ITEM_GUID_M',
    itemGroupGuid: 'ITEM_GROUP_GUID',
    quantity: 1,
    modifierGroups: [
      {
        guid: 'ITEM_GROUP_GUID',
        modifiers: [
          {
            itemGuid: 'ITEM_GUID',
            itemGroupGuid: 'ITEM_GROUP_GUID',
            modifierGroups: [],
            quantity: 1
          }
        ]
      }
    ],
    specialInstructions: ''
  }
}

export {
  addItemToCartInput,
  requiredCheckbox,
  requiredCheckboxWithModQuads,
  singleSelectCheckbox,
  singleSelectCheckboxWithModQuads,
  singleSelectCheckboxWithDefault,
  singleSelectCheckboxWithDefaultModQuads,
  infiniteCheckbox,
  infiniteCheckboxWithModQuads,
  infiniteCheckboxWithDefault,
  infiniteCheckboxWithDefaultModQuads,
  infiniteCheckboxFree,
  infiniteCheckboxFreeModQuads,
  singleSelectRadio,
  singleSelectRadioWithDefault,
  singleSelectRadioWithDefaultModQuads,
  menuItem,
  menuItemWithDeepModifiers,
  DEEP_MODIFIERS_WITH_SAME_GUID,
  selection,
  selectionWithSpecialRequest,
  menuItemv2,
  menuItemv2ModQuads,
  selectionv2,
  selectionv2ModQuads,
  selectionWithSpecialRequestv2,
  selectionWithSpecialRequestv2ModQuads,
  withNesting,
  withSelected,
  withQuantity,
  getAddItemToCartInput
}
