import { ACCOUNT_DATA_STATE } from '@local/do-secundo-passwordless-authentication'
import PropTypes from 'prop-types'

// TODO Some of this will need to be updated when we implement delivery support.

// Based on src/javascript/checkout/checkoutSteps/payment/payment.service.js
// from old OO.
const ALL_OPTIONS = {
  CASH_TAKEOUT: {
    id: 'CASH_TAKEOUT',
    displayName: 'Cash',
    description: 'Please pay with cash.'
  },
  CASH_DELIVERY: {
    id: 'CASH_DELIVERY',
    displayName: 'Cash',
    description:
      'Please pay with a credit card or cash and remember to tip your driver.'
  },
  CREDIT: {
    id: 'CREDIT',
    displayName: 'Credit Card'
  },
  NEW_CREDIT: {
    id: 'NEW_CREDIT',
    displayName: 'New Credit Card'
  },
  SAVED_CREDIT: {
    id: 'SAVED_CREDIT',
    displayName: 'Saved Credit Card'
  },
  IN_STORE: {
    id: 'IN_STORE',
    displayName: 'In Store',
    description: 'Please pay using a valid credit card or cash.'
  },
  CREDIT_IN_STORE: {
    id: 'CREDIT_IN_STORE',
    displayName: 'In Store',
    description: 'Please pay using a valid credit card.'
  }
}

const _findCreditAtCheckout = (paymentOptions) =>
  paymentOptions.atCheckout
    .map((option) => option.paymentType)
    .find((paymentType) => paymentType === 'CREDIT')

const _findCashUponReceipt = (paymentOptions) =>
  paymentOptions.uponReceipt
    .map((option) => option.paymentType)
    .find((paymentType) => paymentType === 'CASH')

const _findCreditUponReceipt = (paymentOptions) =>
  paymentOptions.uponReceipt
    .map((option) => option.paymentType)
    .find((paymentType) => paymentType === 'CREDIT')

const _isCreditAllowed = (paymentOptions) => {
  if (!paymentOptions) {
    return false
  }

  return Boolean(_findCreditAtCheckout(paymentOptions))
}

export const getCreditPaymentOption = ({ authenticated, paymentOptions }) => {
  if (!_isCreditAllowed(paymentOptions)) {
    return null
  }

  return authenticated ? ALL_OPTIONS.NEW_CREDIT : ALL_OPTIONS.CREDIT
}

export const getSavedCreditPaymentOption = ({
  authenticated,
  paymentOptions
}) => {
  if (!_isCreditAllowed(paymentOptions)) {
    return null
  }

  if (!authenticated) {
    return null
  }

  return ALL_OPTIONS.SAVED_CREDIT
}

const _isPaymentUponReceiptAllowed = (paymentOptions) => {
  if (!paymentOptions) {
    return false
  }

  return Boolean(
    _findCreditUponReceipt(paymentOptions) ||
      _findCashUponReceipt(paymentOptions)
  )
}

export const getPaymentUponReceiptOption = ({ paymentOptions }) => {
  if (!_isPaymentUponReceiptAllowed(paymentOptions)) {
    return null
  }

  if (paymentOptions.uponReceipt.length >= 2) {
    return ALL_OPTIONS.IN_STORE
  }

  if (_findCreditUponReceipt(paymentOptions)) {
    return ALL_OPTIONS.CREDIT_IN_STORE
  }

  return ALL_OPTIONS.CASH_TAKEOUT
}

/**
 * Determine the payment type that should be initially selected based on
 * the available options.
 */
export const getInitialPaymentType = (
  paymentOptions,
  savedCards = [],
  creditCardsStatus
) => {
  if (!paymentOptions) {
    return ''
  }

  const credit = _findCreditAtCheckout(paymentOptions)

  if (credit) {
    if (creditCardsStatus === ACCOUNT_DATA_STATE.importing) {
      //If data is being imported and may load in very soon then
      //we want the saved credit card tab to be what the guest will see and wait for.
      return 'SAVED_CREDIT_CARD'
    }
    // If there are no saved cards, even after waiting for an import,
    // there is no other action to take on saved cards so switch to the new credit card entry.
    // TODO return 'CREDIT' (see TODO in CheckoutForm)
    return savedCards.length ? 'SAVED_CREDIT_CARD' : 'CREDIT_CARD'
  }

  // If the diner is paying when they receive their order, we have to
  // indicate CASH for the purpose of submitting the order. The payment will
  // be updated on the POS as needed in this case.
  if (_isPaymentUponReceiptAllowed(paymentOptions)) {
    return 'CASH'
  }

  return ''
}

export const PaymentOptionShape = PropTypes.shape({
  paymentType: PropTypes.string.isRequired
})

export const PaymentOptionsShape = PropTypes.shape({
  atCheckout: PropTypes.arrayOf(PaymentOptionShape).isRequired,
  uponReceipt: PropTypes.arrayOf(PaymentOptionShape).isRequired
})
