import React, { useEffect, useReducer, useState } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { useCart } from '@local/do-secundo-cart-provider'
import { GET_CART } from '../../apollo/cart/cart.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useUpdateFulfillment } from '../UseUpdateFulfillment/UseUpdateFulfillment'
import {
  normalizeFulfillment,
  isFulfillmentTimeValid
} from '../FulfillmentSelectorModal/fulfillment-helpers'
import { stripDeliveryInfo } from '../../utils/address-helpers'

import { initialReducerState } from './initial-fulfillment-state'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { Loading } from '@toasttab/do-secundo-loading'

export const FULFILLMENT_TYPES = {
  ASAP: 'ASAP',
  FUTURE: 'FUTURE'
}

const { DELIVERY } = DINING_OPTION_BEHAVIORS

const FULFILLMENT_WARNING_CODE = 'CART_UNFULFILLABLE'

const fulfillmentKeys = [
  'fulfillmentType',
  'diningOptionBehavior',
  'fulfillmentTime',
  'deliveryInfo'
]

const warningsContainsFulfillment = (warnings = []) => {
  return warnings.some((warning) => warning.code === FULFILLMENT_WARNING_CODE)
}

const getDeliveryInfo = ({ deliveryInfo, diningOptionBehavior }) => {
  if (diningOptionBehavior !== DELIVERY) return undefined
  return deliveryInfo || {}
}

const FulfillmentContext = React.createContext({})

export const fulfillmentReducer = (prevState, nextState) => {
  return {
    savedAddressGuid: prevState.savedAddressGuid,
    ...nextState,
    deliveryInfo: getDeliveryInfo(nextState),
    selected: true
  }
}

const FulfillmentProviderInner = ({
  children,
  asapAvailable,
  initialState
}) => {
  const fulfillmentStorage = useRestaurantStorage()
  const { cartGuid: guid } = useCart()
  const [updateFulfillment, { error, loading: updating }] =
    useUpdateFulfillment()
  const { authenticated } = useAuth()
  const shouldLoadCart = Boolean(guid)
  const { data, loading: cartQueryLoading } = useQuery(GET_CART, {
    variables: { guid },
    skip: !shouldLoadCart
  })
  const [cartLoading, setCartLoading] = useState(shouldLoadCart)

  const [fulfillmentState, setFulfillmentState] = useReducer(
    fulfillmentReducer,
    initialState
  )

  // when data is loaded and there is a cart fulfillmentType set,
  // then clear localStorage and use that instead
  useEffect(() => {
    const { CartResponse } = data ? dataByTypename(data.cartV2) : {}
    if (CartResponse?.cart?.fulfillmentType) {
      // clear local storage and use cart as canonical source
      fulfillmentKeys.forEach((key) => fulfillmentStorage.remove(key))

      const {
        fulfillmentType,
        fulfillmentTime,
        diningOptionBehavior,
        deliveryInfo,
        deliveryProvider
      } = normalizeFulfillment(CartResponse.cart)

      const valid =
        !warningsContainsFulfillment(CartResponse.warnings) &&
        isFulfillmentTimeValid(fulfillmentTime, fulfillmentType)

      setFulfillmentState({
        fulfillmentType,
        fulfillmentTime,
        diningOptionBehavior,
        deliveryInfo,
        deliveryProvider,
        valid
      })
    }

    // We need cartLoading to lag behind cartQueryLoading by one tick so that we will
    // have set fulfillmentState by the time we return loading=true to the consumer.
    setCartLoading(cartQueryLoading)
  }, [cartQueryLoading, data, fulfillmentStorage])

  /**
   * Sets the delivery info on the cart, leaving all other fulfillment
   * properties as-is.
   */
  const setDeliveryInfo = async ({
    savedAddressGuid,
    deliveryInfo: requestedDeliveryInfo
  }) => {
    const { CartResponse } = data ? dataByTypename(data.cartV2) : {}
    const cart = CartResponse && CartResponse.cart
    let newDeliveryInfo = stripDeliveryInfo(requestedDeliveryInfo)

    if (!cart) {
      // TODO Implement if needed, currently only needed by checkout page.
      throw new Error('missing cart')
    }

    if (savedAddressGuid !== undefined) {
      setFulfillmentState({
        ...fulfillmentState,
        savedAddressGuid,
        deliveryInfo: newDeliveryInfo
      })
    }

    const cartFulfillment = normalizeFulfillment(cart)
    const { error } = await updateFulfillment(cartFulfillment, {
      ...cartFulfillment,
      deliveryInfo: newDeliveryInfo
    })
    if (error) {
      throw error
    }
  }

  // any time the state changes and differs from cart values, updateAndClear cart
  // if there's no cart use local storage instead
  const setFulfillment = async (requestedFulfillment) => {
    const { CartResponse } = data ? dataByTypename(data.cartV2) : {}
    const cart = CartResponse && CartResponse.cart
    let newFulfillment = normalizeFulfillment(requestedFulfillment)

    // set state locally if no cart
    if (!cart) {
      fulfillmentKeys.forEach((key) =>
        fulfillmentStorage.set(key, newFulfillment[key])
      )
      setFulfillmentState({
        ...newFulfillment,
        valid: true
      })
      return
    }

    if (newFulfillment.savedAddressGuid !== undefined) {
      setFulfillmentState({
        ...fulfillmentState,
        savedAddressGuid: newFulfillment.savedAddressGuid,
        deliveryInfo: newFulfillment.deliveryInfo
      })
    }

    const cartFulfillment = normalizeFulfillment(cart)
    const { error } = await updateFulfillment(cartFulfillment, newFulfillment)
    if (error) {
      throw error
    }
  }

  const context = {
    ...fulfillmentState,
    savedAddressGuid: authenticated ? fulfillmentState.savedAddressGuid : '',
    /**
     * Indicates if the cart is loading, which must happen first so we know whether it exists or
     * not in order to pull the fulfillment state from the cart vs. local storage.
     */
    loading: cartLoading || cartQueryLoading,
    error,
    /**
     * Indicates if either setDeliveryInfo() or setFulfillment() have been called
     * and are in the midst of updating.
     */
    updating,
    setDeliveryInfo,
    setFulfillment
  }

  return (
    <FulfillmentContext.Provider value={context}>
      {children}
    </FulfillmentContext.Provider>
  )
}

FulfillmentProviderInner.propTypes = {
  children: PropTypes.node.isRequired,
  asapAvailable: PropTypes.bool,
  initialState: PropTypes.object
}

export const FulfillmentProvider = ({ children }) => {
  const defaultToDeliveryFlagOn = useFlag(FF.DEFAULT_TO_DELIVERY)
  const { orderingAvailable, availability, loading } = useAvailability()
  const fulfillmentStorage = useRestaurantStorage()
  if (loading) {
    return (
      <div className='flex items-center justify-center h-24'>
        <Loading variant='secondary' />
      </div>
    )
  }

  const initialState = initialReducerState({
    fulfillmentStorage,
    availability,
    orderingAvailable,
    defaultToDeliveryFlagOn
  })
  const { error } = initialState
  if (error) {
    return (
      <FulfillmentContext.Provider value={{ error }}>
        {children}
      </FulfillmentContext.Provider>
    )
  }

  return (
    <FulfillmentProviderInner children={children} initialState={initialState} />
  )
}

FulfillmentProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const FulfillmentConsumer = ({ children }) => (
  <FulfillmentContext.Consumer>
    {(context) => children(context)}
  </FulfillmentContext.Consumer>
)

FulfillmentConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useFulfillment = () => React.useContext(FulfillmentContext)
