export const OTP_EXPIRED_MESSAGE =
  'This login code has expired. Please click "Resend code" to receive a new code.'

export const ACCOUNT_LOCKED_ERROR_MESSAGE =
  'You have reached the maximum number of authentication attempts. Please try logging in again at a future date. You can still continue to place your order in guest mode.'

export const DEFAULT_403_ERROR_MESSAGE =
  'Wrong or expired code. Try again by reentering or resending the code.'

export const MAX_NUMBER_OF_ATTEMPTS_ERROR =
  "You've reached the maximum number of attempts. Please try to login again."

export const VERIFICATION_CODE_EXPIRED_ERROR =
  'The verification code has expired. Please try to login again.'
