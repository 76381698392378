// @ts-ignore
import { useFulfillment } from '../../../../client/components/FulfillmentProvider/FulfillmentProvider'
import { useQuery } from '@apollo/client'
// @ts-ignore
import { FIND_MENU_ITEMS } from '../../../pricingutility/src/PricingUtility/graphql/doMenuItem.graphql'

export interface UseFindMenuItemsInput {
  items: { itemGuid: string; groupGuid: string }[]
  restaurantGuid: string
}

export const useFindMenuItems = ({
  items,
  restaurantGuid
}: UseFindMenuItemsInput) => {
  const { fulfillmentTime } = useFulfillment()
  const dateTime = fulfillmentTime || undefined

  const { loading, error, data, called } = useQuery(FIND_MENU_ITEMS, {
    notifyOnNetworkStatusChange: true,
    // no cache instead of cache-and-network
    // because of: https://github.com/apollographql/react-apollo/issues/3361
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        restaurantGuid,
        items,
        visibility: 'TOAST_ONLINE_ORDERING',
        dateTime
      }
    }
  })

  return { loading, error, data, called }
}
