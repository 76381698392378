import React from 'react'
import PropTypes from 'prop-types'

import { ReorderCard } from '../../Reorder/ReorderCard/ReorderCard'

export const LoadingOrdersList = ({ count = 10 }) => {
  return Array.from({ length: count }, (_v, k) => (
    <ReorderCard
      key={k}
      loading
      orderGuid={'' + k}
      items={[]}
      price={k}
      date={k}
    />
  ))
}

LoadingOrdersList.propTypes = {
  count: PropTypes.number
}
