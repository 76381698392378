import React from 'react'

interface IModalOverrideContext {
  isDisabled: boolean
  disableModal(uuid: string): void
  enableModal(uuid: string): void
}

export const ModalOverrideContext = React.createContext<IModalOverrideContext>({
  isDisabled: false,
  disableModal() {
    console.error('Used outside of ModalOverrideContext')
  },
  enableModal() {
    console.error('Used outside of ModalOverrideContext')
  }
})

export const ModalOverrideProvider: React.FC = ({ children }) => {
  const [disabledMap, setDisabledMap] = React.useState<Record<string, boolean>>(
    {}
  )

  const isDisabled = Object.values(disabledMap).some((v) => v)

  const context = React.useMemo(() => {
    return {
      isDisabled,
      disableModal: (uuid: string) => {
        setDisabledMap((s) => ({ ...s, [uuid]: true }))
      },
      enableModal: (uuid: string) => {
        setDisabledMap((s) => ({ ...s, [uuid]: false }))
      }
    }
  }, [isDisabled])

  return (
    <ModalOverrideContext.Provider value={context}>
      {children}
    </ModalOverrideContext.Provider>
  )
}
