import { boolean, object, string } from 'yup'
import { passwordSchema } from '../../utils/form-schemas'
import * as CustomerInfo from './CustomerInfo/CustomerInfo'
import * as CheckoutDeliveryInfo from './CheckoutDeliveryInfo/CheckoutDeliveryInfo'
import * as CurbsidePickup from './CurbsidePickup/CurbsidePickup'
import * as GiftCard from './GiftCard/GiftCard'
import * as DeliveryCommunicationConsent from './DeliveryCommunicationConsent/DeliveryCommunicationConsent'
import * as Tip from './Tip/Tip'
import * as Fundraising from './Fundraising/Fundraising'
import { hasSufficientFunds } from '../../utils/gift-card-helpers'
import { getPrimaryCreditCard } from '@local/do-secundo-form-utils'
import { getInitialPaymentType } from './payment-options'

export const formModules = [
  CustomerInfo,
  CheckoutDeliveryInfo,
  CurbsidePickup,
  GiftCard,
  DeliveryCommunicationConsent,
  Tip,
  Fundraising
]

export const whenNewCredit = ({ then, otherwise = string().default('') }) =>
  string().when(['$giftCardHasSufficientFunds', 'paymentType', 'paymentTip'], {
    is: (giftCardHasSufficientFunds, paymentType, paymentTip) => {
      if (paymentType !== 'CREDIT_CARD') return false
      if (paymentTip > 0) return true
      return !giftCardHasSufficientFunds
    },
    then,
    otherwise
  })

export const whenSavedCredit = ({ then, otherwise = string().default('') }) =>
  string().when(['$giftCardHasSufficientFunds', 'paymentType', 'paymentTip'], {
    is: (giftCardHasSufficientFunds, paymentType, paymentTip) => {
      if (paymentType !== 'SAVED_CREDIT_CARD') return false
      if (paymentTip > 0) return true
      return !giftCardHasSufficientFunds
    },
    then,
    otherwise
  })

export const getValidationSchema = ({
  cart,
  curbsidePickupConfig,
  featureFlags,
  fulfillmentContext
}) => {
  // To be refactored into their own form modules.
  const legacyValidationSchema = {
    paymentType: string()
      .trim()
      .required('required')
      .oneOf(
        ['CASH', 'SAVED_CREDIT_CARD', 'CREDIT_CARD'],
        'must be a valid payment type'
      ),
    savedCardGuid: whenSavedCredit({
      then: string()
        .required('required')
        // guid will be prefixed with '!' if card isn't accepted.
        .matches(/^[^!]/, 'must be an accepted card type')
    }),
    encryptedCard: whenNewCredit({
      // The validation only seems to work with string() even though this value is
      // actually an object. Note that the value itself should be opaque since it
      // came from the iframe so we really only care whether it is present or not.
      then: string().required('required')
    }),
    saveCard: boolean().default(false),
    shouldSavePasswordlessAccount: boolean().default(false),
    password: string().when(
      [
        '$authenticated',
        'paymentType',
        'saveCard',
        'shouldSavePasswordlessAccount'
      ],
      {
        is: (
          authenticated,
          paymentType,
          saveCard,
          shouldSavePasswordlessAccount
        ) =>
          !authenticated &&
          paymentType === 'CREDIT_CARD' &&
          saveCard &&
          !shouldSavePasswordlessAccount,
        then: passwordSchema,
        otherwise: string().default('')
      }
    )
  }

  const validationSchema = formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getValidationSchema
        ? formModule.getValidationSchema({
            cart,
            curbsidePickupConfig,
            featureFlags,
            fulfillmentContext
          })
        : null)
    }),
    legacyValidationSchema
  )

  return object().shape(validationSchema)
}

export const getArgsForSubmit = ({
  cart,
  featureFlags,
  fulfillmentContext,
  giftCardContext,
  savedAddresses,
  values,
  curbsidePickupConfig
}) => {
  let { paymentType } = values
  const { giftCard } = giftCardContext

  const customer = CustomerInfo.getArgsForSubmit({ values })

  // TODO Refactor to leverage the `formModules` array like getInitialValues().
  const deliveryInfoArgs = CheckoutDeliveryInfo.getArgsForSubmit({
    featureFlags,
    fulfillmentContext,
    savedAddresses,
    values
  })

  const curbsideArgs = CurbsidePickup.getArgsForSubmit({
    featureFlags,
    fulfillmentContext,
    values,
    curbsidePickupConfig
  })

  const giftCardArgs = GiftCard.getArgsForSubmit({
    featureFlags,
    giftCardContext
  })

  const deliveryCommunicationConsentArgs =
    DeliveryCommunicationConsent.getArgsForSubmit({
      values,
      cart,
      featureFlags
    })

  const tipArgs = Tip.getArgsForSubmit({
    values,
    cart
  })

  const roundUpEnabledArgs = Fundraising.getArgsForSubmit({
    values,
    cart,
    featureFlags
  })

  if (hasSufficientFunds({ giftCard, cart }) && tipArgs.tipAmount === 0) {
    return {
      cashInput: {
        ...customer,
        ...deliveryInfoArgs,
        ...curbsideArgs,
        ...giftCardArgs,
        ...roundUpEnabledArgs
      },
      paymentType: 'CASH'
    }
  }

  if (paymentType === 'SAVED_CREDIT_CARD' || paymentType === 'CREDIT_CARD') {
    // TODO rename CREDIT_CARD to CREDIT everywhere (the constant the backend uses)

    const argsForSubmit = {
      ccInput: {
        ...customer,
        ...deliveryCommunicationConsentArgs,
        ...deliveryInfoArgs,
        ...curbsideArgs,
        ...giftCardArgs,
        ...tipArgs,
        ...roundUpEnabledArgs
      },
      paymentType: 'CREDIT'
    }

    if (paymentType === 'SAVED_CREDIT_CARD') {
      argsForSubmit.ccInput.savedCardInput = {
        cardGuid: values.savedCardGuid
      }
    } else {
      argsForSubmit.ccInput.newCardInput = {
        ...values.encryptedCard,
        saveCard: values.saveCard
      }

      if (values.saveCard) {
        argsForSubmit.password = values.password
      }
    }

    return argsForSubmit
  }

  return {
    cashInput: {
      ...customer,
      ...deliveryInfoArgs,
      ...curbsideArgs,
      ...giftCardArgs,
      ...roundUpEnabledArgs
    },
    paymentType: 'CASH'
  }
}

export const getDefaultCard = (creditCards = []) => {
  const card = getPrimaryCreditCard(creditCards)
  return card.guid || ''
}

export const getInitialValues = ({
  cart,
  creditCards,
  creditCardsStatus,
  featureFlags,
  fulfillmentContext,
  giftCardContext,
  paymentOptions,
  savedAddresses,
  user,
  curbsidePickupConfig,
  shouldSavePasswordlessAccount = false
}) => {
  // To be refactored into their own form modules.
  const legacyInitialValues = {
    savedCardGuid: getDefaultCard(creditCards),
    encryptedCard: '',
    password: '',
    paymentType: getInitialPaymentType(
      paymentOptions,
      creditCards,
      creditCardsStatus
    ),
    saveCard: false,
    shouldSavePasswordlessAccount
  }

  return formModules.reduce(
    (acc, formModule) => ({
      ...acc,
      ...(formModule.getInitialValues
        ? formModule.getInitialValues({
            cart,
            user,
            featureFlags,
            fulfillmentContext,
            giftCardContext,
            savedAddresses,
            curbsidePickupConfig
          })
        : null),
      gcNumber: ''
    }),
    legacyInitialValues
  )
}
