import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'

export * from '../../../../client/utils/error-logger'

const UNKNOWN_MESSAGE = 'An unknown error occurred'

/**
 * Gets a message for display from the provided error object.
 *
 * @param {*} err Can be any object or `Error` with a `message` property.
 * `ApolloError`s will be handled specially to check for graphql or network errors.
 * @returns {string}
 */
export const getMessage = (err) => {
  if (!err) {
    return UNKNOWN_MESSAGE
  }

  if (err.graphQLErrors && err.graphQLErrors.length) {
    return err.graphQLErrors[0].message || UNKNOWN_MESSAGE
  }

  if (err.networkError && err.networkError.message) {
    return err.networkError.message || UNKNOWN_MESSAGE
  }

  // TODO Do we want to return `err` directly if it's a string?
  return err.message || UNKNOWN_MESSAGE
}

/**
 *
 * Gets the retry message from an error
 * This is nonstandard, and only to be used to display retries in the
 * Error component
 */
export const getRetryMessage = (err) => {
  if (!err) {
    return null
  }
  return err && err.retryMessage
}

/**
 * Gets the error as an Error
 * @param {*} error
 * @returns Error
 */
export const getError = (error) => {
  if (!error) return new Error(UNKNOWN_MESSAGE)
  else if (isObject(error) && error instanceof Error) {
    return error
  } else if (isPlainObject(error)) {
    const {
      code,
      message,
      networkError,
      graphQLErrors,
      moreInfo,
      retryMessage
    } = error

    const err = new Error(message)
    err.code = code
    err.networkError = networkError
    err.graphQLErrors = graphQLErrors
    err.moreInfo = moreInfo
    err.retryMessage = retryMessage
    return err
  } else if (isString(error)) {
    return new Error(error)
  }
  return new Error(UNKNOWN_MESSAGE)
}
