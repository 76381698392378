import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useSelect } from 'downshift'

import DropDown from '../../assets/triangle-arrow.svg'

import cx from 'classnames'

import styles from './Select.module.css'

export const Select = ({
  items,
  initialSelectedItem,
  onSelectedItemChange,
  startAdornment,
  getSelectedItemContent,
  getOptionContent,
  getOptionKey = (item, index) => index
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items,
    initialSelectedItem: initialSelectedItem || items[0],
    onSelectedItemChange
  })

  const btn = useRef()
  const width = btn && btn.current ? `${btn.current.offsetWidth}px` : 'auto'

  return (
    <div className={styles.select}>
      <button
        {...getToggleButtonProps({
          ref: btn,
          type: 'button',
          className: styles.button
        })}
      >
        <div className={styles.buttonContent}>
          {startAdornment}
          {getSelectedItemContent(selectedItem)}
        </div>
        <DropDown
          className={cx(styles.menuArrow, { [styles.isOpen]: isOpen })}
        />
      </button>
      <ul
        {...getMenuProps({
          style: { width },
          className: cx(styles.menu, {
            [styles.hidden]: !isOpen
          })
        })}
      >
        {items.map((item, index) => {
          return (
            <li
              {...getItemProps({
                item,
                index,
                key: getOptionKey(item, index),
                className: cx(styles.option, {
                  [styles.selected]: highlightedIndex === index
                })
              })}
            >
              {getOptionContent(item)}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Select.propTypes = {
  items: PropTypes.array.isRequired,
  initialSelectedItem: PropTypes.any,
  onSelectedItemChange: PropTypes.func,
  startAdornment: PropTypes.node,
  getSelectedItemContent: PropTypes.func.isRequired,
  getOptionContent: PropTypes.func.isRequired,
  getOptionKey: PropTypes.func
}
