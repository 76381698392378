import { namespacedStorage } from '../../../../client/utils/namespaced-storage.js'
import { logError } from '@local/do-secundo-error'

export const passwordlessStorage = namespacedStorage('passwordless-auth')

export const passwordlessStorageSet = (key: string, value: any) => {
  try {
    passwordlessStorage.set(key, value)
  } catch (error) {
    logError(error)
  }
}

export const passwordlessStorageGet = (key: string) => {
  try {
    const value = passwordlessStorage.get(key)
    return value
  } catch (error) {
    logError(error)
  }
}

export const passwordlessStorageRemove = (key: string) => {
  try {
    passwordlessStorage.remove(key)
  } catch (error) {
    logError(error)
  }
}
