import React from 'react'
import { Form, Field, Formik } from 'formik'
import { Radio } from '@local/do-secundo-form'
import { DiningOptionHours } from './DiningOptionHours/DiningOptionHours'
import { DINING_OPTION_STRINGS } from '@local/do-secundo-use-dining-options'
import { useRestaurantSchedule } from '../../hooks/use-restaurant-schedule'

import styles from './RestaurantHours.module.css'

export const RestaurantHours = () => {
  const { scheduleMap, loading, error } = useRestaurantSchedule()
  if (loading || error || !scheduleMap) return null
  const diningOptions = Object.keys(scheduleMap)
  if (diningOptions.length === 0) return null

  return (
    <Formik
      initialValues={{
        behavior: diningOptions[0]
      }}
    >
      {({ values }) => (
        <Form>
          <div className={styles.tabs}>
            {diningOptions.map((behavior) => (
              <Field
                key={behavior}
                style='tab'
                component={Radio}
                name='behavior'
                id={behavior}
                data-testid={`${behavior}-tab`}
                label={`${DINING_OPTION_STRINGS[behavior] || 'Other'} hours`}
                value={behavior}
              />
            ))}
          </div>
          <DiningOptionHours
            schedule={scheduleMap[values.behavior].dailySchedules}
          />
        </Form>
      )}
    </Formik>
  )
}
