import React from 'react'
import cx from 'classnames'

import { PopularItem } from './PopularItem/PopularItem'
import { Progress } from '@local/do-secundo-progress'
import { usePopularItems } from '../../hooks/use-popular-items'

import { dataByTypename } from '../../utils/apollo-helpers'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import styles from './PopularItems.module.css'

export const PopularItems = () => {
  const { restaurantInfo } = useRestaurant()

  let enabled = false

  if (
    restaurantInfo.data &&
    restaurantInfo.data.restaurant &&
    restaurantInfo.data.restaurant.popularItemsConfig
  ) {
    enabled = Boolean(restaurantInfo.data.restaurant.popularItemsConfig.enabled)
  }

  const { loading, error, data } = usePopularItems()

  if (!enabled || error) return null

  if (loading) return <Progress />

  const { PopularItemsResponse } = dataByTypename(data.popularItems)
  if (!PopularItemsResponse) return null
  const { items } = PopularItemsResponse
  if (items.length === 0) return null

  return (
    <div
      className={styles.popularItems}
      role='group'
      aria-label='popular items'
    >
      <div className={styles.wrap}>
        <h2 className={cx(styles.header, 'type-large')}>Popular Items</h2>
        <ul className={styles.items}>
          {items.map((item) => (
            <PopularItem item={item} key={item.guid} />
          ))}
        </ul>
      </div>
    </div>
  )
}
