import { ModalHeaderWithBackButton } from '@local/do-secundo-auth-modals'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { Input } from '@local/do-secundo-form'
import { Notification } from '@local/do-secundo-notification'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import {
  useAuth,
  useEnterYourPasswordLogin
} from '../../../../client/components/AuthProvider/AuthProvider'
import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
import { Password } from '../../../../client/components/Form/Password/Password'
import {
  ModeLink,
  ModeRedirect
} from '../../../../client/components/ModeRouter/utils'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import {
  emailSchema,
  passwordSchema
} from '../../../../client/utils/form-schemas'
import { FROM_LOCATION } from '../utils/enums'

export interface EnterYourPasswordModalProps extends RouteComponentProps {
  onClose: () => void
}

export const EnterYourPasswordModal = withRouter(
  ({
    onClose: closeModal,
    location: { state = { email: '', from: '' }, pathname },
    history
  }: EnterYourPasswordModalProps) => {
    const testIdPrefix = 'enter-your-password-modal-'

    const { setMfaState, mfaRequiresVerification } = useAuth()

    const {
      login,
      result: { error: loginError, loading }
    } = useEnterYourPasswordLogin()

    React.useEffect(() => {
      if (state?.from !== FROM_LOCATION.emailLogin) {
        history.push('?mode=login')
      }
    }, [])

    if (mfaRequiresVerification) {
      return (
        <ModeRedirect
          pathname={pathname}
          mode='mfa'
          state={{
            from: FROM_LOCATION.enterYourPassword
          }}
        />
      )
    }

    return (
      <PoweredByToastModal
        className='pt-1'
        onClose={closeModal}
        header={
          <ModalHeaderWithBackButton
            onClick={() => history.goBack()}
            headerText={'Enter your password'}
            testId={`${testIdPrefix}header-with-back-button`}
            className='items-center'
          />
        }
      >
        <Formik
          initialValues={{ email: state.email, password: '' }}
          validationSchema={Yup.object().shape({
            email: emailSchema,
            password: passwordSchema
          })}
          onSubmit={async (values) => {
            const input = {
              variables: {
                input: {
                  ...values,
                  forAccountLinking: false
                }
              }
            }
            const result = await login(input)

            const { MfaChallengeGeneratedResponse, LoginError } =
              dataByTypename(result.data.login)

            if (MfaChallengeGeneratedResponse) {
              setMfaState({
                mfaRequiresVerification: true,
                email: values.email,
                ...result.data.login
              })
            } else if (!LoginError) {
              history.push('?mode=loginEasier', {
                from: FROM_LOCATION.enterYourPassword,
                email: values.email
              })
            }
          }}
        >
          {({ isValid, error }) => (
            <Form>
              {loginError && (
                <Notification severity='error'>
                  {loginError.code === 'EMAIL_LOCKED' ? (
                    <>
                      You have reached the maximum number of failed login
                      attempts. Please&nbsp;
                      <ModeLink
                        mode='forgot'
                        data-testid='connect-account-modal-reset-password-link'
                        className='font-bold hover:underline text-error'
                        state={{
                          from: FROM_LOCATION.enterYourPassword,
                          email: state.email
                        }}
                      >
                        reset your password
                      </ModeLink>
                      &nbsp;and try again.
                    </>
                  ) : (
                    'Invalid username or password combination.'
                  )}
                </Notification>
              )}
              {error && (
                <Notification severity='error'>{error.message}</Notification>
              )}
              <Field
                data-testid={`${testIdPrefix}password`}
                id={`${testIdPrefix}-password`}
                name='password'
                label='Password'
                component={Password}
              />
              <Button
                data-testid={`${testIdPrefix}button-submit`}
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.PRIMARY}
                responsive
                loading={loading}
                disabled={!isValid}
              >
                Next
              </Button>
              <ButtonSpacer vertical />
              <ModeLink
                data-testid='button-forgot-password'
                style='secondaryWide'
                mode='forgot'
                state={{
                  from: FROM_LOCATION.enterYourPassword,
                  email: state.email
                }}
              >
                Forgot Password
              </ModeLink>
            </Form>
          )}
        </Formik>
      </PoweredByToastModal>
    )
  }
)
