import * as React from 'react'
import PropTypes from 'prop-types'
import styles from './MealSelectorSelections.module.css'

/**
 * Displays the generated list of randomized menu items
 * @param {array} randomMenu
 */
const MealSelectorSelections = ({ randomMenu }) => {
  return (
    randomMenu.length > 0 && (
      <div className={styles.generatedMenu}>
        <h3>Your generated selections</h3>
        <div className={styles.generatedMenuItems}>
          {randomMenu.map((menuItem) => (
            <span key={`${menuItem.guid}${menuItem.itemGroupGuid}`}>
              {menuItem.name}
            </span>
          ))}
        </div>
      </div>
    )
  )
}

MealSelectorSelections.propTypes = {
  randomMenu: PropTypes.array
}

export { MealSelectorSelections }
