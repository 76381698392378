import cx from 'classnames'

/**
 * Using Buffet components in this project may not look exactly as expected
 * due to conflicts between projects.
 *
 * You can use these for the additional classes required to restore the proper look,
 * with the reason why the are needed.
 */
export const BUFFET_RESTORE_CLASSNAMES = {
  button: {
    border: 'border-solid', //needed for button borders to look normal, as this project currently does not include tailwind.preflight.css
    primaryBorderColor:
      'border-buffet-primary-75 hover:border-buffet-primary-100', //primary color is turqoise, we need to restore buffet primary
    primaryTextColor: 'text-buffet-primary-75 hover:text-buffet-primary-100' //primary color is turqoise, we need to restore buffet primary
  }
}

export const BUFFET_RESTORE_BUTTON_CLASSNAMES = cx(
  Object.values(BUFFET_RESTORE_CLASSNAMES.button)
)
