import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client'
import {
  CANCEL_PASSWORDLESS_LOGIN,
  CANCEL_PASSWORDLESS_LOGIN_AND_KEEP_LEGACY,
  COMPLETE_PASSWORDLESS_LOGIN,
  CONFIRM_PASSWORDLESS_LOGIN,
  isLegacyAccountLoggedIn,
  isPasswordlessLoggedIn,
  PASSWORDLESS_LOGIN,
  PASSWORDLESS_LOGOUT,
  SEARCH_FOR_CUSTOMERMGMT_ACCOUNT,
  START_PASSWORDLESS_LOGIN,
  useIsPasswordlessAuthEnabled,
  useAccountDataStatus
} from '@local/do-secundo-passwordless-authentication'
import { ACCOUNT_DATA_STATE } from '@local/do-secundo-passwordless-authentication/src/utils/enums'
import {
  addUserProperties,
  analyticsIdentify,
  analyticsResetIdentity
} from '@toasttab/do-secundo-analytics'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { dataByTypename } from '../../utils/apollo-helpers'
import { ResumeSessionError } from '../../apollo/authentication/authentication-errors'
import {
  AUTHENTICATE,
  CUSTOMER,
  LOGIN,
  LOGOUT,
  MFA_LOGIN
} from './Auth.graphql'
import { apolloSkipLogoutWorkaround } from '../../utils/workarounds'
import { ToastNotificationContainer } from '../ToastNotificationContainer/ToastNotificationContainer'

const UNVERIFIED_CUSTOMER_SOURCES = Object.freeze([
  'MANUAL_CREATE_ACCOUNT',
  'SAVE_CREDIT_CARD',
  'LOYALTY_SIGNUP',
  'CREATE_ACCOUNT_DURING_CHECKOUT',
  'MANUAL_CREATE_PASSWORDLESS_ACCOUNT'
])

const initialUnverifiedCustomer = Object.freeze({
  guid: null,
  signupSource: null
})

const defaultAccountDataStatus = Object.freeze({
  importTriggered: false,
  setImportTriggered: () => {},
  creditCards: ACCOUNT_DATA_STATE.notAuthenticated
})

export const AUTH_NOTIFICATION_CONTAINER_ID = 'auth'

export const AuthContext = React.createContext({
  authenticated: false,
  error: null,
  loading: true,
  user: null,
  unverifiedCustomer: initialUnverifiedCustomer,
  logout: () => {},
  hasErrorFromCheckout: false,
  authClient: null, // Authentication Library Client from MU used only for Passwordless Authentication
  setIsAuthenticated: () => {}, // call this function to cause an app rerender. Needed after calling authClient methods that change logged in/out status
  setHasErrorFromCheckout: () => {}, // call this function to update if a create account error on checkout exists
  passwordlessLogin: () => {}, // client only mutation to handle passwordless login
  passwordlessLogout: () => {}, // client only mutation to handle passwordless logout
  // passwordless v1.5/v2 aka unified login
  startPasswordlessLogin: () => {}, // client only mutation to start login
  confirmPasswordlessLogin: () => {}, // client only mutation to confirm code
  completePasswordlessLogin: () => {}, // client only mutation to create/complete profile
  cancelPasswordlessLogin: () => {}, // client only mutation to cancel login
  cancelPasswordlessLoginAndKeepLegacy: () => {}, // client only mutation to cancel login
  accountDataStatus: defaultAccountDataStatus
})

export const useLogin = () => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const [login, result] = useMutation(LOGIN, {
    refetchQueries: !PASSWORDLESS_ENABLED ? [{ query: CUSTOMER }] : [],
    awaitRefetchQueries: !PASSWORDLESS_ENABLED
  })
  if (result.data) {
    const { LoginError } = dataByTypename(result.data.login)
    return {
      login,
      result: {
        ...result,
        error: LoginError || result.error
      }
    }
  }
  return {
    login,
    result
  }
}

// This hook is essentially the same thing as useLogin but will always refetch CUSTOMER
// regardless of PASSWORDLESS_ENABLED. This is only being used inside of the EnterYourPasswordModal.

export const useEnterYourPasswordLogin = () => {
  const [login, result] = useMutation(LOGIN, {
    refetchQueries: [{ query: CUSTOMER }],
    awaitRefetchQueries: true
  })
  if (result.data) {
    const { LoginError } = dataByTypename(result.data.login)
    return {
      login,
      result: {
        ...result,
        error: LoginError || result.error
      }
    }
  }
  return {
    login,
    result
  }
}

export const sharedCustomerMutationOptions = {
  refetchQueries: [{ query: CUSTOMER }],
  awaitRefetchQueries: true,
  // Adding this property as an experiment to see if stuck in loading issue will be resolved. From https://github.com/apollographql/react-apollo/issues/170
  notifyOnNetworkStatusChange: true
}

/**
 * Query to fetch customer data and set analytics data
 * @param {boolean} shouldSkipRequest
 */
export const useCustomer = (skip) => {
  const response = useQuery(CUSTOMER, {
    ...apolloSkipLogoutWorkaround,
    notifyOnNetworkStatusChange: true,
    skip
  })
  const trackAnalyticsIdentity = useCallback((data) => {
    if (data && data.customer && data.customer.guid) {
      analyticsIdentify(data.customer.guid)
    } else {
      analyticsResetIdentity()
    }
  }, [])

  useEffect(() => {
    response.networkStatus === NetworkStatus.ready &&
      trackAnalyticsIdentity(response?.data)
  }, [response.data, response.networkStatus, trackAnalyticsIdentity])

  return response
}

export const getStatus = ({
  loginResult,
  passwordlessLoginResult,
  customerResult,
  mfaLoginResult,
  startPasswordlessLoginResult,
  authClient
}) => {
  const user = customerResult?.data?.customer
  if (mfaLoginResult.loading) {
    if (isPasswordlessLoggedIn(authClient)) {
      return { loading: true, mfaLoading: true, user }
    }
    return { loading: true, mfaLoading: true }
  }
  if (
    loginResult.loading ||
    passwordlessLoginResult.loading ||
    customerResult.loading ||
    mfaLoginResult.loading
  ) {
    return { loading: true }
  }

  const error =
    customerResult.error ||
    loginResult.error ||
    passwordlessLoginResult.error ||
    mfaLoginResult.error
  if (error && !ResumeSessionError.isError(error)) {
    return { error }
  }

  if (loginResult.called) {
    const { LoginError } = dataByTypename(loginResult.data.login)
    if (LoginError) {
      return { error: LoginError }
    }
  }

  if (passwordlessLoginResult.called) {
    const { CompleteProfileCreationError } = dataByTypename(
      passwordlessLoginResult.data.createProfileCreation
    )
    if (CompleteProfileCreationError) {
      return { error: CompleteProfileCreationError }
    }
  }

  if (startPasswordlessLoginResult.called) {
    const { StartIdentityProfileError } = dataByTypename(
      startPasswordlessLoginResult?.data?.startPasswordlessLogin
    )
    if (StartIdentityProfileError) {
      return { error: StartIdentityProfileError }
    }
  }

  if (mfaLoginResult.called) {
    const { LoginError } = dataByTypename(mfaLoginResult.data.mfaLogin)
    if (LoginError) {
      if (isPasswordlessLoggedIn(authClient)) {
        return { error: LoginError, user }
      }
      return { error: LoginError }
    }
  }

  return { user }
}

export const AuthProvider = React.memo(({ children, ...props }) => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  const mutationOptions = PASSWORDLESS_ENABLED
    ? null
    : sharedCustomerMutationOptions
  // unverifiedCustomer is used for loyalty signup
  const [unverifiedCustomer, setUnverifiedCustomerValue] = useState(
    initialUnverifiedCustomer
  )

  // error state to alert users when account was not created during checkout but order has gone through
  const [hasErrorFromCheckout, setHasErrorFromCheckout] = useState(false)

  // customermgmt related mutations
  const [logout, logoutResult] = useMutation(LOGOUT, mutationOptions)
  const [login, loginResult] = useMutation(LOGIN, mutationOptions)
  const [mfaLogin, mfaLoginResult] = useMutation(
    MFA_LOGIN,
    sharedCustomerMutationOptions
  )
  const [authenticate] = useMutation(
    AUTHENTICATE,
    sharedCustomerMutationOptions
  )

  // passwordless v1 related mutations
  const [passwordlessLogout] = useMutation(PASSWORDLESS_LOGOUT)
  const [passwordlessLogin, passwordlessLoginResult] = useMutation(
    PASSWORDLESS_LOGIN,
    sharedCustomerMutationOptions
  )

  // passwordless v1.5 related mutations
  const [startPasswordlessLogin, startPasswordlessLoginResult] = useMutation(
    START_PASSWORDLESS_LOGIN
  )
  const [confirmPasswordlessLogin] = useMutation(CONFIRM_PASSWORDLESS_LOGIN)
  const [completePasswordlessLogin] = useMutation(
    COMPLETE_PASSWORDLESS_LOGIN,
    sharedCustomerMutationOptions
  )
  const [cancelPasswordlessLogin] = useMutation(CANCEL_PASSWORDLESS_LOGIN)
  const [cancelPasswordlessLoginAndKeepLegacy] = useMutation(
    CANCEL_PASSWORDLESS_LOGIN_AND_KEEP_LEGACY
  )
  const [searchForCustomermgmtAccount] = useLazyQuery(
    SEARCH_FOR_CUSTOMERMGMT_ACCOUNT,
    {
      //standby ensures this query is only refetched when explicitly called
      //and does not auto-refetch when variables change or resetStore() is called
      fetchPolicy: 'standby'
    }
  )

  const skipCustomerQuery = PASSWORDLESS_ENABLED
    ? !props.isAuthenticated
    : false // password based determines authenticated state based on the result of the customer query, so force to true
  const customerResult = useCustomer(skipCustomerQuery)
  const accountDataStatus = useAccountDataStatus()

  // This is not needed for passwordless authentication and can be deleted when passwordless auth goes GA
  const [mfaState, setMfaState] = useState({
    mfaRequiresVerification: false,
    challengeToken: '',
    mfaType: '',
    maskedRecipient: ''
  })

  const { user, error, loading, mfaLoading } = getStatus({
    loginResult,
    passwordlessLoginResult,
    customerResult,
    mfaLoginResult,
    startPasswordlessLoginResult,
    authClient: props.authClient
  })

  // setUnverifiedCustomerValue is called in CreateAccountModal
  const setUnverifiedCustomer = useCallback((newState) => {
    if (!newState.guid) {
      console.error('setUnverifiedCustomer must include a guid')
    }

    if (!newState.signupSource) {
      console.error('setUnverifiedCustomer must include a signupSource')
    }

    if (!UNVERIFIED_CUSTOMER_SOURCES.includes(newState.signupSource)) {
      console.error(`invalid signupSource '${newState.signupSource}'`)
    }

    setUnverifiedCustomerValue(newState)
  }, [])

  const clearUnverifiedCustomer = useCallback(() => {
    setUnverifiedCustomerValue(initialUnverifiedCustomer)
  }, [])

  useEffect(() => {
    addUserProperties({ passwordless: PASSWORDLESS_ENABLED })
  }, [PASSWORDLESS_ENABLED])

  let context = {
    hasErrorFromCheckout,
    setHasErrorFromCheckout,
    authenticated:
      isLegacyAccountLoggedIn() && !isPasswordlessLoggedIn(props.authClient)
        ? Boolean(user)
        : props.isAuthenticated,
    error,
    loading,
    login,
    mfaLogin,
    logout,
    authenticate,
    clearUnverifiedCustomer,
    setUnverifiedCustomer,
    unverifiedCustomer,
    user,
    setMfaState,
    ...mfaState,
    accountDataStatus,
    startPasswordlessLogin,
    confirmPasswordlessLogin,
    completePasswordlessLogin,
    cancelPasswordlessLogin,
    cancelPasswordlessLoginAndKeepLegacy,
    searchForCustomermgmtAccount,
    authClient: props.authClient,
    setIsAuthenticated: props.setIsAuthenticated
  }

  if (PASSWORDLESS_ENABLED) {
    context = {
      ...context,
      mfaLoading,
      passwordlessLogin,
      passwordlessLogout
    }
  }

  return (
    <AuthContext.Provider value={context}>
      {children}
      {/** add specific auth notification container as it needs to live outside of page routing */}
      <ToastNotificationContainer containerId='auth' />{' '}
    </AuthContext.Provider>
  )
})

AuthProvider.propTypes = {
  children: PropTypes.node
}

export const useAuth = () => React.useContext(AuthContext)

export const importSuccessToast = () => {
  toast('Connection process started')
}

export const importErrorToast = (err) => {
  toast(
    'We were unable to transfer your order history and saved payment to your new account. Please try again later.'
  )
}

export const showAccountCreatedNotification = () => {
  toast('Welcome to your new Toast account!')
}

export const showLoggedInNotification = (name) => {
  const message = name ? `Welcome back, ${name}!` : 'Welcome back!'
  toast(message)
}
