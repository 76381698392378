import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import { SELECTION_ITEM_DETAILS } from '../../../apollo/menu/menuitem.graphql'
import { useEditItemInCart } from '@local/do-secundo-cart-mutation'

import { UpdateItemFormModal } from '../UpdateItemFormModal/UpdateItemFormModal'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { useNestedModifiers } from '../use-nested-modifiers'
import { useMenuApiAwareLazyQuery } from '../../../hooks/use-menu-api-aware-lazy-query'
import { getSelectionDetails } from '@local/do-secundo-modifier-form-helpers'

export const EditItem = ({
  cartGuid,
  itemGuid,
  itemGroupGuid,
  onClose,
  selectionGuid
}) => {
  const { shortUrl, restaurantGuid } = useRestaurant()
  const { fulfillmentTime, loading } = useFulfillment()
  const dateTime = fulfillmentTime || undefined
  const { nestingLevel } = useNestedModifiers()

  const [getSelectionItemDetails, itemDetailsQuery] = useMenuApiAwareLazyQuery(
    SELECTION_ITEM_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      // no cache instead of cache-and-network
      // because of: https://github.com/apollographql/react-apollo/issues/3361
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          restaurantGuid,
          shortUrl,
          itemGuid,
          itemGroupGuid,
          selectionGuid,
          cartGuid,
          dateTime
        },
        nestingLevel
      }
    }
  )
  const [editItemInCart, { error }] = useEditItemInCart({
    onCompleted: onClose
  })

  useEffect(() => {
    if (!loading) getSelectionItemDetails()
  }, [getSelectionItemDetails, loading])

  const getBasePrice = (data = {}) =>
    (data?.selectionItemDetails && data?.selectionItemDetails.menuItemPrice) ||
    0

  return (
    <UpdateItemFormModal
      updateCart={editItemInCart}
      error={error}
      itemDetailsQuery={itemDetailsQuery}
      getItemDetails={getSelectionDetails}
      getBasePrice={getBasePrice}
      onClose={onClose}
      itemGuid={itemGuid}
      itemGroupGuid={itemGroupGuid}
      selectionGuid={selectionGuid}
      mayDelete
    />
  )
}

EditItem.propTypes = {
  itemGuid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  selectionGuid: PropTypes.string.isRequired
}
