import * as React from 'react'

import { Route } from 'react-router-dom'
import { useCornucopiaState } from '../CornucopiaProvider/CornucopiaProvider'

export const IfInCornucopiaRoute = ({ ...props }) => {
  const { isCornucopiaShellRunning } = useCornucopiaState()
  if (!isCornucopiaShellRunning) return null

  return <Route {...props} />
}

export const IfNotInCornucopiaRoute = ({ ...props }) => {
  const { isCornucopiaShellRunning } = useCornucopiaState()
  if (isCornucopiaShellRunning) return null

  return <Route {...props} />
}
