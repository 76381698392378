import React from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import {
  DINING_OPTION_BEHAVIORS,
  useDiningOptions
} from '@local/do-secundo-use-dining-options'
import { AddressAutocomplete } from '../../AddressAutocomplete/AddressAutocomplete'
import { SavedAddressSelector } from '../../SavedAddressSelector/SavedAddressSelector'

import styles from './DiningOptionSelector.module.css'
import { DiningOptionError } from './DiningOptionError/DiningOptionError'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FULFILLMENT_TYPES } from '../../FulfillmentProvider/initial-fulfillment-state'
import { DiningOption } from './DiningOption/DiningOption'

const { TAKE_OUT, DELIVERY } = DINING_OPTION_BEHAVIORS
const { ASAP, FUTURE } = FULFILLMENT_TYPES

export const DiningOptionSelector = ({
  values: {
    diningOptionBehavior,
    deliveryInfo,
    savedAddressGuid,
    savedAddresses
  }
}) => {
  const { data, loading, error } = useDiningOptions()
  const { availability } = useAvailability()

  if (loading || error) return null
  const diningOptions = data.diningOptions.reduce((map, diningOption) => {
    if (
      !(
        availability[ASAP][diningOption.behavior] ||
        availability[FUTURE][diningOption.behavior]
      )
    )
      return map
    return {
      ...map,
      [diningOption.behavior]: diningOption
    }
  }, {})

  const showDiningOptions = Boolean(
    diningOptions[TAKE_OUT] && diningOptions[DELIVERY]
  )

  const deliveryOnly = Boolean(
    !diningOptions[TAKE_OUT] && diningOptions[DELIVERY]
  )

  return (
    <>
      {showDiningOptions && (
        <div className={styles.flex}>
          <DiningOption
            value={TAKE_OUT}
            label='Pickup'
            id='dining_option_take_out'
          />
          <DiningOption
            value={DELIVERY}
            label='Delivery'
            id='dining_option_delivery'
            deliveryProvider={
              (diningOptions.DELIVERY.deliveryProvider || {}).provider
            }
          />
        </div>
      )}
      {diningOptionBehavior === DELIVERY && (
        <>
          <h2 className={styles.header}>Where should we send your order?</h2>
          {!isEmpty(savedAddresses) && (
            <Field
              id='delivery_info'
              name='deliveryInfo'
              component={SavedAddressSelector}
            />
          )}
          {!savedAddressGuid && (
            <Field
              id='delivery_info'
              name='deliveryInfo'
              showDeliveryPrompt={deliveryOnly}
              component={AddressAutocomplete}
            />
          )}
          <DiningOptionError
            deliveryInfo={deliveryInfo}
            diningOptionBehavior={diningOptionBehavior}
          />
        </>
      )}
    </>
  )
}

DiningOptionSelector.propTypes = {
  values: PropTypes.shape({
    diningOptionBehavior: PropTypes.string
  }).isRequired
}
