import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { getQueryArguments } from '../utils/doMenuInput-helpers'

import { SET_ACTIVE_MENU } from './MenuSelector.graphql'
import { MENUS } from '../apollo/menu/menus.graphql'

export const useMenuSelection = (opts = {}) => {
  const { shortUrl, restaurantGuid } = useRestaurant()
  const { fulfillmentTime } = useFulfillment()

  const dateTime = fulfillmentTime || undefined

  const variables = {
    input: getQueryArguments({ shortUrl, dateTime, restaurantGuid })
  }
  const [setActiveMenu] = useMutation(SET_ACTIVE_MENU, {
    update: (
      store,
      {
        data: {
          setActiveMenu: { id }
        }
      }
    ) => {
      // Need to force update the cache for MENUS query
      // with the current selected menu
      const data = store.readQuery({ query: MENUS, variables })
      const menus = data.menusV3.menus.map((menu) => ({
        ...menu,
        isActive: menu.id === id
      }))
      store.writeQuery({
        query: MENUS,
        data: { menusV3: { menus: [...menus], __typename: 'MenusResponse' } },
        variables
      })
    },
    ...opts
  })

  return useCallback(
    (id) => {
      setActiveMenu({ variables: { id } })
    },
    [setActiveMenu]
  )
}
