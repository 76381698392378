import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const useCurbsidePickup = () => {
  const { restaurantInfo } = useRestaurant()
  const response = restaurantInfo
  if (response.data) {
    return {
      ...response,
      curbsidePickupConfig: response.data.restaurant.curbsidePickupConfig
    }
  }
  return response
}
