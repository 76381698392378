const sortByPropertyPresence = (items, property) => {
  return items.slice().sort((a, b) => {
    if (a[property] && b[property]) return 0
    if (b[property]) return 1
    if (!a[property] && !b[property]) return 0
    return -1
  })
}

const sortByImagePresence = (items) => {
  return sortByPropertyPresence(items, 'imageUrl')
}

const sortByDescriptionPresence = (items) => {
  return sortByPropertyPresence(items, 'description')
}

const sortByStock = (items) => {
  return items.slice().sort((a, b) => {
    if (a.outOfStock && b.outOfStock) return 0
    if (a.outOfStock) return 1
    if (!a.outOfStock && !b.outOfStock) return 0
    return -1
  })
}

// used to match experiment variant to the sorting method
// see XP_MENU_ITEMS_SORT definition for details
const variantToSortMap = {
  1: sortByImagePresence,
  2: sortByDescriptionPresence
}

export {
  sortByDescriptionPresence,
  sortByImagePresence,
  variantToSortMap,
  sortByStock
}
