import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@local/do-secundo-modal'
import { logError } from '@local/do-secundo-error'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { Search } from '../Search'
import { OPEN_MENU_LIST } from '@local/do-secundo-menu-overview'

export const SearchModal = ({ onClose }) => {
  const BIRDS_EYE_VIEW_ENABLED = useFlag(FF.BIRDS_EYE_VIEW)

  const onSearchModalClose = () => {
    try {
      window.sessionStorage.removeItem(OPEN_MENU_LIST)
    } catch (error) {
      logError(error)
    }
  }

  return (
    <Modal
      header={BIRDS_EYE_VIEW_ENABLED ? 'Explore the menu' : 'Search the menu'}
      onClose={() => {
        if (BIRDS_EYE_VIEW_ENABLED) {
          onSearchModalClose()
        }
        onClose()
      }}
    >
      <Search />
    </Modal>
  )
}

SearchModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
