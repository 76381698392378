import * as React from 'react'
import { HashLink } from 'react-router-hash-link'
import { IdNameProps } from '.'

import { MenuBadge, useGetMenuGroupBadge } from '@local/do-secundo-menu-badges'

/**
 * Helper component. The 'to' prop redirects to that menu group's section of the page.
 */
export const OverviewLink = ({ item }: { item: IdNameProps }) => {
  const groupBadge = useGetMenuGroupBadge(item.guid || item.id)

  return (
    <HashLink
      to={{ hash: `#${item.id}` }}
      className='text-lg tracking-normal text-oo-black-87 block py-3'
    >
      <div className='flex flex-row items-center'>
        <span>{item.name}</span>
        {groupBadge && (
          <MenuBadge
            className='ml-4 flex-initial flex-shrink-0'
            testId='menu-overview-group-badge'
          >
            {groupBadge.badgeText}
          </MenuBadge>
        )}
      </div>
    </HashLink>
  )
}
