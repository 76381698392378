import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import { ReorderCard } from '../../Reorder/ReorderCard/ReorderCard'

import styles from '../MyOrdersPage.module.css'

const createDisplayList = (orders) => {
  let currentMonthYear
  if (!orders) {
    return []
  }

  return orders.reduce((acc, order) => {
    const monthYear = getMonthYear(order)
    if (monthYear !== currentMonthYear) {
      acc = [
        ...acc,
        {
          order,
          title: monthYear,
          __typename: 'MonthHeader'
        }
      ]
      currentMonthYear = monthYear
    }
    return [...acc, order]
  }, [])
}

const getMonthYear = (order) => {
  return DateTime.fromMillis(order.estimatedFulfillmentDate).toLocaleString({
    month: 'long',
    year: 'numeric'
  })
}

export const OrdersList = ({ orders }) => {
  const displayList = createDisplayList(orders)

  return displayList.map((element) => {
    if (element.__typename === 'MonthHeader') {
      return (
        <div
          className={styles.monthHeader}
          key={`timebreak-${element.order.guid}`}
        >
          <h4 className={styles.monthHeaderTitle}>{element.title}</h4>
        </div>
      )
    }
    if (element.__typename === 'CompletedOrder') {
      return (
        <ReorderCard
          key={element.guid}
          orderGuid={element.guid}
          items={element.selections}
          price={element.totalV2}
          date={element.estimatedFulfillmentDate}
        />
      )
    }
    return null
  })
}

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      guid: PropTypes.string.isRequired,
      selections: PropTypes.arrayOf(PropTypes.object).isRequired,
      totalV2: PropTypes.number.isRequired,
      estimatedFulfillmentDate: PropTypes.number.isRequired
    })
  )
}
