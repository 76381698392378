import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { FulfillmentDisplay } from '../FulfillmentDisplay/FulfillmentDisplay'
import { useDiningOptions } from '@local/do-secundo-use-dining-options'

import styles from './FulfillmentSelector.module.css'
import {
  useHasMode,
  useModeHistoryPushCallback
} from '../ModeRouter/ModeRouter'
import { useFulfillmentAvailability } from '../../hooks/use-fulfillment-availability'

// Fulfillment display bar (which will open modal upon click as well as prior to having fulfillment selected e.g. on first menu load)
export const FulfillmentSelector = () => {
  const fulfillmentAvailable = useFulfillmentAvailability()
  const [state, setState] = useState({
    isModalShowing: false,
    enabled: false
  })
  const { loading: availabilityLoading, futureDatesAvailable } =
    useAvailability()
  const { selected, loading: fulfillmentLoading, valid } = useFulfillment()
  const { loading: diningOptionsLoading } = useDiningOptions({
    networkOnly: false
  })
  const loading =
    diningOptionsLoading || availabilityLoading || fulfillmentLoading
  const isResetMode = useHasMode('reset')
  const isLoginMode = useHasMode('login')
  const isFulfillment = useHasMode('fulfillment')
  const redirectToFulfillment = useModeHistoryPushCallback({
    mode: 'fulfillment'
  })

  useEffect(() => {
    if (!loading) {
      const isModalShowing =
        (!selected || !valid) &&
        !isResetMode &&
        !isLoginMode &&
        fulfillmentAvailable

      setState({
        isModalShowing,
        enabled: fulfillmentAvailable
      })
    }
  }, [
    valid,
    selected,
    loading,
    futureDatesAvailable,
    isResetMode,
    isLoginMode,
    fulfillmentAvailable
  ])

  useEffect(() => {
    if (state.isModalShowing && !isFulfillment) {
      redirectToFulfillment()
    }
  }, [isFulfillment, loading, redirectToFulfillment, state.isModalShowing])

  return (
    <>
      <button
        className={cx(styles.fulfillmentSelector, {
          [styles.pointer]: state.enabled
        })}
        id='editFulfillment'
        data-testid='edit-fulfillment'
        disabled={!state.enabled}
        onClick={redirectToFulfillment}
        type='button'
      >
        <FulfillmentDisplay editable={state.enabled} centered inline />
      </button>
    </>
  )
}

const scheduleShape = {
  asapAvailableForTakeout: PropTypes.bool,
  todaysHoursForTakeout: PropTypes.object,
  upcomingSchedules: PropTypes.array
}

FulfillmentSelector.propTypes = {
  schedule: PropTypes.shape(scheduleShape),
  timeZoneId: PropTypes.string
}
