import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'

import { AddressSuggestions } from './AddressSuggestions/AddressSuggestions'
import style from './AddressAutocomplete.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { getFormattedAddress } from '../../utils/address-helpers'
import { Notification } from '@local/do-secundo-notification'

const { stateChangeTypes } = Downshift

export const AddressAutocomplete = ({
  field,
  form,
  debounce = 500,
  showDeliveryPrompt = false
}) => {
  const { restaurantInfo } = useRestaurant()
  const { data, loading, error } = restaurantInfo

  const setValue = useCallback(
    (value = {}) => {
      form.setFieldValue(field.name, value)
    },
    [field.name, form]
  )

  const initial = getFormattedAddress(field.value)

  if (loading || error) return null
  const { latitude, longitude } = data.restaurant.location

  return (
    <Downshift
      onChange={setValue}
      itemToString={(item) => getFormattedAddress(item)}
      initialInputValue={initial}
      stateReducer={(state, changes) => {
        switch (changes.type) {
          // Do not clear search input content on blur
          case stateChangeTypes.blurInput:
            return state
          // Close dropdown on escape
          case stateChangeTypes.keyDownEscape:
            return { ...state, isOpen: false }
          default:
            return { ...state, ...changes }
        }
      }}
    >
      {(downshiftProps) => {
        const { getInputProps, isOpen, getMenuProps, inputValue } =
          downshiftProps
        return (
          <div className={style.container}>
            <input
              {...getInputProps({
                className: style.input,
                // add a zero-width space to trick browser autocomplete
                placeholder: `Enter your delivery ad${String.fromCharCode(
                  '8203'
                )}dress`,
                onBlur: () => form.setFieldTouched(field.name, true)
              })}
            />
            {showDeliveryPrompt && !isOpen && inputValue.length === 0 && (
              <Notification severity='warning' padTop>
                Don't forget to enter a delivery address
              </Notification>
            )}
            {isOpen && inputValue.length > 0 && (
              <ul className={style.dropDown} {...getMenuProps()}>
                <AddressSuggestions
                  {...downshiftProps}
                  latitude={latitude}
                  longitude={longitude}
                  debounce={debounce}
                />
              </ul>
            )}
          </div>
        )
      }}
    </Downshift>
  )
}

const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

AddressAutocomplete.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired,
  debounce: PropTypes.number,
  showDeliveryPrompt: PropTypes.bool
}
