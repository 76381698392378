import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ModeLink } from '../../ModeRouter/utils'
import { useAuth } from '../../AuthProvider/AuthProvider'

import styles from './NavigationDrawerHeader.module.css'

export const NavigationDrawerHeader = ({ onClose }) => {
  const { user, authenticated } = useAuth()

  return authenticated ? (
    <h2
      data-testid='drawer-header-user-name'
      className={cx(styles.header, 'type-headline-4')}
    >
      Hi{user?.firstName ? ` ${user.firstName}` : ''}!
    </h2>
  ) : (
    <>
      <h2
        data-testid='drawer-header'
        className={cx(styles.header, 'type-headline-4')}
      >
        Welcome!
      </h2>
      <p
        data-testid='drawer-text-after-header'
        className={cx(styles.text, 'type-default')}
      >
        {'Get your food faster. Save order info and payment details across all of\n' +
          'your favorite restaurants with a Toast account.'}
      </p>
      <div className={styles.flex}>
        <ModeLink
          data-testid='drawer-button-create-account'
          style='primaryWide'
          onClick={onClose}
          mode={'create'}
          rel='nofollow'
        >
          Create account
        </ModeLink>
        <div className={styles.spacer} />
        <ModeLink
          data-testid='drawer-button-login'
          style='secondaryWide'
          onClick={onClose}
          mode='login'
          rel='nofollow'
        >
          Log in
        </ModeLink>
      </div>
    </>
  )
}

NavigationDrawerHeader.propTypes = {
  onClose: PropTypes.func
}
