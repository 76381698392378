import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { LazyLoad } from '../../../LazyLoad/LazyLoad'
import { useEditMode } from '@local/do-secundo-edit-mode-provider'
import styles from './MenuItemImage.module.css'

export const MenuItemImage = ({ imageUrl, outOfStock }) => {
  const { isEditing } = useEditMode()

  if (!imageUrl && isEditing)
    return (
      <div
        data-testid='menu-item-image'
        className={cx(styles.noImage, 'border-solid border-2 border-red-100', {
          [styles.outOfStock]: outOfStock
        })}
      >
        No Image
      </div>
    )

  if (!imageUrl) return null

  return (
    <LazyLoad rootMargin={'0px 0px 100% 0px'}>
      {(loaded) => (
        <div
          data-testid='menu-item-image'
          style={{ backgroundImage: loaded && `url(${imageUrl})` }}
          className={cx(styles.image, { [styles.outOfStock]: outOfStock })}
        />
      )}
    </LazyLoad>
  )
}

MenuItemImage.propTypes = {
  imageUrl: PropTypes.string,
  outOfStock: PropTypes.bool
}
