import React from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import cx from 'classnames'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { ModeLink } from '../../../ModeRouter/utils'
import { LOYALTY_GET_COMPLETED_ORDER } from '../../LoyaltyConfirmation.graphql'
import {
  LOYALTY,
  LearnMoreButton,
  useTermsModal
} from '@local/do-secundo-terms-modal'

import Flare from '../../../../assets/flare.svg'
import styles from '../../LoyaltyConfirmation.module.css'

const getLoyaltyMessaging = ({ hasLoyaltyAttached, loyaltySignupBonus }) => {
  if (hasLoyaltyAttached) {
    return {
      title: 'Your points are waiting...',
      description:
        "You're already earning points! Create an account and put them towards exclusive rewards that can save you money.",
      button: 'Create Toast Account and Join Rewards'
    }
  }

  return {
    title: "Don't let your points go to waste!",
    description: loyaltySignupBonus
      ? `Earn points for this order and another ${loyaltySignupBonus} just for joining this restaurant's reward program.`
      : `Earn points for this order by joining this restaurant's reward program.`,
    button: 'Create Toast Account and Join Rewards'
  }
}

export const LoyaltyAccountCreation = ({ orderGuid, setIntentToEnroll }) => {
  const { restaurantGuid } = useRestaurant()
  const transactionGuid = uuid()
  const { data, loading, error } = useQuery(LOYALTY_GET_COMPLETED_ORDER, {
    variables: {
      input: {
        orderGuid,
        restaurantGuid
      }
    }
  })

  const { TermsModal, openModal } = useTermsModal({ terms: LOYALTY })

  if (loading || error) return null
  const {
    customerV2: customer,
    hasLoyaltyAttached,
    restaurant: {
      loyaltyConfig: { loyaltySignupBonus }
    },
    checkGuid: checkIdentifier
  } = data.completedOrder

  let loyaltyAccountCreationInput
  if (!hasLoyaltyAttached) {
    loyaltyAccountCreationInput = {
      restaurantGuid,
      transactionGuid,
      checkIdentifier
    }
  }

  const basicInfo = {
    firstName: customer.firstName,
    lastName: customer.lastName,
    phone: customer.phone
  }

  const { title, description, button } = getLoyaltyMessaging({
    hasLoyaltyAttached,
    loyaltySignupBonus
  })
  return (
    <div className={styles.container}>
      <TermsModal />
      <Flare />
      <h2 className={cx(styles.title, 'type-headline-4')}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <ModeLink
        onClick={setIntentToEnroll}
        data-testid='button-loyalty-signup'
        mode='create'
        state={{
          signupSource: 'LOYALTY_SIGNUP',
          email: customer.email,
          basicInfo,
          loyaltyAccountCreationInput
        }}
        style='primaryWide'
        className={styles.loyaltyButton}
      >
        {button}
      </ModeLink>
      {!hasLoyaltyAttached && (
        <LearnMoreButton
          className={styles.infoButton}
          onClick={openModal}
          data-testid='button-loyalty-more-info'
          linkText={'Loyalty Program Terms and Conditions'}
        />
      )}
    </div>
  )
}

LoyaltyAccountCreation.propTypes = {
  setIntentToEnroll: PropTypes.func.isRequired,
  orderGuid: PropTypes.string.isRequired
}
