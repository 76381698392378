import React from 'react'
import PropTypes from 'prop-types'
import Search from '../../assets/search.svg'

import styles from './SearchBanner.module.css'

export const SearchBanner = ({ children }) => {
  return (
    <div className={styles.container}>
      <Search className={styles.searchIcon} />
      {children}
    </div>
  )
}

SearchBanner.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}
