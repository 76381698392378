import * as React from 'react'
import { ToastContainer } from 'react-toastify'

import style from './ToastNotificationContainer.module.css'
const { toastContainer, toastWrapper } = style

export interface ToastNotificationContainerProps {
  containerId?: string
}

/**
 * The component that will pick up any pop-up notifications (also known as a toast -- with a lowercase 't')
 * used with toast() function from 'react-toastify'.
 * @see ToastNotification
 */
export const ToastNotificationContainer = ({
  containerId
}: ToastNotificationContainerProps) => {
  return (
    <ToastContainer
      hideProgressBar
      closeButton={false}
      className={toastContainer}
      toastClassName={toastWrapper}
      position='top-center'
      containerId={containerId}
      enableMultiContainer={!!containerId}
    />
  )
}
