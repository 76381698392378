import {
  COMPLETE_IDENTITY_PROFILE,
  COMPLETE_PROFILE_CREATION,
  START_IDENTITY_PROFILE,
  OTP_EXPIRED_MESSAGE,
  ACCOUNT_LOCKED_ERROR_MESSAGE,
  DEFAULT_403_ERROR_MESSAGE,
  MAX_NUMBER_OF_ATTEMPTS_ERROR,
  VERIFICATION_CODE_EXPIRED_ERROR,
  isPasswordlessLoggedIn
} from '@local/do-secundo-passwordless-authentication'
import { cache, config, defaultData } from '../../apollo/apollo-client'
import { dataByTypename } from '../../utils/apollo-helpers'
import {
  accountLinkingStorage,
  cleanUpCartData,
  deleteTokens,
  setTokens,
  storage
} from './authentication-helpers'
import { ResumeSessionError } from './authentication-errors'
import {
  CUSTOMER_SERVER,
  LOGIN_SERVER,
  MFA_LOGIN_SERVER
} from './authentication.graphql'
import { ooV2Auth } from './oov2-auth'

export const handleAuthResponse = (response, forAccountLinking = false) => {
  const {
    AuthenticationResponse: data,
    MfaChallengeGeneratedResponse: mfaData,
    LoginError: error
  } = dataByTypename(response)

  if (error) {
    if (forAccountLinking) {
      deleteTokens(accountLinkingStorage)
    } else {
      deleteTokens()
    }
    return error
  }

  if (mfaData) return mfaData

  if (forAccountLinking) {
    setTokens(data, accountLinkingStorage)
  } else {
    setTokens(data)
  }
  return data
}

export const getLoginErrorMessage = async (error) => {
  const { message } = error

  if (message.includes('403')) {
    try {
      const { error_description } = await error.response.json()
      if (
        error_description === MAX_NUMBER_OF_ATTEMPTS_ERROR ||
        error_description === VERIFICATION_CODE_EXPIRED_ERROR
      ) {
        return OTP_EXPIRED_MESSAGE
      } else {
        return DEFAULT_403_ERROR_MESSAGE
      }
    } catch (error) {
      return DEFAULT_403_ERROR_MESSAGE
    }
  }

  if (message.includes('429')) {
    return ACCOUNT_LOCKED_ERROR_MESSAGE
  }

  return message
}

const completeIdentityProfile = async (client, input) => {
  const resp = await client.mutate({
    mutation: COMPLETE_IDENTITY_PROFILE,
    variables: {
      input
    }
  })
  const { CompleteIdentityProfileResponse, CompleteIdentityProfileError } =
    dataByTypename(resp.data.completeIdentityProfile)

  if (CompleteIdentityProfileError) {
    return CompleteIdentityProfileError
  }

  await config.authClient.refreshAuthClient()

  return CompleteIdentityProfileResponse
}

export const authenticationResolvers = {
  Query: {
    customer: async (obj, args, { client }) => {
      let token = storage.get('accessToken')
      const isPasswordlessAuthenticated = isPasswordlessLoggedIn(
        config.authClient
      )
      if (!token && !isPasswordlessAuthenticated) {
        // Fall back to OO V2 if possible
        const ooV2Tokens = await ooV2Auth.getTokens()
        if (ooV2Tokens) {
          setTokens(ooV2Tokens)
          token = storage.get('accessToken')
        }
      }

      if (token || isPasswordlessAuthenticated) {
        try {
          const result = await client.query({
            query: CUSTOMER_SERVER,
            fetchPolicy: 'network-only'
          })
          return result.data.customer
        } catch (err) {
          if (ResumeSessionError.isError(err)) {
            throw err
          }
          throw new Error('Authentication Error')
        }
      }
      return undefined
    }
  },
  Mutation: {
    login: async (
      _,
      { input: { email, password, forAccountLinking = false } },
      { client }
    ) => {
      try {
        const resp = await client.mutate({
          mutation: LOGIN_SERVER,
          variables: {
            input: { email, password }
          }
        })
        return handleAuthResponse(resp.data.login, forAccountLinking)
      } catch ({ message }) {
        deleteTokens()
        return { message, __typename: 'LoginError' }
      }
    },
    mfaLogin: async (
      _,
      { input: { code, challengeToken, email, forAccountLinking = false } },
      { client }
    ) => {
      try {
        const resp = await client.mutate({
          mutation: MFA_LOGIN_SERVER,
          variables: {
            input: { code, challengeToken, email }
          }
        })

        return handleAuthResponse(resp.data.mfaLogin, forAccountLinking)
      } catch ({ message }) {
        deleteTokens()
        return { message, __typename: 'LoginError' }
      }
    },
    logout: async (_obj, { input: { cart } }, { client }) => {
      await cleanUpCartData(client, { cart })
      deleteTokens()
      client.resetStore()
      return {
        message: 'success!',
        __typename: 'LogoutResponse'
      }
    },
    passwordlessLogin: async (_, { input: { code, source } }, { client }) => {
      try {
        await config.authClient.loginWithCode(code)
        const resp = await client.mutate({
          mutation: COMPLETE_PROFILE_CREATION,
          variables: {
            input: { source }
          }
        })

        const {
          CompleteProfileCreationResponse,
          CompleteProfileCreationError
        } = dataByTypename(resp.data.completeProfileCreation)

        if (CompleteProfileCreationError) {
          throw Error(CompleteProfileCreationError.message)
        }

        return CompleteProfileCreationResponse
      } catch (error) {
        const message = await getLoginErrorMessage(error)
        return {
          message,
          __typename: 'CompleteProfileCreationError'
        }
      }
    },
    //v1.5/2.0 login flow = start -> confirm -> complete
    startPasswordlessLogin: async (_, { input }, { client }) => {
      const resp = await client.mutate({
        mutation: START_IDENTITY_PROFILE,
        variables: {
          input
        }
      })

      const { StartIdentityProfileError } = dataByTypename(
        resp.data.startIdentityProfile
      )

      if (StartIdentityProfileError) {
        return StartIdentityProfileError
      }
    },
    confirmPasswordlessLogin: async (
      _,
      { input: { code, source } },
      { client }
    ) => {
      try {
        await config.authClient.loginWithCode(code)
      } catch (error) {
        const message = await getLoginErrorMessage(error)
        return {
          message,
          __typename: 'PasswordlessLoginError'
        }
      }

      if (!isPasswordlessLoggedIn(config.authClient)) {
        return {
          guestGuid: null,
          profileRequirements: null,
          completed: false,
          __typename: 'ConfirmPasswordlessLoginResponse'
        }
      }

      //         valid token is now active
      let resp
      try {
        resp = await client.query({
          query: CUSTOMER_SERVER,
          fetchPolicy: 'network-only'
        })
      } catch (error) {
        return {
          guestGuid: null,
          profileRequirements: null,
          completed: false,
          __typename: 'ConfirmPasswordlessLoginResponse'
        }
      }

      const guest = resp.data.customer
      const guestGuid = guest.guid
      const profileRequirements = {
        email: guest.email,
        firstName: guest.firstName,
        lastName: guest.lastName
      }

      const completed =
        profileRequirements.email &&
        profileRequirements.firstName &&
        profileRequirements.lastName

      if (completed) {
        //profile is complete, but may be unverified
        //send verification request, do not wait for response
        //it's ok if the request fails, verified profiles are the common case,
        //so don't block the user for the uncommon case
        completeIdentityProfile(client, {
          ...profileRequirements,
          source
        }).catch((error) => {
          // handle error and warn so we don't throw
          // an unhandled exception in a promise
          console.warn(error)
        })
      }
      return {
        guestGuid,
        profileRequirements,
        completed: !!completed,
        __typename: 'ConfirmPasswordlessLoginResponse'
      }
    },
    completePasswordlessLogin: async (_, { input }, { client }) => {
      try {
        // complete identity is an authenticated call so ensure session is active
        // so the token is included and gets refreshes if it expired
        const result = await completeIdentityProfile(client, input)

        const {
          CompleteIdentityProfileResponse,
          CompleteIdentityProfileError
        } = dataByTypename(result)

        if (CompleteIdentityProfileError) {
          return CompleteIdentityProfileError
        }

        return CompleteIdentityProfileResponse
      } catch (err) {
        throw err
      }
    },
    cancelPasswordlessLogin: async () => {
      try {
        await config.authClient.logout() //ensure any active token is cleaned up in the auth client and sets active session to false
      } catch (err) {
        throw err
      }
    },
    // Logs a guest out of passwordless and then refetches guests legacy account
    cancelPasswordlessLoginAndKeepLegacy: async (_, __, { client }) => {
      try {
        await config.authClient.logout() //ensure any active token is cleaned up in the auth client and sets active session to false

        await client.query({
          query: CUSTOMER_SERVER,
          fetchPolicy: 'network-only'
        })
        await config.authClient.refreshAuthClient()
      } catch (err) {
        throw err
      }
    },
    passwordlessLogout: async (_obj, { input: { cart } }, { client }) => {
      try {
        deleteTokens()
        await config.authClient.logout()
        await cleanUpCartData(client, { cart })

        /**
         * `client.resetStore()` will clear out user data, but also refetch all active queries.
         * Since the passwordless authentication state changing will rerender the app, this may result in multiple queries being made
         * when not needed. `client.clearStore()` will clear the data and NOT refetch all active queries, but this includes clearing
         * out the default restaurant data. So we need to write the default data back in.
         */
        await client.clearStore()
        cache.writeQuery(defaultData)

        //this is done so when a user logs out, the history state is always cleared.
        // we need this because the SmsConfirmationBenefits shows based on the from property on the confirm page
        if (window.history.state?.state?.from) {
          window.history.replaceState(
            { ...window.history.state, state: { from: 'logout ' } },
            ''
          )
        }

        return {
          message: 'success!',
          __typename: 'LogoutResponse'
        }
      } catch ({ message }) {
        return { message, __typeName: 'PasswordlessLogoutError' }
      }
    },
    authenticate: (
      _,
      {
        input: {
          accessToken,
          refreshToken,
          customerGuid,
          forAccountLinking = false
        }
      }
    ) => {
      if (forAccountLinking) {
        setTokens({ accessToken, refreshToken }, accountLinkingStorage)
      } else {
        setTokens({ accessToken, refreshToken })
      }
      return {
        accessToken,
        refreshToken,
        customerGuid,
        __typename: 'AuthenticationResponse'
      }
    }
  }
}
