import * as React from 'react'

import PropTypes from 'prop-types'
import cx from 'classnames'

import { Mutation } from '@apollo/client/react/components'
import { UPDATE_BASIC_INFO } from '../../../apollo/customer.graphql'

import { Formik, Form } from 'formik'
import { InputGroup } from '../../Form/InputGroup/InputGroup'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { getRawPhoneNumber } from '@local/do-secundo-form-utils'
import { ErrorComponent, logError } from '@local/do-secundo-error'

import { Fieldset } from '@local/do-secundo-fieldset'

import { object, string } from 'yup'

import { toast } from 'react-toastify'

import styles from './CustomerInformationForm.module.css'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

const validationSchema = object().shape({
  customerFirstName: string().trim().required('required'),
  customerLastName: string().trim().required('required'),
  customerEmail: string()
    .trim()
    .email('must be valid email')
    .required('required'),
  customerTel: string()
    .transform(getRawPhoneNumber)
    .trim()
    .matches(/^\d{10}$/, 'must be a 10 digit number')
    .required('required')
})

const customerInputs = [
  {
    autoComplete: 'section-customer given-name',
    id: 'customer_first_name',
    label: 'First Name',
    name: 'customerFirstName',
    type: 'text',
    autoFocus: true
  },
  {
    autoComplete: 'section-customer family-name',
    id: 'customer_last_name',
    label: 'Last Name',
    name: 'customerLastName',
    type: 'text'
  },
  {
    autoComplete: 'section-customer email',
    id: 'customer_email',
    label: 'Email',
    name: 'customerEmail',
    type: 'email',
    disabled: true
  },
  {
    autoComplete: 'section-customer tel-national',
    id: 'customer_tel',
    label: 'Phone',
    name: 'customerTel',
    type: 'tel',
    mask: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ],
    guide: false
  }
]

export const CustomerInformationForm = ({ initialValues }) => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()

  const phoneInput = customerInputs.find((input) => input.id === 'customer_tel')

  phoneInput.disabled = PASSWORDLESS_ENABLED

  return (
    <Mutation mutation={UPDATE_BASIC_INFO}>
      {(updateBasicInfo, { data, error }) => (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const input = {
              firstName: values.customerFirstName,
              lastName: values.customerLastName,
              phone: getRawPhoneNumber(values.customerTel)
            }
            try {
              await updateBasicInfo({ variables: { input } })
              toast('Information Updated Successfully!')
            } catch (err) {
              logError(err)
            } finally {
              setSubmitting(false)
            }
          }}
          enableReinitialize
        >
          {({ isSubmitting, isValid, handleSubmit }) => {
            return (
              <Form>
                <Fieldset label='Personal Information' collapsable>
                  <InputGroup inputConfigs={customerInputs} />
                  <div className={cx(styles.submitWrapper, 'type-default')}>
                    {error && <ErrorComponent error={error} />}
                    <Button
                      data-testid='customer-information-button-submit'
                      id='SubmitCustomerInformationButton'
                      variant={ButtonVariant.PRIMARY}
                      type={ButtonType.SUBMIT}
                      onClick={(event) => {
                        event.preventDefault()
                        handleSubmit()
                      }}
                      disabled={!isValid}
                      loading={isSubmitting}
                      responsive
                    >
                      Update My Information
                    </Button>
                  </div>
                </Fieldset>
              </Form>
            )
          }}
        </Formik>
      )}
    </Mutation>
  )
}

CustomerInformationForm.propTypes = {
  initialValues: PropTypes.object.isRequired
}
