import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import { MENU_ITEM_DETAILS } from '../../../apollo/menu/menuitem.graphql'
import { useAddItemToCart } from '@local/do-secundo-cart-mutation'

import { UpdateItemFormModal } from '../UpdateItemFormModal/UpdateItemFormModal'

import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { useNestedModifiers } from '../use-nested-modifiers'
import { useMenuApiAwareLazyQuery } from '../../../hooks/use-menu-api-aware-lazy-query'
import { getItemDetails } from '@local/do-secundo-modifier-form-helpers'
import { track } from '@toasttab/do-secundo-analytics'

export const AddItem = ({ itemGuid, itemGroupGuid, onClose }) => {
  const { shortUrl, restaurantGuid } = useRestaurant()
  const { fulfillmentTime, loading } = useFulfillment()
  const dateTime = fulfillmentTime || undefined
  const { nestingLevel } = useNestedModifiers()

  const [getMenuItemDetails, itemDetailsQuery] = useMenuApiAwareLazyQuery(
    MENU_ITEM_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        input: { restaurantGuid, shortUrl, itemGuid, itemGroupGuid, dateTime },
        nestingLevel
      }
    }
  )

  const { data: itemData } = itemDetailsQuery

  const [addItemToCart, { error }] = useAddItemToCart({ onCompleted: onClose })

  useEffect(() => {
    if (!loading) getMenuItemDetails()
  }, [getMenuItemDetails, loading])

  useEffect(() => {
    if (itemData?.menuItemDetails) {
      const { imageUrl, description } = itemData?.menuItemDetails ?? {}
      track('Add Item Open', {
        hasImage: !!imageUrl,
        hasDescription: !!description
      })
    }
  }, [itemData?.menuItemDetails])

  const getBasePrice = (data = {}) =>
    (data?.menuItemDetails && data?.menuItemDetails.price) || 0

  return (
    <UpdateItemFormModal
      updateCart={addItemToCart}
      error={error}
      itemDetailsQuery={itemDetailsQuery}
      getItemDetails={getItemDetails}
      getBasePrice={getBasePrice}
      onClose={onClose}
      itemGuid={itemGuid}
      itemGroupGuid={itemGroupGuid}
    />
  )
}

AddItem.propTypes = {
  itemGuid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
