import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useAuth } from '../../AuthProvider/AuthProvider'
import { getFormattedDiningOption } from '../fulfillment-helpers'

import styles from './FulfillmentHeader.module.css'

export const FulfillmentHeader = ({ editing, diningOptionBehavior }) => {
  const { user, authenticated } = useAuth()
  return (
    <>
      {authenticated && !editing && (
        <p
          data-testid='scheduled-modal-welcome'
          className={cx(styles.welcome, 'type-large')}
        >
          Welcome
          {user?.firstName ? `, ${user.firstName}` : ''}!
        </p>
      )}
      {
        <h2
          data-testid='scheduled-modal-header'
          className={cx(styles.header, 'type-headline-5', {
            [styles.editing]: editing
          })}
        >
          {editing && 'Edit '}
          Order for {getFormattedDiningOption(diningOptionBehavior)}
        </h2>
      }
    </>
  )
}

FulfillmentHeader.propTypes = {
  editing: PropTypes.bool,
  diningOptionBehavior: PropTypes.string
}
