import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Dollars } from '@local/do-secundo-dollars'

import { useDeliveryToasts } from './use-delivery-toasts'
import { useLineItems } from '../../../utils/cart-helpers'
import { TermsModal, CHARGE_INFO } from '@local/do-secundo-terms-modal'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { InfoIcon } from '@toasttab/buffet-pui-icons'
import styles from './CartFooter.module.css'
import { PriceTransparencyCheckoutBanner } from '@local/do-secundo-price-transparency-checkout-banner'
import { FeesModal } from './FeesModal'

export const CartFooter = ({
  cart,
  deliveryChargeLabel = 'Delivery Cost',
  showTotal = false,
  showTip = false,
  tip = null,
  showFundraising = false,
  roundUp = null,
  showPtBanner = false,
  fundraising = null
}) => {
  const [showChargeModal, setShowChargeModal] = useState(false)
  const [showFeesModal, setShowFeesModal] = useState(false)
  const SHOW_CHARGE_INFO_ENABLED = useFlag(FF.SHOW_CHARGE_INFO)
  const getLineItems = useLineItems({
    cart,
    showTip,
    showTotal,
    showFundraising,
    roundUp,
    fundraising,
    deliveryChargeLabel
  })
  useDeliveryToasts({ cart })
  const lineItems = getLineItems(tip, roundUp, fundraising)

  const subTotal = lineItems.find(
    (item) => item.id === 'subtotal-amount'
  ).amount
  const savings = (subTotal * 0.3).toFixed(2)
  return (
    <>
      {showFeesModal && (
        <FeesModal onClose={() => setShowFeesModal(false)} data={cart.order} />
      )}
      <div
        className={cx(styles.footer, 'type-default')}
        data-testid='total-amounts'
        role='group'
        aria-label='cart totals'
      >
        <table>
          <tfoot>
            {lineItems.map(({ label, amount, id }) => {
              const showFeesInfoIcon = id === 'tax-and-fees-amount'
              return (
                <tr
                  data-testid={id}
                  key={id}
                  className={cx({
                    [styles.total]:
                      id === 'total-order-remaining-amount' ||
                      id === 'total-order-amount'
                  })}
                >
                  <td className={styles.chargeInfo}>
                    {label}
                    {showFeesInfoIcon && (
                      <button
                        type='button'
                        className={styles.infoButton}
                        onClick={() => setShowFeesModal(!showFeesModal)}
                      >
                        <span className='flex'>
                          <InfoIcon size='xs' className={styles.infoImage} />
                        </span>
                      </button>
                    )}
                  </td>
                  <td>
                    <Dollars
                      amount={Number(amount) || 0}
                      renderWhenZero={
                        id === 'deliveryChargeTotal-amount' ? 'Free' : undefined
                      }
                    />
                  </td>
                </tr>
              )
            })}
          </tfoot>
        </table>
        {showPtBanner && (
          <div className='mt-5'>
            <PriceTransparencyCheckoutBanner savings={savings} />
          </div>
        )}
      </div>
    </>
  )
}

CartFooter.propTypes = {
  cart: PropTypes.object.isRequired,
  /** Override the default label when displaying a delivery charge line item */
  deliveryChargeLabel: PropTypes.node,
  showTotal: PropTypes.bool,
  showTip: PropTypes.bool,
  tip: PropTypes.number,
  showFundraising: PropTypes.bool,
  roundUp: PropTypes.number,
  fundraising: PropTypes.number
}
