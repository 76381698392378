import React from 'react'
import PropTypes from 'prop-types'

import ToastLogo from '../../assets/powered-by/grey-horizontal.svg'

import { Modal } from '@local/do-secundo-modal'

import styles from './PoweredByToastModal.module.css'
import cx from 'classnames'

export const PoweredByToastModal = ({
  header,
  children,
  onClose,
  focusTrapOptions,
  className = ''
}) => (
  <Modal
    header={header}
    onClose={onClose}
    footer={<ToastLogo className={styles.logo} />}
    focusTrapOptions={focusTrapOptions}
  >
    <div className={cx(styles.modal, className)}>{children}</div>
  </Modal>
)

PoweredByToastModal.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  focusTrapOptions: PropTypes.object
}
