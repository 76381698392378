import { buildModifierGroupOptionsMap, getItemSize } from './helpers'
import { calculateModifierGroupPrice } from './calculateModifierGroupPrice'
/**
 * @typedef PricingStrategies
 * @enum {string}
 */
const PRICING_STRATEGIES = {
  BASE_PRICE: 'BASE_PRICE',
  GROUP_PRICE: 'GROUP_PRICE',
  MENU_SPECIFIC_PRICE: 'MENU_SPECIFIC_PRICE',
  SEQUENCE_PRICE: 'SEQUENCE_PRICE',
  SIZE_PRICE: 'SIZE_PRICE',
  SIZE_SEQUENCE_PRICE: 'SIZE_SEQUENCE_PRICE',
  TIME_SPECIFIC_PRICE: 'TIME_SPECIFIC_PRICE'
}

/**
 * Helper function that transforms dictionary-like DO_MENU_ITEM_DETAILS response into a set of map objects to simplify lookup of data.
 * Is meant to be called once and memoized to avoid re-calculations on each render.
 * @param {object} doMenuItemDetails DO_MENU_ITEM_DETAILS response data
 * @returns {TransformedMenuData} Set of helper objects to work with pricing data.
 */
function getTransformedDoMenuItemData(doMenuItemDetails) {
  // this handles the case when a menuItem is not available for a variety of reason such as:
  // * item removed from menu
  // * item not available bc of time
  if (
    !doMenuItemDetails ||
    !doMenuItemDetails.menuItem ||
    !doMenuItemDetails.menuResponse
  ) {
    return null
  }

  const {
    menuResponse: { modifierGroupReferences, modifierOptionReferences }
  } = doMenuItemDetails

  const modifierGroupOptionsMap = buildModifierGroupOptionsMap({
    modifierGroupReferences,
    modifierOptionReferences
  })

  return {
    modifierGroupOptionsMap
  }
}

/**
 * Returns sum of item price and its modifiers
 * @param item
 * @param menuResponse
 */
function getItemPrice({
  menuItem,
  itemModifierGroups,
  quantity,
  transformedMenuData
}) {
  const { modifierGroupOptionsMap } = transformedMenuData

  const { price = 0 } = menuItem
  const itemPrice = price

  // determine item size if exists
  const itemSize = getItemSize({
    menuItem,
    modifierGroupOptionsMap,
    itemModifierGroups
  })

  // loop over modifierGroups
  const modifierGroupsPrice = itemModifierGroups.reduce(
    (acc, modifierGroup) => {
      const price = calculateModifierGroupPrice({
        modifierGroup,
        modifierGroupOptionsMap,
        itemSize
      })
      return acc + price
    },
    0
  )

  const total = (itemPrice + modifierGroupsPrice) * quantity
  return Number(total.toFixed(2))
}

const { SEQUENCE_PRICE, SIZE_PRICE, SIZE_SEQUENCE_PRICE } = PRICING_STRATEGIES
const sequenceStrategies = [SEQUENCE_PRICE, SIZE_SEQUENCE_PRICE]
const sizeStrategies = [SIZE_PRICE, SIZE_SEQUENCE_PRICE]

/**
 * @typedef {PricingStrategies} PricingStrategies
 */

/**
 * Pricing helper that returns aggregated data related to modifier group price for rendering purposes.
 * @param {PricingArguments} pricingArguments
 * - aggregated data from DO_MENUS that was transformed by pricingUtility for frontend consumption
 * @param {[]} selectedModifiers array of modifier groups with only selected modifiers derived from formik
 * @param {string} guid modifierGroup guid
 * @returns {{pricingStrategy: PricingStrategies, modifierGroupPrice: number, itemSize: ItemSize|null}} modifierGroupPrice is only available if pricing strategy is SEQUENCE or SIZE_SEQUENCE, itemSize is only available for SIZE and SIZE_SEQUENCE strategies and only AFTER it was selected by the user
 */
function getModifierGroupPricingData(
  pricingArguments,
  selectedModifiers,
  guid
) {
  const { modifierGroupOptionsMap, itemSize } = pricingArguments

  const pricingStrategy = modifierGroupOptionsMap[guid]?.[0]?.pricingStrategy

  let modifierGroupPrice = null
  // calculate modifierGroupPrice only if its sequence price, wont run otherwise since some data is absent
  if (checkIfSequenceStrategy(pricingStrategy)) {
    modifierGroupPrice = calculateModifierGroupPrice({
      modifierGroup: selectedModifiers,
      modifierGroupOptionsMap,
      itemSize
    })
  }

  return {
    pricingStrategy,
    modifierGroupPrice,
    itemSize
  }
}

/**
 * Pricing helper that returns pricing data of a single modifier based on the size.
 * @param {DoMenuItemResponse} apiData "raw" data from DO_MENU_ITEM_DETAILS query
 * @param {ItemSize} itemSize itemSize object from pricingUtility
 * @returns {{selectedSizeBasedPrice: number | null, minPrice: number}} selectedSizeBasedPrice is the price of modifier after user selected the size, minPrice is the price for the smallest(cheapest) size
 */

function getModifierSizePrice(apiData, itemSize) {
  // if not size/size_sequence - return nulls
  if (!apiData?.pricingRules?.sizeSequencePricingRules) {
    return {
      selectedSizeBasedPrice: null,
      minPrice: null
    }
  }
  const { sizeSequencePricingRules } = apiData?.pricingRules
  let selectedSizeBasedPrice = null
  if (itemSize) {
    selectedSizeBasedPrice = sizeSequencePricingRules.filter(
      ({ sizeGuid }) => sizeGuid === itemSize.guid
    )?.[0]?.sequencePrices?.[0]?.price
  }

  let minPrice = Infinity
  sizeSequencePricingRules.forEach(
    ({ sizeGuid, sequencePrices: [{ price }] }) => {
      if (sizeGuid) minPrice = Math.min(price, minPrice)
    }
  )
  if (minPrice === Infinity) {
    minPrice = null
  }

  return { selectedSizeBasedPrice, minPrice }
}

/**
 * Determines if pricing strategy if one of the SIZE or SIZE_SEQUENCE
 * @param {PricingStrategies} pricingStrategy
 * @returns {boolean}
 */
function checkIfSizeStrategy(pricingStrategy) {
  return sizeStrategies.some((strategy) => strategy === pricingStrategy)
}

/**
 * Determines if pricing strategy if one of the SEQUENCE or SIZE_SEQUENCE
 * @param {PricingStrategies} pricingStrategy
 * @returns {boolean}
 */
function checkIfSequenceStrategy(pricingStrategy) {
  return sequenceStrategies.some((strategy) => strategy === pricingStrategy)
}

/**
 * Transforms defaultOptionsChargePrice value from 'YES'/'NO' string into a boolean.
 * @param {('YES'|'NO'} defaultOptionsChargePrice
 * @returns {boolean}
 */
function checkIfDefaultOptionsChargePrice(defaultOptionsChargePrice) {
  return defaultOptionsChargePrice === 'YES'
}

export {
  getModifierSizePrice,
  checkIfSizeStrategy,
  getItemPrice,
  getItemSize,
  calculateModifierGroupPrice,
  PRICING_STRATEGIES,
  getTransformedDoMenuItemData,
  checkIfSequenceStrategy,
  getModifierGroupPricingData,
  checkIfDefaultOptionsChargePrice
}
