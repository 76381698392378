import React from 'react'

import { SearchBanner } from '../../SearchBanner/SearchBanner'

export const BeginSearchBanner = () => {
  return (
    <SearchBanner>
      <p>Begin typing to search</p>
    </SearchBanner>
  )
}
