import React from 'react'
import { Field } from 'formik'
import { string } from 'yup'

import { Input } from '@local/do-secundo-form'
import { getRawPhoneNumber, formatTel } from '@local/do-secundo-form-utils'
import styles from './CustomerInfo.module.css'
import cx from 'classnames'

export const Component = () => {
  const [showLegal, setShowLegal] = React.useState(false)
  const [moveInTransition, setMoveInTransition] = React.useState(false)
  return (
    <>
      <Field
        autoComplete='section-customer given-name'
        data-testid='customer_first_name'
        id='customer_first_name'
        label='First Name*'
        name='customerFirstName'
        type='text'
        autoFocus
        component={Input}
      />
      <Field
        autoComplete='section-customer family-name'
        data-testid='customer_last_name'
        id='customer_last_name'
        label='Last Name*'
        name='customerLastName'
        type='text'
        component={Input}
      />
      <Field
        autoComplete='section-customer email'
        data-testid='customer_email'
        id='customer_email'
        label='Email*'
        name='customerEmail'
        type='email'
        component={Input}
        onFocus={() => {
          setMoveInTransition(true)
          setShowLegal(true)
        }}
        onBlur={() => {
          setMoveInTransition(false)
          setTimeout(() => setShowLegal(false), 250)
        }}
      />
      {showLegal && (
        <p
          className={cx(styles['email-legal-language'], {
            [styles['fade-ease-in']]: moveInTransition,
            [styles['fade-ease-out']]: !moveInTransition
          })}
        >
          By providing your email, you agree to receive digital receipts and
          marketing communications from this restaurant or restaurant group, as
          applicable.
        </p>
      )}
      <Field
        autoComplete='section-customer tel-national'
        data-testid='customer_tel'
        id='customer_tel'
        label='Phone*'
        name='customerTel'
        type='tel'
        mask={[
          '(',
          /[1-9]/,
          /\d/,
          /\d/,
          ')',
          ' ',
          /\d/,
          /\d/,
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        guide={false}
        component={Input}
      />
    </>
  )
}

export const getInitialValues = ({ user }) => ({
  customerFirstName: user.firstName || '',
  customerLastName: user.lastName || '',
  customerEmail: user.email || '',
  customerTel: formatTel(getRawPhoneNumber(user.phone || ''))
})

export const getArgsForSubmit = ({ values }) => ({
  customer: {
    email: values.customerEmail,
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    phone: getRawPhoneNumber(values.customerTel)
  }
})

export const getValidationSchema = () => ({
  customerFirstName: string().trim().required('required'),
  customerLastName: string().trim().required('required'),
  customerEmail: string()
    .trim()
    .email('must be valid email')
    .required('required'),
  customerTel: string()
    .transform(getRawPhoneNumber)
    .trim()
    .matches(/^\d{10}$/, 'must be a 10 digit number')
    .required('required')
})
