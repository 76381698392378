import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { GET_CART } from '../../apollo/cart/cart.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useCart } from '@local/do-secundo-cart-provider'

// Not a "real" error as far as we're concerned, just no cart found.
const cartNotFound = (error) =>
  error && error.networkError && error.networkError.statusCode === 400

export const useGetCart = (query = GET_CART) => {
  const context = useCart()
  const { cartGuid: guid, deleteCartGuid } = context
  let { loading, error, data, refetch, networkStatus } = useQuery(query, {
    variables: { guid },
    notifyOnNetworkStatusChange: true,
    skip: !guid
  })
  if (!guid) return context

  if (cartNotFound(error)) {
    error = undefined
  }
  const { CartResponse } = data ? dataByTypename(data.cartV2) : {}
  const cart = (CartResponse && CartResponse.cart) || null
  const info = (CartResponse && CartResponse.info) || null
  const warnings = (CartResponse && CartResponse.warnings) || null
  const cartGuid = (cart && cart.guid) || null
  if (!loading && !error && !cartGuid) {
    deleteCartGuid()
  }
  return {
    ...context,
    error,
    loading,
    data,
    refetch,
    networkStatus,
    cart,
    cartGuid,
    info,
    warnings
  }
}

export const GetCartQuery = ({ children, query = GET_CART }) => {
  const context = useGetCart(query)
  return children(context)
}

GetCartQuery.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object
}
