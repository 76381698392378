import PropTypes from 'prop-types'
import { Model } from './Model'
import { Modifier } from './Modifier'

export class ModifierGroup extends Model {
  constructor(modifierGroup, parent) {
    super(modifierGroup)
    this.modifiers = this.mapModifiers(modifierGroup.modifiers || [])
    this.parent = parent
    this.selectedGuid = ''
  }

  mapModifiers(modifiers) {
    return modifiers.map((modifier, i) => new Modifier(modifier, this, i))
  }

  getNextValues() {
    return this.modifiers
  }

  resetDefaults() {}

  /**
   * @returns {boolean} True if the constraints only allow one selection in the group.
   */
  get isSingleSelect() {
    return this.maxSelections === 1 && this.minSelections === 1
  }

  get fieldName() {
    return `modifiers.${this.guid}`
  }

  /**
   * @returns {string} The formik key for this group
   */
  get valueFieldName() {
    return `${this.fieldName}.value`
  }

  /**
   * @returns {Modifier|null} If the parent is a modifier return it else
   *   return null
   */
  get parentModifier() {
    if (this.parent instanceof Modifier) {
      return this.parent
    }

    return null
  }
}

export const ModifierGroupShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  modifiers: PropTypes.array.isRequired,
  maxSelections: PropTypes.number,
  minSelections: PropTypes.number.isRequired,
  pricingMode: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired
})
