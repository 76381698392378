import * as React from 'react'
import { Modal } from '@local/do-secundo-modal'
import { useMenus } from '../../../../client/hooks/use-menus'
import { LoadingModal } from '@local/do-secundo-modifiers'
import { Button, ButtonVariant, ButtonType } from '@local/do-secundo-button'
import { DonutProgressIndicator } from '@toasttab/buffet-pui-progress-indicators'
import {
  AnalyticsClockIcon,
  AnalyticsCheckIcon
} from '@toasttab/buffet-pui-icons'
import { ErrorComponent } from '@local/do-secundo-error'
import { useEditMode } from '@local/do-secundo-edit-mode-provider'

const getPercentage = (count, total) => {
  return Math.floor((count / total) * 100)
}

const getScore = (score) => {
  if (score < 25) return 'D'
  if (score < 50) return 'C'
  if (score < 75) return 'B'
  return 'A'
}
const traverse = (menus) => {
  let itemCount = 0
  let itemsWithDescriptions = 0
  let itemsWithImages = 0
  let itemsWithCalories = 0
  menus.forEach(({ groups }) => {
    groups.forEach(({ items }) => {
      items.forEach(({ calories, imageUrl, description }) => {
        itemCount++
        if (calories) itemsWithCalories++
        if (imageUrl) itemsWithImages++
        if (description) itemsWithDescriptions++
      })
    })
  })
  const imagePercent = getPercentage(itemsWithImages, itemCount)
  const descriptionPercent = getPercentage(itemsWithDescriptions, itemCount)
  const caloriesPercent = getPercentage(itemsWithCalories, itemCount)

  return {
    total: itemCount,
    itemsWithDescriptions: {
      quantity: itemsWithDescriptions,
      percent: descriptionPercent,
      score: getScore(descriptionPercent)
    },
    itemsWithImages: {
      quantity: itemsWithImages,
      percent: imagePercent,
      score: getScore(imagePercent)
    },
    itemsWithCalories: {
      quantity: itemsWithCalories,
      percent: caloriesPercent,
      score: getScore(caloriesPercent)
    }
  }
}

const randomNumberFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const MenuPerformanceModal = ({ onClose }) => {
  const { loading, error, menus } = useMenus({
    notifyOnNetworkStatusChange: true
  })
  const { toggleEditing } = useEditMode()
  const [isRunning, setIsRunning] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [performanceData, setPerformanceData] = React.useState(null)

  React.useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setProgress((previousProgress) => {
          if (previousProgress >= 100) {
            clearInterval(interval)
            return 100
          }
          const newValue = previousProgress + randomNumberFromInterval(7, 10)
          return newValue > 100 ? 100 : newValue
        })
      }, 100)
      return () => clearInterval(interval)
    }
  }, [isRunning])

  const handleStartTestClick = () => {
    setIsRunning(true)

    setTimeout(() => {
      setPerformanceData(traverse(menus))
      setIsRunning(false)
    }, 1500)
  }

  const handleClose = () => {
    toggleEditing()
    onClose()
  }

  if (loading) return <LoadingModal onClose={onClose} />
  if (error) return <ErrorComponent error={error} />
  return (
    <Modal header={'Menu performance test'} onClose={() => onClose()}>
      {performanceData ? (
        <div className='mx-4'>
          <div lassName='flex flex-col'>
            <h3>{`Breakdown of ${performanceData.total} items:`}</h3>
            <div className='flex justify-around my-1'>
              <div className='w-48'>
                <span>Images: </span>
                <br />
                <strong>{`${performanceData.itemsWithImages.quantity} items - ${performanceData.itemsWithImages.percent}%`}</strong>
              </div>
              <div>
                <span>Score: </span>
                <br />
                <strong>{`${performanceData.itemsWithImages.score}`}</strong>
              </div>
            </div>
            <div className='flex justify-around my-1'>
              <div className='w-48'>
                <span>Descriptions: </span>
                <br />
                <strong>{`${performanceData.itemsWithDescriptions.quantity} items - ${performanceData.itemsWithDescriptions.percent}%`}</strong>
              </div>
              <div>
                <span>Score: </span>
                <br />
                <strong>{`${performanceData.itemsWithDescriptions.score}`}</strong>
              </div>
            </div>
            <div className='flex justify-around my-1'>
              <div className='w-48'>
                <span>Nutritional info: </span>
                <br />
                <strong>{`${performanceData.itemsWithCalories.quantity} items - ${performanceData.itemsWithCalories.percent}%`}</strong>
              </div>
              <div>
                <span>Score: </span>
                <br />
                <strong>{`${performanceData.itemsWithCalories.score}`}</strong>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <h3>Our insight: </h3>
            <div className='my-2'>
              Menus where more than 75% of items have images have ~13% higher
              conversion rate as well as ~15% larger average order size.
            </div>
            <div className='my-2'>
              Presence of item description directly correlates to the guest
              satisfaction and return rate - it is much easier to make a right
              choice when chef gives you a hint:)
            </div>
          </div>
        </div>
      ) : (
        <div className='mx-4'>
          Our research show direct correlation between the quality of digital
          menu and guest conversion rate.
          <br />
          <br />
          To learn more - analyze your menu!
        </div>
      )}
      {isRunning ? (
        <div className='flex justify-center mt-2'>
          <DonutProgressIndicator progressPercent={progress} />
        </div>
      ) : performanceData ? (
        <div className='flex justify-center mt-2'>
          <AnalyticsCheckIcon size='2xl' />
        </div>
      ) : (
        <div className='flex justify-center mt-2'>
          <AnalyticsClockIcon size='2xl' />
        </div>
      )}
      <div className='mx-4'>
        <Button
          onClick={performanceData ? handleClose : handleStartTestClick}
          variant={ButtonVariant.PRIMARY}
          type={ButtonType.BUTTON}
        >
          {`${performanceData ? 'Go to Edit mode' : 'Start the test'}`}
        </Button>
      </div>
    </Modal>
  )
}
