import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonVariant } from '@local/do-secundo-button'

import styles from './MenuItem.module.css'

export const MenuItem = ({
  nestedModifierName,
  description,
  calories,
  imageUrl,
  onRemoveModifier
}) => {
  return (
    <div className={styles.menuItem}>
      {nestedModifierName && (
        <h3 className={styles.nestedName}>{nestedModifierName}</h3>
      )}
      <p data-testid='modifier-item-description' className={styles.description}>
        {description}
      </p>
      {calories > 0 && (
        <p data-testid='modifier-item-calories' className={styles.calories}>
          {calories} Calories
        </p>
      )}
      {imageUrl && (
        <div
          data-testid='modifier-image-url'
          className={styles.image}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      )}
      {onRemoveModifier && (
        <Button
          data-testid='remove-nested-modifier'
          variant={ButtonVariant.PRIMARY}
          responsive
          onClick={onRemoveModifier}
        >
          Remove {nestedModifierName}
        </Button>
      )}
    </div>
  )
}

MenuItem.propTypes = {
  nestedModifierName: PropTypes.string,
  description: PropTypes.string,
  calories: PropTypes.number,
  imageUrl: PropTypes.string,
  onRemoveModifier: PropTypes.func
}
