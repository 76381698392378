import React from 'react'
import PropTypes from 'prop-types'

import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'

import { getFastRect } from '../../utils/dimensions'
import { isMobileSafari } from '../../browser_overrides'

export class NavScroller extends React.Component {
  constructor(props) {
    super(props)
    this.state = { activeItem: null }
    this.createRefMap()
    this.handleScroll = this.handleScroll.bind(this)
    this.handleScrollThrottled = throttle(this.handleScroll, 20, {
      trailing: true
    })
  }
  createRefCallback(id) {
    return (node) => {
      if (node) this.refMap[id].current = node
      return this.refMap[id]
    }
  }

  createRefMap() {
    this.refMap = this.props.items.reduce((acc, { id }) => {
      acc[id] = React.createRef()
      return acc
    }, {})

    // map of callbacks that updates the above refMap
    // this allows us to perform necessary side-effects when ref is set
    this.refCallbackMap = this.props.items.reduce((acc, { id }) => {
      acc[id] = this.createRefCallback(id)
      return acc
    }, {})
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.createRefMap()
      this.handleScroll()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollThrottled)

    if (isMobileSafari) {
      document.body.addEventListener('touchmove', this.handleScrollThrottled)
    }

    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollThrottled)
    if (isMobileSafari) {
      document.body.removeEventListener('touchmove', this.handleScrollThrottled)
    }
  }

  async handleScroll() {
    const strategies = {
      topmostPartiallyVisibleInViewport: async (elem, current, id, offset) => {
        const { top } = await getFastRect(elem)
        const currentValue = current.top || -Infinity

        if (top < 1 + offset && top > currentValue) {
          return { top }
        }
      }
    }

    const getActiveElemProps = strategies.topmostPartiallyVisibleInViewport

    const active = await this.props.items.reduce(async (active, { id }) => {
      const ref = this.refMap[id]
      if (!ref.current) {
        return active
      }

      const offset = await this.props.calcOffsetFn()

      const props = await getActiveElemProps(ref.current, active, id, offset)
      if (props) {
        return {
          id,
          ref,
          ...props
        }
      }
      return active
    }, {})

    const getid = (active) => {
      let item = active.id ? active : this.props.items[0]
      return item && item.id ? item.id : undefined
    }

    const id = getid(active)
    this.setState({ activeItem: id })
  }

  render() {
    const { activeItem } = this.state
    const { items, children } = this.props
    const { refCallbackMap } = this
    return children({
      items,
      activeItem,
      refMap: refCallbackMap
    })
  }
}

NavScroller.propTypes = {
  children: PropTypes.func.isRequired,
  items: PropTypes.array,
  calcOffsetFn: PropTypes.func
}

// TODO: Remove default props from this Component
NavScroller.defaultProps = {
  items: [],
  calcOffsetFn: async () => 0
}
