/**
 * @typedef MenuItem
 * @type {Object}
 * @property {string} description
 * @property {string} guid
 * @property {string} name
 * @property {boolean} outOfStock
 * @property {number} price
 * @property {string} imageUrl
 * @property {number} calories
 * @property {string} itemGroupGuid
 * @property {string} unitOfMeasure
 * @property {boolean} usesFractionalQuantity
 * (these properties are amended to typical MenuItem)
 * @property {string} groupName
 * @property {string} menuName
 *
 * @typedef RulesContext
 * @type {object}
 * @property {object} cart - a cart object
 * @property {Array<MenuItem>} popularItems - Array of popular items
 *
 * @callback filterFunctionWithContext
 * @param {RulesContext} context
 * @param {MenuItem} item
 * @returns {boolean}
 *
 * @callback sortFunctionWithContext
 * @param {RulesContext} context
 * @param {MenuItem} itemA
 * @param {MenuItem} itemB
 * @returns {number}
 */

/**
 * Filters item between a given floor price and ceiling price (non-inclusive). Returns a filter function.
 * @param {number} floor
 * @param {number} ceiling
 * @returns {filterFunctionWithContext}
 */
export const priceRangeFilter = (floor, ceiling) => (_, item) => {
  return item.price < ceiling && item.price > floor
}

/**
 * Filters out items that contains any of the given strings. Returns a filter function.
 * @param {Array<string>} words
 * @returns {filterFunctionWithContext}
 */
export const isNotBannedWordsFilter = (words) => (_, item) => {
  const text = [item.name, item.description].join(' ').toLowerCase()
  const pattern = words.map((str) => str.toLowerCase()).join('|')
  const matcher = new RegExp(`^.*${pattern}.*$`)
  return !matcher.test(text)
}

/**
 * Filters out items that are not already in the cart
 * @type {filterFunctionWithContext}
 */
export const notInCartFilter = ({ cart }, item) => {
  if (!cart) return true
  const selections = (cart && cart.order && cart.order.selections) || []
  const selectionGuids = selections.map((item) => item.itemGuid)
  return !selectionGuids.includes(item.guid)
}

/**
 * Filters out items that are similar to those in cart
 * @type {filterFunctionWithContext}
 */
export const similarNotInCartFilter = ({ cart }, item) => {
  if (!cart) return true
  const selections = (cart && cart.order && cart.order.selections) || []
  const itemNames = selections
    .map((item) => item.name)
    .join(' ')
    .toLowerCase()
  const pattern = item.name
    .toLowerCase()
    .replace(/[^\w]/g, ' ')
    .replace(/\s+/g, '|')
    .replace(/^\||\|$/g, '')
  const matcher = new RegExp(`^.*${pattern}.*$`)
  return !matcher.test(itemNames)
}

/**
 * Filters out items that are not in stock
 * @type {filterFunctionWithContext}
 */
export const isInStockFilter = (_, item) => !item.outOfStock

/**
 * Filters out items that are not in stock
 * @type {filterFunctionWithContext}
 */
export const isNotPopularItems = ({ popularItems }, item) =>
  popularItems.every((popItem) => popItem.guid !== item.guid)

/**
 * Prioritizes items that have certain words
 * @param {Array<string>} words - words in name or description to prioritize
 * @returns {sortFunctionWithContext}
 */
export const prioritizeWordsSort = (words) => (_, a, b) => {
  const textA = [a.name, a.description].join(' ').toLowerCase()
  const textB = [b.name, b.description].join(' ').toLowerCase()

  const aHasWord = words.some((word) => textA.includes(word)) ? 1 : 0
  const bHasWord = words.some((word) => textB.includes(word)) ? 1 : 0

  return bHasWord - aHasWord
}
/**
 * Prioritizes items with images
 * @type {sortFunctionWithContext}
 */
export const prioritizeHasImage = (_, a, b) => {
  const aHasImage = a.imageUrl ? 1 : 0
  const bHasImage = b.imageUrl ? 1 : 0

  return bHasImage - aHasImage
}

/**
 * Array of upsell filter rules
 * @type {Array<import('../Upsells/upsell-rules').filterFunctionWithContext>}
 */
export const upsellFilterRules = [
  priceRangeFilter(0, 10.01),
  isNotBannedWordsFilter(['alcohol', 'beer', 'wine', 'cocktail', 'abv']),
  notInCartFilter,
  similarNotInCartFilter,
  isInStockFilter,
  isNotPopularItems
]

export const intelligentUpsellFilterRules = [
  isNotBannedWordsFilter(['alcohol', 'beer', 'wine', 'cocktail', 'abv']),
  notInCartFilter,
  isInStockFilter,
  isNotPopularItems
]

/**
 * Array of upsell sorting rules
 * @type {Array<import('../Upsells/upsell-rules').sortFunctionWithContext>}
 */
export const upsellSortRules = [
  prioritizeWordsSort([
    'fries',
    'shake',
    'beverage',
    'dessert',
    'soda',
    'soup',
    'salad'
  ]),
  prioritizeHasImage
]
