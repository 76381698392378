import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, Field } from 'formik'

import { ErrorComponent } from '@local/do-secundo-error'
import { Input, Radio } from '@local/do-secundo-form'
import { SavedAddressLabel } from '../../SavedAddressLabel/SavedAddressLabel'

import { useSavedAddressUpdater } from './use-saved-address-updater'
import { CannedDeliveryInstructions } from './CannedDeliveryInstructions'

import styles from './SavedAddressPicker.module.css'
import { ContactlessDeliveryInstructions } from './ContactlessDeliveryInstructions'

const SavedAddressPickerImpl = ({
  savedAddresses = [],
  field,
  formik: { setFieldValue }
}) => {
  const { error } = useSavedAddressUpdater({
    savedAddresses,
    savedAddressGuid: field.value
  })

  const selectedAddress = savedAddresses.find(
    (address) => address.guid === field.value
  )

  useEffect(() => {
    /**
     * check for selectedAddress.deliveryInfo.notes presence for when value is null
     * application will throw fatal error if field is set to null value
     **/
    if (
      selectedAddress &&
      selectedAddress.deliveryInfo &&
      selectedAddress.deliveryInfo.notes
    ) {
      setFieldValue(
        'contactlessCustomerDeliveryInfoNotes',
        selectedAddress.deliveryInfo.notes
      )
    }
  }, [selectedAddress, setFieldValue])

  if (savedAddresses.length === 0) {
    return <p>No Saved Addresses</p>
  }

  return (
    <>
      {savedAddresses.map((address) => (
        <div key={address.guid}>
          <Field
            data-testid='saved-address'
            component={Radio}
            id={`${field.name}_saved_address_${address.guid}`}
            name={field.name}
            label={<SavedAddressLabel savedAddress={address} />}
            value={address.guid}
          />
        </div>
      ))}

      {selectedAddress && (
        <div className={styles.savedAddressDeliveryInstructionsOverride}>
          <Field
            component={Input}
            data-testid='contactless_customer_delivery_info_notes'
            type='text'
            id='contactless_customer_delivery_info_notes'
            label='Update Delivery Instructions'
            name='contactlessCustomerDeliveryInfoNotes'
          />
          <CannedDeliveryInstructions
            content={
              <div>
                <ContactlessDeliveryInstructions />
                <div className={styles.emphasized}>
                  Note: This will replace your saved instructions for this order
                  only.
                </div>
              </div>
            }
            header='Request contactless delivery'
          />
        </div>
      )}
      {error && <ErrorComponent error={error} />}
    </>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
})

SavedAddressPickerImpl.propTypes = {
  savedAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      guid: PropTypes.string.isRequired
    })
  ),
  field: FieldShape.isRequired,
  formik: PropTypes.object
}

export const SavedAddressPicker = connect(SavedAddressPickerImpl)
