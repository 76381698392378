import React from 'react'
import { withRouter } from 'react-router'
import { Formik, Form, Field } from 'formik'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import * as Yup from 'yup'
import cx from 'classnames'

import { ButtonSpacer } from '../../ButtonSpacer/ButtonSpacer'
import { dataByTypename } from '../../../utils/apollo-helpers'
import { Notification } from '@local/do-secundo-notification'
import { useAuth, useLogin } from '../../AuthProvider/AuthProvider'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import { Input } from '@local/do-secundo-form'
import { Password } from '../../Form/Password/Password'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { ModeLink, ModeRedirect } from '../../ModeRouter/utils'
import { emailSchema, passwordSchema } from '../../../utils/form-schemas'

import styles from './LoginModal.module.css'
import { useIsPasswordlessAuthEnabled } from '@local/do-secundo-passwordless-authentication'

export const LoginModal = withRouter(
  ({ onClose, location: { state = {}, pathname }, history }) => {
    const { setMfaState, mfaRequiresVerification } = useAuth()
    const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
    const {
      login,
      result: { error, loading }
    } = useLogin()
    let notificationText
    if (state.createAccountRedirect) {
      notificationText =
        'Looks like you already have an account! Enter your password to complete checkout or continue as guest.'
    }
    if (state.verifyAccountRedirect) {
      notificationText =
        'It looks like you have already verified your account! Log in below.'
    }

    if (mfaRequiresVerification) {
      return <ModeRedirect pathname={pathname} mode='mfa' />
    }

    return (
      <PoweredByToastModal
        onClose={onClose}
        header={
          <>
            <h2
              data-testid='login-modal-header'
              className={cx(styles.header, 'type-headline-4')}
            >
              Log In
            </h2>
            {!notificationText && (
              <p
                data-testid='login-modal-description'
                className={cx(styles.description, 'type-default')}
              >
                See past orders, get your favorites again and save all your info
                in one place.
              </p>
            )}
          </>
        }
      >
        <Formik
          initialValues={{ email: state.email || '', password: '' }}
          validationSchema={Yup.object().shape({
            email: emailSchema,
            password: passwordSchema
          })}
          onSubmit={async (values) => {
            const input = {
              variables: {
                input: {
                  ...values,
                  email: values.email.trim(),
                  forAccountLinking: PASSWORDLESS_ENABLED
                }
              }
            }

            const result = await login(input)

            const { MfaChallengeGeneratedResponse, LoginError } =
              dataByTypename(result.data.login)

            if (MfaChallengeGeneratedResponse) {
              setMfaState({
                mfaRequiresVerification: true,
                email: values.email,
                ...result.data.login
              })
            } else if (!LoginError) {
              onClose()
            }
          }}
        >
          {({ isValid, values }) => (
            <Form>
              {notificationText && (
                <Notification>{notificationText}</Notification>
              )}
              {error && (
                <Notification severity='error'>
                  {error.code === 'EMAIL_LOCKED' ? (
                    <>
                      You have reached the maximum number of failed login
                      attempts. Please&nbsp;
                      <ModeLink
                        mode='forgot'
                        data-testid='login-modal-reset-password-link'
                        className={styles.loginModalResetPasswordLink}
                        state={{
                          email: values.email
                        }}
                      >
                        reset your password
                      </ModeLink>
                      &nbsp;and try again.
                    </>
                  ) : (
                    'Invalid username or password combination.'
                  )}
                </Notification>
              )}
              <Field
                data-testid='login-modal-email'
                autoFocus
                id='login-email'
                name='email'
                type='text'
                label='email'
                component={Input}
              />
              <Field
                data-testid='login-modal-password'
                id='login-password'
                name='password'
                label='password'
                component={Password}
              />
              <Button
                data-testid='login-modal-button-submit'
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.PRIMARY}
                responsive
                loading={loading}
                disabled={!isValid}
              >
                Log In
              </Button>
              <ButtonSpacer vertical />
              <ModeLink
                data-testid='login-modal-button-forgot-password'
                style='secondaryWide'
                mode='forgot'
                state={{
                  email: values.email
                }}
              >
                Forgot Password
              </ModeLink>
              <div className={styles.createAccountWrapper}>
                Don't have an account?{' '}
                <ModeLink
                  data-testid='login-modal-create-account'
                  style='primary'
                  mode='create'
                >
                  Create account
                </ModeLink>
              </div>
            </Form>
          )}
        </Formik>
      </PoweredByToastModal>
    )
  }
)

LoginModal.displayName = 'LoginModal'

LoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  location: ReactRouterPropTypes.location
}
