import React from 'react'
import { Field } from 'formik'
import uniqBy from 'lodash/uniqBy'
import { string } from 'yup'

import { Dollars } from '@local/do-secundo-dollars'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Radio, Input } from '@local/do-secundo-form'

import { useGetCart } from '../../CartQuery/CartQuery'
import { isDaasCart } from '../../../utils/cart-helpers'

import styles from './Tip.module.css'

export const Component = () => {
  const { cart } = useGetCart()
  const { preComputedTips } = cart
  const uniquePreComputedTips = uniqBy(preComputedTips, 'percent')
  const fieldsetLabel = isDaasCart(cart) ? 'Driver Tip' : 'Tip'
  return (
    <Fieldset label={fieldsetLabel} collapsable id='tip_fields'>
      <div data-testid='label-tip' className={styles.tips}>
        {uniquePreComputedTips &&
          uniquePreComputedTips.map((tip, index) => (
            <Field
              component={Radio}
              style='chunky'
              label={
                <>
                  <span className={styles.percent}>{tip.percent}%</span>
                  <span className={styles.dollars}>
                    <Dollars amount={tip.value} />
                  </span>
                </>
              }
              data-testid={'percent-tip-' + tip.percent}
              name='paymentTip'
              id={`tip_${index}`}
              key={`tip_${index}`}
              percent={tip.percent}
              value={tip.value.toFixed(2)}
            />
          ))}
        <Field
          data-testid='custom-tip'
          component={Input}
          label='Custom Amount'
          type='text'
          name='paymentTip'
          inputMode='decimal'
          id='payment_tip'
          key='payment_tip'
        />
      </div>
    </Fieldset>
  )
}

export const getInitialValues = ({ cart }) => {
  const { preComputedTips } = cart

  if (isDaasCart(cart)) {
    const { value } =
      preComputedTips.find(({ percent }) => percent === 20) || {}
    const defaultValue = value ? value.toFixed(2) : ''
    return {
      paymentTip: defaultValue
    }
  }

  return { paymentTip: '' }
}

export const getValidationSchema = () => {
  return {
    paymentTip: string().when('paymentType', {
      is: (paymentType) => {
        const creditPaymentTypes = ['SAVED_CREDIT_CARD', 'CREDIT_CARD']
        return creditPaymentTypes.includes(paymentType)
      },
      then: string()
        .trim()
        .default('')
        // TODO more robust currency validation
        .matches(/^([1-9][0-9]*|0)?(\.\d{2})?$/, 'must be a dollar amount'),
      otherwise: string().default('')
    })
  }
}

export const getArgsForSubmit = ({ values, cart }) => {
  const { creditCardConfig } = cart.restaurant
  const tipAmount =
    (creditCardConfig.tipEnabled && parseFloat(values.paymentTip)) || 0
  return { tipAmount }
}
