export const getRawPhoneNumber = (val) => {
  return val.replace(/\D/g, '').replace(/1?(\d{10})$/, (_, p1) => p1)
}

export const COUNTRY_CODES = {
  USA: '+1',
  PUERTO_RICO: '+1',
  CANADA: '+1'
}

export const getRawPhoneNumberWithCountryCode = (
  phone,
  countryCode = COUNTRY_CODES.USA
) => {
  return `${countryCode}${getRawPhoneNumber(phone)}`
}

export const formatTel = (val = '') => {
  if (!val) {
    return ''
  }
  return val.replace(
    /(\d{3})(\d{3})(\d{4})/,
    (_, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  )
}

export function getPrimaryCreditCard(creditCards) {
  return (
    creditCards.find(({ isPrimary, expired }) => isPrimary && !expired) || {}
  )
}

export function trimToDecimalValue(str, precision = 0) {
  const [int, frac] = str.split('.')

  if (!frac || precision === 0) {
    return int
  }

  return [int, frac.substring(0, precision)].join('.')
}

export const formUtils = {
  getRawPhoneNumber,
  formatTel,
  getPrimaryCreditCard,
  trimToDecimalValue
}
