import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  useDiningOptions,
  DINING_OPTION_BEHAVIORS
} from '@local/do-secundo-use-dining-options'

import { addEventProperties, track } from '@toasttab/do-secundo-analytics'

const { TAKE_OUT, DELIVERY } = DINING_OPTION_BEHAVIORS

export const FULFILLMENT_TYPES = {
  ASAP: 'ASAP',
  FUTURE: 'FUTURE'
}
const { ASAP, FUTURE } = FULFILLMENT_TYPES

const AvailabilityContext = React.createContext({})

export const AvailabilityProvider = ({ children }) => {
  const { restaurantInfo } = useRestaurant()
  const {
    loading: diningOptionsLoading,
    data: diningOptionsData,
    error: diningOptionsError
  } = useDiningOptions({ networkOnly: false })
  const {
    loading: restaurantLoading,
    data: restaurantData,
    error: restaurantError
  } = restaurantInfo

  const context = useMemo(() => {
    if (
      restaurantLoading ||
      diningOptionsLoading ||
      !restaurantData ||
      !diningOptionsData
    ) {
      return {
        loading: restaurantLoading || diningOptionsLoading,
        error: restaurantError || diningOptionsError
      }
    }
    const { diningOptions } = diningOptionsData
    const filteredOptions = diningOptions.filter(
      ({ behavior }) => behavior === TAKE_OUT || behavior === DELIVERY
    )

    const asapMap = filteredOptions.reduce(
      (acc, { asapSchedule, behavior }) => ({
        ...acc,
        [behavior]: asapSchedule.availableNow
      }),
      {}
    )

    const asapAvailable = Boolean(asapMap.TAKE_OUT || asapMap.DELIVERY)

    const futureMap = filteredOptions.reduce(
      (acc, { futureSchedule, behavior }) => {
        const { dates } = futureSchedule
        const available = dates.find(
          ({ timesAndGaps = [] }) => timesAndGaps.length >= 1
        )
        return {
          ...acc,
          [behavior]: Boolean(available)
        }
      },
      false
    )

    const futureDatesAvailable = Boolean(
      futureMap.TAKE_OUT || futureMap.DELIVERY
    )
    const orderingAvailable =
      Boolean(asapAvailable || futureDatesAvailable) &&
      Boolean(restaurantData.restaurant.onlineOrderingEnabled)

    return {
      orderingAvailable,
      availability: {
        [ASAP]: asapMap,
        [FUTURE]: futureMap
      },
      asapAvailable,
      futureDatesAvailable,
      loading: diningOptionsLoading,
      error: diningOptionsError
    }
  }, [
    restaurantLoading,
    restaurantError,
    restaurantData,
    diningOptionsLoading,
    diningOptionsData,
    diningOptionsError
  ])

  const hasTrackedAvailability = React.useRef(false)
  // Track avaialbility
  React.useEffect(() => {
    if (context && context.orderingAvailable !== undefined) {
      // Add property for all future events
      addEventProperties({
        orderingAvailable: context.orderingAvailable
      })
      if (!hasTrackedAvailability.current) {
        hasTrackedAvailability.current = true
        // Explicitly track an event, ensuring the orderingAvailable property
        track('Ordering Availability', {
          orderingAvailable: context.orderingAvailable
        })
      }
    }
  }, [addEventProperties, context, track])

  return (
    <AvailabilityContext.Provider value={context}>
      {children}
    </AvailabilityContext.Provider>
  )
}

AvailabilityProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useAvailability = () => React.useContext(AvailabilityContext)
