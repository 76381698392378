import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'
import lodashUnset from 'lodash/unset'

// Namespaced LocalStorage cache wrapper
export const namespacedStorage = (namespace, storage = localStorage) => {
  if (!namespace) {
    throw new TypeError('namespacedStorage: missing namespace')
  }
  const getMap = () => JSON.parse(storage.getItem(namespace)) || {}
  const setMap = (fn) => {
    const map = getMap()
    fn(map)
    storage.setItem(namespace, JSON.stringify(map))
  }
  return {
    get: (key) => lodashGet(getMap(), key),
    set: (key, value) =>
      setMap((map) => {
        lodashSet(map, key, value)
      }),
    remove: (key) =>
      setMap((map) => {
        lodashUnset(map, key)
      })
  }
}
