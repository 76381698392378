import React from 'react'
import PropTypes from 'prop-types'

import { ErrorModal } from '@local/do-secundo-error-modal'

type OnlineOrderingDisabledModalProps = {
  onClick: PropTypes.func
}

export const OnlineOrderingDisabledModal = ({
  onClick
}: OnlineOrderingDisabledModalProps) => {
  return (
    <ErrorModal
      header='Online ordering temporarily closed'
      message='Contact the restaurant directly to order.'
      buttonLabel='Got it'
      onClick={onClick}
    />
  )
}
