const notNullOrUndefined = (value) => value !== undefined && value !== null

/**
 * Returns the quote time directly from the cart if possible otherwise
 * falling back to the restaurant's minimumTakeoutTime.
 */
export const getQuoteTime = ({ cart, restaurant }) => {
  const value = [
    cart ? cart.quoteTime : null,
    restaurant ? restaurant.minimumTakeoutTime : null
  ].find(notNullOrUndefined)

  return notNullOrUndefined(value) ? value : null
}
