import React, { useCallback, useMemo, useRef } from 'react'
import * as Sentry from '@sentry/browser'
import { NetworkStatus } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import { Cart } from '../Cart/Cart'
import { RestaurantHeader } from '../RestaurantHeader/RestaurantHeader'
import { NavScroller } from '../NavScroller/NavScroller'
import { CartButton } from '@local/do-secundo-cart-button'
import { Dollars } from '@local/do-secundo-dollars'
import { useGetCart } from '../CartQuery/CartQuery'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { getQuoteTime } from '../../utils/quote-time-helpers'
import { useRestoreScrollPosition } from '@toasttab/do-secundo-use-restore-scroll-position'
import { MenuPageError } from './MenuPage.error'
import { MenuPageLayout } from './MenuPageLayout/MenuPageLayout'
import { MenuPageLoader } from './MenuPage.loader'
import style from './MenuPage.module.css'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { lifecycle } from '../../utils/lifecycle'
import { useHasMode } from '../ModeRouter/ModeRouter'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useMenus } from '../../hooks/use-menus'
import { Menus } from '../Menus/Menus'
import cx from 'classnames'
import { FlagOff, FlagOn } from '@local/do-secundo-feature-flag'
import { FF } from '@local/do-secundo-feature-flag'
import { MenuPageOutage } from './MenuPageOutage'

const { toastContainer, toastWrapper } = style

export const MenuPage = () => {
  useRestoreScrollPosition()

  const { restaurantInfo, getRestaurantPath, restaurantGuid, shortUrl } =
    useRestaurant()

  const onCompleted = () => {
    lifecycle.trigger('menuloaded', { restaurantGuid })
  }
  const { fulfillmentTime } = useFulfillment()
  const dateTime = fulfillmentTime || undefined
  const variables = { input: { shortUrl, dateTime } }
  const { loading, error, menus, refetch, networkStatus } = useMenus({
    onCompleted: onCompleted,
    notifyOnNetworkStatusChange: true
  })

  const { error: cartError, loading: cartLoading, cart } = useGetCart()
  const isCartMode = useHasMode('cart')
  const showCartButton =
    !cartError &&
    !cartLoading &&
    !isCartMode &&
    cart?.order?.numberOfSelections > 0

  const retry = () => {
    if (error) refetch()
    if (restaurantInfo.error) {
      restaurantInfo.refetch()
    }
  }

  const activeMenu = useMemo(() => {
    return menus && menus.length
      ? menus.find(({ isActive }) => isActive) || menus[0]
      : { groups: [] }
  }, [menus])
  const allGroups = useMemo(() => {
    if (menus && menus.length) {
      return menus.reduce((acc, { groups }) => [...acc, ...groups], [])
    }
    return []
  }, [menus])
  const availability = useAvailability()
  const restaurantHeaderPublicApi = useRef()

  const calcHeaderOffset = useCallback(async () => {
    const offset = restaurantHeaderPublicApi.current
      ? await restaurantHeaderPublicApi.current.calcHeaderOffset()
      : 0
    return offset
  }, [])

  if (
    loading ||
    availability.loading ||
    networkStatus === NetworkStatus.refetch ||
    restaurantInfo.loading
  ) {
    return <MenuPageLoader />
  }

  if (error || restaurantInfo.error) {
    return (
      <>
        <FlagOff name={FF.MENU_SERVICE_OUTAGE_ERROR}>
          <MenuPageError retry={retry} restaurantInfo={restaurantInfo.data} />
        </FlagOff>
        <FlagOn name={FF.MENU_SERVICE_OUTAGE_ERROR}>
          <MenuPageOutage error={error} restaurantInfo={restaurantInfo.data} />
        </FlagOn>
      </>
    )
  }

  let { orderingAvailable } = availability
  if (!menus || !activeMenu) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning')
      scope.setExtra('queryVars', variables)
      Sentry.captureMessage('Data is null for Menus Query')
    })
    return (
      <>
        <FlagOff name={FF.MENU_SERVICE_OUTAGE_ERROR}>
          <MenuPageError retry={refetch} restaurantInfo={restaurantInfo.data} />
        </FlagOff>
        <FlagOn name={FF.MENU_SERVICE_OUTAGE_ERROR}>
          <MenuPageOutage error={error} restaurantInfo={restaurantInfo.data} />
        </FlagOn>
      </>
    )
  }
  const restaurant = {
    ...restaurantInfo.data.restaurant,
    minimumTakeoutTime: getQuoteTime(restaurantInfo.data)
  }

  return (
    <>
      <NavScroller calcOffsetFn={calcHeaderOffset} items={allGroups}>
        {({ items, activeItem, refMap }) => {
          items = items.filter((item) => item.items && item.items.length > 0)
          return (
            <MenuPageLayout
              header={
                <RestaurantHeader
                  restaurant={restaurant}
                  menusLength={menus ? menus.length : 0}
                  activeMenu={activeMenu}
                  activeItem={activeItem}
                  menus={menus}
                  publicApi={restaurantHeaderPublicApi}
                />
              }
              menus={
                <>
                  <Menus menus={menus} refMap={refMap} menuGroups={items} />
                  <div className={style.footer}>
                    <a
                      href='https://www.toasttab.com/local'
                      aria-label='Toast discover page'
                      className='w-full text-center'
                    >
                      <PoweredByToast className={style.poweredBy} />
                    </a>
                  </div>
                </>
              }
              cart={
                <Cart
                  orderingAvailable={orderingAvailable}
                  checkoutLink={getRestaurantPath('checkout')}
                />
              }
            />
          )
        }}
      </NavScroller>
      <div className={cx(style.cartButton)}>
        {showCartButton && (
          <CartButton
            to={getRestaurantPath('cart')}
            left={<span>View Cart ({cart.order.numberOfSelections})</span>}
            right={
              <span>
                Subtotal: <Dollars amount={cart?.order?.itemsSubtotal} />
              </span>
            }
            showModificationErrors
          />
        )}
      </div>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
      />
    </>
  )
}

// eslint-disable-next-line import/no-default-export
export default MenuPage
