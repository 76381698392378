import * as React from 'react'
import cx from 'classnames'

import { CloseButton } from '@local/do-secundo-closebutton'
import { Link } from '../../../../client/components/Link/Link.jsx'
import { ModeLink } from '../../../../client/components/ModeRouter/utils'
import { PageHeader } from '../../../../client/components/PageHeader/PageHeader.jsx'
import { ScrollToTop } from '../../../../client/components/ScrollToTop/ScrollToTop.jsx'
import { Notification } from '@local/do-secundo-notification'
import {
  passwordlessStorageSet,
  passwordlessStorageGet,
  useIsPasswordlessAuthEnabled,
  useImportStatus
} from '@local/do-secundo-passwordless-authentication'

import styles from './AccountHeader.module.css'

export interface AccountHeaderProps {
  to: string
  title: string
}

export const AccountHeader = ({ to, title }: AccountHeaderProps) => {
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const importStatus = useImportStatus({ skip: !PASSWORDLESS_ENABLED })
  const hasDismissedConnectNotification = passwordlessStorageGet(
    'connectNotificationDismissed'
  )

  const canShowNotification =
    PASSWORDLESS_ENABLED &&
    !hasDismissedConnectNotification &&
    importStatus?.importCount === 0

  return (
    <div
      className={cx(
        styles['account-header'],
        'flex flex-col mx-6 type-default'
      )}
    >
      <ScrollToTop />
      <div className={'flex flex-row items-center justify-between '}>
        <PageHeader header={title} />
        <div className={cx(styles['close-button-wrapper'])}>
          <CloseButton data-testid='close-button-account-page' to={to} />
        </div>
        <div className={styles['close-link']}>
          <Link to={to} data-testid='link-back-to-menu' style='primary'>
            Back to Menu
          </Link>
        </div>
      </div>
      {canShowNotification && (
        <Notification
          isCloseable={true}
          padTop={true}
          onClose={() => {
            passwordlessStorageSet('connectNotificationDismissed', true)
          }}
        >
          <p>Keep your account up to date.</p>
          <ModeLink className={'font-bold hover:underline'} mode='connect'>
            Connect existing account
          </ModeLink>
        </Notification>
      )}
    </div>
  )
}
