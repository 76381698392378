import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Progress } from '@local/do-secundo-progress'

import { useMessage } from '../../utils/use-message'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'

const getOrigin = (src) => {
  const url = new URL(src)
  return url.origin
}

export const CreditCardIframe = ({ src, onChange, window }) => {
  const [height, setHeight] = useState(0)
  const iframeRef = useRef(null)
  const { diningOptionBehavior } = useFulfillment()

  // nuke the CC iframe if diningOptionBehavior(TAKE_OUT/DELIVERY) changed
  useEffect(() => {
    if (iframeRef.current) {
      // easy way to trigger refresh
      iframeRef.current.src = src
    }
  }, [diningOptionBehavior, src])

  useMessage({
    origin: getOrigin(src),
    messageHandlers: {
      CC_VALID: (data) => onChange(data),
      CC_INVALID: () => onChange(''),
      IFRAME_HEIGHT: (data) => setHeight(data)
    },
    window
  })

  return (
    <>
      {height === 0 && <Progress />}
      <iframe
        title='credit-card-form'
        ref={iframeRef}
        data-testid='credit-card-iframe'
        frameBorder='0'
        scrolling='no'
        height={`${height}px`}
        width='100%'
        src={src}
        sandbox='allow-forms allow-scripts allow-same-origin'
      />
    </>
  )
}

CreditCardIframe.propTypes = {
  src: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  window: PropTypes.object
}

CreditCardIframe.defaultValues = {
  window: window // i.e. the global window
}
