import React from 'react'
import PropTypes from 'prop-types'

import { SpecialInstructions, MenuItem } from '@local/do-secundo-modifiers'

import { maxCharacters } from '@local/do-secundo-modifier-form-helpers'

import { MenuItemDetailsShape, ModifierShape } from '@local/do-secundo-model'
import { ModifierGroup } from '../ModifierGroup/ModifierGroup'

export const ModifierBody = ({
  itemDetails,
  isNested = false,
  specialRequestsConfig = { enabled: false },
  deleteButton = null,
  quantityInputField = null,
  onRemoveModifier,
  getSelectedNames = () => []
}) => {
  return (
    <>
      <MenuItem
        nestedModifierName={isNested ? itemDetails.name : undefined}
        calories={itemDetails.calories}
        description={itemDetails.description}
        imageUrl={itemDetails.imageUrl}
        onRemoveModifier={onRemoveModifier}
      />
      {itemDetails.modifierGroups.map((modifierGroup) => (
        <ModifierGroup
          key={modifierGroup.guid}
          modifierGroup={modifierGroup}
          getSelectedNames={getSelectedNames}
        />
      ))}
      {specialRequestsConfig.enabled && (
        <SpecialInstructions
          label='Special Instructions'
          name='specialInstructions'
          maxLength={maxCharacters}
          rows={4}
          placeholder={specialRequestsConfig.placeholderMessage}
        />
      )}
      {quantityInputField}
      {deleteButton}
    </>
  )
}

const specialRequestsConfigShape = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  placeholderMessage: PropTypes.string
})

const itemDetailsShape = PropTypes.oneOfType([
  MenuItemDetailsShape,
  ModifierShape
])

ModifierBody.propTypes = {
  itemDetails: itemDetailsShape,
  deleteButton: PropTypes.node,
  specialRequestsConfig: specialRequestsConfigShape,
  quantityInputField: PropTypes.node,
  getSelectedNames: PropTypes.func.isRequired,
  isNested: PropTypes.bool,
  onRemoveModifier: PropTypes.func
}
