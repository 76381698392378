import * as React from 'react'

/**
 * Helper function which returns a random index number from the provided array
 * @param {array} elem
 * @returns {number}
 */
export const randomIndex = (elem) => Math.floor(Math.random() * elem.length)

/**
 * Helper function which returns an array of
 * random, in-stock items from the provided menu group(s).
 * Uses randomIndex() to select an item from a group.
 * @param {array} menuGroups
 * @returns {array}
 */
export const selectRandomItems = (menuGroups) => {
  return menuGroups.map(({ items }) => {
    const inStockItems = items.filter(({ outOfStock }) => !outOfStock)
    const randomItem = inStockItems[randomIndex(inStockItems)]
    return randomItem
  })
}

/**
 * Custom hook which uses selectRandomItems() to get and set the
 * randomized array of items from selected menu groups
 * @param {array} menuGroups when passed into setRandomMenu()
 * @returns {[
 *  randomMenu: array,
 *  setRandomMenu: function
 * ]}
 */
export const useMenuRandomizer = () => {
  const [randomMenu, setMenu] = React.useState([])
  const setRandomMenu = (menuGroups) => {
    setMenu(selectRandomItems(menuGroups))
  }
  return {
    randomMenu,
    setRandomMenu
  }
}
