import React from 'react'
import { useQuery } from '@apollo/client'

import { AccountHeader } from '@local/do-secundo-account-header'
import { Button, ButtonVariant } from '@local/do-secundo-button'
import { ErrorComponent } from '@local/do-secundo-error'
import { OrdersList } from './OrderList/OrdersList'
import { NoOrders } from './NoOrders/NoOrders'
import { LoadingOrdersList } from './LoadingOrdersList/LoadingOrdersList'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { GET_PAGINATED_ORDERS } from '../../apollo/orders/orders.graphql'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Notification } from '@local/do-secundo-notification'
import styles from './MyOrdersPage.module.css'

export const MyOrdersPage = () => {
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const { loading: availabilityLoading, orderingAvailable } = useAvailability()
  const { data, loading, error, fetchMore } = useQuery(GET_PAGINATED_ORDERS, {
    variables: {
      input: {
        restaurantGuid,
        limit: 10
      }
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  })

  const loadNextBatch = () => {
    fetchMore({
      variables: {
        input: {
          offset: data.paginatedOrders.orders.length,
          limit: 10,
          restaurantGuid
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          paginatedOrders: {
            totalSize: fetchMoreResult.paginatedOrders.totalSize,
            orders: [
              ...prev.paginatedOrders.orders,
              ...fetchMoreResult.paginatedOrders.orders
            ],
            __typename: fetchMoreResult.paginatedOrders.__typename
          }
        })
      }
    })
  }

  const orders = data ? data.paginatedOrders.orders : null
  const shouldShowEmpty = data && data.paginatedOrders.totalSize === 0
  const moreOrders =
    data && data.paginatedOrders.totalSize - data.paginatedOrders.orders.length

  return (
    <div className={styles.page}>
      {!availabilityLoading && !orderingAvailable && (
        <div className={styles.notificationsContainer}>
          <Notification>
            Ordering online is currently not available.
          </Notification>
        </div>
      )}
      <AccountHeader to={getRestaurantPath()} title='My Orders' />
      <div className={styles.orderListContainer}>
        {orders && <OrdersList orders={orders} />}
        {loading && <LoadingOrdersList count={moreOrders} />}
      </div>
      {shouldShowEmpty && <NoOrders />}
      {error && <ErrorComponent />}
      {Boolean(moreOrders) && !loading && (
        <div className={styles.viewMoreButton}>
          <Button
            variant={ButtonVariant.LINK}
            onClick={loadNextBatch}
            id='view-more-button'
          >
            View More
          </Button>
        </div>
      )}
    </div>
  )
}

// eslint-disable-next-line import/no-default-export
export default MyOrdersPage
