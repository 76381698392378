import { useMemo } from 'react'

import { useGetCart } from '../CartQuery/CartQuery'

const NOT_IN_DELIVERY_AREA = 'NOT_IN_DELIVERY_AREA'

const containsWarning = (warnings = []) => {
  return warnings.some((warning) => warning.code === NOT_IN_DELIVERY_AREA)
}

/**
 * Determines if the current cart has a warning due to the current
 * delivery info not being in the restaurant's delivery area.
 *
 * If the cart hasn't yet loaded, we consider it to not have the warning.
 *
 * @returns {UseNotInDeliveryAreaWarningContext}
 */
export const useNotInDeliveryAreaWarning = () => {
  let { warnings } = useGetCart()
  warnings = warnings || []

  let hasWarning = useMemo(() => containsWarning(warnings), [warnings])

  // Note that we don't currently care about the message from the server, but can return
  // it in the future if needed.
  return {
    hasWarning
  }
}

/**
 * @typedef {Object} UseNotInDeliveryAreaWarningContext
 * @property {boolean} hasWarning `true` if the server returned a warning
 *  about the cart's `deliveryInfo` being outside of the restaurant's delivery area
 */
