import React, { useState, useEffect } from 'react'

import { useWindowSize } from '@toasttab/buffet-use-window-size'

export const useNumberOfLines = (text, ref) => {
  const [numberOfLines, setNumberOfLines] = useState(0)
  const windowSize = useWindowSize({})

  useEffect(() => {
    if (text) {
      const numLines = Math.floor(
        parseInt(
          window.getComputedStyle(ref.current, null).getPropertyValue('height')
        ) /
          parseInt(
            window
              .getComputedStyle(ref.current, null)
              .getPropertyValue('line-height')
          )
      )
      setNumberOfLines(numLines)
    }
  }, [windowSize.width, windowSize.height, ref, text])

  return numberOfLines
}
