import React, { createContext, useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'
import { useCompleteReorderMutation } from './useCompleteReorderMutation'
import { REORDER_AND_REPLACE_CART } from '../../../apollo/cart/cart.graphql'

const ReorderContext = createContext({})

export const ReorderProvider = ({ children }) => {
  const { restaurantGuid } = useRestaurant()
  const onCompleteCallback = useCompleteReorderMutation()
  const {
    fulfillmentType,
    fulfillmentTime,
    diningOptionBehavior,
    deliveryInfo
  } = useFulfillment()
  const [reorderGuid, setReorderGuid] = useState(null)
  const [mutate, reorderMutationResult] = useMutation(
    REORDER_AND_REPLACE_CART,
    {
      onCompleted: onCompleteCallback
    }
  )

  const reorder = useCallback(
    (orderGuid) => {
      setReorderGuid(orderGuid)

      return mutate({
        variables: {
          input: {
            orderGuid,
            restaurantGuid,
            orderSource: 'ONLINE',
            cartFulfillmentInput: {
              fulfillmentType,
              fulfillmentDateTime: fulfillmentTime,
              diningOptionBehavior,
              deliveryInfo
            }
          }
        }
      })
    },
    [
      deliveryInfo,
      diningOptionBehavior,
      fulfillmentTime,
      fulfillmentType,
      mutate,
      restaurantGuid
    ]
  )

  const context = {
    reorderGuid,
    reorderMutation: [reorder, reorderMutationResult]
  }

  return (
    <ReorderContext.Provider value={context}>
      {children}
    </ReorderContext.Provider>
  )
}

ReorderProvider.propTypes = {
  children: PropTypes.node.isRequired
}

/**
 * Hook that returns the Reorder context
 * @returns {{ reorderGuid: number, reorderMutation: Function }}
 */
export const useReorder = () => useContext(ReorderContext)
