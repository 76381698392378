import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { ButtonSpacer } from '../ButtonSpacer/ButtonSpacer'

import styles from './ConfirmModal.module.css'

export const ConfirmModal = ({
  header,
  confirmText = 'Confirm',
  onConfirm,
  closeText = 'Cancel',
  onClose,
  hideCancel = false,
  isLoading,
  children,
  responsive = true
}) => {
  const footerButtons = (
    <div className={styles.buttons}>
      <>
        <Button
          data-testid='confirm-modal-confirm-button'
          id='confirm-button'
          type={ButtonType.SUBMIT}
          variant={ButtonVariant.PRIMARY_WIDE}
          onClick={onConfirm}
          loading={isLoading}
        >
          {confirmText}
        </Button>
        <ButtonSpacer vertical />
      </>
      {!hideCancel && (
        <Button
          data-testid='confirm-modal-cancel-button'
          id='cancel-button'
          type={ButtonType.SUBMIT}
          variant={ButtonVariant.LINK_WIDE}
          onClick={onClose}
        >
          {closeText}
        </Button>
      )}
    </div>
  )

  return (
    <Modal
      header={header}
      onClose={onClose}
      footer={footerButtons}
      responsive={responsive}
    >
      <div data-testid='confirm-modal-body' className={styles.modal}>
        {children}
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  children: PropTypes.node,
  closeText: PropTypes.string,
  hideCancel: PropTypes.bool,
  responsive: PropTypes.bool
}
