import React, { useEffect } from 'react'
import { Field } from 'formik'
import { Chip } from '@toasttab/buffet-chip'

import { Input } from '@local/do-secundo-form'
import { useFormik } from '@local/do-secundo-formik-provider'
import { GiftCardPaymentLabel } from './GiftCardPaymentLabel/GiftCardPaymentLabel'
import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'

import styles from './GiftCardPaymentContainer.module.css'
import { getMessage } from '@local/do-secundo-error'

export const GiftCardPaymentContainer = () => {
  const { values, touched, setFieldError, setFieldTouched, submitForm } =
    useFormik()

  const { giftCard, clearGiftCard, error, loading } = useGiftCard()
  const { availableBalance, cardNumber } = giftCard

  useEffect(() => {
    if (error) {
      const message = getMessage(error)
      setFieldError('giftCardNumber', message)
    }
  }, [error, setFieldError])

  if (!values.payWithGiftCard) return null

  // submit promo code on enter press
  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      submitForm()
    }
  }

  const handleFocus = () => {
    // set field touched manually, default happens on blur
    if (!touched['giftCardNumber']) {
      setFieldTouched('giftCardNumber', true)
    }
  }

  /**
   * form.touched.fieldName is not set until input blurs, need to check for field
   * value to enable/disable apply button
   * */
  const isDirty = values.giftCardNumber.replace(/\s/g).length > 5
  const maskFragment = [/\d/, /\d/, /\d/, /\d/, ' ']

  return (
    <div>
      {availableBalance > 0 ? (
        <div className={styles.spacer}>
          <Chip
            canDelete
            onDelete={() => clearGiftCard()}
            text={cardNumber.replace(/(\d{4})/g, '$1 ')}
          />
        </div>
      ) : (
        <Field
          id='gc_number_input'
          component={Input}
          mask={[
            ...maskFragment,
            ...maskFragment,
            ...maskFragment,
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          guide={false}
          type='text'
          name='giftCardNumber'
          label='Enter Gift Card Number'
          value={values.giftCardNumber}
          endAdornment={
            <GiftCardPaymentLabel
              canSubmit={isDirty}
              loading={loading}
              noAdornmentMargin
            />
          }
          autoFocus
          onFocus={handleFocus}
          onKeyDown={handleKeyPress}
        />
      )}
    </div>
  )
}
