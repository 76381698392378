import React from 'react'
import cx from 'classnames'

import { EditIcon } from '@toasttab/buffet-pui-icons'

import { CartIcon } from '../CartIcon/CartIcon'
import { Hamburger } from '../Hamburger/Hamburger'
import { ButtonModeLink, ModeLink } from '../ModeRouter/utils'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useEditMode } from '@local/do-secundo-edit-mode-provider'
import { RestaurantLogo } from '../RestaurantLogo/RestaurantLogo'
import { useGiftCardLinks } from '../use-gift-card-links/use-gift-card-links'
import { Link } from 'react-router-dom'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import GiftCardIcon from '../../assets/gift-card.svg'
import styles from './header.module.css'
import { useRouteMatch } from 'react-router'

import { Button, ButtonVariant } from '@local/do-secundo-button'
import { useVariant } from '../../hooks/use-variant'
import { XP_BRANDED_HEADER_LOGIN_BUTTON } from '../ExperimentsProvider/experiment-definitions'
import { BrandedModeLink } from '../ModeRouter/utils'
import { BUFFET_RESTORE_BUTTON_CLASSNAMES } from '../../styles/restore-buffet-styles'

export const showBrandedLogin = (variant) => {
  switch (variant) {
    case 1:
      return (
        <>
          <ModeLink
            data-testid='header-create-link'
            style='primary'
            mode='create'
            rel='nofollow'
          >
            <span className='type-default'>Create Account</span>
          </ModeLink>
          <span className={styles.spacer} />
          <BrandedModeLink
            testId='header-login-link'
            mode='login'
            rel='nofollow'
            variant='secondary'
            size='base'
            className={styles.branded}
          >
            Log in to Toast Account
          </BrandedModeLink>
        </>
      )
    case 2:
      return (
        <>
          <ModeLink
            data-testid='header-create-link'
            style='primary'
            mode='create'
            rel='nofollow'
          >
            <span className='type-default'>Create Account</span>
          </ModeLink>
          <span className={styles.spacer} />
          <ButtonModeLink
            testId='header-login-link'
            mode='login'
            rel='nofollow'
            variant='secondary'
            size='base'
            className={cx(BUFFET_RESTORE_BUTTON_CLASSNAMES, styles.nonBranded)}
          >
            Log in to Toast Account
          </ButtonModeLink>
        </>
      )
    default:
      return (
        <>
          <ModeLink
            data-testid='header-login-link'
            style='secondary'
            mode='login'
            rel='nofollow'
          >
            <span className='type-default'>Log In</span>
          </ModeLink>
          <span className={styles.spacer} />
          <ModeLink
            data-testid='header-create-link'
            style='primary'
            mode='create'
            rel='nofollow'
          >
            <span className='type-default'>Create Account</span>
          </ModeLink>
        </>
      )
  }
}

export const Header = () => {
  const { getRestaurantPath } = useRestaurant()
  const { authenticated } = useAuth()
  const { purchaseLink } = useGiftCardLinks()
  const { isAuthenticatedManager, isEditing, toggleEditing } = useEditMode()
  const addItem = useRouteMatch('/add/:itemGuid/:itemGroupGuid')
  const menu = useRouteMatch('/')
  const menuPage = addItem || (menu && menu.isExact)
  const loginVariant = useVariant(XP_BRANDED_HEADER_LOGIN_BUTTON.experimentName)
  const accountButtons = showBrandedLogin(loginVariant)
  return (
    <div className={styles.header} role='banner'>
      <Hamburger />
      {menuPage && (
        <>
          <div className={styles.centered}>
            <RestaurantLogo />
          </div>
          <div className={cx(styles.mobile, styles.cart)}>
            <Link to={getRestaurantPath('cart')}>
              <CartIcon showCount showModificationErrors />
            </Link>
          </div>
        </>
      )}
      <div className={styles.desktop}>
        {purchaseLink && (
          <div className={styles.giftCardLink}>
            <GiftCardIcon />
            <a
              className={styles.link}
              href={purchaseLink}
              data-testid='header-purchase-gc-link'
            >
              Buy a Gift Card
            </a>
          </div>
        )}
        {!authenticated && (
          <>
            {isAuthenticatedManager && (
              <div className={'mx-6'}>
                <Button variant={ButtonVariant.LINK} onClick={toggleEditing}>
                  <EditIcon size='xs' /> {isEditing ? 'Exit ' : 'Enter '} Edit
                  Mode
                </Button>
              </div>
            )}
            {purchaseLink && <span className={styles.spacer} />}
            {accountButtons}
          </>
        )}
      </div>
    </div>
  )
}
