/**
 * Helper function of useUpdateSearchParamsAndHistory hook. Updates URL search param at [keyword] with the value of term.
 * Removes URL search param if term is empty. Updates history object to allow going back to the recent search result.
 *
 * @param {Object} history - history object
 * @param {String} term - Search term
 * @param {String} keyword - key of the URL search param to modify
 */
export const updateQueryParams = (history, term, keyword) => {
  const { location } = history
  const searchObj = new URLSearchParams(location.search)
  const encodedTerm = encodeURIComponent(term)
  if (searchObj.has(`${keyword}`)) {
    if (term && encodedTerm !== searchObj.get(`${keyword}`)) {
      searchObj.set(`${keyword}`, encodedTerm)
      history.replace({
        search: searchObj.toString(),
        state: { internal: true }
      })
    } else if (!encodedTerm) {
      searchObj.delete(`${keyword}`)
      history.replace({
        search: searchObj.toString(),
        state: { internal: true }
      })
    }
  } else if (encodedTerm) {
    searchObj.set(`${keyword}`, encodedTerm)
    history.replace({ search: searchObj.toString(), state: { internal: true } })
  }
}
