import * as React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@local/do-secundo-modal'
import { useMenus } from '../../../hooks/use-menus'
import { LoadingModal } from '@local/do-secundo-modifiers'
import { MealSelectorBody } from '../MealSelectorBody/MealSelectorBody'
import { ErrorModal } from '@local/do-secundo-modifiers'

/**
 * Modal wrapper for MealSelectorBody which also handles loading and error states
 * @param {function} onClose
 */
const MealSelectorModal = ({ onClose }) => {
  const { loading, error, menus } = useMenus({})

  if (loading) {
    return <LoadingModal onClose={onClose} />
  }

  if (error || !menus) {
    return <ErrorModal onClose={onClose} error={error} />
  }

  return (
    <Modal
      header={'Random Meal Generator'}
      onClose={onClose}
      wrapper={(children) => <>{children}</>}
    >
      {<MealSelectorBody menus={menus} />}
    </Modal>
  )
}

MealSelectorModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export { MealSelectorModal }
