import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ErrorComponent } from '@local/do-secundo-error'
import { Progress } from '@local/do-secundo-progress'
import { RestaurantAddress } from '../RestaurantAddress/RestaurantAddress'
import styles from './RestaurantInfo.module.css'
import { PageHeader } from '../PageHeader/PageHeader'
import { FulfillmentDisplay } from '../FulfillmentDisplay/FulfillmentDisplay'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { useHistory } from 'react-router'
import { useFulfillmentAvailability } from '../../hooks/use-fulfillment-availability'

export const RestaurantInfo = React.memo(
  ({ header, orderingAvailable, showDeliveryAddress = false }) => {
    const { push: historyPush, location: historyLocation } = useHistory()
    const { restaurantInfo, getRestaurantPath } = useRestaurant()
    const fulfillmentAvailable = useFulfillmentAvailability()

    const [showFulfillmentModal, setShowFulfillmentModal] = useState(false)

    const CHECKOUT_FULFILLMENT_ENABLED = useFlag(FF.CHECKOUT_FULFILLMENT)

    const { loading, error } = restaurantInfo

    if (loading) return <Progress />
    if (error) return <ErrorComponent error={error} />

    const { location, whiteLabelName } = restaurantInfo.data.restaurant

    return (
      <div className={styles.info} role='group' aria-label='restaurant info'>
        <PageHeader header={header} />
        <p className={cx(styles.name, 'type-large')}>{whiteLabelName}</p>
        <RestaurantAddress
          address1={location.address1}
          address2={location.address2}
          city={location.city}
          state={location.state}
        />
        {orderingAvailable ? (
          <>
            {CHECKOUT_FULFILLMENT_ENABLED ? (
              <button
                data-testid={`fulfillment-selector-${historyLocation.pathname}`}
                className={`border-0 bg-transparent text-oo-black-56 block pl-0 my-3 ${
                  fulfillmentAvailable ? 'cursor-pointer' : 'cursor-text'
                }`}
                disabled={!fulfillmentAvailable}
                onClick={() => setShowFulfillmentModal(true)}
              >
                <FulfillmentDisplay
                  showDeliveryAddress={showDeliveryAddress}
                  editable={fulfillmentAvailable}
                />
              </button>
            ) : (
              <div className={styles.fulfillment}>
                <FulfillmentDisplay showDeliveryAddress={showDeliveryAddress} />
              </div>
            )}
            {showFulfillmentModal && CHECKOUT_FULFILLMENT_ENABLED && (
              <FulfillmentSelectorModal
                onClose={() => setShowFulfillmentModal(false)}
                onInvalidAddressClose={() => historyPush(getRestaurantPath())}
              />
            )}
          </>
        ) : null}
      </div>
    )
  }
)

RestaurantInfo.displayName = 'RestaurantInfo'

RestaurantInfo.propTypes = {
  header: PropTypes.string.isRequired,
  showDeliveryAddress: PropTypes.bool
}
