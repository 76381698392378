import * as React from 'react'
import { Fieldset } from '@local/do-secundo-fieldset'
import { ModeLink } from '../../../../client/components/ModeRouter/utils'

export const ConnectAccountFieldset = ({ email = '' }) => {
  return (
    <Fieldset label='Connect Account Info' collapsable>
      <p className='pb-4'>
        Connect your order history and saved payment methods to your Toast
        Account.
      </p>

      <ModeLink style='primaryWide' mode='connect' state={{ email }}>
        Connect account
      </ModeLink>
    </Fieldset>
  )
}
