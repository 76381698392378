import { useEffect } from 'react'
import { useValidateCart } from '@local/do-secundo-cart-mutation'
import { CLEAR_MODIFICATION_ERRORS } from '@local/do-secundo-cart-provider'
import { useCart } from '@local/do-secundo-cart-provider'

export const useValidateCartOnMount = () => {
  const { cartGuid, dispatch } = useCart()
  const response = useValidateCart()
  const [mutate] = response
  useEffect(() => {
    if (cartGuid) {
      dispatch({ type: CLEAR_MODIFICATION_ERRORS })
      mutate({
        variables: {
          input: { cartGuid }
        }
      })
    }
  }, [cartGuid, mutate, dispatch])
  return response
}
