export const getFormattedAddress = (address) => {
  if (!address) return ''
  const { address1, city, state, zipCode } = address
  if (!(address1 && city && state && zipCode)) return ''
  return `${address1}, ${city}, ${state} ${zipCode}`
}

/**
 * Returns a new object picking out only Delivery Info properties from `info`
 * @param {object?} info an object with relevant Delivery Info properties, and possibly other, irrelevant properties
 * @returns {object} a new object with the Delivery Info properties picked from `info`
 */
export const stripDeliveryInfo = (info) => {
  const {
    address1,
    address2,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    notes
  } = info || {}
  return {
    address1,
    address2,
    city,
    state,
    zipCode,
    latitude,
    longitude,
    notes
  }
}
