import React from 'react'

import { Modal } from '@local/do-secundo-modal'
import { ErrorComponent } from '@local/do-secundo-error'

export interface ErrorModalProps {
  onClose(): void
  error?: object
  retry?(): void
}
export const ErrorModal = ({ onClose, error, retry }: ErrorModalProps) => (
  <Modal onClose={onClose}>
    <ErrorComponent error={error} retry={retry} />
  </Modal>
)
