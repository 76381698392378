import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from '@local/do-secundo-formik-provider'

import { Button, ButtonVariant } from '@local/do-secundo-button'

import styles from './GiftCardPaymentLabel.module.css'

export const GiftCardPaymentLabel = ({ canSubmit, loading = false }) => {
  const { submitForm } = useFormik()
  return (
    <div className={styles.giftCardPaymentLabel}>
      <Button
        data-testid='apply-gift-card-button'
        onClick={submitForm}
        disabled={!canSubmit}
        loading={loading}
        variant={ButtonVariant.LINK}
      >
        Apply
      </Button>
    </div>
  )
}

GiftCardPaymentLabel.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  loading: PropTypes.bool
}
