import React from 'react'

import PropTypes from 'prop-types'

import { Mutation } from '@apollo/client/react/components'
import { UPDATE_PASSWORD } from '../../../apollo/customer.graphql'
import { Formik, Form, Field } from 'formik'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Password } from '../../Form/Password/Password'
import { ErrorComponent } from '@local/do-secundo-error'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'

import { object, string, ref } from 'yup'
import { passwordSchema } from '../../../utils/form-schemas'
import { dataByTypename } from '../../../utils/apollo-helpers'
import { toast } from 'react-toastify'

import styles from './UpdatePasswordForm.module.css'

const validationSchema = object().shape({
  oldPassword: passwordSchema,
  newPassword: passwordSchema,
  confirmNewPassword: string()
    .oneOf([ref('newPassword'), null], 'Passwords do not match')
    .required('required')
})

const passwordInputConfigs = [
  {
    autoComplete: 'section-customer old-password',
    id: 'customer_old_password',
    label: 'Password',
    name: 'oldPassword'
  },
  {
    autoComplete: 'section-customer new-password',
    id: 'customer_new_password',
    label: 'New Password',
    name: 'newPassword'
  },
  {
    autoComplete: 'section-customer confirm-new-password',
    id: 'customer_confirm_new_password',
    label: 'Confirm New Password',
    name: 'confirmNewPassword'
  }
]

const initialFormValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

export const UpdatePasswordForm = ({ restaurantGuid = '' }) => {
  return (
    <Mutation mutation={UPDATE_PASSWORD}>
      {(updatePassword) => (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm, setError }) => {
            const input = {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              restaurantGuid
            }

            try {
              const result = await updatePassword({ variables: { input } })
              const { UpdatePasswordError } = dataByTypename(
                result.data.updatePassword
              )

              if (UpdatePasswordError) {
                setError(UpdatePasswordError)
              } else {
                toast('Password Updated Successfully!')
                resetForm(initialFormValues)
              }
            } catch (err) {
            } finally {
              setSubmitting(false)
            }
          }}
          enableReinitialize
        >
          {({ error, isSubmitting, isValid, handleSubmit }) => (
            <Form>
              <Fieldset label='Reset Password' collapsable>
                {passwordInputConfigs.map((config) => (
                  <Field key={config.id} component={Password} {...config} />
                ))}
                <div className={styles.submitWrapper}>
                  {error && <ErrorComponent error={error} />}
                  <Button
                    eventData={{
                      category: 'User Account',
                      action: 'Update Password'
                    }}
                    data-testid='button-submit-customer-password'
                    id={'SubmitCustomerPasswordButton'}
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.SUBMIT}
                    onClick={(event) => {
                      event.preventDefault()
                      handleSubmit()
                    }}
                    loading={isSubmitting}
                    disabled={!isValid}
                    responsive
                  >
                    Update My Password
                  </Button>
                </div>
              </Fieldset>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  )
}

UpdatePasswordForm.propTypes = {
  restaurantGuid: PropTypes.string
}
