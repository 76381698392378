import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { normalize } from 'path'

import { RESTAURANT_INFO } from './graphql/restaurant.graphql'
import { useDataErrorQuery } from '@local/do-secundo-use-data-error-query'

const RestaurantContext = React.createContext({})

export const RestaurantProvider = ({ restaurantGuid, shortUrl, children }) => {
  const getRestaurantPath = (subpath = '') => {
    if (subpath && subpath[0] !== '/') {
      subpath = `/${subpath}`
    }

    return normalize(subpath || '/')
  }

  const context = {
    getRestaurantPath,
    restaurantGuid,
    shortUrl
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

RestaurantProvider.propTypes = {
  children: PropTypes.element.isRequired,
  restaurantGuid: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired
}

export const MockRestaurantProvider = ({
  mocks: { restaurantGuid, shortUrl },
  children
}) => {
  const context = {
    restaurantGuid,
    shortUrl,
    getRestaurantPath: () => shortUrl
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

export const useRestaurant = (opts = {}) => {
  const context = React.useContext(RestaurantContext)
  opts.queryDataErrorFields = ['restaurantV2']
  const resp = useDataErrorQuery(RESTAURANT_INFO, {
    variables: {
      restaurantGuid: context.restaurantGuid
    },
    queryDataErrorFields: ['restaurantV2']
  })
  const restaurantInfo = useMemo(() => {
    if (resp.data) {
      resp.data = {
        restaurant: resp.data.restaurantV2
      }
    }
    return resp
  }, [resp])

  return { ...context, restaurantInfo }
}

export const RestaurantConsumer = ({ children, ...opts }) => {
  const context = useRestaurant(opts)
  return children(context) || null
}

RestaurantConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

MockRestaurantProvider.propTypes = {
  children: PropTypes.element.isRequired,
  mocks: PropTypes.shape({
    restaurantGuid: PropTypes.string,
    shortUrl: PropTypes.string
  }).isRequired
}
