import capitalize from 'lodash/capitalize'

export const deliveryProviderFormatters = {
  doordash: () => 'DoorDash',
  default: (provider) => capitalize(provider)
}

export const formatDeliveryProvider = (provider) => {
  if (!provider) return null
  if (typeof provider !== 'string') return null
  const getter =
    deliveryProviderFormatters[provider.toLowerCase()] ||
    deliveryProviderFormatters.default
  return getter(provider)
}
