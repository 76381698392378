import React from 'react'

import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import { Notification } from '@local/do-secundo-notification'

import { namespacedStorage } from '../../utils/namespaced-storage'

import styles from './WelcomeMessage.module.css'

const welcomeMessageStorage = namespacedStorage('OOWelcomeMessage')
const welcomeMessageViewedKey = 'viewed'

export const welcomeMessageText =
  'We updated our look! We hope you enjoy the improvements we made.'

export const WelcomeMessage = () => {
  const { spaPreviousVersion } = useOOGlobals()

  if (
    !(spaPreviousVersion === 'V2') ||
    welcomeMessageStorage.get(welcomeMessageViewedKey)
  ) {
    return null
  }

  const setViewed = () => {
    welcomeMessageStorage.set(welcomeMessageViewedKey, true)
  }

  return (
    <Notification padTop isCloseable onClose={setViewed}>
      <div className={styles.body}>{welcomeMessageText}</div>
    </Notification>
  )
}
