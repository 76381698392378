import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { OrderingAvailableBanner } from './OrderingAvailableBanner'
import { OrderingNotAvailableBanner } from './OrderingNotAvailableBanner'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { CartIcon } from '../CartIcon/CartIcon'

import styles from './EmptyCart.module.css'

export const EmptyCart = React.memo(({ orderingAvailable, menuLink }) => {
  return (
    <div className={styles.emptyCartWrapper}>
      <div className={cx(styles.emptyCart, 'type-default')}>
        <div className={styles.cartCircle}>
          <CartIcon variant='light' showModificationErrors={false} />
        </div>
        {orderingAvailable ? (
          <OrderingAvailableBanner menuLink={menuLink} />
        ) : (
          <OrderingNotAvailableBanner menuLink={menuLink} />
        )}
      </div>

      <a href='https://www.toasttab.com/local' aria-label='Toast discover page'>
        <PoweredByToast className={styles.poweredBy} />
      </a>
    </div>
  )
})

EmptyCart.displayName = 'EmptyCart'

EmptyCart.propTypes = {
  orderingAvailable: PropTypes.bool
}
