// NOTE: All takeout-web feature flags must start with oo- otherwise they will not work.
const FF = {
  PASSWORDLESS_AUTH_AFTER_CHECKOUT: 'oo-v3-passwordless-auth-after-checkout',
  ENABLE_APPLE_PAY_CREATE_ACCOUNT: 'oo-v3-enable-apple-pay-create-account',
  DEFAULT_TO_DELIVERY: 'oo-default-delivery',
  GLOBAL_GIFTCARDS: 'oo-global-giftcards-enabled',
  SHOW_CHARGE_INFO: 'oo-v3-show-charge-info',
  DAAS_INTEGRATION: 'oo-daas-integration',
  GIFT_CARD_REDEMPTION: 'oo-v3-gift-card-redemption',
  CHECKOUT_FULFILLMENT: 'oo-v3-checkout-fulfillment',
  NV5_SEO: 'oo-v3-nv5-seo',
  MENU_GROUP_DESCRIPTION: 'oo-v3-menu-group-description',
  PIZZA_MENU_MANAGEMENT: 'oo-v3-pizza-menu-management',
  BANNER_IMAGE_FIX: 'oo-v3-banner-image-fix',
  BIRDS_EYE_VIEW: 'oo-v3-birds-eye-view',
  USE_SERVICE_AVAILABILITY: 'oo-v3-use-service-availability',
  ALLOW_APOLLO_RETRY: 'oo-v3-allow-apollo-retry',
  GUEST_FEEDBACK: 'oo-v3-guest-feedback',
  CART_UPSELLS: 'oo-cart-upsell', // non-standard oo-v3 flag, but it's what's used on the back-end to determine if enabled for a restaurant
  MEAL_SELECTOR: 'oo-v3-meal-selector',
  MENU_ITEM_SORT: 'oo-v3-menu-item-sort',
  COMBINED_MENU_GROUPS: 'oo-v3-combined-menu-groups',
  EDIT_MODE: 'oo-v3-edit-mode',
  POPULAR_ITEMS: 'oo-v3-popular-items-carousel',
  SMART_LINKS: 'oo-v3-enable-smart-links',
  PRICE_TRANSPARENCY: 'oo-price-transparency-ux',
  XP_PROMO_BANNERS: 'oo-xp-promo-banners',
  PT_CTA: 'oo-price-transparency-cta',
  MENU_SERVICE_OUTAGE_ERROR: 'oo-menu-service-outage-error',
  ITEM_TAGS: 'oo-item-tags',
  ALCOHOL_ONBOARDING: 'oo-tds-alcohol',
  MENU_BADGES: 'oo-poe-menu-badges',
  OO_CA_FULLSCREEN_CTA: 'oo-ca-fullscreen-cta',
  XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX: 'oo-default-create-account-checkbox',
  XP_BRANDED_HEADER_LOGIN_BUTTON: 'oo-xp-branded-login-buttons',
  TDP_CC_FORM_ENABLED: 'oo-use-tdp-cc-form',
  USE_FUNDRAISING_CONFIG: 'oo-use-fundraising-config'
}

export { FF }
