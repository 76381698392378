import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { byGuid } from '../../utils/find-utils'

import { SavedAddressDisplay } from './SavedAddressDisplay'
import { Select } from '../Select/Select'
import DeliveryIcon from '../../assets/delivery-address.svg'

export const newAddressOption = { isNewAddressOption: true, deliveryInfo: {} }

export const SavedAddressSelector = ({
  form: {
    setFieldValue,
    values: { savedAddressGuid, savedAddresses }
  },
  field: { name }
}) => {
  const initialSelectedItem =
    savedAddresses.find(byGuid(savedAddressGuid)) || newAddressOption

  const onSelectedItemChange = useCallback(
    ({ selectedItem }) => {
      setFieldValue(name, selectedItem.deliveryInfo)
      setFieldValue('savedAddressGuid', selectedItem.guid || '')
    },
    [setFieldValue, name]
  )

  return (
    <Select
      items={savedAddresses.concat([newAddressOption])}
      initialSelectedItem={initialSelectedItem}
      onSelectedItemChange={onSelectedItemChange}
      startAdornment={<DeliveryIcon />}
      getSelectedItemContent={SavedAddressDisplay}
      getOptionContent={SavedAddressDisplay}
    />
  )
}

SavedAddressSelector.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
}
