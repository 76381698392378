import React from 'react'
import Checkmark from '../../../assets/checkmark-circle.svg'
import styles from './FeedbackCompleteBanner.module.css'

export const FeedbackCompleteBanner = () => {
  return (
    <div className={styles.message}>
      <Checkmark className={styles.checkmark} />
      <div className={styles.bannerHeader}>Thank you</div>
      <div className={styles.bannerText}>
        This feedback will help Toast improve the <br /> ordering experience.
      </div>
    </div>
  )
}
