import React from 'react'
import PropTypes from 'prop-types'

import styles from './SmsCommunicationConsent.module.css'

/*
SmsCommunicationConsent is only necessary when we do not need delivery consent
See: OO-3080
*/

const needsSMSConsent = (cart) =>
  cart &&
  (!cart.deliveryProviderInfo ||
    (cart.deliveryProviderInfo &&
      cart.deliveryProviderInfo.needsDeliveryCommunicationConsent === false))

export const SmsCommunicationConsent = ({ cart, consentType }) => {
  const isApplePay = consentType === 'ApplePay'
  return (
    (needsSMSConsent(cart) || isApplePay) && (
      <div className={styles['sms-communication-consent']}>
        <p>
          By providing a mobile number{isApplePay && ' via Apple Pay'}, you give
          Toast permission to contact you using automated text messages to
          provide transactional messages such as order status updates.
        </p>
      </div>
    )
  )
}

SmsCommunicationConsent.propTypes = {
  cart: PropTypes.object,
  consentType: PropTypes.oneOf(['ApplePay'])
}
