/**
 * Accepts buckets weights where the first weight corresponds to the weight of the first variant(0 based count) and returns a variant.
 * Example: Input: 2, 1, 1. Variant 0 - 50%, variant 1 - 25%, variant 2 - 25%.
 * @param  {...number} weights
 * @returns {number} Variant
 */

export const createWeightedBucket = (...weights: number[]): number => {
  const buckets = weights.reduce(
    (acc, val, idx) => [...acc, ...Array.from({ length: val }, () => idx)],
    [] as number[]
  )

  const randomIndex = Math.floor(Math.random() * buckets.length)

  return buckets[randomIndex]!
}

// Basic port of Java's String.hashCode. Not particularly tuned for handling collisions,
// which in this case is not too important
export function hashCode(s: string): number {
  for (var i = 0, h = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
  }
  return Math.abs(h)
}

export const createVariantBucket = (
  numVariants: number,
  string: string
): number => {
  return hashCode(string) % numVariants
}
