import React from 'react'
import cx from 'classnames'

export enum IconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl'
}

export interface IconWithTextProps {
  icon: React.ReactNode
  text: React.ReactNode
  iconContainerClassName?: string
}

export const IconWithText = ({
  icon,
  text,
  iconContainerClassName
}: IconWithTextProps) => {
  return (
    <div
      className={cx(
        'flex flex-col items-center justify-center',
        iconContainerClassName
      )}
    >
      {icon}
      <div className='p-2 text-center text-gray-110'>{text}</div>
    </div>
  )
}
