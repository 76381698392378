import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useWindowSize } from '@toasttab/buffet-use-window-size'

import { StateLink } from '../../StateLink/StateLink'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import { MenuItemPrice } from '@local/do-secundo-menu-item-price'
import { MenuBadge, useGetMenuItemBadge } from '@local/do-secundo-menu-badges'
import { MenuItemImage } from './MenuItemImage/MenuItemImage'

import { useEditMode } from '@local/do-secundo-edit-mode-provider'
import styles from './MenuItems.module.css'
import { imgInPreprod } from '../../../utils/img-url'
import { useVariant } from '../../../hooks/use-variant'
import { XP_PRICE_TRANSPARENCY } from '../../ExperimentsProvider/experiment-definitions'

const MenuItems = ({ items, isExternal }) => {
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)
  const { isEditing } = useEditMode()

  // Unused in code but used to ensure isMobile is correct
  const windowSize = useWindowSize({})
  const isMobile = () => windowSize.width <= 1025

  return (
    <ul data-testid='menu-items' className={styles.menuItems}>
      {items.map((item) => (
        <MenuItem
          item={item.item || item}
          isExternal={isExternal}
          key={item.item?.guid || item.guid}
        />
      ))}
      {isEditing && (
        <li
          data-testid='menu-item'
          key={`new`}
          className={cx(styles.menuItem, 'type-default')}
        >
          <a
            href={`https://${window.location.hostname}/restaurants/admin/menus/menuitem`}
            target='_blank'
            rel='noreferrer'
          >
            <div data-testid='menu-item-content' className='mt-4 ml-4'>
              <span
                id={styles.sizeeighteen}
                data-testid='menu-item-name'
                className={cx(
                  styles.name,
                  isMobile() ? 'type-large font-bold' : 'type-default font-bold'
                )}
              >
                New Item
              </span>
              <p
                data-testid='menu-item-description'
                className={cx(
                  styles.description,
                  isMobile() ? 'type-default' : 'text-subhead'
                )}
                itemProp={nv5SEOEnabled && 'description'}
              >
                Add a new item to this menu group
              </p>
            </div>
          </a>
        </li>
      )}
    </ul>
  )
}

const MenuItem = ({ isExternal, item }) => {
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)
  const { getRestaurantPath } = useRestaurant()
  const { isEditing } = useEditMode()
  const windowSize = useWindowSize({})
  const itemBadge = useGetMenuItemBadge(item.guid)
  const isMobile = () => windowSize.width <= 1025
  const priceTransparencyEnabled = !!useVariant(
    XP_PRICE_TRANSPARENCY.experimentName
  )

  const {
    name,
    guid,
    price,
    outOfStock,
    description,
    calories,
    itemGroupGuid,
    usesFractionalQuantity,
    unitOfMeasure
  } = item
  const imageUrl = imgInPreprod(item.imageUrl)
  const menuItemInner = (
    <div data-testid='menu-item-content' className={styles.content}>
      {itemBadge && !outOfStock && (
        <MenuBadge className={cx('mb-2')} testId='menu-item-badge'>
          {itemBadge.badgeText}
        </MenuBadge>
      )}
      <div className={styles.menuItemInfo}>
        <div
          id={styles.sizeeighteen}
          data-testid='menu-item-name'
          className={cx(styles.name, 'font-bold', {
            'type-large': isMobile(),
            'type-default': !isMobile()
          })}
          itemProp={nv5SEOEnabled && 'name'}
        >
          {name}
        </div>
        <p
          data-testid='menu-item-description'
          className={cx(
            styles.description,
            {
              'text-color-secondary': !outOfStock,
              'text-disabled': outOfStock
            },
            isEditing && description === ''
              ? 'border-solid border-2 border-red-100 rounded-sm pt-2 pl-20'
              : ''
          )}
          itemProp={nv5SEOEnabled && 'description'}
        >
          {isEditing && description === '' ? 'No description' : description}
        </p>
        {price > 0 && !outOfStock && (
          <div className={styles.menuItemPrice}>
            <MenuItemPrice
              price={price}
              usesFractionalQuantity={usesFractionalQuantity}
              unitOfMeasure={unitOfMeasure}
              shouldRenderSchema={nv5SEOEnabled}
            />
          </div>
        )}
        <div className={styles.caloriesAndStock}>
          {outOfStock && (
            <span
              data-testid='menu-item-out-of-stock-label'
              className={cx(styles.outOfStockLabel, 'text-disabled')}
            >
              –Out of stock–
            </span>
          )}
          {calories > 0 && !priceTransparencyEnabled && (
            <span
              data-testid='menu-item-calories'
              className={cx(styles.calories, {
                'text-color-secondary': !outOfStock,
                'text-disabled': outOfStock
              })}
            >
              {calories} Cal
            </span>
          )}
        </div>
      </div>
    </div>
  )
  return (
    <li
      data-testid='menu-item'
      key={`${guid}${itemGroupGuid || ''}`}
      className={cx(styles.menuItem, 'type-default', {
        [styles.outOfStock]: outOfStock
      })}
    >
      {isEditing ? (
        <a
          href={`https://${window.location.hostname}/restaurants/admin/menus/menuitem?guid=${guid}`}
          target='_blank'
          rel='noreferrer'
          className={cx(styles.menuItemLink, {
            'text-color-default': !outOfStock,
            'text-disabled': outOfStock
          })}
        >
          {menuItemInner}

          {nv5SEOEnabled && imageUrl && (
            <meta itemProp='image' content={imageUrl} />
          )}
          <MenuItemImage imageUrl={imageUrl} outOfStock={outOfStock} />
        </a>
      ) : (
        <StateLink
          data-testid='menu-item-link'
          className={cx(
            Boolean(imageUrl)
              ? styles.menuItemLink
              : styles.menuItemLinkNoImage,
            {
              'text-color-default': !outOfStock,
              'text-disabled pointer-events-none': outOfStock
            }
          )}
          to={getRestaurantPath(`add/${guid}/${itemGroupGuid}`)}
          isExternal={isExternal}
          itemProp='hasMenuItem'
          itemScope={nv5SEOEnabled}
          itemType={nv5SEOEnabled && 'https://schema.org/MenuItem'}
        >
          {menuItemInner}

          {nv5SEOEnabled && imageUrl && (
            <meta itemProp='image' content={imageUrl} />
          )}
          <MenuItemImage imageUrl={imageUrl} outOfStock={outOfStock} />
        </StateLink>
      )}
    </li>
  )
}

const MenuItemsShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  price: PropTypes.number
})

MenuItems.propTypes = {
  items: PropTypes.arrayOf(MenuItemsShape),
  isExternal: PropTypes.bool
}

MenuItem.propTypes = {
  item: MenuItemsShape,
  isExternal: PropTypes.bool
}

export { MenuItems, MenuItemsShape }
