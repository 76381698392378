import { FROM_LOCATION } from './enums'
import { History } from 'history'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import {
  showLoggedInNotification,
  User
} from '../../../../client/components/AuthProvider/AuthProvider'
import { isLegacyAccountLoggedIn } from './passwordless-auth-utils'

export interface HandleConfirmPasswordlessLoginResponseProps {
  firstName: string
  handleImportAccount: (arg: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => Promise<void>
  setIsAuthenticated: (auth: boolean) => void
  cancelPasswordlessLoginAndKeepLegacy: () => void
  onClose: () => void
}

export const handleConfirmPasswordlessLoginResponse = async ({
  firstName,
  handleImportAccount,
  setIsAuthenticated,
  cancelPasswordlessLoginAndKeepLegacy,
  onClose
}: HandleConfirmPasswordlessLoginResponseProps) => {
  if (isLegacyAccountLoggedIn()) {
    await handleImportAccount({
      onSuccess: () => {
        setIsAuthenticated(true)
      },
      onError: () => {
        // in the event that import fails we don't want to log the guest into their new account.
        // We want them to continue forward with their legacy account.
        cancelPasswordlessLoginAndKeepLegacy()
      }
    })
  } else {
    setIsAuthenticated(true)
  }
  onClose()
  showLoggedInNotification(firstName)
}

export interface CompletePasswordlessLoginInput {
  variables: {
    input: {
      firstName: string
      lastName: string
      email: string
      source: string
    }
  }
}

interface ProfileRequirements {
  email: string
  firstName: string
  lastName: string
}

export interface ConvertLegacyUserToPasswordlessProps {
  user: User
  completePasswordlessLogin: (input: CompletePasswordlessLoginInput) => void
  history: History
  profileRequirements: ProfileRequirements
  handleImportAccount: (arg: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => Promise<void>
  setIsAuthenticated: (auth: boolean) => void
  cancelPasswordlessLoginAndKeepLegacy: () => void
  onClose: () => void
}

export const convertLegacyUserToPasswordless = async ({
  user,
  completePasswordlessLogin,
  history,
  profileRequirements,
  handleImportAccount,
  setIsAuthenticated,
  cancelPasswordlessLoginAndKeepLegacy,
  onClose
}: ConvertLegacyUserToPasswordlessProps) => {
  const input = {
    variables: {
      input: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        source: 'ONLINE_ORDERING'
      }
    }
  }
  let result

  const pushToCompleteAccountModal = () =>
    history.push('?mode=complete', {
      email: profileRequirements?.email || user?.email,
      firstName: profileRequirements?.firstName || user?.firstName,
      lastName: profileRequirements?.lastName || user?.lastName,
      from: FROM_LOCATION.sms
    })

  try {
    if (!user.firstName || !user.lastName) {
      return pushToCompleteAccountModal()
    }
    result = await completePasswordlessLogin(input)
  } catch (error) {
    return pushToCompleteAccountModal()
  }
  const { CompleteIdentityProfileError } = dataByTypename(
    result.data.completePasswordlessLogin
  )
  if (CompleteIdentityProfileError) {
    return pushToCompleteAccountModal()
  } else {
    await handleImportAccount({
      onSuccess: () => {
        setIsAuthenticated(true)
      },
      onError: () => {
        cancelPasswordlessLoginAndKeepLegacy()
      }
    })

    showLoggedInNotification(profileRequirements?.firstName || user?.firstName)
    onClose()
  }
}
