import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'

import { useAuth } from '../../AuthProvider/AuthProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { LoyaltyAccountCreation } from './LoyaltyAccountCreation/LoyaltyAccountCreation'
import { LoyaltySignup } from './LoyaltySignup/LoyaltySignup'
import { LoyaltyRewardsEarned } from './LoyaltyRewardsEarned/LoyaltyRewardsEarned'
import { LOYALTY_GET_COMPLETED_ORDER } from '../LoyaltyConfirmation.graphql'

export const LoyaltyContent = ({ actionTakenCallback, orderGuid }) => {
  const { authenticated, unverifiedCustomer } = useAuth()
  const { restaurantGuid } = useRestaurant()
  const [hasIntentToEnroll, setIntentToEnrollValue] = useState(false)
  const { data, loading, error } = useQuery(LOYALTY_GET_COMPLETED_ORDER, {
    variables: {
      input: {
        orderGuid,
        restaurantGuid
      }
    }
  })

  const hasValidUnverifiedCustomerSource = [
    'SAVE_CREDIT_CARD',
    'LOYALTY_SIGNUP',
    'CREATE_ACCOUNT_DURING_CHECKOUT'
  ].includes(unverifiedCustomer.signupSource)

  const setIntentToEnroll = useCallback(() => {
    setIntentToEnrollValue(true)
  }, [])

  useEffect(() => {
    if (hasIntentToEnroll && hasValidUnverifiedCustomerSource) {
      actionTakenCallback()
    }
  }, [actionTakenCallback, hasIntentToEnroll, hasValidUnverifiedCustomerSource])

  if (loading || error) return null
  const {
    customerV2,
    hasLoyaltyAttached,
    tip,
    total,
    serviceChargeTotal,
    deliveryChargeTotal,
    restaurant: {
      loyaltyConfig: { loyaltySignupEnabled }
    }
  } = data.completedOrder

  const hasAccount = authenticated || hasValidUnverifiedCustomerSource
  const canSignup = loyaltySignupEnabled && customerV2

  if (hasLoyaltyAttached && hasAccount) {
    if (
      total === 0 ||
      total === tip + serviceChargeTotal + deliveryChargeTotal
    ) {
      return null
    }
    return <LoyaltyRewardsEarned />
  }

  if (!canSignup) {
    return null
  }

  if (hasAccount && !hasLoyaltyAttached) {
    return (
      <LoyaltySignup
        signupOnMount={hasIntentToEnroll && authenticated}
        actionTakenCallback={actionTakenCallback}
        orderGuid={orderGuid}
      />
    )
  }

  return (
    <LoyaltyAccountCreation
      setIntentToEnroll={setIntentToEnroll}
      orderGuid={orderGuid}
    />
  )
}

LoyaltyContent.propTypes = {
  actionTakenCallback: PropTypes.func.isRequired,
  orderGuid: PropTypes.string.isRequired
}
