import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { Radio } from '@local/do-secundo-form'

import styles from './RadioGroup.module.css'

export const RadioGroup = ({ group, name, style }) => (
  <div className={styles.radioGroup} role='group' aria-labelledby='radio-group'>
    {group.map(({ value, label }, index) => (
      <Field
        key={`${name}-${index}`}
        id={value}
        name={name}
        value={value}
        label={label}
        component={Radio}
        style={style}
      />
    ))}
  </div>
)

RadioGroup.propTypes = {
  group: PropTypes.array,
  name: PropTypes.string,
  style: PropTypes.string
}
