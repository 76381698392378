import React from 'react'
import cx from 'classnames'

import styles from './LegalCopy.module.css'

export const LegalCopy = ({ className }) => {
  return (
    <p
      className={cx(styles.legal, 'type-default', className)}
      data-testid='legal-links'
      role='group'
      aria-label='legal information'
    >
      The information you provide will be processed pursuant to Toast's{' '}
      <a
        href='https://pos.toasttab.com/terms-of-service/#diner-tos'
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-terms-of-service'
      >
        Diner Terms of Service
      </a>{' '}
      and{' '}
      <a
        href='https://pos.toasttab.com/privacy'
        rel='noopener noreferrer'
        target='_blank'
        data-testid='link-privacy'
      >
        Privacy Statement
      </a>
    </p>
  )
}
