import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './PageHeader.module.css'

export const PageHeader = ({ header }) => (
  <h2 id={styles.fontoverride} className={cx(styles.header, 'type-headline-2')}>
    {header}
  </h2>
)

PageHeader.propTypes = {
  header: PropTypes.string.isRequired
}
