import React from 'react'
import cx from 'classnames'
import {
  ReorderIcon,
  CreditIcon,
  LightningIcon,
  CheckCircleSelectedDisplayIcon
} from '@toasttab/buffet-pui-icons'
import PoweredByToast from '../../../../client/assets/powered-by/grey-horizontal.svg'

import {
  IconWithText,
  IconWithTextProps,
  IconSize
} from '@local/do-secundo-icon-with-text'
import styles from './AccountBenefits.module.css'

export const defaultIcons = [
  {
    icon: (
      <CreditIcon size={IconSize.MD} className='fill-current text-brand-50' />
    ),
    text: <p className='text-caption'>Quick checkout</p>
  },
  {
    icon: (
      <LightningIcon
        size={IconSize.MD}
        className='fill-current text-brand-50'
      />
    ),
    text: (
      <>
        <p className='text-caption'>Easy</p>
        <p className='text-caption'>login</p>
      </>
    )
  },
  {
    icon: <ReorderIcon size={IconSize.MD} className='text-brand-50' />,
    text: <p className='text-caption'>Reorder favorites</p>
  }
]
export interface AccountBenefitsProps {
  testId?: string
  header?: React.ReactNode
  info?: React.ReactNode
  icons?: IconWithTextProps[]
  message?: React.ReactNode
  accountBenefitsContainerClassName?: string
}

export const AccountBenefits = ({
  testId = 'account-benefits',
  header = <h2 className={'mt-3 type-headline-5'}>Congrats!</h2>,
  info = 'Your Toast Account has been created!',
  icons = defaultIcons,
  message = 'Your Toast Account works across all restaurants powered by Toast.',
  accountBenefitsContainerClassName
}: AccountBenefitsProps) => {
  // added so we can accommodate equal spacing between icons
  // flexbox's calculation didnt make use of entire space available
  const iconWidth = `${100 / icons.length}%`

  return (
    <div
      data-testid={testId}
      className={cx(
        'rounded flex flex-col items-center',
        styles.accountBenefitsContainer,
        accountBenefitsContainerClassName
      )}
    >
      <div className='px-5 pt-6 text-lg text-center'>
        <CheckCircleSelectedDisplayIcon />
      </div>
      {header}
      <div className='px-5 pt-6 text-center type-default text-oo-black-87'>
        {info}
      </div>
      <ul className='flex items-start justify-between px-0 my-6 font-bold'>
        {icons.map((icon, i) => {
          return (
            <li
              key={`${i}-account-benefit`}
              style={{ width: iconWidth }}
              className='list-none'
            >
              <IconWithText {...icon} />
            </li>
          )
        })}
      </ul>
      <div className='w-9/12 text-center type-subhead text-oo-black-87'>
        {message}
      </div>
      <div className='my-5 text-center'>
        <PoweredByToast className='w-1/2' />
      </div>
    </div>
  )
}
