import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { formatPhoneNumber } from '../../utils/phone-number'
import styles from './GuestCommunication.module.css'

export const GuestCommunication = ({
  curbsideSelected,
  guestCommunication = '',
  phone
}) => {
  guestCommunication = !!guestCommunication && guestCommunication.trim()
  if (guestCommunication && guestCommunication.length) {
    return (
      <p
        data-testid='guest-communication-text'
        className={cx(styles.text, styles.dark)}
      >
        {guestCommunication}
      </p>
    )
  }
  if (curbsideSelected) {
    return (
      <div>
        <p className={styles.call}>Call upon arrival</p>
        <a href={`tel:${phone}`} className={styles.phone}>
          {formatPhoneNumber(phone)}
        </a>
      </div>
    )
  }
  return null
}

GuestCommunication.propTypes = {
  curbsideSelected: PropTypes.bool.isRequired,
  guestCommunication: PropTypes.string,
  phone: PropTypes.string
}
