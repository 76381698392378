import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import isEmpty from 'lodash/isEmpty'

import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'

import styles from './FulfillmentDisplay.module.css'
import { getFormattedDiningOption } from '../FulfillmentSelectorModal/fulfillment-helpers'
import { AsapEstimate } from '../AsapEstimate/AsapEstimate'
import { getFormattedAddress } from '../../utils/address-helpers'
import { FutureEstimate } from '../FutureEstimate/FutureEstimate'
import { DeliveryProviderLabel } from '../DeliveryProviderLabel/DeliveryProviderLabel'
import Pencil from '../../assets/pencil.svg'
import { useAvailability } from '@local/do-secundo-availability-provider'
import {
  checkIfRestaurantIsOpen,
  createOnlineOrderingDaySchedule
} from '../../utils/restaurant-schedule-helpers'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

// Fulfillment display bar (which will open modal upon click as well as prior to having fulfillment selected e.g. on first menu load)
export const FulfillmentDisplay = ({
  editable,
  showDeliveryAddress = false,
  inline = false,
  centered = false
}) => {
  const { orderingAvailable } = useAvailability()
  const {
    fulfillmentType,
    diningOptionBehavior,
    deliveryInfo,
    deliveryProvider
  } = useFulfillment()
  const {
    restaurantInfo: { data, error, loading }
  } = useRestaurant()
  const formattedDiningOption = getFormattedDiningOption(diningOptionBehavior)

  const ASAP = () => (
    <span data-testid='fulfillment-asap-ready-time' className={styles.emphasis}>
      now&nbsp;(
      <AsapEstimate diningOptionBehavior={diningOptionBehavior} />)
    </span>
  )

  if (loading || error) return null

  const {
    restaurant: { schedule, onlineOrderingEnabled, timeZoneId }
  } = data

  if (!onlineOrderingEnabled) {
    return (
      <div className={cx(styles.fulfillmentDisplay, styles.unavailable)}>
        We're not accepting online orders at this time, but feel free to browse
        our menu.
      </div>
    )
  }

  if (!orderingAvailable) {
    const { upcomingSchedules } = schedule
    const onlineOrderingDaysSchedule =
      createOnlineOrderingDaySchedule(upcomingSchedules)
    const isRestaurantCurrentlyOpen = checkIfRestaurantIsOpen(
      onlineOrderingDaysSchedule,
      timeZoneId
    )
    return (
      <div className={cx(styles.fulfillmentDisplay, styles.unavailable)}>
        {isRestaurantCurrentlyOpen
          ? "We're not accepting online orders at this time, but feel free to browse our menu."
          : "We're currently closed, but feel free to browse our menu."}
      </div>
    )
  }

  const color = editable ? 'text-primary-75' : 'text-default'
  const emphasis = `type-default font-bold ${color}`
  return (
    <div
      className={cx(styles.fulfillmentDisplay, 'type-default', {
        [styles.editable]: editable,
        [styles.leftAlign]: editable
      })}
    >
      <div
        className={cx(styles.fulfillmentInfo, {
          [styles.centered]: centered
        })}
      >
        <div
          className={cx(styles.fulfillmentRows, { [styles.inline]: inline })}
        >
          <div className={cx(styles.fulfillmentRow, 'type-default')}>
            <span
              data-testid='fulfillment-type'
              className={cx(emphasis, 'type-default')}
            >
              {formattedDiningOption}
            </span>
            &nbsp;for&nbsp;
            {fulfillmentType === 'ASAP' ? (
              <ASAP />
            ) : (
              <FutureEstimate editable={editable} />
            )}
            {editable && <Pencil className={styles.pencil} />}
          </div>
          {showDeliveryAddress && !isEmpty(deliveryInfo) && (
            <div className={styles.fulfillmentRow}>
              to&nbsp;
              <span className={emphasis}>
                {getFormattedAddress(deliveryInfo)}
              </span>
            </div>
          )}
          <DeliveryProviderLabel deliveryProvider={deliveryProvider} />
        </div>
      </div>
    </div>
  )
}

FulfillmentDisplay.propTypes = {
  editable: PropTypes.bool,
  showDeliveryAddress: PropTypes.bool,
  centered: PropTypes.bool,
  inline: PropTypes.bool
}
