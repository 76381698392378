import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { BUFFET_RESTORE_BUTTON_CLASSNAMES } from '../../../styles/restore-buffet-styles'
import { useAuth } from '../../AuthProvider/AuthProvider'
import { BrandedModeLink, ButtonModeLink } from '../../ModeRouter/utils'

export const NavigationDrawerHeaderUnified = ({ onClose }) => {
  const { user, authenticated } = useAuth()

  return authenticated ? (
    <h2
      data-testid='unified-drawer-header-user-name'
      className='type-headline-4 font-normal mb-1'
    >
      Hi{user?.firstName ? ` ${user.firstName}` : ''}!
    </h2>
  ) : (
    <>
      <h2
        data-testid='unified-drawer-header'
        className='type-headline-4 font-normal mb-1'
      >
        Welcome!
      </h2>
      <p
        data-testid='unified-drawer-text-after-header'
        className='type-default text-default mb-4'
      >
        Reorder your favorites and quickly checkout at any restaurant powered
        <br />
        by Toast
      </p>
      <div className='flex flex-col justify-center items-stretch'>
        <BrandedModeLink
          testId='unified-drawer-button-login'
          onClick={onClose}
          mode='login'
          rel='nofollow'
        >
          Log in to Toast Account
        </BrandedModeLink>
        <div className='my-4 text-center text-secondary font-semibold'>
          &mdash; OR &mdash;
        </div>
        <ButtonModeLink
          testId='unified-drawer-button-create-account'
          onClick={onClose}
          mode='create'
          rel='nofollow'
          variant='secondary'
          size='base'
          className={cx('mb-4', BUFFET_RESTORE_BUTTON_CLASSNAMES)}
        >
          Create a Toast Account
        </ButtonModeLink>
      </div>
      <p
        data-testid='unified-drawer-text-after-buttons'
        className='type-default text-default'
      >
        Creating an account is quick and easy for future orders.
      </p>
    </>
  )
}

NavigationDrawerHeaderUnified.propTypes = {
  onClose: PropTypes.func
}
