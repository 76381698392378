import React from 'react'
import SentimentSatisfied from '../../assets/sentimentSatisfied.svg'
import SentimentDissatisfied from '../../assets/sentimentDissatisfied.svg'
import SentimentPassive from '../../assets/sentimentPassive.svg'

// score options aka smiley faces
export const NEGATIVE = 'NEGATIVE'
export const PASSIVE = 'PASSIVE'
export const POSITIVE = 'POSITIVE'

// feedback smiley face reactions for RadioGroup
const feedbackGroup = [
  {
    value: NEGATIVE,
    label: <SentimentDissatisfied />
  },
  {
    value: PASSIVE,
    label: <SentimentPassive />
  },
  {
    value: POSITIVE,
    label: <SentimentSatisfied />
  }
]

// tag options
const tags = {
  MENU: 'Menu',
  ACCOUNT: 'Account',
  PAYMENT: 'Payment',
  RESTAURANT_FEEDBACK: 'Restaurant feedback',
  OTHER: 'Other'
}

const tagOptions = Object.values(tags)
const scores = [NEGATIVE, PASSIVE, POSITIVE]

export { feedbackGroup, scores, tagOptions, tags }
