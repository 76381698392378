import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@local/do-secundo-modal'

import { ModifiersLoader } from './Modifiers.loader'

export const LoadingModal = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <ModifiersLoader />
    </Modal>
  )
}

LoadingModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
