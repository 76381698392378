import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import PaperPlane from '../../../assets/paper-plane.svg'

import styles from './CreateAccountSuccessModal.module.css'

export const CreateAccountSuccessModal = ({ email, onClose }) => {
  return (
    <PoweredByToastModal onClose={onClose}>
      <div className={styles.centered}>
        <PaperPlane className={styles.plane} />
        <h2 className='type-headline-4'>Verify Your Account</h2>
        <p className={styles.body}>
          We sent an email to {email}.
          <br />
          Please verify your email to complete your account creation.
        </p>
        <Button
          data-testid='modal-verify-ok-button'
          variant={ButtonVariant.LINK_WIDE}
          type={ButtonType.BUTTON}
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </PoweredByToastModal>
  )
}

CreateAccountSuccessModal.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
