import React from 'react'
import PropTypes from 'prop-types'

import { NewAddressOption } from './AddressOptions/NewAddressOption'
import { SavedAddressOption } from './AddressOptions/SavedAddressOption'

export const SavedAddressDisplay = ({ isNewAddressOption, ...address }) =>
  isNewAddressOption ? (
    <NewAddressOption />
  ) : (
    <SavedAddressOption {...address} />
  )

const SavedAddressShape = PropTypes.shape({
  guid: PropTypes.string.isRequired,
  name: PropTypes.string,
  deliveryInfo: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    notes: PropTypes.string
  })
})

SavedAddressDisplay.propTypes = {
  isNewAddressOption: PropTypes.bool.isRequired,
  address: SavedAddressShape
}
