import { ApolloError } from '@apollo/client'

/**
 * An error for password based authentication to indicate
 * the session could not be resumed and the guest should be in a logged
 * out state.
 */
export class ResumeSessionError extends Error {
  shouldToastLog: boolean
  constructor(msg: String, cause: unknown) {
    super(`Session could not be resumed: ${msg}`)
    this.name = 'ResumeSessionError'
    this.cause = cause
    this.shouldToastLog = false // ResumeSessionError is a natural error of a guest's tokens expiring so keep it silent
  }
  static isError(error: Error) {
    return (
      error instanceof ResumeSessionError ||
      (error instanceof ApolloError &&
        error.networkError instanceof ResumeSessionError)
    )
  }
}
