import { useGetCart } from '../../CartQuery/CartQuery'
import { useFindMenuItems } from '@local/do-secundo-use-find-menu-items'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import {
  CompletedOrderSelection,
  Selection
} from '../../../apollo/generated/TakeoutWebGraphQLOperations'

export const useCartContainsAlcohol = (
  selections?: Array<CompletedOrderSelection | Selection>
): boolean => {
  const { cart } = useGetCart() as any
  const restaurant = useRestaurant() as any

  const getMenuItemInfo = () => {
    if (selections) {
      return selections.map((selection) => ({
        itemGuid: selection.itemGuid,
        groupGuid: selection.itemGroupGuid
      }))
    }
    if (cart) {
      return (
        cart.order?.selections?.map((selection: Selection) => ({
          itemGuid: selection.itemGuid,
          groupGuid: selection.itemGroupGuid
        })) ?? []
      )
    }
    return []
  }

  const { data } = useFindMenuItems({
    items: getMenuItemInfo(),
    restaurantGuid: restaurant.restaurantGuid
  })

  const items = data?.doMenus_findMenuItems?.items

  return items
    ? items.some(
        (info: any) =>
          info?.menuItem.contentAdvisories.alcohol?.containsAlcohol === 'YES'
      )
    : false
}
