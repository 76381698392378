import React from 'react'
import cx from 'classnames'

import OrdersEmpty from '../../../assets/orders-empty.svg'
import styles from '../MyOrdersPage.module.css'

export const NoOrders = () => (
  <div className={styles.emptyNotification}>
    <OrdersEmpty />
    <h3 className={cx(styles.emptyNotificationHeader, 'type-headline-5')}>
      Place your first order!
    </h3>
    <h4 className={cx(styles.emptyNotificationInfo, 'type-large')}>
      Once an order is complete, it will be listed here.
    </h4>
  </div>
)
