import * as React from 'react'

import { passwordlessStorageSet } from './passwordless-auth-storage'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { IMPORT_ACCOUNT, IMPORT_STATUS } from '../../index'
import { useMutation } from '@apollo/client'
import {
  deleteTokens,
  accountLinkingStorage
} from '../../../../client/apollo/authentication/authentication-helpers'
import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'

/**
 * Returns a mutation function that links customermgmt accounts with passwordless accounts
 * and adds localstorage key/value which controls visibility of the alert to connect accounts
 * on an account page
 *
 */
export const useImportAccount = ({ keepLegacyLoginOnError = false } = {}) => {
  const [importAccount] = useMutation(IMPORT_ACCOUNT, {
    refetchQueries: [{ query: IMPORT_STATUS }],
    awaitRefetchQueries: true
  })
  const { accountDataStatus } = useAuth()
  return async ({
    onSuccess,
    onError
  }: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => {
    const importResult = await importAccount()
    const { ImportAccountResponse, ImportAccountError } = dataByTypename(
      importResult.data.importAccount
    )

    try {
      !keepLegacyLoginOnError && window.localStorage.removeItem('auth')
    } catch (err) {}

    if (ImportAccountResponse) {
      accountDataStatus.setImportTriggered(true)

      try {
        keepLegacyLoginOnError && window.localStorage.removeItem('auth')
      } catch (err) {}

      passwordlessStorageSet('connectNotificationDismissed', true)
      onSuccess && onSuccess()
    } else if (ImportAccountError) {
      onError && onError(ImportAccountError)
    }
    deleteTokens(accountLinkingStorage)
  }
}
