import { BatchHttpLink } from '@apollo/client/link/batch-http'

export const getHttpLink = (config) => {
  const headers = config.RESTAURANT_GUID
    ? {
        'Toast-Restaurant-External-ID': config.RESTAURANT_GUID
      }
    : null
  return new BatchHttpLink({
    uri: `${config.BFF_BASE_URI}/graphql`,
    credentials: 'same-origin',
    headers
  })
}
