import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react'
import PropTypes from 'prop-types'

import * as AnimationStates from './panel-animation-states'

const PanelContext = createContext({
  stack: [],
  push: () => {},
  pop: () => {},
  isAnimating: false,
  animationState: AnimationStates.ZERO_STATE,
  setAnimationState: () => {},
  isEntering: true
})

/**
 * Handles animation states for the panel stack. The provider
 * determines if the actual state gets updated and if the stack gets
 * rerendered. If a push or pop happens while an animation is in
 * progress, state will reset. State transition is as follows:
 *
 *               /-> push -> enter_start -> enter_finish \
 *  zero_state -                                          -> complete
 *               \-> pop -> leave_start -> leave_finish /
 *
 * At any state a push or pop can reset the state loop. To avoid
 * infinite state update, only push or pop can start the cycle.
 * Every other state will eventually reach the resting complete state.
 */
export const PanelProvider = ({ children }) => {
  const [stackState, updateStackState] = useState([])
  const animationStateRef = useRef(AnimationStates.ZERO_STATE)
  const [isEnterAnimating, setIsEnterAnimating] = useState(false)

  // used to store scroll position of "current" panel before pushing a new one
  const panelBodyRef = useRef(null)

  // Tight control over render cycles is needed so we use
  // forceUpdate({}) to guarantee renders.
  const [, forceUpdate] = useState({})

  const setAnimationState = useCallback(
    (state) => {
      switch (state) {
        case AnimationStates.PUSH:
        case AnimationStates.POP:
          animationStateRef.current = state
          // stops animation until new animation callback is triggered
          setIsEnterAnimating(false)
          forceUpdate({})
          break
        case AnimationStates.ENTER_START:
          if (animationStateRef.current === AnimationStates.PUSH) {
            // Start of the main leave animation
            animationStateRef.current = state
            setIsEnterAnimating(true)
            forceUpdate({})
          }
          break
        case AnimationStates.LEAVE_START:
          if (animationStateRef.current === AnimationStates.POP) {
            // Start of the main leave animation
            animationStateRef.current = state
            setIsEnterAnimating(false)
            forceUpdate({})
          }
          break
        case AnimationStates.ENTER_FINISH:
          if (animationStateRef.current === AnimationStates.ENTER_START) {
            animationStateRef.current = state
            // When the main animation finishes stop all other animations
            setIsEnterAnimating(false)
            forceUpdate({})
          }
          break
        case AnimationStates.LEAVE_FINISH:
          if (animationStateRef.current === AnimationStates.LEAVE_START) {
            animationStateRef.current = state
            // When the main animation finishes stop all other animations
            setIsEnterAnimating(false)
            forceUpdate({})
          }
          break
        case AnimationStates.COMPLETE:
          if (
            animationStateRef.current === AnimationStates.ENTER_FINISH ||
            animationStateRef.current === AnimationStates.LEAVE_FINISH
          ) {
            animationStateRef.current = state
            // Rerender isn't needed.
          }
          break
        default:
          throw new Error(`Animation state '${state}' is unknown`)
      }
    },
    [animationStateRef]
  )

  const animationState = animationStateRef.current
  const push = useCallback(
    (children) => {
      updateStackState((stack) => {
        // save scroll position from the ref
        const currentPanel = stack[stack.length - 1]
        if (currentPanel && panelBodyRef.current) {
          currentPanel.scrollTop = panelBodyRef.current.scrollTop || 0
        }
        return [...stack, { children, key: stack.length }]
      })
      setAnimationState(AnimationStates.PUSH)
    },
    [setAnimationState]
  )

  const pop = useCallback(() => {
    updateStackState((stack) => stack.slice(0, stack.length - 1))
    setAnimationState(AnimationStates.POP)
  }, [setAnimationState])

  const context = {
    stack: stackState,
    push,
    pop,
    isEnterAnimating,
    animationState,
    setAnimationState,
    panelBodyRef
  }

  return (
    <PanelContext.Provider value={context}>{children}</PanelContext.Provider>
  )
}

PanelProvider.propTypes = {
  children: PropTypes.any
}

export const PanelConsumer = ({ children }) => {
  return (
    <PanelContext.Consumer>
      {(context) => children(context)}
    </PanelContext.Consumer>
  )
}

PanelConsumer.propTypes = {
  children: PropTypes.func
}

export const usePanelStack = () => useContext(PanelContext)
