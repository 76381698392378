import React from 'react'

import { SavedCreditCardPicker } from '../../SavedCreditCardPicker/SavedCreditCardPicker'
import { Fieldset } from '@local/do-secundo-fieldset'
import { Field, Formik, Form } from 'formik'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { useCreditCard } from '../../CreditCardProvider/CreditCardProvider'
import { ErrorComponent, logError } from '@local/do-secundo-error'

import { getPrimaryCreditCard } from '@local/do-secundo-form-utils'

import styles from './SavedCreditCardForm.module.css'
import { toast } from 'react-toastify'

export const SavedCreditCardForm = () => {
  const { creditCards = [], makePrimary } = useCreditCard()
  const savedCardGuid = getPrimaryCreditCard(creditCards).guid || ''

  return (
    <Fieldset label={'Payment Options'} collapsable>
      <Formik
        initialValues={{ savedCardGuid }}
        onSubmit={async (values, { setSubmitting }) => {
          const input = {
            cardGuid: values.savedCardGuid
          }

          try {
            await makePrimary.mutate({ variables: { input } })
            toast('Primary Card Updated Successfully!')
          } catch (err) {
            logError(err)
          } finally {
            setSubmitting(false)
          }
        }}
        enableReinitialize
      >
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <Form>
              <Field
                component={SavedCreditCardPicker}
                name='savedCardGuid'
                creditCards={creditCards}
                isDeletable
              />
              <div className={styles.submitWrapper}>
                {makePrimary.result.error && (
                  <ErrorComponent error={makePrimary.result.error} />
                )}
                <Button
                  eventData={{
                    category: 'User Account',
                    action: 'Make Primary Credit Card'
                  }}
                  data-testid='button-submit-primary-credit-card'
                  id='SubmitPrimaryCreditCardButton'
                  variant={ButtonVariant.PRIMARY}
                  type={ButtonType.SUBMIT}
                  onClick={(event) => {
                    event.preventDefault()
                    handleSubmit()
                  }}
                  loading={isSubmitting}
                  disabled={!dirty || !isValid}
                  responsive
                >
                  Update My Primary Card
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Fieldset>
  )
}
