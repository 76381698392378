import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

const FormikContext = React.createContext({})

export const FormikProvider = ({ children, ...props }) => {
  return (
    <Formik {...props}>
      {(formikProps) => (
        <FormikContext.Provider value={formikProps}>
          {typeof children === 'function' ? children(formikProps) : children}
        </FormikContext.Provider>
      )}
    </Formik>
  )
}

export const useFormik = () => useContext(FormikContext)

FormikProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
}
