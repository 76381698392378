import { useMemo } from 'react'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useGetCart } from '../CartQuery/CartQuery'

const { DELIVERY } = DINING_OPTION_BEHAVIORS

const handleCartResponse = (data) => {
  const { CartResponse } = dataByTypename(data.cartV2)
  if (!CartResponse) return {}

  const { deliveryQuoteTime, takeoutQuoteTime } = CartResponse.cart
  return {
    deliveryTime: deliveryQuoteTime,
    takeoutTime: takeoutQuoteTime
  }
}
const handleRestaurantResponse = (data) => {
  const { minimumDeliveryTime, minimumTakeoutTime } = data.restaurant
  return {
    deliveryTime: minimumDeliveryTime,
    takeoutTime: minimumTakeoutTime
  }
}

export const useQuoteTime = ({ diningOptionBehavior }) => {
  const { restaurantInfo } = useRestaurant()
  const { cartGuid, ...cartInfo } = useGetCart()

  const response = cartGuid ? cartInfo : restaurantInfo

  const { deliveryTime, takeoutTime } = useMemo(() => {
    const { data, loading } = response
    if (loading || !data) return {}
    const handler = cartGuid ? handleCartResponse : handleRestaurantResponse
    return handler(data)
  }, [cartGuid, response])
  const isDelivery = diningOptionBehavior === DELIVERY

  return {
    time: isDelivery ? deliveryTime : takeoutTime,
    isDelivery,
    ...response
  }
}
