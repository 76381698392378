import React from 'react'
import { Formik, Field, Form } from 'formik'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { AddressAutocomplete } from '../../../AddressAutocomplete/AddressAutocomplete'
import { savedAddressSchema } from '../../../../utils/form-schemas'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { CloseButton } from '@local/do-secundo-closebutton'
import { dataByTypename } from '../../../../utils/apollo-helpers'
import { DeleteSavedAddress } from '../DeleteSavedAddress/DeleteSavedAddress'
import { ErrorComponent } from '@local/do-secundo-error'
import { Input } from '@local/do-secundo-form'
import { stripDeliveryInfo } from '../../../../utils/address-helpers'
import { useUpdateSavedAddress } from '../../../use-saved-addresses/use-saved-addresses'

import styles from './SavedAddressEditor.module.css'

export const SavedAddressEditor = ({ savedAddress, onClose }) => {
  const [updateMutation] = useUpdateSavedAddress()

  const onSuccess = ({ action, resetForm }) => {
    toast(`Address ${action} Successfully!`)
    onClose()
    resetForm(initialValues)
  }

  const { name = '', guid } = savedAddress
  const deliveryInfo = Object.entries(savedAddress.deliveryInfo).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value || '' }),
    {}
  )
  const { notes, address2 } = deliveryInfo

  const initialValues = {
    name,
    deliveryInfo,
    notes,
    address2
  }

  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={initialValues}
        validationSchema={savedAddressSchema}
        onSubmit={async (
          { name, deliveryInfo, notes, address2 },
          { setSubmitting, resetForm, setStatus }
        ) => {
          try {
            deliveryInfo = stripDeliveryInfo({
              ...deliveryInfo,
              notes,
              address2
            })
            const result = await updateMutation({
              variables: {
                input: { guid, name, deliveryInfo }
              }
            })
            const { UpdatePasswordError: error } = dataByTypename(
              result.data.updatePassword
            )

            if (error) {
              setStatus(error)
            } else {
              onSuccess({ action: 'Updated', resetForm })
            }
          } catch (err) {
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({ status, setStatus, resetForm, isSubmitting }) => {
          return (
            <div className={styles.wrapper}>
              <Form>
                <div className={styles.close}>
                  <CloseButton onClick={onClose} />
                </div>
                {status && <ErrorComponent error={status} />}
                <>
                  <Field
                    id='address_name'
                    name='name'
                    label='Address Label'
                    type='text'
                    component={Input}
                  />
                  <Field
                    id='delivery_info'
                    name='deliveryInfo'
                    component={AddressAutocomplete}
                  />
                  <Field
                    id='delivery_info_address_2'
                    name='address2'
                    label='Apt./Suite no.'
                    type='text'
                    component={Input}
                  />
                  <Field
                    id='delivery_info_notes'
                    name='notes'
                    label='Delivery Instructions'
                    type='text'
                    component={Input}
                  />
                  <DeleteSavedAddress
                    onCompleted={() =>
                      onSuccess({ action: 'Deleted', resetForm })
                    }
                    onError={setStatus}
                    savedAddress={savedAddress}
                  />
                  <Button
                    loading={isSubmitting}
                    variant={ButtonVariant.PRIMARY}
                    type={ButtonType.SUBMIT}
                    responsive
                  >
                    Update My Address
                  </Button>
                </>
              </Form>
            </div>
          )
        }}
      </Formik>
    </div>
  )
}

SavedAddressEditor.propTypes = {
  savedAddress: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}
