import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Dollars } from '@local/do-secundo-dollars'

import { LockLockedIcon } from '@toasttab/buffet-pui-icons'

import styles from '../LoyaltyRedemption/LoyaltyRedemption.module.css'
import { Button, ButtonType } from '@local/do-secundo-button'
import { Points } from '@local/do-secundo-points'

export const LockedLoyaltyRedemption = ({ cart }) => {
  const { conversionRate, accrualTarget } = cart.restaurant.loyaltyConfig

  if (!conversionRate || !accrualTarget) {
    return null
  }
  return (
    <div className='pt-2'>
      <Button
        type={ButtonType.BUTTON}
        className={styles.rewardButton}
        data-testid='reward-text'
        disabled={true}
      >
        <div className='flex items-center'>
          <div className='flex justify-start'>
            <div className='pt-2 pr-4'>{<LockLockedIcon />}</div>
            <div>
              <div className={(cx(styles.rewardValue), 'flex justify-start')}>
                <span>
                  <Dollars amount={accrualTarget * conversionRate} /> off
                </span>
              </div>
              <div>
                for {accrualTarget} <Points points={accrualTarget} />
              </div>
            </div>
          </div>
        </div>
      </Button>
    </div>
  )
}

LockedLoyaltyRedemption.propTypes = {
  cart: PropTypes.object.isRequired
}
