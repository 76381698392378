import React from 'react'

import { useRestaurant } from '@local/do-secundo-restaurant-provider'

import styles from './RestaurantLogo.module.css'

export const RestaurantLogo = () => {
  const { restaurantInfo } = useRestaurant()
  const { data, loading, error } = restaurantInfo

  if (loading || error) return null

  const { logoUrls } = data.restaurant
  const logoUrl = logoUrls && logoUrls.small && `${logoUrls.small}`

  return (
    <div
      className={styles.logo}
      style={{ backgroundImage: `url(${logoUrl})` }}
    />
  )
}
