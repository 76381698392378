// TODO: Investigate if we can use the es6 Symbol primitive.
//       It isn't supported by IE11 and unclear if it is polly filled
export const ZERO_STATE = 'zero_state'
export const POP = 'pop'
export const PUSH = 'push'
export const ENTER_START = 'enter_start'
export const LEAVE_START = 'leave_start'
export const ENTER_FINISH = 'enter_finish'
export const LEAVE_FINISH = 'leave_finish'
export const COMPLETE = 'complete'
