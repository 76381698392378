import * as React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import {
  PUSH_MODIFICATION_ERRORS,
  CLEAR_MODIFICATION_ERRORS
} from './cart-actions'

const initialCartState = {
  modificationErrors: []
}

const cartStateReducer = (state, action) => {
  switch (action.type) {
    case PUSH_MODIFICATION_ERRORS:
      const newErrorsWithUuid = action.modificationErrors.map((obj) => ({
        ...obj,
        id: uuid()
      }))
      return {
        ...state,
        modificationErrors: [...state.modificationErrors, ...newErrorsWithUuid]
      }
    case CLEAR_MODIFICATION_ERRORS:
      return {
        ...state,
        modificationErrors: []
      }
    default:
      throw new Error(
        `action type ${action.type} is not defined in the cartState: cartModificationsReducer`
      )
  }
}

const CartContext = React.createContext({})

export const CartProvider = ({
  restaurantStorage,
  restaurantGuid,
  children
}) => {
  const getCartGuid = () => restaurantStorage.get('cartGuid')

  const [cartState, dispatch] = React.useReducer(
    cartStateReducer,
    initialCartState
  )
  const [cartGuid, setCartGuid] = React.useState(getCartGuid())

  const refreshCartGuid = () => {
    const newCartGuid = getCartGuid()
    setCartGuid(newCartGuid)
  }
  const updateCartGuid = (newCartGuid) => {
    if (newCartGuid !== cartGuid) {
      restaurantStorage.set('cartGuid', newCartGuid)
      refreshCartGuid()
    }
  }
  const deleteCartGuid = () => {
    if (cartGuid) {
      restaurantStorage.remove('cartGuid')
      refreshCartGuid()
    }
  }

  const context = {
    restaurantGuid,
    cartGuid,
    updateCartGuid,
    deleteCartGuid,
    cartState,
    dispatch
  }

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
  restaurantGuid: PropTypes.string.isRequired,
  restaurantStorage: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
  }).isRequired
}

export const MockCartProvider = ({
  mocks: {
    restaurantGuid,
    cartGuid,
    updateCartGuid = null,
    deleteCartGuid = null
  },
  children
}) => {
  const context = {
    restaurantGuid,
    cartGuid,
    updateCartGuid,
    deleteCartGuid,
    cartState: { modificationErrors: [] }
  }

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>
}

MockCartProvider.propTypes = {
  children: PropTypes.element.isRequired,
  mocks: PropTypes.shape({
    restaurantGuid: PropTypes.string,
    cartGuid: PropTypes.string,
    updateCartGuid: PropTypes.func,
    deleteCartGuid: PropTypes.func,
    apollo: PropTypes.arrayOf(
      PropTypes.shape({
        request: PropTypes.object.isRequired,
        result: PropTypes.object,
        error: PropTypes.object
      })
    )
  }).isRequired
}

export const CartConsumer = ({ children }) => (
  <CartContext.Consumer>{(context) => children(context)}</CartContext.Consumer>
)

CartConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useCart = () => React.useContext(CartContext)
