import React from 'react'
import { useAuth } from '../../AuthProvider/AuthProvider'
import { Notification } from '@local/do-secundo-notification'

export const AccountWarning = () => {
  const { error } = useAuth()
  if (error) {
    return (
      <Notification severity='warning'>
        Our system is unable to load your account information. Please re-enter
        your information to checkout as a guest.
      </Notification>
    )
  }
  return null
}
