import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import {
  INQUIRE_LOYALTY_REQUEST,
  ADD_LOYALTY_REDEMPTION_TO_CART_REQUEST,
  REMOVE_LOYALTY_REDEMPTION_FROM_CART_REQUEST
} from './apollo/cart.graphql'

import { useCart } from '@local/do-secundo-cart-provider'
import { dataByTypename } from './apollo/apollo-helpers'
import { useGetOnCartOutOfStockCallback } from './useGetOnCartOutOfStockCallback'

export const useLoyaltyMutation = (graphqlMutation, dataKey) => {
  const { cartGuid } = useCart()
  const onCartOutOfStockCallback = useGetOnCartOutOfStockCallback()

  const [loyaltyMutateOriginal, apolloResponse] = useMutation(graphqlMutation, {
    variables: {
      input: {
        cartGuid
      }
    },
    onError: () => {},
    onCompleted: (data) => {
      const { CartOutOfStockError } = dataByTypename(data[dataKey])

      if (CartOutOfStockError) {
        onCartOutOfStockCallback(CartOutOfStockError)
      }
    }
  })
  const loyaltyMutate = useCallback(
    (mutationOptions) => {
      if (!cartGuid) return
      return loyaltyMutateOriginal(mutationOptions)
    },
    [cartGuid, loyaltyMutateOriginal]
  )

  return [loyaltyMutate, apolloResponse]
}

export const useInquireLoyalty = () =>
  useLoyaltyMutation(INQUIRE_LOYALTY_REQUEST, 'loyaltyInquiryV2')

export const useAddRedemption = () => {
  const [addRedemptionOriginal, apolloResponse] = useLoyaltyMutation(
    ADD_LOYALTY_REDEMPTION_TO_CART_REQUEST,
    'addLoyaltyRedemptionV2'
  )
  const { cartGuid } = useCart()
  const addRedemption = (redemption) => {
    return addRedemptionOriginal({
      variables: {
        input: { cartGuid, redemption }
      }
    })
  }

  return [addRedemption, apolloResponse]
}

export const useRemoveRedemption = () => {
  const [removeRedemptionOriginal, apolloResponse] = useLoyaltyMutation(
    REMOVE_LOYALTY_REDEMPTION_FROM_CART_REQUEST,
    'removeLoyaltyRedemptionV2'
  )
  const { cartGuid } = useCart()
  const removeRedemption = (redemption) => {
    return removeRedemptionOriginal({
      variables: { input: { cartGuid, redemption } }
    })
  }
  return [removeRedemption, apolloResponse]
}
