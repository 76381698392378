import * as React from 'react'
import { CollapsibleList } from '@toasttab/do-secundo-collapsible-list'
import { useSessionStorage } from '../../../../client/hooks/use-session-storage'
import { OPEN_MENU_LIST } from './constants'
import { OverviewLink } from './OverviewLink'
import { MenuBadge, useGetMenuBadge } from '@local/do-secundo-menu-badges'

export interface IdNameProps {
  id: string
  guid?: string
  name: string
}

export interface IdNameGroupsProps extends IdNameProps {
  groups?: IdNameProps[]
}

export const MenuOverview = ({ menus }: { menus: IdNameGroupsProps[] }) => {
  const [openedLists, setOpenedLists] = useSessionStorage(OPEN_MENU_LIST, {})

  if (!menus) {
    return null
  }

  const formattedMenus = menus.length === 1 ? menus[0].groups : menus

  return (
    <div className='mt-10'>
      {formattedMenus?.map((menu: IdNameGroupsProps) =>
        menu.groups ? (
          <CollapsibleList
            header={<MenuOverviewHeader {...menu} />}
            defaultCollapsed={!openedLists[menu.id]}
            key={menu.id}
            handleOnClick={() =>
              setOpenedLists({
                ...openedLists,
                [menu.id]: !openedLists[menu.id]
              })
            }
          >
            {menu.groups?.map((group) => (
              <OverviewLink key={group.id} item={group} />
            ))}
          </CollapsibleList>
        ) : (
          <div
            className='font-semibold border-t-0 border-b border-l-0 border-r-0 border-solid border-oo-black-12'
            key={menu.id}
          >
            <OverviewLink item={menu} />
          </div>
        )
      )}
    </div>
  )
}

const MenuOverviewHeader = ({ id, name }: IdNameProps) => {
  const groupBadge = useGetMenuBadge(id)

  return (
    <div className='flex flex-row items-center'>
      <h3 className='m-0 text-left'>{name}</h3>
      {groupBadge && (
        <MenuBadge
          className='mx-4 flex-initial flex-shrink-0'
          testId='menu-overview-menu-badge'
        >
          {groupBadge.badgeText}
        </MenuBadge>
      )}
    </div>
  )
}
