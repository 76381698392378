import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { SearchBar } from '@toasttab/buffet-search-bar'
import { useFlag, FF } from '@local/do-secundo-feature-flag'

export const SearchInput = ({
  initialValue = '',
  setTerm,
  placeholder = '',
  ...props
}) => {
  const BIRDS_EYE_VIEW_ENABLED = useFlag(FF.BIRDS_EYE_VIEW)
  const [inputValue, setInputValue] = useState(initialValue)
  const inputRef = React.useRef()

  const handleChange = useCallback(
    ({ target }) => {
      const { value } = target
      setInputValue(value)
      setTerm(value)
    },
    [setTerm]
  )

  const handleClear = useCallback(() => {
    setInputValue('')
    setTerm('')
  }, [setTerm])

  const handleSubmit = useCallback(({ key }) => {
    if (key === 'Enter' && inputRef.current) {
      inputRef.current.blur()
    }
  }, [])

  return (
    <SearchBar
      {...props}
      ref={inputRef}
      tabIndex={BIRDS_EYE_VIEW_ENABLED ? null : '1'}
      onKeyPress={handleSubmit}
      onClear={handleClear}
      placeholder={placeholder}
      onChange={handleChange}
      value={inputValue}
    />
  )
}

SearchInput.propTypes = {
  initialValue: PropTypes.string,
  setTerm: PropTypes.func,
  placeholder: PropTypes.string
}
