import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MaskedInput from 'react-text-mask'

import Warning from './warning.svg'
import { ErrorMessage } from '@local/do-secundo-form'

import styles from './Input.module.css'

const CustomInput = ({ mask, forwardRef, ...props }) =>
  mask ? (
    <MaskedInput mask={mask} {...props} />
  ) : (
    <input {...props} ref={forwardRef} />
  )

export const Input = ({
  field,
  form,
  label,
  id,
  type,
  inputMode,
  name,
  endAdornment,
  noAdornmentMargin = false,
  className,
  disabled,
  instructions = '',
  onBlur,
  onFocus,
  ...extraProps
}) => {
  const [focus, setFocus] = useState(false)
  const error = form.errors[field.name] && form.touched[field.name]
  const endAdornmentNode = endAdornment && (
    <span className={styles.endAdornment}>{endAdornment}</span>
  )

  return (
    <>
      <div
        className={cx(styles.adornmentWrapper, {
          [styles.focus]: focus,
          [styles.filled]: field.value.length,
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.noAdornmentMargin]: noAdornmentMargin
        })}
      >
        <div
          data-testid={`${id}-container`}
          className={cx(className, styles.wrapper)}
        >
          <label className={cx(styles.label, 'type-default')} htmlFor={id}>
            {label}
          </label>
          <CustomInput
            onFocus={() => {
              setFocus(true)
              onFocus && onFocus()
            }}
            className={cx(styles.input, 'type-default font-regular')}
            type={type}
            inputMode={inputMode}
            id={id}
            disabled={disabled}
            {...field}
            {...extraProps}
            onBlur={(...e) => {
              onBlur && onBlur()
              field.onBlur(...e)
              setFocus(false)
            }}
          />
        </div>
        {endAdornmentNode ||
          (error && (
            <span className={styles.endAdornment}>
              <Warning className={styles.warning} />
            </span>
          ))}
      </div>
      {error ? (
        <div data-testid='error-message' className={styles.errorMessage}>
          <ErrorMessage name={field.name} />
        </div>
      ) : (
        <div data-testid='instructions' className={styles.instructions}>
          {instructions}
        </div>
      )}
    </>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.object,
  field: FieldShape.isRequired,
  type: PropTypes.string.isRequired,
  endAdornment: PropTypes.node,
  noAdornmentMargin: PropTypes.bool,
  instructions: PropTypes.node
}
