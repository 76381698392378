import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { AuthForm, AuthFormSubmitValues } from '@local/do-secundo-auth-form'
import { LoginPrompt } from '@local/do-secundo-identity-prompts'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { unifiedCreateAccountValidationSchema } from '../../../../client/utils/form-schemas'
import {
  deleteTokens,
  accountLinkingStorage
} from '../../../../client/apollo/authentication/authentication-helpers'

import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'
import { handleUnifiedPhoneSubmit, FROM_LOCATION } from '../utils'
import { TermsOfService } from '@local/do-secundo-terms-of-service'
export interface UnifiedCreateAccountModalProps {
  onClose: () => void
  buttonText?: string
  includeEmail?: boolean
  handleSubmitError?: (error: { Error: string }) => void
}

export const UnifiedCreateAccountModal = ({
  onClose,
  handleSubmitError,
  buttonText = 'Continue',
  includeEmail = false
}: UnifiedCreateAccountModalProps) => {
  const { startPasswordlessLogin } = useAuth()
  const history = useHistory()
  const { state = {} } = useLocation()

  const phone = state.guestPhone || state.basicInfo?.phone || ''

  const handleSubmit = async (values: AuthFormSubmitValues) => {
    return handleUnifiedPhoneSubmit({
      values: { phone: values.phone },
      from: FROM_LOCATION.create,
      startPasswordlessLogin,
      history,
      email: state.email,
      basicInfo: state.basicInfo,
      loyaltyAccountCreationInput: state.loyaltyAccountCreationInput
    })
  }

  const shouldShowLoyalty = Boolean(state?.loyaltyAccountCreationInput)

  // we need to delete accountLinkingStorage because they may have abandoned
  // the account linking flow previously and now their credentials are out of date
  // and will result in throwing an InvalidJWT.
  React.useEffect(() => {
    deleteTokens(accountLinkingStorage)
  }, [])

  const disclaimer = shouldShowLoyalty ? (
    <TermsOfService />
  ) : (
    <p className='type-caption'>
      We will send a one-time verification code to set up your account. Your
      phone number will be used to authenticate and provide you with automated
      messages like order status updates.
    </p>
  )

  return (
    <PoweredByToastModal
      className='h-full'
      onClose={onClose}
      header={
        shouldShowLoyalty ? (
          <>
            <h2 className='mx-0 mt-0 mb-3 type-headline-4'>
              Create a Toast Account to earn rewards{' '}
            </h2>
            <p className={'type-default'}>
              Earn points towards rewards and get your food faster. Save order
              info and payment details across all of your favorite restaurants
              with a Toast Account.
            </p>
          </>
        ) : (
          <h2 className='mx-0 mt-0 mb-3 type-headline-4'>
            Enter your phone number
          </h2>
        )
      }
    >
      <div
        className='flex flex-col justify-between h-full m-auto'
        data-testid='sign-up-prompt-container'
      >
        <LoginPrompt
          form={
            <AuthForm
              handleSubmit={handleSubmit}
              initialValues={{ phone }}
              validationSchema={unifiedCreateAccountValidationSchema}
              buttonText={buttonText}
              includeEmail={includeEmail}
              handleSubmitError={handleSubmitError}
              testId={`unified-${
                shouldShowLoyalty ? 'loyalty' : 'create'
              }-auth-form`}
              disclaimer={disclaimer}
            />
          }
        />
      </div>
    </PoweredByToastModal>
  )
}
