import React, { useState } from 'react'
import styles from './RestaurantDetails.module.css'
import { RestaurantHours } from '../../../../client/components/RestaurantHours/RestaurantHours'
import { RestaurantSchedule } from '@local/do-secundo-restaurant-schedule'
import { RestaurantNotification } from '../../../../client/components/RestaurantNotification/RestaurantNotification'
import { RestaurantAddress } from '../../../../client/components/RestaurantAddress/RestaurantAddress'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import cx from 'classnames'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { Modal } from '@local/do-secundo-modal'
import { SocialMediaButtons } from '../../../../client/components/SocialMediaButtons/SocialMediaButtons'
import { formatPhoneNumber } from '../../../../client/utils/phone-number'
import { cdnUrl } from '../../../../client/utils/cdn'
import PhoneImg from '../../../../client/assets/phone.svg'

export const RestaurantDetails = ({
  restaurant: {
    imageUrl,
    bannerUrls,
    whiteLabelName,
    location,
    logoUrls = {},
    schedule,
    description,
    socialMediaLinks,
    timeZoneId,
    onlineOrderingEnabled
  }
}) => {
  const { address1, address2, city, state, zip, phone } = location
  const useBannerImage = useFlag(FF.BANNER_IMAGE_FIX)
  const { orderingAvailable } = useAvailability()
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)
  const [shouldShowInfo, editShowInfo] = useState(false)
  const bannerUrl = useBannerImage
    ? bannerUrls && bannerUrls.raw
    : imageUrl && cdnUrl(imageUrl)

  return (
    <div
      className={styles.header}
      role='group'
      aria-label='restaurant information'
    >
      <div className={styles.notification}>
        <RestaurantNotification />
      </div>
      <div className={styles.banner}>
        <div className={styles.information}>
          {logoUrls && logoUrls.small ? (
            <div
              className={styles.logo}
              style={{ backgroundImage: `url(${logoUrls.small})` }}
            />
          ) : (
            <div className={cx(styles.logo, styles.empty)} />
          )}

          <h1
            className={cx(styles.name, 'type-headline-2')}
            itemProp={nv5SEOEnabled && 'name'}
          >
            {whiteLabelName}
          </h1>
          {nv5SEOEnabled && phone && (
            <meta itemProp='telephone' content={phone} />
          )}
          <div className={cx(styles.info, 'type-default')}>
            <div
              className='mb-1'
              itemProp={nv5SEOEnabled && 'address'}
              itemScope={nv5SEOEnabled}
              itemType={nv5SEOEnabled && 'https://schema.org/PostalAddress'}
            >
              {nv5SEOEnabled && (
                <>
                  <meta
                    itemProp='streetAddress'
                    content={`${address1}${address2 ? `, ${address2}` : ''}`}
                  />
                  <meta itemProp='addressLocality' content={city} />
                  <meta itemProp='addressRegion' content={state} />
                  <meta itemProp='postalCode' content={zip} />
                </>
              )}
              <RestaurantAddress
                address1={address1}
                address2={address2}
                city={city}
                state={state}
              />
            </div>
            <RestaurantSchedule
              schedule={schedule}
              orderingAvailable={orderingAvailable}
              timeZoneId={timeZoneId}
              onlineOrderingEnabled={onlineOrderingEnabled}
            />
            <button
              data-testid='rx-info-button'
              className={styles.infoButton}
              onClick={() => editShowInfo(!shouldShowInfo)}
            >
              More Info
            </button>
          </div>
        </div>
        {shouldShowInfo && (
          <Modal onClose={() => editShowInfo(false)} header={whiteLabelName}>
            <div className={cx(styles.moreInfo, 'type-default')}>
              <div className='py-2 -ml-1'>
                <RestaurantSchedule
                  schedule={schedule}
                  orderingAvailable={orderingAvailable}
                  timeZoneId={timeZoneId}
                  onlineOrderingEnabled={onlineOrderingEnabled}
                />
              </div>
              <div className={styles.infoIcons}>
                {socialMediaLinks && (
                  <SocialMediaButtons socialMediaLinks={socialMediaLinks} />
                )}
                {phone && (
                  <a href={`tel:${phone}`} className={styles.phone}>
                    <PhoneImg className={styles.phoneImage} />
                    {formatPhoneNumber(phone)}
                  </a>
                )}
              </div>
              {description && (
                <p className={styles.description}>{description}</p>
              )}
              {onlineOrderingEnabled && <RestaurantHours />}
            </div>
          </Modal>
        )}
        {bannerUrl ? (
          <div
            className={styles.bannerImage}
            style={{ backgroundImage: `url(${bannerUrl})` }}
          />
        ) : (
          <div className={styles.noBannerImage} />
        )}
      </div>
    </div>
  )
}
