import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import {
  ToastNotification,
  toastEachWarning
} from './ToastNotification/ToastNotification'
import { useFulfillment } from '../FulfillmentProvider/FulfillmentProvider'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useRestaurantStorage } from '../../utils/restaurant-storage'
import { useVariant } from '../../hooks/use-variant'
import { XP_MEAL_SELECTOR } from '../ExperimentsProvider/experiment-definitions'

export const useHandleSubmit = ({
  itemDetails,
  restaurant,
  getComputedPrice,
  basePrice,
  cartGuid,
  itemGuid,
  itemGroupGuid,
  getModifierGroups,
  updateCart,
  selectionGuid
}) => {
  const {
    fulfillmentType,
    fulfillmentTime,
    diningOptionBehavior,
    deliveryInfo
  } = useFulfillment()
  const history = useHistory()
  const { getRestaurantPath } = useRestaurant()
  const restaurantStorage = useRestaurantStorage()
  const mealSelectorVariant = !!useVariant(XP_MEAL_SELECTOR.experimentName)
  const storedShuffleMenu = mealSelectorVariant
    ? restaurantStorage.get('toast-oo-meal-selector')
    : null

  return useCallback(
    async (values, { setSubmitting }) => {
      const { specialInstructions, quantity } = values
      let createCartInput = {
        restaurantGuid: restaurant.guid,
        orderSource: 'ONLINE',
        cartFulfillmentInput: {
          fulfillmentDateTime: fulfillmentTime,
          fulfillmentType: fulfillmentType,
          diningOptionBehavior,
          deliveryInfo
        }
      }
      if (cartGuid) {
        createCartInput = undefined
      }
      const variables = {
        input: {
          cartGuid,
          createCartInput,
          selection: {
            itemGuid,
            itemGroupGuid,
            quantity,
            modifierGroups: getModifierGroups(values, quantity),
            specialInstructions,
            itemMasterId: itemDetails.masterId
          },
          ...(selectionGuid && { selectionGuid })
        }
      }

      if (values.fractionalQuantity) {
        variables.input.selection.fractionalQuantity = {
          unitOfMeasure: itemDetails.unitOfMeasure,
          quantity: parseFloat(values.fractionalQuantity)
        }
      }

      try {
        const response = await updateCart({ variables })
        const responseData =
          response &&
          response.data &&
          (response.data.addItemToCartV2 || response.data.editItemInCartV2)
        const { CartResponse, CartOutOfStockError } =
          dataByTypename(responseData)
        if (CartResponse) {
          const totalQuantity = quantity
          const text = `${totalQuantity} item${
            totalQuantity > 1 ? 's' : ''
          } added`
          toast(<ToastNotification checkmark>{text}</ToastNotification>)
        }
        if (CartOutOfStockError) {
          toastEachWarning({ warnings: [CartOutOfStockError.message] })
        }
      } finally {
        setSubmitting(false)
        // random meal selector
        if (mealSelectorVariant && storedShuffleMenu) {
          const parsedMenu = JSON.parse(storedShuffleMenu)
          if (parsedMenu.length === 0) {
            restaurantStorage.remove('toast-oo-meal-selector')
          } else {
            const { guid, itemGroupGuid } = parsedMenu.shift()
            restaurantStorage.set(
              'toast-oo-meal-selector',
              JSON.stringify(parsedMenu)
            )
            history.push(getRestaurantPath(`add/${guid}/${itemGroupGuid}`))
          }
        }
      }
    },
    [
      restaurant,
      fulfillmentTime,
      fulfillmentType,
      diningOptionBehavior,
      deliveryInfo,
      cartGuid,
      itemGuid,
      itemGroupGuid,
      getModifierGroups,
      itemDetails,
      selectionGuid,
      updateCart,
      history,
      storedShuffleMenu,
      mealSelectorVariant,
      restaurantStorage,
      getRestaurantPath
    ]
  )
}

export const useHandleDelete = ({
  deleteItemFromCart,
  cartGuid,
  itemGuid,
  selectionGuid,
  getComputedPrice,
  basePrice,
  itemDetails
}) =>
  useCallback(
    async (values) => {
      const response = await deleteItemFromCart({
        variables: {
          input: {
            cartGuid,
            selectionGuid
          }
        }
      })

      const responseData =
        response && response.data && response.data.deleteItemFromCartV2
      const { CartOutOfStockError } = dataByTypename(responseData)
      if (CartOutOfStockError) {
        toastEachWarning({ warnings: [CartOutOfStockError.message] })
      }
    },
    [deleteItemFromCart, cartGuid, selectionGuid]
  )
