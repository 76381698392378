import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { scrollElementToPosition } from '../../utils/scroll-helpers'
import { useHorizontalScrollMenu } from './useHorizontalScrollMenu'

import styles from './HorizontalScrollMenu.module.css'

const HorizontalScrollMenu = ({
  leftArrowButton,
  rightArrowButton,
  // scrollElementToPosition is an abstraction of the browser scrollTo api
  handleScroll = scrollElementToPosition,
  children,
  activeItemId,
  // on arrow click, scroll 75% of the visible scrollbar distance
  scrollDistanceMultiplier = 0.75
}) => {
  const RightArrowButton = rightArrowButton
  const LeftArrowButton = leftArrowButton
  const {
    itemContainerRef,
    handleLeftArrowClick,
    handleRightArrowClick,
    hideLeftArrow,
    hideRightArrow
  } = useHorizontalScrollMenu(
    activeItemId,
    handleScroll,
    scrollDistanceMultiplier
  )

  return (
    <div className={styles.root}>
      <LeftArrowButton
        className={cx(styles.arrow, styles.left, {
          [styles.hide]: hideLeftArrow
        })}
        onClick={handleLeftArrowClick}
        data-testid='arrow-left'
      />
      <div
        className={styles.itemContainer}
        ref={itemContainerRef}
        data-testid='itemContainer'
      >
        {children}
      </div>
      <RightArrowButton
        className={cx(styles.arrow, styles.right, {
          [styles.hide]: hideRightArrow
        })}
        onClick={handleRightArrowClick}
        data-testid='arrow-right'
      />
    </div>
  )
}

HorizontalScrollMenu.propTypes = {
  leftArrowButton: PropTypes.any,
  rightArrowButton: PropTypes.any,
  handleScroll: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  activeItemId: PropTypes.string,
  scrollDistanceMultiplier: PropTypes.number
}

export { HorizontalScrollMenu }
