import React, { useState, useCallback } from 'react'

import { Modal } from '@local/do-secundo-modal'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { useObserved } from './useObserved'

type CriticalErrorModalProps = {
  show: boolean
}

const TRACK_SEEN = 'Critical Error Observed'

export const CriticalErrorModal = ({ show }: CriticalErrorModalProps) => {
  const [showModal, setShowModal] = useState(show)
  useObserved(TRACK_SEEN)

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  return (
    showModal && (
      <Modal responsive={false}>
        <div className='flex items-center justify-center h-full'>
          <div className='pb-10 w-80'>
            <MessageError
              testId='CriticalErrorModalMessage'
              header="We couldn't process your order"
              message='Please try again'
              buttonPrimary={{
                label: 'Got it',
                onClick: handleClose,
                testId: 'try-again-button'
              }}
            />
          </div>
        </div>
      </Modal>
    )
  )
}
