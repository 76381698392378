import React, { useMemo } from 'react'
import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import {
  dispatchFulfillmentChange,
  dispatchRestaurantConfig
} from 'cornucopia-apis'
import { CartListener } from '../CartListener'
import { useCart } from '@local/do-secundo-cart-provider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useFulfillment } from '../../../../client/components/FulfillmentProvider/FulfillmentProvider'
import { useFulfillmentAvailability } from '../../../../client/hooks/use-fulfillment-availability'

const CornucopiaStateContext = React.createContext({
  isCornucopiaShellRunning: false
})

interface CornucopiaProviderParams {
  restaurantGuid?: string
  children: React.ReactNode
}

interface CornucopiaState {
  isCornucopiaShellRunning: boolean
}

function CornucopiaProvider({
  restaurantGuid,
  children
}: CornucopiaProviderParams) {
  const { isCornucopiaShellRunning } = useOOGlobals()
  const { cartGuid } = useCart()
  const {
    fulfillmentType,
    fulfillmentTime,
    diningOptionBehavior,
    deliveryInfo,
    selected
  } = useFulfillment()

  const { orderingAvailable } = useAvailability()
  const fulfillmentAvailable = useFulfillmentAvailability()

  const {
    restaurantInfo: { data: restaurantData }
  } = useRestaurant()
  React.useEffect(() => {
    dispatchFulfillmentChange({
      fulfillmentTime,
      // override selected if there is no fulfillment user could select from
      selected: fulfillmentAvailable ? selected : true
    })
  }, [fulfillmentTime, selected, fulfillmentAvailable])

  React.useEffect(() => {
    dispatchRestaurantConfig({
      orderingAvailable,
      specialRequestsConfig: restaurantData?.restaurant.specialRequestsConfig
    })
  }, [orderingAvailable, restaurantData?.restaurant.specialRequestsConfig])

  const cartFulfillmentInput = useMemo(() => {
    return {
      fulfillmentDateTime: fulfillmentTime,
      fulfillmentType,
      diningOptionBehavior,
      deliveryInfo
    }
  }, [deliveryInfo, diningOptionBehavior, fulfillmentTime, fulfillmentType])

  const state = {
    isCornucopiaShellRunning
  }

  return (
    <CornucopiaStateContext.Provider value={state}>
      {isCornucopiaShellRunning && (
        <CartListener
          restaurantGuid={restaurantGuid}
          cartGuid={cartGuid}
          cartFulfillmentInput={cartFulfillmentInput}
        />
      )}
      {children}
    </CornucopiaStateContext.Provider>
  )
}

function useCornucopiaState(): CornucopiaState {
  const context = React.useContext(CornucopiaStateContext)
  if (context === undefined) {
    throw new Error(
      'useCornucopiaState must be used within a CornucopiaProvider'
    )
  }
  return context
}

export { CornucopiaProvider, useCornucopiaState }
