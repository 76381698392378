// Given one or more data field names, inspect the data object and return an error
// message for the first matching field with a __typename matching ___Error, otherwise
// return false
export const queryDataErrorGetter =
  (...dataFields) =>
  (data) => {
    for (const field of dataFields) {
      if (data && data[field]) {
        const { __typename } = data[field]
        if (/Error$/.test(__typename)) {
          return data[field]
        }
      }
    }
    return false
  }
