import React, { useEffect, useReducer, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useFlag } from '@local/do-secundo-feature-flag'
import {
  addEventProperties,
  removeEventProperty,
  track
} from '@toasttab/do-secundo-analytics'
import {
  XP_GUEST_FEEDBACK,
  XP_INTELLIGENT_UPSELLS,
  XP_MEAL_SELECTOR,
  XP_MENU_ITEMS_SORT,
  XP_COMBINED_MENU_GROUPS,
  XP_PRICE_TRANSPARENCY,
  XP_PROMO_BANNERS,
  XP_MENU_BADGES,
  XP_PT_CTA,
  XP_CA_FULLSCREEN_CTA,
  XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX,
  XP_BRANDED_HEADER_LOGIN_BUTTON
} from './experiment-definitions'

const emptyExperimentsContextValues = { experiments: {} }
const fnNoOP = () => {}
const ExperimentsContext = React.createContext(emptyExperimentsContextValues)
const ExperimentsProvider = ({ children }) => {
  const [experiments, updateExperiments] = useReducer(
    (state, experiment) => ({ ...state, ...experiment }),
    {}
  )

  // SETUP EXPERIMENTS
  // Each must be passed the `updateExperiments` callback to update the context state
  // leaving this line here below as an example:
  useCreateExperiment(XP_GUEST_FEEDBACK, updateExperiments)
  useCreateExperiment(XP_INTELLIGENT_UPSELLS, updateExperiments)
  useCreateExperiment(XP_MEAL_SELECTOR, updateExperiments)
  useCreateExperiment(XP_MENU_ITEMS_SORT, updateExperiments)
  useCreateExperiment(XP_COMBINED_MENU_GROUPS, updateExperiments)
  useCreateExperiment(XP_PRICE_TRANSPARENCY, updateExperiments)
  useCreateExperiment(XP_PROMO_BANNERS, updateExperiments)
  useCreateExperiment(XP_MENU_BADGES, updateExperiments)
  useCreateExperiment(XP_PT_CTA, updateExperiments)
  useCreateExperiment(XP_CA_FULLSCREEN_CTA, updateExperiments)
  useCreateExperiment(XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX, updateExperiments)
  useCreateExperiment(XP_BRANDED_HEADER_LOGIN_BUTTON, updateExperiments)

  const context = {
    experiments
  }

  return (
    <ExperimentsContext.Provider value={context}>
      {children}
    </ExperimentsContext.Provider>
  )
}

ExperimentsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const defaultEventOnTrack = 'Experimental treatment applied'
const defaultEventOnUntrack = 'Experimental treatment unapplied'

// eslint-disable-next-line
const useCreateExperiment = (
  {
    experimentName,
    flagName,
    useInitVariant,
    getTreatment = fnNoOP,
    customEventOnTrack = defaultEventOnTrack,
    customEventOnUntrack = defaultEventOnUntrack
  },
  updateExperiments
) => {
  const [hasTracked, setHasTracked] = useState(false)
  const [hasUntracked, setHasUntracked] = useState(true)
  const flagOn = useFlag(flagName, false)
  const variant = useInitVariant()

  const variantKey = experimentName.concat('-variant')
  const treatmentKey = experimentName.concat('-treatment')

  const trackTreatment = useCallback(() => {
    if (!hasTracked) {
      setHasUntracked(false)
      track(customEventOnTrack, {
        [variantKey]: variant,
        [treatmentKey]: true,
        experimentName,
        experimentVariant: variant,
        experimentTreatment: true
      })
      addEventProperties({
        [treatmentKey]: true
      })
      setHasTracked(true)
    }
  }, [
    customEventOnTrack,
    hasTracked,
    treatmentKey,
    variant,
    variantKey,
    experimentName
  ])

  const untrackTreatment = useCallback(() => {
    if (!hasUntracked) {
      setHasTracked(false)
      track(customEventOnUntrack, {
        [variantKey]: variant,
        [treatmentKey]: false,
        experimentName,
        experimentVariant: variant,
        experimentTreatment: false
      })
      removeEventProperty(treatmentKey)
      setHasUntracked(true)
    }
  }, [
    customEventOnUntrack,
    hasUntracked,
    treatmentKey,
    variant,
    variantKey,
    experimentName
  ])

  const getVariant = useCallback(() => {
    return variant
  }, [variant])

  const getTreatmentAndTrack = useCallback(() => {
    trackTreatment()
    return (getTreatment && getTreatment(variant)) || null
  }, [getTreatment, variant, trackTreatment])

  const getVariantAndTrack = useCallback(() => {
    trackTreatment()
    return variant
  }, [variant, trackTreatment])

  // if the feature flag for this experiment is on
  // 1. Add experiment variant and treatment tracking events to provider
  // 2. Start tracking variant
  useEffect(() => {
    if (flagOn) {
      updateExperiments({
        [experimentName]: {
          untrackTreatment,
          getVariant,
          variant,
          getTreatmentAndTrack,
          getVariantAndTrack
        }
      })

      addEventProperties({
        [variantKey]: variant
      })
    }
  }, [
    experimentName,
    flagOn,
    getVariant,
    getTreatmentAndTrack,
    getVariantAndTrack,
    untrackTreatment,
    updateExperiments,
    variant,
    variantKey
  ])
}

const ExperimentsProviderFlagged = (props) => {
  return <ExperimentsProvider {...props} />
}

export { ExperimentsProviderFlagged as ExperimentsProvider }

const emptyExperiment = {
  untrackTreatment: fnNoOP,
  getVariantAndTrack: fnNoOP,
  getTreatmentAndTrack: fnNoOP
}
export const useExperiments = (experimentKey) => {
  const ctx = React.useContext(ExperimentsContext)
  if (experimentKey === undefined) {
    return ctx
  }
  return ctx.experiments[experimentKey] || emptyExperiment
}
