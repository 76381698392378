import { useEffect, useRef } from 'react'
import { track } from '@toasttab/do-secundo-analytics'

export const useObserved = (message) => {
  const trackOnceHasSeenRef = useRef(false) // Track that the guest has seen the Error

  useEffect(() => {
    if (!trackOnceHasSeenRef.current) {
      trackOnceHasSeenRef.current = true
      track(message)
    }
  }, [message])
}
