import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { useDeliveryDistanceValidator } from '../fulfillment-helpers'
import { DINING_OPTION_BEHAVIORS } from '@local/do-secundo-use-dining-options'
import { useFlag, FF } from '@local/do-secundo-feature-flag'

const { DELIVERY } = DINING_OPTION_BEHAVIORS

const getButtonMessage = ({ valid, editing, diningOptionBehavior }) => {
  if (!valid && diningOptionBehavior === DELIVERY) return 'View Menu'

  if (editing) return 'Update'
  return 'Start Order'
}

export const FulfillmentFooter = ({
  formik,
  editing,
  onInvalidAddressClose
}) => {
  const CHECKOUT_FULFILLMENT_ENABLED = useFlag(FF.CHECKOUT_FULFILLMENT)
  const { values, isSubmitting, isValid } = formik
  const { deliveryInfo, diningOptionBehavior } = values
  const { valid, loading } = useDeliveryDistanceValidator(
    deliveryInfo,
    diningOptionBehavior
  )
  let message = getButtonMessage({
    valid,
    editing,
    diningOptionBehavior
  })

  const buttonProps = {
    'data-testid': 'fulfillment-selector-submit',
    type: ButtonType.SUBMIT,
    variant: ButtonVariant.PRIMARY,
    loading: isSubmitting || loading,
    disabled: !isValid
  }
  if (
    !valid &&
    diningOptionBehavior === DELIVERY &&
    CHECKOUT_FULFILLMENT_ENABLED &&
    onInvalidAddressClose
  ) {
    buttonProps.type = ButtonType.BUTTON
    buttonProps.onClick = onInvalidAddressClose
    message = 'Back to menu'
  }

  return <Button {...buttonProps}>{message}</Button>
}

FulfillmentFooter.propTypes = {
  formik: PropTypes.object.isRequired,
  editing: PropTypes.bool
}
