import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import IntersectionObserver from '@researchgate/react-intersection-observer'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useReorderQuery } from '../ReorderQuery/ReorderQuery'
import { getFastRect } from '../../utils/dimensions'
import { FulfillmentSelector } from '../FulfillmentSelector/FulfillmentSelector'
import { ReordersOrPopularItemsBanner } from './ReordersOrPopularItemsBanner'
import styles from './RestaurantHeader.module.css'
import { MealSelectorPrompt } from '../MealSelector/MealSelectorPrompt/MealSelectorPrompt'
import { useVariant } from '../../hooks/use-variant'
import {
  XP_MEAL_SELECTOR,
  XP_MENU_BADGES,
  XP_PROMO_BANNERS
} from '../ExperimentsProvider/experiment-definitions'
import { MenuNavigationModule } from './MenuNavigationModule'
import { Shelf } from 'cornucopia-apis'
import { RestaurantDetails } from '@local/do-secundo-restaurant-details'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { useCart } from '@local/do-secundo-cart-provider'
import { toast } from 'react-toastify'

// separate export to allow testing, https://github.com/enzymejs/enzyme/issues/2196 <-- enzyme does not work well with memoized functional components
export const RestaurantHeaderInner = ({
  restaurant,
  activeMenu,
  menus,
  menusLength,
  activeItem,
  publicApi
}) => {
  const { cartGuid } = useCart()
  const mealSelectorVariant = !!useVariant(XP_MEAL_SELECTOR.experimentName)
  const promoBannersVariant = !!useVariant(XP_PROMO_BANNERS.experimentName)
  const menuBadgesVariant = !!useVariant(XP_MENU_BADGES.experimentName)
  const { orderingAvailable } = useAvailability()
  const stickyRef = useRef()
  const { hasReorders } = useReorderQuery()
  const [showStickyShadow, setShowStickyShadow] = useState(false)
  const windowSize = useWindowSize({})
  const isMobile = windowSize.width <= 768

  /**
   * Show sticky nav when:
   * 1. menusLength > 1
   * 2. menuLengths === 1 and menus.groups.length > 1
   *
   * @returns {*|boolean}
   */
  const shouldShowStickyNav = () => {
    const hasMultipleMenus = menusLength > 1
    const multipleMenuGroups =
      activeMenu && activeMenu.groups && activeMenu.groups.length > 1
    const showStickyNav = hasMultipleMenus || multipleMenuGroups

    return showStickyNav
  }
  const moreThanOneMenu = menusLength > 1
  const calcHeaderOffset = async () => {
    const { height } = await getFastRect(stickyRef.current)
    return height
  }

  // Call directly from the React module to make this mockable
  React.useImperativeHandle(publicApi, () => {
    return {
      calcHeaderOffset
    }
  })

  const showStickyShadowCallback = (entry) => {
    setShowStickyShadow(!entry.isIntersecting)
  }

  return (
    <>
      <RestaurantDetails restaurant={restaurant} />
      <div className={styles.fulfillment}>
        <div className={styles.fulfillmentDisplay}>
          <FulfillmentSelector />
        </div>
      </div>
      {mealSelectorVariant && orderingAvailable && <MealSelectorPrompt />}
      <div className={styles.menuBanners}>
        <Shelf
          name='corn-menu-banners'
          params={{
            cartGuid,
            carouselConfig: {
              pagination: !isMobile,
              slideGap: isMobile ? 8 : 0,
              xOffset: isMobile ? 20 : 0
            },
            experiments: {
              [XP_PROMO_BANNERS.experimentName]: promoBannersVariant,
              [XP_MENU_BADGES.experimentName]: menuBadgesVariant
            },
            toast
          }}
        />
      </div>
      <ReordersOrPopularItemsBanner hasReorders={hasReorders} />
      <IntersectionObserver
        // rootMargin -60px is the height of fixed header plus 5px to trigger the onChange
        rootMargin='-65px 0px 0px 0px'
        onChange={showStickyShadowCallback}
      >
        <div className={styles.stickyShadowSentinel} />
      </IntersectionObserver>

      {shouldShowStickyNav() && (
        <MenuNavigationModule
          stickyRef={stickyRef}
          activeItem={activeItem}
          activeMenu={activeMenu}
          moreThanOneMenu={moreThanOneMenu}
          menus={menus}
          showStickyShadow={showStickyShadow}
        />
      )}
    </>
  )
}

/*
  Related posts that describe the issue and explain double displayName
  https://github.com/enzymejs/enzyme/pull/2137
  https://github.com/enzymejs/enzyme/issues/2196
*/

// to change displayName from RestaurantHeaderInner
RestaurantHeaderInner.displayName = 'RestaurantHeader'

export const RestaurantHeader = React.memo(RestaurantHeaderInner, isEqual)
// to change displayName from Memo(RestaurantHeader)
RestaurantHeader.displayName = 'RestaurantHeader'

const LocationShape = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  phone: PropTypes.string
})

const ImageSetShape = PropTypes.shape({
  raw: PropTypes.string
})

const SocialMediaLinksShape = PropTypes.shape({
  twitterLink: PropTypes.string,
  facebookLink: PropTypes.string
})

const RestaurantShape = PropTypes.shape({
  location: LocationShape,
  guid: PropTypes.string,
  imageUrl: PropTypes.string,
  bannerUrls: ImageSetShape,
  minimumTakeoutTime: PropTypes.number,
  name: PropTypes.string,
  socialMediaLinks: SocialMediaLinksShape
})

const PublicApiShape = PropTypes.shape({
  calcHeaderOffset: PropTypes.func
})

RestaurantHeaderInner.propTypes = {
  restaurant: RestaurantShape,
  menus: PropTypes.array.isRequired,
  activeMenu: PropTypes.object.isRequired,
  menusLength: PropTypes.number,
  activeItem: PropTypes.string,
  publicApi: PropTypes.shape({ current: PublicApiShape })
}
