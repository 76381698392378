import { dataByTypename } from '../../utils/apollo-helpers'
import { SERVICES } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { AuthClient } from '@toasttab/guest-authentication-js'
import { getRawPhoneNumberWithCountryCode } from '@local/do-secundo-form-utils'
import { FROM_LOCATION } from '@local/do-secundo-passwordless-authentication'

interface CustomerInfo {
  customer: {
    email: string
    phone: string
    firstName: string
    lastName: string
  }
}

interface GetCreateAccountInputProps {
  ccInput?: CustomerInfo
  password?: string
  redirectUrl?: string
  restaurantGuid?: string
  isPasswordlessAccount: boolean
  cashInput?: CustomerInfo
}

interface BasicInfo {
  firstName: string
  lastName: string
  phone: string
}

interface PasswordlessInput {
  email: string
  phone: string
  source: string
  basicInfo: BasicInfo
}

interface PasswordInput {
  email: string
  password: string
  redirectUrl: string
  registrationClient: string
  restaurantGuid: string
  basicInfo: BasicInfo
}

interface StartProfileCreationInput {
  variables: {
    input: PasswordlessInput
  }
}

interface HandleCreatePasswordlessAccountProps {
  startProfileCreation: (input: StartProfileCreationInput) => Promise<any>
  ccInput?: CustomerInfo
  setErrorMessage: (str: string) => void
  setUnverifiedCustomer: (input: { guid: string; signupSource: string }) => void
  cashInput?: CustomerInfo
  authClient: AuthClient
}

type PasswordInputOrPasswordlessInput = PasswordInput | PasswordlessInput

/**
 * Helper function to return the createAccountInput based on if we are creating a password or passwordless based account
 */
export const getCreateAccountInput = ({
  ccInput,
  password = '',
  redirectUrl,
  restaurantGuid,
  isPasswordlessAccount = false,
  cashInput
}: GetCreateAccountInputProps): PasswordInputOrPasswordlessInput => {
  if (isPasswordlessAccount) {
    if (ccInput) {
      return {
        email: ccInput.customer.email,
        phone: ccInput.customer.phone,
        source: 'ONLINE_ORDERING',
        basicInfo: {
          firstName: ccInput.customer.firstName,
          lastName: ccInput.customer.lastName,
          phone: ccInput.customer.phone
        }
      }
    }
    if (cashInput) {
      return {
        email: cashInput.customer.email,
        phone: cashInput.customer.phone,
        source: 'ONLINE_ORDERING',
        basicInfo: {
          firstName: cashInput.customer.firstName,
          lastName: cashInput.customer.lastName,
          phone: cashInput.customer.phone
        }
      }
    }
  }

  const createAccountInput = {
    email: ccInput!.customer.email,
    redirectUrl: redirectUrl!,
    registrationClient: 'ONLINE_ORDERING',
    restaurantGuid: restaurantGuid!,
    basicInfo: {
      firstName: ccInput!.customer.firstName,
      lastName: ccInput!.customer.lastName,
      phone: ccInput!.customer.phone
    },
    password
  }
  return createAccountInput
}

export const handleCreatePasswordlessAccount = async ({
  startProfileCreation,
  ccInput,
  setErrorMessage,
  setUnverifiedCustomer,
  cashInput,
  authClient
}: HandleCreatePasswordlessAccountProps) => {
  const startProfileCreationInput = getCreateAccountInput({
    ccInput,
    isPasswordlessAccount: true,
    cashInput
  }) as PasswordlessInput

  const response = await startProfileCreation({
    variables: {
      input: {
        ...startProfileCreationInput,
        phone: getRawPhoneNumberWithCountryCode(startProfileCreationInput.phone)
      }
    }
  })

  const { StartProfileCreationResponse, StartProfileCreationError } =
    dataByTypename(response.data.startProfileCreation)

  if (StartProfileCreationError) {
    setErrorMessage(StartProfileCreationError)
  } else {
    setUnverifiedCustomer({
      guid: StartProfileCreationResponse.guestGuid,
      signupSource: 'CREATE_ACCOUNT_DURING_CHECKOUT'
    })
    return StartProfileCreationResponse.guestGuid
  }
}
/**
 * @deprecated - should be deleted once passwordless auth is fully rolled out
 */
export enum CREATE_ACCOUNT_ERROR {
  EMAIL_IN_USE = 'EMAIL_IN_USE'
}

/**
 * @deprecated - should be deleted once passwordless auth is fully rolled out
 * @returns {Promise<*>}
 */
export const handleCreateAccount = async ({
  createAccount,
  ccInput,
  password,
  redirectUrl,
  restaurantGuid,
  setErrorMessage,
  history,
  setUnverifiedCustomer
}) => {
  const createAccountInput = getCreateAccountInput({
    ccInput,
    password,
    redirectUrl,
    restaurantGuid
  })

  const accountCreationResp = await createAccount({
    variables: { input: createAccountInput }
  })
  const { CreateAccountResponse, CreateAccountError } = dataByTypename(
    accountCreationResp.data.createAccount
  )
  if (CreateAccountError) {
    if (CreateAccountError.code === CREATE_ACCOUNT_ERROR.EMAIL_IN_USE) {
      history.push('?mode=login', { createAccountRedirect: true })
      // Returning error code here so CheckoutPage knows not to go through with
      // the order on this error only and instead opens the login modal.
      // This pattern should NOT be repeated unless absolutely necessary.
      return CREATE_ACCOUNT_ERROR.EMAIL_IN_USE
    } else {
      setErrorMessage(CreateAccountError)
    }
  } else {
    setUnverifiedCustomer({
      guid: CreateAccountResponse.customerGuid,
      signupSource: 'SAVE_CREDIT_CARD'
    })
    return CreateAccountResponse.customerGuid
  }
}

export const handlePlaceOrder = async ({
  placeOrder,
  cart,
  cashInput,
  ccInput,
  paymentType,
  getRestaurantPath,
  customerGuid,
  deleteCartGuid,
  setErrorMessage,
  history,
  trackPurchase,
  logCriticalError,
  passwordlessFlow
}) => {
  if (paymentType === 'CREDIT') {
    ccInput.cartGuid = cart.guid
    if (customerGuid) {
      ccInput.customerGuid = customerGuid
    }
  } else {
    cashInput.cartGuid = cart.guid
  }

  const resp = await placeOrder({
    variables: { cashInput, ccInput, paymentType }
  })
  const { PlaceOrderResponse, PlaceOrderError, PlaceOrderCartUpdatedError } =
    dataByTypename(resp.data.placeOrder)
  const error = PlaceOrderError || PlaceOrderCartUpdatedError
  if (error) {
    if (error.code === 'CRITICAL_ERROR') {
      logCriticalError(SERVICES.PLACE_ORDER)
    }
    setErrorMessage(error)
  } else {
    const { guid } = PlaceOrderResponse.completedOrder
    trackPurchase({
      ...PlaceOrderResponse.completedOrder,
      createPasswordlessAccount: passwordlessFlow
    })
    deleteCartGuid()
    if (passwordlessFlow) {
      const guestPhone = ccInput?.customer?.phone || cashInput?.customer?.phone
      history.replace(getRestaurantPath(`confirm/${guid}`), {
        from: FROM_LOCATION.createAccountCheckout,
        guestPhone
      })
    } else {
      history.replace(getRestaurantPath(`confirm/${guid}`))
    }
  }
}
