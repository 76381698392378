import * as React from 'react'

import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import { PASSWORDLESS_ACCOUNT } from '@local/do-secundo-terms-modal'
import { CheckboxWithTermsModal } from '@local/do-secundo-form'

export interface CreateAccountCheckboxProps {
  testId?: string | number
  phone?: string
  name?: string
  id?: string
  children?: React.ReactNode
  onSelect?: Function // custom onChange handler for the underlying Checkbox component
}

export const CreateAccountCheckbox = ({
  testId = `CreateAccountCheckbox`,
  phone = '',
  name = 'shouldSavePasswordlessAccount',
  id = 'save_passwordless_account_checkbox',
  onSelect
}: CreateAccountCheckboxProps) => {
  return (
    <div className='px-4 py-3 mx-2 mb-4 bg-gray-0'>
      <CheckboxWithTermsModal
        onSelect={onSelect}
        testId={testId}
        name={name}
        id={id}
        Icon={
          <div className='flex justify-center py-4'>
            <ToastLogo size='lg' className='rounded-full ' />
          </div>
        }
        terms={PASSWORDLESS_ACCOUNT}
        children={
          <div className='py-4 border-t'>
            We will send you a one-time verification code to&nbsp;
            <strong>{phone || 'your phone number'}</strong> to complete your
            account. Your phone number will also be used to help authenticate
            you as part of logging into your account.
          </div>
        }
      />
    </div>
  )
}
