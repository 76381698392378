import * as React from 'react'
import { Field } from 'formik'
import { Input } from '@local/do-secundo-form'

export interface EmailInputProps {
  testId?: string
  id?: string
  label?: string
  name?: string
}

export const EmailInput: React.FC<EmailInputProps> = ({
  testId = 'email-input',
  id = 'customer_email',
  label = 'Email*',
  name = 'email'
}) => {
  return (
    <Field
      autoComplete='section-customer email'
      data-testid={testId}
      id={id}
      label={label}
      name={name}
      type='email'
      component={Input}
    />
  )
}
