import React from 'react'
import PropTypes from 'prop-types'

import { SearchBanner } from '../../SearchBanner/SearchBanner'
import styles from './NoSearchResultsBanner.module.css'

export const NoSearchResultsBanner = ({ term }) => {
  return (
    <SearchBanner>
      <div>
        <p>
          <strong>We couldn't find results for</strong>
        </p>
        <p data-testid='empty-search-term'>
          <strong>“{term}”</strong>
        </p>
        <p className={styles.separatedLine}>Try searching for something else</p>
      </div>
    </SearchBanner>
  )
}

NoSearchResultsBanner.propTypes = {
  term: PropTypes.string
}
