import { useEffect } from 'react'
import { useFuseSearch } from '@toasttab/buffet-use-fuse-search'
import uniqBy from 'lodash/uniqBy'

export const usePageSearch = ({
  list = [],
  keys,
  deduplicate,
  initialValue,
  ...otherProps
}) => {
  const { results, setTerm, term } = useFuseSearch({
    list,
    keys,
    options: {
      ignoreLocation: true
    },
    ...otherProps
  })

  // if deduplicate callback provided - only show unique items
  const listOfItems = deduplicate ? uniqBy(results, deduplicate) : results

  useEffect(() => {
    setTerm(initialValue)
  }, [initialValue, setTerm])

  return { term, results: listOfItems, setTerm }
}
