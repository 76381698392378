import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ServicePeriods } from '../ServicePeriods/ServicePeriods'
import { DayOfWeek } from '../../DayOfWeek/DayOfWeek'

import styles from './DiningOptionHours.module.css'

export const DiningOptionHours = ({ schedule = [] }) => {
  if (!schedule || !schedule.length > 0) return <p>No Hours Available</p>
  return (
    <table className={styles.table}>
      <tbody>
        {schedule.map(({ date, servicePeriods }, index) => (
          <tr className={cx({ [styles.today]: !index }, styles.day)} key={date}>
            <td>
              <DayOfWeek isoDate={date} />
            </td>
            <td className={styles.hours}>
              <ServicePeriods servicePeriods={servicePeriods} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

DiningOptionHours.propTypes = {
  schedule: PropTypes.array
}
