import { useEffect, useRef, useState } from 'react'

import { useFulfillment } from '../../FulfillmentProvider/FulfillmentProvider'

export const useSavedAddressUpdater = ({
  savedAddresses = [],
  savedAddressGuid
}) => {
  const { setDeliveryInfo, updating } = useFulfillment()
  const [error, setError] = useState(null)
  const refs = useRef({ savedAddresses, setDeliveryInfo })

  useEffect(() => {
    refs.current = { savedAddresses, setDeliveryInfo }
  }, [savedAddresses, setDeliveryInfo])

  // setDeliveryInfo() is idempotent and therefore safe to call on mount.
  useEffect(() => {
    const currentRefs = refs.current
    const onAddressChange = async (newSavedAddressGuid) => {
      setError(null)

      if (!newSavedAddressGuid) {
        return
      }

      try {
        const newSavedAddress = currentRefs.savedAddresses.find(
          (address) => address.guid === newSavedAddressGuid
        )

        if (!newSavedAddress) {
          throw new Error(`Unknown saved address: ${newSavedAddressGuid}`)
        }

        await currentRefs.setDeliveryInfo({
          savedAddressGuid: newSavedAddress.guid,
          deliveryInfo: newSavedAddress.deliveryInfo
        })
      } catch (e) {
        setError(e)
      }
    }

    onAddressChange(savedAddressGuid)
  }, [savedAddressGuid])

  return { error, updating }
}
