import * as React from 'react'

const buildStringFromArray = (vals: string[]) =>
  vals.map((el) => (el ? el : ' ')).join('')

export interface handleOnChangeProps {
  e: React.FormEvent<HTMLInputElement>
  numFields: number
  setFieldValue: (key: string, value: string) => void
  refs: React.RefObject<HTMLInputElement>[]
  codeValues: string[]
  autoFocusIndex: React.MutableRefObject<number>
  resetFocusAfterCompletion?: boolean
}

export const handleOnChange = ({
  e,
  numFields,
  setFieldValue,
  refs,
  codeValues,
  autoFocusIndex,
  resetFocusAfterCompletion = true
}: handleOnChangeProps) => {
  if (!(e.target instanceof HTMLInputElement)) return
  const index = parseInt(e.target.dataset.id!)
  e.target.value = e.target.value.replace(/[^0-9]/gi, '')
  const checkForNum = parseInt(e.target.value, 10)

  if (isNaN(checkForNum)) return
  if (!e.target.validity.valid) return

  let next: React.RefObject<HTMLInputElement>
  const value = e.target.value
  let vals: string[] = [...codeValues]

  if (value.length > 1) {
    let nextIndex = value.length + index
    autoFocusIndex.current = nextIndex
    if (nextIndex >= numFields) {
      autoFocusIndex.current = 0
    }
    next = refs[nextIndex]
    const split = value.split('')

    split.forEach((item: string, i: number) => {
      const cursor = index + i
      if (cursor < numFields) {
        vals[cursor] = item
      }
    })

    const newVal = buildStringFromArray(vals)
    setFieldValue('codeValues', newVal)
  } else {
    next = refs[index + 1]
    autoFocusIndex.current = index + 1
    vals[index] = value
    const newVal = buildStringFromArray(vals)
    setFieldValue('codeValues', newVal)
  }

  if (next) {
    next.current!.focus()
    next.current!.select()
  } else {
    if (resetFocusAfterCompletion) {
      autoFocusIndex.current = 0
      refs[0].current?.focus()
      refs[0].current?.select()
    }
  }
}

export interface handleKeyDownProps {
  e: React.KeyboardEvent<HTMLInputElement>
  setFieldValue: (key: string, value: string) => void
  refs: React.RefObject<HTMLInputElement>[]
  codeValues: string[]
  autoFocusIndex: React.MutableRefObject<number>
}

export const KEY_CODE = {
  BACKSPACE: 8,
  DELETE: 46,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
}

export const handleKeyDown = ({
  e,
  setFieldValue,
  refs,
  codeValues,
  autoFocusIndex
}: handleKeyDownProps) => {
  if (!(e.target instanceof HTMLInputElement)) return
  const index = parseInt(e.target.dataset.id!)
  const prevIndex = index - 1
  const prev = refs[prevIndex]
  const nextIndex = index + 1
  const next = refs[nextIndex]

  switch (e.keyCode) {
    case KEY_CODE.BACKSPACE:
    case KEY_CODE.DELETE:
      e.preventDefault()
      const vals = [...codeValues]
      codeValues[index] = codeValues[index].replace(' ', '')

      if (codeValues[index]) {
        vals[index] = ''
        const newVal = buildStringFromArray(vals)
        setFieldValue('codeValues', newVal)
      } else if (prev) {
        vals[prevIndex] = ''
        prev.current!.focus()
        const newVal = buildStringFromArray(vals)
        setFieldValue('codeValues', newVal)
      }
      autoFocusIndex.current = prevIndex
      break
    case KEY_CODE.LEFT:
    case KEY_CODE.RIGHT:
    case KEY_CODE.UP:
    case KEY_CODE.DOWN:
      e.preventDefault()
      break
    default:
      if (e.key === e.target.value) {
        e.preventDefault()
        if (next) {
          autoFocusIndex.current = nextIndex
          next.current!.focus()
        }
      }
      break
  }
}
