import React from 'react'
import PropTypes from 'prop-types'
import { formatPhoneNumber } from '../../../utils/phone-number'
import styles from './PlaceOrderDegradedMessage.module.css'

export const PlaceOrderDegradedMessage = ({ restaurantPhone = null }) => {
  const displayLine = restaurantPhone ? (
    <p>
      Call the restaurant to order{' '}
      <a href={`tel:${restaurantPhone}`}>
        {formatPhoneNumber(restaurantPhone)}
      </a>
    </p>
  ) : (
    <p>Contact the restaurant to order</p>
  )
  return (
    <div className={styles.failedOrder}>
      <p>Unable to submit the order?</p>
      {displayLine}
    </div>
  )
}

PlaceOrderDegradedMessage.propTypes = {
  restaurantPhone: PropTypes.string
}
