import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import { ErrorMessage } from '@local/do-secundo-form'
import { MenuItemPrice } from '@local/do-secundo-menu-item-price'

import { trimToDecimalValue } from '@local/do-secundo-form-utils'

import styles from './FractionalItemQuantity.module.css'

const isTouched = (fields, fieldId) => fields[fieldId]

export const FractionalItemQuantity = ({ field, form, ...props }) => {
  const { value } = field
  const { unitOfMeasure, price } = props

  const handleInputChange = ({ target }) => {
    form.setFieldValue(field.name, trimToDecimalValue(target.value, 2) || '')
    form.setFieldTouched(field.name, true)
  }

  const handleFocus = () => {
    if (!isTouched(form.touched, 'fractionalQuantity')) {
      form.setFieldValue(field.name, '', false)
    }
  }

  const updateForm = () => {
    form.setFieldValue(field.name, String(Number(value).toFixed(2)))
  }

  const displayUnitOfMeasure = unitOfMeasure.toLowerCase()

  const error = form.errors[field.name]

  return (
    <div data-testid='fractional-item-quantity' className={styles.wrapper}>
      <div className={styles.descriptionWrapper}>
        <div className={styles.description}>Quantity</div>
        {error && <ErrorMessage name={field.name} />}
      </div>
      <div className={styles.inputWrapper}>
        <input
          className={cx(styles.input, { [styles.error]: error })}
          id='fractional_quantity_input'
          name='FractionalQuantityInput'
          type='number'
          step='any'
          min='0.00'
          placeholder='0.00'
          inputMode='decimal'
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={updateForm}
        />
        <label
          id='fractional_quantity_input_label'
          className={styles.inputLabel}
          htmlFor='FractionalQuantityInput'
        >
          {displayUnitOfMeasure}
        </label>
      </div>
      <div className={styles.price}>
        <MenuItemPrice
          usesFractionalQuantity
          price={price}
          unitOfMeasure={unitOfMeasure}
        />
      </div>
    </div>
  )
}

const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

FractionalItemQuantity.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired,
  unitOfMeasure: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired
}
