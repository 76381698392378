import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { Radio } from '@local/do-secundo-form'
import { DeliveryProviderLabel } from '../../../DeliveryProviderLabel/DeliveryProviderLabel'

import styles from './DiningOption.module.css'

export const DiningOption = ({ id, value, label, deliveryProvider }) => {
  return (
    <div className={styles.radio}>
      <Field
        id={id}
        key={id}
        name='diningOptionBehavior'
        value={value}
        component={Radio}
        style='chunky'
        label={
          <div>
            <span>{label}</span>
          </div>
        }
      />
      <DeliveryProviderLabel deliveryProvider={deliveryProvider} />
    </div>
  )
}

DiningOption.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  deliveryProvider: PropTypes.string
}
