import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import { ErrorComponent } from '@local/do-secundo-error'
import { ModeRedirect } from '../ModeRouter/utils'
import { verifyEmail } from './VerifyEmail.graphql'
import { useAuth } from '../AuthProvider/AuthProvider'
import { dataByTypename } from '../../utils/apollo-helpers'
import Checkmark from '../../assets/checkmark.svg'

import styles from './VerifyEmail.module.css'

export const VerifyEmail = ({ onClose }) => {
  const search = new URLSearchParams(window.location.search)
  const verificationToken = search.get('confirmToken')
  const { authenticate, loading } = useAuth()
  const [mutate, result] = useMutation(verifyEmail, {
    variables: { input: { verificationToken } },
    onError: () => {},
    onCompleted: (data) => {
      if (data) {
        const { AuthenticationResponse } = dataByTypename(data.verifyEmail)

        if (AuthenticationResponse) {
          const handleInput = {
            variables: {
              input: AuthenticationResponse
            }
          }
          authenticate(handleInput)
          toast(
            <span className={styles.toast}>
              <Checkmark className={styles.checkmark} />
              Account verified. You're now logged in.
            </span>
          )
          onClose()
        }
      }
    }
  })

  useEffect(() => {
    mutate()
  }, [mutate])

  if (loading) return null

  const { data, error, refetch } = result
  if (error) {
    return <ErrorComponent error={error} retry={refetch} />
  }

  if (data) {
    const { VerifyEmailError } = dataByTypename(data.verifyEmail)
    if (VerifyEmailError && VerifyEmailError.code === 'TOKEN_ALREADY_USED') {
      return (
        <ModeRedirect mode='login' state={{ verifyAccountRedirect: true }} />
      )
    }
    if (VerifyEmailError) {
      return <ModeRedirect mode='create' state={{ expired: true }} />
    }
  }
  return null
}

VerifyEmail.propTypes = {
  onClose: PropTypes.func.isRequired
}
