import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { FeedbackBanner } from '../GuestFeedback/FeedbackBanner/FeedbackBanner'
import { FeedbackResponseBanner } from '../GuestFeedback/FeedbackResponseBanner/FeedbackResponseBanner'
import { TagSelector } from './TagSelector/TagSelector'
import { Textarea } from '@local/do-secundo-form'
import { RadioGroup } from '../Form/RadioGroup/RadioGroup'

import {
  feedbackGroup,
  scores,
  tagOptions,
  tags
} from '../GuestFeedback/feedbackConstants'

import styles from './FeedbackForm.module.css'

const initialValues = {
  feedbackRating: '',
  feedbackReasons: [],
  feedbackFreeFormText: ''
}

export const validationSchema = Yup.object({
  feedbackRating: Yup.string().required().oneOf(scores),
  feedbackReasons: Yup.array()
    .of(Yup.string().oneOf(tagOptions))
    .required('Required'),
  feedbackFreeFormText: Yup.string().when('feedbackReasons', {
    is: (feedbackReasons) => feedbackReasons.includes(tags.OTHER),
    then: Yup.string().required('*Required')
  })
})

export const FeedbackForm = ({ onSubmit }) => {
  return (
    <div className={styles.feedbackContainer}>
      <FeedbackBanner />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const { values, isValid, setFieldValue, isSubmitting } = formikProps
          const renderCommentInput = values.feedbackReasons.includes('Other')
          const scoreSelected = values.feedbackRating.length > 0
          return (
            <Form>
              <RadioGroup
                group={feedbackGroup}
                name='feedbackRating'
                style='svg'
              />
              {scoreSelected && (
                <div>
                  <FeedbackResponseBanner
                    feedbackRating={values.feedbackRating}
                  />
                  <TagSelector
                    values={values}
                    setFieldValue={setFieldValue}
                    name='feedbackReasons'
                    tagOptions={tagOptions}
                  />
                </div>
              )}
              {renderCommentInput && (
                <div>
                  <Field
                    id='feedbackFreeFormText'
                    name='feedbackFreeFormText'
                    placeholder='Please share more about your experience.'
                    component={Textarea}
                  />
                </div>
              )}
              {scoreSelected && (
                <div className={styles.buttonWrapper}>
                  <Button
                    loading={isSubmitting}
                    variant={ButtonVariant.PRIMARY}
                    disabled={!isValid}
                    type={ButtonType.SUBMIT}
                    responsive
                  >
                    Share Feedback
                  </Button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

FeedbackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
