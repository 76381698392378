import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { MenuItems } from '../Menus/MenuItems/MenuItems'
import { SearchBannerContainer } from './SearchBannerContainer/SearchBannerContainer'
import { SearchInput } from '../SearchInput/SearchInput'
import { SearchContainer } from './SearchContainer/SearchContainer'
import IntersectionObserver from '@researchgate/react-intersection-observer'

import styles from './Search.module.css'

const defaultLengthToRender = 10
const defaultIncrement = 7
export const SearchConsumer = (props) => {
  const { term, menus, loading, results, setTerm, initialValue } = props
  const noItemsFound = !results.length && !!term && !loading
  const inputIsEmpty = !term && !loading
  const [lengthToRender, setLengthToRender] = useState(defaultLengthToRender)

  const handleScroll = useCallback((entry) => {
    if (entry.isIntersecting) {
      setLengthToRender((current) => current + defaultIncrement)
    }
  }, [])

  useEffect(() => {
    setLengthToRender(defaultLengthToRender)
  }, [term])

  const slicedResults = results.slice(0, lengthToRender)
  return (
    <div className={styles.page}>
      <div className={styles.searchInput}>
        <SearchInput
          placeholder='Search for an item...'
          setTerm={setTerm}
          initialValue={initialValue}
        />
      </div>
      <div className={styles.resultsContainer}>
        <MenuItems items={term && term.length ? slicedResults : []} />
        <SearchBannerContainer
          menus={menus}
          loading={loading}
          noItemsFound={noItemsFound}
          inputIsEmpty={inputIsEmpty}
          term={term}
        />
      </div>
      <IntersectionObserver onChange={handleScroll}>
        <div data-testid='anchor' />
      </IntersectionObserver>
    </div>
  )
}

SearchConsumer.propTypes = {
  term: PropTypes.string,
  loading: PropTypes.bool,
  results: PropTypes.array,
  setTerm: PropTypes.func,
  initialValue: PropTypes.string
}

export const Search = () => {
  return <SearchContainer component={SearchConsumer} />
}
