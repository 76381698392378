import * as React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'

import styles from './MealSelectorBody.module.css'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { MealSelectorSelections } from '../MealSelectorSelections/MealSelectorSelections'
import { MealSelectorMenuGroups } from '../MealSelectorMenuGroups/MealSelectorMenuGroups'
import { useMenuRandomizer } from './use-menu-randomizer'
import { useCheckboxes } from './use-checkboxes'
import { useRestaurantStorage } from '../../../utils/restaurant-storage'

/**
 * Contains primary logic for MealSelector in addition to
 * MealSelectorMenuGroups, MealSelectorSelections, and action buttons
 * @param {array} menus
 */
const MealSelectorBody = ({ menus }) => {
  const history = useHistory()
  const { getRestaurantPath } = useRestaurant()
  const restaurantStorage = useRestaurantStorage()
  const { randomMenu, setRandomMenu } = useMenuRandomizer()

  const flatMenu = menus.flatMap((menu) => menu.groups)
  const filteredFlatMenu = flatMenu.filter(({ items }) => {
    const menuGroupsWithContent = items.filter(({ outOfStock }) => !outOfStock)
    return menuGroupsWithContent.length > 0 ? menuGroupsWithContent : null
  })

  const hasNoSelectedMenus = randomMenu.length <= 0

  const { checkboxes, setCheckbox } = useCheckboxes(filteredFlatMenu)

  const selectedMenuGroups = checkboxes.filter((t) => t.checked)

  const addToCart = (randomMenu) => {
    // clear restaurantStorage token
    restaurantStorage.remove('toast-oo-meal-selector')
    // get guid and itemGroupGuid for each item in menu
    const menuGuids = randomMenu.map(({ guid, itemGroupGuid }) => {
      return { guid, itemGroupGuid }
    })
    // remove the first
    const firstRemoved = menuGuids.shift()
    // then destructure its guid and itemGroupGuid
    const { guid, itemGroupGuid } = firstRemoved
    // set the remaining array in restaurantStorage
    restaurantStorage.set('toast-oo-meal-selector', JSON.stringify(menuGuids))
    // redirect to add item page for first item (which was shifted out)
    history.push(getRestaurantPath(`add/${guid}/${itemGroupGuid}`))
  }

  return (
    <div className={styles.mealSelectorBody}>
      <div>
        <MealSelectorMenuGroups
          checkboxes={checkboxes}
          setCheckbox={setCheckbox}
          menus={menus}
        />
        <MealSelectorSelections randomMenu={randomMenu} />
      </div>
      <div className={styles.footer}>
        <Button
          type={ButtonType.BUTTON}
          variant={ButtonVariant.PRIMARY}
          responsive
          data-testid='oo-v3-meal-selector-shuffle-button'
          disabled={selectedMenuGroups.length === 0}
          onClick={() => setRandomMenu(selectedMenuGroups)}
        >
          {hasNoSelectedMenus ? 'Generate selections' : 'Shuffle'}
        </Button>
        <Button
          type={ButtonType.BUTTON}
          variant={ButtonVariant.PRIMARY}
          responsive
          data-testid='oo-v3-meal-selector-add-to-cart-button'
          disabled={hasNoSelectedMenus}
          onClick={() => addToCart(randomMenu)}
        >
          Add to cart
        </Button>
      </div>
    </div>
  )
}

MealSelectorBody.propTypes = {
  menus: PropTypes.array
}

export { MealSelectorBody }
