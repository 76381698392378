import React from 'react'
import PropTypes from 'prop-types'

import { RULE_TYPES, ApprovalRuleShape } from './approval-rule-helpers'
import { useApprovalRulesValidator } from './use-approval-rules-validator'
import { DeliveryRule } from './DeliveryRule/DeliveryRule'

import styles from './ApprovalRules.module.css'

export const ApprovalRules = ({ cart, rectifyApprovalRuleLink }) => {
  const { ruleMap, valid } = useApprovalRulesValidator({ cart })

  if (valid) {
    return null
  }

  const deliveryRule = ruleMap[RULE_TYPES.DELIVERY_LT]

  return (
    <div className={styles.approvalRules}>
      {deliveryRule && (
        <DeliveryRule
          rectifyLink={rectifyApprovalRuleLink}
          rule={deliveryRule}
        />
      )}
    </div>
  )
}

ApprovalRules.propTypes = {
  cart: PropTypes.shape({
    approvalRules: PropTypes.arrayOf(ApprovalRuleShape)
  }),
  rectifyApprovalRuleLink: PropTypes.string
}
