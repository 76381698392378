import isNil from 'lodash/isNil'
import { getExperimentsStorage } from '../experiments-storage'
import { FF } from '@local/do-secundo-feature-flag'
import { createWeightedBucket, createVariantBucket } from './utils'

interface ExperimentDefinition {
  experimentName: string
  flagName: string
  useInitVariant(): number
  getTreatment(variant: number): any
}

/**********************************************
Experiment Name: Guest Feedback
Description: Let 5% of the users submit feedback about the platform
Variants:
  0: Control - No Treatment - 95%
  1: Guest Feedback is visible - 10%
**********************************************/
export const XP_GUEST_FEEDBACK: ExperimentDefinition = {
  experimentName: 'xp-guest-feedback',
  flagName: FF.GUEST_FEEDBACK,
  useInitVariant() {
    const ctx = XP_GUEST_FEEDBACK
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    const dice = Math.random()
    return dice < 0.1 ? 1 : 0
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Passwordless Authentication: Default Opt-In at OO Checkout
Description: Opt 5% of the users on checkout to default their create account checkbox to on
Variants:
  0: Control - No Treatment - 50%
  1: Default opt-in - 50%
**********************************************/
export const XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX: ExperimentDefinition = {
  experimentName: 'xp-default-create-account-checkbox',
  flagName: FF.XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX,
  useInitVariant() {
    const ctx = XP_DEFAULT_CREATE_ACCOUNT_CHECKBOX
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }

    const variant = createWeightedBucket(1, 1)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/************************************************
Experiment Name: Intelligent Upsells
Description: 50% of guests should see intelligent upsells along with their standard upsell treatment
Variants:
  0: Control - No intelligent upsells - 50%
  1: Intelligent Upsells are used when present - 50%
************************************************/
export const XP_INTELLIGENT_UPSELLS: ExperimentDefinition = {
  experimentName: 'xp-intelligent-upsells',
  flagName: FF.CART_UPSELLS,
  useInitVariant() {
    const ctx = XP_INTELLIGENT_UPSELLS
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // Get a bucket and store it for future use.
    const variant = createVariantBucket(2, Math.random().toString())
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/************************************************
Experiment Name: Meal Selector
Description: 50% of guests will see Meal Selector Prompt on rx header
Variants:
  0: Control - No meal selector - 50%
  1: Prompt on rx header to open meal selector modal - 50%
************************************************/
export const XP_MEAL_SELECTOR: ExperimentDefinition = {
  experimentName: 'xp-meal-selector',
  flagName: FF.MEAL_SELECTOR,
  useInitVariant() {
    const ctx = XP_MEAL_SELECTOR
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // Get a bucket and store it for future use.
    const variant = createVariantBucket(2, Math.random().toString())
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Menu items sort
Description: Let 50% of the users see sorted menu items.
25% will see items with images on top,
25% items with descriptions on top,
Variants:
  0: Control - No Treatment - 50%
  1: Items with images are higher in the menu group - 25%
  2: Items with descriptions are higher in the menu group - 25%
**********************************************/
export const XP_MENU_ITEMS_SORT: ExperimentDefinition = {
  experimentName: 'xp-menu-item-sort',
  flagName: FF.MENU_ITEM_SORT,
  useInitVariant() {
    const ctx = XP_MENU_ITEMS_SORT
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }

    // 50% of guests will get 0 variant
    const variant = createWeightedBucket(2, 1, 1)

    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Combined menu groups.
Description: 50% of the users will be presented with a navbar that contains all the menu groups. Menu selector will be hidden.
Variants:
  0: Control - No Treatment - 50%
  1: Combined all menu groups inside of the horizontal nav bar and hide menu selector.
**********************************************/
export const XP_COMBINED_MENU_GROUPS: ExperimentDefinition = {
  experimentName: 'xp-combined-menu-groups',
  flagName: FF.COMBINED_MENU_GROUPS,
  useInitVariant() {
    const ctx = XP_COMBINED_MENU_GROUPS
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // 50% of guests will get 0 variant
    const variant = createWeightedBucket(1, 1)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Price transparency CTA.
Description: 50% of the users will be presented with alternative copy of CTA.
Variants:
  1: Control - 50% - shows old copy.
  2: Test - 50% - shows price transparency phrased copy.
**********************************************/
export const XP_PT_CTA: ExperimentDefinition = {
  experimentName: 'xp-price-transparency-cta',
  flagName: FF.PT_CTA,
  useInitVariant() {
    const ctx = XP_PT_CTA
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // skipping 0 variant to simplify rendering logic since we also keep old CTA
    const variant = createWeightedBucket(0, 1, 1)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: XP_BRANDED_HEADER_LOGIN_BUTTON.
Description: Users will see a branded header login button
Variants:
  0: Control - No Treatment - 90%
  1: Header Login Button treatment - 5%
  2: Header Login Button text only - 5%
**********************************************/
export const XP_BRANDED_HEADER_LOGIN_BUTTON: ExperimentDefinition = {
  experimentName: 'xp-branded-header-login-button',
  flagName: FF.XP_BRANDED_HEADER_LOGIN_BUTTON,
  useInitVariant() {
    const ctx = XP_BRANDED_HEADER_LOGIN_BUTTON
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }

    const variant = createWeightedBucket(90, 5, 5)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Price Transparency.
Description: Users will see strikethrough price or no strikethrough price
Variants:
  0: Control - No Treatment - 50.0%
  1: Treatment
**********************************************/
export const XP_PRICE_TRANSPARENCY: ExperimentDefinition = {
  experimentName: 'xp-price-transparency',
  flagName: FF.PRICE_TRANSPARENCY,
  useInitVariant() {
    const ctx = XP_PRICE_TRANSPARENCY
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // each variant has a 50% chance of applying
    const variant = createWeightedBucket(1, 1)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Promo Banners
Description: 90% of the users will be presented with promo banners on their menu page based on promo code and loyalty configs
Variants:
  0: Control - No Treatment - 10%
  1: Promo banners shown - 90%
**********************************************/
export const XP_PROMO_BANNERS: ExperimentDefinition = {
  experimentName: 'xp-promo-banners',
  flagName: FF.XP_PROMO_BANNERS,
  useInitVariant() {
    const ctx = XP_PROMO_BANNERS
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // 10% not shown, 90% shown
    const variant = createWeightedBucket(1, 9)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Menu Badges
Description: X% of the users will be presented with badges on their menus based on item based offers
Variants:
  0: Control - No Treatment - 20%
  1: Promo banners shown - 80%
**********************************************/
export const XP_MENU_BADGES: ExperimentDefinition = {
  experimentName: 'xp-menu-badges',
  flagName: FF.MENU_BADGES,
  useInitVariant() {
    const ctx = XP_MENU_BADGES
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    // 20% not shown, 80% shown
    const variant = createWeightedBucket(1, 4)
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}

/**********************************************
Experiment Name: Consumer App fullscreen CTA 
Description: Displays a fullscreen CTA to install the Takeout App
Variants:
  0: Control - No Treatment - 50%
  1: Fullscreen CTA
**********************************************/
export const XP_CA_FULLSCREEN_CTA: ExperimentDefinition = {
  experimentName: 'xp-ca-fullscreen-cta',
  flagName: FF.OO_CA_FULLSCREEN_CTA,
  useInitVariant() {
    const ctx = XP_CA_FULLSCREEN_CTA
    const experimentStorage = getExperimentsStorage()
    const storedVariant = experimentStorage.get([ctx.experimentName])
    // allows to manually override variant for testing purposes
    if (!isNil(storedVariant)) {
      return storedVariant
    }
    const dice = Math.random()
    const variant = dice < 0.5 ? 1 : 0
    experimentStorage.set(ctx.experimentName, variant)
    return variant
  },
  getTreatment(variant) {
    return variant
  }
}
