import React from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  AuthFormPhoneAndEmail,
  AuthFormPhoneAndEmailSubmitValues
} from '@local/do-secundo-auth-form'
import { LoginPrompt } from '@local/do-secundo-identity-prompts'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { unifiedLoginValidationSchema } from '../../../../client/utils/form-schemas'
import {
  deleteTokens,
  accountLinkingStorage
} from '../../../../client/apollo/authentication/authentication-helpers'

import { useAuth } from '../../../../client/components/AuthProvider/AuthProvider'
import {
  handleUnifiedEmailSubmit,
  handleUnifiedPhoneSubmit,
  FROM_LOCATION
} from '../utils'

export interface UnifiedLoginModalProps {
  onClose: () => void
  buttonText?: string
  includeEmail?: boolean
  handleSubmitError?: (error: { Error: string }) => void
}

export const UnifiedLoginModal = ({
  onClose,
  handleSubmitError,
  buttonText = 'Continue'
}: UnifiedLoginModalProps) => {
  const { startPasswordlessLogin, searchForCustomermgmtAccount } = useAuth()
  const history = useHistory()
  const { state = {} } = useLocation()

  const handleSubmitLogin = async (
    values: AuthFormPhoneAndEmailSubmitValues,
    isPhone = false
  ) => {
    if (isPhone) {
      return handleUnifiedPhoneSubmit({
        values: { phone: values.phoneOrEmail },
        from: FROM_LOCATION.login,
        startPasswordlessLogin,
        history
      })
    } else {
      return handleUnifiedEmailSubmit({
        values: { email: values.phoneOrEmail.trim() },
        searchForCustomermgmtAccount,
        history
      })
    }
  }
  const phone = state.guestPhone || ''

  // we need to delete accountLinkingStorage because they may have abandoned
  // the account linking flow previously and now their credentials are out of date
  // and will result in throwing an InvalidJWT.
  React.useEffect(() => {
    deleteTokens(accountLinkingStorage)
  }, [])

  const disclaimer = (
    <p className='type-caption'>
      If phone number is entered you will receive a one-time code
    </p>
  )

  return (
    <PoweredByToastModal
      className='h-full'
      onClose={onClose}
      header={
        <h2 className='mx-0 mt-0 mb-3 type-headline-4'>
          Enter your phone number or email
        </h2>
      }
    >
      <div
        className='flex flex-col justify-between h-full m-auto'
        data-testid='sign-up-prompt-container'
      >
        <LoginPrompt
          form={
            <AuthFormPhoneAndEmail
              handleSubmit={handleSubmitLogin}
              initialValues={{ phoneOrEmail: '' }}
              validationSchema={unifiedLoginValidationSchema}
              buttonText={buttonText}
              handleSubmitError={handleSubmitError}
              testId={`unified-login-auth-form`}
              disclaimer={disclaimer}
            />
          }
        />
      </div>
    </PoweredByToastModal>
  )
}
