import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { useAuth } from '../AuthProvider/AuthProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useReorder } from '../Reorder/ReorderProvider/ReorderProvider'
import { ReorderCard } from '../Reorder/ReorderCard/ReorderCard'
import { ErrorComponent } from '@local/do-secundo-error'

import styles from './ReorderBanner.module.css'
import { useReorderQuery } from '../ReorderQuery/ReorderQuery'

export const ReorderBannerPasswordless = () => {
  const { authenticated } = useAuth()
  const { getRestaurantPath } = useRestaurant()
  const { data } = useReorderQuery()

  const {
    reorderMutation: [, { error }]
  } = useReorder()

  if (!authenticated || !data || data.orderHistory.orders.length === 0) {
    return null
  }

  const ordersLink = getRestaurantPath('orders')
  //We're getting 3 orders. 1 and 2 are used for renders; 3 is used to determine if we need to show View More
  const orders = data.orderHistory.orders.slice(0, 2)

  const showViewMoreOnDesktop = data.orderHistory.orders.length > 2
  const showViewMoreOnMobile = data.orderHistory.orders.length > 1

  return (
    <div
      className={cx(styles.container, 'bg-gray-0')}
      role='group'
      aria-label='reorder'
    >
      <div className={styles.wrap}>
        <div className={styles.header}>
          <h2 className={styles.title}>Order Again</h2>
          <Link
            to={ordersLink}
            className={cx(styles.viewMore, {
              [styles.desktop]: showViewMoreOnDesktop,
              [styles.mobile]: showViewMoreOnMobile
            })}
          >
            View More
          </Link>
        </div>
        {Boolean(error) && <ErrorComponent error={error} />}
        <ul className={styles.orderList}>
          {orders.map((order, idx) => (
            <ReorderCard
              key={order.guid}
              orderGuid={order.guid}
              items={order.selections}
              price={order.totalV2}
              date={order.estimatedFulfillmentDate}
              hideOnMobile={idx > 0}
            />
          ))}
        </ul>
      </div>
    </div>
  )
}
