import { useAddRedemption, useRemoveRedemption } from '../../../use-loyalty'

/**
 * @typedef {Object} UseLoyaltyRedemptionValues
 * @property {function} addRedemption - Apollo mutation callback for applying the redemption
 * @property {function} removeRedemption - Apollo mutation callback for removing the redemption
 * @property {boolean} addLoading - Apollo mutation loading state for applying redemption
 * @property {boolean} removeLoading - Apollo mutation loading state for removing redemption
 * @property {?Error} addError - Error yielded from addRedemption mutation
 * @property {?Error} removeError - Error yielded from removeRedemption mutation
 * @property {boolean} redemptionOnCart - True if redemption on cart, false otherwise
 */

/**
 * @typedef {Object} LoyaltyRedemptionInput
 * @property {string} type
 * @property {string} guid
 */

/**
 * @param {Object} options - Hook options
 * @param {Object} options.cart - A cart object
 * @param {LoyaltyRedemptionInput} options.redemption - A loyalty redemption object
 * @return {UseLoyaltyRedemptionValues} - Values object for useLoyalytRedemption hook
 */
export const useLoyaltyRedemption = ({ cart, redemption }) => {
  const loyaltyDiscountOnCart = cart?.order?.discounts?.loyaltyDiscounts.find(
    (discount) =>
      discount.type === redemption.type &&
      discount.guid === redemption.redemptionGuid
  )

  const redemptionOnCart = Boolean(redemption && loyaltyDiscountOnCart)

  const [addRedemption, { error: addError, loading: addLoading }] =
    useAddRedemption()

  const [removeRedemption, { error: removeError, loading: removeLoading }] =
    useRemoveRedemption()

  const redemptionInput = {
    type: redemption.type,
    guid: redemption.redemptionGuid
  }

  const toggleRedeemed = () => {
    if (redemptionOnCart) {
      removeRedemption(redemptionInput)
    } else {
      addRedemption(redemptionInput)
    }
  }

  return {
    addError,
    removeError,
    toggleRedeemed,
    redemptionOnCart,
    addLoading,
    removeLoading
  }
}
