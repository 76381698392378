import React from 'react'
import { Dollars } from '@local/do-secundo-dollars'

export interface CardContainerProps {
  title: string
  amount: number
  testId?: string
}

export const TAXES_DESC = 'Local taxes applied.'
export const GRATUITIES_DESC = 'Set by restaurants to help compensate servers.'
export const SERVICE_DESC =
  'Set by restaurants to help cover operational costs.'
export const PROCESSING_DESC =
  'Set by Toast to help provide affordable digital ordering services for local restaurants.'

export const CardContainer = ({
  title,
  amount,
  testId
}: CardContainerProps) => {
  let description: string
  switch (title) {
    case 'Gratuities': {
      description = GRATUITIES_DESC
      break
    }
    case 'Service Fee': {
      description = SERVICE_DESC
      break
    }
    case 'Order Processing Fee': {
      description = PROCESSING_DESC
      break
    }
    default: {
      description = TAXES_DESC
      break
    }
  }
  return (
    <div className='rounded-lg shadow-md p-5 pb-6 my-3 mx-9'>
      <div className='flex justify-between pb-4 text-color-default items-baseline border-0 border-b border-solid'>
        <span>{title}</span>
        <span className='font-semibold'>
          <Dollars amount={amount} />
        </span>
      </div>
      <div className='pt-3 text-sm' data-testid={testId + '-desc'}>
        {description}
      </div>
    </div>
  )
}
