export interface GetPriceToRenderInput {
  /** Boolean that shows if modifier is included by default. We dont want to account for its price if it is. */
  isDefault: boolean
  /** Only available if item size was selected. Takes precedence over other values. */
  selectedSizeBasedPrice: number | null
  /** Price of the modifier for the smallest(cheapest) item size. Default to it if size was not selected. */
  minPrice: number | null
  /** Regular price value, default to it if no size-related prices are available. */
  price: number
  /** Indicates whether default modifier should be paid for*/
  defaultOptionsChargePrice?: boolean
  /** Quantity of a modifier(for modquads)*/
  quantity: number | null
}

/**
 * Helper that determines which price to render for each modifier based on available pricing values.
 * @param {GetPriceToRenderInput}
 * @returns {number} Price to render.
 */

export const getPriceToRender = ({
  isDefault,
  selectedSizeBasedPrice,
  minPrice,
  price,
  defaultOptionsChargePrice,
  quantity
}: GetPriceToRenderInput): number | null => {
  // selectedSizeBasedPrice takes priority over anything, if not present - show minPrice(only present if sizePricing), if there's no group/size strategy involved - show regular price.
  const priceToReturn = selectedSizeBasedPrice || minPrice || price
  // it modifier is not default or must be charged for - we want to render size-based pricing or its regular price
  if (!isDefault || defaultOptionsChargePrice) return priceToReturn
  // for modifiers that are not selected yet(or have no quantity) and are !defaultOptionsChargePrice DON'T show the price
  if (!quantity) return null
  // if modifier is default and FREE of charge for the first selection - render its sizeBased, min or regular price after the 1st was selected
  return quantity > 1 ? priceToReturn : null
}

export interface FormikModifier {
  /** Guid of modifier*/
  itemGuid: string
  /** Quantity of modifier */
  quantity: number
}

/**
 * Helper that derives the quantity of a selected modifier from formik state
 * @returns {number} Quantity of a selected modifier
 */

export const getModifierQuantityFromFormik = (
  // for radio select the value is a string(item guid)
  formikModifiersByGroup: FormikModifier[] | string,
  itemGuid: string
) => {
  if (!Array.isArray(formikModifiersByGroup)) return null

  const modifier = formikModifiersByGroup.find(
    (modifier) => modifier.itemGuid === itemGuid
  )

  if (modifier) {
    return modifier.quantity
  }

  return null
}
