import * as React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import PlusIcon from './PlusIcon.svg'
import MinusIcon from './MinusIcon.svg'

import styles from './Stepper.module.css'

export const Stepper = ({
  onIncrement,
  onDecrement,
  value,
  incrementDisabled,
  decrementDisabled,
  modifierQuantities,
  dataTestId
}) => {
  const { modQty, stepper, button, quantity, modQtyIcon } = styles
  return (
    <div
      data-testid={`${dataTestId}-stepper`}
      className={cx(stepper, { [modQty]: modifierQuantities })}
    >
      <button
        className={button}
        disabled={decrementDisabled}
        type='button'
        onClick={onDecrement}
        data-testid='decrement'
        aria-label='Decrease quantity'
      >
        <MinusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
      <span data-testid='item-quantity' className={quantity}>
        {value}
      </span>
      <button
        disabled={incrementDisabled}
        className={button}
        type='button'
        onClick={onIncrement}
        data-testid='increment'
        aria-label='Increase quantity'
      >
        <PlusIcon className={cx({ [modQtyIcon]: modifierQuantities })} />
      </button>
    </div>
  )
}

Stepper.propTypes = {
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  incrementDisabled: PropTypes.bool,
  decrementDisabled: PropTypes.bool,
  modifierQuantities: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired
}
