import React from 'react'
import cx from 'classnames'
export interface SmsConfirmationProps {
  testId?: string
  title?: React.ReactNode
  icon?: React.ReactNode
  mainText?: React.ReactNode
  phone?: React.ReactNode
  secondaryText?: React.ReactNode
  form: React.ReactNode
  footer?: React.ReactNode
  smsConfirmationClassName?: string
}

export const SmsConfirmation = ({
  testId = 'sms-confirmation',
  icon = null,
  title = null,
  mainText,
  phone,
  secondaryText,
  form,
  footer,
  smsConfirmationClassName
}: SmsConfirmationProps) => {
  return (
    <div
      data-testid={testId}
      className={cx(
        'flex flex-col justify-center items-center text-center text-xl text-primary',
        smsConfirmationClassName
      )}
    >
      {icon}
      {title}
      {mainText && (
        <div className='type-default text-oo-black-87'>{mainText}</div>
      )}
      {phone && <div className='py-2 font-bold text-oo-black-87'>{phone}</div>}
      {secondaryText && (
        <div className='type-default text-oo-black-87'>{secondaryText}</div>
      )}
      <div>{form}</div>

      {footer}
    </div>
  )
}
