import React, { useState } from 'react'
import { useMutation, NetworkStatus } from '@apollo/client'
import { Redirect, Link } from 'react-router-dom'
import { useGetCart } from '../CartQuery/CartQuery'
import ReactRouterPropTypes from 'react-router-prop-types'

import { useOOGlobals } from '@local/do-secundo-oo-globals-provider'
import {
  GET_CART_FOR_CHECKOUT,
  GET_COMPLETED_ORDER
} from '../../apollo/cart/cart.graphql'
import { PLACE_ORDER } from '../../apollo/cart/cart-client.graphql'
import { CREATE_ACCOUNT } from '../../apollo/authentication/authentication.graphql'
import {
  START_PROFILE_CREATION,
  useIsPasswordlessAuthEnabled
} from '@local/do-secundo-passwordless-authentication'
import { CloseButton } from '@local/do-secundo-closebutton'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { Loading } from '@toasttab/do-secundo-loading'
import { useAuth } from '../AuthProvider/AuthProvider'
import { CREDIT_CARDS } from '../CreditCardProvider/CreditCard.graphql'
import { SAVED_ADDRESSES } from '../../apollo/saved-addresses.graphql'
import { RestaurantInfo } from '../RestaurantInfo/RestaurantInfo'
import { ScrollToTop } from '../ScrollToTop/ScrollToTop'
import { CheckoutForm } from '../CheckoutForm/CheckoutForm'
import { CheckoutFormPasswordlessAuth } from '../CheckoutForm/CheckoutFormPasswordlessAuth'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { CreditCardProvider } from '../CreditCardProvider/CreditCardProvider'
import { ToastContainer } from 'react-toastify'

import style from './CheckoutPage.module.css'
import {
  CheckoutSelector,
  CHECKOUT_METHODS
} from './CheckoutSelector/CheckoutSelector'
import { CheckoutApplePay } from '../CheckoutApplePay/CheckoutApplePay'
import { useTrackPurchase } from '../../utils/checkout-helpers'
import { useServiceAvailability } from '../ServiceAvailabilityProvider/ServiceAvaialbilityProvider'
import { ValidateCart } from '../ValidateCart/ValidateCart'
import { CartModificationError } from '../Cart/CartModificationError/CartModificationError'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { ModeLink } from '../ModeRouter/utils'
import {
  CREATE_ACCOUNT_ERROR,
  handleCreateAccount,
  handleCreatePasswordlessAccount,
  handlePlaceOrder
} from './utils'
import { usePromoBannersSessionData } from 'cornucopia-apis'

const { toastContainer, toastWrapper } = style

export const CheckoutPage = ({ history }) => {
  const trackPurchase = useTrackPurchase()
  const { toastwebBaseUri, routerBasename } = useOOGlobals()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const {
    authenticated,
    setUnverifiedCustomer,
    authClient,
    setHasErrorFromCheckout
  } = useAuth()
  const [errorMessage, setErrorMessage] = useState()
  const { logCriticalError } = useServiceAvailability()
  const { orderingAvailable } = useAvailability()
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const [placeOrder, { error: placeOrderError }] = useMutation(PLACE_ORDER, {
    refetchQueries: () => {
      const toRefetch = []
      // TODO Can we do something in `update` to just remove from the cache
      // instead of proactively refetching CREDIT_CARDS immediately since we
      // don't need it right away.

      // Needed in case user saved a new card or address.
      if (authenticated) {
        toRefetch.push({
          query: CREDIT_CARDS
        })

        toRefetch.push({
          query: SAVED_ADDRESSES
        })
      }

      return toRefetch
    },
    update: (cache, { data: { placeOrder } }) => {
      if (placeOrder.completedOrder) {
        cache.writeQuery({
          query: GET_COMPLETED_ORDER,
          data: { completedOrder: placeOrder.completedOrder },
          variables: {
            input: {
              orderGuid: placeOrder.completedOrder.guid,
              restaurantGuid
            }
          }
        })
        cache.deleteKeyByRegex(/^\$ROOT_QUERY\.paginatedOrders/)
        cache.deleteKeyByRegex(/^\$ROOT_QUERY\.orderHistory/)
      }
    }
  })
  const [createAccount] = useMutation(CREATE_ACCOUNT)
  const [startProfileCreation] = useMutation(START_PROFILE_CREATION)
  const {
    error: getCartError,
    loading,
    cart,
    deleteCartGuid,
    refetch,
    networkStatus
  } = useGetCart(GET_CART_FOR_CHECKOUT)
  const promoBannersSessionData = usePromoBannersSessionData(restaurantGuid)
  const hasPromoCodeToApply =
    promoBannersSessionData.appliedPromoCode?.promoCode
  const applyPromoFromSession = Boolean(hasPromoCodeToApply)

  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <div className='mt-8 md:mt-10'>
        <Loading />
      </div>
    )
  }
  if (getCartError) {
    return (
      <div className='px-10 mt-20'>
        <MessageError
          header='Unable to load checkout'
          message='Try refreshing the page, or contact the restaurant directly to order'
          buttonSecondary={{
            label: 'Refresh page',
            onClick: () => refetch()
          }}
          testId='checkout-load-error'
        />
      </div>
    )
  }
  if (!cart) {
    return <Redirect to={getRestaurantPath()} replace />
  }

  const {
    loyaltyConfig: { loyaltyRedemptionEnabled }
  } = cart.restaurant
  const shouldValidateCart =
    (!loyaltyRedemptionEnabled || !authenticated) && !applyPromoFromSession
  const CheckoutComponent = PASSWORDLESS_ENABLED
    ? CheckoutFormPasswordlessAuth
    : CheckoutForm

  return (
    <CreditCardProvider>
      <div className={style.page} role='main' aria-label='checkout'>
        <ScrollToTop />
        <RestaurantInfo
          header='Checkout'
          orderingAvailable={orderingAvailable}
        />
        <div className={style.cartButton}>
          <CloseButton
            data-testid='close-button-checkout-page'
            to={getRestaurantPath('cart')}
          />
        </div>
        <Link
          to={getRestaurantPath()}
          data-testid='back-to-menu-link'
          className={style.menuButton}
        >
          Back to Menu
        </Link>
        {!authenticated && (
          <div className={style.loginWrapper}>
            Have an account?{' '}
            <ModeLink
              data-testid='checkout-login-link'
              style='primary'
              mode='login'
            >
              Log In
            </ModeLink>
          </div>
        )}
        <CheckoutSelector>
          {(method) => (
            <>
              {shouldValidateCart && <ValidateCart />}
              <CartModificationError />
              {method === CHECKOUT_METHODS.APPLE_PAY ? (
                <CheckoutApplePay />
              ) : (
                <CheckoutComponent
                  error={placeOrderError || errorMessage}
                  cart={cart}
                  paymentOptions={cart.paymentOptions}
                  preComputedTips={cart.preComputedTips}
                  onSubmit={async ({
                    cashInput,
                    ccInput,
                    paymentType,
                    password,
                    passwordlessFlow = false
                  }) => {
                    const redirectUrl = `${toastwebBaseUri}${routerBasename}?mode=verify`
                    let customerGuid
                    setHasErrorFromCheckout(false)

                    if (
                      (ccInput &&
                        ccInput.newCardInput &&
                        ccInput.newCardInput.saveCard &&
                        password) ||
                      passwordlessFlow
                    ) {
                      try {
                        customerGuid = PASSWORDLESS_ENABLED
                          ? await handleCreatePasswordlessAccount({
                              startProfileCreation,
                              ccInput,
                              setErrorMessage,
                              history,
                              setUnverifiedCustomer,
                              cashInput,
                              authClient
                            })
                          : await handleCreateAccount({
                              createAccount,
                              ccInput,
                              password,
                              redirectUrl,
                              restaurantGuid,
                              setErrorMessage,
                              history,
                              setUnverifiedCustomer
                            })
                      } catch (err) {
                        console.warn(err)
                      }

                      //should be removed when pw- is ramped to 100%
                      if (customerGuid === CREATE_ACCOUNT_ERROR.EMAIL_IN_USE) {
                        // Causes legacy pw+ login modal to open for a guest
                        return
                      }
                      if (!customerGuid) {
                        // If account wasn't successfully created update account creation error
                        setHasErrorFromCheckout(true)
                      }
                    }

                    try {
                      await handlePlaceOrder({
                        placeOrder,
                        cart,
                        cashInput,
                        ccInput,
                        paymentType,
                        getRestaurantPath,
                        deleteCartGuid,
                        setErrorMessage,
                        customerGuid,
                        history,
                        trackPurchase,
                        logCriticalError,
                        passwordlessFlow
                      })
                    } catch (err) {
                      console.warn(err)
                    }
                  }}
                />
              )}
            </>
          )}
        </CheckoutSelector>
      </div>
      <ToastContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position='top-center'
      />
    </CreditCardProvider>
  )
}

CheckoutPage.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
}

// eslint-disable-next-line import/no-default-export
export default CheckoutPage
