import { FF } from '@local/do-secundo-feature-flag'
import { useFlag } from '@local/do-secundo-feature-flag'
import React, { useState } from 'react'

const EditModeContext = React.createContext({})

export const EditModeProvider = ({ children }) => {
  const EDIT_MODE_ENABLED = useFlag(FF.EDIT_MODE)
  const [isEditing, setIsEditing] = useState(false)
  //TODO: wire authenticated manager to something other than feature flag
  const context = {
    isAuthenticatedManager: EDIT_MODE_ENABLED,
    isEditing: isEditing,
    toggleEditing: () => setIsEditing(!isEditing)
  }

  return (
    <EditModeContext.Provider value={context}>
      {children}
    </EditModeContext.Provider>
  )
}

export const useEditMode = () => React.useContext(EditModeContext)
