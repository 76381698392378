import { setContext } from '@apollo/client/link/context'
import { isProfileCreated } from '@local/do-secundo-passwordless-authentication'
import omitBy from 'lodash/omitBy'
/**
 * Middleware that checks whether the guest should have a valid token attached to the header.
 * It is possible for a guest to obtain a valid authorization token
 * without having an account. This is because guests validate their phone number
 * before creating an account in guest-profiles. Unless the guest is going through the
 * complete identity profile mutation or they have a profile, we don't want
 * them sending a "valid token" to our backend. This removes that token.
 */
export const getAuthorizationHeaderLink = (config) =>
  setContext(removeAuthorizationHeader(config))

export const removeAuthorizationHeader =
  (config) =>
  ({ operationName }, { headers }) => {
    if (!config.authClient) {
      return {
        headers
      }
    }
    const isAuthenticated = config.authClient.userInfo
    const hasProfile = isProfileCreated(config.authClient)
    if (
      isAuthenticated &&
      !hasProfile &&
      operationName !== 'COMPLETE_IDENTITY_PROFILE'
    ) {
      const newHeaders = omitBy(
        headers,
        (_, k) => k.toLowerCase() === 'authorization'
      )
      return { headers: newHeaders }
    }
    return {
      headers
    }
  }
