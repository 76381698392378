import * as React from 'react'
import cx from 'classnames'
import { Field } from 'formik'
import {
  Checkbox,
  checkboxStyles,
  FieldShape,
  FormShape
} from '@local/do-secundo-form'
import { TermsModal } from '@local/do-secundo-terms-modal'

import styles from './CheckboxWithTermsModal.module.css'

export interface CheckboxWithTermsModalProps {
  testId?: string | number
  name: string
  id: string
  Icon?: React.ReactNode
  terms?: string
  children?: React.ReactNode
  onSelect?: Function // custom onChange handler for the underlying Checkbox component
}

export const CheckboxWithTermsModal = ({
  testId = `CheckboxWithTermsModal`,
  name,
  id,
  Icon,
  terms,
  children,
  onSelect
}: CheckboxWithTermsModalProps) => {
  const [showInfo, setShowInfo] = React.useState(false)
  return (
    <div data-testid={testId}>
      {showInfo && terms && (
        <TermsModal onClose={() => setShowInfo(false)} terms={terms} />
      )}
      {Icon}
      <Field name={name}>
        {({ field, form }: { field: FieldShape; form: FormShape }) => {
          return (
            <>
              <Checkbox
                onSelect={onSelect}
                field={field}
                id={id}
                form={form}
                style={checkboxStyles.TERMS_MODAL}
                label={
                  <p className='mt-1'>
                    Create a Toast Account for a faster checkout next time
                  </p>
                }
              />
              <div
                className='ml-9 mb-3 type-subhead text-default'
                data-testid='legal-account-description'
              >
                <span className='type-subhead text-default'>
                  We will save your contact information and payment cards with
                  Toast. By creating a Toast Account, you agree to Toast's
                </span>{' '}
                <span
                  className='type-subhead text-dark'
                  onClick={(e) => {
                    const privacyLink = e?.target?.getAttribute('data-testid')
                    if (!privacyLink) {
                      setShowInfo(true)
                    }
                  }}
                >
                  <span className='type-subhead text-link cursor-pointer'>
                    Terms & Conditions
                  </span>{' '}
                  and{' '}
                  <a
                    href='https://pos.toasttab.com/privacy'
                    rel='noopener noreferrer'
                    target='_blank'
                    className='type-subhead text-link'
                    data-testid='link-privacy'
                  >
                    Privacy Statement
                  </a>
                  .
                </span>
              </div>

              {children && (
                <div
                  data-testid='checkbox-children'
                  className={cx(
                    'type-default',
                    styles.children,
                    field.value ? styles.visible : styles.hidden
                  )}
                >
                  <hr />
                  {children}
                </div>
              )}
            </>
          )
        }}
      </Field>
    </div>
  )
}
