import React from 'react'
import styles from './FeedbackBanner.module.css'

export const FeedbackBanner = () => {
  return (
    <div className={styles.message}>
      <div className={styles.bannerHeader}>
        How was your experience using this website to order?
      </div>
      <div className={styles.bannerText}>
        This feedback will be sent to Toast, the ordering system, and is not
        shared with the restaurant.
      </div>
    </div>
  )
}
