import { useMemo } from 'react'
import { DateTime } from 'luxon'
import * as Yup from 'yup'
import { useApolloClient, useQuery } from '@apollo/client'

import {
  VALIDATE_DELIVERY_DISTANCE,
  VALIDATE_DELIVERY_DISTANCE_V2
} from './FulfillmentSelectorModal.graphql'
import { isSameISODay, isISODateTimeEqual } from '../../utils/time-utils'
import { FULFILLMENT_TYPES } from '../FulfillmentProvider/FulfillmentProvider'
import {
  DINING_OPTION_BEHAVIORS,
  DINING_OPTION_STRINGS
} from '@local/do-secundo-use-dining-options'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { dataByTypename } from '../../utils/apollo-helpers'
import { stripDeliveryInfo } from '../../utils/address-helpers'

export const getDatesAndTimesFromResponse = ({
  data,
  selectedDate,
  diningOptionBehavior
}) => {
  if (data.diningOptions.length < 1) return {}
  let found =
    data.diningOptions.find(
      (option) => option.behavior === diningOptionBehavior
    ) || data.diningOptions[0]

  const dates = found.futureSchedule.dates.filter(
    ({ timesAndGaps = [] }) => timesAndGaps.length >= 1
  )

  if (dates.length < 1) return { diningOptionBehavior: found.behavior }
  const dateList = dates.map((d) => d.date)
  let currentDate = dates[0]
  if (selectedDate) {
    const matchingDate = dates.find(({ date }) =>
      isSameISODay(date, selectedDate)
    )
    if (matchingDate) {
      currentDate = matchingDate
    }
  }
  return {
    dateList,
    timeList: currentDate.timesAndGaps,
    currentDate,
    diningOptionBehavior: found.behavior
  }
}

export const sameFulfillment = (a, b) =>
  a.fulfillmentType === b.fulfillmentType &&
  isISODateTimeEqual(a.fulfillmentTime, b.fulfillmentTime)

// TODO: Remove this method when we can be sure that server returns
// dateTimes in a consistent format
export const normalizeTime = ({
  isASAP,
  fulfillmentTime,
  fulfillmentDateTime
}) => {
  if (isASAP) return null
  return DateTime.fromISO(fulfillmentTime || fulfillmentDateTime)
    .toUTC()
    .toISO()
}

/**
 * Returns true if:
 * 1. ASAP fulfillment type
 * 2. fulfillment time is valid and is in the future
 * @param {string} fulfillmentTime - iso date string
 * @param {string} fulfillmentType - one of FULFILLMENT_TYPES
 * @returns {boolean}
 */
export const isFulfillmentTimeValid = (fulfillmentTime, fulfillmentType) => {
  if (fulfillmentType === FULFILLMENT_TYPES.ASAP) {
    return true
  }

  if (!fulfillmentTime) {
    return false
  }

  const dateTime = DateTime.fromISO(fulfillmentTime).toUTC()
  if (dateTime.isValid) {
    return dateTime.toMillis() > DateTime.utc().toMillis()
  }
  return false
}

export const normalizeFulfillment = ({
  fulfillmentType,
  fulfillmentTime = null,
  diningOptionBehavior,
  fulfillmentDateTime = null,
  deliveryInfo,
  deliveryProviderInfo,
  savedAddressGuid = null,
  order
}) => {
  deliveryProviderInfo = deliveryProviderInfo || { provider: null }
  const isASAP = fulfillmentType === FULFILLMENT_TYPES.ASAP
  const isDelivery = diningOptionBehavior === DINING_OPTION_BEHAVIORS.DELIVERY
  fulfillmentTime = normalizeTime({
    isASAP,
    fulfillmentTime,
    fulfillmentDateTime
  })

  let deliveryProvider = null

  if (isDelivery) {
    deliveryInfo = stripDeliveryInfo(deliveryInfo || order.deliveryInfo)
    deliveryInfo.address2 = deliveryInfo.address2 || ''
    deliveryInfo.notes = deliveryInfo.notes || ''
    deliveryProvider = deliveryProviderInfo.provider
  } else {
    deliveryInfo = undefined
  }

  return {
    fulfillmentType,
    fulfillmentTime,
    diningOptionBehavior,
    deliveryInfo,
    deliveryProvider,
    savedAddressGuid
  }
}

const hasLatLng = ({ latitude, longitude }) =>
  Boolean(typeof latitude === 'number' && typeof longitude === 'number')

const hasAddressComponents = ({
  address1,
  address2,
  city,
  state,
  zipCode,
  latitude,
  longitude
}) =>
  Boolean(
    typeof address1 === 'string' &&
      (address2 === null || typeof address2 === 'string') &&
      typeof city === 'string' &&
      typeof state === 'string' &&
      typeof zipCode === 'string' &&
      hasLatLng({ latitude, longitude })
  )

const deliveryDistanceErrorMessage =
  'You’re out of range for delivery. To start an order, update your delivery address.'

export const getDeliveryDistanceValidator = ({ client, restaurantGuid }) =>
  Yup.object().when('diningOptionBehavior', {
    is: DINING_OPTION_BEHAVIORS.DELIVERY,
    then: Yup.object()
      .test(
        'outOfRange',
        deliveryDistanceErrorMessage,
        async ({ latitude, longitude }) => {
          const variables = {
            input: {
              restaurantGuid,
              latitude,
              longitude
            }
          }

          if (!hasLatLng({ latitude, longitude })) return true

          try {
            const response = await client.query({
              query: VALIDATE_DELIVERY_DISTANCE,
              variables
            })
            const {
              ValidateDeliveryLocationResponse: { valid }
            } = dataByTypename(response.data.validateDeliveryLocation)

            return valid
          } catch (error) {
            // Return true here to not impede diner workflow
            // Checkout will validate delivery address anyway
            return true
          }
        }
      )
      .test('required', 'required', ({ latitude, longitude }) => {
        return hasLatLng({ latitude, longitude })
      })
  })

export const useDeliveryDistanceYupValidator = () => {
  const client = useApolloClient()
  const { restaurantGuid } = useRestaurant()
  return useMemo(
    () => getDeliveryDistanceValidator({ client, restaurantGuid }),
    [client, restaurantGuid]
  )
}

export const useDeliveryDistanceValidator = (
  address = {},
  diningOptionBehavior
) => {
  const { restaurantGuid } = useRestaurant()
  const daasIntegrationFlag = useFlag(FF.DAAS_INTEGRATION, false)

  const { address1, address2, city, state, zipCode, latitude, longitude } =
    address

  let skip =
    daasIntegrationFlag === null ||
    diningOptionBehavior !== DINING_OPTION_BEHAVIORS.DELIVERY
  let query
  let variables
  let resultKey

  if (daasIntegrationFlag) {
    skip = skip || !hasAddressComponents(address)

    query = VALIDATE_DELIVERY_DISTANCE_V2

    variables = {
      input: {
        restaurantGuid,
        deliveryInfo: {
          address1,
          address2,
          city,
          state,
          zipCode,
          latitude,
          longitude
        }
      }
    }

    resultKey = 'validateDeliveryLocationV2'
  } else {
    skip = skip || !hasLatLng({ latitude, longitude })

    query = VALIDATE_DELIVERY_DISTANCE

    variables = {
      input: {
        restaurantGuid,
        latitude,
        longitude
      }
    }

    resultKey = 'validateDeliveryLocation'
  }

  const { data, loading } = useQuery(query, {
    variables,
    skip,

    // There seems to be an issue where the result of this useQuery is stuck on loading when
    // entering a new address, given that the diner has a saved address.
    // This seems to be an open issue with apollo-client. See relevant Github issues:
    // https://github.com/apollographql/react-apollo/issues/3361
    // https://github.com/apollographql/react-apollo/issues/3774
    // https://github.com/apollographql/react-apollo/issues/3425
    // The 3rd issue (3425) describes a scenario that closely resembles what's happening here.
    fetchPolicy: 'no-cache'
  })

  if (skip) return { valid: true, loading }
  if (loading) return { valid: true, loading }

  const { valid } = data[resultKey]
  const message = valid ? undefined : deliveryDistanceErrorMessage

  return { valid, message, loading }
}

export const getFormattedDiningOption = (diningOptionBehavior) => {
  return DINING_OPTION_STRINGS[diningOptionBehavior] || 'Pickup'
}
