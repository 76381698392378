import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, useHistory, useLocation } from 'react-router'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import {
  useAuth,
  importSuccessToast,
  showLoggedInNotification
} from '../../AuthProvider/AuthProvider'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { dataByTypename } from '../../../utils/apollo-helpers'

import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'
import { Notification } from '@local/do-secundo-notification'
import { Input } from '@local/do-secundo-form'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'

import VerifyLock from '../../../assets/verify-lock.svg'

import styles from './MFAVerificationModal.module.css'
import {
  useIsPasswordlessAuthEnabled,
  isPasswordlessLoggedIn,
  useImportAccount,
  FROM_LOCATION
} from '@local/do-secundo-passwordless-authentication'

export const MFA_TYPE_MAP = {
  EMAIL_CHALLENGE: 'email',
  SMS_CHALLENGE: 'phone'
}

export const MFAVerificationModal = ({ onClose = () => {} }) => {
  const {
    user,
    mfaLogin,
    loading,
    error: mfaError,
    setMfaState,
    challengeToken,
    mfaType,
    email,
    maskedRecipient,
    authClient
  } = useAuth()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()
  const { state, pathname } = useLocation()
  const handleImportAccount = useImportAccount()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const pwlessLoggedIn =
    PASSWORDLESS_ENABLED && isPasswordlessLoggedIn(authClient)
  const handleClose = () => {
    setMfaState({
      mfaRequiresVerification: false,
      challengeToken: '',
      mfaType: '',
      maskedRecipient: ''
    })

    onClose()

    if (pwlessLoggedIn && state?.from === FROM_LOCATION.emailDetected) {
      showLoggedInNotification(user?.firstName)
    }
  }

  if (!challengeToken) {
    return <Redirect to={getRestaurantPath(pathname)} />
  }

  let recipient = `We sent a code to your ${MFA_TYPE_MAP[mfaType]}`

  if (maskedRecipient) {
    recipient = recipient + ` ${maskedRecipient}`
  }

  recipient = recipient + '.'

  return (
    <div className={styles.mfaVerificationModal}>
      <PoweredByToastModal onClose={handleClose}>
        <div className={styles.headerWrapper}>
          <VerifyLock />
          <h2 data-testid='mfa-verification-modal-header'>
            Verify Your Account
          </h2>
          <div
            data-testid='mfa-verification-modal-description'
            className={styles.description}
          >
            <p>{recipient}</p>
            <p>Please enter it below to confirm your identity.</p>
          </div>
        </div>
        <Formik
          initialValues={{ code: '' }}
          validationSchema={Yup.object().shape({
            code: Yup.string().trim().required('Verification code is required')
          })}
          onSubmit={async (values, { setError }) => {
            const input = {
              variables: {
                input: {
                  code: values.code.toString().trim(),
                  challengeToken,
                  email,
                  forAccountLinking:
                    PASSWORDLESS_ENABLED &&
                    state?.from !== FROM_LOCATION.enterYourPassword
                }
              }
            }
            const result = await mfaLogin(input)
            const { AuthenticationResponse, MfaChallengeResponse } =
              dataByTypename(result.data.mfaLogin)

            if (AuthenticationResponse) {
              // divert guest to create new pwless account
              if (PASSWORDLESS_ENABLED) {
                if (pwlessLoggedIn) {
                  await handleImportAccount({
                    onSuccess: () => {
                      setMfaState({
                        mfaRequiresVerification: false
                      })
                      handleClose()
                      importSuccessToast()
                    },
                    onError: setError
                  })
                  return
                }

                setMfaState({
                  mfaRequiresVerification: false
                })

                if (state?.from === FROM_LOCATION.enterYourPassword) {
                  history.push('?mode=loginEasier', {
                    from: FROM_LOCATION.enterYourPassword,
                    email
                  })
                  return
                }
              } else {
                setMfaState({
                  mfaRequiresVerification: false
                })
              }

              handleClose()
            }

            if (MfaChallengeResponse) {
              setMfaState({
                mfaRequiresVerification: true,
                email,
                ...result.data.mfaLogin
              })
            }
          }}
        >
          {({ isValid, error: connectError }) => (
            <Form>
              {mfaError && !loading && (
                <Notification severity='error'>Invalid code.</Notification>
              )}
              {connectError?.code && !mfaError && pwlessLoggedIn && (
                <Notification severity='error'>
                  {connectError.message}
                </Notification>
              )}
              <Field
                data-testid='mfa-modal-code'
                autoFocus
                id='mfa-code'
                name='code'
                type='text'
                inputMode='numeric'
                label='Verification Code'
                component={Input}
                instructions={'*Verification code must be 6 characters'}
              />
              <Button
                data-testid='mfa-verify-modal-submit'
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.PRIMARY}
                responsive
                loading={loading}
                disabled={
                  !isValid ||
                  (connectError?.code && !mfaError && pwlessLoggedIn)
                }
              >
                {pwlessLoggedIn ? 'Connect account' : 'Log In'}
              </Button>
            </Form>
          )}
        </Formik>
      </PoweredByToastModal>
    </div>
  )
}

MFAVerificationModal.propTypes = {
  onClose: PropTypes.func.isRequired
}
