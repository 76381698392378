import * as React from 'react'
import PropTypes from 'prop-types'

import { ErrorComponent } from '@local/do-secundo-error'

const OOGlobalsContext = React.createContext({})

export const OOGlobalsProvider = ({
  devError,
  enabledFeatureFlags,
  gatewayBaseUri,
  toastwebBaseUri,
  restaurantGuid,
  routerBasename,
  shortUrl,
  spaVersion,
  spaPreviousVersion,
  managementGroupGuid,
  mode,
  mapboxAccessToken,
  toastTakeoutCTAEnabled,
  isCornucopiaShellRunning,
  children
}) => {
  const context = {
    devError,
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    spaVersion,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    spaPreviousVersion,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  }

  return (
    <OOGlobalsContext.Provider value={context}>
      {children}
    </OOGlobalsContext.Provider>
  )
}

OOGlobalsProvider.propTypes = {
  children: PropTypes.element.isRequired,

  // Should only possibly get set in local dev mode.
  devError: PropTypes.object,
  enabledFeatureFlags: PropTypes.array,
  gatewayBaseUri: PropTypes.string,
  toastwebBaseUri: PropTypes.string,
  restaurantGuid: PropTypes.string,
  routerBasename: PropTypes.string.isRequired,
  shortUrl: PropTypes.string,
  spaVersion: PropTypes.string.isRequired,
  spaPreviousVersion: PropTypes.string.isRequired,
  mode: PropTypes.string,
  mapboxAccessToken: PropTypes.string,
  managementGroupGuid: PropTypes.string,
  toastTakeoutCTAEnabled: PropTypes.bool,
  isCornucopiaShellRunning: PropTypes.bool
}

export const OOGlobalsConsumer = ({ children }) => (
  <OOGlobalsContext.Consumer>
    {(context) => (
      <>
        {context.devError && <ErrorComponent error={context.devError} />}
        {children(context)}
      </>
    )}
  </OOGlobalsContext.Consumer>
)

OOGlobalsConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useOOGlobals = () => React.useContext(OOGlobalsContext)

export const MockOOGlobalsProvider = ({
  mocks: {
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    spaVersion,
    spaPreviousVersion,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  },
  children
}) => {
  const context = {
    enabledFeatureFlags,
    gatewayBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    routerBasename,
    shortUrl,
    spaVersion,
    spaPreviousVersion,
    managementGroupGuid,
    mode,
    mapboxAccessToken,
    toastTakeoutCTAEnabled,
    isCornucopiaShellRunning
  }

  return (
    <OOGlobalsContext.Provider value={context}>
      {children}
    </OOGlobalsContext.Provider>
  )
}

MockOOGlobalsProvider.propTypes = {
  children: PropTypes.element.isRequired,
  mocks: PropTypes.shape({
    enabledFeatureFlags: PropTypes.array,
    gatewayBaseUri: PropTypes.string,
    toastwebBaseUri: PropTypes.string,
    restaurantGuid: PropTypes.string,
    routerBasename: PropTypes.string,
    shortUrl: PropTypes.string,
    spaVersion: PropTypes.string,
    spaPreviousVersion: PropTypes.string,
    mode: PropTypes.string,
    managementGroupGuid: PropTypes.string,
    mapboxAccessToken: PropTypes.string,
    toastTakeoutCTAEnabled: PropTypes.bool
  }).isRequired
}
