import React, { useState, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@toasttab/buffet-chip'

import { useRemovePromoCode } from '../PromoCodeMutation'

import { toast } from 'react-toastify'

import {
  ToastNotification,
  toastEachWarning
} from '../../Modifiers/ToastNotification/ToastNotification'
import { ErrorComponent } from '@local/do-secundo-error'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { dispatchRemoveAppliedPromoCode } from 'cornucopia-apis'

export const PromoCode = ({ name, cartGuid }) => {
  const { restaurantGuid } = useRestaurant()
  const [removePromoCodeError, setRemovePromoCodeError] = useState(null)
  const [removePromoCode, { loading, error, retry }] = useRemovePromoCode({
    onComplete: () => {
      // clear promo code in session
      dispatchRemoveAppliedPromoCode(restaurantGuid)

      toast(
        <ToastNotification checkmark>
          Promo code has been removed!
        </ToastNotification>
      )
    },
    onError: (error) => {
      setRemovePromoCodeError(error)
    },
    onCartOutOfStock: (message) => {
      // in the rare case that an item in cart went out of stock while user is applying promo code,
      // show out of stock warning
      toastEachWarning({ warnings: [message] })
    }
  })

  const handleRemovePromoCode = useCallback(() => {
    removePromoCode({
      variables: {
        input: {
          cartGuid: cartGuid,
          discountType: 'RESTAURANT'
        }
      }
    })
  }, [removePromoCode, cartGuid])

  /**
   * error occurs during a network timeout/failed connection/failed fetch/etc, while a
   * removePromoCodeError occurs when the API call to remove the promo code fails for some reason.
   */
  return (
    <Fragment>
      {Boolean(error) && <ErrorComponent error={error} retry={retry} />}
      {Boolean(removePromoCodeError) && (
        <ErrorComponent error={removePromoCodeError} />
      )}
      {loading ? (
        <Loading variant={LoadingVariant.SECONDARY} />
      ) : (
        <Chip
          canDelete
          onDelete={handleRemovePromoCode}
          text={name}
          testId='promo-code'
        />
      )}
    </Fragment>
  )
}

PromoCode.propTypes = {
  name: PropTypes.string.isRequired,
  cartGuid: PropTypes.string.isRequired
}
