import * as React from 'react'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { SmsConfirmation } from '@local/do-secundo-sms-confirmation-component'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CodeConfirmationForm,
  CodeConfirmationFormProps
} from '@local/do-secundo-code-confirmation'
import {
  hasPasswordlessAccount,
  FROM_LOCATION,
  useIsPasswordlessAuthEnabled
} from '@local/do-secundo-passwordless-authentication'
import { ModeLink } from '../../../../client/components/ModeRouter/utils'

export interface SmsVerificationModalProps extends CodeConfirmationFormProps {
  onClose?: () => void
  handleResend: () => void
  phone: string
  header?: React.ReactNode
  mainText?: React.ReactNode
}

export const SmsVerificationModal = ({
  onClose = () => {},
  handleSubmit,
  numFields,
  handleResend,
  header,
  mainText
}: SmsVerificationModalProps) => {
  const { state } = useLocation<{ from: FROM_LOCATION }>()
  const dataIdSuffix = state?.from === FROM_LOCATION.login ? 'login' : 'create'

  return (
    <div className={'text-center'} data-testid={'sms-verification-modal'}>
      <PoweredByToastModal onClose={onClose} header={header}>
        {mainText}
        <SmsConfirmation
          form={
            <CodeConfirmationForm
              testId={`code-confirmation-form-${dataIdSuffix}`}
              numFields={numFields}
              handleSubmit={handleSubmit}
              idPrefix={'modal'}
              handleResend={handleResend}
            />
          }
          testId={`sms-confirmation-modal-${dataIdSuffix}`}
        />
      </PoweredByToastModal>
    </div>
  )
}
