import { useEffect, useRef } from 'react'

// Based on https://stackoverflow.com/q/55075604/1437395

/**
 * This is equivalant to react's built in useEffect() hook except the effect doesn't
 * run on the initial mount of the component, only on updates.
 * @param {React.EffectCallback} effect
 * @param {React.DependencyList} dependencies
 */
export const useUpdateEffect = (effect, dependencies) => {
  const isMounted = useRef(false)
  const isInitialMount = useRef(true)
  const effectRef = useRef(effect)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    effectRef.current = effect
  }, [effect])

  useEffect(() => {
    let effectCleanupFunc = () => {}

    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effectCleanupFunc = effectRef.current() || effectCleanupFunc
    }

    return () => {
      effectCleanupFunc()

      if (!isMounted.current) {
        isInitialMount.current = true
      }
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps
}
