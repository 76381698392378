import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import * as yup from 'yup'

import { Checkbox } from '@local/do-secundo-form'
import { useFlag, FF } from '@local/do-secundo-feature-flag'

import styles from './DeliveryCommunicationConsent.module.css'

export const Component = ({ cart }) => {
  const daasIntegrationFlag = useFlag(FF.DAAS_INTEGRATION, false)

  if (!daasIntegrationFlag) {
    return null
  }

  return (
    cart &&
    cart.deliveryProviderInfo &&
    cart.deliveryProviderInfo.needsDeliveryCommunicationConsent && (
      <div className={styles['delivery-communication-consent']}>
        <Field
          id='delivery_communication_consent_given'
          component={Checkbox}
          name='deliveryCommunicationConsentGiven'
          label={<span>Yes, I want to receive text updates</span>}
        />
        <p>
          By providing a mobile number, you give DoorDash and Toast permission
          to contact you using automated text messages to provide transactional
          messages such as order status updates.{' '}
          <a
            href='https://pos.toasttab.com/privacy'
            rel='noopener noreferrer'
            target='_blank'
          >
            Learn more
          </a>
        </p>
      </div>
    )
  )
}

Component.displayName = 'DeliveryCommunicationConsent'
Component.propTypes = {
  cart: PropTypes.object.isRequired
}

export const getInitialValues = ({ cart, featureFlags }) => {
  if (!featureFlags[FF.DAAS_INTEGRATION]) return null

  if (
    !cart ||
    !cart.deliveryProviderInfo ||
    !cart.deliveryProviderInfo.needsDeliveryCommunicationConsent
  ) {
    return null
  }

  return { deliveryCommunicationConsentGiven: true }
}

export const getValidationSchema = ({ cart, featureFlags }) => {
  if (!featureFlags[FF.DAAS_INTEGRATION]) return null

  if (
    !cart ||
    !cart.deliveryProviderInfo ||
    !cart.deliveryProviderInfo.needsDeliveryCommunicationConsent
  ) {
    return null
  }

  return {
    deliveryCommunicationConsentGiven: yup.boolean().default(true)
  }
}

export const getArgsForSubmit = ({ values, cart, featureFlags }) => {
  if (!featureFlags[FF.DAAS_INTEGRATION]) return null

  if (
    !cart ||
    !cart.deliveryProviderInfo ||
    !cart.deliveryProviderInfo.needsDeliveryCommunicationConsent
  ) {
    return null
  }

  return {
    deliveryCommunicationConsentGiven: values.deliveryCommunicationConsentGiven
  }
}
