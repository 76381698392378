import React from 'react'
import PropTypes from 'prop-types'
import { getIn, useFormikContext } from 'formik-latest'
import cx from 'classnames'
import { isSelected } from '@local/do-secundo-modifier-form-helpers'

import { DoneIcon } from '@toasttab/buffet-pui-icons'

import styles from './Required.module.css'

export const Required = ({ name }) => {
  const { errors, values } = useFormikContext()
  const err = getIn(errors, name)
  let value = getIn(values, name)
  if (Array.isArray(value)) {
    value = value.find(isSelected)
  }

  const satisfied = value && !err
  return (
    <div
      data-testid='modifier-required-container'
      className={cx(styles.required, { [styles.satisfied]: satisfied })}
    >
      <div className={styles.pill}>
        {satisfied && <DoneIcon className={styles.check} />}
        <span data-testid='modifier-required-text' className={styles.text}>
          Required
        </span>
      </div>
    </div>
  )
}

Required.displayName = 'Required'

Required.propTypes = {
  name: PropTypes.string.isRequired
}
