import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './MenuGroupCarouselItem.module.css'

export const MenuGroupCarouselItem = ({ item, activeItemId }) => {
  const { id, href, title, name } = item
  return (
    <div
      key={id}
      data-navid={id}
      data-testid='menu-nav-item'
      className={cx(styles.navItem, 'type-default', 'menu-nav-item')}
    >
      <a
        href={href}
        title={title}
        rel='nofollow'
        className={cx(styles.linkNav, styles.link, {
          [styles.active]: activeItemId === id
        })}
      >
        {name}
      </a>
    </div>
  )
}

MenuGroupCarouselItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  activeItemId: PropTypes.string
}
