import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useFlag, FF } from '@local/do-secundo-feature-flag'

import { CartPage } from '../../CartPage/CartPage'
import { useHasMode } from '../../ModeRouter/ModeRouter'

import styles from './MenuPageLayout.module.css'

export const MenuPageLayout = ({ menus, cart, header }) => {
  const isCartMode = useHasMode('cart')
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)

  return (
    <>
      {isCartMode && (
        <div className={styles.cartMobile}>
          <CartPage />
        </div>
      )}
      <div
        className={cx(styles.splitView, { [styles.cartMobile]: isCartMode })}
        itemScope={nv5SEOEnabled}
        itemType={nv5SEOEnabled && 'https://schema.org/Restaurant'}
      >
        <div className={styles.content} role='main' aria-label='menu'>
          {header}
          <div className={styles.menus}>{menus}</div>
        </div>
        <div className={styles.cart} role='region' aria-label='cart'>
          <div className={styles.fixed}>
            <h2 className={cx(styles.label, 'type-headline-5')}>Your Order</h2>
            {cart}
          </div>
        </div>
      </div>
    </>
  )
}

MenuPageLayout.propTypes = {
  menus: PropTypes.node.isRequired,
  cart: PropTypes.node.isRequired,
  header: PropTypes.node
}
