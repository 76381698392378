import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { useLazyQuery } from '@apollo/client'
import cx from 'classnames'

import { ADDRESS_SUGGESTIONS } from '../../../apollo/geolocation/geolocation.graphql'
import { getFormattedAddress } from '../../../utils/address-helpers'

import style from '../AddressAutocomplete.module.css'

const NoResults = () => (
  <li className={style.item}>
    <p className={cx(style.text, style.emphasis)}>No results were found</p>
    <p className={style.text}>Please enter another address</p>
  </li>
)

export const AddressSuggestions = ({
  inputValue,
  getItemProps,
  highlightedIndex,
  selectedItem,
  latitude,
  longitude,
  debounce = 500
}) => {
  const [doQuery, { called, error, loading, data }] =
    useLazyQuery(ADDRESS_SUGGESTIONS)
  const queryTimeout = useRef(null)

  useEffect(() => {
    if (queryTimeout.current) {
      clearTimeout(queryTimeout.current)
    }
    queryTimeout.current = setTimeout(() => {
      doQuery({
        variables: { inputValue, latitude, longitude }
      })
    }, debounce)
    return () => {
      clearTimeout(queryTimeout.current)
    }
  }, [doQuery, inputValue, latitude, longitude, debounce])

  if (!called || (called && loading))
    return <li className={style.item}>Loading...</li>
  if (error) return <NoResults />

  const { addressSuggestions } = data
  if (addressSuggestions.length === 0) return <NoResults />
  return addressSuggestions.map((item, index) => (
    <li
      {...getItemProps({
        key: `${item.latitude}+${item.longitude}`,
        index,
        item,
        className: cx(style.item, {
          [style.highlight]: highlightedIndex === index,
          [style.selected]: isEqual(selectedItem, item)
        })
      })}
    >
      {getFormattedAddress(item)}
    </li>
  ))
}

AddressSuggestions.propTypes = {
  inputValue: PropTypes.string,
  getItemProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.object,
  debounce: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number
}
