import React from 'react'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import cx from 'classnames'

import { Loading } from '@local/do-secundo-loading'
import { CartIcon } from '../../../../client/components/CartIcon/CartIcon'
import styles from './CartButton.module.css'

const buttonStyles = Object.freeze({
  DEFAULT: '',
  ROUNDED: 'rounded'
})
export const CartButton = ({
  to,
  left,
  right,
  type,
  disabled = false,
  loading,
  style = buttonStyles.DEFAULT,
  showModificationErrors,
  ...props
}) => {
  if (loading) {
    return (
      <button
        className={cx(styles.cartButton, 'type-default', styles[style], {
          [styles.loading]: loading
        })}
        type={type}
        {...props}
        disabled
      >
        <Loading />
      </button>
    )
  }
  const buttonContent = (
    <>
      <span className={cx(styles.cartButtonWrapper, 'type-default')}>
        {left}
      </span>
      <span className={'type-default'}>{right}</span>
    </>
  )
  if (type || disabled) {
    return (
      <button
        className={cx(styles.cartButton, 'type-default', styles[style])}
        type={type}
        disabled={disabled}
        {...props}
      >
        {buttonContent}
      </button>
    )
  }
  return (
    <Link
      data-testid-id='button-view-cart'
      className={cx(styles.cartButton, styles[style])}
      to={to}
      {...props}
    >
      {buttonContent}
    </Link>
  )
}

CartButton.propTypes = {
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  to: PropTypes.string,
  left: PropTypes.node.isRequired,
  right: PropTypes.node,
  disabled: PropTypes.bool
}
