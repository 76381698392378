import React from 'react'
import PropTypes from 'prop-types'
import TwitterImg from '../../../assets/twitter.svg'
import FacebookImg from '../../../assets/facebook.svg'
import { getTwitterRestaurantReference } from '../../../utils/social-utils'

import styles from './SocialBanner.module.css'

export const SocialBanner = ({
  socialMediaLinks: { twitterLink, facebookLink },
  whiteLabelName
}) => {
  const ooLink = window.location.href.slice(
    0,
    window.location.href.indexOf('/confirm')
  )
  const message = `Every order makes a difference. Order from ${getTwitterRestaurantReference(
    twitterLink,
    whiteLabelName
  )} to show your support today.`
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    ooLink
  )}&text=${encodeURIComponent(message)}`
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${ooLink}`

  return (
    <div className={styles.banner}>
      <h2 className={styles.header}>Share Your Support</h2>
      <p className={styles.p}>
        Every order makes a difference. Share and encourage others to support
        our restaurant.
      </p>
      <br />
      <p className={styles.p}>
        Follow us to keep up with the latest from our team.
      </p>
      <br />
      <div className={styles.linksContainer}>
        <a
          href={facebookShareLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='social-share-facebook'
          className='facebookButton'
          title='Share on Facebook'
          aria-label='Share on Facebook'
        >
          <FacebookImg className={styles.socialMediaImg} />
        </a>
        <a
          href={twitterShareLink}
          rel='noopener noreferrer'
          target='_blank'
          data-testid='social-share-twitter'
          className='twitterButton'
          title='Share on Twitter'
          aria-label='Share on Twitter'
        >
          <TwitterImg className={styles.socialMediaImg} />
        </a>
      </div>
    </div>
  )
}

const SocialMediaLinksShape = PropTypes.shape({
  twitterLink: PropTypes.string,
  facebookLink: PropTypes.string
})

SocialBanner.propTypes = {
  socialMediaLinks: SocialMediaLinksShape,
  whiteLabelName: PropTypes.string
}
