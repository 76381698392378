import * as React from 'react'
import { ModeLink } from '../ModeRouter/utils'
import SearchListIcon from '../../assets/magnifying-glass-list.svg'

export const BirdsEyeViewHeader = ({ activeMenu }) => {
  return (
    <ModeLink data-testid='birds-eye-view-menu-page' mode='search'>
      <div
        className={`flex items-center pl-5 pr-2 ${
          activeMenu?.groups?.length > 1 ? 'pt-2 pb-1' : 'py-3'
        }`}
      >
        <SearchListIcon
          data-testid='search-list-icon'
          className='mr-3 fill-current text-primary-75'
        />
        <span
          data-testid='menu-name-header'
          className='w-64 font-semibold truncate type-headline-5 text-oo-black-87'
        >
          {activeMenu.name}
        </span>
      </div>
    </ModeLink>
  )
}
