import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import Checkmark from '../../../assets/checkmark.svg'

import styles from './ToastNotification.module.css'

export const ToastNotification = ({ children, checkmark }) => (
  <span className={styles.toast}>
    {checkmark && <Checkmark className={styles.checkmark} />}
    {children}
  </span>
)

ToastNotification.propTypes = {
  children: PropTypes.node,
  checkmark: PropTypes.bool
}

export const toastEachWarning = ({ warnings } = {}) => {
  warnings &&
    warnings.forEach((warning) => {
      toast(<ToastNotification>{warning}</ToastNotification>)
    })
}
