import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
const asyncFastDom = fastdom.extend(fastdomPromised)

/**
 * Returns the client rect of an element using fastdom to mitigate
 * layout thrashing.
 * @param {*} elem The element to get the rect of.
 */
export function getFastRect(elem) {
  if (!elem) {
    return Promise.resolve({
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    })
  }
  return asyncFastDom.measure(() => elem.getBoundingClientRect())
}
