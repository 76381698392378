import React, { useState } from 'react'

const FundraisingContext = React.createContext({})

export const FundraisingContextProvider = ({ children }) => {
  const [fundraisingConfig, setFundraisingConfig] = useState(null)

  const context = {
    setFundraisingConfig,
    fundraisingConfig
  }

  return (
    <FundraisingContext.Provider value={context}>
      {children}
    </FundraisingContext.Provider>
  )
}

export const useFundraisingContext = () => React.useContext(FundraisingContext)
