import * as React from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  useAuth,
  showLoggedInNotification
} from '../../../../client/components/AuthProvider/AuthProvider'
import { AuthForm, AuthFormSubmitValues } from '@local/do-secundo-auth-form'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { SignUpPrompt } from '@local/do-secundo-identity-prompts'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'

import { FROM_LOCATION } from '@local/do-secundo-passwordless-authentication'
import { unifiedCreateAccountValidationSchema } from '../../../../client/utils/form-schemas'
import {
  deleteTokens,
  accountLinkingStorage
} from '../../../../client/apollo/authentication/authentication-helpers'
import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
import { handleUnifiedPhoneSubmit, isPasswordlessLoggedIn } from '../utils'

export interface LoginEasierModalProps {
  onClose: () => void
  buttonText: string
  includeEmail?: boolean
  handleSubmitError?: (error: { Error: string }) => void
}

export const LoginEasierModal = ({
  onClose: closeModal,
  handleSubmitError
}: LoginEasierModalProps) => {
  const history = useHistory()
  const { state = {}, pathname } = useLocation()
  const { startPasswordlessLogin, user, authenticated, authClient } = useAuth()

  const guestName = user?.firstName
  const onClose = React.useCallback(() => {
    closeModal()
    showLoggedInNotification(guestName)
  }, [closeModal, guestName])

  React.useEffect(() => {
    if (state.from !== FROM_LOCATION.enterYourPassword && !authenticated) {
      history.push('?mode=login')
      deleteTokens(accountLinkingStorage)
    }
    if (isPasswordlessLoggedIn(authClient)) {
      history.replace(pathname)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (values: AuthFormSubmitValues) => {
    return handleUnifiedPhoneSubmit({
      values,
      startPasswordlessLogin,
      history,
      from: FROM_LOCATION.loginEasier
    })
  }

  const phone = state.guestPhone || state.basicInfo?.phone || ''

  return (
    <PoweredByToastModal
      onClose={onClose}
      header={
        <>
          <h2 className='mx-0 mt-0 mb-3 type-headline-4'>
            Logging into Toast just got easier.
          </h2>
          <p className='type-default text-default'>
            Use your phone number and one-time code for access to your order
            history, personalized information, and payment methods.
          </p>
        </>
      }
    >
      <div className='m-auto' data-testid='sign-up-prompt-container'>
        <SignUpPrompt
          form={
            <>
              <AuthForm
                hasEmail={false}
                handleSubmit={handleSubmit}
                initialValues={{ phone }}
                validationSchema={unifiedCreateAccountValidationSchema}
                buttonText={'Continue'}
                includeEmail={false}
                handleSubmitError={handleSubmitError}
                testId={'passwordless-create-modal'}
              />
              <ButtonSpacer vertical />
              <Button
                data-testid={`login-easier-button-skip`}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.LINK_WIDE}
                responsive
                onClick={onClose}
              >
                Skip
              </Button>
            </>
          }
          includeLoginLink={false}
        />
      </div>
    </PoweredByToastModal>
  )
}
