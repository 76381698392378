import { MENUS } from '../apollo/menu/menus.graphql'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useFulfillment } from '../components/FulfillmentProvider/FulfillmentProvider'
import { useMemo } from 'react'
import { dataByTypename } from '../utils/apollo-helpers'
import { useMenuApiAwareQuery } from './use-menu-api-aware-query'

/**
 *
 * @param {import('react-apollo').QueryHookOptions} options
 */
export const useMenus = (options = {}) => {
  const { fulfillmentTime } = useFulfillment()
  const { shortUrl, restaurantGuid } = useRestaurant()
  const dateTime = fulfillmentTime || undefined

  const menusResponse = useMenuApiAwareQuery(MENUS, {
    ...options,
    variables: { input: { shortUrl, dateTime, restaurantGuid } }
  })

  const response = useMemo(() => {
    const { loading, error, data } = menusResponse
    if (!loading && !error && data) {
      const { MenusResponse, GeneralError } = dataByTypename(data.menusV3)

      if (MenusResponse) {
        // Filter out Menus with no groups, and groups with no items
        const menus = MenusResponse.menus.reduce((acc, menu) => {
          if (!menu.groups) return acc
          const menuGroups = menu.groups.filter(
            ({ items }) => items && items.length > 0
          )
          if (menuGroups.length <= 0) return acc
          return [...acc, { ...menu, groups: menuGroups }]
        }, [])
        return {
          menus,
          ...menusResponse
        }
      }

      if (GeneralError) {
        return {
          ...menusResponse,
          error: GeneralError
        }
      }
    }
    return null
  }, [menusResponse])

  if (!shortUrl) return { error: new Error('No Short URL Provided') }
  if (response) return response
  return options.skip ? { loading: true } : menusResponse
}
