import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { MenuGroups } from './MenuGroups/MenuGroups'
import { MenuBadge, useGetMenuBadge } from '@local/do-secundo-menu-badges'
import { ViewableMonitor } from '../ViewableMonitor/ViewableMonitor'
import { useMenuSelection } from '../../hooks/use-menu-selection'
import { NoMenus } from './NoMenus'

import styles from './Menus.module.css'

export const MenusInner = ({ menu, isIntersecting, refMap, showHeading }) => {
  const { id, name, groups } = menu
  const setActiveMenu = useMenuSelection()
  const menuBadge = useGetMenuBadge(id)
  useEffect(() => {
    if (isIntersecting) {
      setActiveMenu(id)
    }
  }, [isIntersecting, id, setActiveMenu])
  return (
    <>
      {showHeading && (
        <>
          <div className='flex pt-6 pb-2 flex-wrap-reverse items-center gap-x-4 gap-y-2'>
            <h2 className={cx(styles.title, 'type-headline-5 bold')}>{name}</h2>
            {menuBadge && (
              <MenuBadge testId='menu-badge'>{menuBadge.badgeText}</MenuBadge>
            )}
          </div>
          <hr className={styles.rule} />
        </>
      )}
      <MenuGroups menuGroups={groups} refMap={refMap} menuName={name} />
    </>
  )
}

MenusInner.propTypes = {
  menu: PropTypes.object,
  isIntersecting: PropTypes.bool.isRequired,
  refMap: PropTypes.object.isRequired,
  showHeading: PropTypes.bool.isRequired
}

export const Menus = ({ menus, refMap }) => {
  const showHeading = menus.length > 1
  return menus.length > 0 ? (
    menus.map((menu, index) => (
      <ViewableMonitor rootMargin={'-15% 0px -85% 0px'} key={menu.id}>
        {(isIntersecting) => (
          <MenusInner
            isIntersecting={isIntersecting}
            menu={menu}
            refMap={refMap}
            showHeading={showHeading && index !== 0}
          />
        )}
      </ViewableMonitor>
    ))
  ) : (
    <NoMenus />
  )
}

Menus.propTypes = {
  menus: PropTypes.array,
  refMap: PropTypes.object.isRequired
}
