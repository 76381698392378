import React from 'react'
import { IdentityPrompt } from '../IdentityPrompt'
import { ModeLink } from '../../../../client/components/ModeRouter/utils'
import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
export interface SignUpPromptProps {
  form: React.ReactNode
  className?: string
  icon?: React.ReactNode
  testId?: string
  includeLoginLink?: boolean
}

export const SignUpPrompt = ({
  form,
  className,
  icon,
  testId,
  includeLoginLink = true
}: SignUpPromptProps) => {
  return (
    <IdentityPrompt className={className} icon={icon} testId={testId}>
      {form}
      <ButtonSpacer vertical />
      {includeLoginLink && (
        <ModeLink
          data-testid='create-account-login'
          style='secondaryWide'
          mode='login'
        >
          Log In
        </ModeLink>
      )}
    </IdentityPrompt>
  )
}
