import { calculateModifierGroupPrice } from './calculateModifierGroupPrice'
import uniq from 'lodash/uniq'
import { checkIfSizeStrategy, PRICING_STRATEGIES } from './pricingUtility'

/**
 * Helper function to return a array of items that have duplicates removed
 * @param {ModifierGroup[]} existingItemsArray
 * @param {ModifierGroup[]} newItemsArray
 * @returns {Array}
 */
function getUniqItemsArray(existingItemsArray, newItemsArray) {
  return uniq([...existingItemsArray, ...newItemsArray])
}

/**
 * Helper function that transforms an array of similarly shaped objects into a map by the given property.
 * Example: Array of objects that have property 'id' can be transformed into an object, where 'id' is the key and value is an array of all unique elements with the same "id".
 * @param {object[]} array
 * @param {string} property
 * @returns {object}
 */
function transformArrayIntoObjectByProperty(array, property = 'guid') {
  return array.reduce((acc, elem) => {
    const key = elem[property]
    if (!acc[key]) {
      acc[key] = [elem]
    } else {
      const existingValue = acc[key]
      acc[key] = getUniqItemsArray(existingValue, [elem])
    }
    return acc
  }, {})
}

/**
 * Helper function to build a modifier group's options map
 * Needed to look up a group/option by guid when the referenceId is not available to us.
 * @param {ModifierGroupReference[]} modifierGroupReferences
 * @param {ModifierOptionReference[]} modifierOptionReferences
 * @returns {*}
 */
function buildModifierGroupOptionsMap({
  modifierGroupReferences,
  modifierOptionReferences
}) {
  const modifierGroupsMap = transformArrayIntoObjectByProperty(
    modifierGroupReferences
  )
  const modifierOptionsMap = transformArrayIntoObjectByProperty(
    modifierOptionReferences
  )

  return { ...modifierGroupsMap, ...modifierOptionsMap }
}

/**
 * Returns the size of an item if it is determined by a modifier group instead of on the item itself
 * Used inside of calculateModifierGroupPrice when modifierGroup has a pricing strategy of GROUP_PRICE
 * @param {MenuItemApi & ModifierGroup[]} item - current item returned from menu api response + modifierGroups
 * @param {ModifierGroup[]} itemModifierGroups - all modifierGroups associated to an item, comes from menus api item.modifierGroups
 * @param {ModifierGroupOptionsMap} modifierGroupOptionsMap -
 * @returns {ItemSize|null}
 */
function getItemSize({
  menuItem,
  modifierGroupOptionsMap,
  itemModifierGroups
}) {
  const { pricingRules } = menuItem

  let itemSize = null
  const sizeSpecificPricingGuid = pricingRules?.sizeSpecificPricingGuid || null
  // get sizeSpecificPricingModifierGroup and look up its modifierOptionReferences
  // which translates to the sizes available
  if (sizeSpecificPricingGuid) {
    const sizeSpecificPricingModifierGroup =
      modifierGroupOptionsMap?.[sizeSpecificPricingGuid]?.[0]
    const sizeSpecificModifierOptionReferences =
      sizeSpecificPricingModifierGroup?.modifierOptionReferences || null

    // 1. loop over each modifierGroup
    for (let a = 0; a < itemModifierGroups.length; a++) {
      const itemModifierGroupModifiers = itemModifierGroups[a].modifiers
      // 2. loop over each modifierGroup's modifiers
      for (let b = 0; b < itemModifierGroupModifiers.length; b++) {
        const itemModifierGroupModifier = itemModifierGroupModifiers[b]
        const { itemGuid } = itemModifierGroupModifier
        // 3. look up each modifier's guid in modifierGroupOptionsMap to get its referenceId
        const modifierInfoArr = modifierGroupOptionsMap[itemGuid]

        // 4. loop over each modifier if referenceId exists in sizeSpecificModifierOptionReferences, define itemSize, with current modifierInfo name + guid
        if (modifierInfoArr) {
          for (let modifierInfo of modifierInfoArr) {
            if (
              modifierInfo?.referenceId &&
              sizeSpecificModifierOptionReferences?.length &&
              sizeSpecificModifierOptionReferences.indexOf(
                modifierInfo.referenceId
              ) > -1
            ) {
              itemSize = {
                name: modifierInfo.name,
                guid: modifierInfo.guid
              }
              break
            }
          }
        }

        if (itemSize) {
          // break early from inner for loop bc we dont have to iterate over all the modifier groups
          break
        }
      }

      // break out of outer for loop
      if (itemSize) break
    }
  }

  return itemSize
}

/**
 * Returns sequence price for given modifier based on sizeSequenceRules
 * @param {SizeSequencePricingRule} sizeSequencePricingRule
 * @param {number} modifierQuantity
 * @param {number} modifierIndex
 * @returns {number}
 */
function getSequencePriceForCurrentModifier({
  sizeSequencePricingRule,
  modifierQuantity,
  modifierIndex
}) {
  let price = 0
  const numSizeSequencePricingRules =
    sizeSequencePricingRule?.sequencePrices?.length || 0
  const lastSequencePrice =
    sizeSequencePricingRule?.sequencePrices?.[numSizeSequencePricingRules - 1]

  if (!numSizeSequencePricingRules) return price

  // we want to apply sequence prices properly so we create an array
  // with each index's value corresponding to the order it was added
  // so [1,2,3] means that this modifier has a quantity of 3 and they were added as the first, second and third modifiers
  // below we apply the sequence prices for first, second and third modifiers
  const modifierIndexArr = Array.from(
    Array(modifierQuantity).keys(),
    (value, index) => index + modifierIndex
  )

  modifierIndexArr.forEach((modIndex) => {
    if (modIndex <= numSizeSequencePricingRules) {
      sizeSequencePricingRule.sequencePrices.forEach((sequencePrice) => {
        if (modIndex === sequencePrice.sequence) {
          price += sequencePrice.price
        }
      })
    }

    if (modIndex > numSizeSequencePricingRules) {
      // if modifierIndex > length of the sequence prices, use the last sequence price
      price += lastSequencePrice.price
    }
  })

  return price
}

/**
 * Returns price for a modifier that has its pricing rules set on the modifier group, rather than on the modifier
 * @param {PricingRules} pricingRules
 * @param {string} pricingStrategy
 * @param { number } modifierQuantity
 * @param { number } modifierIndex
 * @param {ItemSize} itemSize
 * @returns {number}
 */
function getGroupPrice({
  pricingRules,
  pricingStrategy,
  modifierQuantity,
  modifierIndex,
  itemSize
}) {
  const { sizeSequencePricingRules } = pricingRules
  let price = 0

  if (!sizeSequencePricingRules) return price

  if (checkIfSizeStrategy(pricingStrategy)) {
    if (itemSize?.name && itemSize?.guid) {
      const [selectedSizeRule] = sizeSequencePricingRules.filter((rule) => {
        return (
          rule?.sizeName === itemSize?.name && rule?.sizeGuid === itemSize?.guid
        )
      })

      if (selectedSizeRule) {
        return getSequencePriceForCurrentModifier({
          sizeSequencePricingRule: selectedSizeRule,
          modifierQuantity,
          modifierIndex
        })
      }
    }

    // if itemSize was not selected yet OR if the pricing could not be found
    // we want to return the smallest price for the group based on the size_sequence rules so that frontend cat estimate the price
    const sizeBasedModifierPrices = sizeSequencePricingRules
      .filter(({ sizeGuid }) => Boolean(sizeGuid))
      .map((rule) => {
        return getSequencePriceForCurrentModifier({
          sizeSequencePricingRule: rule,
          modifierQuantity,
          modifierIndex
        })
      })

    const minPrice =
      sizeBasedModifierPrices.length > 0
        ? Math.min(...sizeBasedModifierPrices)
        : 0

    return price + minPrice
  }

  if (pricingStrategy === PRICING_STRATEGIES.SEQUENCE_PRICE) {
    return getSequencePriceForCurrentModifier({
      // making the assumption here that when pricingStrategy === PRICING_STRATEGIES.SEQUENCE_PRICE,
      // we will only have 1 array and we want to use that
      sizeSequencePricingRule: sizeSequencePricingRules[0],
      modifierQuantity,
      modifierIndex
    })
  }
}

/**
 * Returns nested modifier group price
 * @param {ModifierGroup[]} modifierGroups
 * @param {ModifierGroupOptionsMap} modifierGroupOptionsMap
 * @param {itemSize} itemSize
 * @returns {*}
 */
function getNestedModifierGroupsPrice({
  modifierGroups,
  modifierGroupOptionsMap,
  itemSize
}) {
  return modifierGroups.reduce((acc, nestModifierGroup) => {
    const modGroupPrice = calculateModifierGroupPrice({
      modifierGroup: nestModifierGroup,
      modifierGroupOptionsMap,
      itemSize
    })
    return acc + modGroupPrice
  }, 0)
}

export {
  transformArrayIntoObjectByProperty,
  buildModifierGroupOptionsMap,
  getItemSize,
  getGroupPrice,
  getNestedModifierGroupsPrice,
  getSequencePriceForCurrentModifier,
  getUniqItemsArray
}
