const getTotals = ({ giftCard, cart }) => {
  const { availableBalance } = giftCard
  const serverTip = cart.order.tip || 0
  const cartTotal = cart.order.total - serverTip
  return { availableBalance, cartTotal }
}

export const getAppliedValue = ({ giftCard, cart }) => {
  const { availableBalance, cartTotal } = getTotals({ giftCard, cart })
  return Math.min(availableBalance, cartTotal)
}

export const hasSufficientFunds = ({ giftCard, cart }) => {
  const { availableBalance, cartTotal } = getTotals({ giftCard, cart })
  return availableBalance >= cartTotal
}
