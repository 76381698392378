import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field, Formik } from 'formik'
import { Radio } from '@local/do-secundo-form'
import { canMakeApplePayPayment } from '../../ApplePay/apple-pay-utils'
import { getCreditPaymentOption } from '../../CheckoutForm/payment-options'
import { useGetCart } from '../../CartQuery/CartQuery'
import { useAuth } from '../../AuthProvider/AuthProvider'

import styles from './CheckoutSelector.module.css'

export const CHECKOUT_METHODS = {
  APPLE_PAY: 'APPLE_PAY',
  CLASSIC: 'CLASSIC'
}

export const CheckoutSelector = ({ children }) => {
  const { authenticated } = useAuth()
  const { cart, loading } = useGetCart()
  const cashOnly = !getCreditPaymentOption({
    authenticated,
    paymentOptions: cart.paymentOptions
  })
  if (loading) return null
  if (!canMakeApplePayPayment() || cashOnly) {
    return children(CHECKOUT_METHODS.CLASSIC)
  }
  return (
    <Formik
      initialValues={{
        checkoutMethod: CHECKOUT_METHODS.CLASSIC
      }}
    >
      {({ values }) => (
        <>
          <Form className={styles.form}>
            <Field
              style='chunky'
              component={Radio}
              name='checkoutMethod'
              id='checkout_method_classic'
              label='Checkout'
              value={CHECKOUT_METHODS.CLASSIC}
            />
            <Field
              style='chunky'
              component={Radio}
              name='checkoutMethod'
              id='checkout_method_apple'
              label='Apple Pay'
              value={CHECKOUT_METHODS.APPLE_PAY}
            />
          </Form>
          {children(values.checkoutMethod)}
        </>
      )}
    </Formik>
  )
}

CheckoutSelector.propTypes = {
  children: PropTypes.func
}
