import React, {useState} from 'react'
import PropTypes from 'prop-types'

import { ErrorModal } from '@local/do-secundo-error-modal'
import { FulfillmentSelectorModal } from '../../FulfillmentSelectorModal/FulfillmentSelectorModal'

type OnlineOrderingDeliveryDisabledModalProps = {
  onClick: PropTypes.func
}

export const OnlineOrderingDeliveryDisabledModal = ({
  onClick
}: OnlineOrderingDeliveryDisabledModalProps) => {
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false)

  return (
    <>
      {!showFulfillmentModal && (
        <ErrorModal
          header="We couldn't process your order"
          message='Delivery is no longer available. Update your order preferences to complete your order.'
          buttonLabel='Update order preferences'
          onClick={() => setShowFulfillmentModal(true)}
        />
      )}
      {showFulfillmentModal && (
        <FulfillmentSelectorModal
          onClose={() => {
            setShowFulfillmentModal(false)
            onClick()
          }}
          onInvalidAddressClose={() => {
            setShowFulfillmentModal(false)
            onClick()
          }}
        />
      )}
    </>
  )
}
