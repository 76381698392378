import * as React from 'react'
import PropTypes from 'prop-types'
import { joinFieldByCommas } from '../../../utils/reducer-utils'

import styles from './MealSelectorMenuGroups.module.css'

/**
 * Displays collapsible list of parent-level menu groups. A user can
 * click into the group to display the child-level menu groups
 * (e.g. 'Lunch' parent group displays 'Salad', 'Sandwich', and 'Soup' child groups).
 * Clicking on a child menu group selects it and toggles its checked attribute to true.
 * Clicking again deselects it.
 * @param {array} checkboxes
 * @param {array} menus
 * @param {function} setCheckbox
 */
const MealSelectorMenuGroups = ({ checkboxes, menus, setCheckbox }) => {
  return (
    <>
      <h3>Choose your menu categories</h3>
      <div className={styles.menuList}>
        {menus.map(({ name, id: menuId }) => {
          const selectedMenus = checkboxes.filter(
            ({ id: checkboxId, checked }) =>
              checkboxId.includes(menuId) && checked
          )
          return (
            <div className={styles.menuGroup} tabIndex='-1' key={menuId}>
              <div key={name} className={styles.menuLabel}>
                {name}
                <span>{joinFieldByCommas('name')(selectedMenus)}</span>
              </div>
              <div className={styles.menuSubGroup}>
                {checkboxes
                  .filter(({ id }) => id.includes(menuId))
                  .map(({ name, id: subMenuId, checked }, i) => (
                    <div
                      name={name}
                      id={subMenuId}
                      key={subMenuId}
                      className={
                        checked
                          ? styles.menuSubGroupItemChecked
                          : styles.menuSubGroupItem
                      }
                      checked={checked}
                      onClick={(e) => setCheckbox(subMenuId, e.target.checked)}
                    >
                      {name}
                    </div>
                  ))}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

MealSelectorMenuGroups.propTypes = {
  checkboxes: PropTypes.array,
  menus: PropTypes.array,
  setCheckbox: PropTypes.func
}

export { MealSelectorMenuGroups }
