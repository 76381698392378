import { useEffect } from 'react'
import { track } from '@toasttab/do-secundo-analytics'

export const IFRAME_TRACKING_KEY = 'Embedded in IFrame'
const iFrameTrack = (track) => {
  try {
    if (window.self !== window.top) {
      const referrer = document.referrer
      track(IFRAME_TRACKING_KEY, {
        value: true,
        referrer
      })
    } else {
      track(IFRAME_TRACKING_KEY, {
        value: false
      })
    }
  } catch (e) {
    // Triggered if iframe security properies are enabled
    track(IFRAME_TRACKING_KEY, {
      value: true,
      referrer: 'Unknown'
    })
  }
}

export const IframeTracker = () => {
  useEffect(() => {
    // The track function is provided only when analytics is
    // ready to take calls so the tracker is run only once.
    if (track) {
      iFrameTrack(track)
    }
  }, [])

  return null
}
