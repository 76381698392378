import * as React from 'react'
import { Button, ButtonProps } from '@local/do-secundo-button'

export interface TimeoutButtonProps extends ButtonProps {
  timeoutPeriod: number
  maxNumberOfRetries: number
  children: React.ReactNode
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void
}

export const TimeoutButton = ({
  timeoutPeriod = 5000,
  maxNumberOfRetries = 5,
  children,
  onClick,
  ...props
}: TimeoutButtonProps) => {
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [numberOfAttempts, setNumberOfAttempts] = React.useState(0)
  const cancelTimeout = React.useRef<number | null>(null)

  const resetDisable = React.useCallback(() => {
    setIsDisabled(true)
    cancelTimeout.current = window.setTimeout(() => {
      setIsDisabled(false)
    }, timeoutPeriod)
  }, [timeoutPeriod])

  React.useEffect(() => {
    resetDisable()
    return () => {
      window.clearTimeout(cancelTimeout.current!)
    }
  }, [timeoutPeriod, resetDisable])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNumberOfAttempts((prevNumberOfAttempts) => prevNumberOfAttempts + 1)
    onClick(e)
    resetDisable()
  }

  return (
    <Button
      {...props}
      disabled={isDisabled || numberOfAttempts >= maxNumberOfRetries}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}
