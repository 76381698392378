import * as React from 'react'
import PropTypes from 'prop-types'

import {
  checkIfRestaurantIsOpen,
  createOnlineOrderingDaySchedule,
  getNextClosingTime,
  getNextOpenString
} from '../../../../client/utils/restaurant-schedule-helpers'
import styles from './RestaurantSchedule.module.css'
import { useFulfillment } from '../../../../client/components/FulfillmentProvider/FulfillmentProvider'

export const RestaurantSchedule = ({
  schedule,
  orderingAvailable,
  timeZoneId,
  onlineOrderingEnabled
}) => {
  const { fulfillmentTime } = useFulfillment()
  const { upcomingSchedules } = schedule

  const onlineOrderingDaysSchedule =
    createOnlineOrderingDaySchedule(upcomingSchedules)
  const isRestaurantOpen = checkIfRestaurantIsOpen(
    onlineOrderingDaysSchedule,
    timeZoneId
  )

  if (!onlineOrderingEnabled) {
    return <span className={styles.yellowTag}>Online Ordering Unavailable</span>
  }

  if (isRestaurantOpen) {
    if (!orderingAvailable) {
      return (
        <span className={styles.yellowTag}>Online Ordering Unavailable</span>
      )
    }
    return (
      <>
        <span className={styles.greyTag}>Open</span>&nbsp;
        <span className={'text-secondary'}>{`Closes at ${getNextClosingTime(
          onlineOrderingDaysSchedule,
          timeZoneId
        )}`}</span>
      </>
    )
  } else {
    return (
      <>
        <div className={'mb-1'}>
          <span
            className={orderingAvailable ? styles.greyTag : styles.yellowTag}
          >
            {orderingAvailable
              ? 'Scheduled Orders Only'
              : 'Online Ordering Closed'}
          </span>
          &nbsp;
        </div>
        {getNextOpenString({
          daySchedule: onlineOrderingDaysSchedule,
          timeZoneId,
          schedule,
          orderingAvailable,
          fulfillmentTime
        })}
        &nbsp;
      </>
    )
  }
}

const TodaysHoursForTakeOutShape = PropTypes.shape({
  endTime: PropTypes.string,
  startTime: PropTypes.string
})

const ScheduleShape = PropTypes.shape({
  asapAvailableForTakeout: PropTypes.bool,
  todaysHoursForTakeout: TodaysHoursForTakeOutShape,
  upcomingSchedules: PropTypes.array.isRequired
})

RestaurantSchedule.propTypes = {
  schedule: ScheduleShape.isRequired,
  orderingAvailable: PropTypes.bool.isRequired,
  timeZoneId: PropTypes.string.isRequired,
  onlineOrderingEnabled: PropTypes.bool.isRequired,
  isModalView: PropTypes.string
}
