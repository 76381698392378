import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { FormikProvider } from '@local/do-secundo-formik-provider'
import { Checkbox } from '@local/do-secundo-form'
import { GiftCardPaymentContainer } from './GiftCardPaymentContainer/GiftCardPaymentContainer'
import { useGiftCard } from '../../GiftCardProvider/GiftCardProvider'

import styles from '../CheckoutForm.module.css'
import { IfGiftCard } from '../../IfGiftCard/IfGiftCard'
import { GiftCardNotification } from './GiftCardNotification/GiftCardNotification'
import { FF } from '@local/do-secundo-feature-flag'

export const Component = ({ cart, validateForm }) => {
  const { applyGiftCard, clearGiftCard, giftCard } = useGiftCard()
  let { cardNumber, availableBalance } = giftCard

  useEffect(() => {
    validateForm()
  }, [availableBalance, validateForm])

  return (
    <IfGiftCard>
      <FormikProvider
        initialValues={{
          payWithGiftCard: Boolean(cardNumber) && availableBalance > 0,
          giftCardNumber: cardNumber || ''
        }}
        onSubmit={async (values) => {
          applyGiftCard({
            variables: {
              input: {
                cartGuid: cart.guid,
                cardNumber: values.giftCardNumber.replace(/\D/g, '')
              }
            }
          })
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <div className={styles.gcInput}>
              <Field
                onChange={() => {
                  if (values.payWithGiftCard) {
                    clearGiftCard()
                  }
                  setFieldValue('payWithGiftCard', !values.payWithGiftCard)
                }}
                component={Checkbox}
                label={<span>Pay with a Gift Card</span>}
                name='payWithGiftCard'
                id='pay_with_gift_card'
              />
              <GiftCardPaymentContainer />
            </div>
          )
        }}
      </FormikProvider>
      <GiftCardNotification />
    </IfGiftCard>
  )
}

Component.propTypes = {
  cart: PropTypes.object,
  validateForm: PropTypes.func
}

export const getInitialValues = () => {}

export const getArgsForSubmit = ({ featureFlags, giftCardContext }) => {
  if (!featureFlags[FF.GIFT_CARD_REDEMPTION]) {
    return null
  }

  let { giftCard: { cardNumber, availableBalance } = {} } =
    giftCardContext || {}

  if (Boolean(cardNumber) && availableBalance > 0) {
    return {
      giftCard: {
        cardNumber,
        expectedAvailableBalance: availableBalance
      }
    }
  }

  return null
}
