import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import { Progress } from '@local/do-secundo-progress'
import { useInquireLoyalty } from '../use-loyalty'
import { Fieldset } from '@local/do-secundo-fieldset'
import cx from 'classnames'
import { logError } from '@local/do-secundo-error'

import styles from './LoyaltyRedemption/LoyaltyRedemption/LoyaltyRedemption.module.css'
import { LoyaltyRedemption } from './LoyaltyRedemption/LoyaltyRedemption/LoyaltyRedemption'
import { LockedLoyaltyRedemption } from './LoyaltyRedemption/LockedLoyaltyRedemption/LockedLoyaltyRedemption'
import { CASH_REDEMPTION_TYPE, BIRTHDAY_REDEMPTION_TYPE } from '../constants'
import { Points } from '@local/do-secundo-points'

export const LoyaltySection = ({ cart }) => {
  const { accrualTarget } = cart.restaurant.loyaltyConfig

  const [inquireLoyaltyMutation, loyaltyInquiryResult] = useInquireLoyalty()

  useEffect(() => {
    inquireLoyaltyMutation()
  }, [inquireLoyaltyMutation])

  const getInLoyaltyInquiry = useCallback(
    /**
     * @param {string[]|string} path
     * @param {*} [defaultValue]
     * @returns {*} - resolved return value from path
     */
    (path, defaultValue) => {
      const pathArg = Array.isArray(path)
        ? ['data', 'loyaltyInquiryV2'].concat(path)
        : `data.loyaltyInquiryV2.${path}`
      return get(loyaltyInquiryResult, pathArg, defaultValue)
    },
    [loyaltyInquiryResult]
  )

  const redemptions = getInLoyaltyInquiry(['redemptions'], null)

  const pointsBalance = getInLoyaltyInquiry(['pointsBalance'], null)

  if (loyaltyInquiryResult.loading) {
    return <Progress />
  }

  if (loyaltyInquiryResult.error) {
    logError(loyaltyInquiryResult.error)
    return null
  }

  if (
    !loyaltyInquiryResult.called ||
    !getInLoyaltyInquiry('loyaltyAccountIdentifier', null)
  ) {
    return null
  }

  const cashRedemption = redemptions.find(
    (redemption) => redemption.type === CASH_REDEMPTION_TYPE
  )
  const birthdayRedemption = redemptions.find(
    (redemption) => redemption.type === BIRTHDAY_REDEMPTION_TYPE
  )

  const numAvailableRedemptions = redemptions.length

  if (!numAvailableRedemptions) {
    if (!pointsBalance) {
      return (
        <Fieldset label='Rewards' collapsable>
          <span>No available rewards to redeem</span>
        </Fieldset>
      )
    }
    const pointsDiff = accrualTarget - pointsBalance
    return (
      <Fieldset label={`Rewards`} collapsable>
        <div className={cx(styles.rewardText, 'font-bold')}>
          You have {pointsBalance} <Points points={pointsBalance} />
        </div>
        {pointsDiff < 1 ? (
          <>
            <div className={styles.rewardText} data-testid='reward-text'>
              Collect more points to unlock a reward!
            </div>
          </>
        ) : (
          <>
            <div className={styles.rewardText} data-testid='reward-text'>
              Collect {pointsDiff} more <Points points={pointsDiff} /> to unlock
              a reward!
            </div>

            <LockedLoyaltyRedemption cart={cart} />
          </>
        )}
      </Fieldset>
    )
  }

  return (
    <Fieldset label={`Rewards (${numAvailableRedemptions})`} collapsable>
      <div className={cx(styles.rewardText, 'font-bold')}>Congratulations!</div>
      <div className={styles.rewardText} data-testid='reward-text'>
        You have {pointsBalance} <Points points={pointsBalance} /> and unlocked{' '}
        {numAvailableRedemptions} reward
        {numAvailableRedemptions > 1 ? 's' : ''}
      </div>
      {birthdayRedemption && (
        <LoyaltyRedemption cart={cart} redemption={birthdayRedemption} />
      )}
      {cashRedemption !== undefined ? (
        <LoyaltyRedemption
          cart={cart}
          redemption={cashRedemption}
          pointsBalance={pointsBalance}
        />
      ) : (
        <LockedLoyaltyRedemption cart={cart} />
      )}
    </Fieldset>
  )
}

LoyaltySection.propTypes = {
  cart: PropTypes.object.isRequired
}
