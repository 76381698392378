import * as React from 'react'
import { Field } from 'formik'
import { Input } from '@local/do-secundo-form'

export interface PhoneInputProps {
  testId?: string
  name?: string
  mask?: any
  label?: string
  id?: string
}

const MASK: Array<string | RegExp> = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const PhoneInput: React.FC<PhoneInputProps> = ({
  testId = 'phone-input',
  label = 'Phone number*',
  name = 'phone',
  mask = MASK,
  id = 'create-account-phone-number'
}) => {
  return (
    <Field
      data-testid={testId}
      name={name}
      label={label}
      id={id}
      type='tel'
      mask={mask}
      guide={false}
      component={Input}
    />
  )
}
