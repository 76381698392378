import React from 'react'
import PropTypes from 'prop-types'

import styles from './CannedDeliveryInstructions.module.css'

export const CannedDeliveryInstructions = ({ header, content }) => {
  return (
    <>
      <div data-testid='canned-delivery-instructions'>
        <div className={styles.header}>{header}</div>
        <div data-testid='canned-delivery-text'>{content}</div>
      </div>
    </>
  )
}

CannedDeliveryInstructions.propTypes = {
  content: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired
}
