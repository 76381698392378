import React from 'react'

import { CartLoader } from '../Cart/Cart.loader'
import { MenuPageLayout } from './MenuPageLayout/MenuPageLayout'
import { MenusLoader } from '../Menus/Menus.loader'
import { RestaurantHeaderLoader } from '../RestaurantHeader/RestaurantHeader.loader'

import './MenuPage.module.css'

export const MenuPageLoader = () => {
  return (
    <MenuPageLayout
      header={<RestaurantHeaderLoader />}
      menus={<MenusLoader />}
      cart={<CartLoader />}
    />
  )
}
