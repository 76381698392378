import { useQuery } from '@apollo/client'

/**
 * Hook that determines if DO-menu flag is on, alters input to match old/new API and queries data with useQuery hook.
 * @param {DocumentNode} query
 * @param {QueryHookOptions} options
 * @param {function} getter a callback that returns input object to modify in case of exra nesting (defaults to options.variables.input)
 * @returns {QueryResult}
 */
export const useMenuApiAwareQuery = (
  query,
  options,
  getter = (options) => options.variables.input
) => {
  getter(options).menuApi = 'DO'
  const menusResponse = useQuery(query, {
    ...options
  })

  return menusResponse
}
