import React from 'react'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import { ErrorComponent } from '@local/do-secundo-error'
import { ACCOUNT_DATA_STATE } from '@local/do-secundo-passwordless-authentication'
import { Progress } from '@local/do-secundo-progress'
import { Notification } from '@local/do-secundo-notification'
import { useCreditCard } from '../CreditCardProvider/CreditCardProvider'

import { SavedCreditCardPicker } from '../SavedCreditCardPicker/SavedCreditCardPicker'

import styles from './CheckoutSavedCreditCard.module.css'

export const CheckoutSavedCreditCard = ({ field, form, amexAccepted }) => {
  let {
    creditCards = [],
    loading,
    error,
    refetch,
    creditCardsStatus
  } = useCreditCard()
  const checkImportStatus = !creditCards.length
  const importInProgress =
    checkImportStatus && creditCardsStatus === ACCOUNT_DATA_STATE.importing
  if (loading || importInProgress) {
    return <Progress />
  }
  const showImportError =
    checkImportStatus && creditCardsStatus === ACCOUNT_DATA_STATE.importNotReady
  if (showImportError) {
    return (
      <Notification severity='error' padTop>
        There was an error retrieving your saved credit card information. Please
        try again later.
      </Notification>
    )
  }
  if (error) return <ErrorComponent error={error} retry={refetch} />

  if (!amexAccepted) {
    creditCards = creditCards.map((card) => ({
      ...card,
      guid: card.cardType === 'AMEX' ? `!${card.guid}` : card.guid
    }))
  }

  const currentGuid = getIn(form.values, field.name)
  const currentCard = creditCards.find(({ guid }) => guid === currentGuid) || {}
  const isAmex = currentCard.cardType === 'AMEX'
  const isAmexAndNotAccepted = isAmex && !amexAccepted

  return (
    <>
      <SavedCreditCardPicker creditCards={creditCards} field={field} />
      {isAmexAndNotAccepted && (
        <div className={styles.spacer}>
          <Notification>
            Unfortunately, this restaurant does not accept American Express.
            Please use another credit card to complete your order.
          </Notification>
        </div>
      )}
    </>
  )
}

CheckoutSavedCreditCard.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  amexAccepted: PropTypes.bool
}
