import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Loading, LoadingVariant } from '@local/do-secundo-loading'
import { useReorder } from '../ReorderProvider/ReorderProvider'

import styles from './ReorderButton.module.css'
import { useModeHistoryPushCallback } from '../../ModeRouter/ModeRouter'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'

export const ReorderButton = ({ orderGuid, children, disabled }) => {
  const { getRestaurantPath } = useRestaurant()
  const {
    reorderGuid,
    reorderMutation: [reorder, { loading }]
  } = useReorder()
  const redirectToCart = useModeHistoryPushCallback({
    mode: 'cart',
    to: {
      pathname: getRestaurantPath()
    }
  })

  const isInProgress = loading && reorderGuid === orderGuid

  const handleClick = useCallback(
    async function mutateAndRedirect() {
      await reorder(orderGuid)
      redirectToCart()
    },
    [orderGuid, redirectToCart, reorder]
  )

  return (
    <button
      onClick={handleClick}
      data-testid='reorder-add-to-cart'
      disabled={loading || disabled}
      className={styles.reorderButton}
    >
      {isInProgress ? <Loading variant={LoadingVariant.SECONDARY} /> : children}
    </button>
  )
}

ReorderButton.propTypes = {
  orderGuid: PropTypes.string.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool
}
