import { CompleteAccountFormValues } from '@local/do-secundo-auth-form'
import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import { logError } from '@local/do-secundo-error'
import { Input } from '@local/do-secundo-form'
import { Notification } from '@local/do-secundo-notification'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import { History } from 'history'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import * as Yup from 'yup'
import { CompleteIdentityProfileResponseOrError } from '../../../../client/apollo/generated/PossibleTypes'
// styles for terms of service links
import styles from '../../../../client/components/AuthModals/CreateAccountModal/CreateAccountModal.module.css'
import {
  showAccountCreatedNotification,
  showLoggedInNotification,
  useAuth
} from '../../../../client/components/AuthProvider/AuthProvider'
import { ButtonSpacer } from '../../../../client/components/ButtonSpacer/ButtonSpacer'
import { PoweredByToastModal } from '../../../../client/components/PoweredByToastModal/PoweredByToastModal'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { emailSchema } from '../../../../client/utils/form-schemas'
import { FROM_LOCATION, useImportAccount } from '../index'
import { isLegacyAccountLoggedIn } from '../utils'

type OnClose = () => void
type SetSubmitting = (value: Boolean) => void
type SetIsAuthenticated = (value: Boolean) => void
type OnAuthComplete = (name: String) => void
type HandleSubmitParams = {
  values: CompleteAccountFormValues
  onClose: OnClose
  setSubmitting: SetSubmitting
  history: History
  completePasswordlessLogin: (input: {
    variables: {
      input: {
        firstName: string
        lastName: string
        source: string
        email: string
      }
    }
  }) => Promise<CompleteIdentityProfileResponseOrError>
  cancelPasswordlessLoginAndKeepLegacy: () => void
  user?: any
  handleImportAccount: (arg: {
    onSuccess?: (e?: any) => void | any
    onError?: (e?: any) => void | any
  }) => Promise<void>
  loading: Boolean
  searchForCustomermgmtAccount: () => Promise<{
    data: {
      searchForCustomermgmtAccount: {
        foundMatchingEmail: Boolean
        __typename: string
      }
    }
  }>
  setIsAuthenticated: SetIsAuthenticated
  onAuthComplete: OnAuthComplete
}
type HandleSubmit = ({
  values,
  onClose,
  setSubmitting,
  history,
  completePasswordlessLogin,
  cancelPasswordlessLoginAndKeepLegacy,
  user,
  loading,
  setIsAuthenticated,
  onAuthComplete,
  handleImportAccount
}: HandleSubmitParams) => Promise<void>

export interface CompleteAccountModalProps extends RouteComponentProps {
  onClose: OnClose
  handleSubmit: HandleSubmit
}

/**
 * V1.5/V2 Passwordless
 * handles verification of account in the CompleteAccountModal
 */
export const handleCompleteAccount = async ({
  values,
  onClose,
  setSubmitting,
  history,
  completePasswordlessLogin,
  cancelPasswordlessLoginAndKeepLegacy,
  searchForCustomermgmtAccount,
  setIsAuthenticated,
  onAuthComplete,
  handleImportAccount
}: HandleSubmitParams) => {
  const input = {
    variables: {
      input: {
        ...values,
        source: 'ONLINE_ORDERING'
      }
    }
  }

  const result = await completePasswordlessLogin(input)

  const { CompleteIdentityProfileError } = dataByTypename(
    result.data.completePasswordlessLogin
  )

  if (CompleteIdentityProfileError) {
    throw Error(CompleteIdentityProfileError.message)
  }

  if (isLegacyAccountLoggedIn()) {
    await handleImportAccount({
      onSuccess: () => {
        setIsAuthenticated(true)
      },
      onError: () => {
        cancelPasswordlessLoginAndKeepLegacy()
      }
    })
    setSubmitting(false)
    showLoggedInNotification(values.firstName)
    onClose()
  } else {
    setIsAuthenticated(true)

    if (searchForCustomermgmtAccount) {
      const customermgmtAccountExistResponse =
        await searchForCustomermgmtAccount()
      const { SearchForCustomermgmtAccountResponse } = dataByTypename(
        customermgmtAccountExistResponse.data.searchForCustomermgmtAccount
      )

      if (SearchForCustomermgmtAccountResponse?.foundMatchingEmail) {
        // 1. if customermgmt acct exists, prompt for acct linking in email detected modal
        // store email for use in email detected modal
        history.replace('?mode=emailDetected', { email: values.email })
        return
      }
      // 2. if customermgmt acct does not exist, log user into new pwless acct
    }
    setSubmitting(false)
    onClose()
    onAuthComplete(values.firstName)
  }
}

export const CompleteAccountModal = withRouter(
  ({
    onClose,
    handleSubmit = handleCompleteAccount,
    location: { state },
    history
  }: CompleteAccountModalProps) => {
    const {
      completePasswordlessLogin,
      cancelPasswordlessLogin,
      cancelPasswordlessLoginAndKeepLegacy,
      user,
      loading,
      searchForCustomermgmtAccount,
      setIsAuthenticated,
      authClient
    } = useAuth()
    const [encounteredError, setEncounteredError] = React.useState(false)
    const fieldTestIdPrefix = 'complete-account-modal-'
    const handleImportAccount = useImportAccount({
      keepLegacyLoginOnError: true
    })
    const missingName = !state?.firstName || !state?.lastName
    const nameOnlyMode = missingName && state?.email
    const allowUserToEscapeModal = encounteredError || !nameOnlyMode

    React.useEffect(() => {
      if (state?.from !== FROM_LOCATION.sms || !authClient.token) {
        history.push('?mode=create')
      }
    }, [state?.from, history])

    React.useEffect(() => {
      const handlePageClose = async () => {
        await cancelPasswordlessLogin()
        return 'unloading'
      }
      window.addEventListener('beforeunload', handlePageClose)
      return () => {
        window.removeEventListener('beforeunload', handlePageClose)
      }
    }, [cancelPasswordlessLogin])

    const cancelCompleteAccount = React.useCallback(async () => {
      try {
        await cancelPasswordlessLogin()
      } catch (err) {
        logError(err)
        //do not rethrow, allow the modal to close
      }
      onClose()
    }, [cancelPasswordlessLogin, onClose])

    return (
      <PoweredByToastModal
        className='h-full'
        onClose={allowUserToEscapeModal ? cancelCompleteAccount : null}
        header={
          <>
            <h2
              data-testid='connect-account-modal-header'
              className={'mx-0 mt-0 mb-3 type-headline-4'}
            >
              {nameOnlyMode ? (
                'Almost there! Tell us your name.'
              ) : (
                <>Complete your account for quick ordering &amp; checkout</>
              )}
            </h2>
          </>
        }
      >
        <div className='flex flex-col justify-between h-full m-auto'>
          <Formik
            initialValues={{
              email: state?.email || '',
              firstName: state?.firstName || '',
              lastName: state?.lastName || ''
            }}
            validationSchema={Yup.object().shape({
              email: emailSchema,
              firstName: Yup.string().required('Required'),
              lastName: Yup.string().required('Required')
            })}
            onSubmit={async (values, { setSubmitting, setError }) => {
              try {
                await handleSubmit({
                  values: { ...values, email: values.email.trim() },
                  onClose,
                  setSubmitting,
                  history,
                  completePasswordlessLogin,
                  cancelPasswordlessLoginAndKeepLegacy,
                  user,
                  handleImportAccount,
                  loading,
                  searchForCustomermgmtAccount: nameOnlyMode
                    ? null
                    : searchForCustomermgmtAccount,
                  setIsAuthenticated,
                  onAuthComplete: nameOnlyMode
                    ? showLoggedInNotification
                    : showAccountCreatedNotification
                })
              } catch (err) {
                setError(err)
                setEncounteredError(true)
                setSubmitting(false)
              }
            }}
          >
            {({ isSubmitting, isValid, error }) => (
              <Form>
                {error && (
                  <Notification severity='error'>{error.message}</Notification>
                )}
                <ButtonSpacer vertical />
                <div
                  data-testid={`${fieldTestIdPrefix}hide-email`}
                  className={nameOnlyMode ? 'hidden' : ''}
                >
                  <Field
                    data-testid={`${fieldTestIdPrefix}email`}
                    autoFocus
                    id={`${fieldTestIdPrefix}email`}
                    name='email'
                    type='text'
                    label='Email*'
                    component={Input}
                  />
                  <p className='pb-2 type-caption'>
                    Your email will be used to provide digital receipts, account
                    communications, and marketing messages from both Toast and
                    restaurants you visit unless you unsubscribe.
                  </p>
                </div>
                <Field
                  data-testid={`${fieldTestIdPrefix}firstName`}
                  id={`${fieldTestIdPrefix}password`}
                  name='firstName'
                  type='text'
                  label='First name*'
                  component={Input}
                />
                <Field
                  data-testid={`${fieldTestIdPrefix}lastName`}
                  id={`${fieldTestIdPrefix}-password`}
                  name='lastName'
                  type='text'
                  label='Last name*'
                  component={Input}
                />
                {!nameOnlyMode && (
                  <p
                    className='text-center type-caption'
                    data-testid='complete-account-terms-of-service'
                  >
                    By creating an account, you agree to&nbsp;
                    <a
                      href='https://pos.toasttab.com/terms-of-service#diner-tos'
                      rel='noopener noreferrer'
                      target='_blank'
                      className={cx(styles['legal-link'], 'type-caption')}
                      data-testid='link-terms-of-service'
                    >
                      Toast's Guest Terms of Service
                    </a>
                    &nbsp;and acknowledge your information will be processed
                    pursuant to&nbsp;
                    <a
                      href='https://pos.toasttab.com/privacy'
                      rel='noopener noreferrer'
                      target='_blank'
                      className={cx(styles['legal-link'], 'type-caption')}
                      data-testid='link-privacy'
                    >
                      Toast's Privacy Statement
                    </a>
                    .
                  </p>
                )}
                <Button
                  data-testid={`${fieldTestIdPrefix}button-submit`}
                  type={ButtonType.SUBMIT}
                  variant={ButtonVariant.PRIMARY}
                  responsive
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {nameOnlyMode ? 'Finish' : 'Create account'}
                </Button>
                <ButtonSpacer vertical />
              </Form>
            )}
          </Formik>
        </div>
      </PoweredByToastModal>
    )
  }
)
