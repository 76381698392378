import { namespacedStorage } from './namespaced-storage'
import { useRestaurant } from '@local/do-secundo-restaurant-provider'
import { useMemo } from 'react'

export const useRestaurantStorage = (storagePlace = localStorage) => {
  const { restaurantGuid } = useRestaurant()

  const restaurantStorage = useMemo(() => {
    const namespace = 'toast-oo-restaurant'
    const storage = namespacedStorage(namespace, storagePlace)
    return {
      get: (key) => storage.get([restaurantGuid, key]),
      set: (key, value) => storage.set([restaurantGuid, key], value),
      remove: (key) => storage.remove([restaurantGuid, key])
    }
  }, [restaurantGuid, storagePlace])

  if (!restaurantGuid) throw new Error('Outside of Restaurant Context')
  return restaurantStorage
}
