import React, { useCallback, useMemo } from 'react'
import { withRouter, useLocation, useHistory } from 'react-router'

import { ResetPasswordModal } from '../AuthModals/ResetPasswordModal/ResetPasswordModal'
import { ForgotPasswordModal } from '../AuthModals/ForgotPasswordModal/ForgotPasswordModal'
import { LoginModal } from '../AuthModals/LoginModal/LoginModal'
import { CreateAccountModal } from '../AuthModals/CreateAccountModal/CreateAccountModal'
import { VerifyEmail } from '../VerifyEmail/VerifyEmail'
import { FulfillmentSelectorModal } from '../FulfillmentSelectorModal/FulfillmentSelectorModal'
import { MFAVerificationModal } from '../AuthModals/MFAVerificationModal/MFAVerificationModal'
import { SearchModal } from '../Search/SearchModal/SearchModal'
import { MealSelectorModal } from '../MealSelector/MealSelectorModal/MealSelectorModal'
import { useFulfillmentAvailability } from '../../hooks/use-fulfillment-availability'
import { useAvailability } from '@local/do-secundo-availability-provider'
import { useVariant } from '../../hooks/use-variant'
import { XP_MEAL_SELECTOR } from '../ExperimentsProvider/experiment-definitions'
import { MenuPerformanceModal } from '@local/do-secundo-performance-audit'
import { paramsToCleanUp } from './utils'
import { ConnectAccountModal } from '@local/do-secundo-auth-modals'
import {
  CompleteAccountModal,
  useIsPasswordlessAuthEnabled,
  EmailDetectedModal,
  DeleteAccountModal,
  UnifiedCreateAccountModal,
  UnifiedSmsVerificationModal,
  UnifiedLoginModal,
  EnterYourPasswordModal,
  LoginEasierModal
} from '@local/do-secundo-passwordless-authentication'
import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { useAuth } from '../AuthProvider/AuthProvider'

const ModeRouter = withRouter(({ location, history }) => {
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('mode')
  paramsToCleanUp.forEach((param) => searchParams.delete(param))
  const closeLocation = {
    ...location,
    search: searchParams.toString()
  }
  const pushLocation = () => history.push(closeLocation)

  const replaceLocationClearState = () => {
    const clearState = {
      ...closeLocation,
      state: {}
    }
    history.replace(clearState)
  }

  const replaceLocation = () => history.replace(closeLocation)

  const mealSelectorVariant = !!useVariant(XP_MEAL_SELECTOR.experimentName)
  const fulfillmentAvailable = useFulfillmentAvailability()
  const { orderingAvailable } = useAvailability()
  const { PASSWORDLESS_ENABLED } = useIsPasswordlessAuthEnabled()
  const EDIT_MODE_ENABLED = useFlag(FF.EDIT_MODE)
  const { authenticated } = useAuth()

  const routes = {
    verify: <VerifyEmail onClose={pushLocation} />,
    search: <SearchModal onClose={pushLocation} />,
    forgot: <ForgotPasswordModal onClose={pushLocation} />,
    reset: <ResetPasswordModal onClose={replaceLocation} />,
    mfa: <MFAVerificationModal onClose={pushLocation} />,
    loginEasier: <LoginEasierModal onClose={pushLocation} />,
    sms: <UnifiedSmsVerificationModal onClose={replaceLocationClearState} />,
    complete: <CompleteAccountModal onClose={replaceLocationClearState} />
  }

  if (EDIT_MODE_ENABLED) {
    routes.performance = <MenuPerformanceModal onClose={pushLocation} />
  }

  if (fulfillmentAvailable) {
    routes.fulfillment = <FulfillmentSelectorModal onClose={replaceLocation} />
  }

  if (mealSelectorVariant && orderingAvailable) {
    routes.shuffle = <MealSelectorModal onClose={pushLocation} />
  }

  const authenticatedRoutes = {
    ...routes,
    delete: <DeleteAccountModal onClose={replaceLocation} />,
    ...(PASSWORDLESS_ENABLED && {
      emailDetected: <EmailDetectedModal onClose={replaceLocationClearState} />,
      connect: <ConnectAccountModal onClose={replaceLocation} />
    })
  }

  const unauthenticatedRoutes = {
    ...routes,
    ...(PASSWORDLESS_ENABLED
      ? {
          login: <UnifiedLoginModal onClose={pushLocation} />,
          create: (
            <UnifiedCreateAccountModal
              onClose={replaceLocationClearState}
              headerText='Enter your phone number'
            />
          ),
          enterYourPassword: (
            <EnterYourPasswordModal onClose={replaceLocationClearState} />
          )
        }
      : {
          login: <LoginModal onClose={pushLocation} />,
          create: <CreateAccountModal onClose={pushLocation} />
        })
  }

  const search = new URLSearchParams(location.search)
  const mode = search.get('mode')

  const route = authenticated
    ? authenticatedRoutes[mode]
    : unauthenticatedRoutes[mode]

  const modeRoute = route || null

  React.useEffect(() => {
    const browserSearch = new URLSearchParams(window.location.search)
    const browserMode = browserSearch.get('mode')
    if (mode && !modeRoute && browserMode === mode) {
      // replace location here is being used to remove unused modes from the URL.
      // because of a brief timing inconsistency we need to check that the browser location
      // is equivalent to that of the react location. This ensures we aren't removing modes until
      // they are for sure in sync.
      replaceLocation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeRoute, mode])

  return modeRoute
})

export const useHasMode = (value) => {
  const location = useLocation()
  return new URLSearchParams(location.search).get('mode') === value
}

/**
 *
 * @param {Object} useModeHistoryPushCallbackParams
 * @param {string} useModeHistoryPushCallbackParams.mode
 * @param {import('history').LocationDescriptorObject|string} useModeHistoryPushCallbackParams.to - A location object or pathname string (note: replaces search with 'mode' parameter)
 */
export const useModeHistoryPushCallback = ({
  mode,
  to = { pathname: '/' }
}) => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  )
  searchParams.set('mode', mode)
  paramsToCleanUp.forEach((param) => searchParams.delete(param))

  if (typeof to === 'string') {
    to = { pathname: to }
  }

  return useCallback(() => {
    history.push({
      ...to,
      search: '?' + searchParams.toString()
    })
  }, [history, searchParams, to])
}

export { ModeRouter }
