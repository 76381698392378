import React from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonType, ButtonVariant } from '@local/do-secundo-button'
import PaperPlane from '../../../assets/paper-plane.svg'
import { PoweredByToastModal } from '../../PoweredByToastModal/PoweredByToastModal'

import styles from './ForgotPasswordSuccessModal.module.css'

export const ForgotPasswordSuccessModal = ({ onClose }) => {
  return (
    <PoweredByToastModal onClose={onClose}>
      <div className={styles.centered}>
        <PaperPlane className={styles.plane} />
        <h2>Email Sent</h2>
        <p className={styles.body}>
          Check your email for instructions on resetting your password.
        </p>
        <i className={styles.note}>
          *Note that you will only receive reset instructions if an account is
          associated with the email you entered.
        </i>
        <Button
          variant={ButtonVariant.LINK_WIDE}
          type={ButtonType.BUTTON}
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </PoweredByToastModal>
  )
}

ForgotPasswordSuccessModal.propTypes = {
  onClose: PropTypes.func
}
