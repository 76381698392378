import React, { useRef, useState, useEffect } from 'react'
import cx from 'classnames'

import { handleOnChange, handleKeyDown } from './codeInputHelpers'

export interface CodeInputProps {
  numFields: number
  form: { setFieldValue: (key: string, value: string) => void }
  field: { value: { codeValues: string } }
  resetFocusAfterCompletion?: boolean
  spaceBetweenHyphens: number
  disabled?: boolean
  idPrefix: string
}

export const CodeInput = ({
  numFields,
  form: { setFieldValue },
  field: {
    value: { codeValues }
  },
  resetFocusAfterCompletion = true,
  spaceBetweenHyphens,
  disabled = false,
  idPrefix
}: CodeInputProps) => {
  const values = new Array(numFields).fill('')
  for (let i = 0; i < codeValues.length; i++) {
    values[i] = codeValues[i]
  }
  const autoFocusIndex = useRef(0)
  const [refs, setRefs] = useState(
    Array.from({ length: numFields }, () => React.createRef<HTMLInputElement>())
  )

  useEffect(() => {
    setRefs(
      Array.from({ length: numFields }, () =>
        React.createRef<HTMLInputElement>()
      )
    )
  }, [numFields])

  const hasNonSpaceValue = (value: string) => value && value !== ' '

  const canFocus = (value: string, index: number) =>
    hasNonSpaceValue(value) || autoFocusIndex.current === index || index === 0

  return (
    <>
      {values.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {index % spaceBetweenHyphens === 0 && index !== 0 && (
              <span
                key={`span-${index + spaceBetweenHyphens}`}
                className='text-default'
              >
                {' '}
                -{' '}
              </span>
            )}
            <input
              type='tel'
              pattern={'[0-9]*'}
              key={`input-${index}`}
              data-id={index}
              id={`${idPrefix}-mfa-code-${index}`}
              name={`mfa-code-${index}`}
              autoComplete='off'
              value={value === ' ' ? '' : value}
              // tabIndex to disable all inputs that a user shouldn't be able to update
              tabIndex={canFocus(value, index) ? undefined : -1}
              onMouseDown={(e) => {
                e.preventDefault()
                if (canFocus(value, index)) {
                  const target = e.target as HTMLInputElement
                  target.select()
                }
              }}
              ref={refs[index]}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                handleOnChange({
                  e,
                  numFields,
                  setFieldValue,
                  refs,
                  codeValues: values,
                  autoFocusIndex,
                  resetFocusAfterCompletion
                })
              }
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyDown({
                  e,
                  setFieldValue,
                  refs,
                  codeValues: values,
                  autoFocusIndex
                })
              }
              className={cx('m-1 h-12 w-10 rounded text-center')}
              disabled={disabled}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
