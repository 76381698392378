import React from 'react'
import PropTypes from 'prop-types'

export const RestaurantAddress = React.memo(
  ({ address1, address2, city, state }) => {
    return (
      <div className='type-default text-secondary'>
        {address1}
        {address2 ? ` ${address2}` : ''}, {city}, {state}
      </div>
    )
  }
)

RestaurantAddress.displayName = 'RestaurantAddress'

RestaurantAddress.propTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired
}
