import { useMemo } from 'react'
import { useAvailability } from '@local/do-secundo-availability-provider'
import {
  useDiningOptions,
  DINING_OPTION_BEHAVIORS
} from '@local/do-secundo-use-dining-options'

const { DELIVERY } = DINING_OPTION_BEHAVIORS

export const useFulfillmentAvailability = () => {
  const { futureDatesAvailable, orderingAvailable } = useAvailability()

  const { data } = useDiningOptions({ networkOnly: false })
  const hasDelivery = useMemo(() => {
    const { diningOptions = [] } = data || {}
    return diningOptions.some(({ behavior }) => behavior === DELIVERY)
  }, [data])

  if (!orderingAvailable) return false
  return Boolean(futureDatesAvailable || hasDelivery)
}
