import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { PopularItemImage } from '../PopularItemImage/PopularItemImage'
import { MenuItemCard } from '../../MenuItemCard/MenuItemCard'
import { useWindowSize } from '@toasttab/buffet-use-window-size'
import { MenuBadge, useGetMenuItemBadge } from '@local/do-secundo-menu-badges'

export const PopularItem = ({ item }) => {
  const { imageUrl, guid } = item
  const windowSize = useWindowSize({})
  const isMobile = windowSize.width <= 1025
  const badge = useGetMenuItemBadge(guid)

  return (
    <MenuItemCard
      item={item}
      dataTestid='popular-item-link'
      endAdornment={<PopularItemImage imageUrl={imageUrl} />}
      badge={
        badge ? (
          <MenuBadge
            className={cx(isMobile ? 'mb-3' : 'mb-2')}
            testId='popular-item-badge'
          >
            {badge.badgeText}
          </MenuBadge>
        ) : null
      }
    />
  )
}

const itemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  price: PropTypes.number
})

PopularItem.propTypes = {
  item: itemShape.isRequired
}
