import PropTypes from 'prop-types'
import { Model } from './Model'
import { ModifierGroup } from './ModifierGroup'

export class MenuItemDetails extends Model {
  constructor(menuItemDetails) {
    super(menuItemDetails)
    this.modifierGroups = this.mapModifierGroups(
      menuItemDetails.modifierGroups || []
    )
  }

  mapModifierGroups(modifierGroups) {
    return modifierGroups.map(
      (modifierGroup) => new ModifierGroup(modifierGroup, this)
    )
  }

  getNextValues() {
    return this.modifierGroups
  }
}

export const MenuItemDetailsShape = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string,
  guid: PropTypes.string.isRequired,
  calories: PropTypes.number,
  price: PropTypes.number,
  imageUrl: PropTypes.string,
  usesFractionalQuantity: PropTypes.bool,
  unitOfMeasure: PropTypes.string.isRequired,
  modifierGroups: PropTypes.array.isRequired
})
