import { Redirect, useLocation } from 'react-router'
import { Link } from '../Link/Link'
import React from 'react'
import { BrandedRouterButton } from '@local/do-secundo-branded-buttons'
import { SEARCH_PARAMS } from '@local/do-secundo-passwordless-authentication/src/utils/enums'
import { RouterButton } from '@local/do-secundo-router-button'

export const paramsToCleanUp = [
  'resetToken',
  'confirmToken',
  SEARCH_PARAMS.resetFrom
]

export const getModeComponent =
  (Component) =>
  ({ mode, state, pathname, ...props }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('mode', mode)
    paramsToCleanUp.forEach((param) => searchParams.delete(param))
    return (
      <Component
        {...props}
        to={{ pathname, search: searchParams.toString(), state }}
      />
    )
  }

export const ModeRedirect = getModeComponent(Redirect)
export const ModeLink = getModeComponent(Link)
export const BrandedModeLink = getModeComponent(BrandedRouterButton)
export const ButtonModeLink = getModeComponent(RouterButton)
