import React from 'react'
import PropTypes from 'prop-types'

import Star from '../../../assets/star.svg'

import styles from './PopularItemImage.module.css'
import { imgInPreprod } from '../../../utils/img-url'

export const PopularItemImage = ({ imageUrl }) => {
  if (!imageUrl) {
    return (
      <div className={styles.star}>
        <Star />
      </div>
    )
  }
  return (
    <div
      style={{ backgroundImage: `url(${imgInPreprod(imageUrl)})` }}
      className={styles.image}
    />
  )
}

PopularItemImage.propTypes = {
  imageUrl: PropTypes.string
}
