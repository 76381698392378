import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Modal } from '@local/do-secundo-modal'
import { MessageError } from '@toasttab/do-secundo-message-error'
import { PhoneIcon } from '@toasttab/buffet-pui-icons'
import { formatPhoneNumber } from '../../../../client/utils/phone-number'
import styles from './ErrorModal.module.css'

export interface ErrorModalProps {
  testId?: string | number
  header: string
  message: string
  buttonLabel: string
  onClick: PropTypes.func
  showRxPhone?: boolean
  whiteLabelName?: string
  restaurantPhone?: string
  hideIcon?: boolean
  buttonTestId?: string
}

export const ErrorModal = ({
  testId = `ErrorModal`,
  header,
  message,
  buttonLabel,
  onClick,
  showRxPhone = false,
  whiteLabelName,
  restaurantPhone,
  hideIcon = false,
  buttonTestId
}: ErrorModalProps) => (
  <Modal responsive={false} data-testid={testId}>
    {showRxPhone && (
      <div className='flex items-center justify-center h-full'>
        <div className='flex flex-col items-center justify-center rounded-md shadow-lg w-36 border-1 h-36'>
          <div>
            <div className='flex items-center justify-center w-10 h-10 rounded-full shadow-lg border-1 bg-gray-30'>
              <PhoneIcon />
            </div>
          </div>
          <div
            data-testid='rxname'
            className={cx(
              'w-11/12 pt-4 overflow-hidden text-center max-h-14',
              styles.clamp
            )}
          >
            {whiteLabelName}
          </div>
          <div data-testid='phonenumber' className='text-center'>
            {formatPhoneNumber(restaurantPhone)}
          </div>
        </div>
      </div>
    )}
    <div className='p-5'>
      <MessageError
        testId='message-error'
        header={header}
        message={message}
        buttonPrimary={{
          label: buttonLabel,
          onClick: () => onClick(),
          testId: buttonTestId
        }}
        hideIcon={hideIcon}
      />
    </div>
  </Modal>
)
