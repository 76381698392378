import * as React from 'react'
import { useRestaurantStorage } from '../../../utils/restaurant-storage'
import { useModeHistoryPushCallback } from '../../ModeRouter/ModeRouter'
import styles from './MealSelectorPrompt.module.css'

/**
 * RestaurantHeader-level link which opens MealSelectorModal
 */
const MealSelectorPrompt = () => {
  const redirectToModal = useModeHistoryPushCallback({ mode: 'shuffle' })
  const restaurantStorage = useRestaurantStorage()

  return (
    <button
      onClick={() => {
        restaurantStorage.remove('toast-oo-meal-selector')
        redirectToModal()
      }}
      className={styles.selectorFrame}
      data-testid='oo-v3-meal-selector-open-modal'
    >
      <span>Can't decide what to eat? Let us help!</span>
    </button>
  )
}

export { MealSelectorPrompt }
