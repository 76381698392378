/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useVariant } from '../../../hooks/use-variant'
import cx from 'classnames'

import { useFlag, FF } from '@local/do-secundo-feature-flag'
import { MenuItems, MenuItemsShape } from '../MenuItems/MenuItems'
import { MenuBadge, useGetMenuGroupBadge } from '@local/do-secundo-menu-badges'
import { MoreInfo } from '@local/do-secundo-more-info'
import {
  variantToSortMap,
  sortByStock
} from '../../../utils/menu-group-helpers'
import { XP_MENU_ITEMS_SORT } from '../../ExperimentsProvider/experiment-definitions'

import styles from './MenuGroups.module.css'

const Group = ({ id, refMap, guid, items, name, description }) => {
  const variant = useVariant(XP_MENU_ITEMS_SORT.experimentName)
  const [done, setDone] = useState()
  const ref = refMap[id]
  const { hash } = useLocation()

  const menuGroupDescriptionsOn = useFlag(FF.MENU_GROUP_DESCRIPTION)
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)
  const groupBadge = useGetMenuGroupBadge(guid)
  const sortingMethod = variantToSortMap[variant]

  useEffect(() => {
    if (done) return
    const node = ref && ref() && ref().current
    if (hash.includes(id) && node) {
      node.scrollIntoView()
      setDone(true)
    }
  }, [done, hash, id, ref])

  // Reverted logic to avoid sorting if XP is off
  const itemsToRender = useMemo(() => {
    const xpSortedItems = !variant ? items : sortingMethod(items)
    return sortByStock(xpSortedItems)
  }, [variant, items, sortingMethod])

  return (
    <li
      data-testid='menu-groups'
      key={id}
      itemProp='hasMenuSection'
      itemScope={nv5SEOEnabled}
      itemType={nv5SEOEnabled && 'https://schema.org/MenuSection'}
      role='group'
      aria-label={`${name} menu`}
    >
      {nv5SEOEnabled && <meta itemProp='name' content={name} />}
      {nv5SEOEnabled && description && (
        <meta itemProp='description' content={description} />
      )}
      {nv5SEOEnabled && (
        <meta
          itemProp='url'
          content={`https://${window.location.host}${window.location.pathname}#${id}`}
        />
      )}
      <a id={id} ref={ref} className={styles.anchor} />
      <div className='flex pt-4 pb-3 flex-wrap-reverse items-center gap-x-4 gap-y-2'>
        <h3
          data-testid='menu-group-name'
          className={cx(styles.groupLabel, 'type-large bold')}
          title={name}
        >
          {name}
        </h3>
        {groupBadge && (
          <MenuBadge testId='group-badge'>{groupBadge.badgeText}</MenuBadge>
        )}
      </div>
      {menuGroupDescriptionsOn && (
        <div className={styles.description}>
          <MoreInfo text={description} />
        </div>
      )}
      <MenuItems isExternal items={itemsToRender} />
    </li>
  )
}

Group.propTypes = {
  id: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  refMap: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(MenuItemsShape),
  name: PropTypes.string.isRequired
}

const MenuGroups = ({ menuGroups, refMap, menuName }) => {
  const nv5SEOEnabled = useFlag(FF.NV5_SEO)

  return (
    <ul
      className={styles.menuGroup}
      itemProp='hasMenu'
      itemScope={nv5SEOEnabled}
      itemType={nv5SEOEnabled && 'https://schema.org/Menu'}
    >
      {nv5SEOEnabled && menuName && <meta itemProp='name' content={menuName} />}

      {menuGroups
        .filter((group) => group.items && group.items.length > 0)
        .map(({ name, id, guid, description, items }) => {
          return (
            <Group
              key={id}
              id={id}
              guid={guid}
              refMap={refMap}
              items={items}
              name={name}
              description={description}
            />
          )
        })}
    </ul>
  )
}

const MenuGroupsShape = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    guid: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(MenuItemsShape)
  })
)

MenuGroups.propTypes = {
  refMap: PropTypes.object.isRequired,
  menuGroups: MenuGroupsShape.isRequired,
  menuName: PropTypes.string
}

export { MenuGroups, MenuGroupsShape }
