import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import styles from './Link.module.css'

export const Link = ({ style = 'secondary', onClick = () => {}, ...props }) => {
  return (
    <RouterLink
      className={styles[style]}
      {...props}
      onClick={() => {
        onClick()
      }}
    />
  )
}

Link.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.oneOf([
    'primary',
    'secondary',
    'primaryWide',
    'secondaryWide',
    'extraPadding'
  ])
}
