import { useEffect } from 'react'
import { FormikValues } from 'formik'

export interface AutoSubmitListenerProps {
  canSubmit: boolean
  formik: {
    values: { codeValues: string }
    submitForm: (args: FormikValues) => void
    isValid: boolean
  }
}

export const AutoSubmitListener = ({
  canSubmit,
  formik: { submitForm, isValid, values }
}: AutoSubmitListenerProps) => {
  useEffect(() => {
    if (isValid && canSubmit) {
      submitForm(values)
    }
  }, [values, isValid, submitForm])

  return null
}
