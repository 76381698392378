import React from 'react'
import { Points } from '@local/do-secundo-points'

export const PointsDescription = ({
  conversionRate,
  redemptionAmount,
  pointsBalance
}) => {
  const pointsEquivalentByConversionRate =
    conversionRate && Math.round(redemptionAmount / conversionRate)

  const pointsEquivalent = Math.min(
    pointsEquivalentByConversionRate,
    pointsBalance
  )

  return pointsEquivalent ? (
    <div data-testid='points description'>
      for {pointsEquivalent} <Points points={pointsEquivalent} />
    </div>
  ) : (
    ''
  )
}
